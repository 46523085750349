import { MenuItem, Select, Typography } from '@mui/material';
import { Column, Row, Spacer } from 'components';
import { useCreateWorkOrderModalData } from 'hooks/modals';
import { colors } from 'theme';

interface Props {
	value: string | null;
	setValue: (value: string) => void;
}

const Skill = ({ value, setValue }: Props) => {
	const menuItems = [
		'General maintenance',
		'Prepare unit for new residents (simple work)',
		'Prepare unit for new residents (heavy remodel)',
	];

	const modalData = useCreateWorkOrderModalData('Latchel');
	const inspection = modalData?.metadata?.inspection;

	if (!inspection) return null;

	return (
		<Column>
			<Row>
				<Typography variant="h6">Skill:</Typography>
				<Spacer width={2} />
				<Typography sx={{ marginTop: 0.4 }} color={colors.gray}>
					(Required)
				</Typography>
			</Row>
			<Spacer height={4} />

			<Typography fontWeight={600}>
				This work order contains issues requiring multiple skills.
			</Typography>
			<Spacer height={2} />
			<Typography>
				You can create a single work order by selecting one of the general
				maintenance skills below. Or go back to create individual work orders
				for each flagged item.
			</Typography>

			<Spacer height={8} />

			<Select
				id="select-skill"
				variant="filled"
				value={value || 'Placeholder'}
				onChange={(e) => setValue(e.target.value)}>
				<MenuItem value="Placeholder" sx={{ display: 'none' }}>
					Select one
				</MenuItem>
				{menuItems.map((mi) => (
					<MenuItem id={`menu_item-${mi}`} value={mi} key={mi}>
						{mi}
					</MenuItem>
				))}
			</Select>
		</Column>
	);
};

export default Skill;
