import { DashboardData, DashboardProperty } from '@rentcheck/api-frontend';
import { ApiInspection } from '@rentcheck/types';

export type State = DashboardData | null;

const initialState: State = null;

type Action = {
	type: string;
	data: DashboardData | ApiInspection[] | DashboardProperty[];
};

const reducer = (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case 'SIGNOUT_SUCCESS':
		case 'IMPERSONATE':
			return initialState;

		// Main dashboard fetch from the /dashboard endpoint
		case 'FETCHED_DASHBOARD_DATA':
			return action.data as DashboardData;

		default:
			return state;
	}
};

export default reducer;
