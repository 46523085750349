import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Checkbox,
	Popover as MuiPopover,
	Radio,
	Tooltip,
	Typography,
} from '@mui/material';
import { SpacedRow } from 'components/layout/Row';
import React from 'react';
import styled from 'styled-components';
import { colors, spacing, spacingPx } from 'theme';

import { Column } from '../layout/Column';
import { Row } from '../layout/Row';
import Spacer from '../Spacer';

type PopoverDropdownItemType = 'radio' | 'checkbox';

interface Props {
	options: ItemOptionProps[];
	disabled?: boolean;
	width?: number;
	type: PopoverDropdownItemType;
	loading?: boolean;
	title: string;
	titleIsSelectedOption: boolean;
}

interface ItemOptionProps {
	title: string;
	onClick: () => void;
	selected: boolean;
	disabled?: boolean;
	disabledTooltip?: string;
}

interface ItemProps extends ItemOptionProps {
	type: PopoverDropdownItemType;
}

interface SelectButtonProps {
	selected: boolean;
	type: PopoverDropdownItemType;
}

const SelectButton = ({ selected, type }: SelectButtonProps) => {
	if (type === 'radio') {
		return <Radio checked={selected} sx={{ color: colors.black }} />;
	}
	if (type === 'checkbox') {
		return <Checkbox checked={selected} sx={{ color: colors.black }} />;
	}
	return null;
};

const PopoverDropdown = ({
	loading,
	options,
	disabled,
	width,
	type,
	title,
	titleIsSelectedOption,
}: Props) => {
	const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const paperProps = {
		square: true,
		style: width ? { width: spacing(width) } : {},
	};

	return (
		<>
			<DropdownAnchor onClick={disabled ? undefined : handleClick}>
				{titleIsSelectedOption && (
					<>
						<PopoverItem
							type={type}
							title={title}
							onClick={() => {}}
							selected
						/>
						<FontAwesomeIcon
							icon={solid('caret-down')}
							color={colors.gray}
							size="lg"
						/>
					</>
				)}
				{!titleIsSelectedOption && (
					<>
						<Typography variant="h6" color={colors.gray}>
							{loading ? 'Loading...' : title}
						</Typography>
						<FontAwesomeIcon
							icon={solid('caret-down')}
							color={colors.gray}
							size="lg"
						/>
					</>
				)}
			</DropdownAnchor>
			<MuiPopover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				PaperProps={paperProps}>
				<Content>
					{options.map((option) => (
						<PopoverItem
							type={type}
							key={option.title}
							title={option.title}
							selected={option.selected}
							disabled={option.disabled}
							disabledTooltip={option.disabledTooltip}
							onClick={() => {
								type === 'radio' && handleClose();
								option.onClick();
							}}
						/>
					))}
				</Content>
			</MuiPopover>
		</>
	);
};

export const PopoverItem = ({
	title,
	onClick,
	type,
	selected,
	disabled,
	disabledTooltip,
}: ItemProps) => {
	const PopoverItemContent = () => (
		<ItemContent onClick={disabled ? undefined : onClick} disabled={disabled}>
			<SelectButton selected={selected} type={type} />
			<Spacer width={4} />
			<Typography>{title}</Typography>
		</ItemContent>
	);

	if (disabled && disabledTooltip) {
		return (
			<Tooltip title={disabledTooltip} placement="right">
				<div>
					<PopoverItemContent />
				</div>
			</Tooltip>
		);
	}
	return <PopoverItemContent />;
};

const Content = styled(Column)`
	padding: ${spacingPx(1)} ${spacingPx(5)};
`;

const ItemContent = styled(Row)<{ disabled?: boolean }>`
	align-items: center;
	border-bottom: 1px solid ${colors.lightGray};
	height: ${spacingPx(15)};
	cursor: pointer;
	opacity: ${({ disabled }) => (disabled ? '0.5' : '1.0')};

	&:hover {
		opacity: ${({ disabled }) => (disabled ? '0.3' : '0.8')};
	}
`;

const DropdownAnchor = styled(SpacedRow)`
	height: ${spacingPx(15)};
	border: 1px solid ${colors.lightGray};
	padding: 0 ${spacingPx(5)};
	cursor: pointer;
`;

export default PopoverDropdown;
