import { Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { ActionsChip, Spacer } from 'components';
import {
	ModalFlowActions,
	PropertiesActions,
	SnackbarActions,
} from 'store/actions';
import { Dispatch } from 'types';

import { UnitsApi } from '@rentcheck/api-frontend';
import { APIUnit } from '@rentcheck/types';
import { useTypedSelector } from 'store/reducers/rootReducer';
import AssignToBuildingModal from '../../../components/AssignBuildingModal';
import AssignTeamModal from '../../../components/AssignTeamModal';
import { DeleteActionButton } from '../../../components/delete-action-button';

interface Props {
	children?: React.ReactNode | React.ReactNodeArray;
	unitIds: string[];
	setUnitIds?: (ids: string[]) => void;
	disableDeletion?: boolean;
}

const ActionsMenu = ({
	children,
	unitIds,
	setUnitIds,
	disableDeletion,
}: Props) => {
	const dispatch: Dispatch = useDispatch();

	const units = useTypedSelector((state) => state.properties.units.data);

	const [teamModalVisible, setTeamModalVisible] = useState(false);
	const [buildingModalVisible, setBuildingModalVisible] = useState(false);

	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const handleClick = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		setAnchorEl(e.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleReassign = () => {
		handleClose();

		if (unitIds.length === 0) {
			return;
		}

		setTeamModalVisible(true);
	};

	const handleAddToBuilding = () => {
		handleClose();

		if (unitIds.length === 0) {
			return;
		}

		setBuildingModalVisible(true);
	};

	const handleCreateInspection = async () => {
		handleClose();

		if (unitIds.length === 0) {
			return;
		}

		const properties = units.filter((b) => unitIds.includes(b.id));

		if (properties.length !== unitIds.length) {
			const missingUnitIds = unitIds.filter(
				(id) => !properties.find((p) => p.id === id)
			);

			const missingUnits = await Promise.all(
				missingUnitIds.map((id) => UnitsApi.getById(id))
			).catch((e) => {
				dispatch(SnackbarActions.showError(e.message));
				return [] as APIUnit[];
			});

			properties.push(...missingUnits);
		}

		dispatch(
			ModalFlowActions.showCreateInspectionModal({
				properties,
				property_type: 'unit',
			})
		);
	};

	const deleteUnits = () => {
		dispatch(PropertiesActions.units.remove(unitIds))
			.then(() => setUnitIds?.([]))
			.catch(() => dispatch(SnackbarActions.showError()));
	};

	const handleDelete = () => {
		handleClose();

		if (unitIds.length === 0) return;

		const body = [
			'Are you sure you want to delete these units?',
			'Scheduled inspections for this unit that have not been started along with associated residents will also be removed.',
			"This action can't be undone.",
		];

		dispatch(
			ModalFlowActions.showConfirmationModal({
				title: 'Are you sure?',
				body1: body,
				body2: [],
				buttons: [
					ModalFlowActions.cancelButton(dispatch),
					{
						title: 'Delete',
						color: 'error',
						variant: 'contained',
						onClick: deleteUnits,
					},
				],
			})
		);
	};

	const handleSuccess = () => {};

	return (
		<>
			<div onClick={handleClick}>
				{children ?? (
					<ActionsChip
						ids={unitIds}
						openMenu={(e: React.MouseEvent<HTMLElement>) => handleClick(e)}
					/>
				)}
			</div>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={!!anchorEl}
				onClose={handleClose}>
				<MenuItem onClick={handleReassign}>
					Reassign Team
					<Spacer width={4} />
				</MenuItem>
				<MenuItem onClick={handleAddToBuilding}>
					Add to Building
					<Spacer width={4} />
				</MenuItem>
				<MenuItem onClick={handleCreateInspection}>
					Create Inspection
					<Spacer width={4} />
				</MenuItem>
				<DeleteActionButton disabled={disableDeletion} onClick={handleDelete} />
			</Menu>

			{teamModalVisible && (
				<AssignTeamModal
					propertyIds={unitIds}
					setModalVisible={setTeamModalVisible}
					propertiesReassigned={handleSuccess}
				/>
			)}

			{buildingModalVisible && (
				<AssignToBuildingModal
					propertyIds={unitIds}
					setModalVisible={setBuildingModalVisible}
					unitsReassigned={handleSuccess}
				/>
			)}
		</>
	);
};

export default ActionsMenu;
