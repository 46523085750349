import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Divider,
	Typography,
} from '@mui/material';

import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TemplateQuestion } from '@rentcheck/types';
import { Column, SpacedRow, Spacer } from 'components';
import Rating from 'screens/inspections/details/content/completed/list/row/rating';

import {
	useFeatureDetailsModalData,
	useFeatureImageModalData,
} from 'hooks/modals';
import { MouseEvent, RefObject } from 'react';
import { useDispatch } from 'react-redux';
import { ModalFlowActions } from 'store/actions';
import { FeatureWithMetadata } from 'store/reducers/features';
import { EditFeatureMetadata } from 'store/reducers/modal-flows';

import Notes from './notes';
import Questions from './questions';

import { Rules } from '@rentcheck/biz';
import { FeatureImageViewerRef } from '../../../image/image';

interface Props {
	feature: FeatureWithMetadata;
	mediaUri?: string;
	hoveredImage?: string;
	setHoveredQuestion: (question?: TemplateQuestion) => void;
	mode: EditFeatureMetadata['mode'];
	imageViewerRef?: RefObject<FeatureImageViewerRef>;
}

export default ({
	feature,
	mediaUri,
	hoveredImage,
	setHoveredQuestion,
	mode,
	imageViewerRef,
}: Props) => {
	const dispatch = useDispatch();

	/**
	 * This component is shown from 2 different modals, so we need to check
	 * both metadata sources to get the right info
	 */
	const featureDetailsModalData = useFeatureDetailsModalData();
	const featureImagesModalData = useFeatureImageModalData();

	const inspection =
		featureDetailsModalData?.metadata?.inspection ??
		featureImagesModalData?.metadata?.inspection;
	const features =
		featureDetailsModalData?.metadata?.features ??
		featureImagesModalData?.metadata?.features;

	const handleEdit = (e: MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		e.preventDefault();

		dispatch(
			ModalFlowActions.showEditFeatureModal({
				initialFeature: feature,
				features,
				inspection,
				mode,
				media: mediaUri,
				initialScale: imageViewerRef?.current?.getScale(),
				initialPosition: imageViewerRef?.current?.getPosition(),
			})
		);
	};

	if (!inspection) return null;

	return (
		<Accordion defaultExpanded disableGutters elevation={0}>
			<AccordionSummary
				expandIcon={<FontAwesomeIcon icon={solid('caret-down')} />}>
				<SpacedRow style={{ flex: 1 }}>
					<Typography variant="subtitle2">Details</Typography>
					{Rules.Inspections.canEditFeature(inspection, feature) && (
						<Button
							variant="text"
							color="secondary"
							onClick={handleEdit}
							sx={{ marginRight: 1 }}
							startIcon={<FontAwesomeIcon icon={regular('edit')} />}>
							Edit
						</Button>
					)}
				</SpacedRow>
			</AccordionSummary>
			<AccordionDetails sx={{ flexDirection: 'column' }}>
				<Column>
					<Divider sx={{ marginTop: -1, marginBottom: 1 }} />

					{!!feature.rating && (
						<>
							<Typography variant="overline">Rating</Typography>
							<Rating feature={feature} fullWidth />
							<Spacer height={5} />
						</>
					)}

					{!feature.not_applicable && (
						<>
							<Questions
								feature={feature}
								hoveredImage={hoveredImage}
								setHoveredQuestion={setHoveredQuestion}
							/>

							<Notes feature={feature} />
						</>
					)}
				</Column>
			</AccordionDetails>
		</Accordion>
	);
};
