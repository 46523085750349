import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Screens } from 'components';
import ChipMenu from 'components/chip-menu';
import { useState } from 'react';
import Buildings from './buildings';
import Units from './units';

export type MenuOptionType = 'Units' | 'Buildings';

const menuIcons = { Units: solid('home'), Buildings: solid('buildings') };

export default () => {
	const [propertyType, setPropertyType] = useState<MenuOptionType>('Units');

	return (
		<Screens.Container>
			<Screens.Header
				title="Property Configuration"
				showBreadCrumbs
				subtitle="View and add additional rooms and areas that can be configured on your units and buildings."
			/>

			<ChipMenu
				multiple={false}
				value={propertyType}
				setValue={setPropertyType}
				options={['Units', 'Buildings'] as MenuOptionType[]}
				getChipIcon={(o) => menuIcons[o]}
			/>

			{propertyType === 'Units' && <Units />}
			{propertyType === 'Buildings' && <Buildings />}
		</Screens.Container>
	);
};
