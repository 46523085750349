import { Rules, Utils } from '@rentcheck/biz';
import { ApiInspectionWithTemplate } from '@rentcheck/types';
import { formatDate } from 'utils/helpers';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { SpacedRow } from 'components';
import IconButton from 'components/icon-button';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { ModalFlowActions } from 'store/actions';
import { Dispatch } from 'types';

import GridItem from '../grid-item';

interface Props {
	inspection: ApiInspectionWithTemplate;
}

export default ({ inspection }: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const handleDueDate = () => {
		dispatch(
			ModalFlowActions.showEditDueDateModal({ inspections: [inspection] })
		);
	};

	if (!Rules.Inspections.canShowDueDate(inspection)) {
		return null;
	}

	const dueDate = moment(inspection.due_date).startOf('day');
	const daysOverdue = Math.floor(
		moment.duration(moment().diff(dueDate)).asDays()
	);

	return (
		<GridItem
			title="Due Date"
			value={
				<>
					<SpacedRow>
						<span>
							{formatDate(inspection.due_date)}{' '}
							{daysOverdue > 0 && (
								<span style={{ color: '#C71D23' }}>
									({Utils.Helpers.numberAndPluralizedWord(daysOverdue, 'day')}{' '}
									overdue)
								</span>
							)}
						</span>
						{Rules.Inspections.canEditDueDate(inspection) && (
							<IconButton
								tooltip="Edit Due Date"
								onClick={handleDueDate}
								icon={regular('edit')}
								buttonSx={{ mb: -1, mt: -2 }}
							/>
						)}
					</SpacedRow>
				</>
			}
		/>
	);
};
