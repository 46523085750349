import { Divider, Grid, Typography } from '@mui/material';
import { Column } from 'components';
import { Question } from 'components/features';
import { FeatureWithMetadata } from 'store/reducers/features';

interface Props {
	feature: FeatureWithMetadata;
}

export default ({ feature }: Props) => {
	if (!feature.questions.length) {
		return null;
	}

	return (
		<Column>
			<Typography variant="overline" mt={2}>
				Questions
			</Typography>
			<Grid container columnSpacing={2} columns={2}>
				{feature.questions.map((question, index) => (
					<Grid item xs={2} md={1}>
						<Question feature={feature} question={question} index={index} />
					</Grid>
				))}
			</Grid>
			<Divider sx={{ mt: 2 }} />
		</Column>
	);
};
