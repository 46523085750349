import { Skeleton } from '@mui/material';
import { Spacer } from 'components';

export default () => {
	return (
		<>
			<Skeleton
				variant="rectangular"
				width="100%"
				height={50}
				sx={{ borderRadius: 1, marginTop: 2 }}
			/>
			<Spacer height={1} />
			<Skeleton variant="text" width="40%" height={18} />

			<Spacer height={4} />

			<Skeleton variant="text" width="30%" height={24} />
			<Spacer height={3} />
			<Skeleton variant="text" width="60%" height={18} />
			<Spacer height={2} />
			<Skeleton variant="text" width="60%" height={18} />
		</>
	);
};
