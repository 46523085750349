import { MenuItem } from '@mui/material';
import { FlagResponsibility } from '@rentcheck/types';
import FormTextField from 'components/form-text-field';

interface Props {
	value: FlagResponsibility;
	setValue: (value: FlagResponsibility) => void;
}

const responsibilities = [
	{ value: 'OWNER', label: 'Owner' },
	{ value: 'RESIDENT', label: 'Resident' },
];

const Responsibility = ({ value, setValue }: Props) => {
	return (
		<FormTextField
			select
			title="Responsibility"
			placeholder="Select One"
			value={value || 'placeholder'}
			onChange={(e) => setValue(e.target.value as FlagResponsibility)}>
			{responsibilities.map((r) => (
				<MenuItem
					key={r.value}
					value={r.value}
					id={`select-responsibility-${r.value.toLowerCase()}`}>
					{r.label}
				</MenuItem>
			))}
			<MenuItem value="" id="select-responsibility-clear">
				Clear Selection
			</MenuItem>
		</FormTextField>
	);
};

export default Responsibility;
