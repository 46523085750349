import { Chip, MenuItem, Menu as MuiMenu, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
	ApiMaintenanceReport,
	Feature,
	GetAllMaintenanceReportsParams,
	MaintenanceFlag,
} from '@rentcheck/types';

import { MaintenanceReportsApi } from '@rentcheck/api-frontend';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';
import { colors, spacingPx } from 'theme';

interface Props {
	flag: MaintenanceFlag;
	feature: Feature;
}

const ReportMenu = ({ flag, feature }: Props) => {
	const history = useHistory();
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const [reports, setReports] = useState<ApiMaintenanceReport[]>([]);
	const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

	useEffect(() => {
		const filters: GetAllMaintenanceReportsParams['filters'] = {
			inspection_id: feature.inspection_id,
		};

		setLoading(true);

		MaintenanceReportsApi.getAll(0, 100, filters)
			.then((res) => {
				const reportsForThisFlag = res.data.filter((r) =>
					r.maintenance_flags.some((f) => f.id === flag.id)
				);

				setReports(reportsForThisFlag);
			})
			.catch((e) => dispatch(SnackbarActions.showError(e)))
			.finally(() => setLoading(false));
	}, [feature.inspection_id, flag.id]);

	const handleMenuItemClick = (r: ApiMaintenanceReport) => {
		history.push(`/maintenance/reports/${r.id}`);
	};

	const handleMenuButtonClick = (e: React.MouseEvent<HTMLElement>) => {
		setAnchorElement(e.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorElement(null);
	};

	if (loading) {
		return (
			<Skeleton
				variant="rectangular"
				width={170}
				height={32}
				sx={{ borderRadius: 16 }}
			/>
		);
	}

	if (!reports.length) {
		return null;
	}

	return (
		<>
			<Chip
				label="Maintenance"
				onClick={handleMenuButtonClick}
				sx={{
					marginTop: 0.5,
					marginBottom: 0.5,
					width: 170,
					fontWeight: 500,
					backgroundColor: colors.lightGray,
					color: '#353535',
				}}
			/>
			<Menu
				autoFocus={false}
				open={!!anchorElement}
				anchorEl={anchorElement}
				onClose={handleMenuClose}
				onClick={() => {
					setAnchorElement(null);
				}}>
				{reports.map((r) => (
					<MenuItem
						onClick={() => handleMenuItemClick(r)}
						sx={{ minWidth: 180 }}>
						{r.name}
					</MenuItem>
				))}
			</Menu>
		</>
	);
};

const Menu = styled(MuiMenu)`
	.MuiMenu-paper {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	ul {
		border-top: none;
		border-radius: 4px;
		border-top-left-radius: 0;
		border-top-right-radius: 0;

		li {
			display: flex;
			justify-content: center;
			padding-left: ${spacingPx(4)};
			background-color: ${colors.white};
			transition: background-color 0.5s;
			:hover {
				background-color: #ccd6e166;
			}
		}

		li:nth-child(2n + 1) {
			background-color: #47474708;
			:hover {
				background-color: #ccd6e166;
			}
		}
	}
`;

export default ReportMenu;
