import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, InputAdornment, TextField, Typography } from '@mui/material';
import { Column, Spacer } from 'components';

interface Props {
	performerName?: string;
	approvalNote: string;
	setApprovalNote: (value: string) => void;
}

export default ({ performerName, approvalNote, setApprovalNote }: Props) => {
	return (
		<Column>
			<Spacer height={5} />
			<Typography variant="overline">Approval note</Typography>
			<Spacer height={3} />

			<TextField
				label="Note"
				helperText="Optional"
				placeholder={`Add a note ${performerName ? `for ${performerName}` : ''}`}
				variant="filled"
				value={approvalNote}
				onChange={(e) => setApprovalNote(e.target.value)}
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<FontAwesomeIcon icon={regular('message-lines')} />
						</InputAdornment>
					),
				}}
			/>
			<Spacer height={4} />
			<Divider />
		</Column>
	);
};
