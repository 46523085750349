import { FlatfileButton, FlatfileResults } from '@flatfile/react';
import { LoadingButton } from '@mui/lab';
import { Card, CardContent, Typography } from '@mui/material';
import { ImportersApi } from '@rentcheck/api-frontend';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { SpacedRow, Spacer } from 'components';
import { SnackbarActions } from 'store/actions';

import flatfileSettings from './common/flatfile-settings';
import { UploaderProps } from './common/types';

export default ({ user }: UploaderProps) => {
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);

	const handleData = async (results: FlatfileResults) => {
		setLoading(true);

		ImportersApi.uploadFlatfileUnits(user.email, results.data)
			.then((response) => {
				const message = `Success. \
			(${response.numberOfPropertiesInserted} Created, \
				${response.numberOfPropertiesUpdated} Updated)`;
				dispatch(SnackbarActions.showSuccess(message));
			})
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	return (
		<Card sx={{ width: '100%' }}>
			<CardContent>
				<Typography variant="h6">Units</Typography>
				<Spacer height={2} />
				<SpacedRow>
					<Spacer width={4} />

					<FlatfileButton
						licenseKey="cc7bd3c0-e3dd-4c8a-9b0b-1eb25428d05a"
						customer={{ userId: '123123' }}
						settings={flatfileSettings.units}
						onData={handleData}
						render={(_importer, launch) => (
							<LoadingButton
								variant="contained"
								loading={loading}
								onClick={launch}>
								Upload
							</LoadingButton>
						)}
					/>
				</SpacedRow>
			</CardContent>
		</Card>
	);
};
