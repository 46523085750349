"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.spacingPx = exports.spacing = exports.typography = exports.fonts = exports.colors = exports.shadow = void 0;
const spacing_1 = require("./spacing");
Object.defineProperty(exports, "spacing", { enumerable: true, get: function () { return spacing_1.spacing; } });
Object.defineProperty(exports, "spacingPx", { enumerable: true, get: function () { return spacing_1.spacingPx; } });
exports.shadow = require("./shadow");
exports.colors = require("./colors");
exports.fonts = require("./fonts");
exports.typography = require("./typography");
