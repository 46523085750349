import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { InspectionsApi } from '@rentcheck/api-frontend';
import { ApiInspectionWithTemplate } from '@rentcheck/types';

import { Rules, Utils } from '@rentcheck/biz';
import IconButton from 'components/icon-button';
import { useProperty } from 'hooks/properties';
import { SnackbarActions } from 'store/actions';

interface Props {
	inspection: ApiInspectionWithTemplate;
}

export default ({ inspection }: Props) => {
	const dispatch = useDispatch();

	const { property } = useProperty(inspection.property.id);

	const [loading, setLoading] = useState(
		inspection.sync_data?.status === 'processing' ||
			inspection.sync_data?.status === 'scheduled'
	);

	const handleManualSync = async () => {
		setLoading(true);

		InspectionsApi.manualSync(inspection.id)
			.then(() =>
				dispatch(
					SnackbarActions.showSnackbar(
						'Inspection sync in progress. This may take a while. Check back shortly!'
					)
				)
			)
			.catch((e) => {
				dispatch(SnackbarActions.showError(e));
				setLoading(false);
			});
	};

	if (!property) {
		return null;
	}

	if (!Rules.Inspections.canManualSync(inspection, property)) {
		return null;
	}

	const tooltip = loading
		? 'Inspection sync is queued.'
		: `Sync with ${Utils.Properties.getIntegrationVendor(property)}`;

	return (
		<IconButton
			loading={loading}
			disabled={loading}
			icon={regular('arrows-rotate')}
			tooltip={tooltip}
			onClick={handleManualSync}
		/>
	);
};
