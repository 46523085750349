import { ChangeEvent } from 'react';
import { MenuItem, SelectChangeEvent, Typography } from '@mui/material';

import { TemplateQuestion } from '@rentcheck/types';

import { Row } from 'components';
import FormTextField from 'components/form-text-field';

interface Props {
	question?: TemplateQuestion;
	conditionValue: string[];
	onUpdateConditionValue: (conditionValue: string[]) => void;
}

export default ({
	question,
	conditionValue,
	onUpdateConditionValue,
}: Props) => {
	const handleUpdateConditionValue = (e: ChangeEvent<HTMLInputElement>) => {
		const selectEvent = e as SelectChangeEvent<string[]>;
		const newValue = selectEvent.target.value;

		if (typeof newValue === 'string') {
			return onUpdateConditionValue([newValue]);
		}

		onUpdateConditionValue(
			(newValue as string[]).filter((v) => v !== 'placeholder')
		);
	};

	const multiple = question?.answers.length !== 2;

	return (
		<Row>
			<Typography
				variant="subtitle2"
				mb={3.5}
				sx={{ maxWidth: 180, minWidth: 180 }}>
				& answer is one of:
			</Typography>

			<FormTextField
				select
				required
				fullWidth
				placeholder="Select answer"
				multiple={multiple}
				disabled={!question}
				inputSx={{ mt: 0, mb: 1 }}
				helperText="Required"
				value={conditionValue}
				onChange={handleUpdateConditionValue}>
				{question?.answers.map((a) => {
					const selected = conditionValue.includes(a.title);
					const disabled =
						!selected &&
						multiple &&
						conditionValue.length + 1 === question?.answers.length;

					return (
						<MenuItem key={a.id} value={a.title} disabled={disabled}>
							{a.title}
						</MenuItem>
					);
				})}
			</FormTextField>
		</Row>
	);
};
