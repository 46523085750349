import { Spacer } from 'components';
import _ from 'lodash';

import { useLatchelIntegrations } from 'hooks/latchel-integrations';
import ConnectLatchelButton from '../connect-button';
import IntegrationCard from './integration-card';

const Latchel = () => {
	const { latchelIntegrations } = useLatchelIntegrations();

	if (!latchelIntegrations.length) {
		return (
			<>
				<ConnectLatchelButton label="Install" />
				<Spacer height={8} />
			</>
		);
	}

	const groupedIntegrations = _.groupBy(latchelIntegrations, 'api_key');

	return (
		<>
			{_.values(groupedIntegrations).map((integrations) => (
				<IntegrationCard
					key={integrations[0].api_key}
					integrations={integrations}
				/>
			))}

			<ConnectLatchelButton label="Connect More Teams" />

			<Spacer height={8} />
		</>
	);
};

export default Latchel;
