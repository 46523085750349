import FormTextField from 'components/form-text-field';

interface Props {
	value: string;
	setValue: (value: string) => void;
	error?: string;
}

export default ({ value, setValue, error }: Props) => {
	return (
		<FormTextField
			title="Permission Group Name"
			placeholder="Add a name for your Permission Group"
			helperText={error}
			error={!!error}
			value={value}
			onChange={(e) => setValue(e.target.value)}
			required
			inputProps={{ maxLength: 50 }}
		/>
	);
};
