import {
	Checkbox,
	TableCell,
	TableHead,
	TableRow,
	TableSortLabel,
} from '@mui/material';
import { APIUnit } from '@rentcheck/types';
import { useDispatch } from 'react-redux';

import { useTypedSelector } from 'store/reducers/rootReducer';

import { Utils } from '@rentcheck/biz';
import ActionsMenu from './actions-menu';

interface Props {
	selectedIds: string[];
	setSelectedIds: (ids: string[]) => void;
	selectAllIds: () => void;
	units: APIUnit[];
}

const headers = [
	{ id: 'address', title: 'Address', sortable: true },
	{ id: 'city', title: 'City', sortable: false },
	{ id: 'zip_code', title: 'ZIP Code', sortable: false },
	{ id: 'property_type', title: 'Type', sortable: false },
	{ id: 'team', title: 'Team', sortable: false },
	{
		id: 'last_completed_inspection',
		title: 'Last Inspection Completed',
		sortable: true,
	},
];

const UnitTableHeader = ({
	selectedIds,
	setSelectedIds,
	selectAllIds,
	units,
}: Props) => {
	const dispatch = useDispatch();

	const sortBy = useTypedSelector(
		(state) => state.properties.filters.units.sortBy
	);
	const sortType = useTypedSelector(
		(state) => state.properties.filters.units.sortType
	);

	const switchSortDirection = () => {
		dispatch({
			type: 'TOGGLE_UNITS_SORT_TYPE',
		});
	};

	const handleSort = (headerId: string, sortable: boolean) => {
		if (!sortable) return;

		if (headerId === sortBy) {
			return switchSortDirection();
		}

		dispatch({
			type: 'SET_UNITS_SORT_BY',
			value: headerId,
		});
	};

	const checked = selectedIds.length === units.length && selectedIds.length > 0;

	const indeterminate =
		selectedIds.length > 0 && selectedIds.length !== units.length;

	const disableDeletion = units.some(
		(unit) =>
			Utils.Properties.propertyIsStillInSync(unit) &&
			selectedIds.includes(unit.id)
	);

	return (
		<TableHead>
			<TableRow>
				<TableCell>
					<Checkbox
						id="checkbox-unit-header"
						size="small"
						onChange={selectAllIds}
						indeterminate={indeterminate}
						checked={checked}
					/>
				</TableCell>
				{headers.map((h) => (
					<TableCell key={h.id}>
						<TableSortLabel
							active={sortBy === h.id}
							hideSortIcon={!h.sortable}
							direction={sortType}
							onClick={() => handleSort(h.id, h.sortable)}>
							{h.title}
						</TableSortLabel>
					</TableCell>
				))}
				<TableCell align="center">
					<ActionsMenu
						unitIds={selectedIds}
						setUnitIds={setSelectedIds}
						disableDeletion={disableDeletion}
					/>
				</TableCell>
			</TableRow>
		</TableHead>
	);
};

export default UnitTableHeader;
