import { muiTheme } from 'theme';
import { v4 as uuid } from 'uuid';
import { Dispatch } from '../../types/Dispatch';

export interface Payload {
	id: string;
	title: string;
}

export type Snackbar = Payload;

export type SnackbarActionType =
	| 'SHOW_SNACKBAR'
	| 'SHOW_SUCCESS_SNACKBAR'
	| 'SHOW_PROGRESS_SNACKBAR'
	| 'SHOW_ERROR_SNACKBAR'
	| 'HIDE_SNACKBAR';

export const actionTypes: { [index: string]: SnackbarActionType } = {
	SHOW_SNACKBAR: 'SHOW_SNACKBAR',
	SHOW_SUCCESS_SNACKBAR: 'SHOW_SUCCESS_SNACKBAR',
	SHOW_PROGRESS_SNACKBAR: 'SHOW_PROGRESS_SNACKBAR',
	SHOW_ERROR_SNACKBAR: 'SHOW_ERROR_SNACKBAR',
	HIDE_SNACKBAR: 'HIDE_SNACKBAR',
};

const show = (
	type: SnackbarActionType,
	payload: Omit<Payload, 'id'>,
	dispatch: Dispatch
) => {
	const snackbarPayload: Payload = { ...payload, id: uuid() };

	dispatch({
		type,
		snackbarPayload,
	});

	if (type !== actionTypes.SHOW_PROGRESS_SNACKBAR) {
		setTimeout(
			() => dispatch(hideSnackbar(snackbarPayload.id)),
			muiTheme.components?.MuiSnackbar?.defaultProps?.autoHideDuration ?? 3000
		);
	}

	return snackbarPayload;
};

export const showSnackbar = (title: string) => {
	return async (dispatch: Dispatch): Promise<Payload> => {
		return show(actionTypes.SHOW_SNACKBAR, { title }, dispatch);
	};
};

export const showSuccess = (title: string) => {
	return async (dispatch: Dispatch): Promise<Payload> => {
		return show(actionTypes.SHOW_SUCCESS_SNACKBAR, { title }, dispatch);
	};
};

export const showProgress = (title: string) => {
	return async (dispatch: Dispatch): Promise<Payload> => {
		return show(actionTypes.SHOW_PROGRESS_SNACKBAR, { title }, dispatch);
	};
};

export const showError = (
	title:
		| string
		| Error = 'Something went wrong. If the issue persists please contact support.'
) => {
	return async (dispatch: Dispatch): Promise<Payload> => {
		return show(
			actionTypes.SHOW_ERROR_SNACKBAR,
			{ title: typeof title === 'string' ? title : title.message },
			dispatch
		);
	};
};

export const hideSnackbar = (id: string) => {
	return async (dispatch: Dispatch): Promise<void> => {
		dispatch({
			type: 'HIDE_SNACKBAR',
			id,
		});
	};
};
