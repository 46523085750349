import { DashboardApi, DashboardProperty } from '@rentcheck/api-frontend';
import { ApiInspection } from '@rentcheck/types';
import _ from 'lodash';
import { State as FilterState } from 'store/reducers/dashboard/filters';
import { DashboardListSelector } from 'store/reducers/dashboard/list-helpers';
import { ReduxFn } from 'types';

const pageSize = 20;

export const get = (filters: FilterState): ReduxFn => {
	const fn: ReduxFn = async (dispatch, getState) => {
		const activeProfile = getState().activeProfile;

		if (!activeProfile) throw new Error('No active profile');

		const data = await DashboardApi.get(filters);

		dispatch({ type: 'FETCHED_DASHBOARD_DATA', data });

		return data;
	};

	return fn;
};

const dataFromMergedInspections = (
	existingInspections: ApiInspection[],
	newInspections: ApiInspection[],
	offset: number
) => {
	if (offset !== 0) {
		return _.uniqBy([...existingInspections, ...newInspections], (i) => i.id);
	}

	return newInspections;
};

const getInspections = (
	selector: DashboardListSelector,
	offset: number,
	getFn: (page: number, pageSize: number) => Promise<ApiInspection[]>,
	actionType: string
) => {
	const fn: ReduxFn = async (dispatch, getState) => {
		const existingInspections: ApiInspection[] =
			_.get(getState(), `dashboard.list.${selector}`) ?? [];

		const page = Math.floor(offset / pageSize);

		const inspections = await getFn(page, pageSize);

		const data = dataFromMergedInspections(
			existingInspections,
			inspections,
			offset
		);

		dispatch({
			type: actionType,
			data,
		});

		return data;
	};

	return fn;
};

export const getAwaitingReview = (offset: number = 0) => {
	return getInspections(
		'awaitingReview',
		offset,
		DashboardApi.getAwaitingReview,
		'FETCHED_DASHBOARD_AWAITING_REVIEW'
	);
};

export const getUnassigned = (offset: number = 0) => {
	return getInspections(
		'unassigned',
		offset,
		DashboardApi.getUnassigned,
		'FETCHED_DASHBOARD_UNASSIGNED'
	);
};

export const getAwaitingSignature = (offset: number = 0) => {
	return getInspections(
		'awaitingSignature',
		offset,
		DashboardApi.getAwaitingSignature,
		'FETCHED_DASHBOARD_AWAITING_SIGNATURE'
	);
};

export const getAlmostDue = (offset: number = 0) => {
	return getInspections(
		'almostDue',
		offset,
		DashboardApi.getAlmostDue,
		'FETCHED_DASHBOARD_ALMOST_DUE'
	);
};

export const getUndeliveredEmails = (offset: number = 0) => {
	return getInspections(
		'almostDue',
		offset,
		DashboardApi.getUndeliveredEmails,
		'FETCHED_DASHBOARD_UNDELIVERED_EMAILS'
	);
};

export const getOverdue = (offset: number = 0) => {
	return getInspections(
		'overdue',
		offset,
		DashboardApi.getOverdue,
		'FETCHED_DASHBOARD_OVERDUE'
	);
};

export const getRevisionRequested = (offset: number = 0) => {
	return getInspections(
		'revisionRequested',
		offset,
		DashboardApi.getRevisionRequested,
		'FETCHED_DASHBOARD_REVISION_REQUESTED'
	);
};

export const getUnitsAtRisk = (offset: number = 0) => {
	const fn: ReduxFn = async (dispatch, getState) => {
		const existingProperties: DashboardProperty[] =
			_.get(getState(), 'dashboard.list.propertiesAtRisk') ?? [];

		const page = Math.floor(offset / pageSize);

		const newProperties = await DashboardApi.getAtRisk(page, pageSize);

		const data = _.uniqBy(
			[...existingProperties, ...newProperties],
			(i) => i.id
		);

		dispatch({
			type: 'FETCHED_DASHBOARD_PROPERTIES_AT_RISK',
			data,
		});

		return data;
	};

	return fn;
};
