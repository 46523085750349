export type TeammatesActionTypes =
	| 'SIGNOUT_SUCCESS'
	| 'IMPERSONATE'
	| 'UPDATE_USERS'
	| 'DELETE_USERS'
	| 'APPEND_USERS'
	| 'CREATED_USERS'
	| 'SET_USERS'
	| 'UPDATE_USERS_FILTERS'
	| 'UPDATE_PGS'
	| 'DELETE_PGS'
	| 'APPEND_PGS'
	| 'CREATED_PGS'
	| 'SET_PGS'
	| 'UPDATE_PGS_FILTERS';

export const actionTypes: { [index: string]: TeammatesActionTypes } = {
	SIGNOUT_SUCCESS: 'SIGNOUT_SUCCESS',
	IMPERSONATE: 'IMPERSONATE',
	UPDATE_USERS: 'UPDATE_USERS',
	DELETE_USERS: 'DELETE_USERS',
	APPEND_USERS: 'APPEND_USERS',
	CREATED_USERS: 'CREATED_USERS',
	SET_USERS: 'SET_USERS',
	UPDATE_USERS_FILTERS: 'UPDATE_USERS_FILTERS',
	UPDATE_PGS: 'UPDATE_PGS',
	DELETE_PGS: 'DELETE_PGS',
	APPEND_PGS: 'APPEND_PGS',
	CREATED_PGS: 'CREATED_PGS',
	SET_PGS: 'SET_PGS',
	UPDATE_PGS_FILTERS: 'UPDATE_PGS_FILTERS',
};

export * as PermissionGroups from './permission-groups';
export * as Users from './users';
