import { Button, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

import { errors } from 'assets';
import { CenteredColumn, CenteredRow, Screens } from 'components';
import { spacingPx } from 'theme';

const InspectionDeleted = () => {
	return (
		<Screens.Container>
			<CenteredColumn style={{ height: '70vh', justifyContent: 'center' }}>
				<CenteredRow>
					<Illustration
						src={errors.inspectionNotFound}
						alt="inspection-not-found"
					/>
				</CenteredRow>

				<Typography variant="h2" align="center" style={{ margin: 10 }}>
					Oops. That page doesn't
					<br />
					exist.
				</Typography>

				<Typography align="center">
					The page you are looking for doesn't exist or has been moved.
				</Typography>

				<RouterLink to="/">
					<Button sx={{ mt: 4 }}>Back to Homepage</Button>
				</RouterLink>
			</CenteredColumn>
		</Screens.Container>
	);
};

const Illustration = styled.img`
	height: ${spacingPx(63)};
	resize-mode: contain;
`;

export default InspectionDeleted;
