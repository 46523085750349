import {
	ApiInspectionEvent,
	InspectionEventStatusChangedMetadata,
} from '@rentcheck/types';

import BaseTimelineEvent from '../base-event';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

interface Props {
	event: ApiInspectionEvent;
}

export default ({ event }: Props) => {
	const metadata = event.metadata as InspectionEventStatusChangedMetadata;

	return (
		<BaseTimelineEvent
			icon={solid('clipboard-list-check')}
			title={'Inspection marked as completed'}
			subtitle={`Updated by ${metadata.changed_by_name}`}
			timestamp={event.timestamp}
		/>
	);
};
