import { Rules } from '@rentcheck/biz';
import { ApiInspection, ApiInspectionWithTemplate } from '@rentcheck/types';
import MenuItem from 'components/menu-item';
import { useDispatch } from 'react-redux';
import { InspectionActions, SnackbarActions } from 'store/actions';
import { Snackbar } from 'store/actions/snackbar-actions';
import { Dispatch } from 'types';
import { postMessageToNativeApp } from 'utils/helpers';

interface Props {
	inspection: ApiInspection;
	onSelected: () => void;
}

export default ({ inspection, onSelected }: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const handleClick = async () => {
		onSelected();

		const progressSnackbar: Snackbar = await dispatch(
			SnackbarActions.showProgress('Re-opening inspection...')
		);

		dispatch(InspectionActions.reopenInspection(inspection)).then(
			(data: ApiInspectionWithTemplate) => {
				dispatch(SnackbarActions.hideSnackbar(progressSnackbar.id));
				dispatch(
					SnackbarActions.showSuccess('Inspection re-opened successfully!')
				);

				postMessageToNativeApp({
					type: 'reopened-inspection',
					inspection: data,
				});
			}
		);
	};

	if (!Rules.Inspections.canReOpen(inspection)) {
		return null;
	}

	return <MenuItem onClick={handleClick}>Re-open inspection</MenuItem>;
};
