import { ApiPermissionGroupDigest } from '@rentcheck/types';

import { actionTypes, TeammatesActionTypes } from '../../actions/teammates';

export type State = {
	data: ApiPermissionGroupDigest[];
	totalResults: number;
};

type Action = {
	type: TeammatesActionTypes;
	value: ApiPermissionGroupDigest[];
	totalResults?: number;
};

const initialState: State = {
	data: [],
	totalResults: 0,
};

export default (
	state: State = initialState,
	{ type, value, totalResults }: Action
) => {
	switch (type) {
		case actionTypes.SIGNOUT_SUCCESS:
		case actionTypes.IMPERSONATE:
			return initialState;

		case actionTypes.UPDATE_PGS:
			return {
				...state,
				data: state.data.map((permissionGroup) => {
					const updatedPermissionGroup = value.find(
						(u) => u.id === permissionGroup.id
					);
					return updatedPermissionGroup || permissionGroup;
				}),
			};

		case actionTypes.APPEND_PGS:
			return {
				...state,
				data: [...state.data, ...value],
			};

		case actionTypes.CREATED_PGS: {
			const newPermissionGroups = value.filter(
				(permissionGroup) =>
					!state.data.find((u) => u.id === permissionGroup.id)
			);
			const updatedPermissionGroups = state.data.map((permissionGroup) => {
				const updatedPermissionGroup = value.find(
					(u) => u.id === permissionGroup.id
				);
				return updatedPermissionGroup || permissionGroup;
			});

			return {
				data: [...newPermissionGroups, ...updatedPermissionGroups],
				totalResults: state.totalResults + value.length,
			};
		}

		case actionTypes.SET_PGS:
			return {
				...state,
				data: value,
				totalResults: totalResults ?? value.length,
			};

		case actionTypes.DELETE_PGS:
			return {
				...state,
				data: state.data.filter(
					(permissionGroup) => !value.find((u) => u.id === permissionGroup.id)
				),
				totalResults: state.totalResults - value.length,
			};

		default:
			return state;
	}
};
