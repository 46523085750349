import { Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { ActionsChip, Spacer } from 'components';
import { MaintenanceActions, ModalFlowActions } from 'store/actions';
import { Dispatch } from 'types';
import { numberAndPluralizeWord } from 'utils/helpers';

interface Props {
	children?: React.ReactNode | React.ReactNodeArray;
	ids: string[];
	setSelectedIds?: (ids: string[]) => void;
}

const ActionsMenu = ({ children, ids, setSelectedIds }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const handleClick = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		setAnchorEl(e.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleDelete = () => {
		handleClose();

		if (ids.length === 0) {
			return;
		}

		const body =
			'Are you sure you want to delete these reports? ' +
			`${numberAndPluralizeWord(ids.length, 'report')} ` +
			'will be deleted and this update is irreversible.';

		dispatch(
			ModalFlowActions.showConfirmationModal({
				title: 'Are you sure?',
				body1: [body],
				body2: [],
				buttons: [
					ModalFlowActions.cancelButton(dispatch),
					{
						title: 'Delete',
						color: 'error',
						variant: 'contained',
						onClick: deleteReports,
					},
				],
			})
		);
	};

	const deleteReports = async () => {
		dispatch(ModalFlowActions.closeConfirmationModal());
		dispatch(MaintenanceActions.reports.remove(ids)).then(() =>
			setSelectedIds?.([])
		);
	};

	return (
		<>
			<div onClick={handleClick}>
				{children ?? (
					<ActionsChip
						ids={ids}
						openMenu={(e: React.MouseEvent<HTMLElement>) => handleClick(e)}
					/>
				)}
			</div>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={!!anchorEl}
				onClose={handleClose}>
				<MenuItem id="bulk-delete-button" onClick={handleDelete}>
					Delete
					<Spacer width={4} />
				</MenuItem>
			</Menu>
		</>
	);
};

export default ActionsMenu;
