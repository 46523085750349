import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { InspectionStatusDisplay } from '@rentcheck/types';
import SegmentChip from 'components/segment-chip';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/reducers/rootReducer';

export default () => {
	const dispatch = useDispatch();

	const filters = useTypedSelector((state) => state.inspections.filters);

	const reviewStatuses: InspectionStatusDisplay[] = [
		'Awaiting Review',
		'Revision Review',
	];

	const handleClick = () => {
		dispatch({ type: 'RESET_INSPECTION_FILTERS' });
		dispatch({
			type: 'SET_INSPECTION_STATUS_FILTER',
			value: {
				condition: 'any_of',
				value: reviewStatuses,
			},
		});
	};

	const selected =
		_.xor(filters.inspectionStatus?.value, reviewStatuses).length === 0 &&
		!filters.inspectionTemplate?.value.length &&
		!filters.team?.value.length &&
		!filters.dueDateRange &&
		!filters.completedDateRange &&
		!filters.archived;

	return (
		<SegmentChip
			variant="outlined"
			icon={solid('file-signature')}
			label="Needs Review"
			title="Needs Review"
			onClick={handleClick}
			color="success"
			selected={selected}
		/>
	);
};
