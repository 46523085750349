import { useLocation } from 'react-router-dom';
import AlmostDue from './almost-due';
import AwaitingReview from './awaiting-review';
import AwaitingSignature from './awaiting-signature';
import Overdue from './overdue';
import RevisionRequested from './revision-requested';
import Undelivered from './undelivered';
import UnitsAtRisk from './units-at-risk';
import Unassigned from './unassigned';

const DetailModals = () => {
	const location = useLocation();

	if (!location.pathname.startsWith('/dashboard/')) {
		return null;
	}

	return (
		<>
			<AwaitingReview />
			<AlmostDue />
			<Overdue />
			<Undelivered />
			<RevisionRequested />
			<AwaitingSignature />
			<UnitsAtRisk />
			<Unassigned />
		</>
	);
};

export default DetailModals;
