import { Inspection } from '@rentcheck/api-frontend';
import _ from 'lodash';

export interface State {
	data: Inspection[];
	total: number;
}

export const initialState: State = {
	data: [],
	total: 0,
};

type Action = {
	type: string;
	value?: number;
	inspection?: Inspection;
	inspections?: Inspection[];
};

const upsertInspectionInArray = (
	inspection: Inspection,
	array: Inspection[]
) => {
	const index = array.findIndex((i) => i.id === inspection.id);

	if (index < 0) {
		return [inspection, ...array];
	}

	const response = [...array];
	response[index] = inspection;
	return response;
};

const deleteInspectionFromArray = (
	inspection: Inspection,
	array: Inspection[]
) => {
	return array.filter((i) => i.id !== inspection.id);
};

const mergeInspections = (
	originalInspections: Inspection[],
	newInspections: Inspection[]
) => {
	return originalInspections.map(
		(oi) => newInspections.find((ni) => ni.id === oi.id) ?? oi
	);
};

const reducer = (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case 'SIGNOUT_SUCCESS':
		case 'IMPERSONATE':
			return initialState;

		case 'DELETE_INSPECTION':
		case 'ARCHIVED_INSPECTION':
		case 'UNARCHIVED_INSPECTION':
			if (!action.inspection) return state;
			return {
				...state,
				data: deleteInspectionFromArray(action.inspection, state.data),
			};

		case 'DELETE_INSPECTIONS':
			if (!action.inspections) return state;
			return {
				...state,
				data: state.data.filter(
					(i: any) => !action?.inspections?.includes(i.id)
				),
			};

		case 'UPDATED_INSPECTIONS':
			if (!action.inspections) return state;

			return {
				...state,
				data: mergeInspections(state.data, action.inspections ?? []),
			};

		//case 'CREATE_INSPECTION':
		case 'FETCHED_INSPECTION':
			if (!action.inspection) return state;
			return {
				...state,
				data: upsertInspectionInArray(action.inspection, state.data),
			};

		case 'FETCHED_INITIAL_INSPECTIONS':
			if (!action.inspections) return state;
			return { ...state, data: action.inspections };

		case 'FETCHED_INSPECTIONS_PAGE':
			if (!action.inspections) return state;
			return {
				...state,
				data: _.uniqBy([...state.data, ...action.inspections], (e) => e.id),
			};

		case 'SET_TOTAL_INSPECTIONS':
			if (!action.value) return state;
			return {
				...state,
				total: action.value,
			};

		default:
			return state;
	}
};

export default reducer;
