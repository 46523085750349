import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ApiInspection, Feature } from '@rentcheck/types';
import ToggleButton from 'components/toggle-button';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FeatureActions, SnackbarActions } from 'store/actions';
import { Dispatch } from 'types';

interface Props {
	feature: Feature;
	inspection: ApiInspection;
	buttonSx?: React.CSSProperties;
}

export default ({ feature, buttonSx, inspection }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const [loading, setLoading] = useState(false);

	const handleApproveEdit = () => {
		setLoading(true);

		dispatch(
			FeatureActions.updateFeature(feature.id, {
				edited_by_reviewer: false,
			})
		)
			.then(() => dispatch(SnackbarActions.showSuccess('Edits Approved')))
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	if (!inspection.review) {
		return null;
	}

	if (!feature.edited_by_reviewer) {
		return null;
	}

	return (
		<ToggleButton
			selected
			loading={loading}
			onClick={handleApproveEdit}
			buttonSx={buttonSx}
			icon={regular('user-pen')}
			tooltip="Approve Edits"
		/>
	);
};
