import { DialogActions, Button } from '@mui/material';

interface Props {
	renderAlmostThereModal: boolean;
	showRenderInfo: () => void;
	handleClose: () => void;
	type: 'Errors' | 'Warnings';
}

const Actions = ({
	renderAlmostThereModal,
	showRenderInfo,
	handleClose,
	type,
}: Props) => {
	if (!renderAlmostThereModal) {
		return (
			<DialogActions sx={{ justifyContent: 'flex-end' }}>
				<Button variant="contained" onClick={handleClose}>
					Done
				</Button>
			</DialogActions>
		);
	}

	return (
		<DialogActions>
			<Button variant="text" color="secondary" onClick={handleClose}>
				Done
			</Button>

			<Button variant="text" color="primary" onClick={showRenderInfo}>
				View {type}
			</Button>
		</DialogActions>
	);
};

export default Actions;
