import { Dialog } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { DashboardActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';
import Title from './common/dialog-title';
import PropertiesTable from './common/properties-table';

const UnitsAtRisk = () => {
	const location = useLocation();
	const history = useHistory();
	const dispatch: Dispatch = useDispatch();

	const open = location.pathname.startsWith('/dashboard/units-at-risk');

	const dashboardData = useTypedSelector((state) => state.dashboard.data);
	const dashboardItems = useTypedSelector(
		(state) => state.dashboard.list.propertiesAtRisk
	);

	const handleClose = () => {
		history.push('/dashboard');
	};

	const handleFetch = (from: number) => {
		return dispatch(DashboardActions.getUnitsAtRisk(from));
	};

	return (
		<Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
			<Title title="Units" chipTitle="Units at Risk" />
			<PropertiesTable
				open={open}
				data={dashboardItems ?? []}
				fetch={handleFetch}
				total={dashboardData?.todo_list.properties_at_risk_count ?? 0}
			/>
		</Dialog>
	);
};

export default UnitsAtRisk;
