import { Paper, Table, TableBody, TableContainer } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { InspectionsActions, SnackbarActions } from 'store/actions';

import { InfiniteScroll } from 'components';
import _ from 'lodash';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';

import TableHeader from './header';
import InspectionTableRow from './row';
import FiltersSkeleton from './skeleton-filters';

import { State as FiltersState } from 'store/reducers/inspections/filters';
import EmptyState from '../empty-state';
import { getFilterDependencies } from './utils';

interface Props {
	selectedIds: string[];
	setSelectedIds: (ids: string[]) => void;
}

const InspectionsTable = ({ selectedIds, setSelectedIds }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const filters = useTypedSelector((state) => state.inspections.filters);
	const inspections = useTypedSelector(
		(state) => state.inspections.inspections.data
	);

	const [skippedFirstFetch, setSkippedFirstFetch] = useState(false);
	const [loadingFilters, setLoadingFilters] = useState(false);

	useEffect(() => {
		if (!skippedFirstFetch) {
			setSkippedFirstFetch(true);
			return;
		}

		fetchInspections(filters);
	}, [...getFilterDependencies(filters)]);

	const debouncedFetchInspections = _.debounce((filters: FiltersState) => {
		setSelectedIds([]);
		setLoadingFilters(true);

		dispatch(InspectionsActions.getAll(0, filters))
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => {
				setLoadingFilters(false);
			});
	}, 300);

	const fetchInspections = useCallback((filters: FiltersState) => {
		setLoadingFilters(true);
		debouncedFetchInspections(filters);
	}, []);

	const handleSelectAll = () => {
		if (selectedIds.length === inspections.length) {
			return setSelectedIds([]);
		}

		setSelectedIds(inspections.map((i) => i.id));
	};

	const handleSelectId = (id: string) => {
		setSelectedIds(_.xor(selectedIds, [id]));
	};

	if (inspections.length === 0 && !loadingFilters) {
		return <EmptyState />;
	}

	return (
		<div>
			<TableContainer component={Paper}>
				<FiltersSkeleton loading={loadingFilters} />
				<Table sx={{ minWidth: 650 }}>
					<TableHeader
						selectedIds={selectedIds}
						setSelectedIds={setSelectedIds}
						selectAllIds={handleSelectAll}
						inspections={inspections}
					/>
					<TableBody>
						{inspections.map((row, index) => (
							<InspectionTableRow
								index={index}
								key={row.id}
								inspection={row}
								selectedIds={selectedIds}
								setSelectedIds={setSelectedIds}
								selectId={handleSelectId}
							/>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			{!loadingFilters && (
				<InfiniteScroll
					objectName="Inspections"
					filterState={filters}
					action={InspectionsActions}
					offset={inspections.length}
				/>
			)}
		</div>
	);
};

export default InspectionsTable;
