import { Skeleton } from '@mui/material';
import { Column, Row, SpacedRow, Spacer } from 'components';

export default () => {
	return (
		<Column>
			<Row style={{ justifyContent: 'flex-end' }}>
				<Skeleton variant="circular" width={32} height={32} />
				<Spacer width={2} />
				<Skeleton variant="circular" width={32} height={32} />
				<Spacer width={2} />
				<Skeleton variant="circular" width={32} height={32} />
				<Spacer width={2} />
				<Skeleton variant="circular" width={32} height={32} />
				<Spacer width={2} />
				<Skeleton variant="circular" width={32} height={32} />
			</Row>
			<Skeleton variant="text" width={200} height={60} />
			<Skeleton variant="text" width={300} height={30} />

			<Spacer height={8} />
			<Skeleton variant="text" width={250} height={30} />
			<Spacer height={8} />

			<Skeleton variant="text" width="100%" height={60} />
			{'skeleton'.split('').map((_, i) => (
				<SpacedRow key={i} style={{ width: '100%', height: 55 }}>
					<Skeleton variant="text" width="10%" height={30} />
					<Skeleton variant="text" width="15%" height={30} />
					<Skeleton variant="text" width="20%" height={30} />
					<Skeleton variant="text" width="20%" height={30} />
					<Skeleton variant="text" width="10%" height={30} />
					<Skeleton variant="text" width="5%" height={30} />
					<Skeleton variant="text" width="5%" height={30} />
					<Skeleton variant="text" width="5%" height={30} />
				</SpacedRow>
			))}
		</Column>
	);
};
