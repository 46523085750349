import { Checkbox, Divider, Typography } from '@mui/material';
import { Column, SpacedRow, Spacer } from 'components';

interface Props {
	shouldUpdateUnit: boolean;
	setShouldUpdateUnit: (value: boolean) => void;
}

export default ({ shouldUpdateUnit, setShouldUpdateUnit }: Props) => {
	return (
		<Column>
			<Spacer height={5} />
			<Typography variant="overline">Unit updates</Typography>
			<Spacer height={3} />

			<SpacedRow>
				<Typography variant="body2" color="#00000099">
					Update unit details by adding or removing rooms/areas added or marked
					as N/A during the inspection.
				</Typography>
				<Checkbox
					checked={shouldUpdateUnit}
					onChange={() => setShouldUpdateUnit(!shouldUpdateUnit)}
				/>
			</SpacedRow>
			<Spacer height={4} />
			<Divider />
		</Column>
	);
};
