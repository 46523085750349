import {
	ApiInspectionWithTemplate,
	ApiMaintenanceReport,
} from '@rentcheck/types';
import {
	CloneInspectionMetadata,
	ConfirmationModalButton,
	ConfirmationModalData,
	CreateInspectionMetadata,
	CreateInspectionTemplateMetadata,
	CreateMaintenanceReportMetadata,
	CreatePermissionGroupMetadata,
	CreateTeamMetadata,
	CreateWorkOrderMetadata,
	EditDueDateMetadata,
	EditFeatureMetadata,
	EditRecipientsMetadata,
	ErrorModalData,
	FeatureDetailsMetadata,
	FeatureImageMetadata,
	FlagFeatureMetadata,
	FlagImagesMetadata,
	InspectionRevisionMetadata,
	InspectionTimelineMetadata,
	InviteUsersMetadata,
	ManageAccessMetadata,
	RemoveFlagMetadata,
	RequestSignaturesMetadata,
	ResidentMetadata,
	SendReminderMetadata,
	ShareMetadata,
	ShowEditUsersPermissionGroupsMetadata,
	ShowInspectionTemplateMetadata,
	ShowRevisionNotesMetadata,
} from 'store/reducers/modal-flows';
import { Dispatch } from 'types';

export type ActionType =
	| 'SHOW_CLONE_INSPECTION'
	| 'SHOW_CREATE_INSPECTION'
	| 'SHOW_FLAG_FEATURE'
	| 'SHOW_RESIDENT'
	| 'SHOW_CREATE_WORK_ORDER_LATCHEL'
	| 'SHOW_CREATE_WORK_ORDER_YARDI'
	| 'SHOW_CREATE_WORK_ORDER_APPFOLIO'
	| 'SHOW_CREATE_WORK_ORDER_JENARK'
	| 'SHOW_CREATE_WORK_ORDER_RENT_MANAGER'
	| 'SHOW_CREATE_MAINTENANCE_REPORT'
	| 'SHOW_EDIT_DUE_DATE'
	| 'SHOW_EDIT_RECIPIENTS'
	| 'SHOW_SEND_REMINDER'
	| 'SHOW_FEATURE_DETAILS'
	| 'SHOW_FEATURE_IMAGE'
	| 'SHOW_FLAG_IMAGES'
	| 'SHOW_EDIT_FEATURE'
	| 'SHOW_INSPECTION_REVISION'
	| 'SHOW_CREATE_TEAM'
	| 'CLOSE_CURRENT'
	| 'SHOW_CONFIRMATION_MODAL'
	| 'CLOSE_CONFIRMATION_MODAL'
	| 'CONFIRMATION_MODAL_LOADING'
	| 'REMOVE_FLAG_FROM_MODALS'
	| 'SHOW_ERROR_MODAL'
	| 'CLOSE_ERROR_MODAL'
	| 'SHOW_SHARE_INSPECTION_MODAL'
	| 'SHOW_SHARE_MAINTENANCE_REPORT_MODAL'
	| 'SHOW_UPGRADE_SUBSCRIPTION_MODAL'
	| 'SHOW_CREATE_INSPECTION_TEMPLATE'
	| 'SHOW_INSPECTION_TEMPLATE'
	| 'SHOW_INVITE_USERS'
	| 'SHOW_REVISION_NOTES'
	| 'SHOW_MANAGE_ACCESS'
	| 'SHOW_EDIT_USERS_PERMISSION_GROUPS'
	| 'SHOW_CREATE_PERMISSION_GROUP'
	| 'SHOW_REQUEST_SIGNATURES'
	| 'SHOW_INSPECTION_TIMELINE';

export const actions: { [index: string]: ActionType } = {
	SHOW_CLONE_INSPECTION: 'SHOW_CLONE_INSPECTION',
	SHOW_CREATE_INSPECTION: 'SHOW_CREATE_INSPECTION',
	SHOW_FLAG_FEATURE: 'SHOW_FLAG_FEATURE',
	SHOW_RESIDENT: 'SHOW_RESIDENT',
	SHOW_CREATE_WORK_ORDER_LATCHEL: 'SHOW_CREATE_WORK_ORDER_LATCHEL',
	SHOW_CREATE_WORK_ORDER_YARDI: 'SHOW_CREATE_WORK_ORDER_YARDI',
	SHOW_CREATE_WORK_ORDER_APPFOLIO: 'SHOW_CREATE_WORK_ORDER_APPFOLIO',
	SHOW_CREATE_WORK_ORDER_JENARK: 'SHOW_CREATE_WORK_ORDER_JENARK',
	SHOW_CREATE_WORK_ORDER_RENT_MANAGER: 'SHOW_CREATE_WORK_ORDER_RENT_MANAGER',
	SHOW_CREATE_MAINTENANCE_REPORT: 'SHOW_CREATE_MAINTENANCE_REPORT',
	SHOW_EDIT_DUE_DATE: 'SHOW_EDIT_DUE_DATE',
	SHOW_SEND_REMINDER: 'SHOW_SEND_REMINDER',
	SHOW_FEATURE_DETAILS: 'SHOW_FEATURE_DETAILS',
	SHOW_FEATURE_IMAGE: 'SHOW_FEATURE_IMAGE',
	SHOW_FLAG_IMAGES: 'SHOW_FLAG_IMAGES',
	SHOW_EDIT_FEATURE: 'SHOW_EDIT_FEATURE',
	SHOW_INSPECTION_REVISION: 'SHOW_INSPECTION_REVISION',
	SHOW_EDIT_RECIPIENTS: 'SHOW_EDIT_RECIPIENTS',
	SHOW_CREATE_TEAM: 'SHOW_CREATE_TEAM',
	CLOSE_CURRENT: 'CLOSE_CURRENT',
	SHOW_CONFIRMATION_MODAL: 'SHOW_CONFIRMATION_MODAL',
	CLOSE_CONFIRMATION_MODAL: 'CLOSE_CONFIRMATION_MODAL',
	CONFIRMATION_MODAL_LOADING: 'CONFIRMATION_MODAL_LOADING',
	REMOVE_FLAG_FROM_MODALS: 'REMOVE_FLAG_FROM_MODALS',
	SHOW_ERROR_MODAL: 'SHOW_ERROR_MODAL',
	CLOSE_ERROR_MODAL: 'CLOSE_ERROR_MODAL',
	SHOW_SHARE_INSPECTION_MODAL: 'SHOW_SHARE_INSPECTION_MODAL',
	SHOW_SHARE_MAINTENANCE_REPORT_MODAL: 'SHOW_SHARE_MAINTENANCE_REPORT_MODAL',
	SHOW_UPGRADE_SUBSCRIPTION_MODAL: 'SHOW_UPGRADE_SUBSCRIPTION_MODAL',
	SHOW_CREATE_INSPECTION_TEMPLATE: 'SHOW_CREATE_INSPECTION_TEMPLATE',
	SHOW_INSPECTION_TEMPLATE: 'SHOW_INSPECTION_TEMPLATE',
	SHOW_INVITE_USERS: 'SHOW_INVITE_USERS',
	SHOW_REVISION_NOTES: 'SHOW_REVISION_NOTES',
	SHOW_MANAGE_ACCESS: 'SHOW_MANAGE_ACCESS',
	SHOW_EDIT_USERS_PERMISSION_GROUPS: 'SHOW_EDIT_USERS_PERMISSION_GROUPS',
	SHOW_CREATE_PERMISSION_GROUP: 'SHOW_CREATE_PERMISSION_GROUP',
	SHOW_REQUEST_SIGNATURES: 'SHOW_REQUEST_SIGNATURES',
	SHOW_INSPECTION_TIMELINE: 'SHOW_INSPECTION_TIMELINE',
};

export const cancelButton = (dispatch: Dispatch): ConfirmationModalButton => ({
	title: 'Cancel',
	color: 'secondary',
	variant: 'outlined',
	onClick: () => dispatch(closeConfirmationModal()),
});

export const showCloneInspectionModal = (data: CloneInspectionMetadata) => {
	return (dispatch: Dispatch) => {
		dispatch({ type: actions.SHOW_CLONE_INSPECTION, data });
	};
};

export const showFlagFeatureModal = (data: FlagFeatureMetadata) => {
	return (dispatch: Dispatch) => {
		dispatch({ type: actions.SHOW_FLAG_FEATURE, data });
	};
};

export const showInviteUsers = (data: InviteUsersMetadata) => {
	return (dispatch: Dispatch) => {
		dispatch({ type: actions.SHOW_INVITE_USERS, data });
	};
};

export const showCreatePermissionGroup = (
	data: CreatePermissionGroupMetadata
) => {
	return (dispatch: Dispatch) => {
		dispatch({ type: actions.SHOW_CREATE_PERMISSION_GROUP, data });
	};
};

export const showManageAccess = (data: ManageAccessMetadata) => {
	return (dispatch: Dispatch) => {
		dispatch({ type: actions.SHOW_MANAGE_ACCESS, data });
	};
};

export const showResidentModal = (data: ResidentMetadata) => {
	return (dispatch: Dispatch) => {
		dispatch({ type: actions.SHOW_RESIDENT, data });
	};
};

export const showCreateWorkOrderModal = (data: CreateWorkOrderMetadata) => {
	return (dispatch: Dispatch) => {
		if (data.provider === 'Latchel') {
			return dispatch({ type: actions.SHOW_CREATE_WORK_ORDER_LATCHEL, data });
		}

		if (data.provider === 'Yardi') {
			return dispatch({ type: actions.SHOW_CREATE_WORK_ORDER_YARDI, data });
		}

		if (data.provider === 'AppFolio') {
			return dispatch({ type: actions.SHOW_CREATE_WORK_ORDER_APPFOLIO, data });
		}

		if (data.provider === 'Jenark') {
			return dispatch({ type: actions.SHOW_CREATE_WORK_ORDER_JENARK, data });
		}

		if (data.provider === 'Rent Manager') {
			return dispatch({
				type: actions.SHOW_CREATE_WORK_ORDER_RENT_MANAGER,
				data,
			});
		}
	};
};

export const showCreateMaintenanceReportModal = (
	data: CreateMaintenanceReportMetadata
) => {
	return (dispatch: Dispatch) => {
		dispatch({ type: actions.SHOW_CREATE_MAINTENANCE_REPORT, data });
	};
};

export const showCreateInspectionModal = (data: CreateInspectionMetadata) => {
	return (dispatch: Dispatch) => {
		dispatch({ type: actions.SHOW_CREATE_INSPECTION, data });
	};
};

export const showEditDueDateModal = (data: EditDueDateMetadata) => {
	return (dispatch: Dispatch) => {
		dispatch({ type: actions.SHOW_EDIT_DUE_DATE, data });
	};
};

export const showEditRecipientsModal = (data: EditRecipientsMetadata) => {
	return (dispatch: Dispatch) => {
		dispatch({ type: actions.SHOW_EDIT_RECIPIENTS, data });
	};
};

export const showSendReminderModal = (data: SendReminderMetadata) => {
	return (dispatch: Dispatch) => {
		dispatch({ type: actions.SHOW_SEND_REMINDER, data });
	};
};

export const closeCurrentModal = () => {
	return (dispatch: Dispatch) => {
		dispatch({ type: actions.CLOSE_CURRENT });
	};
};

export const showConfirmationModal = (data: ConfirmationModalData) => {
	return (dispatch: Dispatch) => {
		dispatch({ type: actions.SHOW_CONFIRMATION_MODAL, data });
	};
};

export const closeConfirmationModal = () => {
	return (dispatch: Dispatch) => {
		dispatch({ type: actions.CLOSE_CONFIRMATION_MODAL });
	};
};

export const showErrorModal = (data: ErrorModalData) => {
	return (dispatch: Dispatch) => {
		dispatch({ type: actions.SHOW_ERROR_MODAL, data });
	};
};

export const closeErrorModal = () => {
	return (dispatch: Dispatch) => {
		dispatch({ type: actions.CLOSE_ERROR_MODAL });
	};
};

export const setConfirmationModalLoading = (loading: boolean) => {
	return (dispatch: Dispatch) => {
		dispatch({ type: actions.CONFIRMATION_MODAL_LOADING, value: loading });
	};
};

export const showFeatureDetails = (data: FeatureDetailsMetadata) => {
	return (dispatch: Dispatch) => {
		dispatch({ type: actions.SHOW_FEATURE_DETAILS, data });
	};
};

export const showFeatureImage = (data: FeatureImageMetadata) => {
	return (dispatch: Dispatch) => {
		dispatch({ type: actions.SHOW_FEATURE_IMAGE, data });
	};
};

export const showFlagImages = (data: FlagImagesMetadata) => {
	return (dispatch: Dispatch) => {
		dispatch({ type: actions.SHOW_FLAG_IMAGES, data });
	};
};

export const showEditFeatureModal = (data: EditFeatureMetadata) => {
	return (dispatch: Dispatch) => {
		dispatch({ type: actions.SHOW_EDIT_FEATURE, data });
	};
};

export const showInspectionRevisionModal = (
	data: InspectionRevisionMetadata
) => {
	return (dispatch: Dispatch) => {
		dispatch({ type: actions.SHOW_INSPECTION_REVISION, data });
	};
};

export const showCreateTeamModal = (data: CreateTeamMetadata) => {
	return (dispatch: Dispatch) => {
		dispatch({ type: actions.SHOW_CREATE_TEAM, data });
	};
};

export const removeFlagFromModals = (data: RemoveFlagMetadata) => {
	return (dispatch: Dispatch) => {
		dispatch({ type: actions.REMOVE_FLAG_FROM_MODALS, data });
	};
};

export const showShareInspectionModal = (
	data: ShareMetadata<ApiInspectionWithTemplate>
) => {
	return (dispatch: Dispatch) => {
		dispatch({ type: actions.SHOW_SHARE_INSPECTION_MODAL, data });
	};
};

export const showShareMaintenanceReportModal = (
	data: ShareMetadata<ApiMaintenanceReport>
) => {
	return (dispatch: Dispatch) => {
		dispatch({ type: actions.SHOW_SHARE_MAINTENANCE_REPORT_MODAL, data });
	};
};

export const showUpgradeSubscriptionModal = () => {
	return (dispatch: Dispatch) => {
		dispatch({ type: actions.SHOW_UPGRADE_SUBSCRIPTION_MODAL });
	};
};

export const showCreateInspectionTemplateModal = (
	data: CreateInspectionTemplateMetadata
) => {
	return (dispatch: Dispatch) => {
		dispatch({ type: actions.SHOW_CREATE_INSPECTION_TEMPLATE, data });
	};
};

export const showInspectionTemplateModal = (
	data: ShowInspectionTemplateMetadata
) => {
	return (dispatch: Dispatch) => {
		dispatch({ type: actions.SHOW_INSPECTION_TEMPLATE, data });
	};
};

export const showRevisionNotesModal = (data: ShowRevisionNotesMetadata) => {
	return (dispatch: Dispatch) => {
		dispatch({ type: actions.SHOW_REVISION_NOTES, data });
	};
};
export const showEditUsersPermissionGroupsModal = (
	data: ShowEditUsersPermissionGroupsMetadata
) => {
	return (dispatch: Dispatch) => {
		dispatch({ type: actions.SHOW_EDIT_USERS_PERMISSION_GROUPS, data });
	};
};

export const showRequestSignaturesModal = (data: RequestSignaturesMetadata) => {
	return (dispatch: Dispatch) => {
		dispatch({ type: actions.SHOW_REQUEST_SIGNATURES, data });
	};
};

export const showInspectionTimelineModal = (
	data: InspectionTimelineMetadata
) => {
	return (dispatch: Dispatch) => {
		dispatch({ type: actions.SHOW_INSPECTION_TIMELINE, data });
	};
};
