import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { MenuItem, Typography } from '@mui/material';
import { ApiTenant } from '@rentcheck/types';
import { parsePhoneNumber } from 'awesome-phonenumber';
import { Column, Row, SpacedRow, Spacer } from 'components';
import IconButton, { IconButtonRef } from 'components/icon-button';
import { useEffect, useRef, useState } from 'react';
import ResidentCreate from '../resident-create';

interface Props {
	resident: ApiTenant;
	onAddLease: () => void;
	onRemoveResident: () => void;
}

const ResidentInfo = ({ resident, onAddLease, onRemoveResident }: Props) => {
	const [editing, setEditing] = useState(false);

	const iconButtonRef = useRef<IconButtonRef>(null);

	useEffect(() => {
		setEditing(false);
	}, [resident]);

	const handleEdit = () => {
		iconButtonRef.current?.close();
		setEditing(true);
	};

	const handleAddLease = () => {
		iconButtonRef.current?.close();
		onAddLease();
	};

	const handleRemoveResident = () => {
		iconButtonRef.current?.close();
		onRemoveResident();
	};

	return (
		<>
			<Column>
				<SpacedRow>
					<Typography variant="overline">Contact</Typography>
					<IconButton
						badgeCount={0}
						ref={iconButtonRef}
						icon={solid('ellipsis-vertical')}>
						<MenuItem onClick={handleEdit}>Edit</MenuItem>
						<MenuItem onClick={handleAddLease}>Add Lease</MenuItem>
						<MenuItem onClick={handleRemoveResident}>Remove Resident</MenuItem>
					</IconButton>
				</SpacedRow>

				<Typography variant="h6">
					{resident.first_name} {resident.last_name}
				</Typography>
				<Spacer height={2} />
				<Row>
					<Typography color="#666">Email:</Typography>
					<Spacer width={2} />
					<Typography>{resident.emails[0]}</Typography>
				</Row>
				<Spacer height={2} />
				<Row>
					<Typography color="#666">Phone:</Typography>
					<Spacer width={2} />
					{resident.phone_numbers.length === 0 && (
						<Typography
							style={{ textDecoration: 'underline', cursor: 'pointer' }}
							onClick={handleEdit}>
							Add phone number
						</Typography>
					)}
					{resident.phone_numbers.length > 0 && (
						<Typography>
							{parsePhoneNumber(resident.phone_numbers[0]).number
								?.international ?? resident.phone_numbers[0]}{' '}
						</Typography>
					)}
				</Row>
			</Column>

			{editing && <ResidentCreate resident={resident} />}
		</>
	);
};

export default ResidentInfo;
