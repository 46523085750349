import { Alert } from '@mui/material';
import { CenteredRow, Spacer } from 'components';
import { useLatchelIntegrations } from 'hooks/latchel-integrations';

import _ from 'lodash';

interface Props {
	apiKey: string;
	teamIds: string[];
}

const AlreadyLinkedNotice = ({ apiKey, teamIds }: Props) => {
	const { latchelIntegrations } = useLatchelIntegrations();

	if (!apiKey) {
		return null;
	}

	const alreadyLinkedIntegrations = _.uniqBy(
		latchelIntegrations.filter(
			(li) => teamIds.includes(li.team.id) && li.api_key !== apiKey
		),
		'team.id'
	);

	if (alreadyLinkedIntegrations.length === 0) {
		return null;
	}

	return (
		<>
			<Spacer height={8} />
			<CenteredRow>
				<Alert severity="info" sx={{ width: '80%' }}>
					Some of the selected teams are already linked to a different latchel
					account:{' '}
					{alreadyLinkedIntegrations
						.map((o) => o.team.internal_label)
						.join(', ')}
					.
					<br />
					<br />
					This operation will replace their existing integration.
				</Alert>
			</CenteredRow>
		</>
	);
};

export default AlreadyLinkedNotice;
