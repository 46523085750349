import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'types';

import Address from './Components/Address';
import BedroomsAndBathrooms from './Components/BedroomsAndBathrooms';
import Confirmation from './Components/Confirmation';
import CreatePropertyFooter from './Components/Footer';
import Paywall from './Components/PayWall';
import PropertyType from './Components/PropertyType';
import SelectRooms from './Components/SelectRooms';
import Team from './Components/Team';

import { DialogTitle } from '../..';
import ConfirmationModal from '../../Common/ConfirmationModal';
import Modal from '../../Common/Modal';

import {
	APIProperty,
	APIUnit,
	CreateUnitParams,
	UnitPropertyType,
} from '@rentcheck/types';
import { PropertiesActions, SnackbarActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { RoomNames, paywallLimit } from '../../../constants';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Dialog, DialogContent } from '@mui/material';
import { useTeams } from 'hooks/teams';
import { Analytics } from 'utils';
import { organizationIfOnlyOne } from 'utils/helpers';

interface Props {
	setModalVisible: (visible: boolean) => void;
	creationCallback: (property: APIProperty) => void;
}

export type CreatePropertyStep =
	| 'Property Address'
	| 'Property Type'
	| 'Bedrooms & Bathrooms'
	| 'Main Rooms'
	| 'Common Rooms'
	| 'Outside Rooms'
	| 'Select Team';

const defaultRoomSettings = {
	'Single-Family': ['Kitchen', 'Living Room', 'Dining Room', 'Exterior'],
	other: ['Kitchen', 'Living Room', 'Dining Room'],
};

const CreatePropertyModal = ({ setModalVisible, creationCallback }: Props) => {
	const { teams } = useTeams();

	const profile = useTypedSelector((state) => state.activeProfile);
	const units = useTypedSelector((state) => state.properties.units.data);
	const subscription = useTypedSelector((state) => state.subscription);
	const accountSettings = useTypedSelector((state) => state.accountSettings);

	const dispatch: Dispatch = useDispatch();

	const [exitModalVisible, setExitModalVisible] = useState(false);
	const [paywallModalVisible, setPaywallModalVisible] = useState(false);
	const [loading, setLoading] = useState(false);

	const [address, setAddress] = useState('');
	const [city, setCity] = useState('');
	const [zipcode, setZipcode] = useState('');
	const [propertyType, setPropertyType] = useState<UnitPropertyType>();
	const [bedrooms, setBedrooms] = useState(0);
	const [fullBathrooms, setFullBathrooms] = useState(0);
	const [halfBathrooms, setHalfBathrooms] = useState(0);
	const [roomNames, setRoomNames] = useState(defaultRoomSettings.other);
	const [organizationId, setOrganizationId] = useState(
		organizationIfOnlyOne(profile)?.id
	);

	const [stepHistory, setStepHistory] = useState<CreatePropertyStep[]>([
		'Property Address',
	]);
	const [property, setProperty] = useState<APIUnit | undefined>(undefined);

	useEffect(() => {
		if (units.length >= paywallLimit && !subscription) {
			setPaywallModalVisible(true);
		}
	}, [units]);

	useEffect(() => {
		if (propertyType === 'Single-Family') {
			setRoomNames(defaultRoomSettings['Single-Family']);
		} else {
			setRoomNames(defaultRoomSettings.other);
		}
	}, [propertyType]);

	const pushStep = (newStep: CreatePropertyStep) => {
		setStepHistory([...stepHistory, newStep]);
	};

	const backStep = () => {
		if (stepHistory.length <= 1) {
			return;
		}

		setStepHistory(stepHistory.slice(0, stepHistory.length - 1));
	};

	const handleCreateProperty = async () => {
		if (!propertyType) {
			return;
		}

		setLoading(true);

		const payload: CreateUnitParams['data'] = {
			address: address,
			city: city,
			zip_code: zipcode,
			property_type: propertyType,
			bedrooms: bedrooms,
			full_bathrooms: fullBathrooms,
			half_bathrooms: halfBathrooms,
			rooms: roomNames,
		};

		if (organizationId) {
			payload.team_id = organizationId;
		}

		dispatch(PropertiesActions.units.create(payload))
			.then((response) => {
				Analytics.trackEvent('created property', {
					address,
					city,
					zipcode,
					propertyType,
					propertyID: response.id,
				});

				setProperty(response);
			})
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	const showConfirmationOrSkip = () => {
		if (!address && !city && !zipcode) {
			setModalVisible(false);
		} else {
			setExitModalVisible(true);
		}
	};

	const handleCompleteOutside = () =>
		teams.length > 1 ? pushStep('Select Team') : handleCreateProperty();

	const renderContent = () => {
		const currentContentStep = stepHistory.slice(-1)[0];

		switch (currentContentStep) {
			case 'Property Address':
				return (
					<>
						<Address
							address={address}
							city={city}
							zipcode={zipcode}
							setAddress={setAddress}
							setCity={setCity}
							setZipcode={setZipcode}
						/>
						<CreatePropertyFooter
							leftClick={() => showConfirmationOrSkip()}
							rightClick={() => pushStep('Property Type')}
							leftLabel={'Cancel'}
							rightLabel={'Next: Unit Type'}
							disabled={!address || !city || !zipcode}
						/>
					</>
				);
			case 'Property Type':
				return (
					<>
						<PropertyType
							propertyType={propertyType}
							setValue={setPropertyType}
						/>
						<CreatePropertyFooter
							leftClick={backStep}
							rightClick={() => pushStep('Bedrooms & Bathrooms')}
							leftLabel={'Back'}
							rightLabel={'Next: Bed & Bath'}
							disabled={!propertyType}
						/>
					</>
				);
			case 'Bedrooms & Bathrooms':
				return (
					<>
						<BedroomsAndBathrooms
							bedrooms={bedrooms}
							fullBathrooms={fullBathrooms}
							halfBathrooms={halfBathrooms}
							setBedrooms={setBedrooms}
							setFullBathrooms={setFullBathrooms}
							setHalfBathrooms={setHalfBathrooms}
						/>
						<CreatePropertyFooter
							leftClick={backStep}
							rightClick={() => pushStep('Main Rooms')}
							leftLabel={'Back'}
							rightLabel={'Next: Additional Rooms'}
							disabled={false}
						/>
					</>
				);
			case 'Main Rooms':
				return (
					<>
						<SelectRooms
							roomNames={roomNames}
							options={[
								...RoomNames.main,
								...(accountSettings?.rooms.unit.map((r) => r.name) ?? []),
							]}
							setValue={setRoomNames}
						/>
						<CreatePropertyFooter
							leftClick={backStep}
							rightClick={() => pushStep('Common Rooms')}
							leftLabel={'Back'}
							rightLabel={'Next: Common Areas'}
							disabled={false}
						/>
					</>
				);
			case 'Common Rooms':
				return (
					<>
						<SelectRooms
							roomNames={roomNames}
							options={RoomNames.common}
							setValue={setRoomNames}
						/>
						<CreatePropertyFooter
							leftClick={backStep}
							rightClick={() => pushStep('Outside Rooms')}
							leftLabel={'Back'}
							rightLabel={'Next: Outside'}
							disabled={false}
						/>
					</>
				);
			case 'Outside Rooms':
				return (
					<>
						<SelectRooms
							roomNames={roomNames}
							options={RoomNames.outside}
							setValue={setRoomNames}
						/>
						<CreatePropertyFooter
							leftClick={backStep}
							rightClick={handleCompleteOutside}
							leftLabel={'Back'}
							rightLabel={teams.length > 1 ? 'Next: Team' : 'Complete Unit'}
							disabled={loading}
							loading={loading}
						/>
					</>
				);
			case 'Select Team':
				return (
					<>
						<Team
							organizationId={organizationId}
							setOrganizationId={setOrganizationId}
						/>
						<CreatePropertyFooter
							leftClick={backStep}
							rightClick={handleCreateProperty}
							leftLabel={'Back'}
							rightLabel={'Complete Unit'}
							disabled={loading || !organizationId}
							loading={loading}
						/>
					</>
				);
		}
	};

	if (property) {
		return (
			<Modal>
				<Confirmation
					address={address}
					dismissModal={() => setModalVisible(false)}
					showInspectionModal={() => creationCallback(property)}
				/>
			</Modal>
		);
	}

	if (paywallModalVisible) {
		return <Paywall cancel={() => setModalVisible(false)} />;
	}

	const currentStep = stepHistory.slice(-1)[0];

	return (
		<Dialog onClose={() => setModalVisible(false)} open>
			{!property && (
				<DialogTitle
					title="Add Unit"
					onClose={() => setModalVisible(false)}
					icon={solid('house')}
					bottomSubtitle={
						currentStep !== 'Property Address'
							? `${address}, ${city} ${zipcode}`
							: 'Fill out the details below to add a unit.'
					}
				/>
			)}
			<DialogContent>{renderContent()}</DialogContent>
			{exitModalVisible && (
				<ConfirmationModal
					title="Are you sure?"
					body="Do you want to exit and lose all your progress in creating this new unit?"
					cancelTitle="Go Back"
					confirmTitle="Exit & Close"
					destructive
					cancelPressed={() => setExitModalVisible(false)}
					confirmPressed={() => setModalVisible(false)}
				/>
			)}
		</Dialog>
	);
};

export { Address, BedroomsAndBathrooms, PropertyType, SelectRooms };
export default CreatePropertyModal;
