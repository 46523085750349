import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Paper, Typography } from '@mui/material';
import { AccountSettings, CustomRoom } from '@rentcheck/types';
import { SpacedRow } from 'components';
import IconButton from 'components/icon-button';
import { useState } from 'react';
import EditRoom from './edit-room';

interface Props {
	room: CustomRoom;
	editable?: boolean;
	variant?: keyof AccountSettings['rooms'];
	onRoomCreated?: (room: CustomRoom) => void;
}

export default ({ room, editable, variant, onRoomCreated }: Props) => {
	const [editing, setEditing] = useState(!room.name);

	const handleEdit = () => {
		setEditing(true);
	};

	const handleRoomCreated = (room: CustomRoom) => {
		onRoomCreated?.(room);
	};

	return (
		<Paper
			elevation={0}
			sx={{
				marginBottom: 1,
				backgroundColor: editable ? 'white' : 'transparent',
				border: '1px solid #0000001F',

				':hover': {
					boxShadow: editable ? '0px 1px 5px 0px #00000033' : '',

					'span:last-child': {
						opacity: 1,
					},
				},

				':last-child': {
					marginBottom: 0,
				},

				transitions: 'all 0.3s ease-in-out',

				'span:last-child': {
					opacity: 0,
					transitions: 'all 0.3s ease-in-out',
				},
			}}>
			<SpacedRow style={{ padding: 16 }}>
				{!editing && (
					<>
						<Typography variant="subtitle1" color="#4D4D4D">
							{room.name}
						</Typography>

						{editable && (
							<IconButton icon={regular('edit')} onClick={handleEdit} />
						)}

						{!editable && <span />}
					</>
				)}
				{editing && (
					<EditRoom
						setEditing={setEditing}
						room={room}
						variant={variant}
						onRoomCreated={handleRoomCreated}
					/>
				)}
			</SpacedRow>
		</Paper>
	);
};
