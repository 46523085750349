import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { firestore } from 'firebase/app';
import _ from 'lodash';
import moment from 'moment';
import hash from 'object-hash';
import path from 'path';
import queryString from 'query-string';
import { DayRange, DayValue } from 'react-modern-calendar-datepicker';

import { Inspection as APIFrontendInspection } from '@rentcheck/api-frontend';
import {
	AccountSettings,
	ApiInspection,
	APIProperty,
	ApiUser,
	EmbeddedWebAppMessage,
	Feature,
	Inspection,
	InspectionEdited,
	InspectionRating,
	InspectionStatusDisplay,
	InspectionStatusLabel,
	InspectionTemplate,
	MaintenanceFlag,
	Timestamp,
} from '@rentcheck/types';

import { DateRange } from 'store/reducers/dashboard/filters';
import AreasOrder from 'utils/areas-order';

import { Utils } from '@rentcheck/biz';
import { PROFILE_USER_TYPES } from '../constants';
import { encrypt } from './encryption';

export const organizationIfOnlyOne = (profile: ApiUser) =>
	profile.teams.length === 1 ? profile.teams[0] : undefined;

export function translateInspectionStatus(
	inspection: Inspection,
	inspectionWasRejected?: boolean,
	inspectionWasApproved?: boolean
): InspectionStatusLabel {
	const { inspection_status: status, partially_completed } = inspection;

	if (partially_completed && status === 'Inspection Complete') {
		return 'Completed';
	}

	if (status === 'Inspection Complete') {
		if (inspectionWasApproved) return 'Approved';
		return 'Completed';
	}

	switch (status) {
		case 'Inspection Scheduled':
			return 'Scheduled';
		case 'Inspection Request Sent':
			return 'Requested';
		case 'Inspection Request Accepted':
			return 'Accepted';
		case 'Start Inspection':
			return 'Created';
		case 'Send Request or Start Inspection':
			return 'Created';
		case 'Continue Inspection':
			return inspectionWasRejected
				? 'Revision Requested'
				: inspection.review
					? 'In Review'
					: 'Started';
		case 'Review Inspection': {
			if (inspectionWasRejected) return 'Revision Review';
			return 'Awaiting Review';
		}
		default:
			return 'Created';
	}
}

export const isRenter = (
	userType: string | Pick<ApiUser, 'type'> | undefined
): boolean => {
	if (!userType) return false;

	if (typeof userType === 'string') {
		return PROFILE_USER_TYPES.renter === userType;
	}

	return PROFILE_USER_TYPES.renter === userType.type;
};

export const bedroomsCount = (property?: APIProperty) => {
	return property?.rooms.filter((r) => r.includes('Bedroom')).length || 0;
};

export const fullBathroomsCount = (property?: APIProperty) => {
	return (
		property?.rooms
			.filter((r) => !r.includes('Half Bathroom'))
			.filter((r) => r.includes('Bathroom')).length || 0
	);
};

export const halfBathroomsCount = (property?: APIProperty) => {
	return property?.rooms.filter((r) => r.includes('Half Bathroom')).length || 0;
};

export const noCardinalRoomName = (room: string) => {
	if (Utils.Sections.isBedroom(room)) {
		return 'Bedroom';
	} else if (Utils.Sections.isHalfBathroom(room)) {
		return 'Half Bathroom';
	} else if (Utils.Sections.isFullBathroom(room)) {
		return 'Full Bathroom';
	} else if (Utils.Sections.isFloor(room)) {
		return 'Floor';
	}

	return room;
};

export const propertyIsUnit = (property: APIProperty) => {
	return simplePropertyType(property) === 'unit';
};

export const simplePropertyType = (
	property: Pick<APIProperty, 'property_type'>
): 'unit' | 'building' | 'community' => {
	if (property.property_type === 'Building') return 'building';
	if (property.property_type === 'Community') return 'community';
	return 'unit';
};

export const sortRoomsFunction = (a: string, b: string) => {
	const aidx = AreasOrder.unitAreas.indexOf(a);
	const bidx = AreasOrder.unitAreas.indexOf(b);
	if (aidx > bidx) return 1;
	if (aidx < bidx) return -1;
	return 0;
};

export const roomIcon = (room: string) => {
	switch (room) {
		case 'Kitchen':
			return light('oven');

		case 'Front Door':
			return light('door-open');

		case 'Back Door':
			return light('door-open');

		case 'Dining Room':
			return light('chair');

		case 'Family Room':
			return light('loveseat');

		case 'Living Room':
			return light('couch');

		case 'Laundry Room':
			return light('washer');

		case 'Stairway':
			return light('warehouse-alt');

		case 'Garage':
			return light('garage-open');

		case 'Carport':
			return light('garage');

		case 'General':
			return light('house-user');

		case 'Property Exterior & Grounds':
			return light('trees');

		case 'Multi-Unit Common Areas':
			return light('building');
	}

	if (room.includes('Bedroom')) return light('bed');

	if (room.includes('Bathroom')) return light('bath');

	if (room.includes('Half Bathroom')) return light('sink');

	return light('home-lg');
};

export const orderRoomNamesArray = (roomNames: string[]) => {
	const sorted = new Array<string>();
	AreasOrder.unitAreas.map((area) => {
		if (roomNames.includes(area)) {
			sorted.push(area);
		}
	});
	return sorted;
};

export const formatDate = (date: any, dateFormat: string = 'M/D/YYYY') => {
	return formatDateAndTime(date, dateFormat);
};

export const formatCurrency = (
	value: number | undefined,
	showZeroAsValue?: boolean
) => {
	if (value === 0 && showZeroAsValue) {
		return '$0.00';
	}

	if (!value) {
		return '-';
	}

	return `$${value.toFixed(2)}`;
};

export type DateToFormat =
	| string
	| number
	| Date
	| Timestamp
	| { seconds: number }
	| { _seconds: number }
	| undefined;

export const formatDateAndTime = (
	date: DateToFormat,
	dateFormat = 'M/D/YYYY [at] h:mma'
) => {
	const dateExists = !!date;

	if (!dateExists) {
		return '-';
	}

	if (typeof date === 'string') {
		const momentDate = moment(date);
		return momentDate ? momentDate.format(dateFormat) : date;
	}

	if (typeof date === 'number') {
		return moment(date).format(dateFormat);
	}

	if (date instanceof Date) {
		return moment(date).format(dateFormat);
	}

	const dateAsTimestamp = date as Timestamp;
	if (typeof dateAsTimestamp.toDate === 'function') {
		return moment(dateAsTimestamp.toDate()).format(dateFormat);
	}

	const dateAsSeconds = date as { seconds: number };
	if (dateAsSeconds.seconds) {
		return moment(new Date(dateAsSeconds.seconds * 1000)).format(dateFormat);
	}

	const dateAsUnderscoreSeconds = date as { _seconds: number };
	if (dateAsUnderscoreSeconds._seconds) {
		return moment(new Date(dateAsUnderscoreSeconds._seconds * 1000)).format(
			dateFormat
		);
	}

	return '-';
};

export const getBase64 = (file: File): Promise<string> => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			let encoded = reader?.result?.toString().replace(/^data:(.*,)?/, '');
			if (encoded && encoded.length % 4 > 0) {
				encoded += '='.repeat(4 - (encoded.length % 4));
			}

			if (!encoded) {
				reject('Failed to encode');
				return;
			}

			resolve(encoded);
		};
		reader.onerror = (error) => reject(error);
	});
};

export const shortDisplayDate = (
	date?: DateToFormat,
	separator: string = '/'
) => {
	return formatDateAndTime(date, `MM${separator}DD${separator}YYYY`);
};

export const shortestDisplayDate = (
	date?: DateToFormat,
	separator: string = '/'
) => {
	return formatDateAndTime(date, `M${separator}D${separator}YYYY`);
};

export const maintenanceFlagsFromFeatures = (features: Feature[]) => {
	const flags: MaintenanceFlag[] = [];

	features.forEach((f) => {
		if (!f.maintenance_flags) return;
		flags.push(...f.maintenance_flags);
	});

	return flags;
};

export const downloadCORSInhibitedBlob = async (
	url: string,
	filename?: string,
	query?: any
) =>
	new Promise<void>((resolve, reject) => {
		const xhr = new XMLHttpRequest();
		xhr.responseType = 'blob';

		xhr.onerror = reject;

		xhr.onload = () => {
			if (xhr.status >= 300) {
				reject();
				return;
			}

			const blob = xhr.response;
			const blobURL = window.URL.createObjectURL(blob);

			const element = document.createElement('a');
			element.setAttribute('href', blobURL);
			element.setAttribute('download', filename ?? '');

			element.style.display = 'none';
			document.body.appendChild(element);

			element.click();

			document.body.removeChild(element);
			resolve();
		};

		xhr.open('GET', url + (query ? `?${queryString.stringify(query)}` : ''));
		xhr.send();
	});

export const splitUserName = (name: string) => {
	const components = name.split(' ');
	const firstName = components.shift();
	const lastName = components.join(' ');
	return { firstName, lastName };
};

interface PropertyAddressProps {
	address: string;
	city: string;
	zipcode?: string;
	zip_code?: string;
}

interface InspectionPropertyAddressProps {
	property_address: string;
	property_city: string;
	property_zipcode: string;
}

export const formattedAddress = (
	property?: PropertyAddressProps | InspectionPropertyAddressProps
) => {
	if (!property) {
		return '-';
	}

	const inspectionProperty = property as InspectionPropertyAddressProps;
	const regularProperty = property as PropertyAddressProps;

	if (inspectionProperty.property_address) {
		return `${inspectionProperty.property_address}, ${inspectionProperty.property_city}
		${inspectionProperty.property_zipcode}`;
	}

	return `${regularProperty.address}, ${regularProperty.city} ${
		regularProperty.zipcode || regularProperty.zip_code
	}`;
};

export const formattedInspectionAddress = (
	inspection: Inspection | ApiInspection
) => {
	const apiFrontendInspection = inspection as APIFrontendInspection;

	if (apiFrontendInspection.property) {
		const { property } = apiFrontendInspection;
		return `${property.address}, ${property.city} ${property.zip_code}`;
	}

	const firebaseInspection = inspection as Inspection;

	return `${firebaseInspection.property_address}, ${firebaseInspection.property_city} ${firebaseInspection.property_zipcode}`;
};

export const ordinalRoomName = (roomName: string, index: number) => {
	const english_ordinal_rules = new Intl.PluralRules('en', { type: 'ordinal' });
	const suffixes = { one: 'st', two: 'nd', few: 'rd', other: 'th' };

	//@ts-ignore
	const suffix = suffixes[english_ordinal_rules.select(index)];

	return `${index}${suffix} ${roomName}`;
};

export const inspectionIsInProgress = (inspection: ApiInspection) => {
	const inProgressStatuses: InspectionStatusDisplay[] = [
		'Scheduled',
		'Not Started',
		'Revision Requested',
		'Started',
	];

	return inProgressStatuses.includes(inspection.inspection_status);
};

export const inspectionIsInReview = (inspection: ApiInspection) => {
	const inReviewStatuses: InspectionStatusDisplay[] = [
		'Awaiting Review',
		'Revision Review',
	];

	return inReviewStatuses.includes(inspection.inspection_status);
};

export const inspectionIsComplete = (inspection: ApiInspection) => {
	const completeStatuses: InspectionStatusDisplay[] = ['Completed', 'Approved'];

	return completeStatuses.includes(inspection.inspection_status);
};

/**
 * Transforms a string into a test id friendly string
 * i.e.: 'Bedroom Ceiling' -> 'bedroom_ceiling'
 */
export const testId = (value: string) => {
	return value.toLowerCase().replaceAll(' ', '_');
};

/**
 * Returns a human readable feature name by combining
 * the feature name and the room name if they are different
 * or returning just the feature name if they are the same
 */
export const featureDisplayName = (
	object:
		| Pick<Feature, 'name' | 'section'>
		| {
				room: string;
				feature: string;
		  },
	separator: string = ' - '
) => {
	if (_.get(object, 'room')) {
		return _.uniq([_.get(object, 'room'), _.get(object, 'feature')]).join(
			separator
		);
	}

	if (_.get(object, 'section')) {
		return _.uniq([
			_.get(object, 'section.name_with_ordinal'),
			_.get(object, 'name'),
		]).join(separator);
	}

	return '-';
};

/**
 * Returns a default title for work orders by combining
 * the feature name and the room name if they are different
 * or just the feature name if they are the same and appending
 * the skill at the end
 */
export const workOrderDefaultTitle = (flag: MaintenanceFlag) => {
	return `${featureDisplayName(flag)}: ${flag.skill}`;
};

/**
 * Returns a default description for work orders by combining
 * the feature name and the room name if they are different
 * or just the feature name if they are the same and appending
 * the notes at the end
 */
export const workOrderDefaultDescription = (flag: MaintenanceFlag) => {
	return `${featureDisplayName(flag)}: ${flag.note}`;
};

/**
 * Returns a string built using the count provided and appending
 * the singular or plural form of the original word. For words
 * with simple plural forms (e.g.: bean/beans) this function will
 * handle adding the 's' on its own, for words with more complex
 * plural forms (e.g.: community/communities) the correct plural
 * word needs to be provided
 */
export const numberAndPluralizeWord = (
	count: number | Array<unknown>,
	baseWord: string,
	pluralWord?: string
) => {
	if (Array.isArray(count)) {
		count = count.length;
	}

	if (count === 1) {
		return `${count} ${baseWord}`;
	}

	if (pluralWord) {
		return `${count} ${pluralWord}`;
	}

	return `${count} ${baseWord}s`;
};

export const imageUrlFromGCPUri = (
	uri: string,
	width?: number,
	height?: number
) => {
	const rentcheckSecret = process.env.rentcheck_secret ?? '';

	const urlComponents = [
		process.env.api_base_url +
			`/v1/firebase/image?fit=inside&path=` +
			encrypt(uri, rentcheckSecret),
	];

	if (width) {
		urlComponents.push(`width=${width.toFixed(0)}`);
	}

	if (height) {
		urlComponents.push(`height=${height.toFixed(0)}`);
	}

	return urlComponents.join('&');
};

export const videoUrlFromGCPUri = (uri: string, getThumbnail?: boolean) => {
	const rentcheckSecret = process.env.rentcheck_secret ?? '';

	const urlComponents = [
		process.env.api_base_url +
			`/v1/firebase/video?path=` +
			encrypt(uri, rentcheckSecret),
	];

	if (getThumbnail) {
		urlComponents.push(`format=thumbnail`);
	}

	return urlComponents.join('&');
};

export const getThumbnailUrl = (uri: string): string => {
	const videoExtensions = ['.mp4', '.mov'];
	const fileExtension = path.extname(uri);

	for (const ext of videoExtensions) {
		if (fileExtension === ext) {
			return uri.replace(ext, '_thumbnail.jpeg');
		}
	}

	return uri;
};

/*
 * Returns inspection edits that have the `reopened` type
 */
export const reopenedSubmittedEventsFromInspection = (
	inspection: ApiInspection
) => {
	return (inspection.edits ?? []).filter(
		(e) => e.type === 'reopened-submitted'
	);
};

/*
 * Creates an InspectionEdited object based on the changes made using
 * the edit feature functionality
 */
export const calculateFeatureChangeset = (
	feature: Feature,
	changes: Partial<Omit<Feature, 'id'>>,
	profile: ApiUser
) => {
	const result: InspectionEdited = {
		questions: [],
		images: {
			addded: _.difference(changes.images ?? [], feature.images),
			removed: _.difference(feature.images, changes.images ?? []),
		},
		type: 'edited',
		timestamp: firestore.Timestamp.fromDate(new Date()),
		user_id: profile.id,
		user_name: profile.name,
	};

	if (feature.notes !== changes.notes) {
		result.note = {
			original: feature.notes ?? '',
			updated: changes.notes ?? '',
		};
	}

	if (feature.rating && changes.rating && feature.rating !== changes.rating) {
		result.rating = {
			original: feature.rating as unknown as InspectionRating,
			updated: changes.rating as unknown as InspectionRating,
		};
	}

	if (!_.isEqual(changes.responses, feature.responses)) {
		const questionsChangeset: {
			question: string;
			original: string;
			updated: string;
		}[] = [];

		feature.questions.forEach((q, index) => {
			questionsChangeset.push({
				question: q.title,
				original: feature.responses[index],
				updated: changes.responses ? changes.responses[index] : '',
			});
		});

		result.questions = questionsChangeset;
	}

	return result;
};

/*
 * Helper function to get a timestamp from the DayValue provided by
 * an instance of react-modern-calendar-datepicker
 */
export const timestampFromDayValue = (dayValue: DayValue) => {
	if (!dayValue) return 0;

	const date = { ...dayValue };
	date.month = date.month - 1;

	const momentDate = moment(date);
	momentDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

	return momentDate.valueOf();
};

/*
 * Helper function to get a DayValue as required by an instance of
 * react-modern-calendar-datepicker from a timestamp
 */
export const dayValueFromTimestamp = (timestamp: number): DayValue => {
	const date = moment(timestamp);
	return { day: date.date(), month: date.month() + 1, year: date.year() };
};

/*
 * Helper function to get a DayRange as required by an instance of
 * react-modern-calendar-datepicker from a DateRange (used in filters
 * throughout the app)
 */
export const dayRangeFromDateRange = (dateRange?: DateRange): DayRange => {
	if (!dateRange?.start) {
		return { from: null, to: null };
	}

	if (!dateRange.end) {
		return {
			from: dayValueFromTimestamp(dateRange.start),
			to: null,
		};
	}

	return {
		from: dayValueFromTimestamp(dateRange.start),
		to: dayValueFromTimestamp(dateRange.end),
	};
};

/*
 * Gets the formatted dates from a DateRange
 */
export const formattedDateRange = (dateRange: DateRange): string => {
	const dates = [moment(dateRange.start).format('MMMM D, YYYY')];

	if (dateRange.end) {
		dates.push(moment(dateRange.end).format('MMMM D, YYYY'));
	}

	return dates.join(' - ');
};

/**
 * Plural versions of property types
 */
export const propertyTypePlurals = {
	unit: 'Units',
	building: 'Buildings',
	community: 'Communities',
	Unit: 'Units',
	Building: 'Buildings',
	Community: 'Communities',
};

/**
 * Returns true if the image was taken by a 360 camera
 * @param image the GCP uri for the image
 */
export const imageIs360Photo = (imageUri: string) => {
	return imageUri.includes('360-image');
};

/**
 * Returns true if the password contains solely of an invalid sequence
 * @param str the password string
 */
export const isInvalidPassword = (str: string) => {
	if (process.env.firebase_projectId === 'web-console-nonprod') return false;

	const hasOnlySequentialCharacters =
		'abcdefghijklmnopqrstuvwxyz'.includes(str) ||
		'zyxwvutsrqponmlkjihgfedcba'.includes(str) ||
		'01234567890'.includes(str) ||
		'09876543210'.includes(str);
	const hasThreeRepeatedCharactersInARow = /(.)\1\1/.test(str);
	const consistsOfRepeatedSubsstrings =
		`${str}${str}`.indexOf(str, 1) !== str.length;

	return (
		hasThreeRepeatedCharactersInARow ||
		consistsOfRepeatedSubsstrings ||
		hasOnlySequentialCharacters
	);
};

export const scrollToTop = () => {
	window.scrollTo(0, 0);
};

export const scrollToBottom = (element: HTMLElement | string | null) => {
	if (!element) return;

	if (typeof element === 'string') {
		scrollToBottom(document.getElementById(element));
		return;
	}

	element.scrollTo({ top: element.scrollHeight });
};

export const scrollToElement = (
	element: HTMLElement | string | null,
	block: ScrollLogicalPosition = 'center',
	offset: number = 0
) => {
	if (!element) return;

	if (typeof element === 'string') {
		scrollToElement(document.getElementById(element), block, offset);
		return;
	}

	if (offset === 0) {
		element.scrollIntoView({
			behavior: 'smooth',
			block,
		});
	} else {
		const y = element.getBoundingClientRect().top + window.scrollY + offset;
		window.scrollTo({ top: y, behavior: 'smooth' });
	}
};

export const getReportLocationForTemplate = (
	inspection: ApiInspection,
	accountSettings?: AccountSettings,
	reportName: string = 'default'
) => {
	if (!accountSettings) {
		return undefined;
	}

	const reportTemplate =
		accountSettings.inspection_report_templates?.[reportName];

	if (!reportTemplate) {
		return undefined;
	}

	const reportTemplateHash = hash(reportTemplate);

	return inspection.pdf_reports?.[reportTemplateHash]?.location;
};

export const getFullTemplateName = (
	template: Pick<InspectionTemplate, 'internal_label' | 'name'>
) => {
	if (template.internal_label) {
		return `${template.name}, ${template.internal_label}`;
	}
	return template.name;
};

/**
 * @param feature
 * @returns images and videos from a feature on a single array
 */
export const getFeatureMedia = (feature: Feature): string[] => {
	const images = feature.images ?? [];
	const videos = feature.videos ?? [];

	return [...images, ...videos];
};

/**
 * Helper method that checks for the presence of an object inside the window.
 * @returns true if the app is running inside a webview in the mobile app
 */
export const isEmbeddedInMobileApp = () => {
	return (<any>window).ReactNativeWebView;
};

/**
 * Helper method that sends messages to the native app
 */
export const postMessageToNativeApp = (message: EmbeddedWebAppMessage) => {
	return (<any>window).ReactNativeWebView?.postMessage(JSON.stringify(message));
};

/**
 * Summarizes a list of strings so it only shows the first few items
 * and the total count of items after
 * e.g. ['a', 'b', 'c', 'd', 'e'] -> 'a, b + 3 more'
 */
export const summarizeList = (list: string[], maxItems: number = 2) => {
	const count = list.length;

	if (count <= maxItems) {
		return list.join(', ');
	}

	const firstItems = list.slice(0, maxItems);

	return `${firstItems.join(', ')} + ${count - maxItems} more`;
};
