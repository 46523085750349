import { IntercomAPI } from 'react-intercom';

import { Constants } from '@rentcheck/biz';
import { Button, PlanGate } from 'components';

const Inactive = () => {
	const handleChatToInstall = () => {
		IntercomAPI('showNewMessage');
	};

	return (
		<PlanGate
			variant="professional"
			addonIds={Constants.Addons.AppFolio.TwoWayDataSync}>
			<Button onClick={handleChatToInstall}>Install</Button>
		</PlanGate>
	);
};

export default Inactive;
