import { Rules, Utils } from '@rentcheck/biz';
import { ApiInspectionWithTemplate } from '@rentcheck/types';
import { formatDate } from 'utils/helpers';

import moment from 'moment';

import GridItem from '../grid-item';

interface Props {
	inspection: ApiInspectionWithTemplate;
}

export default ({ inspection }: Props) => {
	if (!Rules.Inspections.canShowReviewDueDate(inspection)) {
		return null;
	}

	const dueDate = moment(inspection.review?.due_date).startOf('day');
	const daysOverdue = Math.floor(
		moment.duration(moment().diff(dueDate)).asDays()
	);

	return (
		<GridItem
			title="Review Due Date"
			value={
				<>
					{formatDate(inspection.review?.due_date)}{' '}
					{daysOverdue > 0 && (
						<span style={{ color: '#C71D23' }}>
							({Utils.Helpers.numberAndPluralizedWord(daysOverdue, 'day')}{' '}
							overdue)
						</span>
					)}
				</>
			}
		/>
	);
};
