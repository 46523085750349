import { Tooltip } from '@mui/material';
import { MaintenanceFlag } from '@rentcheck/types';
import { CenteredColumn } from 'components';
import { ReactElement } from 'react';
import styled from 'styled-components';

interface Props {
	children: ReactElement;
	flags: MaintenanceFlag[];
}

export default ({ children, flags }: Props) => {
	return (
		<Tooltip
			title={
				<CenteredColumn>
					{flags.map((flag) => (
						<TooltipContent key={flag.id}>{flag.skill}</TooltipContent>
					))}
				</CenteredColumn>
			}>
			{children}
		</Tooltip>
	);
};

const TooltipContent = styled.div`
	margin-bottom: 8px;

	:last-child {
		margin-bottom: 0;
	}
`;
