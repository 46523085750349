import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import { Utils } from '@rentcheck/biz';
import { Row } from 'components';
import { CSSProperties } from 'react';

interface Props {
	image: string;
}

export default ({ image }: Props) => {
	const isVideo = Utils.Media.isVideo(image);
	const is360Image = Utils.Media.is360Photo(image);

	const containerStyle: CSSProperties = {
		position: 'absolute',
		alignSelf: 'center',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		cursor: 'pointer',
	};

	if (isVideo) {
		return (
			<Row style={{ ...containerStyle, pointerEvents: 'none' }}>
				<FontAwesomeIcon
					width={12}
					height={16}
					icon={solid('play')}
					color="#fff"
					pointerEvents={'none'}
					cursor={'pointer'}
				/>
			</Row>
		);
	}

	if (is360Image) {
		return (
			<Row style={containerStyle}>
				<Tooltip title="360 Photo">
					<FontAwesomeIcon icon={regular('globe')} color="#fff" />
				</Tooltip>
			</Row>
		);
	}

	return null;
};
