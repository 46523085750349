import { combineReducers } from 'redux';
import filtersReducer, { State as FiltersState } from './filters';
import tenantReducer, { State as TenantState } from './tenant';
import tenantsReducer, { State as TenantsState } from './tenants';

export type State = {
	tenants: TenantsState;
	tenant: TenantState;
	filters: FiltersState;
};

const reducer = combineReducers({
	tenants: tenantsReducer,
	tenant: tenantReducer,
	filters: filtersReducer,
});

export default reducer;
