import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Checkbox, IconButton, Typography } from '@mui/material';
import { MaintenanceFlag } from '@rentcheck/types';
import { Column, Row, SpacedRow, Spacer } from 'components';
import { useFeature } from 'hooks/flagged-features';
import { useCreateWorkOrderModalData } from 'hooks/modals';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { ModalFlowActions } from 'store/actions';
import styled from 'styled-components';
import { spacingPx } from 'theme';
import { featureDisplayName } from 'utils/helpers';

interface CardProps {
	flag: MaintenanceFlag;
	value: string[];
	setValue: (value: string[]) => void;
}

interface Props {
	value: string[];
	setValue: (value: string[]) => void;
}

const FlagCard = ({ flag, value, setValue }: CardProps) => {
	const dispatch = useDispatch();

	const modalData = useCreateWorkOrderModalData('Latchel');
	const inspection = modalData?.metadata?.inspection;

	const feature = useFeature(inspection.id, flag.room, flag.feature);

	const handleEdit = () => {
		if (!inspection) return;
		if (!feature) return;

		dispatch(
			ModalFlowActions.showFlagFeatureModal({
				inspection,
				feature,
				flag,
				disableDelete: true,
			})
		);
	};

	const handleToggleSelected = () => {
		setValue(_.xor(value, [flag.id]));
	};

	if (!flag) return null;

	const name = featureDisplayName(flag);

	const photosCount = flag.image_urls?.length ?? 0;

	const checked = value.includes(flag.id);

	return (
		<Card sx={{ marginBottom: 2 }}>
			<CardContent>
				<SpacedRow style={{ flex: 1 }}>
					<Row>
						<Checkbox
							id={`checkbox-${name}`}
							checked={checked}
							onChange={handleToggleSelected}
						/>
						<Spacer width={2} />
						<Column>
							<Typography fontWeight={600}>{name}</Typography>
							<Typography>
								{photosCount} Photo{photosCount === 1 ? '' : 's'}
							</Typography>
						</Column>
					</Row>
					<IconButton
						id={`edit-${name}`}
						sx={{ height: 40, width: 40 }}
						onClick={handleEdit}>
						<FontAwesomeIcon icon={regular('edit')} size="xs" />
					</IconButton>
				</SpacedRow>
			</CardContent>
		</Card>
	);
};

const Flags = ({ value, setValue }: Props) => {
	const modalData = useCreateWorkOrderModalData('Latchel');
	const { inspection, flags } = modalData?.metadata ?? {};

	if (!inspection) return null;

	return (
		<Column>
			{flags.map((f) => (
				<FlagCard flag={f} value={value} setValue={setValue} />
			))}
		</Column>
	);
};

const CardContent = styled(Row)`
	padding: ${spacingPx(2)};
`;

export default Flags;
