import { Button, DialogActions, DialogContent } from '@mui/material';
import { Spacer } from 'components';
import { useCreateWorkOrderModalData } from 'hooks/modals';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ModalFlowActions, SnackbarActions } from 'store/actions';

import Property from './property';
import Residents from './residents';

import {
	LatchelApi,
	LatchelProperty,
	LatchelTenant,
} from '@rentcheck/api-frontend';
import moment from 'moment';
import { Step } from '..';

interface Props {
	nextStep: (step: Step) => void;
	property: LatchelProperty | null;
	setProperty: (property: LatchelProperty | null) => void;
	tenant?: LatchelTenant;
	setTenant: (value?: LatchelTenant) => void;
	accessInstructions: string;
	setAccessInstructions: (value: string) => void;
}

const SelectProperty = ({
	nextStep,
	property,
	setProperty,
	tenant,
	setTenant,
	accessInstructions,
	setAccessInstructions,
}: Props) => {
	const dispatch = useDispatch();

	const modalData = useCreateWorkOrderModalData('Latchel');
	const { inspection, flags } = modalData?.metadata ?? {};

	const [tenants, setTenants] = useState<LatchelTenant[]>([]);

	const [loadingTenants, setLoadingTenants] = useState(false);

	useEffect(() => {
		if (!property || !inspection?.team) {
			setLoadingTenants(false);
			return;
		}

		setLoadingTenants(true);

		const filterInactiveTenants = (t: LatchelTenant) => {
			const moveOutMoment = t.move_out_date
				? moment(t.move_out_date)
				: undefined;

			const moveInMoment = t.move_in_date ? moment(t.move_in_date) : undefined;

			const hasMovedOut =
				moveOutMoment && moveOutMoment.isBefore(moment(new Date()));

			const willMoveIn =
				moveInMoment && moveInMoment.isAfter(moment(new Date()));

			return t.is_active && !hasMovedOut && !willMoveIn;
		};

		LatchelApi.residents
			.getForProperty(inspection.team?.id, property.property_id)
			.then((data) => setTenants(data.filter(filterInactiveTenants)))
			.catch(() => dispatch(SnackbarActions.showError()))
			.finally(() => setLoadingTenants(false));
	}, [property]);

	useEffect(() => {
		if (tenants.length) {
			setTenant(tenants[0]);
		} else {
			setTenant(undefined);
		}
	}, [tenants]);

	const handleClose = () => {
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	const handleNext = () => {
		if (_.uniqBy(flags, (f) => f.skill).length > 1) {
			nextStep('Select Skill');
			return;
		}

		nextStep('Work Order Details');
	};

	const nextIsDisabled =
		!property ||
		loadingTenants ||
		(tenants.length === 0 && !accessInstructions);

	return (
		<>
			<DialogContent>
				<Spacer height={10} />
				<Property
					value={property}
					setValue={setProperty}
					tenants={tenants}
					loadingTenants={loadingTenants}
				/>
				<Spacer height={10} />
				<Residents
					property={property}
					tenants={tenants}
					tenant={tenant}
					setTenant={setTenant}
					loadingTenants={loadingTenants}
					value={accessInstructions}
					setValue={setAccessInstructions}
				/>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" color="secondary" onClick={handleClose}>
					Back
				</Button>
				<Button onClick={handleNext} disabled={nextIsDisabled}>
					Next
				</Button>
			</DialogActions>
		</>
	);
};

export default SelectProperty;
