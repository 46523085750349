import _ from 'lodash';
import { useEffect, useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Card, CardContent, TextField, Typography } from '@mui/material';

import {
	TemplateQuestion,
	TemplateFeature,
	TemplateFeatureLogic,
	TemplateSection,
} from '@rentcheck/types';

import { Row, SpacedRow, Spacer } from 'components';
import IconButton from 'components/icon-button';
import { featureDisplayName } from 'utils/helpers';

import SelectAnswers from '../../../../shared-components/select-answers';
import SelectQuestion from '../../../../shared-components/select-question';

interface Props {
	feature: TemplateFeature;
	sections: TemplateSection[];
	logic: TemplateFeatureLogic;
	logics: TemplateFeatureLogic[];
	onUpdate: (logic: TemplateFeatureLogic) => void;
	onDelete: (logic: TemplateFeatureLogic) => void;
}

export default ({
	feature,
	sections,
	logic,
	logics,
	onUpdate,
	onDelete,
}: Props) => {
	const [questions, setQuestions] = useState<TemplateQuestion[]>([]);
	const [question, setQuestion] = useState<TemplateQuestion>();

	useEffect(() => {
		const otherLogics = logics.filter((l) => l.id !== logic.id);

		const questions = _.sortBy(
			sections.flatMap((s) =>
				s.features
					.filter((f) => f.id !== feature.id)
					.flatMap((f) =>
						f.questions
							.filter(
								(q) => q.type === 'yes/no' || q.type === 'multiple choice'
							)
							.map((q) => ({
								...q,
								title: `${featureDisplayName({ room: s.name, feature: f.name }, ': ')}: ${q.title}`,
							}))
					)
			),
			'title'
		).filter((q) => !otherLogics.some((l) => l.trigger_id === q.id));

		setQuestions(questions);
	}, [sections]);

	useEffect(() => {
		if (!questions.length) {
			return;
		}

		setQuestion(questions.find((q) => q.id === logic.trigger_id));
	}, [questions, logic.trigger_id]);

	const handleUpdateTriggerId = (triggerId?: string) => {
		if (triggerId === logic.trigger_id) {
			return;
		}

		onUpdate({ ...logic, trigger_id: triggerId ?? '', condition_value: [] });
	};

	const handleUpdateConditionValue = (value: string[]) => {
		onUpdate({
			...logic,
			condition_value: value,
		});
	};

	/**
	 * We need to wait until we can find the question that
	 * matches this trigger id
	 */
	if (logic.trigger_id && !question) {
		return null;
	}

	return (
		<Card sx={{ marginBottom: 2 }}>
			<CardContent sx={{ display: 'flex', p: 2, flexDirection: 'column' }}>
				<SelectQuestion
					question={question}
					questions={questions}
					onUpdateTriggerId={handleUpdateTriggerId}
				/>

				<SelectAnswers
					question={question}
					conditionValue={logic.condition_value}
					onUpdateConditionValue={handleUpdateConditionValue}
				/>

				<Row>
					<Typography variant="subtitle2" sx={{ maxWidth: 180, minWidth: 180 }}>
						Show feature:
					</Typography>

					<TextField
						variant="outlined"
						fullWidth
						value={feature.name}
						disabled
					/>
				</Row>

				<SpacedRow style={{ marginTop: 8 }}>
					<Spacer width={1} />
					<IconButton
						color="primary"
						icon={solid('trash')}
						onClick={() => onDelete(logic)}
						tooltip="Delete"
					/>
				</SpacedRow>
			</CardContent>
		</Card>
	);
};
