import { ApiSubscription } from '@rentcheck/types';

type Action = {
	type: 'SET_SUBSCRIPTION' | 'SIGNOUT_SUCCESS';
	subscription: ApiSubscription | null;
};

export type State = ApiSubscription | null;

const subscriptionReducer = (state = null, action: Action) => {
	switch (action.type) {
		case 'SET_SUBSCRIPTION':
			return action.subscription;
		case 'SIGNOUT_SUCCESS':
			return null;
		default:
			return state;
	}
};

export default subscriptionReducer;
