import { LoadingButton } from '@mui/lab';
import {
	Card,
	CardContent,
	Divider,
	MenuItem,
	Select,
	TextField,
	Typography,
} from '@mui/material';
import { ChargebeeAddon, ChargebeePlan } from '@rentcheck/types';
import { ChargebeeApi, SalesApi } from 'api';
import { Column, Spacer } from 'components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import styled from 'styled-components';
import { isEmailValid } from 'utils/emails';

const PostDemoFollowUp = () => {
	const dispatch = useDispatch();

	const profile = useTypedSelector((state) => state.authenticatedUser);

	const [email, setEmail] = useState('');
	const [plan, setPlan] = useState<ChargebeePlan | undefined>(undefined);
	const [addons, setAddons] = useState<ChargebeeAddon[]>([]);
	const [portfolioSize, setPortfolioSize] = useState('');

	const [plans, setPlans] = useState<ChargebeePlan[]>([]);
	const [allAddons, setAllAddons] = useState<ChargebeeAddon[]>([]);

	const [loading, setLoading] = useState(false);

	const sanitizeEmail = (email: string) => email?.trim()?.toLowerCase();

	useEffect(() => {
		ChargebeeApi.getRemoteConfiguredPlans()
			.then((data) => setPlans(data))
			.catch((e) => dispatch(SnackbarActions.showError(e.message)));

		ChargebeeApi.getAddons()
			.then((data) => setAllAddons(data))
			.catch((e) => dispatch(SnackbarActions.showError(e.message)));
	}, []);

	const handlePlanChange = (id: string) => {
		setPlan(plans.find((plan) => plan.id === id));
	};

	const handleAddonChange = (ids: string | string[]) => {
		setAddons(allAddons.filter((addon) => ids.includes(addon.id)));
	};

	const handleSubmit = () => {
		if (!plan) return;

		setLoading(true);

		const lowercasedEmail = sanitizeEmail(email);

		SalesApi.createSubscriptionProspect(
			profile.email,
			lowercasedEmail,
			plan,
			addons,
			parseInt(portfolioSize)
		)
			.then(() => {
				resetForm();
				dispatch(
					SnackbarActions.showSuccess(`An email was sent to ${profile.email}!`)
				);
			})
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	const isFormValid = () => {
		if (!isEmailValid(email)) return false;
		if (!plan) return false;

		const portfolioSizeNumber = parseInt(portfolioSize);
		if (!portfolioSizeNumber || portfolioSizeNumber < 1) return false;

		return true;
	};

	const resetForm = () => {
		setEmail('');
		setPlan(undefined);
		setAddons([]);
		setPortfolioSize('');
	};

	return (
		<Card>
			<CardContent>
				<Typography variant="h6">Post demo follow-up</Typography>
				<Spacer height={2} />
				<Typography>
					Enter prospect details below to generate payment link.
				</Typography>
			</CardContent>
			<Divider />
			<CardContent>
				<HalfColumn>
					<Typography>Enter prospect email:</Typography>
					<Spacer height={2} />
					<TextField
						variant="filled"
						type="email"
						placeholder="Enter prospect email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						onBlur={() => setEmail(sanitizeEmail(email))}
					/>

					<Spacer height={12} />
					<Typography>Select Chargebee plan:</Typography>
					<Spacer height={2} />
					<Select
						variant="filled"
						value={plan?.id ?? 'placeholder'}
						onChange={(e) => handlePlanChange(e.target.value)}>
						<MenuItem disabled value="placeholder" sx={{ display: 'none' }}>
							Select plan
						</MenuItem>
						{plans.map((plan) => (
							<MenuItem key={plan.id} value={plan.id}>
								{plan.name}
							</MenuItem>
						))}
					</Select>

					<Spacer height={12} />
					<Typography>Select add-ons: (optional)</Typography>
					<Spacer height={2} />
					<Select
						multiple
						variant="filled"
						value={
							addons.length > 0 ? addons.map((a) => a.id) : ['placeholder']
						}
						onChange={(e) => handleAddonChange(e.target.value)}>
						<MenuItem disabled value="placeholder" sx={{ display: 'none' }}>
							Select add-ons
						</MenuItem>
						{allAddons.map((addon) => (
							<MenuItem key={addon.id} value={addon.id}>
								{addon.name}
							</MenuItem>
						))}
					</Select>

					<Spacer height={12} />
					<Typography>Estimated portfolio size:</Typography>
					<Spacer height={2} />
					<TextField
						sx={{ width: 300 }}
						variant="filled"
						type="number"
						placeholder="Portfolio size"
						value={portfolioSize}
						onChange={(e) => setPortfolioSize(e.target.value)}
					/>

					<Spacer height={12} />
					<LoadingButton
						loading={loading}
						variant="contained"
						disabled={!isFormValid()}
						onClick={handleSubmit}>
						Send me the payment link
					</LoadingButton>
				</HalfColumn>
			</CardContent>
		</Card>
	);
};

const HalfColumn = styled(Column)`
	width: 50%;
`;

export default PostDemoFollowUp;
