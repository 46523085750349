import * as React from 'react';
import styled from 'styled-components';
import { colors } from 'theme';

interface Props {
	options: {
		name: string;
		value: string;
	}[];
	onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

export function Select({ options, onChange }: Props) {
	return (
		<SelectElement onChange={onChange}>
			{options.map(({ name, value }) => (
				<option key={value} value={value}>
					{name}
				</option>
			))}
		</SelectElement>
	);
}

const SelectElement = styled.select`
	appearance: none;
	background: right center no-repeat
		url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3E%3Cpath d='M5 8l4 4 4-4z'/%3E%3C/svg%3E ");
	border-radius: 0 !important;
	border: 0;
	color: ${colors.darkGray};
	display: inline !important;
	font-family: 'Poppins';
	font-size: 14px;
	height: auto !important;
	line-height: 1.14;
	min-width: 40px;
	padding: 0 !important;
	width: auto !important;
`;
