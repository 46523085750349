import {
	Button,
	Card,
	CardContent,
	Divider,
	Paper,
	Skeleton,
	Typography,
} from '@mui/material';
import { IntercomApi, IntercomArticle } from '@rentcheck/api-frontend';
import { Column, Row, SpacedRow, Spacer } from 'components';
import _ from 'lodash';
import { useEffect, useState } from 'react';

const SkeletonRow = () => {
	return (
		<Card
			sx={{
				height: 179,
				marginBottom: 1.5,
				':last-child': {
					marginBottom: 0,
				},
			}}>
			<CardContent>
				<Skeleton height={30} width={140} />
				<Spacer height={2} />
				<Skeleton height={20} />
				<Skeleton height={20} />
				<Skeleton height={20} width={230} />
				<Spacer height={2} />
				<Skeleton height={20} width={80} />
			</CardContent>
		</Card>
	);
};

const ArticlesSkeleton = () => {
	return (
		<>
			<SkeletonRow />

			<SkeletonRow />

			<SkeletonRow />
		</>
	);
};

const Resources = () => {
	const [articles, setArticles] = useState<IntercomArticle[] | null>(null);

	useEffect(() => {
		IntercomApi.articles
			.get({ parent_type: 'collection', parent_id: '3614406' })
			.then((data) => setArticles(_.slice(data, 0, 3)))
			.catch(() => setArticles([]));
	}, []);

	return (
		<Paper sx={{ flex: 1, border: '1px solid #CCD6E1' }}>
			<CardContent>
				<SpacedRow>
					<Typography variant="h6">Resources:</Typography>
				</SpacedRow>
			</CardContent>
			<Divider />
			<CardContent sx={{ backgroundColor: '#EFF1F4' }}>
				{!articles && <ArticlesSkeleton />}

				{articles?.map((a) => (
					<Card
						key={a.id}
						sx={{
							marginBottom: 1.5,
							':last-child': {
								marginBottom: 0,
							},
						}}>
						<CardContent
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-between',
							}}>
							<Column>
								<Typography variant="subtitle1">{a.title}</Typography>
								<Typography color="#354351">{a.description}</Typography>
							</Column>
							{!!a.url && (
								<Row>
									<Button
										variant="text"
										sx={{ marginTop: 2 }}
										onClick={() => {
											window.open(a.url ?? '', '_blank');
										}}>
										Read More
									</Button>
								</Row>
							)}
						</CardContent>
					</Card>
				))}
			</CardContent>
		</Paper>
	);
};

export default Resources;
