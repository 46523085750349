import { LoadingButton } from '@mui/lab';
import {
	Button,
	Card,
	CardContent,
	TextField,
	Typography,
} from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { ApiUser } from '@rentcheck/types';

import { AdminToolsApi } from '@rentcheck/api-frontend';
import { Spacer } from 'components';
import { ModalFlowActions, SnackbarActions } from 'store/actions';
import { colors } from 'theme';
import { Dispatch } from 'types';
import { isEmailValid } from 'utils/emails';

export default function EnableUser() {
	const dispatch: Dispatch = useDispatch();

	const [user, setUser] = useState<ApiUser | undefined>(undefined);
	const [userIsDisabled, setUserIsDisabled] = useState(false);
	const [loading, setLoading] = useState(false);
	const [input, setInput] = useState<string>('');
	const [error, setError] = useState('');

	const search = async (email: string) => {
		setLoading(true);
		setError('');

		if (!isEmailValid(email)) {
			setError('Please enter a valid email');
			setLoading(false);
			return;
		}

		const user = await AdminToolsApi.getUserByEmailOrId(email).catch((e) => {
			setError(e.message);
			return undefined;
		});

		if (!user) {
			setError('User not found');
			setLoading(false);
			return;
		}

		setUserIsDisabled(user.authUser.disabled);
		setUser(user.user);
		setLoading(false);
	};

	const confirm = () => {
		if (!user) return;

		dispatch(
			ModalFlowActions.showConfirmationModal({
				title: 'Are you sure?',
				body1: [
					"Check intercom and make sure that this user didn't delete their account while on iOS before re-enabling.",
				],
				body2: [],
				buttons: [
					ModalFlowActions.cancelButton(dispatch),
					{
						title: 'Confirm',
						color: 'primary',
						variant: 'contained',
						onClick: enableUser,
					},
				],
			})
		);
	};

	const enableUser = () => {
		if (!user) return;

		dispatch(ModalFlowActions.setConfirmationModalLoading(true));

		AdminToolsApi.enableUserAccount(user.id)
			.then((data) => {
				if (!data || data.authUser.disabled) {
					throw new Error();
				}

				setUserIsDisabled(false);
				dispatch(SnackbarActions.showSuccess('User Enabled'));
			})
			.catch(() =>
				dispatch(SnackbarActions.showError('There was a problem enabling user'))
			)
			.finally(() => dispatch(ModalFlowActions.closeConfirmationModal()));
	};

	return (
		<Card>
			<CardContent>
				<Typography variant="h5">Re-enable User</Typography>
				<Spacer height={2} />
				<Typography>Search user by email:</Typography>
				<Spacer height={2} />

				<FormRow
					onSubmit={(e) => {
						e.preventDefault();
						search(input);
					}}>
					<TextField
						fullWidth
						variant="filled"
						type="email"
						placeholder="Email"
						onChange={(e) => setInput(e.target.value)}
					/>
					<Spacer width={6} />
					<LoadingButton
						type="submit"
						variant="contained"
						onClick={() => search(input)}
						loading={loading}>
						Search
					</LoadingButton>
				</FormRow>

				{error && <Typography color={colors.danger}>{error}</Typography>}

				{user && (
					<>
						<Spacer height={6} />
						<Typography fontWeight={600}>Name: </Typography>
						<Typography>{user.name}</Typography>
						<Spacer height={1} />
						<Typography fontWeight={600}>Email: </Typography>
						<Typography>{user.email}</Typography>
						<Spacer height={1} />
						<Typography fontWeight={600}>User Type: </Typography>
						<Typography>{user.type}</Typography>
						<Spacer height={6} />
						<Button disabled={!userIsDisabled} onClick={confirm}>
							{userIsDisabled ? 'Re-enable' : 'User is already enabled'}
						</Button>
					</>
				)}
			</CardContent>
		</Card>
	);
}

const FormRow = styled.form`
	display: flex;
	flex-direction: row;
	width: 50%;
	justify-content: space-between;
	text-align: center;
`;
