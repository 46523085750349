import { Feature } from '@rentcheck/types';

import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Divider,
	Typography,
} from '@mui/material';
import { Column, Row } from 'components';
import Notes from './notes';
import Questions from './questions';
import Rating from './rating';

interface Props {
	feature: Feature;
	featurePayload: Partial<Feature>;
	setFeaturePayload: (value: Partial<Feature>) => void;
	fullWidth?: boolean;
}

export default ({
	feature,
	featurePayload,
	setFeaturePayload,
	fullWidth,
}: Props) => {
	return (
		<Column
			style={{
				border: '1px solid #0000001F',
				borderRadius: 4,
				width: fullWidth ? '100%' : 450,
				maxHeight: '100%',
				overflowY: 'scroll',
			}}>
			<Accordion disableGutters elevation={0} expanded>
				<AccordionSummary>
					<Row style={{ height: 36.5 }}>
						<Typography variant="subtitle2">Details</Typography>
					</Row>
				</AccordionSummary>
				<AccordionDetails sx={{ flexDirection: 'column' }}>
					<Column>
						<Divider sx={{ marginTop: -1, marginBottom: 1 }} />

						<Rating
							feature={feature}
							featurePayload={featurePayload}
							setFeaturePayload={setFeaturePayload}
						/>

						<Questions
							feature={feature}
							featurePayload={featurePayload}
							setFeaturePayload={setFeaturePayload}
						/>

						<Notes
							featurePayload={featurePayload}
							setFeaturePayload={setFeaturePayload}
						/>
					</Column>
				</AccordionDetails>
			</Accordion>
		</Column>
	);
};
