import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip } from '@mui/material';
import { DiffResult } from 'store/actions/properties/units';

interface Props {
	diffResult: DiffResult;
}

const ActionChip = ({ diffResult }: Props) => {
	if (diffResult.operation === 'remove') {
		return (
			<Chip
				variant="outlined"
				sx={{ ...chipSx, backgroundColor: '#232E3A1A' }}
				label="Not Applicable"
			/>
		);
	}

	return (
		<Chip
			variant="outlined"
			sx={chipSx}
			label="Added"
			icon={<FontAwesomeIcon icon={regular('search-plus')} />}
		/>
	);
};

const chipSx = {
	fontWeight: 500,
	borderColor: '#232E3A1A',
	width: 150,
};

export default ActionChip;
