import React from 'react';
import styled from 'styled-components';
import { colors } from '../../theme';

interface Props {
	accept: string;
	setFile: (file: File | undefined) => void;
	file: File | undefined;
}

const Uploader = ({ setFile, file, accept }: Props) => {
	const ref = React.useRef(document.createElement('input'));
	const title = file ? file.name : 'Browse to select a file';

	return (
		<>
			<ClickableText underlined={!file} onClick={() => ref.current.click()}>
				{title}
			</ClickableText>
			<input
				type="file"
				accept={accept}
				ref={ref}
				hidden
				onChange={(event) => {
					const file = event.target.files?.[0];
					if (file) setFile(file);
				}}
			/>
		</>
	);
};

const ClickableText = styled.a<{ underlined?: boolean }>`
	color: ${({ underlined }) => (underlined ? colors.primary : colors.black)};
	${({ underlined }) => (underlined ? 'text-decoration: underline;' : '')};
	&:hover {
		cursor: pointer;
	}
`;

export default Uploader;
