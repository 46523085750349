import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Divider, Typography } from '@mui/material';
import { Spacer } from 'components';
import IconButton from 'components/icon-button';
import { Column } from 'components/layout/Column';
import { SpacedRow } from 'components/layout/Row';
import styled from 'styled-components';
import { mediaSize, spacingPx } from 'theme';

interface Props {
	title: string;
	onClose?: () => void;
}

const InnerDialogTitle = ({ title, onClose }: Props) => {
	return (
		<>
			<Wrapper>
				<Spacer width={8} />

				<Column>
					<Typography variant="overline">{title}</Typography>
				</Column>

				{!!onClose && <IconButton icon={regular('times')} onClick={onClose} />}
				{!onClose && <Spacer width={8} />}
			</Wrapper>
			<Divider />
		</>
	);
};

const Wrapper = styled(SpacedRow)`
	padding: ${spacingPx(3)} ${spacingPx(7)} ${spacingPx(2)} ${spacingPx(7)};

	@media ${mediaSize.mobile} {
		paddingleft: ${spacingPx(4)};
		paddingright: ${spacingPx(4)};
	}
`;

export default InnerDialogTitle;
