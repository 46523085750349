import { LatchelApi } from '@rentcheck/api-frontend';
import { Dispatch } from 'types';

import * as ActiveProfileActions from '../active-profile';

export const disconnect = (organizationIds: string[], apiKey: string) => {
	return async (dispatch: Dispatch): Promise<void> => {
		return LatchelApi.disconnect(organizationIds, apiKey).then(() =>
			dispatch(ActiveProfileActions.refreshUser())
		);
	};
};
