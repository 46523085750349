import fbConfig from 'config/fbConfig';
import Reactotron from 'config/reactotronConfig';
import { getFirebase, reactReduxFirebase } from 'react-redux-firebase';
import { applyMiddleware, compose, createStore } from 'redux';
import { getFirestore, reduxFirestore } from 'redux-firestore';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import rootReducer from './reducers/rootReducer';
import { inspectionsTransform } from './transforms';

// @ts-expect-error
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['inspections'],
	transforms: [inspectionsTransform],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
	persistedReducer,
	composeEnhancers(
		applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })),
		Reactotron.createEnhancer?.(),
		reduxFirestore(fbConfig),
		reactReduxFirebase(fbConfig, {
			useFirestoreForProfile: true,
			userProfile: 'users',
			attachAuthIsReady: true,
		})
	)
);

export const persistor = persistStore(store);

export default store;
