import { FileRequestApi } from '@rentcheck/api-frontend';
import { Rules } from '@rentcheck/biz';
import { ApiInspection } from '@rentcheck/types';
import MenuItem from 'components/menu-item';
import { useStoreFeatures } from 'hooks/features';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';
import { Dispatch } from 'types';

interface Props {
	inspection: ApiInspection;
	onSelected: () => void;
}

export default ({ inspection, onSelected }: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const features = useStoreFeatures(inspection.id);

	const handleClick = () => {
		onSelected();

		const message = `CSV report download request is being queued. You should receive an email with a link to the report shortly.`;

		dispatch(SnackbarActions.showSuccess(message));
		FileRequestApi.create(
			'MAINTENANCE_FLAGS_LIST_EXPORT',
			inspection.id,
			'CSV',
			{
				inspection_id: inspection.id,
			}
		);
	};

	if (!Rules.Inspections.canDownloadMaintenanceFlags(inspection, features)) {
		return null;
	}

	return <MenuItem onClick={handleClick}>Download Maintenance Flags</MenuItem>;
};
