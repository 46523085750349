import { DashboardProperty } from '@rentcheck/api-frontend';
import { ApiInspection } from '@rentcheck/types';
import _ from 'lodash';
import moment from 'moment';
import { State } from './list';

export const dashboardListInspectionSelectors = [
	'almostDue',
	'awaitingReview',
	'overdue',
	'undeliveredEmails',
	'revisionRequested',
	'awaitingSignature',
	'unassigned',
] as const;

export const dashboardListPropertySelectors = ['propertiesAtRisk'] as const;

export const dashboardListSelectors = [
	...dashboardListInspectionSelectors,
	...dashboardListPropertySelectors,
] as const;

export type DashboardListSelector = (typeof dashboardListSelectors)[number];

export const mergeObjectsIntoState = (
	state: State,
	objects: ApiInspection[] | DashboardProperty[],
	selector: DashboardListSelector
) => {
	return _.set({ ...state }, selector, objects);
};

export const removeInspectionFromStates = (
	state: State,
	object: ApiInspection
) => {
	const updatedState = { ...state };

	dashboardListInspectionSelectors.forEach((selector) => {
		const inspections: ApiInspection[] = _.get(updatedState, selector);

		if (inspections?.find((i) => i.id === object.id)) {
			_.set(
				updatedState,
				selector,
				inspections.filter((i) => i.id !== object.id)
			);
		}
	});

	return updatedState;
};

export const removeInspectionsFromStates = (
	state: State,
	objects: ApiInspection[]
) => {
	let updatedState: State = { ...state };

	objects.forEach((inspection) => {
		updatedState = removeInspectionFromStates(updatedState, inspection);
	});

	return updatedState;
};

export const updateInspectionFromStates = (
	state: State,
	object: ApiInspection
) => {
	const inspectionStateSelectors = [
		'almostDue',
		'awaitingReview',
		'overdue',
		'undeliveredEmails',
		'revisionRequested',
		'awaitingSignature',
		'unassigned',
	];

	const updatedState = { ...state };

	inspectionStateSelectors.forEach((selector) => {
		const inspections: ApiInspection[] = _.get(updatedState, selector);

		if (inspections?.find((i) => i.id === object.id)) {
			_.set(
				updatedState,
				selector,
				inspections.map((i) => (i.id === object.id ? object : i))
			);
		}
	});

	return updatedState;
};

export const updateInspectionsFromStates = (
	state: State,
	objects: ApiInspection[]
) => {
	if (!objects) return state;

	let updatedState: State = { ...state };
	objects.forEach((inspection) => {
		updatedState = updateInspectionFromStates(updatedState, inspection);
	});

	return updatedState;
};

const endOfYesterday = moment().endOf('day').subtract(1, 'days');
const startOfToday = moment().startOf('day');
const endOf3DaysToCome = moment().endOf('day').add(3, 'days');

export const insertInspectionIntoStatesIfApplicable = (
	state: State,
	objects: ApiInspection[]
) => {
	const updatedState = { ...state };

	objects.forEach((o) => {
		const inspectionDate = moment(o.due_date);

		if (endOfYesterday.isBefore(inspectionDate)) {
			const currentInspections = _.get(updatedState, 'overdue', []);
			_.set(updatedState, 'overdue', [...currentInspections, o]);
		}

		if (
			startOfToday.isBefore(inspectionDate) &&
			endOf3DaysToCome.isAfter(inspectionDate)
		) {
			const currentInspections = _.get(updatedState, 'almostDue', []);
			_.set(updatedState, 'almostDue', [...currentInspections, o]);
		}
	});

	return updatedState;
};

export const removeIncorrectInspectionsFromOverdue = (state: State) => {
	const updatedState = { ...state };

	const overdueInspections: ApiInspection[] = _.get(
		updatedState,
		'overdue',
		[]
	);

	const inspectionsToKeep = overdueInspections.filter((i) => {
		const inspectionDate = moment(i.due_date);
		return endOfYesterday.isAfter(inspectionDate);
	});

	_.set(updatedState, 'overdue', inspectionsToKeep);

	return updatedState;
};

export const removeIncorrectInspectionsFromAlmostDue = (state: State) => {
	const updatedState = { ...state };

	const almostDueInspections: ApiInspection[] = _.get(
		updatedState,
		'almostDue',
		[]
	);

	const inspectionsToKeep = almostDueInspections.filter((i) => {
		const inspectionDate = moment(i.due_date);
		return (
			startOfToday.isBefore(inspectionDate) &&
			endOf3DaysToCome.isAfter(inspectionDate)
		);
	});

	_.set(updatedState, 'almostDue', inspectionsToKeep);

	return updatedState;
};

export const removeIncorrectInspectionsFromAwaitingReview = (state: State) => {
	const updatedState = { ...state };

	const awaitingReviewInspections: ApiInspection[] = _.get(
		updatedState,
		'awaitingReview',
		[]
	);

	const inspectionsToKeep = awaitingReviewInspections.filter(
		(i) => i.inspection_status === 'Awaiting Review'
	);

	_.set(updatedState, 'awaitingReview', inspectionsToKeep);

	return updatedState;
};

export const removeIncorrectInspectionsFromStates = (state: State) => {
	return removeIncorrectInspectionsFromAlmostDue(
		removeIncorrectInspectionsFromOverdue(
			removeIncorrectInspectionsFromAwaitingReview(state)
		)
	);
};

export const removePropertyFromStates = (state: State, id: string) => {
	const updatedState = { ...state };

	dashboardListPropertySelectors.forEach((selector) => {
		const properties: DashboardProperty[] = _.get(updatedState, selector);

		if (properties.find((i) => i.id === id)) {
			_.set(
				updatedState,
				selector,
				properties.filter((i) => i.id !== id)
			);
		}
	});

	return updatedState;
};

export const removePropertiesFromStates = (state: State, ids: string[]) => {
	let updatedState: State = { ...state };

	ids.forEach((id) => {
		updatedState = removePropertyFromStates(updatedState, id);
	});

	return updatedState;
};

export const updatePropertyFromStates = (
	state: State,
	object: DashboardProperty
) => {
	const stateSelectors = ['propertiesAtRisk'];

	const updatedState = { ...state };

	stateSelectors.forEach((selector) => {
		const completeSelector = `${selector}`;
		const properties: DashboardProperty[] = _.get(
			updatedState,
			completeSelector
		);

		if (properties.find((i) => i.id === object.id)) {
			_.set(
				updatedState,
				completeSelector,
				properties.map((i) => (i.id === object.id ? object : i))
			);
		}
	});

	return updatedState;
};

export const updatePropertiesFromStates = (
	state: State,
	objects: DashboardProperty[]
) => {
	let updatedState: State = { ...state };

	objects.forEach((property) => {
		updatedState = updatePropertyFromStates(updatedState, property);
	});

	return updatedState;
};
