import { Skeleton as MuiSkeleton } from '@mui/material';

const Skeleton = () => {
	return (
		<MuiSkeleton
			variant="rectangular"
			sx={{ borderRadius: 5, marginRight: 2 }}
			height={32}
			width={160}
		/>
	);
};

export default Skeleton;
