import React from 'react';
import { MenuItem } from '@mui/material';
import { SpacedRow } from 'components';
import { colors } from 'theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { testId } from 'utils/helpers';

interface Props {
	title: string;
	icon: IconProp;
	onClick: () => void;
}

const Item = ({ title, icon, onClick }: Props) => {
	return (
		<MenuItem onClick={onClick} id={`account-dropdown-${testId(title)}`}>
			<SpacedRow style={{ width: 120, marginRight: 16 }}>
				<div>{title}</div>
				<FontAwesomeIcon
					icon={icon}
					color={colors.gray}
					style={{ fontSize: 14 }}
				/>
			</SpacedRow>
		</MenuItem>
	);
};

export default Item;
