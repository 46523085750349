import { combineReducers } from 'redux';
import inspectionsReducer, { State as InspectionsState } from './inspections';
import inspectionReducer, { State as InspectionState } from './inspection';
import filtersReducer, { State as FiltersState } from './filters';

export type State = {
	inspections: InspectionsState;
	inspection: InspectionState;
	filters: FiltersState;
};

const reducer = combineReducers({
	filters: filtersReducer,
	inspections: inspectionsReducer,
	inspection: inspectionReducer,
});

export default reducer;
