import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Button,
	Chip,
	DialogActions,
	DialogContent,
	Divider,
	Typography,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { WorkOrder } from '@rentcheck/api-frontend';
import { success } from 'assets';
import { CenteredColumn, Row, SpacedRow, Spacer } from 'components';
import { ModalFlowActions } from 'store/actions';

interface Props {
	workOrder: WorkOrder;
}

const SelectSkill = ({ workOrder }: Props) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const handleViewWorkOrder = () => {
		history.push(`/maintenance/work-orders/${workOrder.id}`);
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	const handleDone = () => {
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	return (
		<>
			<DialogContent>
				<CenteredColumn>
					<Spacer height={10} />
					<img style={{ width: '60%' }} src={success.workOrder} />
					<Spacer height={6} />
					<Typography variant="h4" fontWeight={400}>
						Success!
					</Typography>
					<Spacer height={4} />
					<Typography textAlign="center">
						A work order titled '{workOrder.name}' was succesfully created in
						Latchel.
					</Typography>
					<Typography>
						You can see the status of this work order in the{' '}
						<Link
							to="/maintenance/work-orders"
							style={{ color: '#000', textDecoration: 'underline' }}
							onClick={handleDone}>
							Maintenance Tab.
						</Link>
					</Typography>
				</CenteredColumn>

				<Divider sx={{ marginBottom: 2, marginTop: 4 }} />

				<SpacedRow>
					<Row>
						<FontAwesomeIcon icon={solid('check-circle')} color="#5eb168" />
						<Spacer width={2} />
						<Typography fontWeight={600}>{workOrder.name}</Typography>
					</Row>
					<Chip
						sx={{ fontWeight: 600 }}
						label="Work Order Created"
						icon={<FontAwesomeIcon icon={solid('wrench')} />}
					/>
				</SpacedRow>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					color="secondary"
					onClick={handleViewWorkOrder}>
					View your Work Order
				</Button>
				<Button onClick={handleDone}>Done</Button>
			</DialogActions>
		</>
	);
};

export default SelectSkill;
