import { LoadingButton } from '@mui/lab';
import {
	Button,
	DialogActions,
	DialogContent,
	MenuItem,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import _ from 'lodash';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { MaintenanceFlag } from '@rentcheck/types';

import { FeaturesApi } from 'api';
import { Spacer } from 'components';
import FormTextField from 'components/form-text-field';
import { useCreateWorkOrderModalData } from 'hooks/modals';
import {
	FeatureActions,
	ModalFlowActions,
	SnackbarActions,
} from 'store/actions';
import { Dispatch } from 'types';

import { getModalConfig } from '../common/modal-config';
import { handleNativeAppClose } from '../common/native-app';

interface Props {
	validSkills: string[];
}
export default ({ validSkills }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const modalData = useCreateWorkOrderModalData('Rent Manager');
	const { flags: modalFlags, provider } = modalData?.metadata ?? {};

	const [flags] = useState<MaintenanceFlag[]>(modalFlags);
	const [skillsMap, setSkillsMap] = useState<Record<string, string>>({});
	const [loading, setLoading] = useState(false);

	const handleClose = () => {
		handleNativeAppClose();
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	const handleSelectedMapping = (skill: string, appFolioSkill: string) => {
		const newMap = { ...skillsMap };
		newMap[skill] = appFolioSkill;

		setSkillsMap(newMap);
	};

	const handleReassignSkills = async () => {
		setLoading(true);

		for (const flag of invalidSkillFlags) {
			await FeaturesApi.getById(flag.feature_id)
				.then((feature) => {
					if (!feature) {
						throw Error(
							`Couldn't find feature for maintenance flag ${flag.feature_id}`
						);
					}

					return dispatch(
						FeatureActions.upsertMaintenanceFlag(feature, {
							...flag,
							skill: skillsMap[flag.skill],
						})
					);
				})
				.catch((e) => dispatch(SnackbarActions.showError(e.message)))
				.finally(() => setLoading(false));
		}
	};

	const modalConfig = getModalConfig(provider);

	const invalidSkillFlags = flags.filter((f) => !validSkills.includes(f.skill));

	const groupedFlags = _.groupBy(invalidSkillFlags, 'skill');
	const skills = _.keys(groupedFlags);

	const disabled = skills.filter((s) => !skillsMap[s]).length > 0;

	return (
		<>
			<DialogContent>
				<Typography variant="body2" color="#00000099" sx={{ mb: 2 }}>
					Please choose a {modalConfig.vendor} {modalConfig.mappedSkillName} for
					the following flag skills.
				</Typography>
				<TableContainer component={Paper}>
					<Table>
						<TableHead>
							<TableCell>Skill</TableCell>
							<TableCell>
								{modalConfig.vendor} {modalConfig.mappedSkillName}
							</TableCell>
						</TableHead>
						<TableBody>
							{skills.map((skill) => {
								const flags = groupedFlags[skill];
								const value = skillsMap[skill];

								return (
									<TableRow key={skill}>
										<TableCell>
											<Typography variant="subtitle2">{skill}</Typography>
											<Typography variant="body1">
												Total Flags: {flags.length}
											</Typography>
										</TableCell>
										<TableCell>
											<FormTextField
												select
												value={value || 'placeholder'}
												placeholder={`Choose a ${modalConfig.mappedSkillName}`}
												inputSx={{ mt: 0, mb: 0 }}
												onChange={(e) =>
													handleSelectedMapping(skill, e.target.value)
												}>
												{validSkills.map((vt) => (
													<MenuItem value={vt}>{vt}</MenuItem>
												))}
											</FormTextField>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
				<Spacer height={4} />
			</DialogContent>
			<DialogActions>
				<Button color="secondary" variant="outlined" onClick={handleClose}>
					Back
				</Button>
				<LoadingButton
					disabled={disabled}
					variant="contained"
					loading={loading}
					onClick={handleReassignSkills}>
					Next
				</LoadingButton>
			</DialogActions>
		</>
	);
};
