import { Dialog, DialogTitle, Typography } from '@mui/material';
import { CenteredColumn, SpacedRow } from 'components';
import { useCreateWorkOrderModalData } from 'hooks/modals';
import { useDispatch } from 'react-redux';
import { ModalFlowActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';
import { formattedInspectionAddress } from 'utils/helpers';

import CloseButton from '../common/close-button';
import TitleSpacer from '../common/title-spacer';

import Steps from './steps';

interface Props {
	open: boolean;
}

const FlagFeature = ({ open }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const profile = useTypedSelector((state) => state.activeProfile);

	const modalData = useCreateWorkOrderModalData('Latchel');
	const { inspection, flags } = modalData?.metadata ?? {};

	if (!profile || !inspection || !flags) return null;

	const handleClose = () => {
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle sx={{ backgroundColor: '#232e3a' }}>
				<SpacedRow>
					<TitleSpacer />
					<CenteredColumn>
						<Typography variant="h4" fontWeight={500} color="white">
							Create Latchel Work Order
						</Typography>
						<Typography color="white">
							{formattedInspectionAddress(inspection)}
						</Typography>
					</CenteredColumn>
					<CloseButton color="white" />
				</SpacedRow>
			</DialogTitle>
			<Steps />
		</Dialog>
	);
};

export default FlagFeature;
