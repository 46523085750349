import { Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { ActionsChip, Spacer } from 'components';
import {
	ModalFlowActions,
	PropertiesActions,
	SnackbarActions,
} from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';
import { DeleteActionButton } from '../../../components/delete-action-button';

interface Props {
	children?: React.ReactNode | React.ReactNodeArray;
	buildingIds: string[];
	setBuildingIds: (ids: string[]) => void;
	disableDeletion?: boolean;
}

const ActionsMenu = ({
	children,
	buildingIds,
	setBuildingIds,
	disableDeletion,
}: Props) => {
	const dispatch: Dispatch = useDispatch();

	const buildings = useTypedSelector(
		(state) => state.properties.buildings.data
	);

	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const handleClick = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		setAnchorEl(e.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const deleteBuildings = () => {
		dispatch(PropertiesActions.buildings.remove(buildingIds))
			.then(() => setBuildingIds([]))
			.catch(() => dispatch(SnackbarActions.showError()));
	};

	const handleCreateInspection = async () => {
		handleClose();

		if (buildingIds.length === 0) {
			return;
		}

		const properties = buildings.filter((b) => buildingIds.includes(b.id));

		dispatch(
			ModalFlowActions.showCreateInspectionModal({
				properties,
				property_type: 'building',
			})
		);
	};

	const handleDelete = () => {
		handleClose();

		if (buildingIds.length === 0) {
			return;
		}

		const numberofBuildings = `${buildingIds.length} building${
			buildingIds.length === 1 ? '' : 's'
		}`;

		const body = [
			'Are you sure you want to delete these buildings?',
			`${numberofBuildings} will be deleted and this update is irreversible.`,
		];

		dispatch(
			ModalFlowActions.showConfirmationModal({
				title: 'Are you sure?',
				body1: body,
				body2: [],
				buttons: [
					ModalFlowActions.cancelButton(dispatch),
					{
						title: 'Delete',
						color: 'error',
						variant: 'contained',
						onClick: deleteBuildings,
					},
				],
			})
		);
	};

	return (
		<>
			<div onClick={handleClick}>
				{children ?? (
					<ActionsChip
						ids={buildingIds}
						openMenu={(e: React.MouseEvent<HTMLElement>) => handleClick(e)}
					/>
				)}
			</div>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={!!anchorEl}
				onClose={handleClose}>
				<MenuItem onClick={handleCreateInspection}>
					Create Inspection
					<Spacer width={4} />
				</MenuItem>
				<DeleteActionButton disabled={disableDeletion} onClick={handleDelete} />
			</Menu>
		</>
	);
};

export default ActionsMenu;
