import { TableCell, TableRow, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Chip, { SnapshotLabel } from './chip';
import ReviewMarker from './review-marker';
import SkeletonRow from './skeleton-row';

interface Props {
	label: SnapshotLabel;
	link: string;
	count?: number;
}

const SnapshotRow = ({ label, link, count }: Props) => {
	const history = useHistory();

	if (!count && count !== 0) {
		return <SkeletonRow />;
	}

	const handleClick = () => {
		history.push(link);
	};

	return (
		<StyledTableRow count={count} style={{ height: 80 }} onClick={handleClick}>
			<TableCell sx={{ paddingLeft: 4 }}>
				<Typography variant="subtitle2">{count}</Typography>
			</TableCell>
			<TableCell>
				<Chip label={label} />
			</TableCell>
			<TableCell align="right" sx={{ paddingRight: 4 }}>
				<ReviewMarker count={count} />
			</TableCell>
		</StyledTableRow>
	);
};

const StyledTableRow = styled(TableRow)<{ count: number }>`
	cursor: pointer;
	background-color: ${({ count }) => (count === 0 ? '#f8f8f8' : '')};
	transition: background-color 0.3s;

	&:hover {
		background-color: #f8f8f8;
	}
`;

export default SnapshotRow;
