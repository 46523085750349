import { Tooltip } from '@mui/material';
import { ImportDigest, ImportOperation } from '@rentcheck/types';
import _ from 'lodash';

interface Props {
	object: ImportDigest;
}

const mapOperationToDisplayOperation = (operation: ImportOperation) => {
	switch (operation) {
		case 'create':
			return 'Create';
		case 'create/update':
			return 'Create and Update';
		case 'delete':
			return 'Delete';
	}
};

const extractFileName = (path: string) => {
	const parts = path.split('/');
	const filename = parts[parts.length - 1];

	/**
	 * Some files uploaded to s3 include the query string
	 * after the extension, this removes that query string
	 */
	const parts2 = filename.split('?');
	const cleanFilename = parts2[0];

	return cleanFilename;
};

export default ({ object }: Props) => {
	const type = `${mapOperationToDisplayOperation(object.operation)} 
						${_.capitalize(object.model)}s`;

	return (
		<Tooltip title={`Download ${extractFileName(object.source_file_path)}`}>
			<a
				href={object.source_file_path}
				target="_blank"
				style={{ color: 'inherit', textDecoration: 'underline' }}>
				{type}
			</a>
		</Tooltip>
	);
};
