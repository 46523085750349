import { TableCell, TableHead, styled } from '@mui/material';

export default () => {
	return (
		<TableHead>
			<HeaderTableCell>Skill</HeaderTableCell>
			<HeaderTableCell>Feature</HeaderTableCell>
			<HeaderTableCell>Notes</HeaderTableCell>
			<HeaderTableCell>Responsibility</HeaderTableCell>
			<HeaderTableCell>Cost</HeaderTableCell>
			<HeaderTableCell align="center">Media</HeaderTableCell>
			<HeaderTableCell align="center">View</HeaderTableCell>
		</TableHead>
	);
};

const HeaderTableCell = styled(TableCell)`
	background-color: #fff !important;
`;
