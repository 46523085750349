import { Spacer } from 'components';
import _ from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	SxProps,
	Typography,
} from '@mui/material';
import { ConfirmationModalData } from 'store/reducers/modal-flows';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { colors } from 'theme';

interface Props {
	open: boolean;
}

const dialogContentStyle: SxProps = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
};

const ConfirmationModal = ({ open }: Props) => {
	const confirmationModalData = useTypedSelector(
		(state) => state.modalFlows.confirmation_modal as ConfirmationModalData
	);

	const hasSingleButton = confirmationModalData?.buttons.length === 1;

	const buttonsExceptLast = _.initial(confirmationModalData?.buttons ?? []);
	const lastButton = _.last(confirmationModalData?.buttons ?? []);

	if (!confirmationModalData) {
		return null;
	}

	const buttonSx: SxProps = {
		textTransform: 'uppercase',
		':not(:first-of-type)': { marginLeft: 0, marginTop: 1 },
	};

	return (
		<Dialog open={open} maxWidth="xs">
			<DialogTitle
				sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
				{confirmationModalData.image && (
					<img src={confirmationModalData.image} style={{ marginBottom: 12 }} />
				)}
				{confirmationModalData.icon && (
					<FontAwesomeIcon
						icon={confirmationModalData.icon}
						color="#2D3CE6"
						style={{ marginBottom: 12 }}
					/>
				)}
				<Typography variant="subtitle1" align="center">
					{confirmationModalData.title}
				</Typography>
			</DialogTitle>

			<DialogContent sx={dialogContentStyle}>
				{confirmationModalData?.body1.map((b1) => (
					<Typography
						variant="body2"
						align="center"
						color={colors.secondary}
						mt={1}>
						{b1}
					</Typography>
				))}

				<Spacer height={4} />

				{confirmationModalData?.body2?.map((b2) => (
					<Typography variant="body2" align="center" color={colors.secondary}>
						{b2}
					</Typography>
				))}
			</DialogContent>

			<DialogActions
				sx={{
					display: 'flex',
					borderTop: 'none',
					flexDirection: 'column',
					justifyContent: hasSingleButton ? 'center' : 'space-between',
					padding: '0 28px 20px 28px',
				}}>
				{lastButton && (
					<LoadingButton
						fullWidth
						sx={buttonSx}
						variant="text"
						key={lastButton.title}
						onClick={lastButton.onClick}
						color={lastButton.color ?? 'primary'}
						loading={confirmationModalData?.loading}>
						{lastButton.title}
					</LoadingButton>
				)}
				{buttonsExceptLast.map((button) => (
					<Button
						fullWidth
						sx={buttonSx}
						variant="text"
						key={button.title}
						onClick={button.onClick}
						color={button.color ?? 'primary'}>
						{button.title}
					</Button>
				))}
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmationModal;
