import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	ButtonBase,
	CardContent,
	Chip,
	Card as MuiCard,
	Typography,
} from '@mui/material';
import { Row, SpacedRow, Spacer } from 'components';
import PlanGateAnimatedIcon from 'components/plan-gate/animated-icon';
import { PlanGateContext } from 'components/plan-gate/context';
import moment from 'moment';
import { useContext } from 'react';
import styled from 'styled-components';
import { colors } from 'theme';
import Skeleton from './skeleton';

interface Props {
	title: string;
	icon: IconProp;
	body: string;
	isBeta?: boolean;
	onClick: () => void;

	/**
	 * Date string in the format 'YYYY-MM-DD' that indicates when the 'New'
	 * badge should be hidden.
	 */
	newUntil?: string;

	/**
	 * If set to true the card will render as a skeleton loader.
	 */
	loading?: boolean;
}

export default ({
	title,
	icon,
	body,
	onClick,
	isBeta,
	newUntil,
	loading,
}: Props) => {
	const context = useContext(PlanGateContext);

	if (loading) {
		return <Skeleton />;
	}

	const isNew = newUntil && moment(newUntil).isAfter(moment());

	return (
		<ButtonBase
			sx={{ height: '100%', width: '100%', textAlign: 'left' }}
			onClick={onClick}>
			<Card elevation={0}>
				<CardContent>
					<SpacedRow>
						<Row>
							{context.accessAllowed && (
								<FontAwesomeIcon icon={icon} size="lg" />
							)}
							{!context.accessAllowed && (
								<PlanGateAnimatedIcon variant="blue" width={16} />
							)}
							<Spacer width={3} />
							<Typography variant="h6">{title}</Typography>
						</Row>
						<Row style={{ gap: 8 }}>
							{isBeta && (
								<Chip
									label="Beta"
									sx={{
										backgroundColor: '#2D3CE614',
										'.MuiChip-icon': {
											color: colors.primary,
										},
									}}
									icon={<FontAwesomeIcon icon={regular('message-smile')} />}
								/>
							)}
							{isNew && (
								<Chip
									label="New"
									sx={{
										backgroundColor: '#2D3CE614',
										'.MuiChip-icon': {
											color: colors.primary,
										},
									}}
									icon={<FontAwesomeIcon icon={regular('sparkles')} />}
								/>
							)}
						</Row>
					</SpacedRow>
					<Typography mt={2}>{body}</Typography>
				</CardContent>
			</Card>
		</ButtonBase>
	);
};

const Card = styled(MuiCard)`
	border: 1px solid #0000001f;
	flex-grow: 1;
	height: 100%;
	transition: background-color 0.2s ease-in-out;

	:hover {
		background-color: #f6f5ff;
	}
`;
