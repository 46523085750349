import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Typography } from '@mui/material';
import { TemplateFeature, TemplateQuestion } from '@rentcheck/types';
import { Row, SpacedRow } from 'components';
import IconButton from 'components/icon-button';
import { areQuestionsIncomplete, isFeatureIncomplete } from '../../../helpers';

interface Props {
	feature: TemplateFeature;
	question: TemplateQuestion;
	editing: boolean;

	onDelete: () => void;
	onCopy: () => void;
	onAdd: () => void;
}

export default ({
	feature,
	question,
	editing,
	onDelete,
	onCopy,
	onAdd,
}: Props) => {
	const handleDelete = (e?: React.MouseEvent<HTMLButtonElement>) => {
		e?.stopPropagation();
		onDelete();
	};

	const handleCopy = (e?: React.MouseEvent<HTMLButtonElement>) => {
		e?.stopPropagation();
		onCopy();
	};

	const handleAdd = (e?: React.MouseEvent<HTMLButtonElement>) => {
		e?.stopPropagation();
		onAdd();
	};

	const otherQuestions = feature.questions.filter((q) => q.id !== question.id);
	const buttonsDisabled =
		areQuestionsIncomplete(otherQuestions) ||
		(isFeatureIncomplete(feature) && !areQuestionsIncomplete(otherQuestions));

	return (
		<>
			<SpacedRow>
				<Typography variant="subtitle1">{question.title}</Typography>
				{editing && (
					<Row>
						<IconButton
							disabled={buttonsDisabled}
							icon={regular('plus-circle')}
							tooltip="Add Question"
							onClick={handleAdd}
						/>
						<IconButton
							disabled={buttonsDisabled}
							icon={regular('copy')}
							tooltip="Copy Question"
							onClick={handleCopy}
						/>
						<IconButton
							disabled={buttonsDisabled}
							icon={regular('trash')}
							tooltip="Delete"
							onClick={handleDelete}
						/>
					</Row>
				)}
			</SpacedRow>
			{question.type === 'yes/no' && (
				<Typography color="secondary" mt={1}>
					Yes/No
				</Typography>
			)}

			{question.type === 'free text' && (
				<Typography color="secondary" mt={1}>
					Open Text
				</Typography>
			)}

			{question.type === 'multiple choice' &&
				question.answers.map((a) => (
					<Typography color="secondary" mt={1}>
						{a.title}
					</Typography>
				))}

			{question.type === 'asset capture' && (
				<Typography color="secondary" mt={1}>
					Asset Capture
				</Typography>
			)}
		</>
	);
};
