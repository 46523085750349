import { Rules } from '@rentcheck/biz';
import { ApiInspectionWithTemplate } from '@rentcheck/types';
import { formatDate } from 'utils/helpers';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { SpacedRow } from 'components';
import IconButton from 'components/icon-button';
import { useDispatch } from 'react-redux';
import { ModalFlowActions } from 'store/actions';
import { Dispatch } from 'types';

import GridItem from '../grid-item';

interface Props {
	inspection: ApiInspectionWithTemplate;
}

export default ({ inspection }: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const handleInviteDate = () => {
		dispatch(
			ModalFlowActions.showEditDueDateModal({ inspections: [inspection] })
		);
	};

	if (!Rules.Inspections.canShowInviteDate(inspection)) {
		return null;
	}

	return (
		<GridItem
			title="Invitation Date"
			value={
				<>
					<SpacedRow>
						{formatDate(inspection.invite_date)}{' '}
						{Rules.Inspections.canEditDueDate(inspection) && (
							<IconButton
								tooltip="Edit Invite Date"
								onClick={handleInviteDate}
								icon={regular('edit')}
								buttonSx={{ mb: -1, mt: -2 }}
							/>
						)}
					</SpacedRow>
				</>
			}
		/>
	);
};
