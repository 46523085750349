import { Typography } from '@mui/material';

import { Feature, TemplateQuestion } from '@rentcheck/types';

import { Spacer } from 'components';
import { Question } from 'components/features';

interface Props {
	feature: Feature;
	hoveredImage?: string;
	setHoveredQuestion: (question?: TemplateQuestion) => void;
}

export default ({ feature, hoveredImage, setHoveredQuestion }: Props) => {
	if (feature.questions.length === 0) return null;

	return (
		<>
			<Typography variant="overline">Questions</Typography>

			{feature.questions.map((question, index) => (
				<Question
					key={index}
					feature={feature}
					question={question}
					index={index}
					setHoveredQuestion={setHoveredQuestion}
					hoveredImage={hoveredImage}
				/>
			))}

			<Spacer height={5} />
		</>
	);
};
