import { Skeleton } from '@mui/material';
import { Row, SpacedRow, Spacer } from 'components';

const PropertiesSkeleton = () => {
	const headerHeight = 70;
	const rowHeight = 68.5;

	const rows = Array.from(Array(20).keys());

	return (
		<div>
			<SpacedRow>
				<Skeleton variant="text" width={50} height={30} />
				<Row>
					<Skeleton
						variant="rectangular"
						width={32}
						height={32}
						sx={{ borderRadius: 4 }}
					/>
					<Spacer width={2} />
					<Skeleton
						variant="rectangular"
						width={32}
						height={32}
						sx={{ borderRadius: 4 }}
					/>
					<Spacer width={2} />
					<Skeleton
						variant="rectangular"
						width={32}
						height={32}
						sx={{ borderRadius: 4 }}
					/>
				</Row>
			</SpacedRow>
			<Spacer height={6} />
			<Skeleton
				variant="rectangular"
				width="100%"
				height={headerHeight}
				sx={{ borderRadius: 1 }}
			/>
			{rows.map((i) => (
				<Row
					key={i}
					style={{
						height: rowHeight,
						width: '100%',
						marginBottom: 1,
					}}>
					<SpacedRow style={{ flex: 1, paddingLeft: 24, paddingRight: 56 }}>
						<Skeleton variant="text" width={20} height={30} />
						<Spacer width={8} />
						<Skeleton variant="text" width="95%" height={30} />
						<Spacer width={8} />
						<Skeleton
							variant="rectangular"
							width={10}
							height={25}
							sx={{ borderRadius: 1 }}
						/>
					</SpacedRow>
				</Row>
			))}
		</div>
	);
};

export default PropertiesSkeleton;
