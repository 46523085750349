import { Button, Typography } from '@mui/material';
import { ApiTeamDetails } from '@rentcheck/types';
import { Column, Spacer } from 'components';
import styled from 'styled-components';

interface Props {
	team?: ApiTeamDetails;
	close: () => void;
}

const Success = ({ team, close }: Props) => {
	if (!team) return null;

	return (
		<Container>
			<Typography variant="h5">Team Created</Typography>
			<Spacer height={6} />
			<Typography align="center">
				{team.internal_label} was successfully created. You can manage team
				units at any time from the <Bold>Units page</Bold> by uploading new
				units. You can also manage team assignments from the{' '}
				<Bold>Units page</Bold> (when overseeing multiple teams).
			</Typography>
			<Spacer height={6} />
			<Spacer height={10} />
			<Button onClick={close}>Close</Button>
		</Container>
	);
};

const Container = styled(Column)`
	align-items: center;
`;

const Bold = styled.span`
	font-weight: 600;
`;

export default Success;
