import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, CardContent, Typography } from '@mui/material';
import { ApiLatchelIntegration } from '@rentcheck/types';
import { Column, Row, SpacedRow, Spacer } from 'components';
import _ from 'lodash';
import { IntercomAPI } from 'react-intercom';
import { useDispatch } from 'react-redux';
import {
	IntegrationsActions,
	ModalFlowActions,
	SnackbarActions,
} from 'store/actions';
import { colors } from 'theme';
import { Dispatch } from 'types';
import { formatDate } from 'utils/helpers';

interface Props {
	integrations: ApiLatchelIntegration[];
}

const IntegrationCard = ({ integrations }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const integration = _.first(integrations);
	const teams = integrations.map((o) => o.team);
	const teamNames = teams
		.map((t) => t.internal_label)
		.sort((a, b) => a.localeCompare(b))
		.join(', ');

	const handleNeedHelp = () => {
		IntercomAPI('showNewMessage');
	};

	const handleConfirmDisconnect = () => {
		if (!integration) return;

		dispatch(ModalFlowActions.setConfirmationModalLoading(true));
		dispatch(
			IntegrationsActions.latchel.disconnect(
				teams.map((o) => o.id),
				integration.api_key
			)
		)
			.then(() => dispatch(ModalFlowActions.closeConfirmationModal()))
			.catch((e) => {
				dispatch(ModalFlowActions.setConfirmationModalLoading(false));
				dispatch(SnackbarActions.showError(e.message));
			});
	};

	const handleDisconnect = () => {
		dispatch(
			ModalFlowActions.showConfirmationModal({
				title: 'Are you sure?',
				body1: [
					'Are you sure you want to disconnect this Latchel integration?',
				],
				body2: [],
				buttons: [
					ModalFlowActions.cancelButton(dispatch),
					{
						title: 'Disconnect',
						color: 'error',
						variant: 'contained',
						onClick: handleConfirmDisconnect,
					},
				],
			})
		);
	};

	if (!integration) return null;

	return (
		<Card style={{ width: '90%' }} sx={{ marginBottom: 4 }}>
			<CardContent>
				<SpacedRow>
					<Column>
						<Typography fontWeight={600}>{integration.company_name}</Typography>
						<Spacer height={4} />
						<Row>
							<FontAwesomeIcon icon={regular('users')} color={colors.primary} />
							<Spacer width={3} />
							<Typography>{teamNames}</Typography>
						</Row>
						<Spacer height={2} />
						<Row>
							<FontAwesomeIcon icon={regular('link')} color={colors.primary} />
							<Spacer width={3} />
							<Typography>
								Installed by {integration.created_by.name} on{' '}
								{formatDate(integration.created_date)}
							</Typography>
						</Row>
						<Spacer height={2} />
						<Row>
							<FontAwesomeIcon
								icon={regular('comment')}
								color={colors.primary}
							/>
							<Spacer width={3} />
							<Typography
								sx={{ textDecoration: 'underline', cursor: 'pointer' }}
								onClick={handleNeedHelp}>
								Need help?
							</Typography>
						</Row>
					</Column>
					<Button
						color="secondary"
						variant="text"
						onClick={handleDisconnect}
						id={`disconnect-${integration.api_key.slice(-4)}`}>
						Disconnect
					</Button>
				</SpacedRow>
			</CardContent>
		</Card>
	);
};

export default IntegrationCard;
