import * as Sentry from '@sentry/react';
import { firestore } from 'firebase/app';

import { ApplicationSettings } from '@rentcheck/types';

import { objectFromDocumentSnapshot } from './helpers';

const collection = 'application_settings';

export const getApplicationSettings = async () => {
	return firestore()
		.collection(collection)
		.doc('default')
		.get()
		.then((docSnapshot) =>
			objectFromDocumentSnapshot<ApplicationSettings>(docSnapshot)
		);
};

export const listenForApplicationSettings = async (
	setSettings: (value: ApplicationSettings) => void
) => {
	return firestore()
		.collection(collection)
		.doc('default')
		.onSnapshot(
			(snapshot) => {
				const applicationSettings =
					objectFromDocumentSnapshot<ApplicationSettings>(snapshot);

				if (!applicationSettings) return;

				setSettings(applicationSettings);
			},
			(error) => {
				Sentry.captureException(error);
			}
		);
};
