import { Chip } from '@mui/material';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	ApiInspectionWithTemplate,
	Feature,
	MaintenanceFlag,
} from '@rentcheck/types';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { ModalFlowActions } from 'store/actions';
import { useHover } from 'usehooks-ts';

interface Props {
	feature: Feature;
	flag: MaintenanceFlag;
	inspection: ApiInspectionWithTemplate;
	setHoveredFlag?: (flag?: MaintenanceFlag) => void;
}

export default ({ feature, flag, inspection, setHoveredFlag }: Props) => {
	const dispatch = useDispatch();

	const hoverRef = useRef(null);
	const isHovering = useHover(hoverRef);

	useEffect(() => {
		if (!setHoveredFlag) return;

		if (isHovering) {
			setHoveredFlag(flag);
		} else {
			setHoveredFlag(undefined);
		}
	}, [isHovering]);

	const handleEditFlag = (flag: MaintenanceFlag) => {
		dispatch(
			ModalFlowActions.showFlagFeatureModal({ feature, flag, inspection })
		);
	};

	return (
		<div>
			<Chip
				ref={hoverRef}
				label={flag.skill}
				sx={{ mb: 0.5, mt: 0.5, mr: 1, '.MuiChip-icon': { color: '#2D3CE6' } }}
				icon={<FontAwesomeIcon icon={solid('flag')} />}
				onClick={() => handleEditFlag(flag)}
			/>
		</div>
	);
};
