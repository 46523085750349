import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Typography,
} from '@mui/material';
import ContactInfo from './contact-info';
type Props = {
	setEmail: (value: string) => void;
	setPhone: (value: string) => void;
	email: string;
	phone: string;
};
const MoreOptions = ({ email, phone, setEmail, setPhone }: Props) => {
	return (
		<Accordion style={{ boxShadow: 'none' }}>
			<AccordionSummary sx={{ padding: 0 }} expandIcon={<ExpandMoreIcon />}>
				<Typography variant="overline">More Options</Typography>
			</AccordionSummary>

			<AccordionDetails sx={{ padding: 0 }}>
				<ContactInfo
					email={email}
					setEmail={setEmail}
					phone={phone}
					setPhone={setPhone}
				/>
			</AccordionDetails>
		</Accordion>
	);
};

export default MoreOptions;
