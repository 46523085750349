import { ApiTeam } from '@rentcheck/types';
import {
	FilterDetailsProps,
	FilterTitle,
	FilterTitleOption,
	FilterTitleProps,
	FilterType,
} from 'components/lists/filters';
import { useTeams } from 'hooks/teams';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/reducers/rootReducer';

const title: FilterType = 'Team';

const FilterTeamTitle = ({
	selectedFilter,
	setFilterDetails,
}: FilterTitleProps) => {
	const { teams } = useTeams();
	const teamsFilter = useTypedSelector(
		(state) => state.properties.filters.communities.team
	);

	if (teams.length === 0) {
		return null;
	}

	return (
		<FilterTitle
			label={title}
			isActive={teamsFilter.length > 0}
			isSelected={selectedFilter === title}
			onClick={() => setFilterDetails(title)}
		/>
	);
};

const FilterTeamDetails = ({ selectedFilter }: FilterDetailsProps) => {
	const dispatch = useDispatch();

	const { teams } = useTeams();
	const teamsFilter = useTypedSelector(
		(state) => state.properties.filters.communities.team
	);

	if (teams.length === 0) {
		return null;
	}

	if (selectedFilter !== title) {
		return null;
	}

	const handleClick = (organization: ApiTeam) => {
		dispatch({
			type: 'TOGGLE_COMMUNITIES_TEAMS_FILTER',
			value: organization.id,
		});
	};

	return (
		<>
			{teams.map((o) => (
				<FilterTitleOption
					label={o.internal_label}
					isSelected={teamsFilter.includes(o.id)}
					onClick={() => handleClick(o)}
				/>
			))}
		</>
	);
};

export { FilterTeamDetails, FilterTeamTitle };
