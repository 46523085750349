import { Divider, Typography } from '@mui/material';
import { Column } from 'components';
import { FeatureWithMetadata } from 'store/reducers/features';

interface Props {
	feature: FeatureWithMetadata;
}

export default ({ feature }: Props) => {
	if (feature.not_applicable) {
		return null;
	}

	const reviewerNotes = feature.review_notes?.trim();
	const reviewerName = feature.reviewed_by?.name || 'the reviewer';

	if (!reviewerNotes) {
		return null;
	}

	return (
		<Column>
			<Typography variant="overline" mt={2}>
				Notes from {reviewerName}
			</Typography>
			<Typography
				mt={1}
				variant="subtitle2"
				style={{ wordBreak: 'break-word' }}>
				{reviewerNotes}
			</Typography>
			<Divider sx={{ mt: 2 }} />
		</Column>
	);
};
