import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	Divider,
	FormControlLabel,
	InputAdornment,
	TextField,
	Typography,
} from '@mui/material';
import 'react-phone-input-2/lib/style.css';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	ApiInspectionTemplateDigest,
	TemplateFeature,
	TemplateQuestion,
	TemplateSection,
} from '@rentcheck/types';
import { Column, DialogTitle } from 'components';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { numberAndPluralizeWord } from 'utils/helpers';
import { v4 as uuid } from 'uuid';

interface Props {
	section: TemplateSection;
	sections: TemplateSection[];
	template: ApiInspectionTemplateDigest;

	open: boolean;
	setOpen: (open: boolean) => void;

	setSections: (sections: TemplateSection[]) => void;
}

const copyFeatures = (features: TemplateFeature[]) => {
	return features.map((f) => ({
		...f,
		id: uuid(),
		questions: copyQuestions(f.questions),
	}));
};

const copyQuestions = (questions: TemplateQuestion[]) => {
	return questions.map((q) => ({
		...q,
		id: uuid(),
	}));
};

export default ({
	section,
	template,
	open,
	setOpen,
	sections,
	setSections,
}: Props) => {
	const [search, setSearch] = useState('');
	const [selectedSections, setSelectedSections] = useState<TemplateSection[]>(
		[]
	);

	useEffect(() => {
		if (open) return;

		// This is to avoid UI updates when the dialog is being closed
		setTimeout(() => {
			setSearch('');
		}, 500);
	}, [open]);

	const handleToggleValue = (section: TemplateSection) => {
		setSelectedSections(_.xor(selectedSections, [section]));
	};

	const handleCopyToSections = () => {
		setSections(
			sections.map((s) => {
				if (!selectedSections.includes(s)) {
					return s;
				}

				return {
					...s,
					features: copyFeatures(section.features),
				};
			})
		);

		setOpen(false);
	};

	const otherSections = sections.filter((s) => s.id !== section.id);
	const filteredSections = otherSections.filter(
		(s) => !search || s.name.toLowerCase().includes(search.toLowerCase())
	);

	return (
		<Dialog open={open} onClose={() => setOpen(false)}>
			<DialogTitle
				title={`Copy ${section.name} Features`}
				bottomSubtitle={`${template.name} Inspection`}
				icon={regular('copy')}
				onClose={() => setOpen(false)}
			/>
			<DialogContent sx={{ flexShrink: 0, pb: 0 }}>
				<Typography variant="overline">{section.name} features</Typography>
				<Typography variant="subtitle2" mt={1}>
					{numberAndPluralizeWord(section.features.length, 'Feature')}
				</Typography>

				<Divider sx={{ mt: 2, mb: 2 }} />

				<Typography variant="overline">Sections</Typography>
				<Typography color="secondary" mt={1} mb={2}>
					Select sections to copy {section.name} features to. Copying will
					replace any features in a section and overwrite any settings.
				</Typography>
				<TextField
					placeholder="Search"
					variant="outlined"
					fullWidth
					value={search}
					onChange={(e) => setSearch(e.target.value)}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<FontAwesomeIcon icon={regular('search')} />
							</InputAdornment>
						),
					}}
				/>
			</DialogContent>
			<DialogContent sx={{ pt: 1, minHeight: '40vh' }}>
				<Column>
					{filteredSections.map((s, idx) => (
						<>
							<FormControlLabel
								sx={{ mt: 1, mb: 1 }}
								label={s.name}
								control={
									<Checkbox
										checked={selectedSections.includes(s)}
										onChange={() => handleToggleValue(s)}
									/>
								}
							/>
							{idx < filteredSections.length - 1 && <Divider />}
						</>
					))}
				</Column>
			</DialogContent>
			<DialogActions>
				<Button variant="text" onClick={() => setOpen(false)} color="secondary">
					Cancel
				</Button>
				<Button
					disabled={!selectedSections.length}
					onClick={handleCopyToSections}>
					Copy
				</Button>
			</DialogActions>
		</Dialog>
	);
};
