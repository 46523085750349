import { ApiInspectionWithTemplate } from '@rentcheck/types';
import { DialogTitle, Screens } from 'components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FeatureActions, SnackbarActions } from 'store/actions';
import { Dispatch } from 'types';

import Header from '../../header';
import Navigation from '../common/navigation';

import styled from 'styled-components';
import { isEmbeddedInMobileApp, postMessageToNativeApp } from 'utils/helpers';
import Features from './features';
import FiltersAndSearch from './filters-and-search';
import { ChipFilterType } from './types';

interface Props {
	inspection: ApiInspectionWithTemplate;
}

const InspectionCompleted = ({ inspection }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const [loading, setLoading] = useState(true);

	const chipFilterState = useState<ChipFilterType[]>([]);
	const searchFilterState = useState('');

	useEffect(() => {
		dispatch(FeatureActions.requestInspectionFeatures(inspection.id))
			.catch(() => {
				dispatch(SnackbarActions.showError());
			})
			.finally(() => {
				setLoading(false);
			});
	}, [inspection]);

	const handleCloseFromEmbeddedWebView = () => {
		postMessageToNativeApp({
			type: 'closed-modal',
			modal: 'inspection-details',
		});
	};

	return (
		<>
			{isEmbeddedInMobileApp() && (
				<DialogTitleContainer>
					<DialogTitle title="" onClose={handleCloseFromEmbeddedWebView} />
				</DialogTitleContainer>
			)}

			<Navigation inspection={inspection} />
			<Screens.Container id="inspection-details-container">
				<Header inspection={inspection} />

				<FiltersAndSearch
					loading={loading}
					inspection={inspection}
					chipFilterState={chipFilterState}
					searchFilterState={searchFilterState}
				/>

				<Features
					loading={loading}
					inspection={inspection}
					chipFilter={chipFilterState[0]}
					searchFilter={searchFilterState[0]}
				/>
			</Screens.Container>
		</>
	);
};

const DialogTitleContainer = styled.div`
	width: 100%;
	position: sticky;
	top: 0;
	background-color: #fff;
	z-index: 2;
`;

export default InspectionCompleted;
