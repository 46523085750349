import { ApiInspectionEvent } from '@rentcheck/types';

import EditedTimelineEvent from './event/edited';
import InspectionNotificationSentTimelineEvent from './event/inspection-notification-sent';
import RequestedTimelineEvent from './event/inspection-requested';
import MaintenanceReportSharedTimelineEvent from './event/maintenance-report-shared';
import MarkedAsCompleted from './event/marked-as-completed';
import ReminderTimelineEvent from './event/reminder-sent';
import ReminderSMSTimelineEvent from './event/reminder-sms-sent';
import ReopenedTimelineEvent from './event/reopened';
import SignatureRespondedTimelineEvent from './event/report-signed';
import RequestAcceptedTimelineEvent from './event/request-accepted';
import ReviewRequested from './event/review-requested';
import RoomsAddedTimelineEvent from './event/rooms-added';
import InspectionSharedTimelineEvent from './event/shared';
import SignatureRequestedTimelineEvent from './event/signature-requested';
import StatusChangedTimelineEvent from './event/status-changed';

interface Props {
	event: ApiInspectionEvent;
}

const TimelineListItem = ({ event }: Props) => {
	const props = { event };

	switch (event.type) {
		case 'inspection_shared':
			return <InspectionSharedTimelineEvent {...props} />;
		case 'maintenance_report_shared':
			return <MaintenanceReportSharedTimelineEvent {...props} />;
		case 'inspection_signature_requested':
			return <SignatureRequestedTimelineEvent {...props} />;
		case 'inspection_signature_request_fulfilled':
			return <SignatureRespondedTimelineEvent {...props} />;
		case 'inspection_status_changed':
			return <StatusChangedTimelineEvent {...props} />;
		case 'inspection_request_accepted':
			return <RequestAcceptedTimelineEvent {...props} />;
		case 'inspection_reminder_sent':
			return <ReminderTimelineEvent {...props} />;
		case 'inspection_reminder_sent_scheduled':
			return <ReminderTimelineEvent {...props} />;
		case 'inspection_reminder_sent_sms':
			return <ReminderSMSTimelineEvent {...props} />;
		case 'inspection_notification_sent':
			return <InspectionNotificationSentTimelineEvent {...props} />;
		case 'inspection_requested':
			return <RequestedTimelineEvent {...props} />;
		case 'rooms_added':
			return <RoomsAddedTimelineEvent {...props} />;
		case 'inspection_reopened':
			return <ReopenedTimelineEvent {...props} />;
		case 'inspection_edited':
			return <EditedTimelineEvent {...props} />;
		case 'inspection_marked_as_completed':
			return <MarkedAsCompleted {...props} />;
		case 'inspection_review_request_sent':
			return <ReviewRequested {...props} />;
	}

	return null;
};

export default TimelineListItem;
