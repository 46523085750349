import { useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { LoadingButton } from '@mui/lab';
import {
	Button,
	DialogActions,
	DialogContent,
	Divider,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Typography,
} from '@mui/material';

import { ApiInspectionSkill, MaintenanceFlag } from '@rentcheck/types';

import { FeaturesApi } from 'api';
import { Column, Spacer } from 'components';
import { Dispatch } from 'types';
import { useCreateWorkOrderModalData } from 'hooks/modals';
import {
	FeatureActions,
	ModalFlowActions,
	SnackbarActions,
} from 'store/actions';

import { handleNativeAppClose } from '../common/native-app';

interface Props {
	validSkills: ApiInspectionSkill[];
}

export default ({ validSkills }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const modalData = useCreateWorkOrderModalData('AppFolio');
	const { flags: modalFlags } = modalData?.metadata ?? {};

	const [flags] = useState<MaintenanceFlag[]>(modalFlags);
	const [skillsMap, setSkillsMap] = useState<Record<string, string>>({});
	const [loading, setLoading] = useState(false);

	const handleClose = () => {
		handleNativeAppClose();
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	const handleSelectedMapping = (skill: string, appFolioSkill: string) => {
		const newMap = { ...skillsMap };
		newMap[skill] = appFolioSkill;

		setSkillsMap(newMap);
	};

	const handleReassignSkills = async () => {
		setLoading(true);

		for (const flag of invalidSkillFlags) {
			await FeaturesApi.getById(flag.feature_id)
				.then((feature) => {
					if (!feature) {
						throw Error(
							`Couldn't find feature for maintenance flag ${flag.feature_id}`
						);
					}

					return dispatch(
						FeatureActions.upsertMaintenanceFlag(feature, {
							...flag,
							skill: skillsMap[flag.skill],
						})
					);
				})
				.catch((e) => dispatch(SnackbarActions.showError(e.message)))
				.finally(() => setLoading(false));
		}
	};

	const invalidSkillFlags = flags.filter(
		(f) => !validSkills.some((s) => s.title === f.skill)
	);

	const groupedFlags = _.groupBy(invalidSkillFlags, 'skill');
	const skills = _.keys(groupedFlags);

	const disabled = skills.filter((s) => !skillsMap[s]).length > 0;

	return (
		<>
			<DialogContent>
				<Typography variant="body2" color="#00000099">
					Please select a corresponding AppFolio vendor trade for the
					maintenance flags below:
				</Typography>
				<Spacer height={4} />

				{skills.map((skill, idx) => {
					const flags = groupedFlags[skill];
					const value = skillsMap[skill];

					return (
						<Column style={{ marginTop: 16 }}>
							<Typography variant="overline">Skill</Typography>
							<Typography variant="h6">{skill}</Typography>
							<Typography variant="caption" color="#00000099">
								Total Flags: {flags.length}
							</Typography>
							<Spacer height={2} />
							<FormControl fullWidth>
								<InputLabel
									id={`${skill}-label`}
									sx={
										value
											? { transform: 'translate(12px,8px) scale(0.75)' }
											: undefined
									}>
									AppFolio Vendor Trade
								</InputLabel>
								<Select
									labelId={`${skill}-label`}
									variant="filled"
									value={value}
									onChange={(e) =>
										handleSelectedMapping(skill, e.target.value)
									}>
									{validSkills.map((vt) => (
										<MenuItem value={vt.title}>{vt.title}</MenuItem>
									))}
								</Select>
							</FormControl>
							<Spacer height={4} />
							{idx !== skills.length - 1 && <Divider />}
						</Column>
					);
				})}
			</DialogContent>
			<DialogActions>
				<Button color="secondary" variant="outlined" onClick={handleClose}>
					Back
				</Button>
				<LoadingButton
					disabled={disabled}
					variant="contained"
					loading={loading}
					onClick={handleReassignSkills}>
					Next
				</LoadingButton>
			</DialogActions>
		</>
	);
};
