export interface State {
	search: string;
	active_only: boolean;
	propertyId?: string;
	email?: string;

	sortBy: string;
	sortType: 'ASC' | 'DESC';
}

const initialState: State = {
	sortBy: 'last_name',
	sortType: 'ASC',

	propertyId: undefined,
	search: '',
	active_only: true,
	email: undefined,
};

type Action = {
	type: string;
	value?: string | boolean;
};

export default (state: State = initialState, action: Action) => {
	const value = action.value;

	if (value === undefined) {
		return state;
	}

	switch (action.type) {
		case 'SIGNOUT_SUCCESS':
		case 'IMPERSONATE':
			return initialState;

		case 'TOGGLE_TENANTS_SORT_TYPE':
			return { ...state, sortType: state.sortType === 'ASC' ? 'DESC' : 'ASC' };

		case 'SET_TENANTS_SORT_BY':
			return { ...state, sortBy: value, sortType: 'ASC' };

		case 'SET_TENANTS_SEARCH_FILTER':
			return { ...state, search: value ?? '' };

		case 'SET_TENANTS_ACTIVE_ONLY_FILTER':
			return {
				...state,
				active_only: value,
			};

		default:
			return state;
	}
};
