import { ChargebeeAddon, ChargebeePlan, Profile } from '@rentcheck/types';
import firebase from 'firebase/app';

export const createSubscriptionProspect = async (
	userEmail: string,
	prospectEmail: string,
	plan: ChargebeePlan,
	addons: ChargebeeAddon[],
	portfolioSize: number
): Promise<void> => {
	const parameters = {
		user_email: userEmail,
		prospect_email: prospectEmail,
		chargebee_plan_id: plan.id,
		chargebee_addon_ids: addons.map((a) => a.id),
		portfolio_size: portfolioSize,
	};

	return firebase
		.app()
		.functions()
		.httpsCallable('salesCreateSubscriptionProspect')(parameters)
		.then(async () => {
			return;
		});
};

export const createRevShareProspect = async (
	prospectEmail: string,
	name: string,
	units: string,
	plan_id: string,
	addons: ChargebeeAddon[]
): Promise<Profile> => {
	const parameters = {
		prospect_email: prospectEmail,
		user_name: name,
		total_properties: units,
		chargebee_plan_id: plan_id,
		chargebee_addon_ids: addons.map((a) => a.id),
	};

	return firebase
		.app()
		.functions()
		.httpsCallable('salesCreateRevShareProspect')(parameters)
		.then(async (response) => response.data);
};
