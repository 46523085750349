import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors, spacingPx } from 'theme';

import { Typography } from '@mui/material';
import Spacer from '../Spacer';
import { Column } from '../layout/Column';
import { Row, SpacedRow } from '../layout/Row';

interface TopLinkProps {
	linkTo: string;
	label: string;
}

export const TopLink = ({ linkTo, label }: TopLinkProps) => {
	return (
		<RowLink to={linkTo}>
			<FontAwesomeIcon icon={regular('arrow-left')} color={colors.gray} />
			<Spacer width={2} />
			<Typography color={colors.gray}>{label}</Typography>
		</RowLink>
	);
};

export const TopSpacer = styled.div`
	height: ${spacingPx(12.5)};
`;

export const ScreenTitleRowDivider = styled.div`
	background-color: ${colors.contentBorder};
	height: 1px;
	flex: 1;
`;

const titleRowExtraOffset = '3px';

export const ScreenContentDivider = styled.div<{ hasTitleRow?: boolean }>`
	background-color: ${colors.contentBorder};
	width: 1px;
	height: ${({ hasTitleRow }) =>
		hasTitleRow
			? `calc(80vh - ${spacingPx(29)} - ${titleRowExtraOffset})`
			: `80vh`};
`;

export const ScreenTitleRow = styled(SpacedRow)`
	padding: ${spacingPx(4)} ${spacingPx(5)} ${spacingPx(4)} ${spacingPx(5)};
`;

export const ScreenContent = styled(Row)<{ hasTitleRow?: boolean }>`
	height: ${({ hasTitleRow }) =>
		hasTitleRow
			? `calc(80vh - ${spacingPx(29)} - ${titleRowExtraOffset})`
			: `80vh`};
`;

export const ScreenContentMain = styled(Column)<{ hasTitleRow?: boolean }>`
	padding: ${spacingPx(8)} ${spacingPx(10)};
	overflow: scroll;
	flex: 1;
	height: ${({ hasTitleRow }) =>
		hasTitleRow
			? `calc(80vh - ${spacingPx(29)} - ${titleRowExtraOffset})`
			: `80vh`};
`;

export const ScreenContentSideBar = styled(Column)<{ hasTitleRow?: boolean }>`
	overflow: scroll;
	width: ${spacingPx(80)};
	height: ${({ hasTitleRow }) =>
		hasTitleRow
			? `calc(80vh - ${spacingPx(29)} - ${titleRowExtraOffset})`
			: `80vh`};
`;

export const ScreenContainer = styled.div`
	width: 80vw;
	background-color: ${colors.contentBackground};
	height: 80vh;
	border: 1px solid ${colors.contentBorder};
	border-radius: 3px;
	-webkit-box-shadow: 0 0 1px ${colors.shadow};
	box-shadow: 0 0 1px ${colors.shadow};
	position: relative;
}`;

const RowLink = styled(Link)`
	display: flex;
	flex-direction: row;
	align-items: center;
	height: ${spacingPx(14)};
`;
