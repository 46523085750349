import {
	Checkbox,
	TableCell,
	TableHead,
	TableRow,
	TableSortLabel,
} from '@mui/material';
import { useDispatch } from 'react-redux';

import { Inspection } from '@rentcheck/api-frontend';

import { useTypedSelector } from 'store/reducers/rootReducer';

import ActionsMenu from './actions-menu';

interface Props {
	selectedIds: string[];
	setSelectedIds: (ids: string[]) => void;
	selectAllIds: () => void;
	inspections: Inspection[];
}

const headers = [
	{ id: 'address', title: 'Address', sortable: true },
	{ id: 'inspection_template', title: 'Inspection Template', sortable: false },
	{ id: 'due_date', title: 'Due Date', sortable: true },
	{ id: 'completed_date', title: 'Date Completed', sortable: true },
	{ id: 'inspection_status', title: 'Inspection Status', sortable: true },
	{ id: 'features_ratings', title: '', sortable: false },
];

export default ({
	selectedIds,
	setSelectedIds,
	selectAllIds,
	inspections,
}: Props) => {
	const dispatch = useDispatch();

	const archived = useTypedSelector(
		(state) => state.inspections.filters.archived
	);
	const sortBy = useTypedSelector(
		(state) => state.inspections.filters.sort.sort_by
	);
	const sortType = useTypedSelector(
		(state) => state.inspections.filters.sort.sort_type
	);

	const switchSortDirection = () => {
		dispatch({
			type: 'TOGGLE_INSPECTION_SORT_TYPE',
		});
	};

	const handleSort = (headerId: string, sortable: boolean) => {
		if (!sortable) return;

		if (headerId === sortBy) {
			return switchSortDirection();
		}

		dispatch({
			type: 'SET_INSPECTION_SORT_BY',
			value: headerId,
		});
	};

	const checked =
		selectedIds.length === inspections.length && selectedIds.length > 0;

	const indeterminate =
		selectedIds.length > 0 && selectedIds.length !== inspections.length;

	return (
		<TableHead>
			<TableRow>
				<TableCell>
					<Checkbox
						size="small"
						onChange={selectAllIds}
						indeterminate={indeterminate}
						checked={checked}
					/>
				</TableCell>
				{headers.map((h) => (
					<TableCell key={h.id}>
						<TableSortLabel
							active={sortBy === h.id}
							direction={sortType.toLowerCase() as 'asc' | 'desc'}
							hideSortIcon={!h.sortable}
							onClick={() => handleSort(h.id, h.sortable)}>
							{h.title}
						</TableSortLabel>
					</TableCell>
				))}
				<TableCell align="center">
					<ActionsMenu
						inspectionIds={selectedIds}
						setSelectedIds={setSelectedIds}
						archived={archived}
					/>
				</TableCell>
			</TableRow>
		</TableHead>
	);
};
