import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, IconButton, TableCell, TableRow } from '@mui/material';
import { APIUnit } from '@rentcheck/types';
import { useHistory } from 'react-router-dom';

import LastCompletedInspection from '../../common/last-completed-inspection';
import ActionsMenu from './actions-menu';
import { Utils } from '@rentcheck/biz';

interface Props {
	unit: APIUnit;
	selectedIds: string[];
	setSelectedIds: (ids: string[]) => void;
	selectId: (id: string) => void;
}

const UnitTableRow = ({
	unit,
	selectedIds,
	setSelectedIds,
	selectId,
}: Props) => {
	const history = useHistory();

	const handleClick = () => {
		history.push(`/properties/${unit.id}`);
	};

	const stillInSync = Utils.Properties.propertyIsStillInSync(unit);

	return (
		<TableRow
			sx={{
				'&:last-child td, &:last-child th': { border: 0 },
				cursor: 'pointer',
			}}>
			<TableCell>
				<Checkbox
					id={`checkbox-unit-${unit.id}`}
					size="small"
					checked={selectedIds.includes(unit.id)}
					onChange={() => selectId(unit.id)}
				/>
			</TableCell>
			<TableCell onClick={handleClick}>{unit.address}</TableCell>
			<TableCell onClick={handleClick}>{unit.city}</TableCell>
			<TableCell onClick={handleClick}>{unit.zip_code}</TableCell>
			<TableCell onClick={handleClick}>{unit.property_type}</TableCell>
			<TableCell onClick={handleClick}>{unit.team?.internal_label}</TableCell>
			<LastCompletedInspection property={unit} onClick={handleClick} />
			<TableCell align="center">
				<ActionsMenu
					unitIds={[unit.id]}
					setUnitIds={setSelectedIds}
					disableDeletion={stillInSync}>
					<IconButton
						id={`action-unit-${unit.id}`}
						size="small"
						sx={{ width: 28, marginLeft: -2 }}>
						<FontAwesomeIcon icon={solid('ellipsis-v')} color="gray" />
					</IconButton>
				</ActionsMenu>
			</TableCell>
		</TableRow>
	);
};

export default UnitTableRow;
