import { DatePicker } from '@mui/lab';
import { TextField, useMediaQuery, useTheme } from '@mui/material';
import { AssignMethod } from '@rentcheck/types';
import { Column } from 'components';
import { RecurringOption } from 'components/inspections/create-steps/recurrence';
import moment from 'moment-timezone';
import { useEffect } from 'react';

interface Props {
	assignMethod?: AssignMethod;
	inviteDate?: Date;
	setInviteDate: (value?: Date) => void;
	dueDate?: Date;
	recurrence?: RecurringOption;
}

export default ({
	assignMethod,
	inviteDate,
	setInviteDate,
	dueDate,
	recurrence,
}: Props) => {
	const theme = useTheme();
	const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));

	const needsToSelectInviteDate =
		assignMethod === 'emails' ||
		assignMethod === 'residents' ||
		assignMethod === 'teammates';

	useEffect(() => {
		if (!needsToSelectInviteDate) {
			setInviteDate(undefined);
		}

		if (recurrence && recurrence !== 'Does not repeat') {
			setInviteDate(undefined);
		}
	}, [assignMethod, recurrence]);

	useEffect(() => {
		/**
		 * If there's no due date no need to calculate
		 * anything right now
		 */
		if (!dueDate) {
			return setInviteDate(undefined);
		}

		/**
		 * In any other case we need to select a new invitation date
		 * and for this we go with the default
		 */
		const now = moment();
		const defaultValue = moment(dueDate).subtract(14, 'd');

		if (defaultValue.isBefore(now, 'd')) {
			return setInviteDate(now.toDate());
		}

		return setInviteDate(defaultValue.toDate());
	}, [dueDate]);

	if (!needsToSelectInviteDate) {
		return null;
	}

	if (recurrence && recurrence !== 'Does not repeat') {
		return null;
	}

	if (!dueDate) {
		return null;
	}

	const inviteTime = moment().tz('America/Chicago').set('h', 11);
	const userTimezone = moment.tz.guess() || 'America/Chicago';
	const formattedUserTimezone = moment.tz(userTimezone).format('z');
	const userTimezoneHasName = !/^[+-]\d+$/.test(formattedUserTimezone);
	const inviteIsToday = moment(inviteDate).isSame(moment(), 'd');

	const inviteTimeMessage = inviteIsToday
		? 'Invitation will be sent immediately upon inspection creation.'
		: `Invitation will be sent at ` +
			`${inviteTime.tz('UTC').format('ha')} ` +
			`UTC (${inviteTime.tz(userTimezone).format('ha')} ` +
			`${
				userTimezoneHasName ? formattedUserTimezone : `user's timezone`
			}) on the selected day.`;

	return (
		<Column style={{ width: mobileScreen ? '100%' : '60%' }}>
			<DatePicker
				inputFormat="MM/dd/yy"
				disablePast={true}
				maxDate={dueDate}
				value={inviteDate}
				mask="__/__/__"
				renderInput={(props) => (
					<TextField
						{...props}
						id="input-invite-date"
						variant="filled"
						label="Invitation date"
						helperText={inviteTimeMessage}
						sx={{ marginBottom: 2 }}
					/>
				)}
				onChange={(date) => {
					setInviteDate(date ?? undefined);
				}}
			/>
		</Column>
	);
};
