/* @ts-ignore */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { match as Match } from 'react-router-dom';

import { APIBuilding } from '@rentcheck/types';

import {
	ScreenContainer,
	ScreenContent,
	ScreenContentDivider,
	ScreenContentMain,
	ScreenTitleRowDivider,
	TopLink,
} from 'components';
import { ConfirmationModal } from 'components/Common';
import Content from 'components/properties/details/content';
import { PropertiesActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';

import PropertyDetailsHeader from './property-details-header';
import PropertyInformation from './property-information';

interface MatchParams {
	id: string;
	reportId?: string;
}

interface Props {
	match: Match<MatchParams>;
}

const BuildingDetails = ({ match }: Props) => {
	const propertyId = match.params.id;

	const dispatch: Dispatch = useDispatch();
	const history = useHistory();

	const property = useTypedSelector((state) =>
		state.properties.property?.id === propertyId
			? state.properties.property
			: null
	);

	const [fetchError, setFetchError] = useState<string | undefined>(undefined);

	const genericPropertyError =
		"This property was removed or you don't have the necessary permissions to view it.";

	useEffect(() => {
		dispatch(PropertiesActions.get(propertyId))
			.catch(() => setFetchError(genericPropertyError))
			.catch(() => setFetchError(genericPropertyError));
	}, []);

	if (!property) {
		if (fetchError) {
			return (
				<ConfirmationModal
					title="There was a problem"
					body={fetchError}
					confirmTitle="Back to all buildings"
					confirmPressed={() => history.push('/properties/buildings')}
				/>
			);
		}

		return null;
	}

	return (
		<div>
			<TopLink linkTo="/properties/buildings" label="Back to all buildings" />
			<ScreenContainer>
				<PropertyDetailsHeader property={property as APIBuilding} />
				<ScreenTitleRowDivider />
				<ScreenContent hasTitleRow>
					<PropertyInformation property={property as APIBuilding} />
					<ScreenContentDivider hasTitleRow />
					<ScreenContentMain hasTitleRow>
						<Content property={property} />
					</ScreenContentMain>
				</ScreenContent>
			</ScreenContainer>
		</div>
	);
};

export default BuildingDetails;
