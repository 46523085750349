import { CustomRoom } from '@rentcheck/types';
import { Column } from 'components';
import { useState } from 'react';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { defaultRooms } from './common/constants';
import RoomRow from './common/room-row';
import Section from './common/section';

export default () => {
	const accountSettings = useTypedSelector((state) => state.accountSettings);

	const [newRooms, setNewRooms] = useState<CustomRoom[]>([]);

	const interiorRooms =
		accountSettings?.rooms.unit.filter((r) => r.category === 'interior') ?? [];
	const exteriorRooms =
		accountSettings?.rooms.unit.filter((r) => r.category === 'exterior') ?? [];

	const handleAddInterior = () => {
		setNewRooms([{ name: '', category: 'interior' }, ...newRooms]);
	};

	const handleAddExterior = () => {
		setNewRooms([{ name: '', category: 'exterior' }, ...newRooms]);
	};

	const handleRoomCreated = (room: CustomRoom) => {
		setNewRooms(newRooms.filter((r) => r !== room));
	};

	return (
		<Column>
			<Section title="Bedrooms and bathrooms">
				{defaultRooms.units.bedsAndBaths.map((room) => (
					<RoomRow key={room} room={{ name: room, category: 'other' }} />
				))}
			</Section>

			<Section title="Interior" onAdd={handleAddInterior}>
				{newRooms
					.filter((r) => r.category === 'interior')
					.map((room) => (
						<RoomRow
							editable
							key={room.name}
							room={room}
							variant="unit"
							onRoomCreated={handleRoomCreated}
						/>
					))}
				{interiorRooms.map((room) => (
					<RoomRow editable key={room.name} room={room} variant="unit" />
				))}
				{defaultRooms.units.interior.map((room) => (
					<RoomRow key={room} room={{ name: room, category: 'other' }} />
				))}
			</Section>

			<Section title="Exterior" onAdd={handleAddExterior}>
				{newRooms
					.filter((r) => r.category === 'exterior')
					.map((room) => (
						<RoomRow
							editable
							key={room.name}
							room={room}
							variant="unit"
							onRoomCreated={handleRoomCreated}
						/>
					))}
				{exteriorRooms.map((room) => (
					<RoomRow editable key={room.name} room={room} variant="unit" />
				))}
				{defaultRooms.units.exterior.map((room) => (
					<RoomRow key={room} room={{ name: room, category: 'other' }} />
				))}
			</Section>
		</Column>
	);
};
