import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { MenuItem } from '@mui/material';
import ToggleButton from 'components/toggle-button';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FeatureActions, SnackbarActions } from 'store/actions';
import { FeatureWithMetadata } from 'store/reducers/features';
import { colors } from 'theme';
import { Dispatch } from 'types';
import { metadataHasDamage } from 'utils/image-metadata';

interface Props {
	feature: FeatureWithMetadata;
	image?: string;
	buttonSx?: React.CSSProperties;
	disabled?: boolean;
}

export default ({ feature, image, buttonSx, disabled }: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const [loading, setLoading] = useState(false);

	const handleNotDamaged = () => {
		if (!imageMetadata) return;

		setLoading(true);

		dispatch(FeatureActions.postMetadataFeedback(imageMetadata, false))
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	const imageMetadata = feature.image_metadata.find(
		(im) => im.inspection_feature.image_key === image
	);

	if (!imageMetadata || !metadataHasDamage(imageMetadata)) {
		return null;
	}

	return (
		<ToggleButton
			selected
			loading={loading}
			buttonSx={{ backgroundColor: colors.warning, ...buttonSx }}
			icon={regular('house-chimney-crack')}
			tooltip="Damage detected in this image"
			disabled={disabled}>
			<MenuItem onClick={handleNotDamaged}>Mark image as not damaged</MenuItem>
		</ToggleButton>
	);
};
