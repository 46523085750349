import { Checkbox, Typography } from '@mui/material';

import { Column, SpacedRow } from 'components';
import { spacingPx } from 'theme';

interface Props {
	title: string;
	subtitle: string;
	checked: boolean;
	onClick: () => void;
}

export default ({ title, subtitle, checked, onClick }: Props) => {
	return (
		<SpacedRow style={{ padding: spacingPx(2) }}>
			<Column>
				<Typography>{title}</Typography>
				<Typography color="#00000099">{subtitle}</Typography>
			</Column>

			<Checkbox checked={checked} onClick={onClick} />
		</SpacedRow>
	);
};
