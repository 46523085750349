import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/reducers/rootReducer';

const inactiveHeaders = [
	{ id: 'first_name', title: 'First Name' },
	{ id: 'last_name', title: 'Last Name' },
	{ id: 'email', title: 'Email' },
];

const activeHeaders = [
	{ id: 'first_name', title: 'First Name' },
	{ id: 'last_name', title: 'Last Name' },
	{ id: 'property_address', title: 'Address' },
	{ id: 'lease_start', title: 'Lease Start' },
	{ id: 'lease_end', title: 'Lease End' },
	{ id: 'status', title: 'Lease Status' },
	{ id: 'emails', title: 'Email' },
];

const ResidentsHeader = () => {
	const dispatch = useDispatch();

	const filters = useTypedSelector((state) => state.tenants.filters);
	const headers = filters.active_only ? activeHeaders : inactiveHeaders;

	const switchSortDirection = () => {
		dispatch({ type: 'TOGGLE_TENANTS_SORT_TYPE', value: true });
	};

	const handleSort = (headerId: string) => {
		if (headerId === filters.sortBy) return switchSortDirection();

		dispatch({ type: 'SET_TENANTS_SORT_BY', value: headerId });
	};

	return (
		<TableHead>
			<TableRow>
				{headers.map((h) => (
					<TableCell key={h.id}>
						<TableSortLabel
							active={filters.sortBy === h.id}
							direction={filters.sortType.toLowerCase() as 'asc' | 'desc'}
							onClick={() => handleSort(h.id)}>
							{h.title}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

export default ResidentsHeader;
