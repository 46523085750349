import { useTypedSelector } from 'store/reducers/rootReducer';

import SnapshotRow from './snapshot-row';

const Unassigned = () => {
	const dashboardData = useTypedSelector((state) => state.dashboard.data);

	const unassignedCount = dashboardData?.todo_list.unassigned_inspections_count;

	return (
		<SnapshotRow
			label="Unassigned"
			link="/dashboard/unassigned"
			count={unassignedCount}
		/>
	);
};

export default Unassigned;
