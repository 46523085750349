import { Row } from 'components';
import { useTypedSelector } from 'store/reducers/rootReducer';

import CompletionRate from './completion-rate';
import CreatedInspections from './created-inspections';
import LockedState from './locked-state';
import ResidentRating from './resident-rating';
import RevisionRate from './revision-rate';

interface Props {
	loading: boolean;
}

const Content = ({ loading }: Props) => {
	const dashboardData = useTypedSelector((state) => state.dashboard.data);

	return (
		<Row style={{ overflowX: 'scroll' }}>
			<LockedState />
			<CompletionRate data={dashboardData} loading={loading} />
			<RevisionRate data={dashboardData} loading={loading} />
			<ResidentRating data={dashboardData} loading={loading} />
			<CreatedInspections />
		</Row>
	);
};

export default Content;
