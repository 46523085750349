import { Button } from '@mui/material';
import { ApiTenant } from '@rentcheck/types';
import { forwardRef, useImperativeHandle } from 'react';
import { useDispatch } from 'react-redux';

import {
	ModalFlowActions,
	SnackbarActions,
	TenantsActions,
} from 'store/actions';
import { Dispatch } from 'types';

interface Props {
	resident: ApiTenant;
}

export interface DeleteResidentRef {
	delete: () => void;
}

export default forwardRef<DeleteResidentRef, Props>((props, ref) => {
	const { resident } = props;

	const dispatch: Dispatch = useDispatch();

	useImperativeHandle(ref, () => ({
		delete: handleDelete,
	}));

	const handleConfirmDelete = async () => {
		dispatch(ModalFlowActions.closeConfirmationModal());
		dispatch(ModalFlowActions.closeCurrentModal());

		const progress = await dispatch(
			SnackbarActions.showProgress('Deleting Resident.')
		);

		dispatch(TenantsActions.remove(resident.id))
			.then(() => dispatch(SnackbarActions.showSuccess('Resident Deleted.')))
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => dispatch(SnackbarActions.hideSnackbar(progress.id)));
	};

	const handleDelete = () => {
		dispatch(
			ModalFlowActions.showConfirmationModal({
				title: 'Are you sure?',
				body1: [
					'Are you sure you want to delete this resident?',
					'This action is not reversible',
				],
				body2: [],
				buttons: [
					ModalFlowActions.cancelButton(dispatch),
					{
						title: 'Delete',
						color: 'error',
						variant: 'contained',
						onClick: handleConfirmDelete,
					},
				],
			})
		);
	};

	return (
		<Button variant="text" color="secondary" onClick={handleDelete}>
			Remove Resident
		</Button>
	);
});
