import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { MenuItem } from '@mui/material';
import IconButton from 'components/icon-button';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FeatureActions, SnackbarActions } from 'store/actions';
import { FeatureWithMetadata } from 'store/reducers/features';
import { Dispatch } from 'types';
import { metadataHasDamage } from 'utils/image-metadata';

interface Props {
	feature: FeatureWithMetadata;
	image: string;
}

export default ({ feature, image }: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const [loading, setLoading] = useState(false);

	const handleNotDamaged = () => {
		if (!imageMetadata) return;

		setLoading(true);

		dispatch(FeatureActions.postMetadataFeedback(imageMetadata, false))
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	const imageMetadata = feature.image_metadata.find(
		(im) => im.inspection_feature.image_key === image
	);

	if (!imageMetadata || !metadataHasDamage(imageMetadata)) {
		return null;
	}

	return (
		<IconButton
			loading={loading}
			color="warning"
			icon={solid('house-chimney-crack')}
			tooltip="Damage detected in this image"
			buttonSx={{
				backgroundColor: '#FFFFFFBD',
				':hover': {
					backgroundColor: '#CCCCCCBD',
				},
			}}>
			<MenuItem onClick={handleNotDamaged}>Mark image as not damaged</MenuItem>
		</IconButton>
	);
};
