import { ApiInspection, ReminderRecipient } from '@rentcheck/types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';

import { buildRecipients } from '../common';
import Section from '../section';
import Recipient from './recipient';
import Skeleton from './skeleton';

interface Props {
	inspections: ApiInspection[];
	recipients: ReminderRecipient[];
	setRecipients: (value: ReminderRecipient[]) => void;
}

export default ({ inspections, recipients, setRecipients }: Props) => {
	const dispatch = useDispatch();

	useEffect(() => {
		buildRecipients(inspections)
			.then(setRecipients)
			.catch((e) =>
				dispatch(
					SnackbarActions.showError(`Error getting recipients: (${e.message})`)
				)
			);
	}, [inspections]);

	const handleUpdateRecipient = (
		recipient: ReminderRecipient,
		index: number
	) => {
		const newRecipients = [...recipients];
		newRecipients[index] = recipient;

		setRecipients(newRecipients);
	};

	const enabledRecipients = recipients.filter((r) => r.selected);

	return (
		<Section
			title="Recipients"
			rightTitle={`${enabledRecipients.length} selected`}>
			{!recipients.length && <Skeleton />}
			{recipients.map((r, idx) => (
				<Recipient
					recipient={r}
					updateRecipient={(newValue) => handleUpdateRecipient(newValue, idx)}
				/>
			))}
		</Section>
	);
};
