import { Typography, TypographyProps } from '@mui/material';
import { useAppliedFiltersCount } from 'store/reducers/inspections/filters';

interface Props {
	open: boolean;
	setOpen: (open: boolean) => void;
}

export default ({ open, setOpen }: Props) => {
	const count = useAppliedFiltersCount();

	const commonProps: TypographyProps = {
		variant: 'body2',
		color: '#4d4d4d',
		sx: { alignSelf: 'flex-end', cursor: 'pointer' },
	};

	if (!open) {
		return (
			<Typography onClick={() => setOpen(true)} {...commonProps}>
				Show Filters {count ? `(${count})` : ''}
			</Typography>
		);
	}

	return (
		<Typography mb={1} onClick={() => setOpen(false)} {...commonProps}>
			Hide Filters {count ? `(${count})` : ''}
		</Typography>
	);
};
