import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	Typography,
} from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { DialogTitle, Row, Spacer } from 'components';
import { ActiveProfileActions } from 'store/actions';
import { colors } from 'theme';
import { Dispatch } from 'types';

interface Props {
	open: boolean;
	setOpen: (show: boolean) => void;
	handleClose: () => void;
	userId: string;
}

const InstructionsModal = ({ open, setOpen, handleClose }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const [dismiss, setDismiss] = useState(false);

	const handleEdit = () => {
		if (dismiss) {
			dispatch(
				ActiveProfileActions.update({
					feature_editing_dismissed: true,
				})
			);
		}

		setOpen(false);
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle
				title="Feature Editing"
				icon={regular('pen-field')}
				onClose={handleClose}
			/>

			<DialogContent>
				<Typography>
					Editing a feature allows you to update the ratings, answers, and
					images on a completed inspection.
				</Typography>
				<Spacer height={5} />

				<Typography>
					You must save or cancel all your changes before moving on to another
					feature.
				</Typography>
				<Spacer height={5} />

				<Typography>
					<Link
						target="_blank"
						href="https://help.getrentcheck.com/en/articles/6555193-editing-inspections">
						Learn more about inspection editing.
					</Link>
				</Typography>
				<Spacer height={5} />

				<Row>
					<Checkbox
						checked={dismiss}
						id="dismiss-modal-radio"
						onClick={() => setDismiss(!dismiss)}
					/>

					<Typography>Don't show me this message again.</Typography>
				</Row>
				<Spacer height={5} />
			</DialogContent>

			<DialogActions>
				<Button variant="outlined" color="secondary" onClick={handleClose}>
					Cancel
				</Button>

				<Button
					id="button-edit-feature"
					variant="contained"
					onClick={handleEdit}>
					Edit Feature
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const Link = styled.a`
	color: ${colors.darkGray};
	text-decoration: underline;
`;

export default InstructionsModal;
