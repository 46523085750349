import { TextField, Typography } from '@mui/material';
import { Row, Spacer } from 'components';

interface Props {
	value: string;
	setValue: (value: string) => void;
}

const AccessInstructions = ({ value, setValue }: Props) => {
	return (
		<>
			<Row>
				<Typography variant="h6">Access instructions</Typography>
			</Row>

			<Spacer height={2} />

			<TextField
				multiline
				fullWidth
				variant="outlined"
				placeholder="None provided"
				helperText="This is the work order field ‘Access Entry Notes’ in Yardi"
				value={value}
				onChange={(e) => setValue(e.target.value)}
			/>

			<Spacer height={4} />
		</>
	);
};

export default AccessInstructions;
