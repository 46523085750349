import styled from 'styled-components';

import { LoadingButton } from '@mui/lab';
import { Button, Typography } from '@mui/material';
import { colors, spacingPx } from 'theme';
import { Spacer } from '../';
import Modal from './Modal';

interface Props {
	title: string;
	body: string;
	cancelTitle?: string;
	confirmTitle: string;
	destructive?: boolean;
	cancelPressed?: () => void;
	confirmPressed: () => void;
	error?: string;
	loading?: boolean;
}

const ConfirmationModal = ({
	title,
	body,
	cancelTitle,
	confirmTitle,
	destructive,
	cancelPressed,
	confirmPressed,
	error,
	loading,
}: Props) => {
	return (
		<Modal targetId="confirmation" width={114} topPadding={10}>
			<CenteredColumn>
				<Spacer height={5} />
				<Typography variant="h5">{title}</Typography>
				<Spacer height={2} />
				<Typography>{body}</Typography>
				<Spacer height={12} />
				<Divider />
				<Spacer height={5} />
				{error && (
					<>
						<Typography variant="error">{error}</Typography>
						<Spacer height={5} />
					</>
				)}
			</CenteredColumn>
			{!!cancelTitle && !!cancelPressed && (
				<SpacedRow>
					<ButtonContainer left>
						<Button variant="outlined" onClick={() => cancelPressed()}>
							{cancelTitle}
						</Button>
					</ButtonContainer>
					<ButtonContainer right>
						<LoadingButton
							variant="contained"
							loading={loading}
							color={destructive ? 'error' : 'primary'}
							onClick={() => confirmPressed()}>
							{confirmTitle}
						</LoadingButton>
					</ButtonContainer>
				</SpacedRow>
			)}
			{(!cancelTitle || !cancelPressed) && (
				<CenteredColumn>
					<LoadingButton
						variant="contained"
						loading={loading}
						color={destructive ? 'error' : 'primary'}
						onClick={() => confirmPressed()}>
						{confirmTitle}
					</LoadingButton>
				</CenteredColumn>
			)}
		</Modal>
	);
};

const Row = styled.div`
	display: flex;
	flex-direction: row;
`;

const CenteredColumn = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const SpacedRow = styled(Row)`
	justify-content: space-between;
`;

const ButtonContainer = styled.div<{ right?: boolean; left?: boolean }>`
	display: flex;
	flex-direction: column;
	padding-left: ${({ right }) => (right ? spacingPx(2) : 0)};
	padding-right: ${({ left }) => (left ? spacingPx(2) : 0)};
`;

const Divider = styled.div`
	height: 1px;
	width: 100%;
	background-color: ${colors.contentBorder};
`;

export default ConfirmationModal;
