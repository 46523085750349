import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { MenuItem, Typography } from '@mui/material';
import {
	TemplateFeature,
	TemplateQuestion,
	TemplateQuestionAnswer,
} from '@rentcheck/types';
import { Row, SpacedRow } from 'components';
import FormTextField from 'components/form-text-field';
import IconButton from 'components/icon-button';
import {
	areQuestionsIncomplete,
	isFeatureIncomplete,
} from 'screens/modal-flows-controller/inspection-template/features/helpers';

import { createQuestion } from '../../helpers';
import AssetCapture from './asset-capture';
import FreeText from './free-text';
import MultipleChoice from './multiple-choice';
import YesNo from './yes-no';

interface Props {
	index: number;
	question: TemplateQuestion;
	feature: TemplateFeature;

	onDelete: () => void;
	onCopy: () => void;
	onAdd: () => void;

	onUpdate: (question: TemplateQuestion) => void;
	onDeleteAnswer: (
		question: TemplateQuestion,
		answer: TemplateQuestionAnswer
	) => void;
	onSave: () => void;
}

export default ({
	index,
	question,
	feature,
	onDelete,
	onCopy,
	onAdd,
	onUpdate,
	onSave,
	onDeleteAnswer,
}: Props) => {
	const handleChangeQuestionType = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newType = e.target.value as TemplateQuestion['type'];
		const newQuestion = createQuestion(newType);

		const wasAssetCapture = question.type === 'asset capture';
		const willBeAssetCapture = newType === 'asset capture';

		onUpdate({
			...question,
			type: newType,
			answers: newQuestion.answers,
			answer_type: newQuestion.answer_type,
			title:
				willBeAssetCapture || wasAssetCapture
					? newQuestion.title
					: question.title,
		});
	};

	const disableButtons =
		areQuestionsIncomplete(feature.questions) || isFeatureIncomplete(feature);

	return (
		<>
			<SpacedRow style={{ marginBottom: 12 }}>
				<Typography variant="subtitle2" color="secondary">
					Question {index + 1}
				</Typography>

				<Row>
					<IconButton
						disabled={disableButtons}
						icon={regular('plus-circle')}
						tooltip="Add Question"
						onClick={onAdd}
					/>
					<IconButton
						disabled={disableButtons}
						icon={regular('copy')}
						tooltip="Copy Question"
						onClick={onCopy}
					/>
					<IconButton
						icon={regular('trash')}
						tooltip="Delete"
						onClick={onDelete}
					/>
				</Row>
			</SpacedRow>

			<FormTextField
				select
				title="Question type"
				value={question.type}
				onChange={handleChangeQuestionType}>
				<MenuItem value="yes/no">Yes/No</MenuItem>
				<MenuItem value="free text">Open Text</MenuItem>
				<MenuItem value="multiple choice">Multiple Choice</MenuItem>
				<MenuItem value="asset capture">Asset Capture</MenuItem>
			</FormTextField>

			{question.type === 'yes/no' && (
				<YesNo question={question} onUpdate={onUpdate} onSave={onSave} />
			)}

			{question.type === 'free text' && (
				<FreeText question={question} onUpdate={onUpdate} onSave={onSave} />
			)}

			{question.type === 'multiple choice' && (
				<MultipleChoice
					question={question}
					onUpdate={onUpdate}
					onSave={onSave}
					onDeleteAnswer={onDeleteAnswer}
				/>
			)}

			{question.type === 'asset capture' && (
				<AssetCapture question={question} onUpdate={onUpdate} onSave={onSave} />
			)}
		</>
	);
};
