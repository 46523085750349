import _ from 'lodash';

export interface DateRange {
	start: number;
	end: number;
}

export interface State {
	team: string[];
	dateRange?: DateRange;
	search: string;

	sortBy: string;
	sortType: 'asc' | 'desc';
}

const initialState: State = {
	sortBy: 'name',
	sortType: 'asc',
	team: [],
	dateRange: undefined,
	search: '',
};

const resetState: Partial<State> = {
	team: [],
	dateRange: undefined,
};

type Action = {
	type: string;
	value?: string;
	dateRange: DateRange;
};

const reducer = (state: State = initialState, action: Action) => {
	switch (action.type) {
		case 'SIGNOUT_SUCCESS':
		case 'IMPERSONATE':
			return initialState;

		case 'TOGGLE_COMMUNITIES_TEAMS_FILTER':
			if (!action.value) return state;
			return { ...state, team: _.xor(state.team, [action.value]) };

		case 'SET_COMMUNITIES_DATE_RANGE_FILTER':
			return { ...state, dateRange: action.dateRange };

		case 'TOGGLE_COMMUNITIES_SORT_TYPE':
			return { ...state, sortType: state.sortType === 'asc' ? 'desc' : 'asc' };

		case 'SET_COMMUNITIES_SORT_BY':
			if (!action.value) return state;
			return { ...state, sortBy: action.value, sortType: 'asc' };

		case 'SET_COMMUNITIES_SEARCH_FILTER':
			return { ...state, search: action.value ?? '' };

		case 'RESET_COMMUNITIES_FILTERS':
			return { ...state, ...resetState };

		default:
			return state;
	}
};

export const appliedFiltersCount = (state: State) => {
	return [!!state.dateRange, state.team.length > 0].filter((f) => f).length;
};

export const hasAppliedFilters = (state: State) => {
	return appliedFiltersCount(state) > 0 || state.search.length > 0;
};

export default reducer;
