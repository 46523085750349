import { Button, Typography } from '@mui/material';
import styled from 'styled-components';
import { CenteredColumn, Spacer } from '..';
import { colors, spacingPx } from '../../theme';
import Modal from '../Common/Modal';

interface Props {
	setModalVisible: (visible: boolean) => void;
}

const UploadInProgress = ({ setModalVisible }: Props) => {
	return (
		<Modal>
			<CenteredColumn>
				<Spacer height={20} />
				<Typography variant="h5">Upload in Progress!</Typography>
				<Spacer height={3} />
				<Typography>
					Your data upload is in progress and you will receive an email once
					it’s complete.
				</Typography>
				<Spacer height={20} />
				<Divider />
				<Spacer height={5} />
				<ButtonContainer>
					<Button onClick={() => setModalVisible(false)}>Close</Button>
				</ButtonContainer>
			</CenteredColumn>
		</Modal>
	);
};

const Divider = styled.div`
	height: 1px;
	width: 100%;
	background-color: ${colors.contentBorder};
`;

const ButtonContainer = styled.div<{ right?: boolean; left?: boolean }>`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding-left: ${({ right }) => (right ? spacingPx(2) : 0)};
	padding-right: ${({ left }) => (left ? spacingPx(2) : 0)};
`;

export default UploadInProgress;
