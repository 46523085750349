import { FormControl, MenuItem, Select, Typography } from '@mui/material';
import { Row, Spacer } from 'components';
import { OccupancyT } from '.';

interface Props {
	value: OccupancyT;
	setValue: (value: OccupancyT) => void;
}

const Occupancy = ({ value, setValue }: Props) => {
	return (
		<>
			<Row>
				<Typography variant="h6">Unit Occupancy</Typography>
				<Spacer width={1} />
				<Typography color="#B90B17">*</Typography>
			</Row>

			<Spacer height={2} />

			<FormControl fullWidth>
				<Select
					variant="filled"
					value={value}
					onChange={(e) => setValue(e.target.value as OccupancyT)}>
					<MenuItem id="Occupied" value="Occupied">
						Occupied
					</MenuItem>
					<MenuItem id="Vacant" value="Vacant">
						Vacant
					</MenuItem>
				</Select>
			</FormControl>

			<Spacer height={4} />
		</>
	);
};

export default Occupancy;
