import { Constants } from '@rentcheck/biz';
import { Button, PlanGate } from 'components';
import { IntercomAPI } from 'react-intercom';

const Latchel = () => {
	const handleChatToInstall = () => {
		IntercomAPI('showNewMessage');
	};

	return (
		<PlanGate variant="professional" addonIds={Constants.Addons.Latchel.All}>
			<Button onClick={handleChatToInstall}>Install</Button>
		</PlanGate>
	);
};

export default Latchel;
