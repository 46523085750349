import { ApiInspectionWithTemplate } from '@rentcheck/types';

import GridItem from '../grid-item';
import SyncStatus from './sync-status';

interface Props {
	inspection: ApiInspectionWithTemplate;
}

export default ({ inspection }: Props) => {
	return (
		<GridItem
			title="Status"
			value={
				<>
					<SyncStatus inspection={inspection} />
					{inspection.inspection_status}
				</>
			}
		/>
	);
};
