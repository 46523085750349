import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Skeleton,
	useMediaQuery,
	useTheme,
} from '@mui/material';

import { DialogTitle, Screens, Spacer } from 'components';
import { isEmbeddedInMobileApp } from 'utils/helpers';

import { handleClose } from './common';

export default () => {
	const theme = useTheme();
	const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Screens.Container>
			<Dialog
				open
				onClose={handleClose}
				fullScreen={mobileScreen || isEmbeddedInMobileApp()}>
				<DialogTitle title="Create Work Order" icon={solid('share')} />
				<DialogContent>
					<Skeleton
						variant="rectangular"
						width="60%"
						height={14}
						sx={{ borderRadius: 1 }}
					/>

					<Spacer height={6} />
					<Skeleton
						variant="rectangular"
						width="10%"
						height={20}
						sx={{ borderRadius: 1 }}
					/>
					<Spacer height={1} />
					<Skeleton
						variant="rectangular"
						width="30%"
						height={20}
						sx={{ borderRadius: 1 }}
					/>
					<Spacer height={1} />
					<Skeleton
						variant="rectangular"
						width="10%"
						height={14}
						sx={{ borderRadius: 1 }}
					/>
					<Spacer height={1} />
					<Skeleton
						variant="rectangular"
						width="100%"
						height={60}
						sx={{ borderRadius: 1 }}
					/>

					<Spacer height={6} />
					<Skeleton
						variant="rectangular"
						width="10%"
						height={20}
						sx={{ borderRadius: 1 }}
					/>
					<Spacer height={1} />
					<Skeleton
						variant="rectangular"
						width="30%"
						height={20}
						sx={{ borderRadius: 1 }}
					/>
					<Spacer height={1} />
					<Skeleton
						variant="rectangular"
						width="10%"
						height={14}
						sx={{ borderRadius: 1 }}
					/>
					<Spacer height={1} />
					<Skeleton
						variant="rectangular"
						width="100%"
						height={60}
						sx={{ borderRadius: 1 }}
					/>
				</DialogContent>
				<DialogActions>
					<Button variant="text" color="secondary" disabled>
						Dismiss
					</Button>
					<Button disabled>Create</Button>
				</DialogActions>
			</Dialog>
		</Screens.Container>
	);
};
