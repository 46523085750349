import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, TextField, Typography } from '@mui/material';
import { Column, SpacedRow, Spacer } from 'components';
import { useState } from 'react';

interface Props {
	value: string;
	setValue: (value: string) => void;
}

const Dispatch = ({ value, setValue }: Props) => {
	const [enabled, setEnabled] = useState(false);

	if (enabled) {
		return (
			<Column>
				<SpacedRow>
					<Typography fontWeight={600}>
						Specific Instructions For The Vendor
					</Typography>
					<Button
						variant="text"
						color="secondary"
						startIcon={<FontAwesomeIcon icon={solid('times')} />}
						sx={{ height: 30, paddingLeft: 1.5, paddingRight: 1 }}
						onClick={() => {
							setEnabled(false);
							setValue('');
						}}>
						Remove
					</Button>
				</SpacedRow>
				<Spacer height={2} />
				<TextField
					id="textinput-vendor_instructions"
					multiline
					label="Add Specific Instructions For The Vendor"
					fullWidth
					value={value}
					onChange={(e) => setValue(e.target.value)}
					sx={{ '.MuiInputBase-input': { fontWeight: 400 } }}
				/>
			</Column>
		);
	}

	return (
		<Button
			id="button-add_vendor_instructions"
			variant="text"
			color="secondary"
			startIcon={<FontAwesomeIcon icon={solid('plus')} />}
			onClick={() => setEnabled(true)}>
			Add Specific Instructions For The Vendor
		</Button>
	);
};

export default Dispatch;
