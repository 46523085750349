import { InspectionTemplatesApi } from '@rentcheck/api-frontend';
import {
	ApiInspectionTemplate,
	ApiInspectionTemplateDigest,
	ApiTeam,
	GetAllInspectionTemplatesParams,
	ListOptions,
} from '@rentcheck/types';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTypedSelector } from 'store/reducers/common';
import useApiModel from './common/api-hook';

const defaultSort: ListOptions['sort'] = {
	sort_by: 'created_date',
	sort_type: 'ASC',
};

type UseInspectionTemplatesParams =
	GetAllInspectionTemplatesParams['filters'] & {
		teams?: ApiTeam[];
		property_types?: ApiInspectionTemplateDigest['property_type'][];
		search?: string;
	};

export const useInspectionTemplates = ({
	property_types = ['building', 'community', 'unit'],
	include_unavailable,
	include_deleted,
	active_only,
	published_only,
	search,
}: UseInspectionTemplatesParams) => {
	const hookData = useApiModel(
		InspectionTemplatesApi,
		{
			include_unavailable,
			include_deleted,
			active_only,
			published_only,
			property_types,
		},
		defaultSort
	);

	const searchFilteredTemplates = useMemo(() => {
		if (!search) {
			return hookData.data;
		}

		const lowercaseSearch = search.toLowerCase();

		return hookData.data?.filter((template) =>
			`${template.internal_label}${template.name}`
				.toLowerCase()
				.includes(lowercaseSearch)
		);
	}, [hookData.data, search]);

	return {
		templates: searchFilteredTemplates,
		..._.omit(hookData, 'data'),
	};
};

export const useInspectionTemplate = (id?: string) => {
	const [template, setTemplate] = useState<ApiInspectionTemplate>();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (!id) {
			setLoading(false);
			setTemplate(undefined);

			return;
		}

		setLoading(true);

		InspectionTemplatesApi.get(id)
			.then(setTemplate)
			.catch(() => {})
			.finally(() => setLoading(false));
	}, [id]);

	return { template, loading };
};

export const useStoreInspectionTemplate = (id?: string) => {
	const template = useTypedSelector(
		(state) => state.inspectionTemplates.template
	);

	if (!id) {
		return null;
	}

	if (!template || template.id !== id) {
		return null;
	}

	return template;
};
