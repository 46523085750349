import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import styled from 'styled-components';
import { colors } from 'theme';
import { orderRoomNamesArray, roomIcon } from 'utils/helpers';

import { InspectionsApi } from '@rentcheck/api-frontend';
import { FeaturesApi } from 'api';
import { Column } from 'components/layout/Column';
import { Row } from 'components/layout/Row';

interface Props {
	firstID: string;
	secondID: string;
}

function InspectionsComparisonDetailNavbar({ firstID, secondID }: Props) {
	const [rooms, setRooms] = React.useState<any>([]);

	const scrollToRoom = (room: any) => {
		const element = document.getElementById(`room-${room}`);
		if (element) {
			element.scrollIntoView({ behavior: 'auto' });
		}
	};

	const combineArrays = (...arrays: any[]) => {
		let jointArray: string[] = [];
		arrays.forEach((array) => {
			jointArray = [...jointArray, ...array];
		});
		const uniqueArray = jointArray.filter(
			(item, index) => jointArray.indexOf(item) === index
		);
		return uniqueArray;
	};

	const needWarningSign = (
		firstFeaturesByRoom: any,
		secondFeaturesByRoom: any,
		room_name: string
	) => {
		if (firstFeaturesByRoom[room_name] && secondFeaturesByRoom[room_name]) {
			const firstKeys = Object.keys(firstFeaturesByRoom[room_name]);
			const secondKeys = Object.keys(secondFeaturesByRoom[room_name]);
			const allFeatures = combineArrays(firstKeys, secondKeys);
			for (const feature of allFeatures) {
				if (
					firstFeaturesByRoom[room_name][feature] &&
					secondFeaturesByRoom[room_name][feature]
				) {
					const firstRating = firstFeaturesByRoom[room_name][feature].rating;
					const secondRating = secondFeaturesByRoom[room_name][feature].rating;
					if (firstRating !== secondRating) {
						return true;
					}
				} else if (firstFeaturesByRoom[room_name][feature]) {
					const rating = firstFeaturesByRoom[room_name][feature].rating;
					if (rating === 'Fair' || rating === 'Poor') {
						return true;
					}
				} else {
					const rating = secondFeaturesByRoom[room_name][feature].rating;
					if (rating === 'Fair' || rating === 'Poor') {
						return true;
					}
				}
			}
			return false;
		} else if (firstFeaturesByRoom[room_name]) {
			for (const feature in firstFeaturesByRoom[room_name]) {
				const rating = firstFeaturesByRoom[room_name][feature].rating;
				if (rating === 'Fair' || rating === 'Poor') {
					return true;
				}
			}
			return false;
		} else {
			for (const feature in secondFeaturesByRoom[room_name]) {
				const rating = secondFeaturesByRoom[room_name][feature].rating;
				if (rating === 'Fair' || rating === 'Poor') {
					return true;
				}
			}
			return false;
		}
	};

	const fillRoomElements = (
		room_names: string[],
		firstFeaturesByRoom: any,
		secondFeaturesByRoom: any
	) => {
		const roomElements: any[] = [];
		for (let i = 0; i < room_names.length; i++) {
			const room_name = room_names[i];
			const warning = needWarningSign(
				firstFeaturesByRoom,
				secondFeaturesByRoom,
				room_name
			);

			roomElements.push(
				<SideBarItem onClick={() => scrollToRoom(room_name)} key={room_name}>
					<Icon icon={roomIcon(room_name)} />
					<TextWrapper>
						<Title>{room_name}</Title>
					</TextWrapper>
					<RightIcon>
						{warning ? (
							<Tooltip
								title={`There is a difference in one or more ratings between the two inspections.`}
								placement="top-start"
								arrow>
								<span className="fa-layers fa-fw fa-2x">
									<FontAwesomeIcon icon={solid('square')} />
									<FontAwesomeIcon
										icon={solid('exclamation-triangle')}
										color={colors.black}
										inverse
										transform={'shrink-8'}
									/>
								</span>
							</Tooltip>
						) : (
							<span className="fa-layers fa-fw fa-2x" style={{ opacity: 0.2 }}>
								<FontAwesomeIcon icon={solid('square')} />
								<FontAwesomeIcon
									icon={solid('check-circle')}
									color={colors.black}
									inverse
									transform={'shrink-8'}
								/>
							</span>
						)}
					</RightIcon>
				</SideBarItem>
			);
		}
		setRooms(roomElements);
	};

	const getFeaturesByRoom = (features: any, inspection: any) => {
		const featuresByRoom: any = {};
		for (const roomKey of inspection.room_names) {
			featuresByRoom[roomKey] = {};
		}

		Object.values(features).forEach((feature: any) => {
			if (feature.incomplete) return;
			const roomName = feature.room;

			if (roomName) {
				if (featuresByRoom[roomName]) {
					featuresByRoom[roomName] = {
						...featuresByRoom[roomName],
						[feature.feature]: feature,
					};
				} else {
					featuresByRoom[roomName] = {
						[feature.feature]: feature,
					};
				}
			}
		});
		return featuresByRoom;
	};

	const populateRooms = async () => {
		try {
			const firstInspection = await InspectionsApi.getById(firstID);
			const secondInspection = await InspectionsApi.getById(secondID);

			const firstFeatures = await FeaturesApi.getForInspectionId(firstID);
			const secondFeatures = await FeaturesApi.getForInspectionId(secondID);

			const firstFeaturesByRoom = getFeaturesByRoom(
				firstFeatures,
				firstInspection
			);
			const secondFeaturesByRoom = getFeaturesByRoom(
				secondFeatures,
				secondInspection
			);

			const allRoomNames = combineArrays(
				firstInspection.room_names,
				secondInspection.room_names
			);
			const sortedRooms = orderRoomNamesArray(allRoomNames);
			fillRoomElements(sortedRooms, firstFeaturesByRoom, secondFeaturesByRoom);
		} catch (error) {
			setRooms([]);
		}
	};

	React.useEffect(() => {
		populateRooms();
	}, []);

	return <SideBarContainer>{rooms}</SideBarContainer>;
}

const SideBarContainer = styled(Column)`
	height: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
	border-right: 1px solid #d9d7d7;
`;

const RightIcon = styled.div`
	margin-left: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const SideBarItem = styled(Row)`
	min-height: 60px;
	width: 90%;
	background-color: #fafafa;
	display: flex;
	flex-direction: row;
	padding: 10px;
	padding-right: 15px;
	border-bottom: 1px solid #dee2e7;
	border-left: 5px solid #fafafa;
	cursor: pointer;

	&&:hover {
		border-left: 5px solid #3d50ff;
	}
`;

const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-left: 10px;
`;

const Icon = styled(FontAwesomeIcon)`
	height: 30px;
	width: 30px !important;
`;

const Title = styled.div`
	color: #242424;
	font-size: 16px;
	font-weight: bold;
	line-height: 18px;
`;

export default InspectionsComparisonDetailNavbar;
