import { WorkOrdersApi } from '@rentcheck/api-frontend';
import { ReduxFn } from 'types';
import { State as FilterState } from '../../reducers/maintenance/filters/work-orders';

export const pageSize = 20;

export const getAll = (
	offset: number = 0,
	filterState: FilterState
): ReduxFn => {
	const fn: ReduxFn = async (dispatch) => {
		const pageNumber = Math.floor(offset / pageSize);

		const filters = {
			search: filterState.search,
			inspectionTemplate: filterState.inspectionTemplate,
			dateRange: filterState.dateRange?.start
				? filterState.dateRange
				: undefined,
			status: filterState.status,
		};

		const sorting = {
			sortBy: filterState.sortBy,
			sortType: filterState.sortType,
		};

		const { data, totalResults } = await WorkOrdersApi.getAll(
			pageNumber,
			pageSize,
			filters,
			sorting
		);

		const dispatchType =
			offset === 0 ? 'FETCHED_INITIAL_WORK_ORDERS' : 'FETCHED_WORK_ORDERS_PAGE';

		dispatch({ type: dispatchType, workOrders: data });
		dispatch({ type: 'SET_TOTAL_WORK_ORDERS', value: totalResults });

		return data;
	};

	return fn;
};

export const getById = (id: string): ReduxFn => {
	const fn: ReduxFn = async (dispatch) => {
		const workOrder = await WorkOrdersApi.getById(id);
		dispatch({ type: 'FETCHED_WORK_ORDER', workOrder });
		return workOrder;
	};
	return fn;
};
