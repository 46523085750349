import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Dialog,
	DialogContent,
	Button,
	Typography,
	Divider,
} from '@mui/material';

import { CenteredColumn, Spacer } from 'components';
import { colors } from 'theme';

import {
	IntegrationDialogOption,
	ActiveIntegration,
} from './PropertyUploadButton';

interface Props {
	dialogOption: IntegrationDialogOption;
	activeIntegration: ActiveIntegration;
	handleClose: () => void;
	handleContinue: () => void;
}

export default ({
	dialogOption,
	activeIntegration,
	handleClose,
	handleContinue,
}: Props) => {
	return (
		<Dialog onClose={handleClose} open={!!dialogOption} maxWidth="xs">
			<DialogContent>
				<CenteredColumn>
					<FontAwesomeIcon
						icon={solid('triangle-exclamation')}
						color={colors.primary}
						size="xl"
					/>

					<Spacer height={3} />

					<Typography variant="subtitle1">{`Manually add ${dialogOption}?`}</Typography>
				</CenteredColumn>

				<Spacer height={3} />

				<Typography color="#00000080">
					{`Are you sure you want to manually add a ${dialogOption}?`}
				</Typography>
				<Typography color="#00000080">{`You currently have an active ${activeIntegration} integration.`}</Typography>
				<Typography color="#00000080">
					{`Manually adding ${dialogOption}s can cause duplicates, which may lead to additional charges.`}
				</Typography>

				<Spacer height={5} />

				<CenteredColumn>
					<Button
						variant="text"
						onClick={handleContinue}
						fullWidth
						style={{ padding: 10 }}>
						Continue
					</Button>
				</CenteredColumn>

				<Divider />

				<CenteredColumn>
					<Button
						variant="text"
						onClick={handleClose}
						fullWidth
						style={{ padding: 10 }}>
						Cancel
					</Button>
				</CenteredColumn>
			</DialogContent>
		</Dialog>
	);
};
