import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import styled from 'styled-components';
import { colors, spacingPx } from 'theme';
import { CenteredRow, Column, SpacedRow, Spacer } from '../../..';

interface Props {
	leftClick?: () => void;
	rightClick: () => void;
	rightClickDisabled?: () => void;
	leftLabel?: string;
	rightLabel: string;
	disabled: boolean;
	loading?: boolean;
}

const CreatePropertyFooter = ({
	leftClick,
	rightClick,
	leftLabel,
	rightLabel,
	disabled,
	loading = false,
}: Props) => {
	return (
		<Column>
			<Spacer height={7} />
			<Divider />
			<Spacer height={5} />

			{!!leftClick && !!leftLabel && (
				<SpacedRow>
					<ButtonContainer left>
						<Button variant="outlined" color="secondary" onClick={leftClick}>
							{leftLabel}
						</Button>
					</ButtonContainer>
					<ButtonContainer right>
						<LoadingButton
							loading={loading}
							disabled={disabled}
							onClick={rightClick}>
							{rightLabel}
						</LoadingButton>
					</ButtonContainer>
				</SpacedRow>
			)}
			{!leftClick && !leftLabel && (
				<CenteredRow>
					<ButtonContainer right>
						<LoadingButton
							loading={loading}
							disabled={disabled}
							onClick={rightClick}>
							{rightLabel}
						</LoadingButton>
					</ButtonContainer>
				</CenteredRow>
			)}
		</Column>
	);
};

const ButtonContainer = styled.div<{ right?: boolean; left?: boolean }>`
	display: flex;
	flex-direction: column;
	padding-left: ${({ right }) => (right ? spacingPx(2) : 0)};
	padding-right: ${({ left }) => (left ? spacingPx(2) : 0)};
`;

const Divider = styled.div`
	height: 1px;
	width: 100%;
	background-color: ${colors.contentBorder};
`;

export default CreatePropertyFooter;
