import { MaintenanceFlag } from '@rentcheck/types';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FeatureActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { maintenanceFlagsFromFeatures } from 'utils/helpers';

export const useSortedMaintenanceFlags = (inspectionId: string) => {
	const dispatch = useDispatch();

	const featuresDictionary = useTypedSelector(
		(state) => state.features[inspectionId]
	);

	const features = _.values(featuresDictionary ?? []);

	const [sortBy, setSortBy] = useState<keyof MaintenanceFlag>('room');
	const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
	const [sortedMaintenanceFlags, setSortedMaintenanceFlags] = useState(
		maintenanceFlagsFromFeatures(features)
	);

	useEffect(() => {
		dispatch(FeatureActions.requestInspectionFeatures(inspectionId));
	}, [inspectionId]);

	useEffect(() => {
		const maintenanceFlags = maintenanceFlagsFromFeatures(features);

		const sortFunction = (f1: MaintenanceFlag, f2: MaintenanceFlag) => {
			const textA = _.get(f1, sortBy, '-');
			const textB = _.get(f2, sortBy, '-');

			if (textA === '-') return 0;
			if (textB === '-') return -1;

			return textA > textB ? -1 : textA < textB ? 1 : 0;
		};

		if (sortDirection === 'asc') {
			setSortedMaintenanceFlags(maintenanceFlags.sort(sortFunction).reverse());
		} else {
			setSortedMaintenanceFlags(maintenanceFlags.sort(sortFunction));
		}
	}, [sortBy, sortDirection, featuresDictionary]);

	return {
		setSortBy,
		sortBy,
		setSortDirection,
		sortDirection,
		maintenanceFlags: sortedMaintenanceFlags,
	};
};

export const useFeature = (
	inspectionId: string,
	roomName: string,
	featureName: string
) => {
	return useTypedSelector((state) =>
		_.values(state.features[inspectionId])?.find(
			(f) => f.section.name_with_ordinal === roomName && f.name === featureName
		)
	);
};
