import { FlatfileButton, FlatfileResults } from '@flatfile/react';
import { AccountSettings } from '@rentcheck/types';
import { Column, Spacer } from 'components';

import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AccountSettingsActions, SnackbarActions } from 'store/actions';
import { Dispatch } from 'types';
import flatfileSettings from './flatfile-settings';
import SkillsList from './skills-list';

interface Props {
	value?: AccountSettings;
	setValue: (value?: AccountSettings) => void;
}

export default ({ value, setValue }: Props) => {
	const dispatch = useDispatch<Dispatch>();
	const [loading, setLoading] = useState(false);

	if (!value) {
		return null;
	}

	const handleData = async (results: FlatfileResults) => {
		/**
		 * Validate that there's exactly one default skill.
		 */
		if (results.data.filter((data) => data.is_default).length !== 1) {
			dispatch(
				SnackbarActions.showError('Please select exactly one default skill.')
			);
			return;
		}

		setLoading(true);

		/**
		 * Clean up data.sub_category if undefined, as firebase
		 * is not configured to ignore undefined values.
		 */
		results.data.forEach((data) => {
			if (data.sub_category === undefined) {
				delete data.sub_category;
			}
		});

		dispatch(AccountSettingsActions.updateSkills(results.data))
			.then((ac: AccountSettings) => {
				setValue(ac);
				dispatch(SnackbarActions.showSuccess('Skills uploaded succesfully'));
			})
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	return (
		<Column style={{ width: '50%' }}>
			<Spacer height={4} />
			<FlatfileButton
				licenseKey="cc7bd3c0-e3dd-4c8a-9b0b-1eb25428d05a"
				customer={{ userId: '123123' }}
				settings={flatfileSettings.skills}
				onData={handleData}
				render={(_importer, launch) => (
					<LoadingButton variant="contained" loading={loading} onClick={launch}>
						Upload
					</LoadingButton>
				)}
			/>
			<Spacer height={4} />
			<SkillsList value={value} setValue={setValue} />
		</Column>
	);
};
