import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { Table, TableBody, TableContainer, Paper } from '@mui/material';

import { Dispatch } from 'types';
import { MaintenanceActions, SnackbarActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import {
	hasAppliedFilters,
	State as FiltersState,
} from 'store/reducers/maintenance/filters/work-orders';
import { InfiniteScroll } from 'components';

import TableHeader from './header';
import TableRow from './row';
import EmptyState from '../../common/empty-state';
import FiltersSkeleton from '../../common/skeleton-filters';

const WorkOrdersTable = () => {
	const dispatch: Dispatch = useDispatch();

	const workOrders = useTypedSelector(
		(state) => state.maintenance.workOrders.data
	);
	const filters = useTypedSelector(
		(state) => state.maintenance.filters.workOrders
	);

	const [skippedFirstFetch, setSkippedFirstFetch] = useState(false);
	const [loadingFilters, setLoadingFilters] = useState(false);
	const [selectedIds, setSelectedIds] = useState<string[]>([]);

	useEffect(() => {
		if (!skippedFirstFetch) {
			setSkippedFirstFetch(true);
			return;
		}

		fetchOrders(filters);
	}, [filters]);

	const debouncedFetch = _.debounce((filters: FiltersState) => {
		setSelectedIds([]);
		setLoadingFilters(true);

		dispatch(MaintenanceActions.workOrders.getAll(0, filters))
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => {
				setLoadingFilters(false);
			});
	}, 300);

	const fetchOrders = useCallback((filters: FiltersState) => {
		setLoadingFilters(true);
		debouncedFetch(filters);
	}, []);

	const handleSelectAll = () => {
		if (selectedIds.length === workOrders.length) {
			return setSelectedIds([]);
		}

		setSelectedIds(workOrders.map((i) => i.id));
	};

	const handleSelectId = (id: string) => {
		setSelectedIds(_.xor(selectedIds, [id]));
	};

	if (
		workOrders.length === 0 &&
		!hasAppliedFilters(filters) &&
		!loadingFilters
	) {
		return <EmptyState type="workOrders" />;
	}

	return (
		<div>
			<TableContainer component={Paper}>
				<FiltersSkeleton loading={loadingFilters} />
				<Table sx={{ minWidth: 650 }}>
					<TableHeader
						selectedIds={selectedIds}
						selectAllIds={handleSelectAll}
						workOrders={workOrders}
					/>
					<TableBody>
						{workOrders.map((row) => (
							<TableRow
								key={row.id}
								workOrder={row}
								selectedIds={selectedIds}
								selectId={handleSelectId}
							/>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			{!loadingFilters && (
				<InfiniteScroll
					objectName="Work Orders"
					filterState={filters}
					action={MaintenanceActions.workOrders}
					offset={workOrders.length}
				/>
			)}
		</div>
	);
};

export default WorkOrdersTable;
