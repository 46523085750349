import { useTypedSelector } from 'store/reducers/rootReducer';

import SnapshotRow from './snapshot-row';

const AlmostDue = () => {
	const dashboardData = useTypedSelector((state) => state.dashboard.data);

	const count = dashboardData?.todo_list.almost_due_inspections_count;

	return (
		<SnapshotRow
			count={count}
			label="Almost Due"
			link="/dashboard/almost-due"
		/>
	);
};

export default AlmostDue;
