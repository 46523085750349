import { TeamInvitesApi, TeamsApi } from '@rentcheck/api-frontend';
import {
	ApiTeamDetails,
	CreateTeamInviteResult,
	MembershipRole,
} from '@rentcheck/types';
import { Dispatch } from 'types';
import { Analytics } from 'utils';
import { getBase64 } from 'utils/helpers';

export type TeamActionType = 'SET_TEAM' | 'IMPERSONATE' | 'FETCHED_TEAMS';

export const actionTypes: { [index: string]: TeamActionType } = {
	FETCHED_TEAMS: 'FETCHED_TEAMS',
	SET_TEAM: 'SET_TEAM',
	IMPERSONATE: 'IMPERSONATE',
};

export const getById = (id: string) => {
	return async (dispatch: Dispatch): Promise<ApiTeamDetails> => {
		return TeamsApi.getById(id).then((team) => {
			dispatch({ type: actionTypes.SET_TEAM, team });
			dispatch({ type: actionTypes.FETCHED_TEAMS, teams: [team] });

			return team;
		});
	};
};

export const create = (name: string, internalLabel: string, logo?: File) => {
	return async (dispatch: Dispatch): Promise<ApiTeamDetails> => {
		const logoBase64 = logo ? await getBase64(logo) : undefined;
		const team = await TeamsApi.create({
			name,
			internal_label: internalLabel,
			logo: logoBase64,
		});

		dispatch({ type: actionTypes.SET_TEAM, team });
		dispatch({ type: actionTypes.FETCHED_TEAMS, teams: [team] });

		Analytics.trackEvent('created_team', {
			team_id: team.id,
			team_name: team.name,
			team_label: team.internal_label,
		});

		if (logo) {
			Analytics.trackEvent('uploaded_team_logo', {
				team_id: team.id,
				team_name: team.name,
				team_label: team.internal_label,
			});
		}

		return team;
	};
};

const getLogoBase64 = async (logo?: File | null) => {
	if (logo === null) return 'delete';
	if (!logo) return undefined;

	return getBase64(logo);
};

export const update = (
	id: string,
	payload: { name?: string; internal_label?: string; logo?: File | null }
) => {
	return async (dispatch: Dispatch): Promise<ApiTeamDetails> => {
		const logoBase64 = await getLogoBase64(payload.logo);

		const team = await TeamsApi.update(id, { ...payload, logo: logoBase64 });

		dispatch({ type: actionTypes.SET_TEAM, team });
		dispatch({ type: actionTypes.FETCHED_TEAMS, teams: [team] });

		return team;
	};
};

export const inviteTeammates = (
	teamId: string,
	emails: string[],
	isMainTeam: boolean = true
) => {
	return async (
		dispatch: Dispatch
	): Promise<CreateTeamInviteResult['data']> => {
		const response = await TeamInvitesApi.create(teamId, { emails });

		isMainTeam && dispatch({ type: actionTypes.SET_TEAM, team: response.team });
		dispatch({ type: actionTypes.FETCHED_TEAMS, teams: [response.team] });

		return response;
	};
};

export const removeTeammate = (organizationId: string, teammateId: string) => {
	return async (dispatch: Dispatch): Promise<ApiTeamDetails> => {
		const team = await TeamsApi.deleteMember(organizationId, teammateId);

		dispatch({ type: actionTypes.SET_TEAM, team });
		dispatch({ type: actionTypes.FETCHED_TEAMS, teams: [team] });

		return team;
	};
};

export const updateTeammate = (
	organizationId: string,
	teammateId: string,
	payload: { role?: MembershipRole }
) => {
	return async (dispatch: Dispatch): Promise<ApiTeamDetails> => {
		const team = await TeamsApi.updateMember(
			organizationId,
			teammateId,
			payload
		);

		dispatch({ type: actionTypes.SET_TEAM, team });
		dispatch({ type: actionTypes.FETCHED_TEAMS, teams: [team] });

		return team;
	};
};
