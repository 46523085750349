import { Skeleton } from '@mui/material';
import { Row, SpacedRow, Spacer } from 'components';

const FilterSkeleton = () => {
	return (
		<>
			<Skeleton
				variant="rectangular"
				width={70}
				height={30}
				sx={{ borderRadius: 4 }}
			/>
			<Spacer width={3} />
		</>
	);
};
const FiltersSkeleton = () => {
	return (
		<SpacedRow>
			<Row>
				<FilterSkeleton />
				<FilterSkeleton />
				<FilterSkeleton />
				<FilterSkeleton />
				<FilterSkeleton />
				<FilterSkeleton />
			</Row>
			<Skeleton
				variant="rectangular"
				width={300}
				height={40}
				sx={{ borderRadius: 1 }}
			/>
		</SpacedRow>
	);
};

export default FiltersSkeleton;
