import { Typography } from '@mui/material';
import { colors } from 'theme';

import typographyTheme from 'theme/typography';
interface Props {
	details: { title: string; value: string }[];
}

const IntegrationDetails = ({ details }: Props) => {
	return (
		<>
			{details.map((d) => (
				<Typography variant="body2" color={colors.secondary} mb={1}>
					<span
						style={{
							...typographyTheme.MuiTypography.styleOverrides.subtitle2,
							marginRight: 4,
						}}>
						{d.title}
					</span>
					{d.value}
				</Typography>
			))}
		</>
	);
};

export default IntegrationDetails;
