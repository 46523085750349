import { combineReducers } from 'redux';
import templateReducer, { State as TemplateState } from './template';
import templatesReducer, { State as TemplatesState } from './templates';

export type State = {
	templates: TemplatesState;
	template: TemplateState;
};

const reducer = combineReducers({
	templates: templatesReducer,
	template: templateReducer,
});

export default reducer;
