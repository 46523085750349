import {
	Checkbox,
	Collapse,
	Divider,
	FormControl,
	FormControlLabel,
	Paper,
	Radio,
	RadioGroup,
	Typography,
} from '@mui/material';
import { ApiTeam } from '@rentcheck/types';
import { Column } from 'components';
import { useStoreTeams } from 'hooks/teams';
import { useEffect } from 'react';
import { scrollToElement } from 'utils/helpers';

export type TeamAccessT = 'all-teams' | 'select-teams';

interface Props {
	accessType: TeamAccessT;
	setAccessType: (accessType: TeamAccessT) => void;

	selectedTeams: ApiTeam[];
	setSelectedTeams: (selectedTeams: ApiTeam[]) => void;
}

export default ({
	accessType,
	setAccessType,
	selectedTeams,
	setSelectedTeams,
}: Props) => {
	const { teams } = useStoreTeams();

	useEffect(() => {
		if (accessType === 'all-teams') {
			setSelectedTeams([]);
		}
	}, [accessType]);

	const handleChangeAccessType = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const accessType = event.target.value as TeamAccessT;

		setAccessType(accessType);

		if (accessType === 'select-teams') {
			setTimeout(
				() =>
					scrollToElement('edit-template-details-team-access-scroll-marker'),
				400
			);
		}
	};

	const handleChangeTeam = (team: ApiTeam) => {
		if (selectedTeams.some((t) => t.id === team.id)) {
			setSelectedTeams(selectedTeams.filter((t) => t.id !== team.id));
		} else {
			setSelectedTeams([...selectedTeams, team]);
		}
	};

	return (
		<>
			<Typography variant="overline">Inspection Template access</Typography>
			<Typography variant="body2" color="text.secondary" mt={1}>
				Enable for one or more teams
			</Typography>

			<Paper elevation={0} sx={{ border: '1px solid #0000001F', mt: 1 }}>
				<FormControl fullWidth>
					<RadioGroup value={accessType} onChange={handleChangeAccessType}>
						<FormControlLabel
							value="all-teams"
							control={<Radio />}
							sx={{ padding: '8px 16px 8px 2px' }}
							labelPlacement="start"
							label={<Typography sx={{ flex: 1 }}>All Teams</Typography>}
						/>

						<Divider />
						<FormControlLabel
							value="select-teams"
							control={<Radio />}
							sx={{ padding: '8px 16px 8px 2px' }}
							labelPlacement="start"
							label={<Typography sx={{ flex: 1 }}>Select Teams</Typography>}
						/>
						<Collapse
							id="team-list"
							in={accessType === 'select-teams'}
							sx={{
								display: 'flex',
								maxHeight: 250,
								overflowY: 'scroll',
								overflowX: 'clip',
							}}>
							<Column>
								{teams.map((team) => (
									<FormControlLabel
										key={team.id}
										value="select-teams"
										control={
											<Checkbox
												checked={selectedTeams.some((t) => t.id === team.id)}
												onChange={() => handleChangeTeam(team)}
											/>
										}
										sx={{ padding: '8px 18px 8px 2px' }}
										labelPlacement="start"
										label={
											<Typography variant="body2" sx={{ flex: 1 }}>
												{team.internal_label}
											</Typography>
										}
									/>
								))}
							</Column>
						</Collapse>
					</RadioGroup>
				</FormControl>
			</Paper>
			<div id="edit-template-details-team-access-scroll-marker" />
		</>
	);
};
