import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { APIBuilding, APIProperty } from '@rentcheck/types';

import { Typography } from '@mui/material';
import { SelectPropertyDropDown, Spacer } from 'components';
import Modal, { Footer } from 'components/Common/Modal';
import { PropertiesActions, SnackbarActions } from 'store/actions';
import { Dispatch } from 'types';

interface Props {
	propertyIds: string[];
	unitsReassigned: () => void;
	setModalVisible: (visible: boolean) => void;
}

const AssignBuildingModal = ({
	propertyIds,
	unitsReassigned,
	setModalVisible,
}: Props) => {
	const dispatch: Dispatch = useDispatch();

	const [selectedProperty, setSelectedProperty] = useState<APIProperty | null>(
		null
	);

	const handleAssignUnitsToBuilding = async () => {
		if (!selectedProperty) return;

		const name = (selectedProperty as APIBuilding).name;

		setModalVisible(false);

		const progress = await dispatch(
			SnackbarActions.showProgress(`Assigning to ${name}`)
		);

		dispatch(
			PropertiesActions.units.update(propertyIds, {
				building_id: selectedProperty.id,
			})
		)
			.then(() => {
				unitsReassigned();
				dispatch(SnackbarActions.showSuccess(`Assigned to ${name}`));
			})
			.catch(() => dispatch(SnackbarActions.showError()))
			.finally(() => dispatch(SnackbarActions.hideSnackbar(progress.id)));
	};

	const singularOrPlural =
		propertyIds.length === 1 ? 'this unit' : 'these units';

	return (
		<>
			<Modal setHidden={() => setModalVisible(false)}>
				<Typography variant="h4">
					Which building do you want to add {singularOrPlural} to?
				</Typography>

				<Spacer height={7} />

				<div>
					<SelectPropertyDropDown
						selectedProperty={selectedProperty}
						setSelectedProperty={setSelectedProperty}
						closeModal={() => setModalVisible(false)}
						showType="building"
					/>
				</div>

				<Footer
					hideDivider
					leftButtonTitle="Cancel"
					leftButtonOnClick={() => setModalVisible(false)}
					disabled={!selectedProperty}
					rightButtonTitle="Add"
					rightButtonOnClick={handleAssignUnitsToBuilding}
				/>
			</Modal>
		</>
	);
};

export default AssignBuildingModal;
