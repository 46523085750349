import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Rules } from '@rentcheck/biz';
import { ApiInspection, Feature } from '@rentcheck/types';
import { colors } from 'theme';
import { getThumbnailUrl } from 'utils/helpers';

interface Props {
	inspection: ApiInspection;
	feature: Feature;
	image: string;
}

export default ({ inspection, feature, image }: Props) => {
	if (!Rules.Inspections.canPerformFeatureActions(inspection)) {
		return null;
	}

	const hasMaintenanceFlagLinks = !!feature.maintenance_flags?.find(
		(flag) =>
			flag.image_urls?.find((url) => url === image) ||
			flag.video_urls?.find(
				(url) => url === image || getThumbnailUrl(url) === image
			)
	);

	if (!hasMaintenanceFlagLinks) return null;

	return (
		<div
			style={{
				height: 32,
				width: 32,
				borderRadius: 32,
				marginLeft: 8,
				backgroundColor: '#FFFFFFBD',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}>
			<FontAwesomeIcon icon={solid('flag')} color={colors.primary} />
		</div>
	);
};
