import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Typography,
} from '@mui/material';
import { useHistory } from 'react-router-dom';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { success } from 'assets';
import { CenteredColumn, Column, DialogTitle, Spacer } from 'components';

interface Props {
	vendor: string;
	parentUrl: string;
	handleClose: () => void;
	waitForConnection?: boolean;
}

const Success = ({
	vendor,
	parentUrl,
	waitForConnection,
	handleClose,
}: Props) => {
	const history = useHistory();

	const handleSetup = () => {
		handleClose();

		if (waitForConnection) {
			history.push(parentUrl);
			return;
		}

		history.push(parentUrl + '/data-sync');
	};

	return (
		<Dialog open onClose={handleClose}>
			<DialogTitle
				title={`Connect ${vendor} to RentCheck`}
				icon={solid('plug')}
				onClose={handleClose}
			/>
			<DialogContent>
				<Column>
					<img
						style={{
							width: 400,
							height: 180,
							objectFit: 'contain',
							alignSelf: 'center',
						}}
						src={success.appFolioUpgrade}
					/>
					<Spacer height={4} />
					<CenteredColumn>
						<Typography variant="h5">Oh Yeah!</Typography>

						{!waitForConnection && (
							<Typography
								variant="subtitle1"
								sx={{ marginTop: 2 }}
								textAlign="center">
								Your {vendor} and RentCheck accounts are connected. Set up your
								sync to add units, add residents, and start creating
								inspections.
							</Typography>
						)}

						{waitForConnection && (
							<Typography
								variant="subtitle1"
								sx={{ marginTop: 2 }}
								textAlign="center">
								We’re in the process of connecting your RentCheck account to{' '}
								{vendor}. This could take up to 15 minutes to complete.
								<br />
								<br />
								We'll notify you by email and in-app once the connection is
								complete and it's time for you to configure your {vendor} data
								sync settings.
							</Typography>
						)}
					</CenteredColumn>
				</Column>
			</DialogContent>

			<DialogActions>
				<div />

				<Button onClick={handleSetup}>
					{waitForConnection ? 'Done' : 'Set up sync'}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default Success;
