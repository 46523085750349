import { TemplateQuestion } from '@rentcheck/types';
import FormTextField from 'components/form-text-field';

interface Props {
	question: TemplateQuestion;
	onUpdate: (question: TemplateQuestion) => void;
	onSave: () => void;
}

export default ({ question, onUpdate, onSave }: Props) => {
	const handleUpdateQuestionTitle = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		onUpdate({ ...question, title: e.target.value });
	};

	const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			onSave();
		}
	};

	return (
		<>
			<FormTextField
				required
				autoFocus
				title="Question Text"
				value={question.title}
				placeholder="Add a question. Example: What color are the walls?"
				onChange={handleUpdateQuestionTitle}
				onKeyPress={handleKeyPress}
			/>
		</>
	);
};
