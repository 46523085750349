import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import SegmentChip from 'components/segment-chip';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/reducers/rootReducer';

export default () => {
	const dispatch = useDispatch();

	const filters = useTypedSelector((state) => state.inspections.filters);

	const handleClick = () => {
		dispatch({ type: 'RESET_INSPECTION_FILTERS' });
		dispatch({ type: 'SET_INSPECTION_ARCHIVED_FILTER', value: true });
	};

	const selected = filters.archived;

	return (
		<SegmentChip
			variant="outlined"
			icon={solid('archive')}
			label="Archived"
			title="Archived"
			onClick={handleClick}
			color="info"
			selected={selected}
		/>
	);
};
