import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import * as AuthActions from 'store/actions/auth-actions';
import { useTypedSelector } from 'store/reducers/rootReducer';

import { UserType } from '@rentcheck/types';

import { Dispatch } from 'types';
import Container from '../container';
import AdditionalStep from './additional-step';
import InitialStep from './initial-step';

export type Steps = 'initial' | 'additional' | 'success';

const SignUp = () => {
	const location = useLocation();
	const dispatch: Dispatch = useDispatch();
	const history = useHistory();

	const auth = useTypedSelector((state) => state.firebase.auth);

	const search = location.search;

	const [step, setStep] = useState<Steps>('initial');
	const [defaultPage, setDefaultPage] = useState('');
	const [defaultPageSegment, setDefaultPageSegment] = useState('');
	const [loading, setLoading] = useState(false);

	// First Step
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [terms, setTerms] = useState(false);
	const [bypassEmailValidation, setBypassEmailValidation] = useState(false);
	const [emailSuggestion, setEmailSuggestion] = useState('');

	// Second Step
	const [userType, setUserType] = useState<UserType>('Landlord');
	const [totalProperties, setTotalProperties] = useState(0);
	const [companyName, setCompanyName] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');

	const [initialScreenError, setInitialScreenError] =
		useState<AuthActions.AdditionalScreenErrors>('');

	useEffect(() => {
		if (search) {
			setDefaultPage(search);

			const segment = queryString.parse(search).redirect;
			if (segment) {
				setDefaultPageSegment(segment as string);
			}
		}
	}, [search]);

	const signUpUser = () => {
		setLoading(true);

		dispatch(
			AuthActions.signUp({
				email,
				password,
				firstName,
				lastName,
				userType: userType as UserType,
				totalProperties,
				company: companyName,
				phoneNumber,
				bypassEmailValidation,
				setEmailSuggestion,
				setBypassEmailValidation,
				setInitialScreenError,
			})
		)
			.then(redirectIfNeeded)
			.finally(() => setLoading(false));
	};

	const redirectIfNeeded = () => {
		if (defaultPageSegment) {
			history.push(`/app?$deeplink_path=${defaultPageSegment}`);
		}
	};

	if (auth.uid) return <Redirect to={defaultPage} />;

	return (
		<Container>
			{step === 'initial' && (
				<InitialStep
					userType={userType}
					setUserType={setUserType}
					totalProperties={totalProperties}
					setTotalProperties={setTotalProperties}
					companyName={companyName}
					setCompanyName={setCompanyName}
					phoneNumber={phoneNumber}
					setPhoneNumber={setPhoneNumber}
					defaultPage={defaultPage}
					setStep={setStep}
				/>
			)}
			{step === 'additional' && (
				<AdditionalStep
					email={email}
					emailSuggestion={emailSuggestion}
					loading={loading}
					setEmail={setEmail}
					password={password}
					setPassword={setPassword}
					firstName={firstName}
					setFirstName={setFirstName}
					lastName={lastName}
					setLastName={setLastName}
					terms={terms}
					setTerms={setTerms}
					defaultPage={defaultPage}
					setError={setInitialScreenError}
					error={initialScreenError}
					signUpUser={signUpUser}
				/>
			)}
		</Container>
	);
};

export default SignUp;
