import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { StorageApi } from 'api';
import { CenteredRow } from 'components';
import IconButton from 'components/icon-button';
import { useState } from 'react';
import {
	downloadCORSInhibitedBlob,
	isEmbeddedInMobileApp,
} from 'utils/helpers';

interface Props {
	imageUri: string;

	handleZoomIn?: () => void;
	handleZoomOut?: () => void;
}

export default ({ imageUri, handleZoomIn, handleZoomOut }: Props) => {
	const [downloadLoading, setDownloadLoading] = useState(false);

	const handleDownload = () => {
		setDownloadLoading(true);

		StorageApi.getDownloadUrl(imageUri)
			.then((url) => {
				downloadCORSInhibitedBlob(url);
			})
			.finally(() => setDownloadLoading(false));
	};

	return (
		<CenteredRow style={{ marginBottom: 12 }}>
			{!!handleZoomOut && (
				<IconButton
					icon={regular('magnifying-glass-minus')}
					onClick={handleZoomOut}
					tooltip="Zoom Out"
				/>
			)}

			{!!handleZoomIn && (
				<IconButton
					icon={regular('magnifying-glass-plus')}
					onClick={handleZoomIn}
					tooltip="Zoom In"
				/>
			)}
			{!isEmbeddedInMobileApp() && (
				<IconButton
					icon={regular('download')}
					onClick={handleDownload}
					tooltip="Download Media"
					loading={downloadLoading}
				/>
			)}
		</CenteredRow>
	);
};
