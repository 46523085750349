import { combineReducers } from 'redux';
import communitiesReducer, { State as CommunitiesState } from './communities';
import buildingsReducer, { State as BuildingsState } from './buildings';
import unitsReducer, { State as UnitsState } from './units';

export type State = {
	communities: CommunitiesState;
	buildings: BuildingsState;
	units: UnitsState;
};

const reducer = combineReducers({
	communities: communitiesReducer,
	buildings: buildingsReducer,
	units: unitsReducer,
});

export default reducer;
