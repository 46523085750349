import { useTypedSelector } from 'store/reducers/rootReducer';

import SnapshotRow from './snapshot-row';

const Overdue = () => {
	const dashboardData = useTypedSelector((state) => state.dashboard.data);

	const count = dashboardData?.todo_list.overdue_inspections_count;

	return (
		<SnapshotRow count={count} label="Overdue" link="/dashboard/overdue" />
	);
};

export default Overdue;
