import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FilterMenuItem } from 'components/table/filter-button-v2';
import { useDispatch } from 'react-redux';
import { actionType, useFilter } from './common';

export default () => {
	const dispatch = useDispatch();
	const filter = useFilter();

	const handleClicked = () => {
		dispatch({ type: actionType, value: true });
	};

	const disabled = !!filter;
	const tooltip = disabled ? 'This filter is already in use' : undefined;

	return (
		<FilterMenuItem
			title={'Inspection Archived'}
			icon={solid('archive')}
			onClick={handleClicked}
			{...{ disabled, tooltip }}
		/>
	);
};
