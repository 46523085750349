import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/reducers/rootReducer';

import { Button, LoadingButton } from 'components/button';
import { useInvoices } from 'hooks/billing';
import { SessionOptionsActions } from 'store/actions';
import { Dispatch } from 'types';
import { handleUpdatePaymentMethod } from '../subscribed/common';
import ChatToUpgrade from './chat-to-upgrade';
import ExplorePlans from './explore-plans';

export default () => {
	const dispatch = useDispatch<Dispatch>();

	const profile = useTypedSelector((state) => state.activeProfile);
	const subscription = useTypedSelector((state) => state.subscription);

	const { invoices, loading } = useInvoices();

	const handleAddPaymentDetails = () => {
		dispatch(SessionOptionsActions.setBillingSetupDismissed(false));
	};

	const handleUpgradeFromTrial = () => {
		handleUpdatePaymentMethod(dispatch, profile, subscription);
	};

	if (subscription) {
		if (subscription.status === 'in_trial' && !subscription.payment_method) {
			return (
				<Button
					variant="outlined"
					onClick={handleUpgradeFromTrial}
					startIcon={<FontAwesomeIcon icon={solid('sparkles')} />}>
					Upgrade from Trial
				</Button>
			);
		}

		if (subscription.status === 'paused') {
			return <ChatToUpgrade />;
		}

		if (subscription.status === 'cancelled') {
			if (loading) {
				return (
					<LoadingButton
						loading
						startIcon={<FontAwesomeIcon icon={solid('sparkles')} />}>
						Explore Plans
					</LoadingButton>
				);
			}
		}

		if (invoices.some((i) => ['not_paid', 'payment_due'].includes(i.status))) {
			return <ChatToUpgrade />;
		}

		return <ExplorePlans />;
	}

	if (profile.prospect_data?.payment_info_provided === false) {
		return (
			<Button
				variant="outlined"
				onClick={handleAddPaymentDetails}
				startIcon={<FontAwesomeIcon icon={solid('credit-card')} />}>
				Add payment details
			</Button>
		);
	}

	return <ExplorePlans />;
};
