import {
	InspectionStatusDisplay,
	inspectionStatusDisplayEnumArray,
	MultiValueFilter,
} from '@rentcheck/types';
import AutocompleteFilterChip from 'components/table/filter-button-v2/components/filter-chip/autocomplete';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { actionType, icon, useFilter } from './common';

export default () => {
	const dispatch = useDispatch();
	const filter = useFilter();

	const handleSetValue = (
		value: MultiValueFilter<InspectionStatusDisplay> | undefined
	) => {
		if (!value || !value.value || value.value.length === 0) {
			dispatch({
				type: actionType,
				value: undefined,
			});

			return;
		}

		dispatch({
			type: actionType,
			value,
		});
	};

	/**
	 * Assign here and create a new array to get around the TS error
	 * for inspectionStatusDisplayEnumArray being readonly vs the
	 * options prop in the FilterChip being mutable
	 */
	const options = useMemo(() => [...inspectionStatusDisplayEnumArray], []);

	if (!filter) {
		return null;
	}

	return (
		<AutocompleteFilterChip
			multiple
			title="Status"
			icon={icon}
			value={filter.value}
			condition={filter.condition}
			setFilterValue={handleSetValue}
			options={options}
		/>
	);
};
