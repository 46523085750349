import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Paper, Skeleton, TextField, Typography } from '@mui/material';
import { LatchelProperty, LatchelTenant } from '@rentcheck/api-frontend';
import { Column, Row, Spacer } from 'components';
import { colors } from 'theme';

interface Props {
	property: LatchelProperty | null;
	tenants: LatchelTenant[];
	tenant?: LatchelTenant;
	setTenant: (value?: LatchelTenant) => void;
	loadingTenants: boolean;
	value: string;
	setValue: (value: string) => void;
}

interface OccupiedProps {
	tenants: LatchelTenant[];
	tenant?: LatchelTenant;
	setTenant: (value?: LatchelTenant) => void;
}

interface VacantProps {
	value: string;
	setValue: (value: string) => void;
}

const Occupied = ({ tenants, tenant, setTenant }: OccupiedProps) => {
	return (
		<>
			<Typography variant="h6">Primary Resident:</Typography>
			<Spacer height={3} />
			<Typography>
				Resident will be notified by latchel after work orders are created.
			</Typography>
			<Spacer height={2} />
			{tenants.map((t) => (
				<Paper
					sx={{ padding: 2, marginBottom: 1, cursor: 'pointer' }}
					onClick={() => setTenant(t)}>
					<Row>
						<FontAwesomeIcon
							icon={t === tenant ? solid('check-circle') : regular('circle')}
							size="lg"
							color={t === tenant ? colors.primary : colors.gray}
						/>
						<Spacer width={3} />
						<Typography>{t.name}</Typography>
					</Row>
				</Paper>
			))}
		</>
	);
};

const Vacant = ({ value, setValue }: VacantProps) => {
	return (
		<>
			<Row>
				<Typography variant="h6">Property Access Instructions:</Typography>
				<Spacer width={2} />
				<Typography sx={{ marginTop: 0.4 }} color={colors.gray}>
					(Required)
				</Typography>
			</Row>

			<Spacer height={3} />

			<Typography>
				Confirm access instructions so maintenance can access the property.
			</Typography>

			<Spacer height={4} />

			<TextField
				id="textinput-access_instructions"
				multiline
				label="Access Instructions"
				value={value}
				onChange={(e) => setValue(e.target.value)}
				sx={{ '.MuiInputBase-input': { fontWeight: 400 } }}
			/>
		</>
	);
};

const Residents = ({
	property,
	tenants,
	tenant,
	setTenant,
	loadingTenants,
	value,
	setValue,
}: Props) => {
	const occupied = tenants.length > 0;

	if (!property) {
		return null;
	}

	if (loadingTenants) {
		return (
			<Column>
				<Skeleton variant="text" width={250} height={44} />
				<Skeleton variant="text" width={400} height={28} />
				<Spacer height={2} />
				<Skeleton
					variant="rectangular"
					width={504}
					height={83}
					sx={{ borderRadius: 1 }}
				/>
			</Column>
		);
	}

	return (
		<Column>
			{occupied && (
				<Occupied tenants={tenants} tenant={tenant} setTenant={setTenant} />
			)}
			{!occupied && <Vacant value={value} setValue={setValue} />}
		</Column>
	);
};

export default Residents;
