import { Screens } from 'components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/reducers/rootReducer';

import { PropertiesActions, SubscriptionActions } from 'store/actions';
import { Dispatch } from 'types';
import ActionButton from './action-button';
import Skeleton from './skeleton';
import Subscribed from './subscribed';
import Unsubscribed from './unsubscribed';

const Billing = () => {
	const dispatch = useDispatch<Dispatch>();

	const profile = useTypedSelector((state) => state.activeProfile);
	const subscription = useTypedSelector((state) => state.subscription);
	const units = useTypedSelector((state) => state.properties.units);

	const [loading, setLoading] = useState(!subscription || !units.total);

	useEffect(() => {
		if (!profile) {
			return;
		}

		if (subscription && units.total) {
			setLoading(false);
			return;
		}

		setLoading(true);

		const promises: Promise<unknown>[] = [
			dispatch(SubscriptionActions.getSubscription()),
		];

		/**
		 * If units total is 0 it means we either don't have units yet or the account
		 * actually has 0 units, just in case we will fetch units to get the total.
		 */
		if (units.total === 0) {
			promises.push(dispatch(PropertiesActions.units.getAll()));
		}

		Promise.allSettled(promises).finally(() => setLoading(false));
	}, [profile]);

	if (!profile || loading) {
		return <Skeleton />;
	}

	return (
		<Screens.Container>
			<Screens.Header
				title="Billing"
				showBreadCrumbs
				subtitle="View and manage subscription details and billing information. Download RentCheck invoices."
				actions={[<ActionButton />]}
			/>
			<Subscribed />
			<Unsubscribed />
		</Screens.Container>
	);
};

export default Billing;
