import { Skeleton } from '@mui/material';
import { Column, Row } from 'components';

const FiltersSkeleton = () => {
	const chipStyle = {
		borderRadius: 4,
		marginRight: 1,
		marginTop: 0.5,
		marginBottom: 0.5,
	};

	return (
		<Column>
			<Row style={{ flexWrap: 'wrap' }}>
				<Skeleton variant="rectangular" width={80} height={30} sx={chipStyle} />
				<Skeleton variant="rectangular" width={60} height={30} sx={chipStyle} />
			</Row>
		</Column>
	);
};

export default FiltersSkeleton;
