import { ImportDigest } from '@rentcheck/types';
import { useIsMobileBreakpoint } from 'utils/hooks';

import Lg from './lg';
import Sm from './sm';

interface Props {
	object: ImportDigest;
}

export default ({ object }: Props) => {
	const isMobile = useIsMobileBreakpoint('md');

	if (isMobile) {
		return <Sm object={object} />;
	}

	return <Lg object={object} />;
};
