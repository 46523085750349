import { Dialog, useMediaQuery, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';

import { integrations } from 'assets';
import { DialogTitle } from 'components';
import { useCreateWorkOrderModalData } from 'hooks/modals';
import { ModalFlowActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';

import { formattedAddress, isEmbeddedInMobileApp } from 'utils/helpers';
import Content from './content';
import { handleNativeAppClose } from './common/native-app';

interface Props {
	open: boolean;
}

const CreateWorkOrder = ({ open }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	const profile = useTypedSelector((state) => state.activeProfile);

	const modalData = useCreateWorkOrderModalData('AppFolio');
	const { inspection, flags } = modalData?.metadata ?? {};

	if (!profile || !inspection || !flags) return null;

	const handleClose = () => {
		handleNativeAppClose();
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			fullScreen={isMobile || isEmbeddedInMobileApp()}>
			<DialogTitle
				title="Create AppFolio Work Order"
				bottomSubtitle={formattedAddress(inspection.property)}
				onClose={handleClose}
				image={integrations.appFolio}
			/>
			<Content />
		</Dialog>
	);
};

export default CreateWorkOrder;
