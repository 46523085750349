import { Breadcrumbs, Link, LinkProps, Typography } from '@mui/material';
import _ from 'lodash';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { colors } from 'theme';
import themeTypography from 'theme/typography';

const breadcrumbNameMap: { [key: string]: string } = {
	'/account': 'Account',
	'/account/profile': 'Profile',
	'/account/profile/edit': 'Edit Profile',
	'/account/profile/updatepassword': 'Update Password',
	'/account/billing': 'Billing',
	'/account/imports': 'Imports',
	'/account/inspection-report-settings': 'Inspection Report Settings',
	'/account/inspection-messaging': 'Inspection Messaging',
	'/account/maintenance-settings': 'Maintenance Settings',
	'/account/property-configuration': 'Property Configuration',
	'/account/inspection-templates': 'Inspection Templates',

	'/maintenance': 'Maintenance',
	'/maintenance/reports': 'Reports',
	'/maintenance/work-orders': 'Work Orders',
};

interface LinkRouterProps extends LinkProps {
	to: string;
	replace?: boolean;
}

function LinkRouter(props: LinkRouterProps) {
	return (
		<Link
			{...props}
			component={RouterLink as any}
			sx={{
				...themeTypography.MuiTypography.styleOverrides.body2,
				color: colors.secondary,
			}}
		/>
	);
}

const LastBreadcrumb = (props: {
	breadcrumbReplacements?: Record<string, string>;
}) => {
	const location = useLocation();
	const pathnames = location.pathname.split('/').filter((x) => x);
	const last = _.last(pathnames);

	if (!last) {
		return null;
	}

	const textValue =
		breadcrumbNameMap[location.pathname] ??
		props.breadcrumbReplacements?.[last] ??
		last;

	return (
		<Typography
			color={colors.secondary}
			variant="body2"
			noWrap
			sx={{ maxWidth: 200 }}>
			{textValue}
		</Typography>
	);
};

export default (props: { breadcrumbReplacements?: Record<string, string> }) => {
	const location = useLocation();
	const pathnames = location.pathname.split('/').filter((x) => x);

	return (
		<Breadcrumbs separator=">">
			{pathnames.map((value, index) => {
				const last = index === pathnames.length - 1;
				const to = `/${pathnames.slice(0, index + 1).join('/')}`;

				return last ? (
					<LastBreadcrumb
						breadcrumbReplacements={props.breadcrumbReplacements}
					/>
				) : (
					<LinkRouter underline="hover" color="inherit" to={to} key={to}>
						{breadcrumbNameMap[to]}
					</LinkRouter>
				);
			})}
		</Breadcrumbs>
	);
};
