import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
} from '@mui/material';
import { Screens } from 'components';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import {
	MaintenanceActions,
	ModalFlowActions,
	SnackbarActions,
} from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import styled from 'styled-components';
import { colors } from 'theme';
import { Dispatch } from 'types';
import {
	featureDisplayName,
	formatCurrency,
	formatDate,
	formattedAddress,
} from 'utils/helpers';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { MaintenanceFlag } from '@rentcheck/types';
import IconButton from 'components/icon-button';
import _ from 'lodash';
import Skeleton from './skeleton';
import TableHeader from './table-header';

import AppFolioIntegration from './integrations/appfolio';
import LatchelIntegration from './integrations/latchel';
import RentManagerIntegration from './integrations/rentmanager';
import YardiIntegration from './integrations/yardi';
import StatusChip from './status';
import SyncStatus from './sync-status';

export default () => {
	const dispatch = useDispatch<Dispatch>();

	const match = useRouteMatch<{ id: string }>();
	const workOrder = useTypedSelector((state) => {
		return state.maintenance.workOrder?.id === match.params.id
			? state.maintenance.workOrder
			: undefined;
	});

	useEffect(() => {
		if (workOrder) {
			return;
		}

		dispatch(MaintenanceActions.workOrders.getById(match.params.id)).catch(
			(e) => dispatch(SnackbarActions.showError(e.message))
		);
	}, []);

	const handleViewFlag = (flag: MaintenanceFlag) => {
		if (!workOrder) {
			return null;
		}

		dispatch(
			ModalFlowActions.showFlagImages({
				flags: [flag],
				initialFlag: flag,
				property: workOrder.property,
			})
		);
	};

	const getMediaCount = (flag: MaintenanceFlag) => {
		const imagesCount = flag.image_urls?.length ?? 0;
		const videosCount = flag.video_urls?.length ?? 0;

		return imagesCount + videosCount;
	};

	if (!workOrder) {
		return (
			<Screens.Container>
				<Skeleton />
			</Screens.Container>
		);
	}

	return (
		<Screens.Container>
			<Screens.Header
				showBreadCrumbs
				breadcrumbReplacements={{ [workOrder.id]: workOrder.name }}
				title={workOrder.name}
				actions={[<StatusChip workOrder={workOrder} />]}
				subtitle={
					<>
						<Typography variant="inherit">
							Created by {workOrder.created_by_name} on{' '}
							{formatDate(workOrder.created_date)}
						</Typography>
						<Typography variant="inherit" mt={1}>
							<StyledLink to={`/properties/${workOrder.property.id}`}>
								{formattedAddress(workOrder.property)}
							</StyledLink>
						</Typography>
						<Typography variant="inherit" mt={1}>
							Created from{' '}
							<StyledLink to={`/inspections/${workOrder.inspection.id}`}>
								{workOrder.inspection.template.name}
							</StyledLink>{' '}
							inspection
							{workOrder.inspection.completed_date &&
								` completed on ${formatDate(
									workOrder.inspection.completed_date
								)}`}
						</Typography>
						<SyncStatus workOrder={workOrder} />
					</>
				}
			/>

			<LatchelIntegration workOrder={workOrder} />
			<YardiIntegration workOrder={workOrder} />
			<AppFolioIntegration workOrder={workOrder} />
			<RentManagerIntegration workOrder={workOrder} />

			<TableContainer component={Paper} elevation={0} sx={{ mt: 4 }}>
				<Table>
					<TableHeader />
					<TableBody
						sx={{
							td: {
								border: 'none',
							},
							'tr:nth-child(2n + 1)': {
								backgroundColor: '#47474708',
							},
						}}>
						{workOrder.maintenance_flags.map((flag) => (
							<TableRow key={flag.id}>
								<TableCell>{flag.skill}</TableCell>
								<TableCell>{featureDisplayName(flag)}</TableCell>
								<TableCell>{flag.note}</TableCell>
								<TableCell>{_.capitalize(flag.responsibility)}</TableCell>
								<TableCell>{formatCurrency(flag.cost)}</TableCell>
								<TableCell align="center">
									{getMediaCount(flag) || '-'}
								</TableCell>
								<TableCell align="center">
									<IconButton
										icon={regular('image')}
										color="primary"
										onClick={() => handleViewFlag(flag)}
										disabled={!getMediaCount(flag)}
										tooltip={getMediaCount(flag) ? 'View Images' : 'No Images'}
									/>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Screens.Container>
	);
};

const StyledLink = styled(Link)`
	color: ${colors.secondary};
	text-decoration: underline;
`;
