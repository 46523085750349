import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';

import { useTypedSelector } from 'store/reducers/rootReducer';
import typography from 'theme/typography';

interface Props {
	title: string;
	icon: IconProp;
	style: 'light' | 'primary';
	endIcon?: IconProp;
	endIconOnClick?: () => void;
}

const styles = (style: 'light' | 'primary') => {
	if (style === 'light') {
		return {
			iconColor: '#7D8CFF',
			border: '1px solid #7D8CFF',
			backgroundColor: '#FFF',
			hoverBackgroundColor: '#7D8CFF1F',
			textColor: '#061ACA',
		};
	}

	return {
		iconColor: '#061ACA',
		border: '1px solid #2D3CE61F',
		backgroundColor: '#2D3CE61F',
		hoverBackgroundColor: '#2D3CE62F',
		textColor: '#000',
	};
};

const FilterChip = ({ title, icon, style, endIcon, endIconOnClick }: Props) => {
	const data = useTypedSelector((state) => state.dashboard.data);

	const {
		iconColor,
		border,
		backgroundColor,
		hoverBackgroundColor,
		textColor,
	} = styles(style);

	const disabled = !data || data.stats.overall_total_inspections < 10;
	const disabledColor = '#8F9FB2';

	return (
		<Button
			disabled={disabled}
			startIcon={<FontAwesomeIcon icon={icon} size="sm" />}
			endIcon={
				endIcon ? (
					<FontAwesomeIcon icon={endIcon} onClick={endIconOnClick} size="xs" />
				) : null
			}
			sx={{
				...(typography.MuiTypography.styleOverrides.body1 ?? {}),
				height: 32,
				boxShadow: 0,
				borderRadius: 50,
				marginRight: 2,
				color: textColor,
				backgroundColor,
				border,
				opacity: disabled ? 0.4 : 1,
				cursor: 'pointer',
				'.MuiButton-startIcon': {
					height: 16,
					width: 16,
					color: disabled ? disabledColor : iconColor,
				},
				'.MuiButton-endIcon': {
					height: 16,
					width: 16,
					color: disabled ? disabledColor : '#000',
					fontSize: 12,
				},
				':hover': {
					backgroundColor: hoverBackgroundColor,
					boxShadow: 0,
				},

				':disabled': {
					borderColor: disabledColor,
				},
			}}>
			{title}
		</Button>
	);
};

export default FilterChip;
