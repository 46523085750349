import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';

import { Feature } from '@rentcheck/types';

import { Column, Row, SpacedRow } from 'components';
import { useFeatureDetailsModalData } from 'hooks/modals';
import { colors } from 'theme';
import {
	featureDisplayName,
	formattedAddress,
	numberAndPluralizeWord,
} from 'utils/helpers';

import IconButton from 'components/icon-button';

interface Props {
	feature: Feature;
	onClose: () => void;
}

const Header = ({ feature, onClose }: Props) => {
	const modalData = useFeatureDetailsModalData();

	const inspection = modalData?.metadata?.inspection;

	if (!inspection) {
		return null;
	}

	return (
		<SpacedRow>
			<Column>
				<Typography variant="h4">{featureDisplayName(feature)}</Typography>
				<Row>
					<Typography>
						{formattedAddress(inspection.property)} -{' '}
						{inspection.inspection_template.name}
					</Typography>
					{inspection.fast_track && (
						<FontAwesomeIcon
							icon={solid('bolt')}
							color={colors.primary}
							style={{ fontSize: 14, marginLeft: 8 }}
						/>
					)}
				</Row>
			</Column>
			<Column style={{ alignItems: 'flex-end' }}>
				<Row style={{ marginBottom: 4 }}>
					<IconButton icon={regular('arrow-left')} disabled />
					<IconButton icon={regular('arrow-right')} disabled />
					<IconButton icon={regular('flag')} disabled />
					<IconButton icon={regular('times')} onClick={onClose} />
				</Row>
				<Typography color="#00000099" sx={{ whiteSpace: 'nowrap' }}>
					{numberAndPluralizeWord(feature.images.length, 'image')}
				</Typography>
			</Column>
		</SpacedRow>
	);
};

export default Header;
