import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip, Tooltip } from '@mui/material';
import * as theme from 'theme';

interface Props {
	onClearFilters: () => void;
	appliedFiltersCount: number;
}

export default ({ onClearFilters, appliedFiltersCount }: Props) => {
	if (!appliedFiltersCount) {
		return null;
	}

	return (
		<Tooltip title="Clear all filters">
			<Chip
				label=""
				variant="outlined"
				icon={<FontAwesomeIcon icon={solid('times')} />}
				onClick={onClearFilters}
				sx={{
					'.MuiChip-label': {
						paddingRight: '6px',
					},
					'.MuiChip-icon': {
						color: theme.colors.primary,
					},
				}}
			/>
		</Tooltip>
	);
};
