import { LoadingButton } from '@mui/lab';
import { Button, Typography } from '@mui/material';
import { Column, FileInput, SpacedRow, Spacer } from 'components';
import { useLatchelIntegrations } from 'hooks/latchel-integrations';
import { useState } from 'react';
import styled from 'styled-components';
import { colors, spacingPx } from 'theme';

interface Props {
	internalLabel: string;
	setLogo: (file?: File) => void;
	nextStep: () => void;
	back: () => void;
	loading: boolean;
	error: string;
}

const TeamLogo = ({
	internalLabel,
	nextStep,
	back,
	setLogo,
	error,
	loading,
}: Props) => {
	const { latchelIntegrations } = useLatchelIntegrations();

	const [file, setFile] = useState<File | undefined>(undefined);

	const hasLatchelIntegrations = latchelIntegrations.length;

	return (
		<Column>
			<Typography variant="h5">Create Team</Typography>
			<Spacer height={1} />
			<Typography color="#4d4d4d">{internalLabel}</Typography>
			<Spacer height={6} />
			<Typography color="#4d4d4d">
				Add your company logo to your inspection reports by adding it here. Your
				logo will appear at the top right of inspection reports in place of the
				RentCheck logo. It will also be added to RentCheck inspection invite and
				reminder emails. For best results attach your logo as a .png or .jpg
				with a 3:1 aspect ratio (i.e. 360 px wide and 120 px tall).
			</Typography>
			<Spacer height={6} />
			<Typography variant="h5">Attach logo (Optional)</Typography>
			<Spacer height={3} />
			<FileInput
				setFile={(f) => {
					setFile(f);
					setLogo(f);
				}}
				file={file}
				accept="image/*"
			/>
			<Spacer height={10} />
			<Divider />
			<Spacer height={5} />
			{!!error && <Typography variant="error">{error}</Typography>}
			<Spacer height={5} />
			<SpacedRow>
				<ButtonContainer left>
					<Button
						variant="outlined"
						color="secondary"
						onClick={() => {
							setLogo(undefined);
							back();
						}}>
						Back
					</Button>
				</ButtonContainer>
				<ButtonContainer right>
					<LoadingButton
						loading={loading}
						onClick={() => {
							setLogo(file);
							nextStep();
						}}>
						{hasLatchelIntegrations ? 'Next' : 'Complete Team'}
					</LoadingButton>
				</ButtonContainer>
			</SpacedRow>
		</Column>
	);
};

const ButtonContainer = styled.div<{ right?: boolean; left?: boolean }>`
	display: flex;
	flex-direction: column;
	padding-left: ${({ right }) => (right ? spacingPx(2) : 0)};
	padding-right: ${({ left }) => (left ? spacingPx(2) : 0)};
`;

const Divider = styled.div`
	height: 1px;
	width: 100%;
	background-color: ${colors.contentBorder};
`;

export default TeamLogo;
