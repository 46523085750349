import { LatchelApi } from '@rentcheck/api-frontend';
import { ApiTeamDetails } from '@rentcheck/types';
import Modal from 'components/Common/Modal';
import _ from 'lodash';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TeamActions } from 'store/actions';
import { Dispatch } from 'types';

import { useLatchelIntegrations } from 'hooks/latchel-integrations';
import { TeamsActions } from 'store/actions';
import Latchel from './latchel';
import TeamLogo from './logo';
import TeamName from './name';
import Success from './success';

interface Props {
	setModalVisible: (visible: boolean) => void;
	creationCallback?: (team: ApiTeamDetails) => void;
}

export type CreateTeamStep = 'Team Name' | 'Logo' | 'Latchel' | 'Success';

const CreateTeamModal = ({ setModalVisible, creationCallback }: Props) => {
	const dispatch: Dispatch = useDispatch();
	const history = useHistory();

	const { latchelIntegrations } = useLatchelIntegrations();
	const hasLatchelIntegrations = latchelIntegrations.length > 0;

	const [stepHistory, setStepHistory] = useState<CreateTeamStep[]>([
		'Team Name',
	]);
	const [createdTeam, setCreatedTeam] = useState<ApiTeamDetails>();

	const [name, setName] = useState('');
	const [internalLabel, setInternalLabel] = useState('');
	const [logo, setLogo] = useState<File | undefined>(undefined);
	const [latchelApiKey, setLatchelApiKey] = useState<string | null>(null);

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	const pushStep = (newStep: CreateTeamStep) => {
		setStepHistory([...stepHistory, newStep]);
	};

	const backStep = () => {
		setError('');

		if (stepHistory.length <= 1) {
			return;
		}

		setStepHistory(stepHistory.slice(0, stepHistory.length - 1));
	};

	const handleCreateTeam = async () => {
		setError('');
		setLoading(true);
		dispatch(TeamActions.create(name, internalLabel, logo))
			.then(async (organization) => {
				if (!latchelApiKey) return organization;

				await LatchelApi.connect(latchelApiKey, [organization.id]);
				await dispatch(TeamsActions.getAll());

				return organization;
			})
			.then((organization) => {
				setCreatedTeam(organization);
				pushStep('Success');
			})
			.catch((e) => setError(e.message))
			.finally(() => setLoading(false));
	};

	const renderContent = () => {
		const currentStep = _.last(stepHistory);
		switch (currentStep) {
			case 'Team Name':
				return (
					<TeamName
						name={name}
						internalLabel={internalLabel}
						setName={setName}
						setInternalLabel={setInternalLabel}
						cancel={() => setModalVisible(false)}
						nextStep={() => pushStep('Logo')}
					/>
				);
			case 'Logo':
				return (
					<TeamLogo
						internalLabel={internalLabel}
						setLogo={setLogo}
						nextStep={
							hasLatchelIntegrations
								? () => pushStep('Latchel')
								: handleCreateTeam
						}
						back={backStep}
						error={error}
						loading={loading}
					/>
				);
			case 'Latchel':
				return (
					<Latchel
						internalLabel={internalLabel}
						setApiKey={setLatchelApiKey}
						nextStep={handleCreateTeam}
						back={backStep}
						error={error}
						loading={loading}
					/>
				);
			case 'Success':
				return (
					<Success
						team={createdTeam}
						close={() => {
							if (!createdTeam) return;
							setModalVisible(false);
							creationCallback
								? creationCallback(createdTeam)
								: history.push(`/account/teams/${createdTeam?.id}`);
						}}
					/>
				);
		}
	};

	return <Modal>{renderContent()}</Modal>;
};

export default CreateTeamModal;
