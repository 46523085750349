import { TextField, Typography } from '@mui/material';
import { ApiInspection } from '@rentcheck/types';
import { Spacer } from 'components';

import Section from './section';

interface Props {
	inspections: ApiInspection[];
	message: string;
	setMessage: (value: string) => void;
}

export default ({ message, setMessage }: Props) => {
	return (
		<Section title="Message">
			<Typography>
				Let the recipients know why you are sending a reminder
			</Typography>
			<Spacer height={2} />
			<TextField
				variant="filled"
				label="Add a custom message"
				helperText="* Message will only be included in email reminders."
				multiline
				value={message}
				onChange={(e) => setMessage(e.target.value)}
			/>
		</Section>
	);
};
