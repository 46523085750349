import { Skeleton } from '@mui/material';
import { Row } from 'components';

const FiltersSkeleton = () => {
	const chipStyle = {
		borderRadius: 4,
		marginRight: 1,
		marginTop: 0.5,
		marginBottom: 0.5,
	};

	return (
		<Row style={{ flexWrap: 'wrap' }}>
			<Skeleton variant="rectangular" width={98} height={30} sx={chipStyle} />
			<Skeleton variant="rectangular" width={141} height={30} sx={chipStyle} />
			<Skeleton variant="rectangular" width={110} height={30} sx={chipStyle} />
			<Skeleton variant="rectangular" width={93} height={30} sx={chipStyle} />
			<Skeleton variant="rectangular" width={133} height={30} sx={chipStyle} />
			<Skeleton variant="rectangular" width={94} height={30} sx={chipStyle} />
		</Row>
	);
};

export default FiltersSkeleton;
