import { APIUnit } from '@rentcheck/types';
import _ from 'lodash';

export interface State {
	data: APIUnit[];
	total: number;
	loading: boolean;
}

const initialState: State = {
	data: [],
	total: 0,
	loading: false,
};

type Action = {
	type: string;
	value?: number;
	ids?: string[];
	loading?: boolean;
	property?: APIUnit;
	properties?: APIUnit[];
};

const upsertUnitInArray = (unit: APIUnit, array: APIUnit[]) => {
	const index = array.findIndex((i) => i.id === unit.id);

	if (index < 0) {
		return [unit, ...array];
	}

	const response = [...array];
	response[index] = unit;
	return response;
};

const reducer = (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case 'SIGNOUT_SUCCESS':
		case 'IMPERSONATE':
			return initialState;

		case 'FETCHED_UNIT':
			if (!action.property) return state;
			return {
				...state,
				data: upsertUnitInArray(action.property, state.data),
			};

		case 'FETCHED_INITIAL_UNITS':
			if (!action.properties) return state;
			return {
				...state,
				data: action.properties,
			};

		case 'FETCHED_UNITS_PAGE':
			if (!action.properties) return state;
			return {
				...state,
				data: _.uniqBy([...state.data, ...action.properties], (e) => e.id),
			};

		case 'SET_TOTAL_UNITS':
			if (!action.value) return state;
			return {
				...state,
				total: action.value,
			};

		case 'DELETE_UNITS':
			if (!action.ids) return state;
			return {
				...state,
				data: state.data.filter((u) => !action.ids?.includes(u.id)),
			};

		case 'SET_LOADING_UNITS':
			return {
				...state,
				loading: !!action.loading,
			};

		default:
			return state;
	}
};

export default reducer;
