import { Chip, Typography } from '@mui/material';
import { InspectionEventEmailSent } from '@rentcheck/types';
import { SpacedRow } from 'components/layout/Row';

interface Props {
	value?: InspectionEventEmailSent[];
}

export default ({ value }: Props) => {
	if (!value) {
		return null;
	}

	if (!value.length) {
		return null;
	}

	return (
		<>
			<Typography variant="body2" color="#4D4D4D" mt={1}>
				Sent To:
			</Typography>
			{value.map((emailSent) => (
				<SpacedRow style={{ flex: 1 }}>
					<Typography variant="body2" color="#4D4D4D" mt={1}>
						{emailSent.email}
						{!!emailSent.reference_code && ` (${emailSent.reference_code})`}
					</Typography>
					<Status event={emailSent} />
				</SpacedRow>
			))}
		</>
	);
};

const Status = ({ event }: any) => {
	let label = event.event ?? 'Sent';

	switch (event.event) {
		case 'open':
			label = `Opened (${event.open_counter}x)`;
			break;
		case 'click':
			label = `Clicked (${event.click_counter}x)`;
			break;
		case 'created':
			label = 'Sent';
			break;
		case 'bounce':
			label = 'Bounced';
			break;
		case 'delivered':
			label = 'Delivered';
			break;
		case 'processed':
			label = 'Delivered';
			break;
		case 'unsubscribed':
			label = 'Unsubscribed';
			break;
	}

	return <Chip label={label} sx={{ marginBottom: 0.5 }} />;
};
