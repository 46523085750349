import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { useTypedSelector } from 'store/reducers/rootReducer';

import CircularProgress from './components/circular-progress';
import Container from './components/container';
import Tooltip from './components/tooltip';

const CreatedInspections = () => {
	const history = useHistory();

	const data = useTypedSelector((state) => state.dashboard.data);

	if (!data || data.stats.overall_total_inspections > 10) {
		return null;
	}

	const handleCta = () => {
		history.push('/inspections');
	};

	const progressValue = data.stats.overall_total_inspections / 10;

	return (
		<Container
			title="Inspections Created"
			subtitle={`${data.stats.overall_total_inspections}/10 Inspections`}>
			<CircularProgress
				progress={progressValue}
				mode="ccw"
				textValue={`${data.stats.overall_total_inspections}`}
			/>
			<Tooltip
				title="Dashboard data"
				body="Create 10 inspections to unlock your data. The more inspections you create the richer your data will be. "
				cta="+ Create inspection"
				handleCta={handleCta}>
				<FontAwesomeIcon
					id="tooltip-inspections-created"
					icon={solid('info-circle')}
					color="#8F9FB2"
					size="sm"
				/>
			</Tooltip>
		</Container>
	);
};

export default CreatedInspections;
