import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import { Utils } from '@rentcheck/biz';
import { colors } from 'theme';

interface Props {
	image: string;
}

export default ({ image }: Props) => {
	if (!Utils.Media.isFromCameraRoll(image)) {
		return null;
	}

	return (
		<div
			style={{
				height: 32,
				width: 32,
				borderRadius: 32,
				marginLeft: 8,
				backgroundColor: '#FFFFFFBD',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}>
			<Tooltip title="Media uploaded from device">
				<FontAwesomeIcon
					icon={solid('cloud-arrow-up')}
					color={colors.darkSteel}
				/>
			</Tooltip>
		</div>
	);
};
