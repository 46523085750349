import { LatchelApi } from '@rentcheck/api-frontend';
import { ApiLatchelIntegration } from '@rentcheck/types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { SnackbarActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';

export const useLatchelIntegrations = () => {
	const dispatch: Dispatch = useDispatch();

	const teams = useTypedSelector((state) => state.teams);

	const [integrations, setIntegrations] = useState<ApiLatchelIntegration[]>([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (!teams.length) {
			return;
		}

		setLoading(true);

		LatchelApi.integrations
			.getAll()
			.then(setIntegrations)
			.catch((e) => dispatch(SnackbarActions.showError(e)))
			.finally(() => setLoading(false));
	}, [teams]);

	return {
		loading,
		latchelIntegrations: integrations ?? [],
	};
};
