import { BillingModalOption } from '@rentcheck/types';
import { Column } from 'components';
import Features from './features';
import TopCard from './top-card';

interface Props {
	billingOption: BillingModalOption;
}

export default ({ billingOption }: Props) => {
	return (
		<Column style={{ flex: 1 }}>
			<TopCard plan={billingOption.plan} />
			<Features features={billingOption.features} />
		</Column>
	);
};
