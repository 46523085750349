import { AppFolioApi } from '@rentcheck/api-frontend';
import {
	ApiSubscription,
	AppFolioIntegration,
	BaseIntegration,
	BaseSyncDetails,
	FirebaseUpdatePayload,
} from '@rentcheck/types';
import { IntegrationsApi } from 'api';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Analytics } from 'utils';
import { hasAppfolioWorkOrderAddons } from 'utils/integrations';

import DataSyncModal, {
	IngestionFilterOptionType,
	MappingSectionType,
} from '../../common/data-sync-modal';
import InstallButton from '../../common/install-button';
import InstalledContent from '../../common/installed-content';

import InstallModal from './install-modal';

const commonProps = {
	vendor: 'AppFolio',
	parentUrl: '/account/integrations/appfolio',
	learnMoreLink:
		'https://help.getrentcheck.com/en/articles/6729976-appfolio-integration',
};

const mappingSections: MappingSectionType[] = [
	{
		type: 'Units',
		title: 'Unit Sync',
		body: 'The Unit Sync adds new units from AppFolio and keeps them up to date in RentCheck. On every sync, when a matching unit is found the unit will be updated with new information. Otherwise a new unit will be created in RentCheck.',
		mode: 'read',
		mappings: [
			['Address1, Address2 Name', 'Address'],
			['City', 'City'],
			['Zip', 'Zip Code'],
			['Bedrooms', 'Bedrooms'],
			['Bathrooms', 'Full Bathrooms & Half Bathrooms'],
			['PropertyType', 'Type'],
		],
	},
	{
		type: 'Buildings',
		title: 'Building Sync',
		body: 'The Building Sync adds new buildings from AppFolio and keeps them up to date in RentCheck. On every sync, when a matching building is found the building will be updated with new information. Otherwise a new building will be created in RentCheck.',
		mode: 'read',
		mappings: [
			['Name', 'Building Name'],
			['Address1, Address2', 'Address'],
			['City', 'City'],
			['Zip', 'Zip Code'],
		],
	},
	{
		type: 'Residents',
		title: 'Resident Sync',
		body: 'The Resident Sync imports your tenant directory from AppFolio and includes current and future residents. When the Resident Sync is enabled contact details including email and phone are kept in sync along with resident status and key lease dates such as lease start, least end, move-in, renewal, and move-out dates.',
		mode: 'read',
		mappings: [
			['FirstName', 'First Name'],
			['LastName', 'Last Name'],
			['Email', 'Email'],
			['PhoneNumber', 'Phone'],
			['LeaseStartDate', 'Lease Start Date'],
			['LeaseEndDate', 'Lease End Date'],
			['MoveOutOn', 'Move-out Date'],
		],
	},
	{
		type: 'Inspection Reports',
		title: 'Inspection Sync',
		body: 'Send completed unit and building inspection reports back to AppFolio upon completion and approval. Unit inspection PDF reports will be uploaded as an Occupancy attachment and also attached to the unit itself.',
		mode: 'write',
		frequency: 12,
		mappings: [
			['Inspection PDF report', 'Attached to property and current tenants'],
		],
	},
	{
		type: 'Work Orders',
		title: 'Work Order Sync',
		body: 'Create work orders in AppFolio as you are flagging items for maintenance in RentCheck.',
		mode: 'write',
		mappings: [
			['Notes', 'JobDescription'],
			['Vendor Trade', 'VendorTrade'],
			['Priority', 'Priority'],
			['Ok to enter', 'PermissionToEnter'],
		],
		requiredAddons: {
			check: hasAppfolioWorkOrderAddons,
			name: 'AppFolio Two-Way Data Sync + Work Orders',
		},
	},
];

const Active = () => {
	const dispatch = useDispatch();

	const subscription = useTypedSelector((state) => state.subscription);

	const [isInstalled, setIsInstalled] = useState(false);

	useEffect(() => {
		if (!subscription) {
			return;
		}

		IntegrationsApi.getAppFolioIntegrationForSubscriptionId(subscription.id)
			.then((data) => setIsInstalled(!!data))
			.catch(() => dispatch(SnackbarActions.showError()));
	}, [subscription]);

	const getIntegration = (s: ApiSubscription) => {
		return IntegrationsApi.getAppFolioIntegrationForSubscriptionId(s.id);
	};

	const getExternalTeams = () => {
		return AppFolioApi.getPropertyGroups().then((result) =>
			result.filter((r) => r.type === 'Portfolio')
		);
	};

	const getIngestionFilterOptions = () => {
		return AppFolioApi.getPropertyGroups();
	};

	const getPropertyTypeFilterOptions = () => {
		return [
			'Single-Family',
			'Multi-Family',
			'Student-Housing',
			'Commercial',
			'Corporate',
			'Mixed-Use',
			'HOA',
			'Vacation',
			'Manufactured',
		];
	};

	const handleSaveIntegration = (
		integration: BaseIntegration,
		basePayload: FirebaseUpdatePayload<BaseIntegration>,
		firstTimeSetup: boolean,
		propertyTypes?: string[],
		ingestionFilters?: IngestionFilterOptionType[]
	) => {
		const payload: FirebaseUpdatePayload<AppFolioIntegration> = {
			...basePayload,
			property_groups: ingestionFilters,
			property_types: propertyTypes,
		};

		return IntegrationsApi.updateAppFolioIntegration(
			integration.id,
			payload
		).then(async () => {
			await AppFolioApi.manualSync();

			if (firstTimeSetup && subscription) {
				const yesNoFromSyncDetails = (syncDetails?: BaseSyncDetails) => {
					return syncDetails?.active ? 'yes' : 'no';
				};

				const syncDetails = basePayload.sync_details as
					| BaseIntegration['sync_details']
					| undefined;

				Analytics.trackEvent('enabled_appfolio_sync', {
					subscription_id: subscription.id,
					building_sync_enabled: yesNoFromSyncDetails(syncDetails?.buildings),
					unit_sync_enabled: yesNoFromSyncDetails(syncDetails?.units),
					resident_sync_enabled: yesNoFromSyncDetails(syncDetails?.residents),
					inspection_sync_enabled: yesNoFromSyncDetails(
						syncDetails?.inspections
					),
					work_order_sync_enabled: yesNoFromSyncDetails(
						syncDetails?.work_orders
					),
				});
			}
		});
	};

	return (
		<>
			<InstallButton
				isInstalled={isInstalled}
				InstallModal={InstallModal}
				{...commonProps}
			/>

			{isInstalled && <InstalledContent {...commonProps} />}

			<DataSyncModal
				{...commonProps}
				mappingSections={mappingSections}
				getIntegration={getIntegration}
				handleSaveIntegration={handleSaveIntegration}
				teamAssignment={{ extTeamName: 'Portfolio', getExternalTeams }}
				ingestionFilter={{
					name: 'Property Group',
					selector: 'property_groups',
					getFilterOptions: getIngestionFilterOptions,
				}}
				propertyTypeFilter={{
					name: 'Property Type',
					selector: 'property_types',
					getFilterOptions: getPropertyTypeFilterOptions,
				}}
			/>
		</>
	);
};

export default Active;
