import { MenuItem } from '@mui/material';
import { ApiTeamDetails, TeamUserInfo } from '@rentcheck/types';

import { AssignedTeammates } from 'screens/modal-flows-controller/create-inspection/common/assigned-teammates';

interface Props {
	teammates: TeamUserInfo[];
	team: ApiTeamDetails;
	assignedTeammates: AssignedTeammates;
	setAssignedTeammates: (teammates: AssignedTeammates) => void;
}

export default ({
	teammates,
	team,
	assignedTeammates,
	setAssignedTeammates,
}: Props) => {
	const assignedTeammatesForTeam = assignedTeammates[team.id] ?? [];
	const isSelected = assignedTeammatesForTeam.length === teammates.length;

	const handleSelected = () => {
		if (isSelected) {
			return setAssignedTeammates({
				...assignedTeammates,
				[team.id]: [],
			});
		}

		setAssignedTeammates({
			...assignedTeammates,
			[team.id]: [...teammates],
		});
	};

	return (
		<MenuItem onClick={handleSelected}>
			{isSelected ? 'Deselect All' : 'Select All'}
		</MenuItem>
	);
};
