import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, IconButton, TableCell, TableRow } from '@mui/material';
import { APICommunity } from '@rentcheck/types';
import { useHistory } from 'react-router-dom';

import LastCompletedInspection from '../../common/last-completed-inspection';
import ActionsMenu from './actions-menu';

import { Utils } from '@rentcheck/biz';
import { useAppFolioIntegration } from 'hooks/appfolio-integration';
import { useRentManagerIntegrations } from 'hooks/rentmanager-integration';

interface Props {
	community: APICommunity;
	selectedIds: string[];
	setSelectedIds: (ids: string[]) => void;
	selectId: (id: string) => void;
}

const CommunityTableRow = ({
	community,
	selectedIds,
	setSelectedIds,
	selectId,
}: Props) => {
	const history = useHistory();

	const { appFolioIntegration } = useAppFolioIntegration();
	const { rentManagerIntegrations } = useRentManagerIntegrations();

	const handleClick = () => {
		history.push(`/properties/${community.id}`);
	};

	const hasIntegrationSync = Utils.Properties.propertyHasIntegrationSync(
		community,
		{ appFolioIntegration, rentManagerIntegrations }
	);

	return (
		<TableRow
			sx={{
				'&:last-child td, &:last-child th': { border: 0 },
				cursor: 'pointer',
			}}>
			<TableCell>
				<Checkbox
					id={`checkbox-community-${community.id}`}
					size="small"
					checked={selectedIds.includes(community.id)}
					onChange={() => selectId(community.id)}
				/>
			</TableCell>
			<TableCell onClick={handleClick}>{community.name}</TableCell>
			<TableCell onClick={handleClick}>{community.address}</TableCell>
			<TableCell onClick={handleClick}>{community.city}</TableCell>
			<TableCell onClick={handleClick}>{community.zip_code}</TableCell>
			<TableCell onClick={handleClick}>
				{community.team?.internal_label}
			</TableCell>
			<LastCompletedInspection property={community} onClick={handleClick} />
			<TableCell align="center">
				<ActionsMenu
					communityIds={[community.id]}
					setCommunityIds={setSelectedIds}
					disableDeletion={hasIntegrationSync}>
					<IconButton
						id={`action-community-${community.id}`}
						size="small"
						sx={{ width: 28, marginLeft: -2 }}>
						<FontAwesomeIcon icon={solid('ellipsis-v')} color="gray" />
					</IconButton>
				</ActionsMenu>
			</TableCell>
		</TableRow>
	);
};

export default CommunityTableRow;
