import { Payload } from 'store/actions/session-options-actions';

export type ActionTypes = 'SET_BILLING_SETUP_DISMISSED';

export interface State {
	billingSetupDismissed?: boolean;
}

interface Action {
	type: ActionTypes;
	sessionOptionsPayload?: Payload;
}

export default (
	state: State = { billingSetupDismissed: false },
	action: Action
): State => {
	switch (action.type as string) {
		case 'SET_BILLING_SETUP_DISMISSED':
			if (!action.sessionOptionsPayload) return state;
			return {
				...state,
				billingSetupDismissed: action.sessionOptionsPayload.value as boolean,
			};

		case 'SIGNOUT_SUCCESS':
			return {
				billingSetupDismissed: false,
			};

		default:
			return state;
	}
};
