import { Paper, Table, TableBody, TableContainer } from '@mui/material';
import { ApiTenant } from '@rentcheck/types';
import { useTypedSelector } from 'store/reducers/rootReducer';

import EmptyState from './empty-state';

import { InfiniteScroll } from 'components';
import { TenantsActions } from 'store/actions';

import Header from './header';
import Row from './row';
import FiltersSkeleton from './skeleton-filters';

interface Props {
	loading: boolean;
	data: ApiTenant[];
}

const ResidentsTable = ({ data, loading }: Props) => {
	const filters = useTypedSelector((state) => state.tenants.filters);

	if (!loading && !filters.search && !data.length) {
		return <EmptyState active={filters.active_only} />;
	}

	return (
		<div>
			<TableContainer component={Paper}>
				<FiltersSkeleton loading={loading} />
				<Table sx={{ minWidth: 650 }}>
					<Header />
					<TableBody>
						{data.map((row) => (
							<Row resident={row} />
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<InfiniteScroll
				objectName="Residents"
				filterState={filters}
				action={TenantsActions}
				offset={data.length}
			/>
		</div>
	);
};

export default ResidentsTable;
