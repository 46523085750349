import FormTextField from 'components/form-text-field';

interface Props {
	value: string;
	setValue: (value: string) => void;
}

const InternalNotes = ({ value, setValue }: Props) => {
	return (
		<FormTextField
			title="Internal Notes"
			placeholder="Add any internal notes for you and your team"
			multiline
			value={value}
			onChange={(e) => setValue(e.target.value)}
		/>
	);
};

export default InternalNotes;
