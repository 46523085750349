import { LinearProgress } from '@mui/material';
import { GetAllResult } from '@rentcheck/types';
import { Row } from 'components/layout/Row';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';
import { useOnScreen } from 'utils/hooks';

interface Props {
	pageSize: number;
	data: unknown[];
	filterState?: any;
	onLoadMore: () => Promise<GetAllResult<any>>;
}

export default ({ data, pageSize, filterState, onLoadMore }: Props) => {
	const dispatch = useDispatch();

	const isVisibleRef = useRef<HTMLDivElement>(null);
	const isVisible = useOnScreen(isVisibleRef);

	const [needsToFetch, setNeedsToFetch] = useState(false);
	const [loading, setLoading] = useState(false);
	const [outOfResults, setOutOfResults] = useState(false);

	useEffect(() => {
		if (!isVisible) {
			return;
		}

		setNeedsToFetch(true);
	}, [isVisible]);

	useEffect(() => {
		if (data.length % pageSize !== 0) {
			setOutOfResults(true);
		}
	}, [data, pageSize]);

	const handleFetch = () => {
		setLoading(true);
		setNeedsToFetch(false);

		onLoadMore()
			.then((res) => setOutOfResults(res.data.length === 0))
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		if (!needsToFetch) {
			return;
		}

		if (outOfResults) {
			return;
		}

		if (loading) {
			return;
		}

		handleFetch();
	}, [needsToFetch, loading, outOfResults]);

	useEffect(() => {
		setOutOfResults(false);
	}, [filterState]);

	if (outOfResults) return null;

	return (
		<Row ref={isVisibleRef} style={{ justifyContent: 'center' }}>
			<LinearProgress variant="indeterminate" sx={{ width: '100%' }} />
		</Row>
	);
};
