import {
	CircularProgress as MuiCircularProgress,
	Typography,
} from '@mui/material';
import { Column } from 'components';

interface Props {
	progress: number;
	textValue?: string;
	mode?: 'cw' | 'ccw';
}

const CircularProgress = ({ progress, textValue, mode = 'cw' }: Props) => {
	const progressPercentage = progress * 100;

	return (
		<Column
			style={{
				position: 'relative',
				height: 60,
				width: 60,
				alignItems: 'center',
				justifyContent: 'center',
			}}>
			<MuiCircularProgress
				value={100}
				variant="determinate"
				size={60}
				thickness={6}
				style={{ position: 'absolute', top: 0, right: 0 }}
				sx={{
					circle: {
						color: '#CCD6E1',
						strokeLinecap: 'round',
					},
				}}
			/>
			<MuiCircularProgress
				value={progressPercentage}
				variant="determinate"
				size={60}
				thickness={6}
				style={{
					position: 'absolute',
					top: 0,
					right: 0,
					transform:
						mode === 'ccw' ? 'scaleX(-1) rotate(-90deg)' : 'rotate(-90deg)',
				}}
				sx={{
					circle: {
						strokeLinecap: 'round',
					},
				}}
			/>
			<Typography fontSize={17}>
				{textValue ?? `${progressPercentage.toFixed(0)}%`}
			</Typography>
		</Column>
	);
};

export default CircularProgress;
