import { Typography } from '@mui/material';
import { APIProperty } from '@rentcheck/types';
import { PopoverDropdown, Spacer } from 'components';
import { useStoreTeams } from 'hooks/teams';
import { useState } from 'react';

interface Props {
	filterNonAdmin?: boolean;
	property?: APIProperty;
	organizationId?: string;
	setOrganizationId: (id: string) => void;
}

const Team = ({
	property,
	organizationId,
	setOrganizationId,
	filterNonAdmin,
}: Props) => {
	const { teams, loading } = useStoreTeams();

	const [selectedOrganizationId, setSelectedOrganizationId] = useState(
		property?.team?.id ?? organizationId
	);

	const popoverItems =
		teams
			.filter((o) => (filterNonAdmin ? o.user_role === 'admin' : true))
			?.map((o) => ({
				title: o.internal_label,
				selected: selectedOrganizationId === o.id,
				onClick: () => {
					setSelectedOrganizationId(o.id);
					setOrganizationId(o.id);
				},
			})) ?? [];

	const selectedOrganization = teams.find(
		(o) => o.id === selectedOrganizationId
	);

	const organizationName = selectedOrganization?.internal_label ?? '';

	return (
		<div>
			<Typography variant="h6">
				Which team do you want to assign this unit to?
			</Typography>
			<Spacer height={7} />
			<PopoverDropdown
				type="radio"
				options={popoverItems}
				width={134}
				loading={loading}
				title={organizationName || 'Select a team'}
				titleIsSelectedOption={!!organizationName}
			/>
		</div>
	);
};

export default Team;
