import { Inspection } from '@rentcheck/types';
import {
	FilterDetailsProps,
	FilterTitle,
	FilterTitleOption,
	FilterTitleProps,
	FilterType,
} from 'components/lists/filters';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/reducers/rootReducer';

const title: FilterType = 'Inspection Template';

interface TitleProps extends FilterTitleProps {
	filter: 'workOrders' | 'reports';
}

const FilterInspectionTypeTitle = ({
	selectedFilter,
	setFilterDetails,
	filter,
}: TitleProps) => {
	const inspectionTypesFilter = useTypedSelector(
		(state) => state.maintenance.filters[filter].inspectionTemplate
	);

	return (
		<FilterTitle
			label={title}
			isActive={inspectionTypesFilter.length > 0}
			isSelected={selectedFilter === title}
			onClick={() => setFilterDetails(title)}
		/>
	);
};

interface DetailsProps extends FilterDetailsProps {
	filter: 'workOrders' | 'reports';
	type: 'WORK_ORDERS' | 'REPORTS';
}

const FilterInspectionTypeDetails = ({
	selectedFilter,
	type,
	filter,
}: DetailsProps) => {
	const dispatch = useDispatch();

	const inspectionTypesFilter = useTypedSelector(
		(state) => state.maintenance.filters[filter].inspectionTemplate
	);

	const templates = useTypedSelector(
		(state) => state.inspectionTemplates.templates
	);

	if (selectedFilter !== title) {
		return null;
	}

	const handleClick = (
		inspectionTemplateId: Inspection['inspection_template']['id']
	) => {
		dispatch({
			type: `TOGGLE_${type}_INSPECTION_TYPE_FILTER`,
			value: inspectionTemplateId,
		});
	};

	return (
		<>
			{templates.map((template) => (
				<FilterTitleOption
					key={template.id}
					label={template.name}
					isSelected={inspectionTypesFilter.includes(template.id)}
					onClick={() => handleClick(template.id)}
				/>
			))}
		</>
	);
};

export { FilterInspectionTypeDetails, FilterInspectionTypeTitle };
