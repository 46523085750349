import { Grid, TableCell, TableRow, Typography } from '@mui/material';
import { ImportDigest } from '@rentcheck/types';

import { Row } from 'components';
import Creator from './creator';
import Errors from './errors';
import Status from './status';
import Type from './type';

interface Props {
	object: ImportDigest;
}

export default ({ object }: Props) => {
	return (
		<TableRow>
			<TableCell>
				<Grid container columns={2} spacing={2} rowSpacing={4}>
					<GridItem title="Type">
						<Type object={object} />
					</GridItem>
					<GridItem title="New records">{object.new_count}</GridItem>
					<GridItem title="Deleted records">{object.deleted_count}</GridItem>
					<GridItem title="Error count">
						<Errors object={object} />
					</GridItem>
					<GridItem title="User">
						<Creator object={object} />
					</GridItem>
					<GridItem title="Status">
						<Status object={object} />
					</GridItem>
				</Grid>
			</TableCell>
		</TableRow>
	);
};

const GridItem = (props: { title: string; children: React.ReactNode }) => {
	const { title, children } = props;

	return (
		<Grid item xs={1} sx={{ display: 'flex', flexDirection: 'column' }}>
			<Typography variant="overline" mb={1}>
				{title}
			</Typography>
			<Row>{children}</Row>
		</Grid>
	);
};
