import { Switch, Typography } from '@mui/material';
import { Column, Row } from 'components';
import { colors } from 'theme';

interface Props {
	value: boolean;
	setValue: (value: boolean) => void;
}

export default ({ value, setValue }: Props) => {
	const handleClick = () => setValue(!value);

	return (
		<Row
			style={{
				alignItems: 'center',
				justifyContent: 'space-between',
				cursor: 'pointer',
			}}
			onClick={handleClick}>
			<Column>
				<Typography variant="overline">Edit Subscription Plan</Typography>
				<Typography variant="body2" color={colors.secondary} mt={1}>
					Toggle ON to allow this group to make billing plan adjustments.
				</Typography>
			</Column>
			<Column>
				<Row>
					<Typography variant="overline">{value ? 'On' : 'Off'}</Typography>
					<Switch checked={!!value} onChange={handleClick} />
				</Row>
			</Column>
		</Row>
	);
};
