import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { InspectionsActions, SnackbarActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';

import { ApiInspection, InspectionStatusDisplay } from '@rentcheck/types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Dispatch } from 'types';
import { InspectionCompleted, InspectionInProgress } from './content';
import Skeleton from './skeleton';

interface MatchProps {
	id: string;
}

export const shouldShowInProgressScreen = (inspection: ApiInspection) => {
	const validStatuses: InspectionStatusDisplay[] = [
		'Scheduled',
		'Not Started',
		'Started',
	];

	return validStatuses.includes(inspection.inspection_status);
};

export default () => {
	const dispatch: Dispatch = useDispatch();
	const match = useRouteMatch<MatchProps>();
	const history = useHistory();

	const inspectionId = match.params.id;

	const inspection = useTypedSelector((state) =>
		state.inspections.inspection?.id === inspectionId
			? state.inspections.inspection
			: null
	);

	useEffect(() => {
		dispatch(InspectionsActions.getById(inspectionId)).catch((e) => {
			if (e.message === 'inspection not found') {
				history.push('/404');
				return;
			}

			dispatch(SnackbarActions.showError(e.message));
		});
	}, [inspectionId]);

	if (!inspection) {
		return <Skeleton />;
	}

	if (shouldShowInProgressScreen(inspection)) {
		return <InspectionInProgress inspection={inspection} />;
	}

	return <InspectionCompleted inspection={inspection} />;
};
