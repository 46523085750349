import { Typography } from '@mui/material';
import { InspectionEventSMSReminderSentMetadata } from '@rentcheck/types';

interface Props {
	value?: InspectionEventSMSReminderSentMetadata['numbers_sent'];
}

export default ({ value }: Props) => {
	if (!value) {
		return null;
	}

	return (
		<>
			<Typography variant="body2" color="#4D4D4D" mt={1}>
				Sent To:
			</Typography>
			{value.map(({ number }) => (
				<Typography variant="body2" color="#4D4D4D" mt={1}>
					{number}
				</Typography>
			))}
		</>
	);
};
