import { useCreateWorkOrderModalData } from 'hooks/modals';
import _ from 'lodash';
import { useState } from 'react';

import {
	LatchelProperty,
	LatchelTenant,
	LatchelVendor,
	WorkOrder,
	WorkOrdersApi,
} from '@rentcheck/api-frontend';
import SelectProperty from './select-property';
import SelectSkill from './select-skill';
import Success from './success';
import WorkOrderDetails from './work-order-details';

const steps = [
	'Select Property',
	'Select Skill',
	'Work Order Details',
	'Success',
] as const;

export type Step = (typeof steps)[number];

const Steps = () => {
	const modalData = useCreateWorkOrderModalData('Latchel');
	const { inspection, flags } = modalData?.metadata ?? {};

	const [steps, setSteps] = useState<Step[]>(['Select Property']);

	const [property, setProperty] = useState<LatchelProperty | null>(null);
	const [tenant, setTenant] = useState<LatchelTenant>();
	const [accessInstructions, setAccessInstructions] = useState('');
	const [skill, setSkill] = useState<string | null>(null);
	const [name, setName] = useState('');
	const [selectedFlagIds, setSelectedFlagIds] = useState(
		flags.map((f) => f.id)
	);

	const [description, setDescription] = useState('');
	const [vendor, setVendor] = useState<LatchelVendor | null>(null);
	const [vendorInstructions, setVendorInstructions] = useState('');

	const [workOrder, setWorkOrder] = useState<WorkOrder | null>(null);

	const handleNextStep = (step: Step) => {
		setSteps([...steps, step]);
	};

	const handlePreviousStep = () => {
		setSteps(_.take(steps, steps.length - 1));
	};

	const handleCreate = async () => {
		if (!property) return;

		return WorkOrdersApi.create({
			type: 'Latchel',
			primaryResidentId: tenant ? _.toString(tenant?.user_id) : undefined,
			latchelPropertyId: property?.property_id,
			accessInstructions: accessInstructions || undefined,
			vendorInstructions: vendorInstructions || undefined,
			skill: skill ?? undefined,
			flags: flags.filter((f) => selectedFlagIds.includes(f.id)),
			vendorId: vendor?.company_id ?? undefined,
			description,
			name,
		}).then((data) => {
			setWorkOrder(data);
			return;
		});
	};

	if (!inspection || !flags) return null;

	const currentStep = _.last(steps);

	switch (currentStep) {
		case 'Select Property':
			return (
				<SelectProperty
					nextStep={handleNextStep}
					property={property}
					setProperty={setProperty}
					accessInstructions={accessInstructions}
					setAccessInstructions={setAccessInstructions}
					tenant={tenant}
					setTenant={setTenant}
				/>
			);
		case 'Select Skill':
			return (
				<SelectSkill
					nextStep={handleNextStep}
					previousStep={handlePreviousStep}
					skill={skill}
					setSkill={setSkill}
				/>
			);
		case 'Work Order Details':
			return (
				<WorkOrderDetails
					skill={skill}
					property={property as LatchelProperty}
					createWorkOrder={handleCreate}
					nextStep={handleNextStep}
					previousStep={handlePreviousStep}
					name={name}
					setName={setName}
					selectedFlagIds={selectedFlagIds}
					setSelectedFlagIds={setSelectedFlagIds}
					description={description}
					setDescription={setDescription}
					vendor={vendor}
					setVendor={setVendor}
					vendorInstructions={vendorInstructions}
					setVendorInstructions={setVendorInstructions}
				/>
			);
		case 'Success':
			return <Success workOrder={workOrder as WorkOrder} />;
	}

	return null;
};

export default Steps;
