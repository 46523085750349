import { combineReducers } from 'redux';
import permissionGroupsReducer, {
	State as PermissionGroupsState,
} from './permission-groups';
import permissionGroupsFiltersReducer, {
	State as PermissionGroupsFiltersState,
} from './permission-groups-filters';
import usersReducer, { State as UsersState } from './users';
import usersFiltersReducer, {
	State as UsersFiltersState,
} from './users-filters';

export type State = {
	users: UsersState;
	usersFilters: UsersFiltersState;
	permissionGroups: PermissionGroupsState;
	permissionGroupsFilters: PermissionGroupsFiltersState;
};

const reducer = combineReducers({
	users: usersReducer,
	usersFilters: usersFiltersReducer,
	permissionGroups: permissionGroupsReducer,
	permissionGroupsFilters: permissionGroupsFiltersReducer,
});

export default reducer;
