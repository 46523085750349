import { LoadingButton } from '@mui/lab';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Typography,
} from '@mui/material';
import { IntercomAPI } from 'react-intercom';

import { CenteredColumn, DialogTitle, LottieWrapper, Spacer } from 'components';

interface Props {
	open: boolean;
	handleClose: () => void;
	vendor: string;
}

const SyncConfirmation = ({ open, handleClose, vendor }: Props) => {
	const handleContactSupport = () => {
		IntercomAPI('show');
		handleClose();
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle title="" onClose={handleClose} />

			<DialogContent>
				<CenteredColumn>
					<Typography variant="h3">Data sync in progress!</Typography>

					<LottieWrapper variant="sync-on" width={500} height={286} />

					<Typography align="center" variant="subtitle1">
						When first configuring your sync settings please allow up to 15
						minutes for data to sync to your RentCheck account.
					</Typography>
					<Typography align="center" variant="subtitle1">
						Data from {vendor} is synced on a daily basis. When updating sync
						settings it may take up to 24 hours for data to be synced and
						reflected in your RentCheck account.
					</Typography>
					<Spacer height={6} />
				</CenteredColumn>
			</DialogContent>

			<DialogActions>
				<Button
					variant="outlined"
					color="secondary"
					onClick={handleContactSupport}>
					Get help
				</Button>

				<LoadingButton variant="contained" onClick={handleClose}>
					Got it
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export default SyncConfirmation;
