import { TableCell, TableHead, TableRow, styled } from '@mui/material';
import { ApiMaintenanceReport } from '@rentcheck/types';

interface Props {
	report: ApiMaintenanceReport;
}

export default ({ report }: Props) => {
	const headers: Record<string, { title: string; width?: string }> = {
		Responsibility: { title: 'Responsibility', width: '112px' },
		Room: { title: 'Room/Area' },
		Feature: { title: 'Feature' },
		Notes: { title: 'Notes' },
		Media: { title: 'Media', width: '54px' },
		Skill: { title: 'Skill/Vendor Trade' },
		Cost: { title: 'Cost', width: '80px' },
		View: { title: 'View', width: '54px' },
	};

	const sortedHeaders = {
		responsibility: [
			headers.Responsibility,
			headers.Room,
			headers.Feature,
			headers.Notes,
			headers.Media,
			headers.Skill,
			headers.Cost,
			headers.View,
		],
		room: [
			headers.Room,
			headers.Feature,
			headers.Notes,
			headers.Skill,
			headers.Media,
			headers.Responsibility,
			headers.Cost,
			headers.View,
		],
		skill: [
			headers.Skill,
			headers.Feature,
			headers.Room,
			headers.Notes,
			headers.Media,
			headers.Responsibility,
			headers.Cost,
			headers.View,
		],
	};

	return (
		<TableHead>
			<TableRow>
				{sortedHeaders[report.organize_by].map((header) => (
					<HeaderTableCell width={header.width}>{header.title}</HeaderTableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

const HeaderTableCell = styled(TableCell)`
	background-color: #fff !important;
`;
