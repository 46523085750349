import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip } from '@mui/material';
import { colors } from 'theme';
import typography from 'theme/typography';
import { testId } from 'utils/helpers';

interface Props {
	active: boolean;
	title: string;
}

const ActiveChip = ({ active, title }: Props) => {
	if (!active) return null;
	return (
		<Chip
			label="Active"
			id={`active-badge-${testId(title)}`}
			icon={<FontAwesomeIcon icon={regular('check')} color={colors.white} />}
			sx={{
				boxShadow: '0 0 4px #979797',
				marginLeft: 2,
				backgroundColor: colors.primary,
				...typography.MuiTypography.styleOverrides.body2,
				color: colors.white,
				'.MuiChip-icon': { color: colors.white },
			}}
		/>
	);
};

export default ActiveChip;
