import { DashboardProperty } from '@rentcheck/api-frontend';
import { ApiInspection } from '@rentcheck/types';
import {
	insertInspectionIntoStatesIfApplicable,
	mergeObjectsIntoState,
	removeIncorrectInspectionsFromStates,
	removeInspectionsFromStates,
	removePropertiesFromStates,
	updateInspectionsFromStates,
	updatePropertiesFromStates,
} from './list-helpers';

export interface State {
	almostDue: ApiInspection[];
	awaitingReview: ApiInspection[];
	overdue: ApiInspection[];
	unassigned: ApiInspection[];
	revisionRequested: ApiInspection[];
	awaitingSignature: ApiInspection[];
	undeliveredEmails: ApiInspection[];
	propertiesAtRisk: DashboardProperty[];
}

const initialState: State = {
	almostDue: [],
	awaitingReview: [],
	overdue: [],
	unassigned: [],
	revisionRequested: [],
	awaitingSignature: [],
	undeliveredEmails: [],
	propertiesAtRisk: [],
};

type Action = {
	type: string;
	data: ApiInspection[] | DashboardProperty[];
	inspection: ApiInspection;
	inspections: ApiInspection[];
	properties: DashboardProperty[];
	ids: string[];
};

const reducer = (state: State = initialState, action: Action): State => {
	const inspections = action.inspections
		? action.inspections
		: [action.inspection];

	switch (action.type) {
		case 'SIGNOUT_SUCCESS':
		case 'IMPERSONATE':
			return initialState;

		// Individual fetches used to simulate real-timeness of the data
		case 'FETCHED_DASHBOARD_AWAITING_REVIEW':
			return mergeObjectsIntoState(
				state,
				action.data as ApiInspection[],
				'awaitingReview'
			);

		case 'FETCHED_DASHBOARD_UNASSIGNED':
			return mergeObjectsIntoState(
				state,
				action.data as ApiInspection[],
				'unassigned'
			);

		case 'FETCHED_DASHBOARD_ALMOST_DUE':
			return mergeObjectsIntoState(
				state,
				action.data as ApiInspection[],
				'almostDue'
			);

		case 'FETCHED_DASHBOARD_UNDELIVERED_EMAILS':
			return mergeObjectsIntoState(
				state,
				action.data as ApiInspection[],
				'undeliveredEmails'
			);

		case 'FETCHED_DASHBOARD_AWAITING_SIGNATURE':
			return mergeObjectsIntoState(
				state,
				action.data as ApiInspection[],
				'awaitingSignature'
			);

		case 'FETCHED_DASHBOARD_OVERDUE':
			return mergeObjectsIntoState(
				state,
				action.data as ApiInspection[],
				'overdue'
			);

		case 'FETCHED_DASHBOARD_REVISION_REQUESTED':
			return mergeObjectsIntoState(
				state,
				action.data as ApiInspection[],
				'revisionRequested'
			);

		case 'FETCHED_DASHBOARD_PROPERTIES_AT_RISK':
			return mergeObjectsIntoState(
				state,
				action.data as DashboardProperty[],
				'propertiesAtRisk'
			);

		// Support for Inspection Bulk Actions
		case 'DELETE_INSPECTION':
		case 'ARCHIVED_INSPECTION':
			return removeInspectionsFromStates(state, inspections);

		case 'DELETE_INSPECTIONS':
			return removeInspectionsFromStates(
				state,
				inspections.map((i) => ({ id: i })) as unknown as ApiInspection[]
			);

		case 'UPDATED_INSPECTIONS':
			return removeIncorrectInspectionsFromStates(
				insertInspectionIntoStatesIfApplicable(
					updateInspectionsFromStates(state, inspections),
					inspections
				)
			);

		// Support for Properties Bulk Actions
		case 'DELETE_UNITS':
			return removePropertiesFromStates(state, action.ids);

		case 'UPDATED_UNITS':
			return updatePropertiesFromStates(state, action.properties);

		default:
			return state;
	}
};

export default reducer;
