import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { SubscriptionsApi } from '@rentcheck/api-frontend';
import { ChargebeeAPIInvoice } from '@rentcheck/types';
import { SnackbarActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/common';
import { Dispatch } from 'types';

export const useInvoices = () => {
	const dispatch = useDispatch<Dispatch>();

	const subscription = useTypedSelector((state) => state.subscription);

	const [invoices, setInvoices] = useState<ChargebeeAPIInvoice[]>([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (!subscription) {
			return;
		}

		setLoading(true);
		SubscriptionsApi.getInvoices()
			.then((response) => setInvoices(response))
			.catch((e) => dispatch(SnackbarActions.showError(e)))
			.finally(() => setLoading(false));
	}, [subscription?.id]);

	return {
		loading,
		invoices,
	};
};
