import { Rules } from '@rentcheck/biz';
import { ApiInspection } from '@rentcheck/types';
import MenuItem from 'components/menu-item';
import { useDispatch } from 'react-redux';
import { InspectionActions, SnackbarActions } from 'store/actions';
import { Snackbar } from 'store/actions/snackbar-actions';
import { Dispatch } from 'types';

interface Props {
	inspection: ApiInspection;
	onSelected: () => void;
}

export default ({ inspection, onSelected }: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const handleClick = async () => {
		onSelected();

		const progressSnackbar: Snackbar = await dispatch(
			SnackbarActions.showProgress('Canceling Review Request')
		);

		dispatch(InspectionActions.cancelReview(inspection.id))
			.catch((e) => dispatch(SnackbarActions.showError(e)))
			.finally(() => {
				dispatch(SnackbarActions.hideSnackbar(progressSnackbar.id));
			});
	};

	if (!Rules.Inspections.canCancelReviewRequest(inspection)) {
		return null;
	}

	return <MenuItem onClick={handleClick}>Cancel Review Request</MenuItem>;
};
