import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
	Card,
	CardContent,
	Dialog,
	DialogContent,
	Typography,
} from '@mui/material';
import { ApiInspection } from '@rentcheck/types';
import { DialogTitle, SpacedRow, Spacer } from 'components';
import IconButton from 'components/icon-button';
import { useState } from 'react';
import { colors } from 'theme';
import {
	formatDate,
	inspectionIsInProgress,
	reopenedSubmittedEventsFromInspection,
} from 'utils/helpers';
import { useIsMobileBreakpoint } from 'utils/hooks';

interface Props {
	inspection: ApiInspection;
}

export default ({ inspection }: Props) => {
	const isMobile = useIsMobileBreakpoint();

	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const editNotes = reopenedSubmittedEventsFromInspection(inspection).filter(
		// @ts-expect-error
		(e) => e.note
	);

	const notesCount = (inspection.inspection_summary ? 1 : 0) + editNotes.length;

	if (notesCount === 0) {
		return null;
	}

	if (inspectionIsInProgress(inspection)) {
		return null;
	}

	return (
		<>
			<IconButton
				icon={regular('comment-alt-lines')}
				tooltip="Inspection Notes"
				badgeCount={notesCount}
				badgeColor="primary"
				onClick={handleOpen}
			/>

			<Dialog open={open} onClose={handleClose} fullScreen={isMobile}>
				<DialogTitle
					title="Inspection Notes"
					icon={solid('comment-alt-lines')}
					onClose={handleClose}
				/>
				<DialogContent>
					{!!inspection.inspection_summary && (
						<>
							<Typography variant="h6">Summary Note</Typography>
							<Spacer height={2} />
							<Card>
								<CardContent>
									<Typography>
										From {inspection.current_performer?.name ?? 'the resident'}
									</Typography>
									<Spacer height={2} />
									<Typography color="#232E3A">
										{inspection.inspection_summary}
									</Typography>
								</CardContent>
							</Card>
						</>
					)}

					{editNotes.length > 0 && (
						<>
							<Typography variant="h6" sx={{ marginTop: 6 }}>
								Edit Notes
							</Typography>
							<Spacer height={2} />
							{editNotes.map((e) => (
								<Card sx={{ marginBottom: 3 }}>
									<CardContent>
										<SpacedRow>
											<Typography>From {e.user_name}</Typography>
											<Typography color={colors.gray}>
												{formatDate(e.timestamp)}
											</Typography>
										</SpacedRow>
										<Spacer height={2} />

										<Typography color="#232E3A">{(e as any).note}</Typography>
									</CardContent>
								</Card>
							))}
						</>
					)}
				</DialogContent>
			</Dialog>
		</>
	);
};
