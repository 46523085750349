import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { LoadingButton } from '@mui/lab';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	TextField,
	Typography,
} from '@mui/material';
import { AppFolioApi } from '@rentcheck/api-frontend';
import { IntegrationsApi } from 'api';
import { CenteredRow, Column, DialogTitle, Spacer } from 'components';
import { useEffect, useState } from 'react';
import { IntercomAPI } from 'react-intercom';
import { useDispatch } from 'react-redux';
import { SnackbarActions, SubscriptionActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Analytics } from 'utils';

import Success from '../../../common/install-success-modal';
import HeaderIcons from './header-icons';

interface Props {
	open: boolean;
	handleClose: () => void;
}

const InstallAppFolioModal = ({ open, handleClose }: Props) => {
	const dispatch = useDispatch();

	const profile = useTypedSelector((state) => state.activeProfile);
	const subscription = useTypedSelector((state) => state.subscription);

	const [clientID, setClientID] = useState('');
	const [clientSecret, setClientSecret] = useState('');

	const [clientIDError, setClientIDError] = useState('');
	const [clientSecretError, setClientSecretError] = useState('');

	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);

	useEffect(() => {
		setClientID('');
		setClientSecret('');

		setClientIDError('');
		setClientSecretError('');

		setLoading(false);
	}, [open]);

	useEffect(() => {
		setClientIDError('');
	}, [clientID]);

	useEffect(() => {
		setClientSecretError('');
	}, [clientSecret]);

	const validateForm = () => {
		let formIsValid = true;

		if (!clientID) {
			setClientIDError('Client ID is required.');
			formIsValid = false;
		}

		if (!clientSecret) {
			setClientSecretError('Client Secret is required.');
			formIsValid = false;
		}

		return formIsValid;
	};

	const handleConnect = () => {
		if (!profile || !subscription) {
			return;
		}

		if (!validateForm()) {
			return;
		}

		setLoading(true);

		AppFolioApi.verifyCredentials(clientID, clientSecret)
			.then(() =>
				IntegrationsApi.installAppFolioIntegration(
					profile,
					subscription.id,
					clientID,
					clientSecret
				)
			)
			.then(() => {
				setSuccess(true);
				dispatch(SubscriptionActions.getSubscription());
				Analytics.trackEvent('activated_appfolio_integration_by_entering_key', {
					subscription_id: subscription.id,
					chargebee_subscription_id: subscription.plan.sub_id,
				});
			})
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	const handleCloseSuccess = () => {
		handleClose();
		setSuccess(false);
	};

	const handleRequestAssistance = () => {
		IntercomAPI(
			'showNewMessage',
			'I need assistance installing the AppFolio Integration'
		);
	};

	if (success) {
		return (
			<Success
				vendor="AppFolio"
				parentUrl="/account/integrations/appfolio"
				handleClose={handleCloseSuccess}
			/>
		);
	}

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle
				title="Connect AppFolio to RentCheck"
				icon={solid('plug')}
				onClose={handleClose}
			/>

			<DialogContent>
				<Column>
					<HeaderIcons />
					<Spacer height={16} />
					<Typography variant="h6">Client ID:</Typography>
					<Spacer height={1} />
					<TextField
						id="textinput-client_id"
						variant="filled"
						value={clientID}
						onChange={(e) => setClientID(e.target.value)}
						error={!!clientIDError}
						helperText={clientIDError}
					/>
					<Spacer height={8} />
					<Typography variant="h6">Client Secret:</Typography>
					<Spacer height={1} />
					<TextField
						id="textinput-client_secret"
						variant="filled"
						value={clientSecret}
						onChange={(e) => setClientSecret(e.target.value)}
						error={!!clientSecretError}
						helperText={clientSecretError}
					/>
					<Spacer height={8} />
					<CenteredRow>
						<Typography>
							Our team is here to help with your installation.{' '}
							<a
								onClick={handleRequestAssistance}
								style={{ textDecoration: 'underline', cursor: 'pointer' }}>
								Request assistance.
							</a>
						</Typography>
					</CenteredRow>
				</Column>
			</DialogContent>

			<DialogActions>
				<Button variant="outlined" color="secondary" onClick={handleClose}>
					Cancel
				</Button>

				<LoadingButton
					id="appfolio-modal-install"
					variant="contained"
					onClick={handleConnect}
					loading={loading}>
					Install
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export default InstallAppFolioModal;
