import { LoadingButton } from '@mui/lab';
import { Card, CardContent, TextField, Typography } from '@mui/material';
import { AdminToolsApi } from '@rentcheck/api-frontend';
import { FirebaseUpdatePayload, Inspection } from '@rentcheck/types';
import { InspectionsApi } from 'api';
import { SpacedRow, Spacer } from 'components';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';
import { Dispatch } from 'types';

interface Props {
	inspection: Inspection;
	refreshInspection: () => void;
}

const MoveToProperty = ({ inspection, refreshInspection }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const [newValue, setNewValue] = useState('');
	const [loading, setLoading] = useState(false);

	const handleConfirm = async () => {
		if (!inspection) {
			return;
		}

		if (!newValue) {
			return;
		}

		const newProperty = await AdminToolsApi.getProperty(newValue);

		if (!newProperty) {
			dispatch(
				SnackbarActions.showError("Couldn't find a property with that ID")
			);
			return;
		}

		if (!newProperty.team) {
			dispatch(
				SnackbarActions.showError("Couldn't find a property with that ID")
			);
			return;
		}

		const newRenter = await AdminToolsApi.getUserByEmailOrId(
			inspection.creatorID
		);

		if (!newRenter) {
			dispatch(
				SnackbarActions.showError(
					"Couldn't find the original inspection creator"
				)
			);
			return;
		}

		setLoading(true);

		const payload: FirebaseUpdatePayload<Inspection> = {};

		payload.organization_id = newProperty.team.id;
		payload.creatorID = newProperty.created_by.id;
		payload.creator_id = newProperty.created_by.id;
		payload.landlordID = newProperty.created_by.id;

		payload.authorized_user_ids = inspection.authorized_user_ids;

		payload.propertyID = newProperty.id;
		payload.property_address = newProperty.address;
		payload.property_city = newProperty.city;
		payload.property_zipcode = newProperty.zip_code;

		payload.performed_by = inspection.creatorID;
		payload.renterID = inspection.creatorID;
		payload.renter_name = newRenter.user.name;

		payload.inspection_status = inspection.inspection_status;

		if (payload.authorized_user_ids && newProperty.team.id) {
			if (
				payload.authorized_user_ids &&
				!payload.authorized_user_ids.includes(newProperty.team.id)
			) {
				payload.authorized_user_ids = [
					...payload.authorized_user_ids,
					newProperty.team.id,
				];
			}
		}

		if (inspection.inspection_status === 'Inspection Complete') {
			payload.inspection_status = 'Review Inspection';
		}

		await InspectionsApi.update(inspection.id, payload);

		refreshInspection();

		dispatch(SnackbarActions.showSuccess('Inspection Updated'));
	};

	return (
		<Card sx={{ width: '100%' }}>
			<CardContent>
				<Typography variant="h6">
					Transfer Resident Inspection to PM's Property
				</Typography>
				<Spacer height={2} />
				<SpacedRow>
					<TextField
						fullWidth
						variant="filled"
						type="string"
						placeholder="New Property ID"
						value={newValue}
						onChange={(e) => setNewValue(e.target.value)}
					/>

					<Spacer width={4} />

					<LoadingButton loading={loading} onClick={handleConfirm}>
						Confirm
					</LoadingButton>
				</SpacedRow>
			</CardContent>
		</Card>
	);
};

export default MoveToProperty;
