import { ApiUserDigest } from '@rentcheck/types';

import { actionTypes, TeammatesActionTypes } from '../../actions/teammates';

export type State = {
	data: ApiUserDigest[];
	totalResults: number;
};

type Action = {
	type: TeammatesActionTypes;
	value: ApiUserDigest[];
	totalResults?: number;
};

const initialState: State = {
	data: [],
	totalResults: 0,
};

export default (
	state: State = initialState,
	{ type, value, totalResults }: Action
) => {
	switch (type) {
		case actionTypes.SIGNOUT_SUCCESS:
		case actionTypes.IMPERSONATE:
			return initialState;

		case actionTypes.UPDATE_USERS:
			return {
				...state,
				data: state.data.map((user) => {
					const updatedUser = value.find((u) => u.id === user.id);
					return updatedUser || user;
				}),
			};

		case actionTypes.APPEND_USERS:
			return {
				...state,
				data: [...state.data, ...value],
			};

		case actionTypes.CREATED_USERS: {
			const newUsers = value.filter(
				(user) => !state.data.find((u) => u.id === user.id)
			);
			const updatedUsers = state.data.map((user) => {
				const updatedUser = value.find((u) => u.id === user.id);
				return updatedUser || user;
			});

			return {
				data: [...newUsers, ...updatedUsers],
				totalResults: state.totalResults + value.length,
			};
		}

		case actionTypes.SET_USERS:
			return {
				...state,
				data: value,
				totalResults: totalResults ?? value.length,
			};

		case actionTypes.DELETE_USERS:
			return {
				...state,
				data: state.data.filter((user) => !value.find((u) => u.id === user.id)),
				totalResults: state.totalResults - value.length,
			};

		default:
			return state;
	}
};
