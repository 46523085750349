import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Column, Row } from 'components';
import IconButton, { IconButtonRef } from 'components/icon-button';
import { useIsTopModal } from 'hooks/modals';
import { useRef } from 'react';
import { videoUrlFromGCPUri } from 'utils/helpers';
import { useKeyPress } from 'utils/hooks';

import TopControls from '../image/top-controls';

interface Props {
	imageUri: string;
	handleNext: () => void;
	handlePrevious: () => void;
}

export default ({ imageUri, handleNext, handlePrevious }: Props) => {
	const isTopModal = useIsTopModal('Flag Images');

	const containerRef = useRef<HTMLDivElement>(null);
	const prevButtonRef = useRef<IconButtonRef>(null);
	const nextButtonRef = useRef<IconButtonRef>(null);

	useKeyPress((event: { keyCode: number }) => {
		if (!isTopModal) {
			return;
		}

		// LEFT Arrow
		if (event.keyCode === 37) {
			prevButtonRef.current?.click();
		}

		// RIGHT Arrow
		if (event.keyCode === 39) {
			nextButtonRef.current?.click();
		}
	});

	return (
		<Column style={{ width: '100%', height: '100%' }}>
			<TopControls imageUri={imageUri} />
			<Row style={{ width: '100%', height: 'calc(100% - 44px)' }}>
				<IconButton
					ref={prevButtonRef}
					icon={regular('arrow-left')}
					buttonSx={{ marginLeft: 0, marginRight: 1 }}
					onClick={handlePrevious}
				/>

				<div
					ref={containerRef}
					style={{
						width: 'calc(100vw - 80px)',
						height: '100%',
						overflow: 'hidden',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					<video
						controls={true}
						height="100%"
						width={'100%'}
						style={{ background: '#000' }}
						src={videoUrlFromGCPUri(imageUri)}
					/>
				</div>
				<IconButton
					ref={nextButtonRef}
					icon={regular('arrow-right')}
					onClick={handleNext}
				/>
			</Row>
		</Column>
	);
};
