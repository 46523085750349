import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Rules, Utils } from '@rentcheck/biz';
import { ApiInspectionWithTemplate } from '@rentcheck/types';
import IconButton from 'components/icon-button';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { ModalFlowActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';

interface Props {
	inspection: ApiInspectionWithTemplate;
}

export default ({ inspection }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const features = useTypedSelector((state) =>
		Utils.InspectionFeatures.sortFeaturesBySection(
			_.values(state.features[inspection.id] ?? {}),
			inspection.inspection_template
		).filter((f) => f.revision_requested)
	);

	const handleRequestRevision = () => {
		dispatch(
			ModalFlowActions.showInspectionRevisionModal({ inspection, features })
		);
	};

	if (!Rules.Inspections.canReject(inspection)) {
		return null;
	}

	return (
		<IconButton
			icon={regular('pen-swirl')}
			onClick={handleRequestRevision}
			badgeCount={features.length}
			badgeColor="primary"
			tooltip="Request Revision"
		/>
	);
};
