import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { Rules } from '@rentcheck/biz';
import { ApiInspectionWithTemplate } from '@rentcheck/types';
import { useIsMobileBreakpoint } from 'utils/hooks';

const headers = [
	{ id: 'room', title: 'Area/Room' },
	{ id: 'feature', title: 'Feature' },
	{ id: 'images', title: 'Images' },
	{ id: 'notes', title: 'Notes' },
	{ id: 'rating', title: 'Rating' },
	{ id: 'flag', title: 'Actions' },
	{ id: 'arrow', title: '' },
];

interface Props {
	inspection: ApiInspectionWithTemplate;
}
const TableHeader = ({ inspection }: Props) => {
	const isSmallerThanMd = useIsMobileBreakpoint('md');

	if (isSmallerThanMd) {
		return null;
	}

	const visibleHeaders = headers.filter((h) => {
		if (h.id !== 'flag') {
			return true;
		}

		return Rules.Inspections.canPerformFeatureActions(inspection);
	});

	return (
		<TableHead>
			<TableRow>
				{visibleHeaders.map((h) => (
					<TableCell key={h.id}>
						<TableSortLabel hideSortIcon>{h.title}</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

export default TableHeader;
