import { UserSettings } from '@rentcheck/types';

export type State = UserSettings;

type Action = {
	type: 'SET_USER_SETTINGS' | 'SIGNOUT_SUCCESS';
	value: Partial<UserSettings>;
};

export const defaultUserSettings: UserSettings = {
	inspection_view_mode: 'list',
};

export default (state: State = defaultUserSettings, action: Action) => {
	switch (action.type) {
		case 'SET_USER_SETTINGS':
			return { ...state, ...action.value };
		case 'SIGNOUT_SUCCESS':
			return defaultUserSettings;
		default:
			return state;
	}
};
