import { Card, CardContent, Typography } from '@mui/material';
import { BillingModalOption } from '@rentcheck/types';
import { Row, SpacedRow } from 'components';
import { borders } from 'theme';

import CTA from './cta';
import MostPopular from './most-popular';

interface Props {
	plan: BillingModalOption['plan'];
}

export default ({ plan }: Props) => {
	return (
		<Card
			elevation={0}
			sx={{ minWidth: { xs: undefined, md: 350 }, border: borders.cardBorder }}>
			<CardContent sx={{ p: 2, paddingBottom: '16px !important' }}>
				<SpacedRow>
					<Typography variant="subtitle1" color="#4D4D4D">
						{plan.name}
					</Typography>
					<MostPopular plan={plan} />
				</SpacedRow>
				<Row>
					<Typography variant="h4" color="#101010">
						{plan.price}
					</Typography>
					<Typography color="#101010" style={{ marginTop: -14, marginLeft: 4 }}>
						{plan.price_per}
					</Typography>
				</Row>
				<Typography color="#101010">{plan.billing_recurrence}</Typography>
				<CTA plan={plan} />
			</CardContent>
		</Card>
	);
};
