import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Typography } from '@mui/material';
import { DialogTitle } from 'components';
import { useFeatureDetailsModalData } from 'hooks/modals';
import { FeatureWithMetadata } from 'store/reducers/features';
import { colors } from 'theme';
import {
	featureDisplayName,
	formattedAddress,
	numberAndPluralizeWord,
} from 'utils/helpers';

import {
	ApproveReviewEditsButton,
	FlagButton,
	RevisionButton,
	RevisionNotesButton,
} from 'components/features';
import { useDispatch } from 'react-redux';
import { ModalFlowActions } from 'store/actions';
import ArrowsController from './arrows-controller';

interface Props {
	feature: FeatureWithMetadata;
	setFeature: (value: FeatureWithMetadata) => void;
}

const Header = ({ feature, setFeature }: Props) => {
	const dispatch = useDispatch();

	const modalData = useFeatureDetailsModalData();
	const totalMediaCount =
		feature.images.length + (feature?.videos?.length ?? 0);

	const inspection = modalData?.metadata?.inspection;

	const handleClose = () => {
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	if (!inspection) {
		return null;
	}

	return (
		<DialogTitle
			icon={inspection.fast_track ? solid('bolt') : undefined}
			iconColor={colors.primary}
			title={featureDisplayName(feature)}
			bottomSubtitle={`${formattedAddress(inspection.property)} - ${inspection.inspection_template.name}`}
			onClose={handleClose}
			secondaryActionButtons={[
				<ArrowsController feature={feature} setFeature={setFeature} />,
				<ApproveReviewEditsButton feature={feature} inspection={inspection} />,
				<RevisionNotesButton feature={feature} inspection={inspection} />,
				<RevisionButton feature={feature} inspection={inspection} />,
				<FlagButton inspection={inspection} feature={feature} />,
			]}
			tertiaryActionButtons={[
				<Typography color="#00000099">
					{numberAndPluralizeWord(totalMediaCount, 'image')}
				</Typography>,
			]}
		/>
	);
};

export default Header;
