import { useEditFeatureModalData } from 'hooks/modals';

import AllImages from './all-images';
import FullScreen from './full-screen';

interface Props {
	open: boolean;
}

const EditFeature = ({ open }: Props) => {
	const modalData = useEditFeatureModalData();
	const { mode } = modalData?.metadata ?? {};

	if (mode === 'all-images') {
		return <AllImages open={open} />;
	}

	if (mode === 'full-screen-image') {
		return <FullScreen open={open} />;
	}

	return null;
};

export default EditFeature;
