import LottieWrapper from 'components/lottie-wrapper';

interface PlanGateAnimationProps {
	variant?: 'blue' | 'white';
	width?: number;
	height?: number;
	mt?: number;
}

const PlanGateAnimatedIcon = ({
	variant,
	width = 18,
	height = 18,
	mt = 0,
}: PlanGateAnimationProps) => {
	const animationVariant =
		variant === 'blue' ? 'sparkles-blue' : 'sparkles-white';

	return (
		<div style={{ width, height, marginTop: mt }}>
			<LottieWrapper variant={animationVariant} width={width} height={height} />
		</div>
	);
};

export default PlanGateAnimatedIcon;
