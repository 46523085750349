import { Filter } from 'components/table/filter-button-v2';
import { ReactNode } from 'react';

class FilterBuilder implements Filter {
	menuItemComponent: () => ReactNode;
	component: () => ReactNode;

	constructor(menuItem: () => ReactNode, chip: () => ReactNode) {
		this.menuItemComponent = menuItem;
		this.component = chip;
	}
}

export default FilterBuilder;
