import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';

import { EditBillingPlanButton } from 'components/edit-billing-plan-button';
import { ModalFlowActions } from 'store/actions';
import { Dispatch } from 'types';
import { Analytics } from 'utils';

export default () => {
	const dispatch = useDispatch<Dispatch>();

	const handleUpgrade = () => {
		Analytics.trackEvent('selected_button_to_upgrade_plan');
		dispatch(ModalFlowActions.showUpgradeSubscriptionModal());
	};

	return (
		<EditBillingPlanButton
			variant="outlined"
			onClick={handleUpgrade}
			startIcon={<FontAwesomeIcon icon={solid('sparkles')} />}>
			Explore Plans
		</EditBillingPlanButton>
	);
};
