import { Paper, Table, TableBody, TableContainer } from '@mui/material';
import { ApiPermissionGroupDigest } from '@rentcheck/types';
import InfiniteScrollV2 from 'components/infinite-scroll-v2';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TeammatesActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';
import Header from './header';
import Row from './row';
import Skeleton from './skeleton';

export default () => {
	const dispatch = useDispatch<Dispatch>();

	const filters = useTypedSelector(
		(state) => state.teammates.permissionGroupsFilters
	);
	const data = useTypedSelector(
		(state) => state.teammates.permissionGroups.data
	);
	const totalData = useTypedSelector(
		(state) => state.teammates.permissionGroups.totalResults
	);

	const [selectedIds, setSelectedIds] = useState<string[]>([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(true);
		dispatch(TeammatesActions.PermissionGroups.getAll(0, filters)).finally(() =>
			setLoading(false)
		);
	}, []);

	if (data.length === 0 && loading) {
		return <Skeleton />;
	}

	const handleSelectAllChanged = () => {
		if (allSelected) {
			return setSelectedIds([]);
		}

		setSelectedIds(_.map(data, 'id'));
	};

	const handleSelectOneChanged = (user: ApiPermissionGroupDigest) => {
		setSelectedIds(_.xor(selectedIds, [user.id]));
	};

	const handleLoadMore = async () => {
		return dispatch(
			TeammatesActions.PermissionGroups.getAll(data.length, filters)
		);
	};

	const allSelected =
		selectedIds.length > 0 &&
		_.difference(_.map(data, 'id'), selectedIds).length === 0;

	const indeterminate = !allSelected && selectedIds.length > 0;

	return (
		<>
			<TableContainer component={Paper} sx={{ mt: 2 }}>
				<Table>
					<Header
						selected={allSelected}
						indeterminate={indeterminate}
						selectedIds={selectedIds}
						data={data}
						totalData={totalData}
						onSelect={handleSelectAllChanged}
					/>
					<TableBody>
						{data.map((pg) => (
							<Row
								key={pg.id}
								permissionGroup={pg}
								totalData={totalData}
								selected={selectedIds.includes(pg.id)}
								onSelect={handleSelectOneChanged}
								isLastPermissionGroup={data.length === 1}
							/>
						))}
					</TableBody>
				</Table>
				<InfiniteScrollV2
					onLoadMore={handleLoadMore}
					filterState={filters}
					data={data}
					pageSize={20}
				/>
			</TableContainer>
		</>
	);
};
