import { TextField, Typography } from '@mui/material';
import { Row, Spacer } from 'components';

interface Props {
	value: string;
	setValue: (value: string) => void;
}

const Title = ({ value, setValue }: Props) => {
	return (
		<>
			<Row>
				<Typography variant="h6">Work Order Title</Typography>
				<Spacer width={1} />
				<Typography color="#B90B17">*</Typography>
			</Row>

			<Spacer height={2} />

			<TextField
				variant="outlined"
				placeholder="Add a name for this Work Order"
				helperText="Enter a brief description for the work order. This is the work order field “brief description’ in Yardi."
				value={value}
				onChange={(e) => setValue(e.target.value)}
			/>

			<Spacer height={4} />
		</>
	);
};

export default Title;
