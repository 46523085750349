import { LoadingButton } from '@mui/lab';
import {
	Card,
	CardContent,
	Divider,
	TextField,
	Typography,
} from '@mui/material';
import { Inspection } from '@rentcheck/types';
import { InspectionsApi } from 'api';
import { Column, Row, SpacedRow, Spacer } from 'components';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';
import styled from 'styled-components';
import { Dispatch } from 'types';
import ChangeStatus from './change-status';
import MoveToProperty from './move-to-property';
import MoveToPropertyInternal from './move-to-property-internal';
import RestoreInspection from './restore';
import TakeOver from './take-over';

const InspectionTools = () => {
	const [inspectionId, setinspectionId] = useState('');
	const [loading, setLoading] = useState(false);

	const [inspection, setInspection] = useState<Inspection | undefined>(
		undefined
	);

	const dispatch: Dispatch = useDispatch();

	const search = async (inspectionId: string) => {
		setInspection(undefined);
		setLoading(true);

		InspectionsApi.getById(inspectionId)
			.then(setInspection)
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	const refreshInspection = () => {
		setLoading(true);
		InspectionsApi.getById(inspection?.id ?? '')
			.then(setInspection)
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	return (
		<Card>
			<CardContent>
				<Typography variant="h5">Inspection tools</Typography>
				<Spacer height={2} />
				<Typography>Search by Inspection Id:</Typography>
				<Spacer height={4} />

				<FormRow
					onSubmit={(e) => {
						e.preventDefault();
						search(inspectionId);
					}}>
					<TextField
						fullWidth
						variant="filled"
						type="string"
						placeholder="Inspection ID"
						onChange={(e) => setinspectionId(e.target.value)}
					/>

					<Spacer width={6} />
					<LoadingButton
						type="submit"
						variant="contained"
						onClick={() => search(inspectionId)}
						loading={loading}>
						Search
					</LoadingButton>
				</FormRow>

				{!!inspection && (
					<SpacedRow style={{ alignItems: 'flex-start', paddingTop: 32 }}>
						<Column style={{ width: '50%' }}>
							<Card>
								<CardContent>
									<Typography variant="h6">
										{inspection.inspection_template.name} Inspection
									</Typography>
									<Typography>
										{inspection.property_address}, {inspection.property_city}{' '}
										{inspection.property_zipcode}
									</Typography>
									<Typography>
										Created {inspection.created_date.toDate().toDateString()}
									</Typography>
									<Divider sx={{ marginTop: 1, marginBottom: 2 }} />
									<Row>
										<Typography fontWeight={600}>Inspection Status:</Typography>
										<Spacer width={1} />
										<Typography>{inspection.inspection_status}</Typography>
									</Row>
									<Divider sx={{ marginTop: 2, marginBottom: 2 }} />
									<Row>
										<Typography fontWeight={600}>
											Authorized User IDs:
										</Typography>
										<Spacer width={1} />
										<Typography>
											{inspection.authorized_user_ids.join(', ')}
										</Typography>
									</Row>
									<Row>
										<Typography fontWeight={600}>Organization ID:</Typography>
										<Spacer width={1} />
										<Typography>{inspection.organization_id}</Typography>
									</Row>
									<Row>
										<Typography fontWeight={600}>Creator ID:</Typography>
										<Spacer width={1} />
										<Typography>{inspection.creatorID}</Typography>
									</Row>
									<Row>
										<Typography fontWeight={600}>Landlord ID:</Typography>
										<Spacer width={1} />
										<Typography>{inspection.landlordID}</Typography>
									</Row>
									<Spacer height={2} />
									<Row>
										<Typography fontWeight={600}>Property ID:</Typography>
										<Spacer width={1} />
										<Typography>{inspection.propertyID}</Typography>
									</Row>
									<Spacer height={2} />
									<Row>
										<Typography fontWeight={600}>Renter ID:</Typography>
										<Spacer width={1} />
										<Typography>{inspection.renterID}</Typography>
									</Row>
									<Row>
										<Typography fontWeight={600}>Renter Name:</Typography>
										<Spacer width={1} />
										<Typography>{inspection.renter_name}</Typography>
									</Row>
								</CardContent>
							</Card>
						</Column>
						<Column style={{ width: '48%', alignItems: 'flex-start' }}>
							<RestoreInspection
								inspection={inspection}
								refreshInspection={refreshInspection}
							/>
							<Spacer height={4} />
							<ChangeStatus
								inspection={inspection}
								refreshInspection={refreshInspection}
							/>
							<Spacer height={4} />
							<TakeOver
								inspection={inspection}
								refreshInspection={refreshInspection}
							/>
							<Spacer height={4} />
							<MoveToProperty
								inspection={inspection}
								refreshInspection={refreshInspection}
							/>
							<Spacer height={4} />
							<MoveToPropertyInternal
								inspection={inspection}
								refreshInspection={refreshInspection}
							/>
						</Column>
					</SpacedRow>
				)}
			</CardContent>
		</Card>
	);
};

const FormRow = styled.form`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	text-align: center;
	width: 50%;
`;

export default InspectionTools;
