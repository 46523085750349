import { ApiPermissionGroupDigest } from '@rentcheck/types';
import {
	FilterDetailsProps,
	FilterTitle,
	FilterTitleOption,
	FilterTitleProps,
	FilterType,
} from 'components/lists/filters';
import usePermissionGroups from 'hooks/permission-groups';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { TeammatesActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';

const title: FilterType = 'Permission Group';

const FilterPermissionGroupTitle = ({
	selectedFilter,
	setFilterDetails,
}: FilterTitleProps) => {
	const filters = useTypedSelector((state) => state.teammates.usersFilters);

	return (
		<FilterTitle
			label={title}
			isActive={filters.permissionGroupIds.length > 0}
			isSelected={selectedFilter === title}
			onClick={() => setFilterDetails(title)}
		/>
	);
};

const FilterPermissionGroupDetails = ({
	selectedFilter,
}: FilterDetailsProps) => {
	const dispatch = useDispatch();

	const { permissionGroups } = usePermissionGroups();

	const filters = useTypedSelector((state) => state.teammates.usersFilters);

	if (permissionGroups.length === 0) {
		return null;
	}

	if (selectedFilter !== title) {
		return null;
	}

	const handleClick = (pg: ApiPermissionGroupDigest) => {
		const newTeamIds = _.xor(filters.permissionGroupIds, [pg.id]);

		dispatch(
			TeammatesActions.Users.setFilters({ permissionGroupIds: newTeamIds })
		);
	};

	return (
		<>
			{permissionGroups?.map((pg) => (
				<FilterTitleOption
					label={pg.name}
					isSelected={filters.permissionGroupIds.includes(pg.id)}
					onClick={() => handleClick(pg)}
				/>
			))}
		</>
	);
};

export { FilterPermissionGroupDetails, FilterPermissionGroupTitle };
