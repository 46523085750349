import {
	Autocomplete,
	Card,
	CardContent,
	Checkbox,
	TextField,
	Typography,
} from '@mui/material';

import { Spacer } from 'components';
import { PropertyTypeFilter } from '../types';

interface Props {
	types: string[];
	setTypes: (types: string[]) => void;
	error: boolean;
	vendor: string;
	propertyTypeFilter: PropertyTypeFilter;
}

const PropertyType = ({
	types,
	setTypes,
	error,
	vendor,
	propertyTypeFilter,
}: Props) => {
	return (
		<Card sx={{ marginBottom: 4 }}>
			<CardContent>
				<Typography variant="subtitle1">
					{propertyTypeFilter.name} Selection
				</Typography>
				<Spacer height={2} />
				<Typography variant="body2" color="#4D4D4D">
					Select one or more {propertyTypeFilter.name.toLowerCase()}s to sync
					with RentCheck. Only properties with one of these{' '}
					{propertyTypeFilter.name.toLowerCase()}s will be added and synced.
				</Typography>

				<Spacer height={3} />
				<Autocomplete
					multiple
					disableCloseOnSelect
					sx={{ '.MuiAutocomplete-inputRoot': { paddingLeft: 1 } }}
					options={propertyTypeFilter.getFilterOptions()}
					getOptionLabel={(o) => o}
					value={types}
					onChange={(e, v) => setTypes(v)}
					limitTags={2}
					renderInput={(params) => (
						<TextField
							{...params}
							variant="filled"
							label={`Select ${vendor} ${propertyTypeFilter.name}`}
							sx={{ input: { minHeight: 22 } }}
							error={error}
							helperText={error ? 'Required' : ''}
						/>
					)}
					renderOption={(props, option, { selected }) => (
						<li {...props}>
							<Checkbox checked={selected} />
							<Typography>{option}</Typography>
						</li>
					)}
				/>
			</CardContent>
		</Card>
	);
};

export default PropertyType;
