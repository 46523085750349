import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip, SxProps, Tooltip, Typography } from '@mui/material';
import { ApiInspection, Feature, FeatureRating } from '@rentcheck/types';
import { Row } from 'components';
import { useRef } from 'react';
import { colors } from 'theme';

interface Props {
	feature: Feature;
	inspection: ApiInspection;
}

const Rating = ({ feature, inspection }: Props) => {
	const chipRef = useRef<HTMLDivElement>(null);

	if (feature.not_applicable) {
		return (
			<Row>
				<Tooltip title="Not Applicable">
					<Chip
						variant="outlined"
						label={'N/A'}
						sx={createChipSx(
							'#354351',
							'#232E3A1A',
							'#232E3A3D',
							'#232E3A1A',
							'#232E3A1A',
							98
						)}
					/>
				</Tooltip>
				<Typography variant="subtitle2" ml={1}>
					This feature was marked 'Not Applicable' by{' '}
					{inspection.completed_by?.name ?? 'the performer'}.
				</Typography>
			</Row>
		);
	}

	if (!feature.rating) return null;

	const icon = {
		Good: regular('smile'),
		Fair: regular('meh'),
		Poor: regular('frown'),
	}[feature.rating];

	return (
		<Tooltip title={feature.rating}>
			<Chip
				ref={chipRef}
				variant="outlined"
				label={<FontAwesomeIcon icon={icon} />}
				sx={chipSx(feature.rating)}
			/>
		</Tooltip>
	);
};

const createChipSx = (
	iconColor: string,
	backgroundColor: string,
	hoverColor: string,
	borderColor: string,
	borderHoverColor: string,
	width: number = 40
): SxProps => {
	return {
		fontWeight: 500,
		backgroundColor: backgroundColor,
		borderColor: colors.transparent,

		marginRight: 1,
		marginTop: 0.5,
		marginBottom: 0.5,
		width,
		color: iconColor,

		'.MuiChip-icon': {
			color: iconColor,
		},

		'&&:hover': {
			backgroundColor: hoverColor,
			borderColor: borderHoverColor,
		},

		'.MuiChip-label': {
			paddingLeft: 0,
			paddingRight: 0,
		},
	};
};

const chipSx = (rating: FeatureRating) => {
	switch (rating) {
		case 'Good':
			return createChipSx(
				'#36B45F',
				'#36B45F1F',
				'#36B45F3D',
				'#36B45F1F',
				'#36B45F1F'
			);
		case 'Fair':
			return createChipSx(
				'#DB5C09',
				'#EB830D1F',
				'#EB830D3D',
				'#EB830D1F',
				'#EB830D1F'
			);
		case 'Poor':
			return createChipSx(
				'#B90B17',
				'#F04D4C1F',
				'#F04D4C3D',
				'#F04D4C1F',
				'#F04D4C1F'
			);
	}
};

export default Rating;
