import { AccountSettings } from '@rentcheck/types';

export type State = AccountSettings;

type Action = {
	type: 'SET_ACCOUNT_SETTINGS' | 'SIGNOUT_SUCCESS';
	value: AccountSettings;
};

const accountSettingsReducer = (state = null, action: Action) => {
	switch (action.type) {
		case 'SET_ACCOUNT_SETTINGS':
			return action.value;
		case 'SIGNOUT_SUCCESS':
			return null;
		default:
			return state;
	}
};

export default accountSettingsReducer;
