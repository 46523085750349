import { MaintenanceReportsApi } from '@rentcheck/api-frontend';
import {
	CreateMaintenanceReportParams,
	GetAllMaintenanceReportsParams,
} from '@rentcheck/types';
import moment from 'moment';
import { ReduxFn } from 'types';
import { numberAndPluralizeWord } from 'utils/helpers';
import {
	DateRange,
	State as FilterState,
} from '../../reducers/maintenance/filters/reports';
import * as SnackbarActions from '../snackbar-actions';

export const pageSize = 20;

const buildDateRange = (
	dateRange?: DateRange
): GetAllMaintenanceReportsParams['filters']['created_date'] => {
	if (!dateRange) {
		return undefined;
	}

	return {
		start: dateRange.start ? moment(dateRange.start).toISOString() : undefined,
		end: dateRange.end ? moment(dateRange.end).toISOString() : undefined,
	};
};

export const getAll = (
	offset: number = 0,
	filterState: FilterState
): ReduxFn => {
	const fn: ReduxFn = async (dispatch) => {
		const pageNumber = Math.floor(offset / pageSize);

		const filters: GetAllMaintenanceReportsParams['filters'] = {
			search: filterState.search,
			inspection_templates: filterState.inspectionTemplate.join(','),
			created_date: buildDateRange(filterState.dateRange),
		};

		const sorting: GetAllMaintenanceReportsParams['options']['sort'] = {
			sort_by: filterState.sortBy,
			sort_type: filterState.sortType,
		};

		const { data, total_results } = await MaintenanceReportsApi.getAll(
			pageNumber,
			pageSize,
			filters,
			sorting
		);

		const dispatchType =
			offset === 0 ? 'FETCHED_INITIAL_REPORTS' : 'FETCHED_REPORTS_PAGE';

		dispatch({ type: dispatchType, reports: data });
		dispatch({ type: 'SET_TOTAL_REPORTS', value: total_results });

		return data;
	};

	return fn;
};

export const getById = (id: string): ReduxFn => {
	const fn: ReduxFn = async (dispatch) => {
		const report = await MaintenanceReportsApi.getById(id);
		dispatch({ type: 'FETCHED_REPORT', report });
		return report;
	};
	return fn;
};

export const refreshReport = (): ReduxFn => {
	const fn: ReduxFn = async (dispatch, getState) => {
		const currentReport = getState().maintenance.report;

		if (!currentReport) {
			return;
		}

		return dispatch(getById(currentReport.id));
	};
	return fn;
};

export const remove = (ids: string[]): ReduxFn => {
	const fn: ReduxFn = async (dispatch) => {
		try {
			const numberOfReports = numberAndPluralizeWord(ids.length, 'report');

			const progressSnackbar = await dispatch(
				SnackbarActions.showProgress(`Deleting ${numberOfReports}`)
			);

			await MaintenanceReportsApi.remove(ids);

			dispatch({ type: 'DELETED_REPORTS', ids });

			dispatch(SnackbarActions.hideSnackbar(progressSnackbar.id));

			dispatch(
				SnackbarActions.showSuccess(`Deleted ${numberOfReports} successfully!`)
			);
		} catch (e) {
			dispatch(SnackbarActions.showError((e as Error).message));
		}
	};
	return fn;
};

export const create = (
	name: CreateMaintenanceReportParams['data']['name'],
	organizeBy: CreateMaintenanceReportParams['data']['organize_by'],
	flags: CreateMaintenanceReportParams['data']['flags']
) => {
	const fn: ReduxFn = async (dispatch) => {
		const report = await MaintenanceReportsApi.create({
			name,
			organize_by: organizeBy,
			flags,
		});

		dispatch({
			type: 'CREATE_MAINTENANCE_REPORT',
			report: report,
		});

		return report;
	};

	return fn;
};
