import * as React from 'react';
import ReactModal from 'react-modal';

ReactModal.setAppElement('#root');

interface Props extends ReactModal.Props {
	children: React.ReactNode;
	isOpen: boolean;
}

export function Modal({ children, ...rest }: Props) {
	return (
		//@ts-ignore
		<ReactModal style={styles} {...rest}>
			{children}
		</ReactModal>
	);
}

const styles = {
	overlay: {
		backgroundColor: 'rgba(35, 46, 58, 0.9)',
		display: 'flex',
		alignItems: 'center',
		zIndex: 9999999999,
	},
	content: {
		border: 0,
		borderRadius: 4,
		left: 0,
		margin: 'auto',
		maxHeight: '90vh',
		overflow: 'visible',
		padding: 0,
		position: 'relative',
		top: 0,
		width: 600,
	},
} as ReactModal.Styles;
