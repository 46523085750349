import { FileRequestApi } from '@rentcheck/api-frontend';
import { Rules, Utils } from '@rentcheck/biz';
import { ApiInspection } from '@rentcheck/types';
import MenuItem from 'components/menu-item';
import { useStoreFeatures } from 'hooks/features';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';
import { Dispatch } from 'types';

interface Props {
	inspection: ApiInspection;
	onSelected: () => void;
}

export default ({ inspection, onSelected }: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const features = useStoreFeatures(inspection.id);

	const handleClick = () => {
		onSelected();

		const images = _.flatten(features.map((f) => f.images));
		const message = `You will receive an email with a link to download ${Utils.Helpers.numberAndPluralizedWord(images.length, 'image')}`;

		FileRequestApi.create('INSPECTION_REPORT', inspection.id, 'ZIP');

		dispatch(SnackbarActions.showSuccess(message));
	};

	if (!Rules.Inspections.canDownloadImages(inspection, features)) {
		return null;
	}

	return <MenuItem onClick={handleClick}>Download Images (Zip file)</MenuItem>;
};
