import {
	ApiInspectionEvent,
	InspectionEventInspectionNotificationSentMetadata,
} from '@rentcheck/types';

import BaseTimelineEvent from '../base-event';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

interface Props {
	event: ApiInspectionEvent;
}

const InspectionNotificationSentTimelineEvent = ({ event }: Props) => {
	const metadata =
		event.metadata as InspectionEventInspectionNotificationSentMetadata;
	const subtitle = metadata.requester_name
		? `Requested by ${metadata.requester_name}`
		: '';

	return (
		<BaseTimelineEvent
			icon={solid('bell')}
			title="Inspection notification sent"
			timestamp={event.timestamp}
			subtitle={subtitle}
			sentTo={metadata.emails_sent}
		/>
	);
};

export default InspectionNotificationSentTimelineEvent;
