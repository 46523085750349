import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Typography,
} from '@mui/material';
import { Column, Spacer } from 'components';
import { PriorityT } from '../../common';

interface Props {
	value: PriorityT;
	setValue: (value: PriorityT) => void;
}

const Priority = ({ value, setValue }: Props) => {
	return (
		<>
			<Column>
				<Typography variant="overline">Priority</Typography>
				<Typography variant="caption" sx={{ marginTop: -0.5 }}>
					This is the work order field ‘Priority’ in AppFolio
				</Typography>
			</Column>

			<Spacer height={2} />

			<FormControl fullWidth>
				<InputLabel
					sx={
						value ? { transform: 'translate(12px,8px) scale(0.75)' } : undefined
					}>
					Priority
				</InputLabel>
				<Select
					variant="filled"
					value={value}
					onChange={(e) => setValue(e.target.value as PriorityT)}>
					<MenuItem id="Low" value="Low">
						Low
					</MenuItem>
					<MenuItem id="Normal" value="Normal">
						Normal
					</MenuItem>
					<MenuItem id="Urgent" value="Urgent">
						Urgent
					</MenuItem>
				</Select>
			</FormControl>

			<Spacer height={4} />
		</>
	);
};

export default Priority;
