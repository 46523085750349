import { Autocomplete, MenuItem, Typography } from '@mui/material';
import {
	BuildingsApi,
	CommunitiesApi,
	SortConfig,
	UnitsApi,
} from '@rentcheck/api-frontend';
import { APIProperty } from '@rentcheck/types';
import { Column } from 'components';
import FormTextField from 'components/form-text-field';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';

interface Props {
	propertyType: 'unit' | 'building' | 'community';
	property: APIProperty | undefined;
	setProperty: (value: APIProperty | undefined) => void;
	filterProperty?: (property: APIProperty) => boolean;
}

export default ({
	propertyType,
	property,
	setProperty,
	filterProperty,
}: Props) => {
	const dispatch = useDispatch();

	const [properties, setProperties] = useState<APIProperty[]>([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		search('');
	}, []);

	const debouncedSearch = _.debounce((filters, sorting) => {
		const api = (() => {
			switch (propertyType) {
				case 'building':
					return BuildingsApi;
				case 'community':
					return CommunitiesApi;
				case 'unit':
				default:
					return UnitsApi;
			}
		})();

		api
			.getAll(0, 100, filters, sorting)
			.then((result) =>
				setProperties(
					filterProperty
						? (result.data as APIProperty[]).filter(filterProperty)
						: result.data
				)
			)
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	}, 300);

	const search = (keyword: string) => {
		const filters = {
			search: keyword,
			teamId: [],
			buildingId: [],
			inspectionType: [],
		};

		const sorting: SortConfig = {
			sortBy: 'address',
			sortType: 'asc',
		};

		setLoading(true);
		debouncedSearch(filters, sorting);
	};

	const autocompleteProps = (() => {
		switch (propertyType) {
			case 'unit':
				return {
					title: 'Merge Units',
					placeholder: 'Choose Unit',
					loadingText: 'Loading Units...',
					noOptionsText: 'No units match your search',
				};
			case 'building':
				return {
					title: 'Merge Buildings',
					placeholder: 'Choose Building',
					loadingText: 'Loading Buildings...',
					noOptionsText: 'No buildings match your search',
				};
			case 'community':
				return {
					title: 'Merge Communities',
					placeholder: 'Choose Community',
					loadingText: 'Loading Communities...',
					noOptionsText: 'No communities match your search',
				};
			default:
				return {
					title: 'Merge Properties',
					placeholder: 'Choose Property',
					loadingText: 'Loading Properties...',
					noOptionsText: 'No properties match your search',
				};
		}
	})();

	return (
		<Autocomplete
			fullWidth
			disableClearable
			options={properties}
			getOptionLabel={(o) => o.address}
			isOptionEqualToValue={(o, v) => o.id === v?.id}
			filterOptions={(x) => x}
			value={property}
			onChange={(_, value) => setProperty(value ?? undefined)}
			onInputChange={(_, value) => search(value)}
			noOptionsText={autocompleteProps.noOptionsText}
			loading={loading}
			loadingText={autocompleteProps.loadingText}
			renderOption={(props, option) => (
				<MenuItem {...props} key={option.id}>
					<Column>
						{option.address}
						<Typography fontSize={12} color="#666">
							{option.city}, {option.zip_code}
						</Typography>
					</Column>
				</MenuItem>
			)}
			renderInput={(params) => (
				<FormTextField
					{...params}
					title={autocompleteProps.title}
					caption={`This action will merge the two ${propertyType}s and consolidate all inspection data, ensuring the data remains intact. Please note that this action is irreversible.`}
					placeholder={autocompleteProps.placeholder}
					helperText="Required"
				/>
			)}
		/>
	);
};
