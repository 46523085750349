import { Grid } from '@mui/material';
import { ApiInspectionWithTemplate } from '@rentcheck/types';

import Address from './address';
import Assignees from './assignees';
import CompletedBy from './completed-by';
import DateCompleted from './date-completed';
import DueDate from './due-date';
import InviteDate from './invite-date';
import ReviewDueDate from './review-due-date';
import RevisionDueDate from './revision-due-date';
import Status from './status';
import Team from './team';

interface Props {
	inspection: ApiInspectionWithTemplate;
}

export default ({ inspection }: Props) => {
	return (
		<Grid container columns={{ xs: 1, md: 2 }} spacing={2}>
			<Address inspection={inspection} />
			<Assignees inspection={inspection} />
			<Team inspection={inspection} />
			<Status inspection={inspection} />
			<DateCompleted inspection={inspection} />
			<CompletedBy inspection={inspection} />
			<DueDate inspection={inspection} />
			<RevisionDueDate inspection={inspection} />
			<ReviewDueDate inspection={inspection} />
			<InviteDate inspection={inspection} />
		</Grid>
	);
};
