import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { Rules } from '@rentcheck/biz';
import { ApiInspection } from '@rentcheck/types';
import { DialogTitle } from 'components';
import Signature, {
	DrawnSignature,
	SignatureRef,
	TextSignature,
} from 'components/signature';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	InspectionActions,
	PropertiesActions,
	SnackbarActions,
} from 'store/actions';
import { DiffResult } from 'store/actions/properties/units';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';
import { Analytics } from 'utils';
import { useIsMobileBreakpoint } from 'utils/hooks';

import { inspectionPropertyDiff } from '../../secondary-actions/quick-edits/utils';
import ApprovalNote from './approval-note';
import MoreOptions from './more-options';
import UnitUpdates from './unit-updates';

interface Props {
	inspection: ApiInspection;
}

export default ({ inspection }: Props) => {
	const dispatch: Dispatch = useDispatch();
	const isMobile = useIsMobileBreakpoint();

	const profile = useTypedSelector((state) => state.activeProfile);

	const signatureRef = useRef<SignatureRef>(null);

	const [modalOpen, setModalOpen] = useState(false);
	const [unitDiff, setUnitDiff] = useState<DiffResult[]>([]);
	const [loading, setLoading] = useState(false);

	const [approvalNote, setApprovalNote] = useState('');
	const [shouldUpdateUnit, setShouldUpdateUnit] = useState(false);
	const [rating, setRating] = useState<number>();
	const [privateNote, setPrivateNote] = useState('');

	useEffect(() => {
		inspectionPropertyDiff(inspection).then((result) => setUnitDiff(result));
	}, [inspection]);

	useEffect(() => {
		setLoading(false);

		setApprovalNote('');
		setShouldUpdateUnit(false);
		setRating(undefined);
		setPrivateNote('');

		signatureRef.current?.clear();
	}, [modalOpen]);

	const handleClose = () => {
		setModalOpen(false);
	};

	const handleApprove = async () => {
		const signature = signatureRef.current?.getSignature();

		if (!signature) {
			return dispatch(
				SnackbarActions.showError(
					'Please sign the inspection before approving it'
				)
			);
		}

		setLoading(true);

		if (shouldUpdateUnit) {
			await dispatch(
				PropertiesActions.units.applyUnitDiff(inspection.property.id, unitDiff)
			).catch((e) => dispatch(SnackbarActions.showError(e.message)));
		}

		await dispatch(
			InspectionActions.approveInspection(
				inspection,
				profile,
				privateNote,
				approvalNote,
				rating,
				(signature as DrawnSignature).data,
				(signature as TextSignature).text
			)
		)
			.then(() => {
				Analytics.trackEvent('approved_inspection', {
					inspection_template: inspection.inspection_template.name,
					inspection_id: inspection.id,
					user_id: profile.id,
					performed_by: inspection.current_performer?.id ?? '',
				});
			})
			.then(handleClose)
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	if (!Rules.Inspections.canApprove(inspection)) {
		return null;
	}

	return (
		<>
			<Button
				onClick={() => setModalOpen(true)}
				variant="outlined"
				startIcon={<FontAwesomeIcon icon={solid('circle-check')} />}>
				Approve
			</Button>

			<Dialog open={modalOpen} onClose={handleClose} fullScreen={isMobile}>
				<DialogTitle
					title="Approve Inspection"
					icon={solid('badge-check')}
					onClose={handleClose}
				/>

				<DialogContent>
					<Signature ref={signatureRef} />
					<ApprovalNote
						performerName={inspection.current_performer?.name}
						approvalNote={approvalNote}
						setApprovalNote={setApprovalNote}
					/>
					{unitDiff.length > 0 && (
						<UnitUpdates
							shouldUpdateUnit={shouldUpdateUnit}
							setShouldUpdateUnit={setShouldUpdateUnit}
						/>
					)}
					<MoreOptions
						rating={rating}
						setRating={setRating}
						privateNote={privateNote}
						setPrivateNote={setPrivateNote}
					/>
				</DialogContent>

				<DialogActions>
					<Button onClick={handleClose} variant="text" color="secondary">
						Cancel
					</Button>
					<LoadingButton
						variant="contained"
						onClick={handleApprove}
						loading={loading}>
						Approve
					</LoadingButton>
				</DialogActions>
			</Dialog>
		</>
	);
};
