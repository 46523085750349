import { Button, Tooltip } from '@mui/material';
import { useUserSettings } from 'hooks/user-settings';
import { FeatureWithMetadata } from 'store/reducers/features';

interface Props {
	features: FeatureWithMetadata[];
	expandedFeatureIds: string[];
	setExpandedFeatureIds: (ids: string[]) => void;
}

export default ({
	features,
	expandedFeatureIds,
	setExpandedFeatureIds,
}: Props) => {
	const { userSettings } = useUserSettings();

	if (userSettings.inspection_view_mode === 'list') {
		return null;
	}

	const handleExpandAll = () => {
		setExpandedFeatureIds(features.map((f) => f.id));
	};

	const handleCollapseAll = () => {
		setExpandedFeatureIds([]);
	};

	const someExpanded = expandedFeatureIds.length > 0;

	if (someExpanded) {
		return (
			<Tooltip title="Collapse all features">
				<Button
					variant="outlined"
					color="secondary"
					onClick={handleCollapseAll}>
					Collapse Details
				</Button>
			</Tooltip>
		);
	}

	return (
		<Tooltip title="Expand all features">
			<Button variant="outlined" color="secondary" onClick={handleExpandAll}>
				Expand Details
			</Button>
		</Tooltip>
	);
};
