import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import { Utils } from '@rentcheck/biz';
import { Column } from 'components';
import { FeatureWithMetadata } from 'store/reducers/features';
import styled from 'styled-components';

interface Props {
	feature: FeatureWithMetadata;
	image: string;
}

export default ({ feature, image }: Props) => {
	const linkedQuestions = Utils.InspectionFeatures.getLinkedQuestionsForMedia(
		feature,
		image
	);

	if (!linkedQuestions.length) {
		return null;
	}

	return (
		<div
			style={{
				position: 'absolute',
				bottom: 0,
				left: 0,
				height: 36,
				width: 36,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}>
			<Tooltip
				placement="top"
				title={
					<Column>
						{linkedQuestions.map((q) => (
							<TooltipContent key={q.question.id}>
								<div>Q: {q.question.title}</div>
								<div>A: {q.answer}</div>
							</TooltipContent>
						))}
					</Column>
				}>
				<FontAwesomeIcon
					icon={solid('message-question')}
					color="#fff"
					style={{
						filter: 'drop-shadow( 1px 1px 1px rgba(0, 0, 0, .5))',
					}}
				/>
			</Tooltip>
		</div>
	);
};

const TooltipContent = styled.div`
	margin-bottom: 8px;

	:last-child {
		margin-bottom: 0;
	}
`;
