import { MenuItem, SelectChangeEvent } from '@mui/material';
import FormTextField from 'components/form-text-field';
import { useStoreTeams } from 'hooks/teams';
import { ChangeEvent } from 'react';

interface Props {
	value: string[];
	setValue: (value: string[]) => void;
	caption?: string;
}

export default ({
	value,
	setValue,
	caption = 'Invited teammates will have access to the properties included in selected teams.',
}: Props) => {
	const { teams, loading } = useStoreTeams();

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const selectEvent = e as SelectChangeEvent<string[]>;
		const newValue = selectEvent.target.value;
		setValue(newValue as string[]);
	};

	return (
		<FormTextField
			title="Add to Teams"
			caption={caption}
			placeholder="Select Teams"
			value={value}
			loading={loading}
			select
			required
			multiple
			onChange={handleChange}>
			{teams.map((t) => (
				<MenuItem key={t.id} value={t.id}>
					{t.internal_label}
				</MenuItem>
			))}
		</FormTextField>
	);
};
