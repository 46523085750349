import { Column } from 'components';
import SearchBar from 'components/table/search';
import { RootState } from 'store/reducers/rootReducer';

import { Box, Divider, Paper } from '@mui/material';

import { useState } from 'react';
import styled from 'styled-components';
import HideButton from './hide-button';
import FiltersButton from './menu';
import Segments from './segments';

const List = () => {
	const searchExtractor = (state: RootState) =>
		state.inspections.filters.search;

	const [filtersOpen, setFiltersOpen] = useState(true);

	return (
		<Column style={{ flex: 1 }}>
			<HideButton open={filtersOpen} setOpen={setFiltersOpen} />
			{filtersOpen && (
				<Paper
					elevation={0}
					sx={{ border: '1px solid #0000001f', borderRadius: 2 }}>
					<Section>
						<Segments />
					</Section>
					<Divider />
					<Section style={{ paddingTop: 8, paddingBottom: 8 }}>
						<Box
							sx={{
								display: 'flex',
								flexDirection: { xs: 'column', md: 'row' },
								gap: '8px',
							}}>
							<FiltersButton />
							<SearchBar
								stateExtractor={searchExtractor}
								type="inspection"
								style={{ marginLeft: 'auto' }}
							/>
						</Box>
					</Section>
				</Paper>
			)}
		</Column>
	);
};

const Section = styled(Column)`
	padding: 16px;
`;

export default List;
