import { Typography } from '@mui/material';
import { ApiInspection } from '@rentcheck/types';
import styled from 'styled-components';
import { colors, spacingPx } from 'theme';
import { shortDisplayDate } from 'utils/helpers';
import { Column, Spacer } from '../../../';

interface Props {
	inspection: ApiInspection;
	currentSelection?: ApiInspection; // comparison edit only
	disabled: boolean;
	selected: boolean;
	setSelectedInspection: (inspection: ApiInspection) => void;
}

const ComparisonRow = ({
	inspection,
	disabled,
	selected,
	currentSelection,
	setSelectedInspection,
}: Props) => {
	const isCurrentSelection =
		currentSelection && inspection.id === currentSelection.id;

	return (
		<Wrapper
			selected={selected}
			disabled={disabled}
			onClick={() => {
				if (disabled) {
					return;
				}
				setSelectedInspection(inspection);
			}}>
			<SpacedRow>
				<Column>
					<TitleRow>
						<Typography variant="h4">
							{inspection.inspection_template.name} Inspection{' '}
							{isCurrentSelection ? `(Current)` : ``}
						</Typography>
						{inspection.archived && (
							<>
								<Spacer width={2} />
								<Typography>(Archived)</Typography>
							</>
						)}
					</TitleRow>
					<Spacer height={2} />
					<SpacedRow>
						<SubvalueColumn width={26}>
							<Typography>Status</Typography>
							<Typography>{inspection.inspection_status}</Typography>
						</SubvalueColumn>
						<Spacer width={2} />
						<SubvalueColumn width={30}>
							<Typography>Completed Date</Typography>
							<Typography>
								{shortDisplayDate(inspection.completed_date)}
							</Typography>
						</SubvalueColumn>
						<Spacer width={2} />
						<SubvalueColumn width={30}>
							<Typography>Completed By</Typography>
							<Typography>{inspection.current_performer?.name}</Typography>
						</SubvalueColumn>
					</SpacedRow>
				</Column>
			</SpacedRow>
		</Wrapper>
	);
};

const Wrapper = styled.div<{ selected: boolean; disabled: boolean }>`
	padding: ${spacingPx(4)};
	height: ${spacingPx(28)};
	border-radius: ${spacingPx(2)};
	background-color: ${({ selected }) =>
		selected ? `${colors.primary}05` : colors.white};
	border: ${({ selected }) =>
		selected ? `3px ${colors.primary} solid` : `3px ${colors.white} solid`};
	opacity: ${({ disabled }) => (disabled ? 0.5 : 1.0)};
	cursor: pointer;
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
`;

const SubvalueColumn = styled(Column)<{ width: number }>`
	width: ${({ width }) => spacingPx(width)};
`;

const SpacedRow = styled(Row)`
	justify-content: space-between;
`;

const TitleRow = styled(Row)`
	align-items: flex-end;
`;

export default ComparisonRow;
