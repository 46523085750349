import { Autocomplete, Typography } from '@mui/material';

import { TemplateQuestion } from '@rentcheck/types';

import { Row } from 'components';
import FormTextField from 'components/form-text-field';

interface Props {
	question?: TemplateQuestion;
	questions: TemplateQuestion[];
	onUpdateTriggerId: (triggerId: string) => void;
}

export default ({ question, questions, onUpdateTriggerId }: Props) => {
	return (
		<Row>
			<Typography
				variant="subtitle2"
				mb={3.5}
				sx={{ maxWidth: 180, minWidth: 180 }}>
				If question is:
			</Typography>

			<Autocomplete
				fullWidth
				openOnFocus
				options={questions}
				getOptionLabel={(o) => o.title}
				value={question}
				onChange={(e, v) => onUpdateTriggerId(v?.id ?? '')}
				isOptionEqualToValue={(o, v) => o.id === v?.id}
				noOptionsText="No Results"
				renderInput={(params) => (
					<FormTextField
						{...params}
						required
						multiline
						value={question?.title}
						autoFocus={!question}
						inputSx={{ mt: 0, mb: 1 }}
						placeholder="Select question"
						helperText="Required"
					/>
				)}
			/>
		</Row>
	);
};
