import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import 'react-phone-input-2/lib/style.css';
import { useDispatch } from 'react-redux';

import { LoadingButton } from '@mui/lab';
import { FileRequestApi } from '@rentcheck/api-frontend';
import { ApiMaintenanceReport, ApiTenant } from '@rentcheck/types';
import { DialogTitle, Spacer } from 'components';
import FormTextField from 'components/form-text-field';
import RecipientsList, { Recipient } from 'components/recipients-list';
import { useShareMaintenanceReportModalData } from 'hooks/modals';
import { useEffect, useState } from 'react';
import { ModalFlowActions, SnackbarActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';
import { Analytics } from 'utils';
import { formattedAddress } from 'utils/helpers';

interface Props {
	open: boolean;
}

export default ({ open }: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const profile = useTypedSelector((state) => state.activeProfile);

	const modalData = useShareMaintenanceReportModalData();
	const object = modalData?.metadata?.object;

	const [loading, setLoading] = useState(false);
	const [recipients, setRecipients] = useState<Recipient[]>([]);
	const [message, setMessage] = useState('');
	const [properties, setProperties] = useState<
		ApiMaintenanceReport['property'][]
	>([]);

	useEffect(() => {
		setRecipients([]);
		setMessage('');
		setProperties([]);
	}, [open]);

	useEffect(() => {
		if (!object) return;

		setProperties([(object as ApiMaintenanceReport).property]);
	}, [object]);

	const handleClose = () => {
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	const createFileRequest = async (recipients: string[]) => {
		return FileRequestApi.create('MAINTENANCE_REPORT', object.id, 'PDF', {
			send_to: recipients,
			note: message,
		});
	};

	const trackAnalyticsEvent = (recipients: string[]) => {
		Analytics.trackEvent('shared_maintenance_report', {
			user_id: profile.id,
			maintenance_report_id: object.id,
			inspection_id: (object as ApiMaintenanceReport).inspection.id,
			recipients: recipients.join(', '),
		});
	};

	const handleShare = () => {
		if (!recipients.length) {
			return;
		}

		setLoading(true);

		const sendTo = recipients.map((r) =>
			typeof r === 'string' ? (r as string) : (r as ApiTenant).emails[0]
		);

		createFileRequest(sendTo)
			.then(() => {
				trackAnalyticsEvent(sendTo);
				dispatch(SnackbarActions.showSuccess('Report shared successfully'));
				dispatch(ModalFlowActions.closeCurrentModal());
			})
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	if (!object || properties.length === 0) {
		return null;
	}

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle
				title="Share Maintenance Report"
				icon={solid('share')}
				onClose={handleClose}
				bottomSubtitle={formattedAddress(properties[0])}
			/>

			<DialogContent>
				<div style={{ minHeight: 350 }}>
					<RecipientsList
						label="Emails"
						placeholder="Enter emails separated by a comma"
						properties={properties}
						value={recipients}
						setValue={setRecipients}
						validOccupancyStatus={['current', 'future', 'past']}
					/>

					<Spacer height={10} />

					<FormTextField
						title="Message"
						multiline
						fullWidth
						maxRows={4}
						placeholder="Add a personal note"
						value={message}
						onChange={(e) => setMessage(e.target.value)}
						helperText={
							message ? 'Message will be included in email' : 'Optional'
						}
					/>
				</div>
			</DialogContent>

			<DialogActions>
				<Button variant="text" onClick={handleClose} color="secondary">
					Cancel
				</Button>
				<LoadingButton
					loading={loading}
					variant="contained"
					onClick={handleShare}>
					Share Report
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};
