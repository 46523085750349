import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
	APIProperty,
	ApiInspection,
	ApiInspectionTemplate,
	ApiInspectionTemplateDigest,
	ApiInspectionWithTemplate,
	ApiMaintenanceReport,
	ApiPermissionGroupDigest,
	ApiTeam,
	ApiTenant,
	ApiUserDigest,
	Feature,
	Inspection,
	MaintenanceFlag,
	SendReminderError,
} from '@rentcheck/types';
import _ from 'lodash';
import { actions } from '../actions/modal-flows';
import { FeatureWithMetadata } from './features';

export interface CloneInspectionMetadata {
	inspection: Inspection;
	property: APIProperty;
}

export interface CreateInspectionSuccessMetadata {
	inspection: Inspection;
}

export interface FlagFeatureMetadata {
	inspection: ApiInspection;
	feature: Feature;
	flag?: MaintenanceFlag;
	images?: string[];
	disableDelete?: boolean;
	disableCreateWorkOrder?: boolean;
}

export interface InviteUsersMetadata {
	teams: ApiTeam[];
}

export interface CreatePermissionGroupMetadata {
	permissionGroup?: ApiPermissionGroupDigest;
}

export interface ManageAccessMetadata {
	user: ApiUserDigest;
}

export interface ResidentMetadata {
	resident?: ApiTenant;
}

export interface CreateWorkOrderMetadata {
	provider: 'Latchel' | 'Yardi' | 'AppFolio' | 'Jenark' | 'Rent Manager';
	inspection: ApiInspection;
	flags: MaintenanceFlag[];
}

export interface CreateMaintenanceReportMetadata {
	inspection: ApiInspection;
	flags: MaintenanceFlag[];
}

export type CreateInspectionFlowType = 'unit' | 'building' | 'community';
export interface CreateInspectionMetadata {
	properties: APIProperty[];
	property_type: CreateInspectionFlowType;
}

export interface EditDueDateMetadata {
	inspections: ApiInspection[];
}

export interface EditRecipientsMetadata {
	inspection: ApiInspection;
}

export interface SendReminderMetadata {
	inspections: ApiInspection[];
}

export interface FeatureDetailsMetadata {
	inspection: ApiInspectionWithTemplate;
	features: FeatureWithMetadata[];
	initialFeature: FeatureWithMetadata;
}

export interface FeatureImageMetadata extends FeatureDetailsMetadata {
	initialIndex: number;
}

export interface FlagImagesMetadata {
	property: {
		id: string;
		zip_code: string;
		city: string;
		address: string;
	};
	flags: MaintenanceFlag[];
	initialFlag: MaintenanceFlag;
}

export interface EditFeatureMetadata {
	initialFeature: FeatureWithMetadata;
	features: FeatureWithMetadata[];
	inspection: ApiInspectionWithTemplate;
	mode: 'full-screen-image' | 'all-images';
	media?: string;

	initialScale?: number;
	initialPosition?: { x: number; y: number; oldX: number; oldY: number };
}

export interface InspectionRevisionMetadata {
	features: Feature[];
	inspection: ApiInspectionWithTemplate;
}

export interface CreateTeamMetadata {}

export interface RemoveFlagMetadata {
	flag: MaintenanceFlag;
}

export interface ShareMetadata<
	T extends ApiInspectionWithTemplate | ApiMaintenanceReport,
> {
	object: T;
}

export const createInspectionTemplateMetadataModes = [
	'unit',
	'building',
	'community',
] as const;
export interface CreateInspectionTemplateMetadata {
	/**
	 * When set this will trigger the modal to be in edit mode
	 */
	template?: ApiInspectionTemplate;

	/**
	 * When set this will trigger the modal to be in clone mode
	 */
	baseTemplate?: ApiInspectionTemplate;

	mode: (typeof createInspectionTemplateMetadataModes)[number];
}

export interface ShowInspectionTemplateMetadata {
	template: ApiInspectionTemplateDigest;
	editMode?: boolean;
}

export interface ShowRevisionNotesMetadata {
	feature: Feature;
}
export interface ShowEditUsersPermissionGroupsMetadata {
	user_ids: string[];
}

export interface RequestSignaturesMetadata {
	inspection: ApiInspection;
}

export interface InspectionTimelineMetadata {
	inspection: ApiInspection;
}

export type ModalFlowData =
	| CloneInspectionMetadata
	| CreateInspectionSuccessMetadata
	| FlagFeatureMetadata
	| CreateWorkOrderMetadata
	| CreateMaintenanceReportMetadata
	| EditDueDateMetadata
	| SendReminderMetadata
	| FeatureDetailsMetadata
	| FlagImagesMetadata
	| EditFeatureMetadata
	| CreateTeamMetadata
	| RemoveFlagMetadata
	| ResidentMetadata
	| ShowRevisionNotesMetadata
	| RequestSignaturesMetadata
	| InspectionTimelineMetadata
	| null;

export interface ConfirmationModalButton {
	title: string;
	color?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
	variant?: 'text' | 'outlined' | 'contained';
	onClick: () => void;
}

export interface ConfirmationModalData {
	title: string;
	body1: string[];
	body2?: string[];
	buttons: ConfirmationModalButton[];
	loading?: boolean;
	image?: string;
	icon?: IconProp;
}

export interface ErrorModalData {
	title: string;
	errors: SendReminderError[];
}

export const modalTypes = [
	'Create Inspection',
	'Create Work Order Latchel',
	'Create Work Order Yardi',
	'Create Work Order AppFolio',
	'Create Work Order Jenark',
	'Create Work Order Rent Manager',
	'Create Maintenance Report',
	'Create Permission Group',
	'Create Team',
	'Flag Feature',
	'Edit Due Date',
	'Send Reminder',
	'Feature Details',
	'Feature Images',
	'Flag Images',
	'Edit Feature',
	'Edit Recipients',
	'Edit Users Permission Groups',
	'Inspection Revision',
	'Invite Users',
	'Inspection Timeline',
	'Manage Access',
	'Resident',
	'Share Inspection',
	'Share Maintenance Report',
	'Upgrade Subscription',
	'Create Inspection Template',
	'View Inspection Template',
	'Revision Notes',
	'Request Signatures',
] as const;

export type ModalType = (typeof modalTypes)[number];

export type Modal = {
	type: ModalType | null;
	metadata: ModalFlowData;
};

export interface State {
	modals: Modal[];
	confirmation_modal: ConfirmationModalData | null;
	error_modal: ErrorModalData | null;
}

export type Action = {
	type: string;
	data: ModalFlowData;
	value?: boolean;
	features?: Feature[];
	tenants?: ApiTenant[];
};

const defaultState = {
	modals: [],
	confirmation_modal: null,
	error_modal: null,
};

const mergeFlagDataIntoModalState = (modal: Modal, features: Feature[]) => {
	const metadata = modal.metadata as { flags: MaintenanceFlag[] };
	const flagsInUpdate = _.flatten(
		features
			.filter((f) => f.maintenance_flags)
			.map((feature) => feature.maintenance_flags)
	) as MaintenanceFlag[];

	metadata.flags = metadata.flags.map(
		(originalFlag) =>
			flagsInUpdate.find((f) => f.id === originalFlag.id) ?? originalFlag
	);

	return;
};

const removeFlagDataFromModalState = (modal: Modal, flag: MaintenanceFlag) => {
	const metadata = modal.metadata as { flags: MaintenanceFlag[] };
	metadata.flags = metadata.flags.filter((f) => f.id !== flag.id);
};

const pushModalIntoState = (modals: Modal[], modal: Modal) => {
	return [...modals, modal];
};

const popModalFromState = (modals: Modal[]) => {
	if (modals.length === 0) {
		return modals;
	}

	return [..._.take(modals, modals.length - 1)];
};

export default (
	state: State = defaultState,
	{ type, data, value, features, tenants }: Action
) => {
	switch (type) {
		case actions.SHOW_CREATE_INSPECTION:
			return {
				...state,
				modals: pushModalIntoState(state.modals, {
					type: 'Create Inspection',
					metadata: data,
				}),
			};
		case actions.SHOW_FLAG_FEATURE:
			return {
				...state,
				modals: pushModalIntoState(state.modals, {
					type: 'Flag Feature',
					metadata: data,
				}),
			};
		case actions.SHOW_RESIDENT:
			return {
				...state,
				modals: pushModalIntoState(state.modals, {
					type: 'Resident',
					metadata: data,
				}),
			};
		case actions.SHOW_CREATE_WORK_ORDER_LATCHEL:
			return {
				...state,
				modals: pushModalIntoState(state.modals, {
					type: 'Create Work Order Latchel',
					metadata: data,
				}),
			};
		case actions.SHOW_CREATE_WORK_ORDER_YARDI:
			return {
				...state,
				modals: pushModalIntoState(state.modals, {
					type: 'Create Work Order Yardi',
					metadata: data,
				}),
			};
		case actions.SHOW_CREATE_WORK_ORDER_APPFOLIO:
			return {
				...state,
				modals: pushModalIntoState(state.modals, {
					type: 'Create Work Order AppFolio',
					metadata: data,
				}),
			};
		case actions.SHOW_CREATE_WORK_ORDER_JENARK:
			return {
				...state,
				modals: pushModalIntoState(state.modals, {
					type: 'Create Work Order Jenark',
					metadata: data,
				}),
			};
		case actions.SHOW_CREATE_WORK_ORDER_RENT_MANAGER:
			return {
				...state,
				modals: pushModalIntoState(state.modals, {
					type: 'Create Work Order Rent Manager',
					metadata: data,
				}),
			};
		case actions.SHOW_CREATE_MAINTENANCE_REPORT:
			return {
				...state,
				modals: pushModalIntoState(state.modals, {
					type: 'Create Maintenance Report',
					metadata: data,
				}),
			};
		case actions.SHOW_EDIT_DUE_DATE:
			return {
				...state,
				modals: pushModalIntoState(state.modals, {
					type: 'Edit Due Date',
					metadata: data,
				}),
			};
		case actions.SHOW_EDIT_RECIPIENTS:
			return {
				...state,
				modals: pushModalIntoState(state.modals, {
					type: 'Edit Recipients',
					metadata: data,
				}),
			};
		case actions.SHOW_SEND_REMINDER:
			return {
				...state,
				modals: pushModalIntoState(state.modals, {
					type: 'Send Reminder',
					metadata: data,
				}),
			};
		case actions.SHOW_FEATURE_DETAILS:
			return {
				...state,
				modals: pushModalIntoState(state.modals, {
					type: 'Feature Details',
					metadata: data,
				}),
			};
		case actions.SHOW_FEATURE_IMAGE:
			return {
				...state,
				modals: pushModalIntoState(state.modals, {
					type: 'Feature Images',
					metadata: data,
				}),
			};
		case actions.SHOW_FLAG_IMAGES:
			return {
				...state,
				modals: pushModalIntoState(state.modals, {
					type: 'Flag Images',
					metadata: data,
				}),
			};
		case actions.SHOW_EDIT_FEATURE:
			return {
				...state,
				modals: pushModalIntoState(state.modals, {
					type: 'Edit Feature',
					metadata: data,
				}),
			};
		case actions.SHOW_INSPECTION_REVISION:
			return {
				...state,
				modals: pushModalIntoState(state.modals, {
					type: 'Inspection Revision',
					metadata: data,
				}),
			};
		case actions.SHOW_UPGRADE_SUBSCRIPTION_MODAL:
			return {
				...state,
				modals: pushModalIntoState(state.modals, {
					type: 'Upgrade Subscription',
					metadata: data,
				}),
			};
		case actions.SHOW_SHARE_INSPECTION_MODAL:
			return {
				...state,
				modals: pushModalIntoState(state.modals, {
					type: 'Share Inspection',
					metadata: data,
				}),
			};
		case actions.SHOW_SHARE_MAINTENANCE_REPORT_MODAL:
			return {
				...state,
				modals: pushModalIntoState(state.modals, {
					type: 'Share Maintenance Report',
					metadata: data,
				}),
			};
		case actions.SHOW_CREATE_TEAM:
			return {
				...state,
				modals: pushModalIntoState(state.modals, {
					type: 'Create Team',
					metadata: data,
				}),
			};
		case actions.SHOW_INVITE_USERS:
			return {
				...state,
				modals: pushModalIntoState(state.modals, {
					type: 'Invite Users',
					metadata: data,
				}),
			};
		case actions.SHOW_EDIT_USERS_PERMISSION_GROUPS:
			return {
				...state,
				modals: pushModalIntoState(state.modals, {
					type: 'Edit Users Permission Groups',
					metadata: data,
				}),
			};
		case actions.SHOW_INSPECTION_TIMELINE:
			return {
				...state,
				modals: pushModalIntoState(state.modals, {
					type: 'Inspection Timeline',
					metadata: data,
				}),
			};
		case actions.SHOW_REQUEST_SIGNATURES:
			return {
				...state,
				modals: pushModalIntoState(state.modals, {
					type: 'Request Signatures',
					metadata: data,
				}),
			};
		case actions.SHOW_MANAGE_ACCESS:
			return {
				...state,
				modals: pushModalIntoState(state.modals, {
					type: 'Manage Access',
					metadata: data,
				}),
			};
		case actions.SHOW_CREATE_INSPECTION_TEMPLATE:
			return {
				...state,
				modals: pushModalIntoState(state.modals, {
					type: 'Create Inspection Template',
					metadata: data,
				}),
			};
		case actions.SHOW_CREATE_PERMISSION_GROUP:
			return {
				...state,
				modals: pushModalIntoState(state.modals, {
					type: 'Create Permission Group',
					metadata: data,
				}),
			};
		case actions.SHOW_INSPECTION_TEMPLATE:
			return {
				...state,
				modals: pushModalIntoState(state.modals, {
					type: 'View Inspection Template',
					metadata: data,
				}),
			};
		case actions.SHOW_REVISION_NOTES:
			return {
				...state,
				modals: pushModalIntoState(state.modals, {
					type: 'Revision Notes',
					metadata: data,
				}),
			};

		case actions.CLOSE_CURRENT:
			return { ...state, modals: popModalFromState(state.modals) };

		case actions.SHOW_CONFIRMATION_MODAL:
			return { ...state, confirmation_modal: data };
		case actions.CLOSE_CONFIRMATION_MODAL:
			return { ...state, confirmation_modal: null };
		case actions.CONFIRMATION_MODAL_LOADING:
			if (!state.confirmation_modal) return state;
			return {
				...state,
				confirmation_modal: {
					...state.confirmation_modal,
					loading: value,
				},
			};

		case actions.SHOW_ERROR_MODAL:
			return { ...state, error_modal: data };
		case actions.CLOSE_ERROR_MODAL:
			return { ...state, error_modal: null };

		/**
		 * Tenant Creation modal can set its own tenant
		 * that then needs to be updated in the state
		 */
		case 'UPDATE_TENANTS':
		case 'CREATE_TENANTS': {
			if (!tenants || tenants.length === 0) {
				return state;
			}

			const updatedState = { ...state };
			const newTenant = _.first(tenants) as ApiTenant;

			updatedState.modals.forEach((modal) => {
				if (!modal.type) {
					return;
				}

				if (modal.type === 'Resident') {
					(modal.metadata as ResidentMetadata).resident = newTenant;
				}
			});

			return updatedState;
		}

		case actions.REMOVE_FLAG_FROM_MODALS: {
			const flag = (data as RemoveFlagMetadata).flag;

			if (!flag) return state;

			const updatedState = { ...state };

			updatedState.modals.forEach((modal) => {
				if (
					modal.type === 'Create Work Order Latchel' ||
					modal.type === 'Create Work Order Yardi' ||
					modal.type === 'Create Work Order AppFolio' ||
					modal.type === 'Create Work Order Rent Manager'
				)
					removeFlagDataFromModalState(modal, flag);
			});

			return updatedState;
		}

		/**
		 * Work Order creation, Maintenance Report Creation,
		 * Image Carousel && Edit Feature
		 */
		case 'UPDATE_FEATURES': {
			if (!features) return state;

			const updatedState = { ...state };

			updatedState.modals.forEach((modal) => {
				if (!modal.type) {
					return;
				}

				if (modal.type === 'Create Maintenance Report') {
					mergeFlagDataIntoModalState(modal, features);
				}

				// Making this comparison with || instead
				// of startsWith() or includes() to get better type checking
				if (
					modal.type === 'Create Work Order Latchel' ||
					modal.type === 'Create Work Order Yardi' ||
					modal.type === 'Create Work Order AppFolio' ||
					modal.type === 'Create Work Order Rent Manager'
				) {
					mergeFlagDataIntoModalState(modal, features);
				}

				if (
					modal.type === 'Feature Details' ||
					modal.type === 'Feature Images' ||
					modal.type === 'Inspection Revision'
				) {
					const metadata = modal.metadata as { features: Feature[] };

					metadata.features = metadata.features.map((f) => {
						const updatedFeature = features.find(
							(feature) => f.id === feature.id
						);
						return updatedFeature ?? f;
					});
				}

				if (modal.type === 'Edit Feature') {
					const metadata = modal.metadata as { features: Feature[] };

					metadata.features = metadata.features.map((f) => {
						const updatedFeature = features.find(
							(feature) => f.id === feature.id
						);
						return updatedFeature ?? f;
					});
				}
			});

			return updatedState;
		}

		default:
			return state;
	}
};
