import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardContent, Typography } from '@mui/material';
import { IntercomAPI } from 'react-intercom';
import { useHistory } from 'react-router-dom';

import { Row, Spacer } from 'components';
import { colors } from 'theme';

interface Props {
	title?: string;
	learnMoreLink: string;
	parentUrl: string;
	waitForConnection?: boolean;
}

const InstalledContent = ({
	title,
	learnMoreLink,
	parentUrl,
	waitForConnection,
}: Props) => {
	const history = useHistory();

	const handleNeedHelp = () => {
		IntercomAPI('showNewMessage');
	};

	const handleLearnMore = () => {
		window.open(learnMoreLink, '_blank');
	};

	const handleDataSync = () => {
		history.push(parentUrl + '/data-sync');
	};

	return (
		<Card sx={{ marginBottom: 2 }}>
			<CardContent>
				{title && (
					<Typography variant="subtitle1" mb={2}>
						{title}
					</Typography>
				)}
				{!waitForConnection && (
					<Row onClick={handleDataSync} style={{ marginBottom: 8 }}>
						<FontAwesomeIcon
							icon={regular('gears')}
							color={colors.primary}
							width={20}
						/>

						<Spacer width={3} />

						<Typography sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
							Data Sync Settings
						</Typography>
					</Row>
				)}

				<Row onClick={handleLearnMore}>
					<FontAwesomeIcon
						icon={regular('info-circle')}
						color={colors.primary}
						width={20}
					/>

					<Spacer width={3} />

					<Typography sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
						Learn More
					</Typography>
				</Row>
				<Spacer height={2} />

				<Row onClick={handleNeedHelp}>
					<FontAwesomeIcon
						icon={regular('comment')}
						color={colors.primary}
						width={20}
					/>

					<Spacer width={3} />

					<Typography sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
						Need Help?
					</Typography>
				</Row>
			</CardContent>
		</Card>
	);
};

export default InstalledContent;
