import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { LoadingButton } from '@mui/lab';
import { Button, TextField } from '@mui/material';
import { AccountSettings, CustomRoom } from '@rentcheck/types';
import { ResponsiveSpacedRow, Row, Spacer } from 'components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	AccountSettingsActions,
	ModalFlowActions,
	SnackbarActions,
} from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';
import { defaultRoomsList } from './constants';

interface Props {
	room: CustomRoom;
	variant?: keyof AccountSettings['rooms'];

	setEditing: (editing: boolean) => void;
	onRoomCreated: (room: CustomRoom) => void;
}

export default ({ setEditing, room, variant, onRoomCreated }: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const accountSettings = useTypedSelector((state) => state.accountSettings);

	const [name, setName] = useState(room.name);
	const [error, setError] = useState(false);
	const [hasTyped, setHasTyped] = useState(room.name.length > 0);
	const [saveLoading, setSaveLoading] = useState(false);

	const existingRoomNames = [
		...(accountSettings?.rooms[variant ?? 'unit']
			.filter((r) => r !== room)
			.map((r) => r.name) ?? []),
		...defaultRoomsList[variant ?? 'unit'],
	];

	useEffect(() => {
		setName(room.name);
	}, [room]);

	useEffect(() => {
		setError(
			existingRoomNames.includes(name) || (hasTyped && name.length === 0)
		);
	}, [name]);

	const handleSave = async () => {
		if (!accountSettings) return;
		if (!variant) return;
		if (!name.trim()) return;

		if (existingRoomNames.includes(name)) {
			dispatch(
				SnackbarActions.showError('A room with that name already exists')
			);
			return;
		}

		setSaveLoading(true);

		const updatedRooms = accountSettings.rooms;

		/**
		 * We're editing an existing room, so we need to update the
		 * object in the array, not just push a new one.
		 */
		if (room.name) {
			updatedRooms[variant] = updatedRooms[variant].map((r) =>
				r === room ? { ...r, name } : r
			);
		}

		/**
		 * We're creating a new room, prepend it to the array.
		 */
		if (!room.name) {
			updatedRooms[variant].unshift({ ...room, name });
		}

		dispatch(AccountSettingsActions.updateRooms(updatedRooms))
			.then(() => {
				!room.name && onRoomCreated(room);
				setEditing(false);
			})
			.catch((e) => dispatch(SnackbarActions.showError(e)))
			.finally(() => setSaveLoading(false));
	};

	const handleCancel = () => {
		setEditing(false);

		/**
		 * When cancelling we call onRoomCreated even if we didn't create a new
		 * one as this function removes the room from the new rooms array
		 */
		!room.name && onRoomCreated(room);
	};

	const handleConfirmDelete = () => {
		dispatch(
			ModalFlowActions.showConfirmationModal({
				icon: solid('trash'),
				title: 'Delete Room',
				body1: [
					'Are you sure you want to delete this room?',
					`Deleting ${room.name} will remove ${room.name} from all inspection templates associated with your subscription.`,
				],
				buttons: [
					ModalFlowActions.cancelButton(dispatch),
					{
						title: 'Delete',
						color: 'error',
						variant: 'contained',
						onClick: handleDelete,
					},
				],
			})
		);
	};

	const handleDelete = () => {
		if (!variant) return;

		dispatch(ModalFlowActions.setConfirmationModalLoading(true));

		dispatch(
			AccountSettingsActions.deleteRooms({
				property_type: variant,
				rooms: [room],
			})
		)
			.catch((e) => dispatch(SnackbarActions.showError(e)))
			.finally(() => dispatch(ModalFlowActions.closeConfirmationModal()));
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter') {
			return handleSave();
		}
	};

	return (
		<ResponsiveSpacedRow variant="end">
			<TextField
				fullWidth
				autoFocus
				variant="filled"
				label="Room / Area Name"
				placeholder="Add Room/Area Name"
				helperText={`${name.length}/25 Characters`}
				value={name}
				onChange={(e) => {
					setName(e.target.value.slice(0, 25));
					setHasTyped(true);
				}}
				onKeyPress={handleKeyPress}
				error={error}
			/>
			<Spacer width={4} />
			<Row>
				<LoadingButton
					sx={{ marginRight: 1 }}
					onClick={handleSave}
					loading={saveLoading}>
					Save
				</LoadingButton>
				<Button
					variant="outlined"
					sx={{ marginRight: 1 }}
					color="secondary"
					onClick={handleCancel}>
					Cancel
				</Button>
				{room.name && (
					<LoadingButton
						variant="outlined"
						color="secondary"
						onClick={handleConfirmDelete}>
						Delete
					</LoadingButton>
				)}
			</Row>
		</ResponsiveSpacedRow>
	);
};
