import { Accordion } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { integrations } from 'assets';
import { useTypedSelector } from 'store/reducers/rootReducer';

import { Permissions } from '@rentcheck/biz';
import { useAppFolioIntegration } from 'hooks/appfolio-integration';
import Content from '../common/content';
import Header from '../common/header';
import Active from './active';
import Inactive from './inactive';

const headerProps = {
	icon: integrations.appFolio,
	title: 'AppFolio',
	body: 'Import and sync property and resident data from AppFolio to RentCheck. Send completed inspection reports back to AppFolio and create work orders in AppFolio automatically from RentCheck inspections.',
	route: '/account/integrations/appfolio',
};

const AppFolio = () => {
	const location = useLocation();

	const subscription = useTypedSelector((state) => state.subscription);
	const { appFolioIntegration } = useAppFolioIntegration();

	const expanded = location.pathname.startsWith(headerProps.route);

	const canInstall = Permissions.Integrations.AppFolio.canInstall(subscription);

	return (
		<Accordion expanded={expanded}>
			<Header {...headerProps} active={!!appFolioIntegration} />
			<Content>
				{canInstall && <Active />}
				{!canInstall && <Inactive />}
			</Content>
		</Accordion>
	);
};

export default AppFolio;
