import { GetAllMaintenanceReportsParams } from '@rentcheck/types';
import _ from 'lodash';

export interface DateRange {
	start: number;
	end: number;
}

export interface State {
	dateRange?: DateRange;
	inspectionTemplate: string[];
	search: string;

	sortBy: string;
	sortType: GetAllMaintenanceReportsParams['options']['sort']['sort_type'];
}

const initialState: State = {
	sortBy: 'created_date',
	sortType: 'DESC',
	dateRange: undefined,
	search: '',
	inspectionTemplate: [],
};

const resetState: Partial<State> = {
	dateRange: undefined,
	inspectionTemplate: [],
};

type Action = {
	type: string;
	value?: string;
	dateRange: DateRange;
};

const reducer = (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case 'SIGNOUT_SUCCESS':
		case 'IMPERSONATE':
			return initialState;

		case 'TOGGLE_REPORTS_INSPECTION_TYPE_FILTER':
			if (!action.value) return state;
			return {
				...state,
				inspectionTemplate: _.xor(state.inspectionTemplate, [action.value]),
			};

		case 'SET_REPORTS_DATE_RANGE_FILTER':
			return { ...state, dateRange: action.dateRange };

		case 'TOGGLE_REPORTS_SORT_TYPE':
			return { ...state, sortType: state.sortType === 'ASC' ? 'DESC' : 'ASC' };

		case 'SET_REPORTS_SORT_BY':
			if (!action.value) return state;
			return { ...state, sortBy: action.value, sortType: 'ASC' };

		case 'SET_REPORTS_SEARCH_FILTER':
			return { ...state, search: action.value ?? '' };

		case 'RESET_REPORTS_FILTERS':
			return { ...state, ...resetState };

		default:
			return state;
	}
};

export const appliedFiltersCount = (state: State) => {
	return [!!state.dateRange, state.inspectionTemplate.length].filter((f) => f)
		.length;
};

export const hasAppliedFilters = (state: State) => {
	return appliedFiltersCount(state) > 0 || state.search.length > 0;
};

export default reducer;
