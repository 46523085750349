import { PropertiesApi } from '@rentcheck/api-frontend';
import { APIProperty } from '@rentcheck/types';

import { useEffect, useState } from 'react';

export const useProperty = <T extends APIProperty = APIProperty>(
	propertyId?: string
) => {
	const [property, setProperty] = useState<T>();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (!propertyId) {
			setProperty(undefined);
			setLoading(false);
			return;
		}

		setLoading(true);

		PropertiesApi.getById(propertyId)
			.then((data) => setProperty(data as T))
			.catch(() => {})
			.finally(() => setLoading(false));
	}, [propertyId]);

	return { property, loading };
};
