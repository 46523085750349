import { SxProps } from '@mui/material';

export const createSx = (
	selected: boolean,
	iconColor: string,
	backgroundColor: string,
	hoverColor: string
): SxProps => {
	return {
		fontWeight: 500,
		backgroundColor: backgroundColor,
		borderColor: selected ? iconColor : backgroundColor,
		marginRight: 1,
		marginTop: 0.5,
		marginBottom: 0.5,

		'.MuiChip-icon': {
			color: iconColor,
		},

		'&&:hover': {
			backgroundColor: hoverColor,
		},
	};
};
