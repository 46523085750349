import { useDispatch } from 'react-redux';
import { DateRange } from 'store/reducers/inspections/filters';
import { actionType, icon, useFilter } from './common';

import DateRangeFilterChip, {
	dateRangePresets,
} from 'components/table/filter-button-v2/components/filter-chip/date-range';

export default () => {
	const dispatch = useDispatch();
	const filter = useFilter();

	const handleSetValue = (value: DateRange | undefined) => {
		dispatch({
			type: actionType,
			dateRange: value,
		});
	};

	return (
		<DateRangeFilterChip
			title="Due Date"
			icon={icon}
			value={filter}
			setValue={handleSetValue}
			presets={[dateRangePresets.thisWeek, dateRangePresets.thisMonth]}
		/>
	);
};
