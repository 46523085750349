import { InspectionsApi } from '@rentcheck/api-frontend';
import { Constants } from '@rentcheck/biz';
import { ApiInspectionSkill } from '@rentcheck/types';
import { useEffect, useState } from 'react';

const hasSkillsForVendor = (
	skills: ApiInspectionSkill[],
	vendor: ApiInspectionSkill['vendor']
): boolean => {
	return skills.some((skill) => skill.vendor === vendor);
};

const getAuxiliaryVariables = (
	skills: ApiInspectionSkill[]
): { title: string; helperText: string } => {
	if (hasSkillsForVendor(skills, 'Latchel')) {
		return { title: 'Skill', helperText: '' };
	}

	if (hasSkillsForVendor(skills, 'AppFolio')) {
		return {
			title: 'Vendor Trade',
			helperText:
				'Select a vendor trade. This field maps to the work order field ‘Vendor Trade’ in AppFolio.',
		};
	}

	if (hasSkillsForVendor(skills, 'Jenark')) {
		return {
			title: 'Code',
			helperText: 'These are your maintenance codes in Jenark.',
		};
	}

	if (hasSkillsForVendor(skills, 'Rent Manager')) {
		return {
			title: 'Category',
			helperText: 'These are your service issue categories in Rent Manager.',
		};
	}

	/**
	 * If none of the above we return a default value
	 */
	return { title: 'Skill', helperText: '' };
};

export const useSkills = (inspectionId?: string) => {
	const [title, setTitle] = useState('Skill');
	const [helperText, setHelperText] = useState('');
	const [loading, setLoading] = useState(false);
	const [skills, setSkills] = useState<ApiInspectionSkill[]>(
		Constants.Skills.rentCheckSkills
	);

	useEffect(() => {
		if (!inspectionId) {
			return;
		}

		setLoading(true);

		InspectionsApi.getMaintenanceSkills(inspectionId)
			.then((data) => {
				const { title, helperText } = getAuxiliaryVariables(data);

				setSkills(data);
				setTitle(title);
				setHelperText(helperText);
			})
			.catch(() => {
				setSkills(Constants.Skills.rentCheckSkills);
			})
			.finally(() => setLoading(false));
	}, []);

	return { skills, loading, title, helperText };
};

export const getCategoryAndSubcategoryFromSkill = (skill: string) => {
	const components = skill.split(': ');
	const category = components.shift() ?? '';
	const subcategory = components.join(': ');

	return { category, subcategory };
};

export const getWorkOrderCodeAndDescriptionFromSkill = (skill: string) => {
	const components = skill.split(' (');
	const code = components.shift() ?? '';
	const description = (components.shift() ?? '').replaceAll(/[()]/g, '');

	return { code, description };
};
