import { Typography } from '@mui/material';
import { emptyStates } from 'assets';
import { CenteredColumn, Spacer } from 'components';
import styled from 'styled-components';
import { spacingPx } from 'theme';

interface Props {
	type: 'workOrders' | 'reports';
}

const EmptyState = ({ type }: Props) => {
	const reportType = type === 'reports';
	return (
		<Container>
			<Spacer height={15} />
			<EmptyStateImage
				src={reportType ? emptyStates.maintenance : emptyStates.workOrders}
				alt="empty-state"
			/>
			<Spacer height={4} />
			<Typography sx={{ maxWidth: 600, fontWeight: 'bold' }}>
				Create {reportType ? 'Maintenance Reports' : 'Work Orders'} from issues
				flagged on inspections.
			</Typography>
		</Container>
	);
};

const EmptyStateImage = styled.img`
	height: 35vh;
	resize-mode: contain;
`;

const Container = styled(CenteredColumn)`
	padding: 0 ${spacingPx(40)};
`;

export default EmptyState;
