import { GetAllUsersV2Params } from '@rentcheck/types';
import {
	FilterDetailsProps,
	FilterTitle,
	FilterTitleOption,
	FilterTitleProps,
	FilterType,
} from 'components/lists/filters';
import { useDispatch } from 'react-redux';
import { TeammatesActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';

const title: FilterType = 'User Status';

export const FilterUserStatusTitle = ({
	selectedFilter,
	setFilterDetails,
}: FilterTitleProps) => {
	const filters = useTypedSelector((state) => state.teammates.usersFilters);

	return (
		<FilterTitle
			label={title}
			isActive={!!filters.status}
			isSelected={selectedFilter === title}
			onClick={() => setFilterDetails(title)}
		/>
	);
};

export const FilterUserStatusDetails = ({
	selectedFilter,
}: FilterDetailsProps) => {
	const dispatch = useDispatch();

	const filters = useTypedSelector((state) => state.teammates.usersFilters);

	if (selectedFilter !== title) {
		return null;
	}

	const handleFilterChanged = (
		value: GetAllUsersV2Params['filters']['status']
	) => {
		dispatch(TeammatesActions.Users.setFilters({ status: value }));
	};

	return (
		<>
			<FilterTitleOption
				label={'Active'}
				isSelected={filters.status === 'Active'}
				onClick={() => handleFilterChanged('Active')}
			/>
			<FilterTitleOption
				label={'Invited'}
				isSelected={filters.status === 'Invited'}
				onClick={() => handleFilterChanged('Invited')}
			/>
		</>
	);
};
