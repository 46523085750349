import { Row, SpacedRow, Spacer } from 'components';
import QueryTotals from 'components/lists/query-totals';
import SearchBar from 'components/table/search';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { MaintenanceActions, SnackbarActions } from 'store/actions';
import { RootState, useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';

import Skeleton from '../common/skeleton';
import FiltersButton from './filters';
import Table from './table';

const WorkOrdersList = () => {
	const dispatch: Dispatch = useDispatch();

	const filters = useTypedSelector(
		(state) => state.maintenance.filters.workOrders
	);

	const [initialLoading, setInitialLoading] = useState(true);

	useEffect(() => {
		dispatch(MaintenanceActions.workOrders.getAll(0, filters as any))
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => {
				setInitialLoading(false);
			});
	}, []);

	const searchExtractor = (state: RootState) => {
		return state.maintenance.filters.workOrders.search;
	};

	const totalExtractor = (state: RootState) => {
		return state.maintenance.workOrders.total;
	};

	if (initialLoading) {
		return <Skeleton />;
	}

	return (
		<div id="work-orders-table">
			<SpacedRow>
				<QueryTotals totalExtractor={totalExtractor} />
				<Row>
					<SearchBar stateExtractor={searchExtractor} type="work orders" />
					<Spacer width={2} />
					<FiltersButton />
				</Row>
			</SpacedRow>
			<Spacer height={6} />
			<Table />
		</div>
	);
};

export default WorkOrdersList;
