import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
	ApiInspectionEvent,
	InspectionEventReminderSentMetadata,
} from '@rentcheck/types';

import BaseTimelineEvent from '../base-event';

interface Props {
	event: ApiInspectionEvent;
}

export default ({ event }: Props) => {
	const metadata = event.metadata as InspectionEventReminderSentMetadata;

	return (
		<BaseTimelineEvent
			icon={solid('pen-swirl')}
			title="Inspection Review Requested"
			subtitle={`Sent by ${metadata.sent_by}`}
			timestamp={event.timestamp}
			sentTo={metadata.emails_sent}
			note={metadata.note}
		/>
	);
};
