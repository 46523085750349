import { Paper, Table, TableBody, TableContainer } from '@mui/material';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PropertiesActions, SnackbarActions } from 'store/actions';

import { InfiniteScroll } from 'components';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';

import FiltersSkeleton from '../../common/skeleton-filters';
import TableHeader from './header';
import TableRow from './row';

import {
	hasAppliedFilters,
	State as FiltersState,
} from 'store/reducers/properties/filters/units';
import EmptyState from '../../common/empty-state';

interface Props {
	selectedIds: string[];
	setSelectedIds: (ids: string[]) => void;
}

const UnitsTable = ({ selectedIds, setSelectedIds }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const units = useTypedSelector((state) => state.properties.units.data);
	const filters = useTypedSelector((state) => state.properties.filters.units);

	const [skippedFirstFetch, setSkippedFirstFetch] = useState(false);
	const [loadingFilters, setLoadingFilters] = useState(false);

	useEffect(() => {
		if (!skippedFirstFetch) {
			setSkippedFirstFetch(true);
			return;
		}

		fetchUnits(filters);
	}, [filters]);

	const debouncedFetch = _.debounce((filters: FiltersState) => {
		setSelectedIds([]);
		setLoadingFilters(true);

		dispatch(PropertiesActions.units.getAll(0, filters))
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => {
				setLoadingFilters(false);
			});
	}, 300);

	const fetchUnits = useCallback((filters: FiltersState) => {
		setLoadingFilters(true);
		debouncedFetch(filters);
	}, []);

	const handleSelectAll = () => {
		if (selectedIds.length === units.length) {
			return setSelectedIds([]);
		}

		setSelectedIds(units.map((i) => i.id));
	};

	const handleSelectId = (id: string) => {
		setSelectedIds(_.xor(selectedIds, [id]));
	};

	if (units.length === 0 && !hasAppliedFilters(filters) && !loadingFilters) {
		return <EmptyState propertyType="Units" />;
	}

	return (
		<div>
			<TableContainer component={Paper}>
				<FiltersSkeleton loading={loadingFilters} />
				<Table sx={{ minWidth: 650 }}>
					<TableHeader
						selectedIds={selectedIds}
						setSelectedIds={setSelectedIds}
						selectAllIds={handleSelectAll}
						units={units}
					/>
					<TableBody>
						{units.map((row) => (
							<TableRow
								key={row.id}
								unit={row}
								selectedIds={selectedIds}
								setSelectedIds={setSelectedIds}
								selectId={handleSelectId}
							/>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			{!loadingFilters && (
				<InfiniteScroll
					objectName="Units"
					filterState={filters}
					action={PropertiesActions.units}
					offset={units.length}
				/>
			)}
		</div>
	);
};

export default UnitsTable;
