import { Chip } from '@mui/material';
import { colors } from 'theme';
import typography from 'theme/typography';
import { testId } from 'utils/helpers';

interface Props {
	active?: boolean;
	title: string;
}

const BetaChip = ({ active, title }: Props) => {
	if (!active) return null;

	return (
		<Chip
			id={`beta-badge-${testId(title)}`}
			label="Beta"
			sx={{
				boxShadow: '0 0 4px #979797',
				marginLeft: 2,
				backgroundColor: '#445567',
				...typography.MuiTypography.styleOverrides.body2,
				color: colors.white,
			}}
		/>
	);
};

export default BetaChip;
