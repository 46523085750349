import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Typography } from '@mui/material';
import { ApiTenant } from '@rentcheck/types';
import { Column, SpacedRow } from 'components';
import IconButton from 'components/icon-button';
import _ from 'lodash';

import { Recipient } from './common';

interface Props {
	recipient: Recipient;
	recipients: Recipient[];
	setRecipients: (value: Recipient[]) => void;
}

export default ({ recipient, recipients, setRecipients }: Props) => {
	const handleDelete = () => {
		setRecipients(recipients.filter((r) => r !== recipient));
	};

	const tenantValue = _.isString(recipient)
		? undefined
		: (recipient as ApiTenant);

	const title = tenantValue
		? `${tenantValue.first_name} ${tenantValue.last_name}`
		: undefined;
	const subtitle = tenantValue?.emails[0] ?? (recipient as string);

	return (
		<SpacedRow>
			<Column style={{ padding: '12px 16px' }} color="#000000DE">
				<Typography>{title}</Typography>
				<Typography variant="body2">{subtitle}</Typography>
			</Column>
			<IconButton icon={solid('times')} onClick={handleDelete} />
		</SpacedRow>
	);
};
