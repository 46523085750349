import { Menu, MenuItem, Typography } from '@mui/material';
import React, { useState } from 'react';

import { Feature, FeatureRating } from '@rentcheck/types';

import { Spacer } from 'components';
import RatingChip from 'screens/inspections/details/content/completed/list/row/rating';

interface Props {
	feature: Feature;
	featurePayload: Partial<Feature>;
	setFeaturePayload: (payload: Partial<Feature>) => void;
}

export default ({ feature, featurePayload, setFeaturePayload }: Props) => {
	const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

	const ratingOptions: FeatureRating[] = ['Good', 'Fair', 'Poor'];

	const handleOpenMenu = (e: React.MouseEvent<HTMLElement>) => {
		setAnchorElement(e.currentTarget);
	};

	const handleSelectRating = (rating: FeatureRating) => {
		setFeaturePayload({
			...featurePayload,
			rating,
		});
	};

	const handleMenuClose = () => {
		setAnchorElement(null);
	};

	if (!feature.rating) return null;

	return (
		<>
			<Typography variant="overline">Rating</Typography>

			<RatingChip
				feature={{ ...feature, ...featurePayload }}
				onClick={handleOpenMenu}
				editable
				fullWidth
			/>

			<Menu
				autoFocus={false}
				open={!!anchorElement}
				anchorEl={anchorElement}
				onClose={handleMenuClose}
				onClick={() => {
					setAnchorElement(null);
				}}>
				{ratingOptions.map((rating) => (
					<MenuItem
						id={`menu-item-${rating}`}
						sx={{ minWidth: 300 }}
						onClick={() => handleSelectRating(rating)}>
						{rating}
					</MenuItem>
				))}
			</Menu>

			<Spacer height={5} />
		</>
	);
};
