import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Divider,
	InputAdornment,
	TextField,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { APIProperty } from '@rentcheck/types';
import { useCreateInspectionModalData } from 'hooks/modals';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { numberAndPluralizeWord, propertyTypePlurals } from 'utils/helpers';

import DialogTitle from '../common/inner-dialog-title';
import innerTransition from '../common/inner-transition';
import RowButton from '../common/row-button';
import List from './list';
import Selected from './selected';

interface Props {
	value: APIProperty[];
	setValue: (value: APIProperty[]) => void;
}

const SelectProperty = ({ value, setValue }: Props) => {
	const theme = useTheme();
	const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));

	const modalData = useCreateInspectionModalData();
	const { property_type: propertyType } = modalData?.metadata ?? {};

	const [modalOpen, setModalOpen] = useState(false);
	const [properties, setProperties] = useState(value);
	const [search, setSearch] = useState('');

	useEffect(() => {
		setProperties(value);
	}, [modalOpen]);

	const handleOpen = () => {
		setModalOpen(true);
	};

	const handleClose = () => {
		setModalOpen(false);
	};

	const handleOk = () => {
		setValue(properties);
		setModalOpen(false);
	};

	const title = `Select ${propertyTypePlurals[propertyType]}`;

	return (
		<>
			<RowButton
				title={title}
				icon={regular('house')}
				onClick={handleOpen}
				hasContent={value.length > 0}>
				<Selected properties={value} setValue={setValue} />
			</RowButton>

			<Divider />

			<Dialog
				open={modalOpen}
				onClose={handleClose}
				fullScreen={mobileScreen}
				TransitionComponent={innerTransition}>
				<DialogTitle title={title} onClose={handleClose} />
				<DialogContent sx={{ minHeight: 56, flex: 0 }}>
					<TextField
						fullWidth
						placeholder="Search"
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<FontAwesomeIcon icon={regular('search')} />
								</InputAdornment>
							),
						}}
					/>
				</DialogContent>

				<List value={properties} setValue={setProperties} search={search} />

				<DialogActions>
					<Button variant="text" color="secondary" onClick={handleClose}>
						Back
					</Button>
					<Button variant="contained" onClick={handleOk}>
						{properties.length > 0
							? `Select ${numberAndPluralizeWord(
									properties.length,
									_.capitalize(propertyType),
									propertyTypePlurals[propertyType]
								)}`
							: 'Ok'}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default SelectProperty;
