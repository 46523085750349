import { Dialog } from '@mui/material';
import 'react-phone-input-2/lib/style.css';
import { useDispatch } from 'react-redux';

import { ModalFlowActions } from 'store/actions';
import { Dispatch } from 'types';
import { useIsMobileBreakpoint } from 'utils/hooks';
import Content from './content';

interface Props {
	open: boolean;
}

export default ({ open }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const isMobile = useIsMobileBreakpoint();

	const handleClose = () => {
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	return (
		<Dialog open={open} onClose={handleClose} fullScreen={isMobile}>
			<Content />
		</Dialog>
	);
};
