import { Button, Typography } from '@mui/material';
import assets from 'assets';
import { CenteredColumn } from 'components';
import { useHistory } from 'react-router-dom';

export const EmptyState = () => {
	const history = useHistory();

	return (
		<CenteredColumn>
			<Typography
				my={2}
				variant="subtitle2"
				color="secondary"
				textAlign="center">
				Nothing to see here.
			</Typography>
			<img
				src={assets.inspectionTemplates.pugEmptyState}
				alt="Empty State"
				style={{ maxWidth: 389 }}
			/>
			<Typography
				my={2}
				variant="subtitle2"
				color="secondary"
				textAlign="center">
				You have no published inspection templates. Create or
				<br /> publish inspection templates to continue.
			</Typography>
			<Button
				onClick={() => history.push('/account/inspection-templates')}
				variant="outlined">
				Manage Inspection Templates
			</Button>
		</CenteredColumn>
	);
};
