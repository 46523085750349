import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ApiSubscription, ApiUser } from '@rentcheck/types';
import firebase from 'firebase';
import { ModalFlowActions } from 'store/actions';
import { Dispatch } from 'types';

export const handleUpdatePaymentMethod = (
	dispatch: Dispatch,
	profile?: ApiUser | null,
	subscription?: ApiSubscription | null
) => {
	if (!profile || !subscription) {
		return;
	}

	const canUpdate =
		profile.email.toLowerCase() === subscription?.rentcheck_contact.email;

	if (!canUpdate) {
		dispatch(
			ModalFlowActions.showConfirmationModal({
				title: 'Hmmm...',
				icon: solid('lock'),
				body1: [
					"We’re sorry, but you don't have access to update the payment " +
						'method for this subscription. Only the subscription billing ' +
						`contact, ${subscription?.rentcheck_contact.name} ` +
						`(${subscription?.rentcheck_contact.email}) has permission ` +
						'to make this change.',
				],
				buttons: [
					{
						title: 'Done',
						onClick: () => dispatch(ModalFlowActions.closeConfirmationModal()),
					},
				],
			})
		);
		return;
	}

	const chargebeeSite = firebase
		.remoteConfig()
		.getValue('chargebee_site')
		.asString();

	window.open(
		`https://${chargebeeSite}.chargebee.com/portal/v2/payment_methods`,
		'_blank'
	);
};
