import { useEffect, useState } from 'react';
import _ from 'lodash';
import 'react-phone-input-2/lib/style.css';
import { Card, CardContent } from '@mui/material';

import {
	TemplateQuestion,
	TemplateFeature,
	TemplateSection,
} from '@rentcheck/types';

import { featureDisplayName } from 'utils/helpers';

import SelectFeatures from '../select-features';
import { BulkTemplateFeatureLogic } from '../../bulk-modal';
import SelectAnswers from '../../../../shared-components/select-answers';
import SelectQuestion from '../../../../shared-components/select-question';

interface Props {
	features: TemplateFeature[];
	sections: TemplateSection[];
	logic: BulkTemplateFeatureLogic;
	onUpdate: (logic: BulkTemplateFeatureLogic) => void;
}

export default ({ features, sections, logic, onUpdate }: Props) => {
	const [questions, setQuestions] = useState<TemplateQuestion[]>([]);
	const [question, setQuestion] = useState<TemplateQuestion>();

	useEffect(() => {
		const questions = _.sortBy(
			sections.flatMap((s) =>
				s.features.flatMap((f) =>
					f.questions
						.filter((q) => q.type === 'yes/no' || q.type === 'multiple choice')
						.map((q) => ({
							...q,
							title: `${featureDisplayName({ room: s.name, feature: f.name }, ': ')}: ${q.title}`,
						}))
				)
			),
			'title'
		);

		setQuestions(questions);
	}, []);

	useEffect(() => {
		if (!questions.length) {
			return;
		}

		setQuestion(questions.find((q) => q.id === logic.trigger_id));
	}, [questions, logic.trigger_id]);

	const handleUpdateTriggerId = (triggerId?: string) => {
		if (triggerId === logic.trigger_id) {
			return;
		}

		onUpdate({ ...logic, trigger_id: triggerId ?? '', condition_value: [] });
	};

	const handleUpdateConditionValue = (value: string[]) => {
		onUpdate({
			...logic,
			condition_value: value,
		});
	};

	const handleUpdateFeatures = (features: TemplateFeature[]) => {
		onUpdate({ ...logic, features });
	};

	/**
	 * We need to wait until we can find the question that
	 * matches this trigger id
	 */
	if (logic.trigger_id && !question) {
		return null;
	}

	return (
		<Card sx={{ marginBottom: 2 }}>
			<CardContent sx={{ display: 'flex', p: 2, flexDirection: 'column' }}>
				<SelectQuestion
					question={question}
					questions={questions}
					onUpdateTriggerId={handleUpdateTriggerId}
				/>

				<SelectAnswers
					question={question}
					conditionValue={logic.condition_value}
					onUpdateConditionValue={handleUpdateConditionValue}
				/>

				<SelectFeatures
					question={question}
					features={logic.features}
					allFeatures={features}
					onUpdateFeatures={handleUpdateFeatures}
				/>
			</CardContent>
		</Card>
	);
};
