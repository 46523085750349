import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment, TextField as MaterialTextField } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import styled from 'styled-components';
import { colors } from 'theme';

import { Column } from '../layout/Column';

interface Props {
	id?: string;
	type?: string;
	error?: string;
	label: string;
	prefix?: string;
	value?: string;
	showStepper?: boolean;
	width?: number;
	variant?: 'standard' | 'filled' | 'outlined';
	onChange: (value: string) => void;
}

interface StepperProps {
	value?: string;
	setValue?: (value: string) => void;
}

const Stepper = ({ value, setValue }: StepperProps) => {
	const step = (diff: number) => {
		if (!value) {
			return;
		}

		const numberValue = isNaN(parseInt(value)) ? 0 : parseInt(value);
		setValue?.((numberValue + diff).toString());
	};

	return (
		<Column>
			<StepperIcon
				icon={solid('chevron-up')}
				color={colors.gray}
				onClick={() => step(1)}
			/>
			<StepperIcon
				icon={solid('chevron-down')}
				color={colors.gray}
				onClick={() => step(-1)}
			/>
		</Column>
	);
};

export const TextInput = ({
	onChange,
	label,
	value,
	prefix,
	showStepper,
	width,
	error,
	type = 'text',
	variant = 'standard',
}: Props) => {
	const inputId = 'field-' + label + '-id';

	const inputProps: any = {};

	if (prefix) {
		inputProps.startAdornment = (
			<InputAdornment position="start">{prefix}</InputAdornment>
		);
	}

	if (showStepper) {
		inputProps.endAdornment = (
			<InputAdornment position="end">
				<Stepper value={value} setValue={onChange} />
			</InputAdornment>
		);
	}

	if (variant === 'filled' && !prefix) {
		inputProps.startAdornment = <InputAdornment position="start" />;
	}

	const labelValue = variant === 'standard' ? label : undefined;
	const placeholderValue = variant === 'filled' ? label : undefined;

	return (
		<TextField
			id={inputId}
			label={labelValue}
			placeholder={placeholderValue}
			value={value}
			type={type}
			variant={variant}
			onChange={(e) => onChange(e.target.value)}
			InputProps={inputProps}
			width={width}
			error={!!error}
			helperText={error}
		/>
	);
};

interface PhoneInputProps {
	label: string;
	country?: string;
	value?: string;
	onChange: (value: string) => void;
	hideBottomBorder?: boolean;
}

export const PhoneNumberInput = ({
	country,
	label,
	value,
	onChange,
	hideBottomBorder,
}: PhoneInputProps) => {
	return (
		<PhoneInputWrapper hideBottomBorder={hideBottomBorder}>
			<PhoneInput
				inputStyle={{ border: '0px' }}
				buttonStyle={{ border: '0px', backgroundColor: colors.transparent }}
				country={country}
				placeholder={label}
				value={value}
				onChange={onChange}
			/>
		</PhoneInputWrapper>
	);
};

export const PhoneNumberInputFilled = ({
	country,
	label,
	value,
	onChange,
	hideBottomBorder,
}: PhoneInputProps) => {
	return (
		<PhoneInputWrapper
			id="phone-input-wrapper"
			hideBottomBorder={hideBottomBorder}
			style={{
				width: '100%',
				backgroundColor: 'rgba(0,0,0,0.06)',
				borderRadius: '4px 4px 0 0',
			}}>
			<PhoneInput
				inputStyle={{ border: '0px', background: 'transparent' }}
				buttonStyle={{ border: '0px', backgroundColor: colors.transparent }}
				country={country}
				placeholder={label}
				value={value}
				onChange={onChange}
			/>
		</PhoneInputWrapper>
	);
};

const TextField = styled(MaterialTextField)<{ width?: number }>`
	flex: 1;
	${({ width }) => (width ? `width: ${width}px` : ``)}
`;

const PhoneInputWrapper = styled.div<{ hideBottomBorder?: boolean }>`
	display: flex;
	flex-direction: column;
	padding: 8px 0 8px 0;
	overflow: hidden;
	margin-bottom: 1px;
	${({ hideBottomBorder }) =>
		hideBottomBorder ? `` : `border-bottom: 1px solid rgba(0, 0, 0, 0.54)`};

	&:hover {
		margin-bottom: 0px;
		${({ hideBottomBorder }) =>
			hideBottomBorder ? `` : `border-bottom: 2px solid ${colors.black}`};
	}

	&:focus-within {
		margin-bottom: 0px;
		${({ hideBottomBorder }) =>
			hideBottomBorder ? `` : `border-bottom: 2px solid ${colors.primary}`};
	}
`;

const StepperIcon = styled(FontAwesomeIcon)`
	cursor: pointer;
	user-select: none;
`;

export default TextInput;
