import { Skeleton, Typography } from '@mui/material';

import { Row } from 'components';
import _ from 'lodash';

interface Props {
	title: string;
	value: string;
	loading?: boolean;
}

export default ({ title, value, loading }: Props) => {
	return (
		<Row>
			<Row style={{ width: '50%' }}>
				<Typography variant="subtitle2">{title}</Typography>
			</Row>
			<Row style={{ width: '50%' }}>
				{loading ? (
					<Skeleton variant="text" width={_.random(100, 200)} height={18} />
				) : (
					<Typography>{value}</Typography>
				)}
			</Row>
		</Row>
	);
};
