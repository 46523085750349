import { Button } from '@mui/material';
import { useState } from 'react';

import ConnectLatchelModal from './modal';

interface Props {
	label: string;
}

const ConnectLatchelButton = ({ label }: Props) => {
	const [modalOpen, setModalOpen] = useState(false);

	const handleConnect = () => {
		setModalOpen(true);
	};

	return (
		<>
			<Button onClick={handleConnect}>{label}</Button>

			<ConnectLatchelModal
				open={modalOpen}
				handleClose={() => setModalOpen(false)}
			/>
		</>
	);
};

export default ConnectLatchelButton;
