import { ApiTenant, Tenant } from '@rentcheck/types';

import { actionTypes, TenantActionTypes } from '../../actions/tenants';

export type State = Tenant | null;

type Action = {
	type: TenantActionTypes;
	tenants: ApiTenant[];
};

export default (state: State = null, { type, tenants }: Action) => {
	if (!tenants) {
		return state;
	}

	const tenant = tenants[0];

	if (!tenant) {
		return state;
	}

	switch (type) {
		case actionTypes.SIGNOUT_SUCCESS:
		case actionTypes.IMPERSONATE:
			return null;

		case actionTypes.SET_TENANT:
			return tenant;

		case actionTypes.CREATE_TENANTS:
			return tenant;

		case actionTypes.UPDATE_TENANTS: {
			if (tenant.id !== state?.id) {
				return state;
			}

			return tenant;
		}

		case actionTypes.DELETE_TENANT: {
			if (tenant.id !== state?.id) {
				return state;
			}

			return null;
		}

		default:
			return state;
	}
};
