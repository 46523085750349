import {
	ApiInspectionEvent,
	InspectionEventSMSReminderSentMetadata,
} from '@rentcheck/types';

import BaseTimelineEvent from '../base-event';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

interface Props {
	event: ApiInspectionEvent;
}

const ReminderSMSTimelineEvent = ({ event }: Props) => {
	const metadata = event.metadata as InspectionEventSMSReminderSentMetadata;

	return (
		<BaseTimelineEvent
			icon={solid('bell')}
			title="SMS Reminder sent"
			timestamp={event.timestamp}
			sentToSMS={metadata.numbers_sent}
			note={metadata.note}
		/>
	);
};

export default ReminderSMSTimelineEvent;
