export const shouldIgnoreClick = (target?: HTMLElement) => {
	if (!target) {
		return true;
	}

	const targetClasses = target.classList;

	/**
	 * If the user clicks on a menu item we assume it's one of the
	 * select menu item options, in that case we need to ignore the click
	 */
	if (targetClasses.contains('MuiMenuItem-root')) {
		return true;
	}

	/**
	 * If the user clicks on the backdrop we do nothing, this is the user
	 * dismissing one of the text field selects, not clicking outside of
	 * the question card
	 */
	if (targetClasses.contains('MuiBackdrop-root')) {
		return true;
	}

	return false;
};
