import { useTypedSelector } from 'store/reducers/rootReducer';

import SnapshotRow from './snapshot-row';

const AwaitingReview = () => {
	const dashboardData = useTypedSelector((state) => state.dashboard.data);

	const count = dashboardData?.todo_list.inspections_awaiting_review_count;

	return (
		<SnapshotRow
			count={count}
			label="Awaiting Review"
			link="/dashboard/awaiting-review"
		/>
	);
};

export default AwaitingReview;
