import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Autocomplete, MenuItem, Typography } from '@mui/material';
import { SortConfig, UnitsApi } from '@rentcheck/api-frontend';
import { OccupancyProperty } from '@rentcheck/types';
import { Column } from 'components';
import FormTextField from 'components/form-text-field';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';

interface Props {
	unit: OccupancyProperty | undefined;
	setUnit: (value: OccupancyProperty | undefined) => void;
	errors: string[];
}

export default ({ errors, unit, setUnit }: Props) => {
	const dispatch = useDispatch();

	const [units, setUnits] = useState<OccupancyProperty[]>([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		search('');
	}, []);

	const debouncedSearch = _.debounce((filters, sorting) => {
		UnitsApi.getAll(0, 100, filters, sorting)
			.then((result) => setUnits(result.data))
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	}, 300);

	const search = (keyword: string) => {
		const filters = {
			search: keyword,
			teamId: [],
			buildingId: [],
			inspectionType: [],
		};

		const sorting: SortConfig = {
			sortBy: 'address',
			sortType: 'asc',
		};

		setLoading(true);
		debouncedSearch(filters, sorting);
	};

	return (
		<Autocomplete
			fullWidth
			disablePortal
			disableClearable
			options={units}
			getOptionLabel={(o) => o.address}
			isOptionEqualToValue={(o, v) => o.id === v?.id}
			filterOptions={(x) => x}
			value={unit}
			onChange={(_, value) => value && setUnit(value)}
			onInputChange={(_, value) => search(value)}
			noOptionsText="No units match your search"
			loading={loading}
			loadingText="Loading Units..."
			renderOption={(props, option) => (
				<MenuItem {...props} key={option.id}>
					<Column>
						{option.address}
						<Typography fontSize={12} color="#666">
							{option.city}, {option.zip_code}
						</Typography>
					</Column>
				</MenuItem>
			)}
			renderInput={(params) => (
				<FormTextField
					{...params}
					title="Choose Unit"
					placeholder="Choose Unit"
					helperText="Required"
					error={errors.includes('unit')}
					icon={regular('house')}
				/>
			)}
		/>
	);
};
