import moment from 'moment';

export interface DateRange {
	start: number;
	end: number;
}

export interface State {
	// These are always active
	propertyType: 'Unit' | 'Building' | 'Community';
	dateRange: DateRange;

	// These can be added optionally, so they are null
	// until the user activates them
	building: string[] | null;
	team: string[] | null;
}

export const dateRanges = {
	last3Months: {
		start: moment().subtract(3, 'month').startOf('day').valueOf(),
		end: moment().endOf('day').valueOf(),
	},
	last6Months: {
		start: moment().subtract(6, 'month').startOf('day').valueOf(),
		end: moment().endOf('day').valueOf(),
	},
};

const initialState: State = {
	propertyType: 'Unit',
	dateRange: dateRanges.last3Months,
	building: null,
	team: null,
};

type Action = {
	type: string;
	value?: string | DateRange;
};

const reducer = (state: State = initialState, action: Action) => {
	switch (action.type) {
		case 'SIGNOUT_SUCCESS':
		case 'IMPERSONATE':
			return initialState;

		case 'SET_DASHBOARD_FILTER_PROPERTY_TYPE':
			return { ...state, propertyType: action.value };

		case 'SET_DASHBOARD_FILTER_DATE_RANGE':
			return { ...state, dateRange: action.value };

		case 'SET_DASHBOARD_FILTER_TEAM':
			return { ...state, team: action.value };

		case 'SET_DASHBOARD_FILTER_BUILDING':
			return { ...state, building: action.value };

		default:
			return state;
	}
};

export default reducer;
