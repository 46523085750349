import { Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { ActionsChip, Spacer } from 'components';
import {
	ModalFlowActions,
	PropertiesActions,
	SnackbarActions,
} from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';
import { DeleteActionButton } from '../../../components/delete-action-button';

interface Props {
	children?: React.ReactNode | React.ReactNodeArray;
	communityIds: string[];
	setCommunityIds: (ids: string[]) => void;
	disableDeletion?: boolean;
}

const ActionsMenu = ({
	children,
	communityIds,
	setCommunityIds,
	disableDeletion,
}: Props) => {
	const dispatch: Dispatch = useDispatch();

	const communities = useTypedSelector(
		(state) => state.properties.communities.data
	);

	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const handleClick = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		setAnchorEl(e.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const deleteCommunities = () => {
		dispatch(PropertiesActions.communities.remove(communityIds))
			.then(() => setCommunityIds([]))
			.catch(() => dispatch(SnackbarActions.showError()));
	};

	const handleCreateInspection = async () => {
		handleClose();

		if (communityIds.length === 0) {
			return;
		}

		const properties = communities.filter((b) => communityIds.includes(b.id));

		dispatch(
			ModalFlowActions.showCreateInspectionModal({
				properties,
				property_type: 'community',
			})
		);
	};

	const handleDelete = () => {
		handleClose();

		if (communityIds.length === 0) {
			return;
		}

		const numberOfCommunities = `${communityIds.length} communit${
			communityIds.length === 1 ? 'y' : 'ies'
		}`;

		const body = [
			'Are you sure you want to delete these communities?',
			`${numberOfCommunities} will be deleted and this update is irreversible.`,
		];

		dispatch(
			ModalFlowActions.showConfirmationModal({
				title: 'Are you sure?',
				body1: body,
				body2: [],
				buttons: [
					ModalFlowActions.cancelButton(dispatch),
					{
						title: 'Delete',
						color: 'error',
						variant: 'contained',
						onClick: deleteCommunities,
					},
				],
			})
		);
	};

	return (
		<>
			<div onClick={handleClick}>
				{children ?? (
					<ActionsChip
						ids={communityIds}
						openMenu={(e: React.MouseEvent<HTMLElement>) => handleClick(e)}
					/>
				)}
			</div>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={!!anchorEl}
				onClose={handleClose}>
				<MenuItem onClick={handleCreateInspection}>
					Create Inspection
					<Spacer width={4} />
				</MenuItem>
				<DeleteActionButton disabled={disableDeletion} onClick={handleDelete} />
			</Menu>
		</>
	);
};

export default ActionsMenu;
