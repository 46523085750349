import { Divider, Grid, Skeleton } from '@mui/material';
import { Screens, Spacer } from 'components';

export default () => {
	return (
		<>
			<Screens.HeaderSkeleton subtitle />
			<Spacer height={4} />
			<Grid container columns={{ xs: 1, md: 2 }} spacing={2} mb={2}>
				<Grid item xs={1}>
					<Skeleton variant="text" width={120} height={18} />
					<Skeleton variant="text" width={240} height={16} sx={{ mt: 0.5 }} />
					<Divider sx={{ mt: 1, opacity: 0 }} />
				</Grid>
				<Grid item xs={1}>
					<Skeleton variant="text" width={120} height={18} />
					<Skeleton variant="text" width={240} height={16} sx={{ mt: 0.5 }} />
					<Divider sx={{ mt: 1, opacity: 0 }} />
				</Grid>
				<Grid item xs={1}>
					<Skeleton variant="text" width={120} height={18} />
					<Skeleton variant="text" width={240} height={16} sx={{ mt: 0.5 }} />
					<Divider sx={{ mt: 1, opacity: 0 }} />
				</Grid>
				<Grid item xs={1}>
					<Skeleton variant="text" width={120} height={18} />
					<Skeleton variant="text" width={240} height={16} sx={{ mt: 0.5 }} />
					<Divider sx={{ mt: 1, opacity: 0 }} />
				</Grid>
				<Grid item xs={1}>
					<Skeleton variant="text" width={120} height={18} />
					<Skeleton variant="text" width={240} height={16} sx={{ mt: 0.5 }} />
					<Divider sx={{ mt: 1, opacity: 0 }} />
				</Grid>
				<Grid item xs={1}>
					<Skeleton variant="text" width={120} height={18} />
					<Skeleton variant="text" width={240} height={16} sx={{ mt: 0.5 }} />
					<Divider sx={{ mt: 1, opacity: 0 }} />
				</Grid>
			</Grid>
		</>
	);
};
