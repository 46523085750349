import { CenteredColumn } from 'components';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Typography } from '@mui/material';
import { emptyStates } from 'assets';

interface Props {
	onAddLogic: () => void;
}

export default ({ onAddLogic }: Props) => {
	return (
		<CenteredColumn>
			<img src={emptyStates.sectionLogic} alt="No Logic" />
			<Typography variant="subtitle2" mt={2} mb={2}>
				Lost in the dog park? Start playing with logic.
			</Typography>
			<Button
				variant="text"
				onClick={onAddLogic}
				startIcon={<FontAwesomeIcon icon={regular('plus')} />}>
				Add Logic
			</Button>
		</CenteredColumn>
	);
};
