import { Screens } from 'components';
import { NewInspectionButton } from 'components/Common';
import ExportButton from 'components/table/export-button';
import { useTypedSelector } from 'store/reducers/rootReducer';
import InspectionsList from './list';

const Inspections = () => {
	const profile = useTypedSelector((state) => state.activeProfile);
	const inspectionTotals = useTypedSelector(
		(state) => state.inspections.inspections.total
	);

	return (
		<Screens.Container>
			{!profile && <Screens.HeaderSkeleton />}
			{!!profile && (
				<Screens.Header
					title="Inspections"
					subtitle={`${inspectionTotals} Total`}
					actions={[
						<ExportButton variant="inspections" />,
						<NewInspectionButton />,
					]}
				/>
			)}
			<InspectionsList />
		</Screens.Container>
	);
};

export default Inspections;
