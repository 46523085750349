import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Popover } from '@mui/material';
import { Column } from 'components/layout/Column';
import { Row } from 'components/layout/Row';
import SegmentChip from 'components/segment-chip';
import {
	forwardRef,
	MouseEventHandler,
	ReactNode,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from 'react';
import styled from 'styled-components';

interface Props {
	/**
	 * The title of the filter.
	 * E.g.: "Archived", "Due Date", "Inspection Template"
	 */
	title: string;

	/**
	 * The value of the filter.
	 * E.g.: "2/12/2024", "is any of (2)"
	 */
	label?: string;

	/**
	 * The icon to display on the left side of the chip.
	 */
	icon: IconDefinition;

	/**
	 * The handler for removing the filter
	 */
	onDelete: () => void;

	/**
	 * The handler for dismissing the popover without clicking apply
	 */
	onDismiss?: () => void;

	/**
	 * Called when the popover is shown
	 */
	onShow?: () => void;

	/**
	 * The children of the filter chip to be shown as a Popover
	 * when the chip is clicked.
	 */
	children?: ReactNode | ReactNode[];

	/**
	 * If `true`, the popover will not be dismissed when the user clicks outside of it.
	 */
	preventDismiss?: boolean;
}

export interface FilterChipRef {
	/**
	 * Closes the Chip Popover
	 * @param forceClose when set to `true` it will close the popover even if `preventDismiss` is set to `true`
	 * @returns
	 */
	close: (forceClose?: boolean) => void;
}

const FilterChip = forwardRef<FilterChipRef, Props>((props, fwdRef) => {
	const { title, label, icon, onDelete, onDismiss, children, preventDismiss } =
		props;

	const ref = useRef<HTMLDivElement>(null);

	const [menuAnchor, setMenuAnchor] = useState<HTMLDivElement | null>(null);

	useEffect(() => {
		/**
		 * If the label is not provided, we want to open the popover
		 * immediately when the component is mounted. (this means the
		 * filter is new and the user hasn't selected a value yet)
		 */
		if (!label) {
			ref.current?.click();
		}
	}, []);

	useImperativeHandle(fwdRef, () => ({
		close: handleClosePopover,
	}));

	const handleClosePopover = (forceClose?: boolean) => {
		if (preventDismiss && !forceClose) {
			return;
		}

		setMenuAnchor(null);
		onDismiss?.();
	};

	const handleClick: MouseEventHandler<HTMLDivElement> = (e) => {
		if (!children) {
			return;
		}

		setMenuAnchor(e.currentTarget);

		/**
		 * We want to wait for the popover to be shown before calling
		 * the `onShow` handler. This is because we need the component
		 * position to be calculated completely.
		 */
		setTimeout(() => props.onShow?.(), 250);
	};

	return (
		<>
			<SegmentChip
				ref={ref}
				variant="filled"
				color="primary"
				icon={icon}
				style={menuAnchor ? { border: '1px solid #757CF0' } : undefined}
				label={
					<Row>
						<span style={{ fontWeight: 500 }}>{title}</span>
						{label && <span style={{ marginLeft: 4 }}>{label}</span>}
					</Row>
				}
				title={title}
				onDelete={onDelete}
				onClick={handleClick}
			/>
			{!!children && (
				<Popover
					sx={{ marginTop: 1 }}
					open={!!menuAnchor}
					anchorEl={menuAnchor}
					onClose={() => handleClosePopover()}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}>
					<MenuContainer>{children}</MenuContainer>
				</Popover>
			)}
		</>
	);
});

const MenuContainer = styled(Column)`
	min-width: 250px;
	padding: 8px;
`;

export default FilterChip;
