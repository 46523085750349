import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { PopoverMenu } from 'components';
import { spacingPx } from 'theme';

import { ModalFlowActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';

import { Button } from '@mui/material';
import { Permissions } from '@rentcheck/biz';
import UploadEmailModal from '../../components/UploadEmailModal';

export default () => {
	const dispatch = useDispatch();

	const subscription = useTypedSelector((state) => state.subscription);

	const [uploadTenantModalIsVisible, setUploadTenantModalIsVisible] =
		useState(false);

	if (!Permissions.Residents.canUpload(subscription)) {
		return (
			<Button
				startIcon={<AddIcon />}
				onClick={() => dispatch(ModalFlowActions.showResidentModal({}))}>
				Add Resident
			</Button>
		);
	}

	const popoverItems = [
		{
			title: 'Add New Resident',
			onClick: () => dispatch(ModalFlowActions.showResidentModal({})),
		},
		{
			title: 'Upload Residents',
			onClick: () => setUploadTenantModalIsVisible(true),
			isLast: true,
		},
	];

	return (
		<>
			<PopoverMenu
				triggerNode={
					<Button
						endIcon={
							<FontAwesomeIcon
								style={{ marginBottom: spacingPx(2) }}
								icon={solid('sort-down')}
							/>
						}
						onClick={() => {}}>
						New Residents
					</Button>
				}
				options={popoverItems}
				width={50}
			/>
			{uploadTenantModalIsVisible && (
				<UploadEmailModal
					setModalVisible={setUploadTenantModalIsVisible}
					type="resident"
				/>
			)}
		</>
	);
};
