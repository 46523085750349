import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, Typography } from '@mui/material';

import { Column, Row, SpacedRow, Spacer } from 'components';
import { colors } from 'theme';

export default (props: {
	vendor: string;
	label: string;
	toggle: boolean;
	operation: 'read' | 'write';
	hideDivider?: boolean;
}) => {
	const { label, toggle, operation, vendor, hideDivider } = props;

	return (
		<Column>
			<SpacedRow style={{ marginBottom: 16 }}>
				<Row>
					<FontAwesomeIcon icon={regular('sync')} color={colors.gray} />
					<Spacer width={5} />
					<Column>
						<Typography variant="overline">
							{operation === 'read'
								? `${vendor} to RentCheck`
								: `RentCheck to ${vendor}`}
						</Typography>
						<Typography variant="subtitle1">{label}</Typography>
					</Column>
				</Row>

				<Typography
					variant="subtitle1"
					sx={{
						width: '45%',
						backgroundColor: toggle ? '#2D3CE60D' : '#232E3A0D',
						padding: 1,
						borderRadius: 1,
					}}>
					{toggle ? 'ON' : 'OFF'}
				</Typography>
			</SpacedRow>
			{!hideDivider && <Divider sx={{ marginBottom: 2 }} />}
		</Column>
	);
};
