import { Skeleton } from '@mui/material';
import { Column, SpacedRow, Spacer } from 'components';

export default () => {
	return (
		<Column>
			<SpacedRow>
				<Column style={{ flex: 1 }}>
					<Skeleton variant="text" width="80%" height="20px" />
					<Skeleton variant="text" width="30%" height="20px" />
				</Column>
				<Skeleton variant="circular" width="30px" height="30px" />
			</SpacedRow>
			<Spacer height={2} />
			<Skeleton variant="text" width="60%" />
			<Skeleton variant="text" width="40%" />
			<Skeleton variant="text" width="50%" />
			<Skeleton variant="text" width="55%" />
			<Skeleton variant="text" width="40%" />
			<Skeleton variant="text" width="30%" />
			<Skeleton variant="text" width="35%" />
		</Column>
	);
};
