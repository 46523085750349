import { Checkbox, TableCell, TableRow, Typography } from '@mui/material';
import { MaintenanceFlag } from '@rentcheck/types';
import _ from 'lodash';

import { Column } from 'components';
import { useDispatch } from 'react-redux';
import { ModalFlowActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';

import { useFeature } from 'hooks/flagged-features';
import { colors } from 'theme';
import { featureDisplayName } from 'utils/helpers';
import Photos from './photos';
import ReportMenu from './report-menu';
import WorkOrdersMenu from './work-orders-menu';

interface Props {
	flag: MaintenanceFlag;
	selectedIds: string[];
	selectId: (id: string) => void;
}

const FeatureTableRow = ({ flag, selectedIds, selectId }: Props) => {
	const dispatch = useDispatch();

	const inspection = useTypedSelector((state) => state.inspections.inspection);
	const feature = useFeature(inspection?.id ?? '', flag.room, flag.feature);

	const cost = flag.cost ? `$${flag.cost.toFixed(2)}` : '-';

	const handleClick = () => {
		if (!inspection) return;
		if (!feature) return;

		dispatch(
			ModalFlowActions.showFlagFeatureModal({
				inspection,
				feature,
				flag,
			})
		);
	};

	if (!feature) return null;

	return (
		<TableRow
			sx={{
				'&:last-child td, &:last-child th': { border: 0 },
				cursor: 'pointer',
			}}>
			<TableCell>
				<Checkbox
					size="small"
					checked={selectedIds.includes(flag.id)}
					onChange={() => selectId(flag.id)}
				/>
			</TableCell>

			<TableCell onClick={handleClick}>{flag.skill}</TableCell>

			<TableCell onClick={handleClick}>{featureDisplayName(flag)}</TableCell>

			<TableCell>
				<Photos flag={flag} />
			</TableCell>

			<TableCell onClick={handleClick} sx={{ maxWidth: 300 }}>
				<Typography
					color="#54687E"
					sx={{
						display: '-webkit-box',
						overflow: 'hidden',
						WebkitBoxOrient: 'vertical',
						WebkitLineClamp: 3,
					}}>
					{flag.note}
				</Typography>
			</TableCell>

			<TableCell onClick={handleClick}>
				<Column>
					{flag.created_by.name}
					<Typography variant="caption" color={colors.secondary} noWrap>
						{flag.created_by.type}
					</Typography>
				</Column>
			</TableCell>

			<TableCell onClick={handleClick}>
				{_.capitalize(flag.responsibility || '-')}
			</TableCell>

			<TableCell onClick={handleClick}>{cost}</TableCell>

			<TableCell>
				<Column style={{ minWidth: 170 }}>
					<ReportMenu flag={flag} feature={feature} />
					<WorkOrdersMenu flag={flag} />
				</Column>
			</TableCell>
		</TableRow>
	);
};

export default FeatureTableRow;
