import React from 'react';
import { Typography } from '@mui/material';
import { RootState, useTypedSelector } from 'store/reducers/rootReducer';

interface Props {
	totalExtractor: (state: RootState) => number;
	selectedIds?: string[];
}

const QueryTotals = ({ totalExtractor, selectedIds }: Props) => {
	const total = useTypedSelector(totalExtractor);

	if (selectedIds && selectedIds.length > 0) {
		return <Typography>{selectedIds.length} Selected</Typography>;
	}

	return <Typography>{total} Total</Typography>;
};

export default QueryTotals;
