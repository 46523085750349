import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { CircularProgress } from '@mui/material';
import { Column, Row } from 'components';
import IconButton, { IconButtonRef } from 'components/icon-button';
import { useIsTopModal } from 'hooks/modals';
import { useRef, useState } from 'react';
import { ReactPhotoSphereViewer } from 'react-photo-sphere-viewer';
import { imageUrlFromGCPUri } from 'utils/helpers';
import { useKeyPress } from 'utils/hooks';

import { ViewerProps } from '../common';
import TopControls from '../top-controls';

type ReactPhotoSphereViewerRef = typeof ReactPhotoSphereViewer & {
	animate: (data: { zoom: number; speed: string }) => void;
};

const zoomStep = 25;

export default ({
	imageUri,
	feature,
	handleNext,
	handlePrevious,
	mode,
}: ViewerProps) => {
	const isTopModal = useIsTopModal('Feature Images');

	const containerRef = useRef<HTMLDivElement>(null);
	const prevButtonRef = useRef<IconButtonRef>(null);
	const nextButtonRef = useRef<IconButtonRef>(null);
	const photoSphereRef = useRef<ReactPhotoSphereViewerRef>(null);

	const [zoom, setZoom] = useState(50);

	useKeyPress((event: { keyCode: number }) => {
		if (!isTopModal) {
			return;
		}

		// LEFT Arrow
		if (event.keyCode === 37) {
			prevButtonRef.current?.click();
		}

		// RIGHT Arrow
		if (event.keyCode === 39) {
			nextButtonRef.current?.click();
		}
	});

	const handleZoomIn = () => {
		photoSphereRef.current?.animate({
			zoom: zoom + zoomStep,
			speed: '15rpm',
		});
	};

	const handleZoomOut = () => {
		photoSphereRef.current?.animate({
			zoom: zoom - zoomStep,
			speed: '10rpm',
		});
	};

	const onZoomChange = (data: { zoomLevel: number }) => {
		setZoom(data.zoomLevel);
	};

	return (
		<Column style={{ flex: 1, width: '100%', height: '100%' }}>
			<TopControls
				imageUri={imageUri}
				feature={feature}
				handleZoomIn={handleZoomIn}
				handleZoomOut={handleZoomOut}
				mode={mode}
			/>
			<Row style={{ flex: 1, height: 'calc(100% - 44px)' }}>
				<IconButton
					ref={prevButtonRef}
					icon={regular('arrow-left')}
					buttonSx={{ marginLeft: 0, marginRight: 1 }}
					onClick={handlePrevious}
					disabled={mode === 'edit'}
				/>
				<div
					ref={containerRef}
					style={{
						flex: 1,
						height: '100%',
						overflow: 'hidden',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					<CircularProgress style={{ position: 'absolute' }} />
					<ReactPhotoSphereViewer
						key={imageUri}
						ref={photoSphereRef}
						src={imageUrlFromGCPUri(imageUri)}
						height={'100vh'}
						width={'100%'}
						container={''}
						onZoomChange={onZoomChange}
					/>
				</div>
				<IconButton
					ref={nextButtonRef}
					icon={regular('arrow-right')}
					onClick={handleNext}
					disabled={mode === 'edit'}
				/>
			</Row>
		</Column>
	);
};
