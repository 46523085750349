import { firestore } from 'firebase/app';

export const objectsFromQuerySnapshot = <T extends unknown>(
	querySnapshot: firestore.QuerySnapshot
) => {
	const objects: T[] = [];

	querySnapshot.forEach((doc) => {
		const data = doc.data() as T;
		if (!data) return;
		objects.push(data);
	});

	return objects;
};

export const objectFromDocumentSnapshot = <T extends unknown>(
	doc: firestore.DocumentSnapshot
) => {
	if (!doc.exists) return undefined;
	return doc.data() as T;
};
