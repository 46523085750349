import { combineReducers } from 'redux';

import dataReducer, { State as DataState } from './data';
import filtersReducer, { State as FiltersState } from './filters';
import listReducer, { State as ListState } from './list';

export type State = {
	data: DataState;
	filters: FiltersState;
	list: ListState;
};

const reducer = combineReducers({
	filters: filtersReducer,
	data: dataReducer,
	list: listReducer,
});

export default reducer;
