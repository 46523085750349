import { useState } from 'react';
import { Autocomplete, FilterOptionsState } from '@mui/material';

import { APIProperty, ApiTenant, Occupancy } from '@rentcheck/types';

import { isEmailValid } from 'utils/emails';
import { Column, Spacer } from 'components';
import FormTextField from 'components/form-text-field';
import { useTenants } from 'hooks/residents';

import AutocompleteOption from './autocomplete-option';
import { Recipient } from './common';
import RecipientRow from './recipient-row';

interface Props {
	properties: Pick<APIProperty, 'id'>[];
	value: Recipient[];
	setValue: (value: Recipient[]) => void;
	helperText?: string;
	placeholder?: string;
	label?: string;
	validOccupancyStatus?: Occupancy['status'][];
}

export default ({
	properties,
	value,
	setValue,
	label,
	helperText = 'Add emails separated by a comma or press enter after each email.',
	placeholder = 'Add or select recipients',
	validOccupancyStatus = ['current', 'future'],
}: Props) => {
	const [inputValue, setInputValue] = useState('');

	const { loading, tenants } = useTenants(undefined, {
		property_id: properties.length === 1 ? properties[0].id : undefined,
		occupancy_status:
			properties.length === 1 ? validOccupancyStatus : undefined,
	});

	const filterOptions = (
		options: ApiTenant[],
		state: FilterOptionsState<ApiTenant>
	) => {
		return options.filter((o) => {
			const name = `${o.first_name} ${o.last_name}`;

			return (
				name.includes(state.inputValue) ||
				!!o.emails.find((e) => e.includes(state.inputValue))
			);
		});
	};

	const validateEmailsAndAddToRecipients = (inputValue: string) => {
		const emails = inputValue.split(/[,; ]/);
		const validEmails = emails.filter(isEmailValid);
		const uniqueEmails = validEmails.filter((email) => !value.includes(email));

		setValue([...value, ...uniqueEmails]);

		if (uniqueEmails.length > 0) {
			setTimeout(() => setInputValue(''), 50);
		}
	};

	const handleAutocompleteKeyDown = (e: React.KeyboardEvent) => {
		if (!['Enter', ',', ';', ' '].includes(e.key)) {
			return;
		}

		validateEmailsAndAddToRecipients(inputValue);
	};

	const handleBlur = () => {
		validateEmailsAndAddToRecipients(inputValue);
	};

	return (
		<Column>
			<Spacer height={4} />
			{properties.length != 1 && (
				<FormTextField
					title="Email Recipients"
					value={inputValue}
					onChange={(e) => setInputValue(e.target.value)}
					onKeyDown={handleAutocompleteKeyDown}
					label={label}
					placeholder={placeholder}
					helperText={helperText}
				/>
			)}
			{properties.length === 1 && (
				<Autocomplete
					loading={loading}
					fullWidth
					disablePortal
					options={tenants}
					filterOptions={filterOptions}
					onKeyDown={handleAutocompleteKeyDown}
					onInputChange={(_, v) => setInputValue(v)}
					onBlur={handleBlur}
					inputValue={inputValue}
					renderInput={(params) => (
						<FormTextField
							{...params}
							title="Email Recipients"
							placeholder={placeholder}
							helperText={helperText}
						/>
					)}
					renderOption={(_, t) => (
						<AutocompleteOption
							tenant={t}
							properties={properties}
							recipients={value}
							setRecipients={setValue}
						/>
					)}
				/>
			)}

			{value.map((v) => (
				<RecipientRow
					recipient={v}
					recipients={value}
					setRecipients={setValue}
				/>
			))}
		</Column>
	);
};

export * from './common';
