import { Constants } from '@rentcheck/biz';
import { ApiSubscription } from '@rentcheck/types';
import * as IntegrationsApi from 'api/integrations';

export const yardiWorkOrderAddonIds = ['yardi-integration'];

export const jenarkWorkOrderAddonIds = ['jenark-integration'];

export const inspectionTemplatePerUnitAddonId =
	'inspection-template-customization-2';

export const inspectionTemplateFreeTrialAddonId =
	'inspection-template-customization-free-trial';

export const inspectionTemplateAddonIds = [
	'inspection-template-customization',
	inspectionTemplateFreeTrialAddonId,
	inspectionTemplatePerUnitAddonId,
];

const subscriptionHasAddons = (
	subscription: ApiSubscription | null,
	addonIds?: string[]
) => {
	if (!subscription) {
		return false;
	}

	if (!addonIds) {
		return false;
	}

	return !!subscription.addons.find((a) => addonIds.includes(a.id));
};

export const hasAppfolioAddons = (subscription: ApiSubscription | null) => {
	return subscriptionHasAddons(
		subscription,
		Constants.Addons.AppFolio.TwoWayDataSync
	);
};

export const hasAppfolioWorkOrderAddons = (
	subscription: ApiSubscription | null
) => {
	return subscriptionHasAddons(
		subscription,
		Constants.Addons.AppFolio.WorkOrders
	);
};

export const hasRentManagerAddons = (subscription: ApiSubscription | null) => {
	return subscriptionHasAddons(subscription, Constants.Addons.RentManager.All);
};

export const hasRentManagerWorkOrderAddons = (
	subscription: ApiSubscription | null
) => {
	return subscriptionHasAddons(
		subscription,
		Constants.Addons.RentManager.WorkOrders
	);
};

export const hasYardiWorkOrderAddons = (
	subscription: ApiSubscription | null
) => {
	return subscriptionHasAddons(subscription, yardiWorkOrderAddonIds);
};

export const hasLatchelWorkOrdersAddons = (
	subscription: ApiSubscription | null
) => {
	return subscriptionHasAddons(
		subscription,
		Constants.Addons.Latchel.WorkOrders
	);
};

export const hasJenarkWorkOrderAddons = (
	subscription: ApiSubscription | null
) => {
	return subscriptionHasAddons(subscription, jenarkWorkOrderAddonIds);
};

export const hasWorkOrderAddons = (subscription: ApiSubscription | null) => {
	const addonIds = [
		...Constants.Addons.Latchel.WorkOrders,
		...Constants.Addons.AppFolio.WorkOrders,
		...Constants.Addons.RentManager.WorkOrders,
		...yardiWorkOrderAddonIds,
		...jenarkWorkOrderAddonIds,
	];

	return subscriptionHasAddons(subscription, addonIds);
};

export const hasInspectionTemplateAddons = (
	subscription: ApiSubscription | null
) => {
	return subscriptionHasAddons(subscription, inspectionTemplateAddonIds);
};

export const hasActiveIntegration = async (subscriptionId?: string) => {
	if (!subscriptionId) return '';

	const [appfolioIntegration, rentmanagerIntegration] = await Promise.all([
		IntegrationsApi.getAppFolioIntegrationForSubscriptionId(subscriptionId),
		IntegrationsApi.getRentmanagerIntegrationBySubscriptionId(subscriptionId),
	]);

	if (appfolioIntegration) {
		return 'AppFolio';
	}

	if (rentmanagerIntegration) {
		return 'Rent Manager';
	}

	return '';
};
