import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Dialog, DialogContent } from '@mui/material';
import { useDispatch } from 'react-redux';

import { DialogTitle } from 'components';
import { ModalFlowActions } from 'store/actions';
import { Dispatch } from 'types';

import Timeline from 'components/inspections/timeline';
import { useInspectionTimelineModalData } from 'hooks/modals';
import { useIsMobileBreakpoint } from 'utils/hooks';

interface Props {
	open: boolean;
}

export default ({ open }: Props) => {
	const isMobile = useIsMobileBreakpoint();

	const dispatch: Dispatch = useDispatch();

	const modalData = useInspectionTimelineModalData();
	const inspection = modalData?.metadata.inspection;

	const handleClose = () => {
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	return (
		<Dialog open={open} onClose={handleClose} fullScreen={isMobile}>
			<DialogTitle
				title="Inspection Timeline"
				onClose={handleClose}
				icon={solid('list-timeline')}
			/>
			<DialogContent>
				{inspection && <Timeline inspection={inspection} />}
			</DialogContent>
		</Dialog>
	);
};
