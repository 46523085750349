import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { useDispatch } from 'react-redux';

import { DialogTitle } from 'components';
import { ModalFlowActions, TeammatesActions } from 'store/actions';
import { Dispatch } from 'types';

import { LoadingButton } from '@mui/lab';
import { BulkEditUsersPermissionGroupsResult } from '@rentcheck/types';
import { useEditUsersPermissionGroupsModalData } from 'hooks/modals';
import { useEffect, useState } from 'react';
import AssignPermissionGroup from './assign-permission-group';

import { EventRegister } from 'utils';

interface Props {
	open: boolean;
}

export default ({ open }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const modalData = useEditUsersPermissionGroupsModalData();
	const user_ids = modalData?.metadata.user_ids;

	const [permissionGroupIds, setPermissionGroupIds] = useState<string[]>([]);

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (open) {
			return;
		}

		setPermissionGroupIds([]);
		setLoading(false);
	}, [open]);

	const handleClose = () => {
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	const handleBulkEdit = () => {
		setLoading(true);

		dispatch(
			TeammatesActions.Users.editPermissionGroups({
				user_ids,
				permission_group_ids: permissionGroupIds,
			})
		)
			.then((res: BulkEditUsersPermissionGroupsResult) => {
				if (res.data.length === 0) {
					return;
				}

				EventRegister.emit('teammates.users.bulkActionSuccess');

				handleClose();
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle
				title="Edit Permission Groups"
				icon={solid('people-group')}
				onClose={handleClose}
			/>
			<DialogContent>
				<AssignPermissionGroup
					value={permissionGroupIds}
					setValue={setPermissionGroupIds}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} variant="text" color="secondary">
					Cancel
				</Button>
				<LoadingButton
					loading={loading}
					disabled={!permissionGroupIds?.length}
					onClick={handleBulkEdit}>
					Save
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};
