import * as Sentry from '@sentry/react';
import moment from 'moment-timezone';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';
import * as serviceWorker from './serviceWorker';
import store, { persistor } from './store';

import { PersistGate } from 'redux-persist/integration/react';
import './index.css';

Sentry.init({
	dsn: 'https://63be41e081e5415587b303717c4e11dc@o398956.ingest.sentry.io/5379070',
	ignoreErrors: [
		'ResizeObserver loop completed with undelivered notifications.',
	],
});

var abbrs = {
	EST: 'Eastern Time',
	EDT: 'Eastern Time',
	CST: 'Central Time',
	CDT: 'Central Time',
	MST: 'Mountain Time',
	MDT: 'Mountain Time',
	PST: 'Pacific Time',
	PDT: 'Pacific Time',
};

moment.fn.zoneName = function () {
	var abbr = this.zoneAbbr();
	return abbrs[abbr] || abbr;
};

store.firebaseAuthIsReady.then(() => {
	ReactDOM.render(
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<App />
			</PersistGate>
		</Provider>,
		document.getElementById('root')
	);

	// If you want your app to work offline and load faster, you can change
	// unregister() to register() below. Note this comes with some pitfalls.
	// Learn more about service workers: https://bit.ly/CRA-PWA
	serviceWorker.unregister();
});
