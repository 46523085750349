import { Divider, Typography } from '@mui/material';
import { TemplateQuestion } from '@rentcheck/types';

import { Column, Spacer } from 'components';

interface Props {
	question: TemplateQuestion;
	answer: string;
	showDivider?: boolean;
	inline?: boolean;
}

export default ({
	question,
	answer,
	showDivider = true,
	inline = false,
}: Props) => {
	return (
		<Column
			style={{
				marginBottom: inline ? 12 : 6,
				marginTop: 12,
				flex: inline ? 1 : undefined,
			}}>
			<div
				style={{
					display: 'flex',
					flexDirection: inline ? 'row' : 'column',
					justifyContent: inline ? 'space-between' : 'flex-start',
					alignItems: inline ? 'center' : 'flex-start',
				}}>
				<Typography color="#00000099">{question.title}</Typography>
				<Spacer height={1} />
				<Typography variant="subtitle2" sx={{ wordBreak: 'break-word' }}>
					{answer}
				</Typography>
			</div>
			{showDivider && <Divider sx={{ marginTop: 1.5 }} />}
		</Column>
	);
};
