import { Chip } from '@mui/material';
import { ApiInspection } from '@rentcheck/types';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Rules } from '@rentcheck/biz';

interface Props {
	inspection: ApiInspection;
}

export default ({ inspection }: Props) => {
	if (!inspection.archived) {
		return null;
	}

	if (!Rules.Inspections.canUnarchive(inspection)) {
		return null;
	}

	return (
		<Chip label="Archived" icon={<FontAwesomeIcon icon={solid('archive')} />} />
	);
};
