import { Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CenteredColumn, LottieWrapper, Row, Spacer } from 'components';
import { SnackbarActions } from 'store/actions';
import { Dispatch } from 'types';
import { useOnScreen } from 'utils/hooks';

interface Props {
	objectName: string;
	offset: number;
	pageSize: number;
	fetch: (from: number) => Promise<any[]>;
}

const InfiniteScroll = ({ fetch, offset, objectName, pageSize }: Props) => {
	const isOutOfResults = (offset: number) => {
		return offset < pageSize;
	};

	const dispatch: Dispatch = useDispatch();

	const ref = useRef<Element>(null);

	const isVisible = useOnScreen(ref);

	const [needsToFetch, setNeedsToFetch] = useState(false);
	const [loading, setLoading] = useState(false);
	const [outOfResults, setOutOfResults] = useState(isOutOfResults(offset));

	const handleFetch = () => {
		setLoading(true);
		setNeedsToFetch(false);

		fetch(offset)
			.then((data) => {
				setOutOfResults(data.length - offset < pageSize);
			})
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		if (!needsToFetch) {
			return;
		}

		if (outOfResults) {
			return;
		}

		if (loading) {
			return;
		}

		handleFetch();
	}, [needsToFetch, loading, outOfResults]);

	useEffect(() => {
		if (!isVisible) return;

		setNeedsToFetch(true);
	}, [isVisible]);

	useEffect(() => {
		isOutOfResults(offset) && setOutOfResults(true);
	}, [offset]);

	if (outOfResults) return null;

	return (
		<div ref={ref as any}>
			<Row style={{ justifyContent: 'center' }}>
				<CenteredColumn>
					<Spacer height={4} />
					<LottieWrapper variant="dog-fetching" width={150} height={150} />
					<Typography color="#232e3a" align="center" fontWeight="500">
						We're fetching your {objectName}...
					</Typography>
				</CenteredColumn>
			</Row>
		</div>
	);
};

export default InfiniteScroll;
