import { WorkOrder } from '@rentcheck/api-frontend';

import IntegrationDetails from './common';

interface Props {
	workOrder: WorkOrder;
}

const Yardi = ({ workOrder }: Props) => {
	if (!workOrder.yardi) {
		return null;
	}

	const details = [
		{
			title: 'Problem Description:',
			value: workOrder.yardi.problem_description,
		},
		{
			title: 'Category:',
			value: `${workOrder.yardi.category}: ${workOrder.yardi.subcategory}`,
		},
		{
			title: 'Unit occupancy:',
			value: workOrder.yardi.unit_occupancy,
		},
		{
			title: 'Ok to enter:',
			value: workOrder.yardi.ok_to_enter,
		},
		{
			title: 'Access Instructions:',
			value: workOrder.yardi.access_instructions || 'None',
		},
	];

	return <IntegrationDetails details={details} />;
};

export default Yardi;
