import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { AccountSettings } from '@rentcheck/types';

interface Props {
	value: AccountSettings;
	setValue: (value?: AccountSettings) => void;
}

export default ({ value }: Props) => {
	return (
		<TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell></TableCell>
						<TableCell>Category</TableCell>
						<TableCell>Sub-category</TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					{value.skills?.map((skill) => (
						<TableRow>
							<TableCell>
								{skill.is_default && (
									<FontAwesomeIcon icon={regular('check')} />
								)}
							</TableCell>
							<TableCell>{skill.category}</TableCell>
							<TableCell>{skill.sub_category}</TableCell>
						</TableRow>
					))}

					{!value.skills?.length && (
						<TableRow>
							<TableCell colSpan={2}>No skills added</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
