import { TeamsApi } from '@rentcheck/api-frontend';
import { GetAllTeamsParams } from '@rentcheck/types';
import { Dispatch } from 'types';

export type TeamsActionType =
	| 'FETCHED_TEAMS'
	| 'IMPERSONATE'
	| 'SIGNOUT_SUCCESS';

export const actionTypes: { [index: string]: TeamsActionType } = {
	FETCHED_TEAMS: 'FETCHED_TEAMS',
	IMPERSONATE: 'IMPERSONATE',
	SIGNOUT_SUCCESS: 'SIGNOUT_SUCCESS',
};

export const getAll =
	(filters: GetAllTeamsParams['filters'] = { include_all_teams: false }) =>
	async (dispatch: Dispatch): Promise<void> =>
		TeamsApi.getAll(0, 100, filters).then((teams) =>
			dispatch({
				type: actionTypes.FETCHED_TEAMS,
				teams: teams.data,
			})
		);
