import { PropertiesApi } from '@rentcheck/api-frontend';
import { APIProperty } from '@rentcheck/types';
import { Screens } from 'components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ModalFlowActions } from 'store/actions';
import { CreateInspectionFlowType } from 'store/reducers/modal-flows';
import { getFlowType, handleClose } from './common';
import Skeleton from './skeleton';

export default () => {
	const location = useLocation();
	const dispatch = useDispatch();

	const URLParameters = new URLSearchParams(location.search);
	const propertyIds = URLParameters.get('property_ids[]');
	const nonce = URLParameters.get('nonce');

	const [loading, setLoading] = useState(true);

	const handleShowCreateModal = (
		type: CreateInspectionFlowType,
		properties: APIProperty[]
	) => {
		dispatch(
			ModalFlowActions.showCreateInspectionModal({
				property_type: type,
				properties,
			})
		);
	};

	useEffect(() => {
		if (!propertyIds) {
			setLoading(false);
			handleShowCreateModal(getFlowType(location.pathname), []);

			return;
		}

		setLoading(true);

		Promise.all(propertyIds.split(',').map((id) => PropertiesApi.getById(id)))
			.then((properties) => {
				handleShowCreateModal(getFlowType(location.pathname), properties);
			})
			.catch(handleClose)
			.finally(() => setLoading(false));
	}, [location.pathname, nonce, propertyIds]);

	if (loading) {
		return <Skeleton />;
	}

	return <Screens.Container />;
};
