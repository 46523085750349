import { Divider, Typography } from '@mui/material';
import { Column } from 'components';
import { FeatureWithMetadata } from 'store/reducers/features';

interface Props {
	feature: FeatureWithMetadata;
}

export default ({ feature }: Props) => {
	if (feature.not_applicable) {
		return null;
	}

	return (
		<Column>
			<Typography variant="overline" mt={2}>
				Notes
			</Typography>
			<Typography variant="subtitle2" mt={1}>
				{feature.notes || 'No notes'}
			</Typography>
			<Divider sx={{ mt: 2 }} />
		</Column>
	);
};
