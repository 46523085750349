import { Paper, Table, TableBody, TableContainer } from '@mui/material';
import { ApiInspectionWithTemplate } from '@rentcheck/types';

import { FeatureWithMetadata } from 'store/reducers/features';
import { useIsMobileBreakpoint } from 'utils/hooks';
import FlaggedAndCollapse from '../flagged-and-collapse';
import Header from './header';
import Row from './row';

interface Props {
	features: FeatureWithMetadata[];
	inspection: ApiInspectionWithTemplate;
}

export default ({ features, inspection }: Props) => {
	const isSmallerThanMd = useIsMobileBreakpoint('md');

	return (
		<>
			<FlaggedAndCollapse inspection={inspection} />
			<TableContainer
				component={Paper}
				elevation={isSmallerThanMd ? 0 : 1}
				sx={{
					marginTop: 2,
					marginBottom: isSmallerThanMd ? 12 : 0,
					border: isSmallerThanMd ? '1px solid #0000001F' : undefined,
				}}>
				<Table>
					<Header inspection={inspection} />

					<TableBody>
						{features.map((f) => (
							<Row
								key={f.id}
								feature={f}
								inspection={inspection}
								allFeatures={features}
							/>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};
