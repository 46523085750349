import { ApiMaintenanceReport } from '@rentcheck/types';

export type State = ApiMaintenanceReport | null;

const initialState: State = null;

type Action = {
	type: string;
	report?: ApiMaintenanceReport;
};

const reducer = (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case 'SIGNOUT_SUCCESS':
		case 'IMPERSONATE':
			return initialState;

		case 'FETCHED_REPORT':
			if (!action.report) return state;
			return action.report;

		default:
			return state;
	}
};

export default reducer;
