import { Popover } from '@mui/material';
import {
	forwardRef,
	ReactNode,
	ReactNodeArray,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from 'react';

import { useTypedSelector } from 'store/reducers/rootReducer';

interface Props {
	defaultOpen?: boolean;
	anchorChild: ReactNode;
	contentChildren: ReactNode | ReactNodeArray;
}

export interface FilterPopoverRef {
	close: () => void;
}

const FilterPopover = forwardRef<FilterPopoverRef, Props>(
	({ anchorChild, contentChildren, defaultOpen }, forwardedRef) => {
		const data = useTypedSelector((state) => state.dashboard.data);
		const ref = useRef(null);

		const [anchor, setAnchor] = useState<HTMLElement | null>(null);

		useEffect(() => {
			if (!ref.current || !defaultOpen) {
				return;
			}

			setAnchor(ref.current);
		}, [ref.current]);

		useImperativeHandle(forwardedRef, () => ({
			close: handleClose,
		}));

		const handleOpen = (e: React.MouseEvent<HTMLElement>) => {
			if (!data || data.stats.overall_total_inspections < 10) {
				return;
			}

			setAnchor(e.currentTarget);
		};

		const handleClose = () => {
			setAnchor(null);
		};

		return (
			<>
				<div onClick={handleOpen} ref={ref}>
					{anchorChild}
				</div>
				<Popover
					open={!!anchor}
					anchorEl={anchor}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
					sx={{ padding: 1, marginTop: 1 }}>
					<div
						style={{
							border: '1px solid #7D8CFF',
							borderRadius: 4,
							maxHeight: 500,
							overflowY: 'scroll',
						}}>
						{contentChildren}
					</div>
				</Popover>
			</>
		);
	}
);

export default FilterPopover;
