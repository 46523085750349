import _ from 'lodash';

import { ApiTenant } from '@rentcheck/types';
import { actionTypes, TenantActionTypes } from '../../actions/tenants';

export interface State {
	data: ApiTenant[];
	total: number;
}

type Action = {
	type: TenantActionTypes;
	tenants: ApiTenant[];
	value: number;
};

export default (
	state: State = { data: [], total: 0 },
	{ type, tenants, value }: Action
) => {
	switch (type) {
		case actionTypes.SIGNOUT_SUCCESS:
		case actionTypes.IMPERSONATE:
			return { data: [], total: 0 };
	}

	if (!tenants && !value) {
		return state;
	}

	switch (type) {
		case actionTypes.RECIEVE_TENANTS:
			return { ...state, data: tenants };

		case actionTypes.SET_TENANTS_TOTAL:
			return { ...state, total: value };

		case actionTypes.APPEND_TENANTS:
			return { ...state, data: [...state.data, ...tenants] };

		case actionTypes.CREATE_TENANTS:
			return { ...state, data: [...tenants, ...state.data] };

		case actionTypes.UPDATE_TENANTS: {
			const updatedTenant = tenants[0];
			const tenantsData = _.cloneDeep(state.data).map((t) => {
				if (t.id === updatedTenant.id) {
					return updatedTenant;
				}

				return t;
			});

			return { ...state, data: tenantsData };
		}

		case actionTypes.DELETE_TENANT: {
			return {
				...state,
				data: state.data.filter((t) => t.id !== tenants[0].id),
			};
		}

		default:
			return state;
	}
};
