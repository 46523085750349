import { ApiInspectionWithTemplate } from '@rentcheck/types';
import { formattedAddress, isEmbeddedInMobileApp } from 'utils/helpers';

import { Rules } from '@rentcheck/biz';
import GridItem from '../grid-item';

interface Props {
	inspection: ApiInspectionWithTemplate;
}

export default ({ inspection }: Props) => {
	const colSpan = Rules.Inspections.canShowAssignee(inspection) ? 1 : 2;
	const showLink =
		Rules.Inspections.canShowHyperlinkToPropertyPage(inspection) &&
		!isEmbeddedInMobileApp();

	return (
		<GridItem
			colSpan={colSpan}
			title="Address"
			value={formattedAddress(inspection.property)}
			linkTo={showLink ? `/properties/${inspection.property.id}` : undefined}
		/>
	);
};
