import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent } from '@mui/material';
import { WorkOrdersApi } from '@rentcheck/api-frontend';
import { useCreateWorkOrderModalData } from 'hooks/modals';
import { getWorkOrderCodeAndDescriptionFromSkill } from 'hooks/skills';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ModalFlowActions, SnackbarActions } from 'store/actions';
import { Dispatch } from 'types';
import { workOrderDefaultTitle } from 'utils/helpers';

import Details from './details';
import Photos from './photos';
import Title from './title';

const Content = () => {
	const dispatch: Dispatch = useDispatch();

	const modalData = useCreateWorkOrderModalData('Jenark');
	const { flags } = modalData?.metadata ?? {};

	const firstFlag = flags[0];

	const [title, setTitle] = useState(workOrderDefaultTitle(flags[0]));
	const [details, setDetails] = useState(firstFlag.note);
	const [photos, setPhotos] = useState(firstFlag.image_urls ?? []);

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		handleCreate();
	}, []);

	const handleClose = () => {
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	const handleSuccess = () => {
		dispatch(ModalFlowActions.closeCurrentModal());
		dispatch(SnackbarActions.showSuccess('Work Order Created!'));
	};

	const handleCreate = () => {
		const firstFlag = _.first(flags);

		if (!firstFlag) {
			return;
		}

		setLoading(true);

		const { code } = getWorkOrderCodeAndDescriptionFromSkill(firstFlag.skill);

		WorkOrdersApi.create({
			type: 'Jenark',
			name: title,
			workOrderCode: code,
			flags,
		})
			.then(handleSuccess)
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	const disabled = !title.length;

	return (
		<>
			<DialogContent>
				<Title value={title} setValue={setTitle} />
				<Details value={details} setValue={setDetails} />
				<Photos value={photos} setValue={setPhotos} />
			</DialogContent>
			<DialogActions>
				<Button color="secondary" variant="outlined" onClick={handleClose}>
					Back
				</Button>
				<LoadingButton
					disabled={disabled}
					variant="contained"
					loading={loading}
					onClick={handleCreate}>
					Create Work Order
				</LoadingButton>
			</DialogActions>
		</>
	);
};

export default Content;
