import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	DialogContent,
	IconButton,
	TableCell as MuiTableCell,
	styled,
} from '@mui/material';
import { DashboardProperty } from '@rentcheck/api-frontend';
import { Spacer } from 'components';
import { useHistory } from 'react-router-dom';
import ActionsMenu from 'screens/properties/list/units/table/actions-menu';
import { formatDate } from 'utils/helpers';

import { APIProperty } from '@rentcheck/types';
import Table from './table';

interface Props {
	open: boolean;
	total: number;
	data: DashboardProperty[];
	fetch: (from: number) => Promise<DashboardProperty[]>;
}

const PropertiesTable = ({ open, fetch, total, data }: Props) => {
	const headers = [
		'Address',
		'City',
		'Zip',
		'Type',
		'Team',
		'Last Inspection Completed',
	];

	return (
		<DialogContent>
			<Table
				open={open}
				fetch={fetch}
				data={data}
				total={total}
				renderRow={(d) => <PropertiesTableRow property={d} />}
				variant="properties"
				objectName="Units"
				headers={headers}
			/>
			<Spacer height={8} />
		</DialogContent>
	);
};

interface RowProps {
	property: APIProperty;
}

const PropertiesTableRow = ({ property }: RowProps) => {
	const history = useHistory();

	const handleClick = () => {
		history.push(`/properties/${property.id}`);
	};

	return (
		<>
			<TableCell onClick={handleClick}>{property.address}</TableCell>
			<TableCell onClick={handleClick}>{property.city}</TableCell>
			<TableCell onClick={handleClick}>{property.zip_code}</TableCell>
			<TableCell onClick={handleClick}>{property.property_type}</TableCell>
			<TableCell onClick={handleClick}>
				{property.team?.internal_label}
			</TableCell>
			<TableCell align="center" onClick={handleClick}>
				{formatDate(property.last_completed_inspection?.date)}
			</TableCell>
			<TableCell align="center">
				<ActionsMenu unitIds={[property.id]}>
					<IconButton size="small" sx={{ width: 28, marginLeft: -2 }}>
						<FontAwesomeIcon icon={solid('ellipsis-v')} color="gray" />
					</IconButton>
				</ActionsMenu>
			</TableCell>
		</>
	);
};

const TableCell = styled(MuiTableCell)`
	cursor: pointer;
`;

export default PropertiesTable;
