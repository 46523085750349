import { Checkbox, Typography } from '@mui/material';
import { ReminderRecipient } from '@rentcheck/types';
import { Column, SpacedRow } from 'components';
import MuiPhoneNumber from 'mui-phone-number';
import { useState } from 'react';
import styled from 'styled-components';

interface Props {
	recipient: ReminderRecipient;
	updateRecipient: (recipient: ReminderRecipient) => void;
}

const PhoneNumbers = ({ recipient, updateRecipient }: Props) => {
	const [userInputMode, setUserInputMode] = useState(false);

	if (recipient.phoneNumbers.length) {
		return (
			<>
				{recipient.phoneNumbers.map((p) => (
					<Typography color="#666666">{p}</Typography>
				))}
			</>
		);
	}

	if (userInputMode) {
		const handleChange = (phone: string, index: number) => {
			const additionalPhoneNumbers = [...recipient.additionalPhoneNumbers];
			additionalPhoneNumbers[index] = phone;
			updateRecipient({ ...recipient, additionalPhoneNumbers });
		};

		return (
			<>
				{recipient.additionalPhoneNumbers.map((p, idx) => (
					<PhoneNumberTextField
						defaultCountry={'us'}
						onChange={(e) => handleChange(e as string, idx)}
						variant="filled"
						value={p}
					/>
				))}
			</>
		);
	}

	const handleAddNumber = () => {
		setUserInputMode(true);
		const additionalPhoneNumbers = [...recipient.additionalPhoneNumbers];
		additionalPhoneNumbers.push('');
		updateRecipient({ ...recipient, additionalPhoneNumbers });
	};

	return (
		<Typography
			color="#666666"
			style={{ cursor: 'pointer', textDecoration: 'underline' }}
			onClick={handleAddNumber}>
			Add Number
		</Typography>
	);
};

export default ({ recipient, updateRecipient }: Props) => {
	const handleCheckboxChanged = () => {
		updateRecipient({ ...recipient, selected: !recipient.selected });
	};

	return (
		<SpacedRow style={{ marginBottom: 10 }}>
			<Column>
				<Typography fontWeight={600}>{recipient.email}</Typography>

				{!!recipient.name && (
					<Typography color="#666666">{recipient.name}</Typography>
				)}

				<PhoneNumbers recipient={recipient} updateRecipient={updateRecipient} />
			</Column>
			<Checkbox
				size="small"
				checked={recipient.selected}
				onChange={handleCheckboxChanged}
			/>
		</SpacedRow>
	);
};

const PhoneNumberTextField = styled(MuiPhoneNumber)`
	margin-top: 4px;
	margin-bottom: 6px;
	width: 200px;

	.MuiFilledInput-root {
		padding: 4px 12px 16px 12px;
	}
	.MuiInputAdornment-root {
		filter: grayscale();
	}
	.MuiIconButton-root {
		min-width: 20px !important;
		height: 20px !important;
	}
	.MuiFilledInput-input {
		padding: 0;
		margin-top: 4px;
		margin-bottom: -10px;
	}
`;
