import { Typography } from '@mui/material';
import { APIProperty } from '@rentcheck/types';
import { useState } from 'react';
import {
	bedroomsCount,
	fullBathroomsCount,
	halfBathroomsCount,
} from 'utils/helpers';
import { Row, Spacer, TextInput } from '../../..';

interface Props {
	property?: APIProperty;
	bedrooms?: number;
	fullBathrooms?: number;
	halfBathrooms?: number;
	setBedrooms: (count: number) => void;
	setFullBathrooms: (count: number) => void;
	setHalfBathrooms: (count: number) => void;
}

const BedroomsAndBathrooms = ({
	property,
	bedrooms,
	fullBathrooms,
	halfBathrooms,
	setBedrooms,
	setFullBathrooms,
	setHalfBathrooms,
}: Props) => {
	const setDefaultState = (
		type: 'Bedrooms' | 'Full Bathrooms' | 'Half Bathrooms'
	) => {
		switch (type) {
			case 'Bedrooms':
				if (property) {
					return bedroomsCount(property);
				} else {
					return bedrooms ?? 0;
				}
			case 'Full Bathrooms':
				if (property) {
					return fullBathroomsCount(property);
				} else {
					return fullBathrooms ?? 0;
				}
			case 'Half Bathrooms':
				if (property) {
					return halfBathroomsCount(property);
				} else {
					return halfBathrooms ?? 0;
				}
			default:
				return 0;
		}
	};

	const [selectedBedrooms, setSelectedBedrooms] = useState(
		setDefaultState('Bedrooms')
	);
	const [selectedFullBathrooms, setSelectedFullBathrooms] = useState(
		setDefaultState('Full Bathrooms')
	);
	const [selectedHalfBathrooms, setSelectedHalfBathrooms] = useState(
		setDefaultState('Half Bathrooms')
	);

	const sanitizeNumber = (v: string) => {
		const newValue = parseInt(v);
		return Math.min(Math.max(isNaN(newValue) ? 0 : newValue, 0), 10);
	};

	return (
		<div>
			<Typography variant="h6">How many bedrooms are there?</Typography>
			<Spacer height={7} />
			<TextInput
				showStepper
				type="number"
				label="Bedrooms"
				onChange={(v) => {
					const value = sanitizeNumber(v);
					setSelectedBedrooms(value);
					setBedrooms(value);
				}}
				value={selectedBedrooms.toString()}
			/>
			<Spacer height={14} />
			<Typography variant="h6">How many bathrooms are there?</Typography>
			<Spacer height={7} />
			<Row>
				<TextInput
					showStepper
					type="number"
					label="Full Bathrooms"
					onChange={(v) => {
						const value = sanitizeNumber(v);
						setSelectedFullBathrooms(value);
						setFullBathrooms(value);
					}}
					value={selectedFullBathrooms.toString()}
				/>
				<Spacer width={4} />
				<TextInput
					showStepper
					type="number"
					label="Half Bathrooms"
					onChange={(v) => {
						const value = sanitizeNumber(v);
						setSelectedHalfBathrooms(value);
						setHalfBathrooms(value);
					}}
					value={selectedHalfBathrooms.toString()}
				/>
				<Spacer width={36} />
			</Row>
		</div>
	);
};

export default BedroomsAndBathrooms;
