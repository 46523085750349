import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import IconButton from 'components/icon-button';
import { FeatureWithMetadata } from 'store/reducers/features';

interface Props {
	feature: FeatureWithMetadata;
	expanded: boolean;
	setExpanded: (expanded: boolean) => void;
}

export default ({ feature, expanded, setExpanded }: Props) => {
	if (expanded) {
		return (
			<div onClick={(e) => e.stopPropagation()}>
				<IconButton
					disabled={feature.not_applicable}
					icon={solid('caret-up')}
					tooltip="Collapse Feature"
					onClick={() => {
						setExpanded(false);
					}}
				/>
			</div>
		);
	}

	return (
		<div onClick={(e) => e.stopPropagation()}>
			<IconButton
				disabled={feature.not_applicable}
				icon={solid('caret-down')}
				tooltip="Expand Feature"
				onClick={() => setExpanded(true)}
			/>
		</div>
	);
};
