import { ApiInspectionWithTemplate } from '@rentcheck/types';

import { Rules } from '@rentcheck/biz';
import { isEmbeddedInMobileApp } from 'utils/helpers';
import GridItem from '../grid-item';

interface Props {
	inspection: ApiInspectionWithTemplate;
}

export default ({ inspection }: Props) => {
	if (!inspection.team) {
		return null;
	}

	const showLink =
		Rules.Inspections.canShowHyperlinkToPropertyPage(inspection) &&
		!isEmbeddedInMobileApp();

	return (
		<GridItem
			title="Team"
			value={inspection.team?.display_name}
			linkTo={showLink ? `/account/teams/${inspection.team.id}` : undefined}
		/>
	);
};
