import React from 'react';
import { TabBar } from 'components';

const PropertiesTabBar = () => {
	const tabBarOptions = [
		{
			label: 'Units',
			url: '/properties',
			isDefault: true,
		},
		{
			label: 'Buildings',
			url: '/properties/buildings',
		},
		{
			label: 'Communities',
			url: '/properties/communities',
		},
	];

	return <TabBar large options={tabBarOptions} baseRoute="/properties" />;
};

export default PropertiesTabBar;
