import { Box, Card, Typography } from '@mui/material';
import { ApiInspectionWithTemplate } from '@rentcheck/types';
import Filters from './filters';
import Skeleton from './skeleton';

import { Row, SearchBar } from 'components';
import { useState } from 'react';
import { ChipFilterType, UseStateType } from '../types';

interface Props {
	loading: boolean;
	inspection: ApiInspectionWithTemplate;
	chipFilterState: UseStateType<ChipFilterType[]>;
	searchFilterState: UseStateType<string>;
}

const FiltersAndSearch = ({
	inspection,
	loading,
	chipFilterState,
	searchFilterState,
}: Props) => {
	const [filtersVisible, setFiltersVisible] = useState(true);
	const [searchFilter, setSearchFilter] = searchFilterState;

	const handleToggleFilters = () => {
		setFiltersVisible(!filtersVisible);
	};

	if (loading) {
		return <Skeleton />;
	}

	return (
		<>
			<Card elevation={0} sx={{ border: '1px solid #0000001F' }}>
				<Box
					sx={{
						margin: 2,
						display: 'flex',
						alignItems: 'flex-end',
						justifyContent: 'space-between',
						flexDirection: { xs: filtersVisible ? 'column' : 'row', md: 'row' },
					}}>
					{filtersVisible && (
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								alignSelf: 'flex-start',
								mb: {
									xs: 1,
									md: 0,
								},
							}}>
							<Row>
								<Typography
									color="#4d4d4d"
									onClick={handleToggleFilters}
									mb={1}
									variant="body2"
									sx={{
										cursor: 'pointer',
									}}>
									Hide Filters
								</Typography>
							</Row>
							<Filters
								inspection={inspection}
								chipFilterState={chipFilterState}
							/>
						</Box>
					)}

					<Row style={{ flex: 1 }}>
						{!filtersVisible && (
							<Typography
								color="#4d4d4d"
								onClick={handleToggleFilters}
								variant="body2"
								sx={{
									cursor: 'pointer',
								}}>
								Show Filters
							</Typography>
						)}
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								flex: 1,
								justifyContent: 'flex-end',
							}}>
							<SearchBar value={searchFilter} onChange={setSearchFilter} />
						</div>
					</Row>
				</Box>
			</Card>
		</>
	);
};

export default FiltersAndSearch;
