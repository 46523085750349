export const fontWeight = {
	light: 300,
	regular: 400,
	medium: 500,
	bold: 600,
};

export default {
	MuiTypography: {
		variants: [
			{
				props: { variant: 'code' },
				style: {
					color: '#000',
					fontFamily: 'Monospace',
					fontSize: '0.875rem',
					fontStyle: 'normal',
					fontWeight: fontWeight.regular,
					lineHeight: 'normal',
					letterSpacing: '0.031rem',
					backgroundColor: '#EBEFF3',
					borderRadius: '2px',
					padding: '0 2px',
				},
			},
			{
				props: { variant: 'error' },
				style: {
					color: '#E63030',
					fontFamily: 'Roboto',
					fontSize: '0.875rem',
					fontStyle: 'normal',
					fontWeight: fontWeight.regular,
					lineHeight: 'normal',
					letterSpacing: '0.031rem',
				},
			},
		],
		styleOverrides: {
			h1: {
				color: '#000',
				fontFamily: 'Poppins',
				fontSize: '4rem',
				fontStyle: 'normal',
				fontWeight: fontWeight.light,
				lineHeight: 'normal',
				letterSpacing: '-0.093rem',
			},
			h2: {
				color: '#000',
				fontFamily: 'Poppins',
				fontSize: '3rem',
				fontStyle: 'normal',
				fontWeight: fontWeight.light,
				lineHeight: '3.5rem',
				letterSpacing: '-0.031rem',
			},
			h3: {
				color: '#000',
				fontFamily: 'Poppins',
				fontSize: '2.5rem',
				fontStyle: 'normal',
				fontWeight: fontWeight.regular,
				lineHeight: 'normal',
			},
			h4: {
				color: '#000',
				fontFamily: 'Poppins',
				fontSize: '2rem',
				fontStyle: 'normal',
				fontWeight: fontWeight.bold,
				lineHeight: 'normal',
				letterSpacing: '0.015rem',
			},
			h5: {
				color: '#000',
				fontFamily: 'Poppins',
				fontSize: '1.5rem',
				fontStyle: 'normal',
				fontWeight: fontWeight.medium,
				lineHeight: 'normal',
			},
			h6: {
				color: '#000',
				fontFamily: 'Poppins',
				fontSize: '1.25rem',
				fontStyle: 'normal',
				fontWeight: fontWeight.medium,
				lineHeight: 'normal',
				letterSpacing: '0.009rem',
			},
			subtitle1: {
				color: '#000',
				fontFamily: 'Poppins',
				fontSize: '1rem',
				fontStyle: 'normal',
				fontWeight: fontWeight.medium,
				lineHeight: 'normal',
				letterSpacing: '0.006rem',
			},
			subtitle2: {
				color: '#000',
				fontFamily: 'Poppins',
				fontSize: '0.875rem',
				fontStyle: 'normal',
				fontWeight: fontWeight.medium,
				lineHeight: 'normal',
				letterSpacing: '0.006rem',
			},
			body1: {
				color: '#000',
				fontFamily: 'Roboto',
				fontSize: '0.875rem',
				fontStyle: 'normal',
				fontWeight: fontWeight.regular,
				lineHeight: 'normal',
				letterSpacing: '0.031rem',
			},
			body2: {
				color: '#000',
				fontFamily: 'Roboto',
				fontSize: '0.75rem',
				fontStyle: 'normal',
				fontWeight: fontWeight.regular,
				lineHeight: 'normal',
				letterSpacing: '0.015rem',
			},
			caption: {
				color: '#000',
				fontFamily: 'Roboto',
				fontSize: '0.625rem',
				fontStyle: 'normal',
				fontWeight: fontWeight.regular,
				lineHeight: 'normal',
				letterSpacing: '0.025rem',
			},
			overline: {
				color: '#000',
				fontFamily: 'Poppins',
				fontSize: '0.625rem',
				fontStyle: 'normal',
				fontWeight: fontWeight.medium,
				lineHeight: 'normal',
				letterSpacing: '0.093rem',
				textTransform: 'uppercase',
			},
		},
	},
};
