import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Button,
	DialogActions,
	DialogContent,
	InputAdornment,
	TextField,
} from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { ApiInspectionTemplateDigest } from '@rentcheck/types';
import { Column, DialogTitle } from 'components';
import TemplateCard from 'components/template-card';
import { useCreateInspectionTemplateModalData } from 'hooks/modals';
import { useStoreInspectionTemplates } from 'hooks/templates';
import { ModalFlowActions } from 'store/actions';
import { Dispatch } from 'types';

interface Props {
	onBack: () => void;
	onSelect: (template: ApiInspectionTemplateDigest) => void;
}

export default ({ onBack, onSelect }: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const modalData = useCreateInspectionTemplateModalData();
	const propertyType = modalData?.metadata?.mode;

	const [search, setSearch] = useState('');

	const { templates } = useStoreInspectionTemplates({
		propertyTypes: [propertyType],
		search,
		includeUnavailable: true,
	});

	const handleClose = () => {
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	return (
		<>
			<DialogTitle
				title="Start from a template"
				icon={solid('copy')}
				onClose={handleClose}
			/>
			<DialogContent sx={{ flexShrink: 0, pb: 0 }}>
				<TextField
					placeholder="Search"
					variant="outlined"
					fullWidth
					value={search}
					onChange={(e) => setSearch(e.target.value)}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<FontAwesomeIcon icon={regular('search')} />
							</InputAdornment>
						),
					}}
				/>
			</DialogContent>
			<DialogContent sx={{ pt: 1, minHeight: '60vh' }}>
				<Column>
					{templates.map((t) => (
						<TemplateCard template={t} onClick={onSelect} />
					))}
				</Column>
			</DialogContent>
			<DialogActions>
				<Button variant="text" color="secondary" onClick={onBack}>
					Back
				</Button>
			</DialogActions>
		</>
	);
};
