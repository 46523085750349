import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import {
	ApiInspectionSkill,
	FlagResponsibility,
	MaintenanceFlag,
} from '@rentcheck/types';
import { DialogTitle } from 'components';
import { firestore } from 'firebase/app';
import { useFlagFeatureModalData } from 'hooks/modals';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	FeatureActions,
	ModalFlowActions,
	SnackbarActions,
} from 'store/actions';
import {
	CreateWorkOrderMetadata,
	FlagFeatureMetadata,
} from 'store/reducers/modal-flows';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';
import { v4 as uuid } from 'uuid';

import { useSkills } from 'hooks/skills';
import {
	formattedAddress,
	getThumbnailUrl,
	scrollToElement,
} from 'utils/helpers';
import { useIsMobileBreakpoint } from 'utils/hooks';
import Cost from './cost';
import IntegrationWorkOrder from './integration-work-order';
import InternalNotes from './internal-notes';
import Notes from './notes';
import Photos from './photos';
import Responsibility from './responsibility';
import Skill from './skill';

interface Props {
	open: boolean;
}

const FlagFeature = ({ open }: Props) => {
	const dispatch: Dispatch = useDispatch();
	const isMobile = useIsMobileBreakpoint();

	const profile = useTypedSelector((state) => state.activeProfile);

	const modalData = useFlagFeatureModalData();
	const { inspection, feature, flag, disableDelete, disableCreateWorkOrder } =
		(modalData?.metadata ?? {}) as FlagFeatureMetadata;

	const { skills, loading: loadingSkills } = useSkills(inspection?.id);

	const [notes, setNotes] = useState('');
	const [internalNotes, setInternalNotes] = useState('');
	const [media, setMedia] = useState<string[]>([]);
	const [cost, setCost] = useState('');
	const [skill, setSkill] = useState<ApiInspectionSkill | null>(null);
	const [responsibility, setResponsibility] = useState<FlagResponsibility>('');
	const [createWorkOrderProvider, setCreateWorkOrderProvider] =
		useState<CreateWorkOrderMetadata['provider']>();

	const [skillError, setSkillError] = useState('');
	const [notesError, setNotesError] = useState('');
	const [costError, setCostError] = useState('');

	const [loading, setLoading] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);

	useEffect(() => {
		setCreateWorkOrderProvider(undefined);
	}, [open]);

	useEffect(() => {
		if (!feature) return;
		if (loadingSkills) return;

		const defaultSkill = skills.find((s) => s.default) ?? skills[0] ?? null;

		if (flag) {
			setNotes(flag.note);
			setInternalNotes(flag.internal_note ?? '');
			setMedia([
				...(flag.image_urls ?? []),
				...(flag.video_urls ?? []).map((url) => getThumbnailUrl(url)),
			]);
			setCost(flag.cost?.toString() ?? '');
			setSkill(skills.find((s) => s.title === flag.skill) ?? defaultSkill);
			setResponsibility(flag.responsibility ?? '');

			return;
		}

		setNotes(feature.notes ?? '');
		setInternalNotes('');
		setMedia(modalData.metadata.images ?? []);
		setCost('');
		setSkill(defaultSkill);
		setResponsibility('');
	}, [open, feature, skills, loadingSkills]);

	useEffect(() => {
		setNotesError('');
	}, [notes]);

	useEffect(() => {
		setSkillError('');
	}, [skill]);

	useEffect(() => {
		setCostError('');
	}, [cost]);

	if (!inspection || !feature) return null;

	const handleClose = () => {
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	const validateForm = () => {
		let formIsValid = true;
		let elementToScrollTo: HTMLElement | null = null;

		setSkillError('');
		setNotesError('');

		if (!skill) {
			formIsValid = false;
			setSkillError('Skill is required');
			elementToScrollTo = document.getElementById('textinput-skill');
		}

		if (!notes) {
			formIsValid = false;
			setNotesError('Notes are required');
			elementToScrollTo = document.getElementById('textinput-notes');
		}

		scrollToElement(elementToScrollTo);

		return formIsValid;
	};

	const handleDelete = () => {
		if (loading || deleteLoading) return;
		if (!flag) return;

		setDeleteLoading(true);

		dispatch(FeatureActions.deleteflagFromFeature(feature, flag))
			.then(handleClose)
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setDeleteLoading(false));
	};

	const handleConfirm = () => {
		if (deleteLoading) return;
		if (!validateForm()) return;

		if (!skill) return;

		setLoading(true);

		const image_urls = [];
		const video_urls = [];

		for (const photo of media) {
			const relatedVideo = feature.videos?.find(
				(v) => getThumbnailUrl(v) === photo
			);

			if (relatedVideo) {
				video_urls.push(relatedVideo);
			} else {
				image_urls.push(photo);
			}
		}

		const payload: MaintenanceFlag = {
			id: flag?.id ?? uuid(),
			note: notes,
			internal_note: internalNotes,
			skill: skill.title,
			responsibility: responsibility ?? '',
			image_urls: image_urls,
			video_urls: video_urls,
			room: feature.section.name_with_ordinal,
			feature: feature.name,
			feature_id: feature.id,
			vendor: flag?.vendor ?? 'RentCheck',
			created_by: flag?.created_by ?? {
				id: profile.id,
				name: profile.name,
				type: profile.type,
			},
			created_date:
				flag?.created_date ?? firestore.Timestamp.fromDate(new Date()),
		};

		if (cost) {
			payload.cost = parseFloat(cost);
		}

		dispatch(FeatureActions.upsertMaintenanceFlag(feature, payload))
			.then(handleClose)
			.then(() => {
				if (createWorkOrderProvider) {
					dispatch(
						ModalFlowActions.showCreateWorkOrderModal({
							inspection,
							flags: [payload],
							provider: createWorkOrderProvider,
						})
					);
				}
			})
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	return (
		<Dialog open={open} onClose={handleClose} fullScreen={isMobile}>
			<DialogTitle
				title={`Flag ${feature.name}`}
				bottomSubtitle={formattedAddress(inspection.property)}
				icon={solid('flag')}
				onClose={handleClose}
			/>

			<DialogContent>
				<Notes value={notes} setValue={setNotes} error={notesError} />
				<InternalNotes value={internalNotes} setValue={setInternalNotes} />
				<Skill
					value={skill}
					setValue={setSkill}
					error={skillError}
					inspection={inspection}
				/>
				<Responsibility value={responsibility} setValue={setResponsibility} />
				<Photos value={media} setValue={setMedia} feature={feature} />
				<Cost value={cost} setValue={setCost} error={costError} />
				{!disableCreateWorkOrder && (
					<IntegrationWorkOrder
						value={createWorkOrderProvider}
						setValue={setCreateWorkOrderProvider}
						inspection={inspection}
					/>
				)}
			</DialogContent>
			<DialogActions>
				{!!flag && !disableDelete && (
					<LoadingButton
						color="error"
						onClick={handleDelete}
						loading={deleteLoading}>
						Remove Flag
					</LoadingButton>
				)}
				{(!flag || disableDelete) && (
					<Button
						id="button-close-flag-modal"
						variant="outlined"
						color="secondary"
						onClick={handleClose}>
						Back
					</Button>
				)}
				<LoadingButton
					variant="contained"
					onClick={handleConfirm}
					loading={loading}>
					Confirm Flag
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export default FlagFeature;
