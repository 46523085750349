import { LoadingButton } from '@mui/lab';
import { Button, Typography } from '@mui/material';
import {
	APIProperty,
	UnitPropertyType,
	UpdateUnitParams,
} from '@rentcheck/types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { PropertiesActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import styled from 'styled-components';
import { colors, spacingPx } from 'theme';
import { Dispatch } from 'types';
import {
	bedroomsCount,
	fullBathroomsCount,
	halfBathroomsCount,
} from 'utils/helpers';
import { SpacedRow, Spacer } from '../..';
import { RoomNames } from '../../../constants';
import { Modal } from '../../Common';
import {
	Address,
	BedroomsAndBathrooms,
	PropertyType,
	SelectRooms,
} from '../CreateUnit';

interface Props {
	section: EditPropertySection;
	property: APIProperty;
	setHidden: () => void;
}

export type EditPropertySection =
	| 'Property Address'
	| 'Property Type'
	| 'Bedrooms & Bathrooms'
	| 'Additional Rooms'
	| 'Common Areas'
	| 'Outside Rooms';

const EditProperty = ({ section, property, setHidden }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const accountSettings = useTypedSelector((state) => state.accountSettings);

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | undefined>(undefined);

	const [propertyType, setPropertyType] = useState(property.property_type);
	const [roomNames, setRoomNames] = useState(property.room_names);
	const [bedrooms, setBedrooms] = useState(bedroomsCount(property));
	const [fullBathrooms, setFullBathrooms] = useState(
		fullBathroomsCount(property)
	);
	const [halfBathrooms, setHalfBathrooms] = useState(
		halfBathroomsCount(property)
	);
	const [address, setAddress] = useState(property.address);
	const [city, setCity] = useState(property.city);
	const [zipcode, setZipcode] = useState(property.zip_code);

	const saveAndClose = () => {
		setLoading(true);
		const updatePayload: UpdateUnitParams['data'] = {};

		if (section === 'Property Address' && address && city && zipcode) {
			updatePayload.address = address;
			updatePayload.city = city;
			updatePayload.zip_code = zipcode;
		}

		if (section === 'Property Type' && propertyType) {
			updatePayload.property_type = propertyType as UnitPropertyType;
		}

		if (
			['Outside Rooms', 'Additional Rooms', 'Common Areas'].includes(section) &&
			roomNames
		) {
			/**
			 * If updating regular rooms we need to filter out the bedrooms and bathrooms
			 * since those are handled separately
			 */
			const filteredRoomNames = roomNames.filter(
				(r) => !r.includes('Bedroom') && !r.includes('Bathroom')
			);

			updatePayload.rooms = filteredRoomNames;
		}

		if (section === 'Bedrooms & Bathrooms') {
			updatePayload.bedrooms = bedrooms;
			updatePayload.full_bathrooms = fullBathrooms;
			updatePayload.half_bathrooms = halfBathrooms;
		}

		dispatch(PropertiesActions.units.update(property.id, updatePayload))
			.then(() => setHidden())
			.catch((e) => setError(e.message))
			.finally(() => setLoading(false));
	};

	const customRooms = accountSettings?.rooms.unit.map((r) => r.name) ?? [];

	const renderContent = () => {
		switch (section) {
			case 'Property Address':
				return (
					<Address
						property={property}
						setAddress={setAddress}
						setCity={setCity}
						setZipcode={setZipcode}
					/>
				);
			case 'Property Type':
				return <PropertyType property={property} setValue={setPropertyType} />;
			case 'Bedrooms & Bathrooms':
				return (
					<BedroomsAndBathrooms
						property={property}
						setBedrooms={setBedrooms}
						setFullBathrooms={setFullBathrooms}
						setHalfBathrooms={setHalfBathrooms}
					/>
				);

			case 'Additional Rooms':
				return (
					<SelectRooms
						property={property}
						options={[...RoomNames.main, ...customRooms]}
						setValue={setRoomNames}
					/>
				);
			case 'Common Areas':
				return (
					<SelectRooms
						property={property}
						options={RoomNames.common}
						setValue={setRoomNames}
					/>
				);
			case 'Outside Rooms':
				return (
					<SelectRooms
						property={property}
						options={RoomNames.outside}
						setValue={setRoomNames}
					/>
				);
		}
	};

	return (
		<Modal targetId="edit-property" setHidden={setHidden}>
			<Typography variant="h6">Update {section}</Typography>
			<Typography>
				{property.address}, {property.city} {property.zip_code}
			</Typography>
			<Spacer height={7} />
			{renderContent()}
			<Spacer height={7} />
			<Divider />
			<Spacer height={5} />
			{!!error && (
				<div>
					<Typography color={colors.danger}>{error}</Typography>
					<Spacer height={5} />
				</div>
			)}
			<SpacedRow>
				<ButtonContainer left>
					<Button variant="text" onClick={setHidden}>
						Cancel
					</Button>
				</ButtonContainer>
				<ButtonContainer right>
					<LoadingButton
						loading={loading}
						variant="contained"
						onClick={saveAndClose}>
						Save & Close
					</LoadingButton>
				</ButtonContainer>
			</SpacedRow>
		</Modal>
	);
};

const Divider = styled.div`
	height: 1px;
	width: 100%;
	background-color: ${colors.contentBorder};
`;

const ButtonContainer = styled.div<{ right?: boolean; left?: boolean }>`
	display: flex;
	flex-direction: column;
	padding-left: ${({ right }) => (right ? spacingPx(2) : 0)};
	padding-right: ${({ left }) => (left ? spacingPx(2) : 0)};
`;

export default EditProperty;
