import { Chip, MenuItem, Menu as MuiMenu } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { MaintenanceFlag } from '@rentcheck/types';

import { colors, spacingPx } from 'theme';

interface Props {
	flag: MaintenanceFlag;
}

const WorkOrdersMenu = ({ flag }: Props) => {
	const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

	const handleClose = () => {
		setAnchorElement(null);
	};

	if (!flag.work_orders?.length) {
		return null;
	}

	const sortedWorkOrders = flag.work_orders.sort((a, b) =>
		a.name.localeCompare(b.name)
	);

	return (
		<>
			<Chip
				id={`chip-work_orders_${flag.feature}_${flag.skill}`}
				onClick={(e) => setAnchorElement(e.currentTarget)}
				label="Work Order"
				variant="outlined"
				sx={{
					marginTop: 0.5,
					marginBottom: 0.5,
					width: 170,
					fontWeight: 500,
					backgroundColor: colors.white,
					borderColor: colors.lightGray,
					color: '#353535',
				}}
			/>
			<Menu
				autoFocus={false}
				open={!!anchorElement}
				anchorEl={anchorElement}
				onClose={handleClose}>
				{sortedWorkOrders.map((wo) => (
					<Link
						key={wo.id}
						to={`/maintenance/work-orders/${wo.id}`}
						style={{ color: '#000' }}>
						<MenuItem sx={{ minWidth: 180 }}>{wo.name}</MenuItem>
					</Link>
				))}
			</Menu>
		</>
	);
};

const Menu = styled(MuiMenu)`
	.MuiMenu-paper {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	ul {
		border-top: none;
		border-radius: 4px;
		border-top-left-radius: 0;
		border-top-right-radius: 0;

		li {
			display: flex;
			justify-content: center;
			padding-left: ${spacingPx(4)};
			background-color: ${colors.white};
			transition: background-color 0.5s;
			:hover {
				background-color: #ccd6e166;
			}
		}

		li:nth-child(2n + 1) {
			background-color: #47474708;
			:hover {
				background-color: #ccd6e166;
			}
		}
	}
`;

export default WorkOrdersMenu;
