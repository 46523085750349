import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Column, Row } from 'components';
import { FilterType } from 'components/lists/filters';
import FilterButton from 'components/table/filter-button';
import { appliedFiltersCount } from 'store/reducers/maintenance/filters/work-orders';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { hasRentManagerWorkOrderAddons } from 'utils/integrations';
import styled from 'styled-components';
import * as theme from 'theme';

import {
	FilterDateDetails,
	FilterDateTitle,
} from '../../common/filter-created-date';
import {
	FilterInspectionTypeDetails,
	FilterInspectionTypeTitle,
} from '../../common/filter-inspection-type';
import { FilterStatusDetails, FilterStatusTitle } from './filter-status';

const Filters = () => {
	const dispatch = useDispatch();

	const filters = useTypedSelector(
		(state) => state.maintenance.filters.workOrders
	);

	const subscription = useTypedSelector((state) => state.subscription);
	const showStatusFilter = !hasRentManagerWorkOrderAddons(subscription);

	const [selectedFilter, setSelectedFilter] = useState<
		FilterType | undefined
	>();

	const handleResetFilters = () => {
		dispatch({ type: 'RESET_WORK_ORDERS_FILTERS' });
	};

	return (
		<FilterButton
			resetFilters={handleResetFilters}
			badgeCount={appliedFiltersCount(filters)}>
			<Row style={{ alignItems: 'flex-start' }}>
				<FilterTitles>
					<FilterDateTitle
						setFilterDetails={setSelectedFilter}
						selectedFilter={selectedFilter}
						filter="workOrders"
					/>
					{showStatusFilter && (
						<FilterStatusTitle
							setFilterDetails={setSelectedFilter}
							selectedFilter={selectedFilter}
						/>
					)}
					<FilterInspectionTypeTitle
						setFilterDetails={setSelectedFilter}
						selectedFilter={selectedFilter}
						filter="workOrders"
					/>
				</FilterTitles>

				<FilterDetails>
					<FilterDateDetails
						selectedFilter={selectedFilter}
						filter="workOrders"
						type="WORK_ORDERS"
					/>
					{showStatusFilter && (
						<FilterStatusDetails selectedFilter={selectedFilter} />
					)}
					<FilterInspectionTypeDetails
						selectedFilter={selectedFilter}
						filter="workOrders"
						type="WORK_ORDERS"
					/>
				</FilterDetails>
			</Row>
		</FilterButton>
	);
};

const FilterTitles = styled(Column)`
	flex: 1;
	padding: ${theme.spacingPx(2)} 0;
	border-right: 1px solid ${theme.colors.lightGray};
`;

const FilterDetails = styled(Column)`
	flex: 1;
	margin-left: -1px;
	border-left: 1px solid ${theme.colors.lightGray};
	padding: ${theme.spacingPx(2)} 0;
`;

export default Filters;
