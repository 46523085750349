import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Checkbox,
	IconButton,
	TableCell,
	TableRow,
	Typography,
} from '@mui/material';
import { ApiMaintenanceReport } from '@rentcheck/types';
import { Column } from 'components';
import { useHistory } from 'react-router-dom';
import { formatDate } from 'utils/helpers';
import ActionsMenu from './actions-menu';

interface Props {
	report: ApiMaintenanceReport;
	selectedIds: string[];
	selectId: (id: string) => void;
	setSelectedIds: (ids: string[]) => void;
}

interface CellProps {
	report: ApiMaintenanceReport;
}

const NameAndSkill = ({ report }: CellProps) => {
	return (
		<Column>
			<Typography>{report.name}</Typography>
		</Column>
	);
};

const ReportRow = ({
	report,
	selectedIds,
	selectId,
	setSelectedIds,
}: Props) => {
	const history = useHistory();

	const handleClick = () => {
		history.push(`/maintenance/reports/${report.id}`);
	};

	return (
		<TableRow
			sx={{
				'&:last-child td, &:last-child th': { border: 0 },
				cursor: 'pointer',
			}}>
			<TableCell>
				<Checkbox
					size="small"
					checked={selectedIds.includes(report.id)}
					onChange={() => selectId(report.id)}
				/>
			</TableCell>

			<TableCell onClick={handleClick}>{report.property.address}</TableCell>
			<TableCell onClick={handleClick}>{report.property.city}</TableCell>
			<TableCell onClick={handleClick}>{report.property.zip_code}</TableCell>
			<TableCell onClick={handleClick}>
				<NameAndSkill report={report} />
			</TableCell>
			<TableCell onClick={handleClick}>
				{report.inspection.template.name}
			</TableCell>
			<TableCell onClick={handleClick}>
				{formatDate(report.created_date)}
			</TableCell>
			<TableCell align="center">
				<ActionsMenu ids={[report.id]} setSelectedIds={setSelectedIds}>
					<IconButton size="small" sx={{ width: 28, marginLeft: -2 }}>
						<FontAwesomeIcon icon={solid('ellipsis-v')} color="gray" />
					</IconButton>
				</ActionsMenu>
			</TableCell>
		</TableRow>
	);
};

export default ReportRow;
