import { Paper, SxProps, Theme, Typography } from '@mui/material';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TemplateFeature, TemplateSection } from '@rentcheck/types';
import { CenteredRow, SpacedRow } from 'components';
import FormTextField from 'components/form-text-field';
import { useEffect, useRef, useState } from 'react';
import {
	Draggable,
	DraggingStyle,
	NotDraggingStyle,
} from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { ModalFlowActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';
import { scrollToElement } from 'utils/helpers';
import { useOnClickOutside } from 'utils/hooks';
import { shouldIgnoreClick } from '../../helpers/click-outside';
import ActionButtons from './action-buttons';
import Questions from './questions';
import { createQuestion } from './questions/helpers';

interface Props {
	feature: TemplateFeature;
	index: number;
	editing: boolean;
	selected?: boolean;
	sections: TemplateSection[];
	onUpdate: (feature: TemplateFeature) => void;
	onUpdateSection: (section: TemplateSection) => void;
	onSelect: (feature: TemplateFeature) => void;
	onDelete: (feature: TemplateFeature) => void;
	onAdd: (feature: TemplateFeature) => void;
	onCopy: (feature: TemplateFeature) => void;
}

const getItemStyle = (
	selected?: boolean,
	draggableStyle?: DraggingStyle | NotDraggingStyle
): SxProps<Theme> => ({
	mb: 1,
	minHeight: 54,
	padding: 2,
	backgroundColor: selected ? '#f6f5ff' : '#ffffff',
	border: selected ? '1px solid #2D3CE6' : '1px solid #0000001F',

	display: 'flex',
	flexDirection: 'column',
	transition: 'elevation 0.2s ease-in-out',
	cursor: 'pointer',

	// styles we need to apply on draggables
	...(draggableStyle as any),
});

export default ({
	feature,
	index,
	editing,
	selected,
	sections,
	onUpdate,
	onUpdateSection,
	onSelect,
	onDelete,
	onAdd,
	onCopy,
}: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const showingConfirmationModal = useTypedSelector(
		(state) => !!state.modalFlows.confirmation_modal
	);

	const [editingThisFeature, setEditingThisFeature] = useState(false);

	const clickOutsideRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		/**
		 * If the feature is empty we are looking at a recently created one
		 * enable editingThisFeature and let the user input name and description
		 */
		if (
			!feature.name.trim() &&
			!feature.description.trim() &&
			!feature.questions.length
		) {
			setEditingThisFeature(true);
			onSelect(feature);
		}
	}, [feature]);

	useEffect(() => {
		if (!editing) {
			setEditingThisFeature(false);
		}
	}, [editing]);

	useEffect(() => {
		if (!selected && feature.name.trim() && feature.description.trim()) {
			setEditingThisFeature(false);
		}
	}, [selected]);

	useEffect(() => {
		if (selected) {
			scrollToElement(
				document.getElementById(`scroll-marker-feature-${feature.id}`),
				'start'
			);
		}
	}, [selected, editing]);

	useOnClickOutside(clickOutsideRef, (e: any) => {
		setTimeout(() => handleClickedOutside(e), 150);
	});

	const handleNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		onUpdate({ ...feature, name: e.target.value });
	};

	const handleDescriptionChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		onUpdate({ ...feature, description: e.target.value });
	};

	const handleClickedOutside = (e: any) => {
		if (showingConfirmationModal) {
			return;
		}

		if (shouldIgnoreClick(e.target as any)) {
			return;
		}

		/**
		 * If the feature is empty we need to delete it
		 */
		if (
			!feature.name.trim() &&
			!feature.description.trim() &&
			!feature.questions.length
		) {
			return onDelete(feature);
		}

		/**
		 * If the feature is incomplete but not empty we need to ask the user
		 * if they want to delete it or finish setting it up
		 */
		if (!feature.name.trim() || !feature.description.trim()) {
			return dispatch(
				ModalFlowActions.showConfirmationModal({
					icon: regular('save'),
					title: 'Incomplete Feature',
					body1: [
						'The feature name and instructions need to be added in order to save.',
					],
					buttons: [
						{
							title: 'Delete Feature',
							onClick: () => {
								dispatch(ModalFlowActions.closeConfirmationModal());
								handleDelete();
							},
						},
						{
							title: 'Go Back',
							onClick: () => {
								dispatch(ModalFlowActions.closeConfirmationModal());
								onSelect(feature);
							},
						},
					],
				})
			);
		}

		handleSave();
	};

	const handleEditNameOrDescription = () => {
		if (!editing) {
			return;
		}

		setEditingThisFeature(true);
	};

	const handleDelete = () => {
		onDelete(feature);
	};

	const handleSave = () => {
		setEditingThisFeature(false);
	};

	const handleAddFeature = () => {
		onAdd(feature);
	};

	const handleCopyFeature = () => {
		onCopy(feature);
	};

	const handleAddQuestion = () => {
		onUpdate({
			...feature,
			questions: [createQuestion('yes/no'), ...feature.questions],
		});
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter') {
			return handleSave();
		}
	};

	return (
		<Draggable
			key={feature.id}
			draggableId={feature.id}
			isDragDisabled={!editing}
			index={index}>
			{(provided, snapshot) => (
				<>
					<div
						id={`scroll-marker-feature-${feature.id}`}
						style={{ marginTop: -8, marginBottom: 16 }}
					/>
					<div ref={clickOutsideRef}>
						<Paper
							ref={provided.innerRef}
							onClick={() => onSelect(feature)}
							elevation={snapshot.isDragging ? 1 : 0}
							{...provided.draggableProps}
							sx={getItemStyle(selected, provided.draggableProps.style)}>
							<div {...provided.dragHandleProps}>
								{editing && (
									<CenteredRow>
										<FontAwesomeIcon icon={regular('grip-lines')} />
									</CenteredRow>
								)}

								{!editingThisFeature && (
									<Paper
										elevation={0}
										sx={{
											p: 2,
											mt: 1,
											border: editing ? '1px solid #0000001F' : 'none',
											backgroundColor: selected ? '#f6f5ff' : '#ffffff',
										}}>
										<SpacedRow style={{ minHeight: 32 }}>
											<Typography
												variant="h6"
												onClick={handleEditNameOrDescription}
												sx={{ cursor: 'pointer' }}>
												{feature.name || 'New Feature'}
											</Typography>
											<ActionButtons
												feature={feature}
												editing={editing}
												editingThisFeature={editingThisFeature}
												sections={sections}
												onDelete={handleDelete}
												onAdd={handleAddFeature}
												onCopy={handleCopyFeature}
												onSave={handleSave}
												onUpdate={onUpdate}
												onAddQuestion={handleAddQuestion}
											/>
										</SpacedRow>
										<Typography
											mt={1}
											color="secondary"
											onClick={handleEditNameOrDescription}
											sx={{ cursor: 'pointer' }}>
											{feature.description}
										</Typography>
									</Paper>
								)}

								{editingThisFeature && (
									<Paper
										elevation={0}
										sx={{
											p: 2,
											mt: 1,
											border: '1px solid #0000001F',
										}}>
										<SpacedRow style={{ minHeight: 32 }}>
											<FormTextField
												fullWidth
												autoFocus
												required
												inputSx={{ mb: 1 }}
												value={feature.name}
												onChange={handleNameChanged}
												placeholder="Add Feature Name. Example: Walls"
												helperText="0-50 Characters"
												inputProps={{ maxLength: 50 }}
											/>

											<ActionButtons
												feature={feature}
												editing={editing}
												editingThisFeature={editingThisFeature}
												sections={sections}
												onDelete={handleDelete}
												onAdd={handleAddFeature}
												onCopy={handleCopyFeature}
												onUpdate={onUpdate}
												onSave={handleSave}
												onAddQuestion={handleAddQuestion}
											/>
										</SpacedRow>
										<FormTextField
											fullWidth
											multiline
											required
											onKeyPress={handleKeyPress}
											onChange={handleDescriptionChanged}
											value={feature.description}
											placeholder="Add Feature Instructions. Example: Stand back from each corner of the room to ensure you get photos of the room and wall in their entirety. Focus on any damage."
											helperText="0-145 Characters"
											inputProps={{ maxLength: 145 }}
										/>
									</Paper>
								)}
							</div>
							<Questions
								editing={editing}
								feature={feature}
								sections={sections}
								onUpdateFeature={onUpdate}
								onUpdateSection={onUpdateSection}
							/>
						</Paper>
					</div>
				</>
			)}
		</Draggable>
	);
};
