import { Rules } from '@rentcheck/biz';
import { ApiInspectionWithTemplate } from '@rentcheck/types';
import {
	ApproveReviewEditsButton,
	RevisionButton,
	RevisionNotesButton,
} from 'components/features';
import { FeatureWithMetadata } from 'store/reducers/features';

interface Props {
	feature: FeatureWithMetadata;
	inspection: ApiInspectionWithTemplate;
}

export default ({ feature, inspection }: Props) => {
	if (!Rules.Inspections.canPerformFeatureActions(inspection)) {
		return null;
	}

	return (
		<div onClick={(e) => e.stopPropagation()}>
			<ApproveReviewEditsButton feature={feature} inspection={inspection} />
			<RevisionNotesButton feature={feature} inspection={inspection} />
			<RevisionButton feature={feature} inspection={inspection} />
		</div>
	);
};
