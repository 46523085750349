import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
	ApiInspectionEvent,
	InspectionEventInspectionSharedMetadata,
} from '@rentcheck/types';
import BaseTimelineEvent from '../base-event';

interface Props {
	event: ApiInspectionEvent;
}

export default ({ event }: Props) => {
	const metadata = event.metadata as InspectionEventInspectionSharedMetadata;

	const content = [
		`Shared by ${metadata.shared_by}`,
		'Sent to:',
		metadata.emails_sent.join(', '),
	];

	if (metadata.note) {
		content.push('Note:');
		content.push(metadata.note);
	}

	return (
		<BaseTimelineEvent
			icon={solid('envelope')}
			title="Maintenance report shared"
			subtitle={`Shared by ${metadata.shared_by}`}
			sentTo={metadata.emails_sent}
			note={metadata.note}
			timestamp={event.timestamp}
		/>
	);
};
