import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import IconButton from 'components/icon-button';
import { useState } from 'react';
import { colors } from 'theme';

interface Props {
	text: string;
}

export default ({ text }: Props) => {
	const [copied, setCopied] = useState(false);

	const handleClick = () => {
		navigator.clipboard.writeText(text);

		setCopied(true);

		setTimeout(() => setCopied(false), 2000);
	};

	if (copied) {
		return (
			<IconButton
				icon={regular('check')}
				buttonSx={{ color: colors.success, mt: -0.75, mb: -0.75, mr: -1 }}
			/>
		);
	}

	return (
		<IconButton
			icon={regular('copy')}
			buttonSx={{ color: '#fff', mt: -0.75, mb: -0.75, mr: -1 }}
			onClick={handleClick}
		/>
	);
};
