import { Dialog } from '@mui/material';
import _ from 'lodash';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { LatchelApi } from '@rentcheck/api-frontend';

import {
	ModalFlowActions,
	SnackbarActions,
	TeamActions,
	TeamsActions,
} from 'store/actions';
import { Dispatch } from 'types';

import { useLatchelIntegrations } from 'hooks/latchel-integrations';
import Latchel from './latchel';
import TeamLogo from './logo';
import TeamName from './name';

interface Props {
	open: boolean;
}

export type CreateTeamStep = 'Team Name' | 'Logo' | 'Latchel';

const TeamModal = ({ open }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const { latchelIntegrations } = useLatchelIntegrations();

	const [stepHistory, setStepHistory] = useState<CreateTeamStep[]>([
		'Team Name',
	]);

	const [name, setName] = useState('');
	const [internalLabel, setInternalLabel] = useState('');
	const [logo, setLogo] = useState<File | undefined>(undefined);
	const [latchelApiKey, setLatchelApiKey] = useState<string | null>(null);
	const [loading, setLoading] = useState(false);

	const hasLatchelIntegrations = latchelIntegrations.length;

	const pushStep = (newStep: CreateTeamStep) => {
		setStepHistory([...stepHistory, newStep]);
	};

	const backStep = () => {
		if (stepHistory.length <= 1) {
			return;
		}

		setStepHistory(stepHistory.slice(0, stepHistory.length - 1));
	};

	const handleCreateTeam = async () => {
		setLoading(true);
		dispatch(TeamActions.create(name, internalLabel, logo))
			.then(async (organization) => {
				if (!latchelApiKey) return organization;

				await LatchelApi.connect(latchelApiKey, [organization.id]);

				await dispatch(TeamsActions.getAll());

				return organization;
			})
			.then((organization) => {
				dispatch(ModalFlowActions.closeCurrentModal());
				dispatch(ModalFlowActions.showInviteUsers({ teams: [organization] }));
			})
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	const handleClose = () => {
		setName('');
		setInternalLabel('');
		setLogo(undefined);
		setStepHistory(['Team Name']);

		dispatch(ModalFlowActions.closeCurrentModal());
	};

	const renderContent = () => {
		const currentStep = _.last(stepHistory);

		switch (currentStep) {
			case 'Team Name':
				return (
					<TeamName
						name={name}
						internalLabel={internalLabel}
						setName={setName}
						setInternalLabel={setInternalLabel}
						cancel={handleClose}
						nextStep={() => pushStep('Logo')}
					/>
				);
			case 'Logo':
				return (
					<TeamLogo
						internalLabel={internalLabel}
						setLogo={setLogo}
						nextStep={
							hasLatchelIntegrations
								? () => pushStep('Latchel')
								: handleCreateTeam
						}
						back={backStep}
						loading={loading}
					/>
				);
			case 'Latchel':
				return (
					<Latchel
						internalLabel={internalLabel}
						setApiKey={setLatchelApiKey}
						nextStep={handleCreateTeam}
						back={backStep}
						loading={loading}
					/>
				);
		}
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			{renderContent()}
		</Dialog>
	);
};

export default TeamModal;
