import { Row } from 'components';

import AddFilter from './add';
import BuildingFilter from './buildings';
import DateFilter from './date';
import PropertyTypeFilter from './property-type';
import TeamFilter from './team';

const Filters = () => {
	return (
		<Row>
			<DateFilter />
			<PropertyTypeFilter />
			<TeamFilter />
			<BuildingFilter />
			<AddFilter />
		</Row>
	);
};

export default Filters;
