import { FilterMenuItem } from 'components/table/filter-button-v2';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { actionType, icon, useFilter } from './common';

export default () => {
	const dispatch = useDispatch();
	const filter = useFilter();

	const profile = useTypedSelector((state) => state.activeProfile);

	const handleClicked = () => {
		dispatch({
			type: actionType,
			value: [profile.id],
		});
	};

	const disabled = filter.length > 0;
	const tooltip = disabled ? 'This filter is already in use' : undefined;

	return (
		<FilterMenuItem
			title={'Assigned To Me'}
			icon={icon}
			onClick={handleClicked}
			{...{ disabled, tooltip }}
		/>
	);
};
