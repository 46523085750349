import { Chip, Typography } from '@mui/material';
import { OccupanciesApi } from '@rentcheck/api-frontend';
import {
	APIProperty,
	ApiOccupancy,
	ApiTenant,
	Occupancy,
} from '@rentcheck/types';
import { Column, Row, SpacedRow } from 'components';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from 'theme';
import { Recipient } from './common';

interface Props {
	tenant: ApiTenant;
	properties: Pick<APIProperty, 'id'>[];
	recipients: Recipient[];
	setRecipients: (value: Recipient[]) => void;
}

export default ({ tenant, properties, recipients, setRecipients }: Props) => {
	const [occupancies, setOccupancies] = useState<ApiOccupancy[]>([]);

	useEffect(() => {
		OccupanciesApi.getForTenant(tenant.id)
			.then(setOccupancies)
			.catch(() => {});
	}, [tenant]);

	const isTenantStatusForProperties = (status: Occupancy['status']) => {
		const propertyIds = occupancies
			.filter((o) => o.status === status)
			.map((o) => o.property.id);

		return !!properties.find((p) => propertyIds.includes(p.id));
	};

	const isCurrent = isTenantStatusForProperties('current');
	const isFuture = isTenantStatusForProperties('future');
	const isPast = isTenantStatusForProperties('past');

	const tenantRecipients = recipients.filter((r) => typeof r !== 'string');
	const tenantRecipientIds = tenantRecipients.map((r) => (r as ApiTenant).id);
	const isSelected =
		tenantRecipientIds.includes(tenant.id) ||
		recipients.includes(tenant.emails[0]);

	const handleSelected = () => {
		if (isSelected) {
			setRecipients(
				recipients.filter((t) => t !== tenant && t !== tenant.emails[0])
			);

			return;
		}

		setRecipients([...recipients, tenant]);
	};

	return (
		<OptionContainer onClick={handleSelected} selected={isSelected}>
			<Row>
				<Column>
					<Typography variant="subtitle1" color={colors.secondary}>
						{tenant.first_name} {tenant.last_name}
					</Typography>
					<Typography variant="body2" color={colors.secondary}>
						{tenant.emails[0]}
					</Typography>
				</Column>
			</Row>
			<Row>
				{isPast && <Chip label="Past" />}
				{isCurrent && <Chip label="Current" sx={{ marginLeft: 1 }} />}
				{isFuture && <Chip label="Future" sx={{ marginLeft: 1 }} />}
			</Row>
		</OptionContainer>
	);
};

const OptionContainer = styled(SpacedRow)<{ selected: boolean }>`
	transition: 0.2s background-color ease-in-out;
	cursor: pointer;
	padding: 8px 16px;

	background-color: ${({ selected }) =>
		selected ? '#2d3ce60a' : 'transparent'};

	&:hover {
		background-color: #2d3ce60a;
	}
`;
