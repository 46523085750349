import { LoadingButton } from '@mui/lab';
import { Card, CardContent, Typography } from '@mui/material';
import { AccountSettings } from '@rentcheck/types';

import { SpacedRow, Spacer } from 'components';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';

import CustomSkills from './custom-skills';
import { AccountSettingsApi } from '@rentcheck/api-frontend';

export default () => {
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const [accountSettings, setAccountSettings] = useState<AccountSettings>();

	const handleSearchAccountSettings = async () => {
		setLoading(true);

		try {
			const accountSettings = await AccountSettingsApi.get();

			if (!accountSettings) {
				throw Error('Account Settings not found');
			}

			setAccountSettings(accountSettings);
		} catch (e) {
			dispatch(SnackbarActions.showError((e as Error).message));
		}

		setLoading(false);
	};

	return (
		<Card>
			<CardContent>
				<Typography variant="h5">Customize Maintenance Skills</Typography>
				<Spacer height={2} />

				<Typography variant="body1">
					You need to impersonate the target user in order to customize their
					maintenance skills.
				</Typography>
				<Spacer height={2} />
				<SpacedRow style={{ alignItems: 'flex-start', width: '50%' }}>
					<LoadingButton
						sx={{ height: 56 }}
						loading={loading}
						onClick={handleSearchAccountSettings}>
						Get account settings
					</LoadingButton>
				</SpacedRow>

				<CustomSkills value={accountSettings} setValue={setAccountSettings} />
			</CardContent>
		</Card>
	);
};
