import { Button, Divider, Typography } from '@mui/material';
import { Column } from 'components/layout/Column';
import { SpacedRow } from 'components/layout/Row';
import { ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
	title: string;
	children?: ReactNode | ReactNode[];
	onApply: () => void;
	applyDisabled?: boolean;
}

export default ({ title, children, onApply, applyDisabled }: Props) => {
	return (
		<>
			<Typography variant="overline" ml={1} color="#445567">
				{title}
			</Typography>
			<Content>{children}</Content>
			<Divider sx={{ mb: 1 }} />
			<SpacedRow>
				<div />
				<Button
					onClick={onApply}
					size="small"
					sx={{ borderRadius: 1 }}
					disabled={applyDisabled}>
					Apply
				</Button>
			</SpacedRow>
		</>
	);
};

const Content = styled(Column)`
	padding: 8px;
`;
