import { LoadingButton } from '@mui/lab';
import { Checkbox, TextField, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { CenteredColumn, Row, Spacer } from 'components';
import { SnackbarActions } from 'store/actions';
import { isInvalidPassword } from 'utils/helpers';

import { AdditionalScreenErrors } from 'store/actions/auth-actions';
import { EMAIL_REGEX } from '../../../constants';

interface Props {
	email: string;
	emailSuggestion: string;
	setEmail: (email: string) => void;
	password: string;
	setPassword: (password: string) => void;
	firstName: string;
	setFirstName: (firstName: string) => void;
	lastName: string;
	setLastName: (lastName: string) => void;
	terms: boolean;
	setTerms: (selected: boolean) => void;
	defaultPage: string;
	error: AdditionalScreenErrors;
	setError: (error: AdditionalScreenErrors) => void;
	signUpUser: () => void;
	loading: boolean;
}

const AdditionalStep = ({
	email,
	emailSuggestion,
	setEmail,
	password,
	setPassword,
	firstName,
	setFirstName,
	lastName,
	setLastName,
	terms,
	setTerms,
	defaultPage,
	error,
	loading,
	setError,
	signUpUser,
}: Props) => {
	const dispatch = useDispatch();

	const submit = () => {
		if (!EMAIL_REGEX.test(email)) {
			setError('email');
			return;
		}

		if (password.length < 8) {
			setError('length');
			return;
		}

		if (isInvalidPassword(password)) {
			setError('invalid');
			return;
		}

		if (!firstName.length) {
			setError('first');
			return;
		}

		if (!lastName.length) {
			setError('last');
			return;
		}

		setError('');

		if (!terms) {
			dispatch(
				SnackbarActions.showError('Please agree to the Terms of Service')
			);
			return;
		}

		signUpUser();
	};

	return (
		<CenteredColumn>
			<Form
				onSubmit={(e) => {
					e.preventDefault();
					submit();
				}}>
				<TextField
					fullWidth
					id="email"
					variant="filled"
					type="email"
					label="Email"
					value={email}
					color={emailSuggestion ? 'warning' : 'primary'}
					focused={emailSuggestion !== ''}
					onChange={(e) => setEmail(e.target.value)}
					error={error === 'email'}
					helperText={error === 'email' ? 'Email is invalid' : ' '}
				/>

				{emailSuggestion && (
					<Typography mb={2} mt={-1}>
						Did you mean{' '}
						<Link to="#" onClick={() => setEmail(emailSuggestion)}>
							{emailSuggestion}
						</Link>
						?
					</Typography>
				)}

				<TextField
					fullWidth
					id="password"
					variant="filled"
					type="password"
					label="Password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					error={error === 'length' || error === 'invalid'}
					helperText={
						error === 'length'
							? 'Password must be at least 8 characters'
							: error === 'invalid'
								? "Make sure your password doesn't consist entirely of repeated or sequential characters"
								: ' '
					}
				/>

				<TextField
					fullWidth
					id="first-name"
					variant="filled"
					label="First Name"
					value={firstName}
					onChange={(e) => setFirstName(e.target.value)}
					error={error === 'first'}
					helperText={error === 'first' ? 'Name cannot be blank' : ' '}
				/>

				<TextField
					fullWidth
					id="last-name"
					variant="filled"
					label="Last Name"
					value={lastName}
					onChange={(e) => setLastName(e.target.value)}
					error={error === 'last'}
					helperText={error === 'last' ? 'Name cannot be blank' : ' '}
				/>

				<Spacer height={2} />

				<Row>
					<Checkbox
						id="terms-of-service"
						onClick={() => setTerms(!terms)}
						checked={terms}
						inputProps={{ 'aria-label': 'controlled' }}
					/>

					<Typography>I agree to the</Typography>
					<Spacer width={1} />
					<a href="https://getrentcheck.com/termsofservice" target="_blank">
						Terms of Service
					</a>
				</Row>

				<Spacer height={10} />

				<LoadingButton
					fullWidth
					type="submit"
					onClick={submit}
					variant="contained"
					loading={loading}>
					Sign up
				</LoadingButton>
			</Form>
			<Spacer height={6} />

			<Row>
				Already have an account?
				<Spacer width={1} />
				<Link to={`/signin/${defaultPage}`}>Log in</Link>
			</Row>
		</CenteredColumn>
	);
};

const Form = styled.form`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
`;

export default AdditionalStep;
