import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import IconButton from 'components/icon-button';
import { useDispatch } from 'react-redux';
import { ModalFlowActions } from 'store/actions';

export default () => {
	const dispatch = useDispatch();

	const handleClose = () => {
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	return <IconButton icon={regular('times')} onClick={handleClose} />;
};
