import { CardContent, Typography } from '@mui/material';

import { SpacedRow } from 'components';

import LastUpdated from './last-updated';

const Header = () => {
	return (
		<CardContent>
			<SpacedRow>
				<Typography variant="h6">Daily Snapshot:</Typography>
				<LastUpdated />
			</SpacedRow>
		</CardContent>
	);
};

export default Header;
