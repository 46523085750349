import { Tooltip } from '@mui/material';
import { ImportDigest, ImportStatus } from '@rentcheck/types';
import moment from 'moment';

interface Props {
	object: ImportDigest;
}

const mapStatusToDisplayStatus = (status: ImportStatus) => {
	switch (status) {
		case 'completed':
			return 'Completed';
		case 'failed':
			return 'Failed';
		case 'processing':
			return 'In process';
		case 'scheduled':
			return 'Scheduled';
	}
};

export default ({ object }: Props) => {
	const status = mapStatusToDisplayStatus(object.status);

	return (
		<Tooltip
			title={
				object.completed_at
					? moment(object.completed_at).format('MM/DD/YYYY [at] hh:mm A')
					: ''
			}>
			<div>{status}</div>
		</Tooltip>
	);
};
