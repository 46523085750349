import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Divider, Typography } from '@mui/material';
import { Rules } from '@rentcheck/biz';
import { ApiInspectionWithTemplate } from '@rentcheck/types';
import { Column, Row } from 'components';
import { FlagChip } from 'components/features';
import { useDispatch } from 'react-redux';
import { ModalFlowActions } from 'store/actions';
import { FeatureWithMetadata } from 'store/reducers/features';

interface Props {
	feature: FeatureWithMetadata;
	inspection: ApiInspectionWithTemplate;
}

export default ({ feature, inspection }: Props) => {
	const dispatch = useDispatch();

	if (!Rules.Inspections.canPerformFeatureActions(inspection)) {
		return null;
	}

	const handleAddFlag = () => {
		dispatch(ModalFlowActions.showFlagFeatureModal({ feature, inspection }));
	};

	return (
		<Column>
			<Typography variant="overline" mt={2}>
				Flags
			</Typography>

			<Row style={{ flexWrap: 'wrap', marginTop: 6 }}>
				{feature.maintenance_flags.map((flag) => (
					<FlagChip
						key={flag.id}
						flag={flag}
						feature={feature}
						inspection={inspection}
					/>
				))}
				<Button
					onClick={handleAddFlag}
					startIcon={<FontAwesomeIcon icon={solid('plus')} />}
					variant="text"
					color="secondary">
					Add Flag
				</Button>
			</Row>
			<Divider sx={{ mt: 2 }} />
		</Column>
	);
};
