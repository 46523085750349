import { Dialog, DialogContent, DialogTitle, Divider } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { Spacer } from 'components';

import { Utils } from '@rentcheck/biz';
import { imageIs360Photo } from 'utils/helpers';
import Header from './header';
import Image from './image';
import Image360 from './image-360';
import Video from './video';

export default () => {
	const { search } = useLocation();

	const searchParams = new URLSearchParams(search);

	const imageType = searchParams.get('image_url') ?? '';
	const isVideo =
		imageType.includes('firebase/video?') || Utils.Media.isVideo(imageType);

	return (
		<Dialog fullScreen open>
			<DialogTitle sx={{ paddingLeft: 4, paddingRight: 4 }}>
				<Header />
				<Spacer height={4} />
				<Divider />
			</DialogTitle>

			<DialogContent>
				{!imageIs360Photo(imageType) && !isVideo && <Image />}
				{isVideo && <Video />}
				{imageIs360Photo(imageType) && <Image360 />}
			</DialogContent>
		</Dialog>
	);
};
