import { Chip } from '@mui/material';
import { BillingModalOption } from '@rentcheck/types';

interface Props {
	plan: BillingModalOption['plan'];
}

export default ({ plan }: Props) => {
	if (!plan.is_most_popular) {
		return null;
	}

	return (
		<Chip
			label="Most Popular"
			color="success"
			style={{
				color: '#4D4D4D',
				backgroundColor: '#C8FFD9',
				marginTop: -8,
				marginBottom: -8,
			}}
		/>
	);
};
