import { TemplateQuestion } from '@rentcheck/types';
import { v4 as uuid } from 'uuid';

export const createQuestion = (
	type: TemplateQuestion['type']
): TemplateQuestion => {
	switch (type) {
		case 'yes/no':
			return {
				id: uuid(),
				type: 'yes/no',
				title: '',
				answer_type: 'single',
				answers: [
					{
						id: uuid(),
						title: 'Yes',
						requires_photo: false,
						requires_note: false,
					},
					{
						id: uuid(),
						title: 'No',
						requires_photo: false,
						requires_note: false,
					},
				],
			};
		case 'free text':
			return {
				id: uuid(),
				type: 'free text',
				title: '',
				answer_type: 'single',
				answers: [],
			};
		case 'multiple choice':
			return {
				id: uuid(),
				type: 'multiple choice',
				title: '',
				answer_type: 'single',
				answers: [
					{
						id: uuid(),
						title: 'Yes',
						requires_photo: false,
						requires_note: false,
					},
					{
						id: uuid(),
						title: 'No',
						requires_photo: false,
						requires_note: false,
					},
				],
			};
		case 'asset capture':
			return {
				id: uuid(),
				type: 'asset capture',
				title: 'Serial Number',
				answer_type: 'single',
				answers: [],
			};
	}
};
