import { Paper } from '@mui/material';
import { InspectionReportTemplate } from '@rentcheck/types';
import { Column } from 'components';

import OptionGroup, { ReportOption } from '../common/option-group';
import SectionHeader from '../common/section-header';

export interface Props {
	isEditing: boolean;
	payload: InspectionReportTemplate;
	setPayload: (value: InspectionReportTemplate) => void;
}

export default ({ isEditing, payload, setPayload }: Props) => {
	const payloadKey = 'maintenance_flags_section';

	const options: ReportOption[] = [
		{
			label: 'Owner Responsible Flags',
			key: 'owner_flags.section_active',
			options: [
				{
					label: 'Estimated Cost',
					key: 'owner_flags.cost',
				},
				{
					label: 'Flag Notes',
					key: 'owner_flags.notes',
				},
				{
					label: 'Internal Notes',
					key: 'owner_flags.internal_notes',
				},
			],
		},
		{
			label: 'Uncategorized Responsible Flags',
			key: 'uncategorized_flags.section_active',
			marginTop: 2,
			options: [
				{
					label: 'Estimated Cost',
					key: 'uncategorized_flags.cost',
				},
				{
					label: 'Flag Notes',
					key: 'uncategorized_flags.notes',
				},
				{
					label: 'Internal Notes',
					key: 'uncategorized_flags.internal_notes',
				},
			],
		},
		{
			label: 'Resident Responsible Flags',
			key: 'resident_flags.section_active',
			marginTop: 2,
			options: [
				{
					label: 'Estimated Cost',
					key: 'resident_flags.cost',
				},
				{
					label: 'Flag Notes',
					key: 'resident_flags.notes',
				},
				{
					label: 'Internal Notes',
					key: 'resident_flags.internal_notes',
				},
			],
		},
	];

	return (
		<Paper
			elevation={0}
			sx={{ border: '1px solid #0000001F', padding: '8px 0', mt: 3 }}>
			<SectionHeader
				title="Maintenance Flags"
				isEditing={isEditing}
				payload={payload}
				setPayload={setPayload}
				payloadKey={payloadKey}
			/>
			<Column style={{ marginLeft: 24 }}>
				{options.map((option) => (
					<OptionGroup
						isEditing={isEditing}
						payload={payload}
						setPayload={setPayload}
						payloadKey={payloadKey}
						option={option}
					/>
				))}
			</Column>
		</Paper>
	);
};
