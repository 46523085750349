import React from 'react';
import styled from 'styled-components';
import ClearIcon from '@mui/icons-material/Clear';
import { spacingPx } from 'theme';

interface Props {
	email: string;
	clickable?: boolean;
	onClick?: () => void;
	onRemoveClick?: () => void;
}

const EmailChip = ({ email, clickable, onClick, onRemoveClick }: Props) => {
	return (
		<Container key={email} clickable={clickable} onClick={onClick}>
			<Text>{email}</Text>
			{!!onRemoveClick && (
				<ClearIcon color="disabled" onClick={onRemoveClick} />
			)}
		</Container>
	);
};

const Text = styled.div`
	font-size: 14px;
	font-weight: bold;
	padding: ${spacingPx(1)};
	max-width: 20em;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const Container = styled.div<{ clickable?: boolean }>`
	align-items: center;
	background-color: rgba(231, 235, 240);
	height: ${spacingPx(9)};
	width: fit-content(20em) !important;
	border-radius: ${spacingPx(1)};
	display: flex;
	flex-direction: row;
	padding-right: ${spacingPx(1)};
	padding-left: ${spacingPx(1)};
	margin-right: ${spacingPx(2)};
	margin-bottom: ${spacingPx(1)};
	${({ clickable }) => (clickable ? `cursor: pointer` : ``)};
`;

export default EmailChip;
