import { Skeleton } from '@mui/material';
import { Screens } from 'components';

export default () => {
	return (
		<Screens.Container>
			<Screens.HeaderSkeleton showBreadCrumbs subtitle />
			<Skeleton
				variant="rectangular"
				height={100}
				width="100%"
				sx={{ borderRadius: 1, mb: 3 }}
			/>
			<Skeleton
				variant="rectangular"
				height={500}
				width="100%"
				sx={{ borderRadius: 1, mb: 3 }}
			/>
			<Skeleton
				variant="rectangular"
				height={200}
				width="100%"
				sx={{ borderRadius: 1, mb: 3 }}
			/>
		</Screens.Container>
	);
};
