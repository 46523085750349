import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Tooltip,
} from '@mui/material';
import { useDispatch } from 'react-redux';

import { DialogTitle } from 'components';
import { ModalFlowActions, TeammatesActions } from 'store/actions';
import { Dispatch } from 'types';

import { LoadingButton } from '@mui/lab';
import { MembershipRole } from '@rentcheck/types';
import { useInviteUsersModalData } from 'hooks/modals';
import { useEffect, useState } from 'react';

import AssignPermissionGroup from '../edit-users-permission-groups/assign-permission-group';
import AssignRole from './assign-role';
import EmailInput from './email-input';
import TeamSelection from './team-selection';

interface Props {
	open: boolean;
}

const getButtonProps = (
	emails: string[],
	teamIds: string[],
	role?: MembershipRole
) => {
	const buttonDisabled = emails.length === 0 || teamIds.length === 0 || !role;
	const buttonTooltip =
		emails.length === 0
			? 'Add at least one email'
			: teamIds.length === 0
				? 'Select at least one team'
				: !role
					? 'Select a role'
					: '';

	return { buttonDisabled, buttonTooltip };
};

export default ({ open }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const modalData = useInviteUsersModalData();
	const initialTeams = modalData?.metadata.teams;

	const [emails, setEmails] = useState<string[]>([]);
	const [teamIds, setTeamIds] = useState<string[]>([]);
	const [role, setRole] = useState<MembershipRole>();
	const [permissionGroupIds, setPermissionGroupIds] = useState<string[]>([]);

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (open) {
			return;
		}

		setEmails([]);
		setTeamIds([]);
		setRole(undefined);
		setPermissionGroupIds([]);
		setLoading(false);
	}, [open]);

	useEffect(() => {
		if (!initialTeams) return;
		setTeamIds(initialTeams.map((team) => team.id));
	}, [initialTeams]);

	const handleClose = () => {
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	const handleSendInvite = () => {
		if (!role) return;

		setLoading(true);

		dispatch(
			TeammatesActions.Users.invite({
				emails,
				team_ids: teamIds,
				role,
				permission_group_ids: permissionGroupIds,
			})
		)
			.then(() => {
				handleClose();
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const { buttonDisabled, buttonTooltip } = getButtonProps(
		emails,
		teamIds,
		role
	);

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle
				title="Invite User"
				icon={solid('user-plus')}
				onClose={handleClose}
			/>
			<DialogContent>
				<EmailInput value={emails} setValue={setEmails} />
				<TeamSelection value={teamIds} setValue={setTeamIds} />
				<AssignRole value={role} setValue={setRole} />
				<AssignPermissionGroup
					value={permissionGroupIds}
					setValue={setPermissionGroupIds}
					caption="Select one or more permission groups to assign to the user."
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} variant="text" color="secondary">
					Cancel
				</Button>
				<Tooltip title={buttonTooltip}>
					<span>
						<LoadingButton
							loading={loading}
							disabled={buttonDisabled}
							onClick={handleSendInvite}>
							Send Invite
						</LoadingButton>
					</span>
				</Tooltip>
			</DialogActions>
		</Dialog>
	);
};
