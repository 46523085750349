import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Paper, Typography } from '@mui/material';
import { useSortedMaintenanceFlags } from 'hooks/flagged-features';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { FileRequestApi } from '@rentcheck/api-frontend';
import { ApiInspection, APIProperty } from '@rentcheck/types';

import { Column, Row, SpacedRow, Spacer } from 'components';
import IconButton from 'components/icon-button';
import { SnackbarActions } from 'store/actions';
import { colors } from 'theme';

import CreateButton from './create-button';
import FeatureFlagsTable from './table';

interface Props {
	inspection: ApiInspection;
	property: APIProperty;
}

const FlaggedFeatureList = ({ inspection, property }: Props) => {
	const dispatch = useDispatch();

	const {
		setSortBy,
		sortBy,
		setSortDirection,
		sortDirection,
		maintenanceFlags,
	} = useSortedMaintenanceFlags(inspection.id);

	const [selectedFlagIds, setSelectedFlagIds] = useState<string[]>([]);

	const selectedFlags = maintenanceFlags.filter((f) =>
		selectedFlagIds.includes(f.id)
	);

	const maintenanceReportExportToCSVHandler = () => {
		const message = `CSV report download request is being queued. You should receive an email with a link to the report shortly.`;

		dispatch(SnackbarActions.showSuccess(message));
		FileRequestApi.create(
			'MAINTENANCE_FLAGS_LIST_EXPORT',
			inspection.id,
			'CSV',
			{
				inspection_id: inspection.id,
				maintenance_flag_ids: selectedFlags.length
					? selectedFlags.map((flag) => flag.id)
					: maintenanceFlags.map((flag) => flag.id),
			}
		);
	};

	return (
		<>
			<SpacedRow>
				<Column>
					<Row>
						<Paper
							sx={{
								borderRadius: '18px',
								width: 36,
								height: 36,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}>
							<FontAwesomeIcon icon={solid('flag')} color={colors.primary} />
						</Paper>
						<Spacer width={4} />
						<Typography variant="h5">Flagged Items</Typography>
					</Row>

					<Spacer height={4} />

					<Typography>{maintenanceFlags.length} Total</Typography>

					<Spacer height={1} />
				</Column>

				<Row>
					<IconButton
						icon={solid('arrow-to-bottom')}
						onClick={maintenanceReportExportToCSVHandler}
						tooltip="Export to CSV"
					/>

					<Spacer width={2} />

					<CreateButton
						inspection={inspection}
						property={property}
						selectedFlags={selectedFlags}
						allFlags={maintenanceFlags}
					/>
				</Row>
			</SpacedRow>

			<Spacer height={6} />

			<FeatureFlagsTable
				flags={maintenanceFlags}
				selectedIds={selectedFlagIds}
				setSelectedIds={setSelectedFlagIds}
				setSortBy={setSortBy}
				sortBy={sortBy}
				setSortDirection={setSortDirection}
				sortDirection={sortDirection}
			/>
		</>
	);
};

export default FlaggedFeatureList;
