import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Paper, SxProps, Theme } from '@mui/material';
import {
	TemplateFeature,
	TemplateQuestion,
	TemplateQuestionAnswer,
} from '@rentcheck/types';
import { CenteredRow } from 'components';
import { useEffect, useRef, useState } from 'react';
import {
	Draggable,
	DraggingStyle,
	NotDraggingStyle,
} from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { shouldIgnoreClick } from 'screens/modal-flows-controller/inspection-template/helpers/click-outside';
import { ModalFlowActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { useOnClickOutside } from 'usehooks-ts';
import { areQuestionsIncomplete } from '../../../helpers';
import Editing from './editing';
import NotEditing from './not-editing';

interface Props {
	index: number;
	feature: TemplateFeature;
	question: TemplateQuestion;
	editing: boolean;

	onDelete: (question: TemplateQuestion) => void;
	onCopy: (question: TemplateQuestion) => void;
	onAdd: (question: TemplateQuestion) => void;
	onUpdate: (question: TemplateQuestion) => void;
	onDeleteAnswer: (
		question: TemplateQuestion,
		answer: TemplateQuestionAnswer
	) => void;
}

const getItemStyle = (
	draggableStyle?: DraggingStyle | NotDraggingStyle
): SxProps<Theme> => ({
	mb: 1,
	padding: 2,
	backgroundColor: '#ffffff',
	border: '1px solid #0000001F',

	display: 'flex',
	flexDirection: 'column',
	transition: 'elevation 0.2s ease-in-out',
	cursor: 'pointer',

	// styles we need to apply on draggables
	...(draggableStyle as any),
});

export default ({
	feature,
	question,
	editing,
	index,
	onDelete,
	onCopy,
	onAdd,
	onUpdate,
	onDeleteAnswer,
}: Props) => {
	const dispatch = useDispatch();

	const showingConfirmationModal = useTypedSelector(
		(state) => !!state.modalFlows.confirmation_modal
	);

	const [editingThisQuestion, setEditingThisQuestion] = useState(false);

	const clickOutsideRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (editing && !question.title) {
			setEditingThisQuestion(true);
		}
	}, [question, editing]);

	useOnClickOutside(clickOutsideRef, (e) => {
		if (showingConfirmationModal) {
			return;
		}

		if (shouldIgnoreClick(e.target as any)) {
			return;
		}

		/**
		 * If the question is incomplete we need to ask the user
		 * if they want to delete it or finish setting it up
		 */
		if (areQuestionsIncomplete([question])) {
			return dispatch(
				ModalFlowActions.showConfirmationModal({
					icon: regular('save'),
					title: 'Incomplete Question',
					body1: ['The question text needs to be added in order to save.'],
					buttons: [
						{
							title: 'Delete Question',
							onClick: () => {
								dispatch(ModalFlowActions.closeConfirmationModal());
								handleDelete();
							},
						},
						{
							title: 'Go Back',
							onClick: () => {
								dispatch(ModalFlowActions.closeConfirmationModal());
							},
						},
					],
				})
			);
		}

		handleSave();
	});

	const handleDelete = () => {
		onDelete(question);
	};

	const handleCopy = () => {
		onCopy(question);
	};

	const handleAdd = () => {
		onAdd(question);
	};

	const handleClick = () => {
		if (editing) {
			setEditingThisQuestion(true);
		}
	};

	const handleSave = () => {
		if (areQuestionsIncomplete([question])) {
			return;
		}

		setEditingThisQuestion(false);
	};

	return (
		<Draggable
			key={question.id}
			draggableId={question.id}
			isDragDisabled={!editing}
			index={index}>
			{(provided, snapshot) => (
				<div ref={clickOutsideRef}>
					<Paper
						ref={provided.innerRef}
						elevation={snapshot.isDragging ? 1 : 0}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						sx={getItemStyle(provided.draggableProps.style)}
						onClick={handleClick}>
						{editing && (
							<CenteredRow>
								<FontAwesomeIcon icon={regular('grip-lines')} />
							</CenteredRow>
						)}
						{!editingThisQuestion && (
							<NotEditing
								feature={feature}
								question={question}
								editing={editing}
								onAdd={handleAdd}
								onCopy={handleCopy}
								onDelete={handleDelete}
							/>
						)}
						{editingThisQuestion && (
							<Editing
								index={index}
								question={question}
								feature={feature}
								onAdd={handleAdd}
								onCopy={handleCopy}
								onDelete={handleDelete}
								onUpdate={onUpdate}
								onSave={handleSave}
								onDeleteAnswer={onDeleteAnswer}
							/>
						)}
					</Paper>
				</div>
			)}
		</Draggable>
	);
};
