import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogContent, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { colors, spacingPx } from 'theme';
import Spacer from '../Spacer';
import { CenteredColumn, Column } from '../layout/Column';
import { SpacedRow } from '../layout/Row';

interface Props {
	width?: number;
	topPadding?: number;
	targetId?: string;
	children: React.ReactNode;
	setHidden?: () => void;
}

const Modal = ({ children, setHidden }: Props) => {
	const handleDismiss = () => {
		if (!setHidden) {
			return;
		}

		setHidden();
	};

	return (
		<Dialog open onClose={handleDismiss}>
			<DialogContent>{children}</DialogContent>
		</Dialog>
	);
};

interface FooterProps {
	leftButtonTitle?: string;
	leftButtonOnClick?: () => void;
	rightButtonTitle: string;
	rightButtonOnClick: () => void;
	error?: string;
	disabled?: boolean;
	loading?: boolean;
	hideDivider?: boolean;
}

export const Footer = ({
	leftButtonTitle,
	leftButtonOnClick,
	rightButtonTitle,
	rightButtonOnClick,
	error,
	disabled,
	loading,
	hideDivider,
}: FooterProps) => {
	if (!leftButtonTitle || !leftButtonOnClick) {
		return (
			<CenteredColumn>
				<Spacer height={10} />
				{!hideDivider && (
					<>
						<Divider />
						<Spacer height={5} />
					</>
				)}
				{!!error && <Typography variant="error">{error}</Typography>}
				<Spacer height={5} />
				<LoadingButton
					variant="contained"
					loading={loading}
					disabled={disabled}
					onClick={rightButtonOnClick}>
					{rightButtonTitle}
				</LoadingButton>
			</CenteredColumn>
		);
	}

	return (
		<Column>
			<Spacer height={10} />
			{!hideDivider && (
				<>
					<Divider />
					<Spacer height={5} />
				</>
			)}
			{!!error && <Typography variant="error">{error}</Typography>}
			<Spacer height={5} />
			<SpacedRow>
				<ButtonContainer left>
					<Button
						variant="outlined"
						color="secondary"
						onClick={leftButtonOnClick}>
						{leftButtonTitle}
					</Button>
				</ButtonContainer>
				<ButtonContainer right>
					<LoadingButton
						variant="contained"
						loading={loading}
						disabled={disabled}
						onClick={rightButtonOnClick}>
						{rightButtonTitle}
					</LoadingButton>
				</ButtonContainer>
			</SpacedRow>
		</Column>
	);
};

const ButtonContainer = styled.div<{ right?: boolean; left?: boolean }>`
	display: flex;
	flex-direction: column;
	padding-left: ${({ right }) => (right ? spacingPx(2) : 0)};
	padding-right: ${({ left }) => (left ? spacingPx(2) : 0)};
`;

const Divider = styled.div`
	height: 1px;
	width: 100%;
	background-color: ${colors.contentBorder};
`;

export default Modal;
