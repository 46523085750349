import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { AlertColor } from '@mui/material';
import {
	Payload,
	SnackbarActionType,
	actionTypes,
} from 'store/actions/snackbar-actions';

export interface SnackbarState {
	id: string;
	title: string;
	type: AlertColor | 'progress';
	icon?: [IconPrefix, IconName];
}

export type State = SnackbarState[];

interface Action {
	type: SnackbarActionType;
	id?: string;
	snackbarPayload?: Payload;
}

export default (state: State = [], action: Action): State => {
	switch (action.type) {
		case actionTypes.SHOW_SNACKBAR:
			if (!action.snackbarPayload) return state;

			return [
				{
					id: action.snackbarPayload.id,
					title: action.snackbarPayload.title,
					type: 'info',
				},
				...state,
			];

		case actionTypes.SHOW_SUCCESS_SNACKBAR:
			if (!action.snackbarPayload?.title) return state;

			return [
				{
					id: action.snackbarPayload.id,
					title: action.snackbarPayload.title,
					type: 'success',
				},
				...state,
			];

		case actionTypes.SHOW_PROGRESS_SNACKBAR:
			if (!action.snackbarPayload?.title) return state;

			return [
				{
					id: action.snackbarPayload.id,
					title: action.snackbarPayload.title,
					type: 'progress',
				},
				...state,
			];

		case actionTypes.SHOW_ERROR_SNACKBAR:
			if (!action.snackbarPayload?.title) return state;

			return [
				{
					id: action.snackbarPayload.id,
					title: action.snackbarPayload.title,
					type: 'error',
				},
				...state,
			];

		case actionTypes.HIDE_SNACKBAR:
			return state.filter((snackbar) => snackbar.id !== action.id);

		default:
			return state;
	}
};
