import { MenuItem } from '@mui/material';
import { TemplateQuestion } from '@rentcheck/types';
import FormTextField from 'components/form-text-field';
import { ASSET_CATEGORIES } from '../../../../constants';

interface Props {
	question: TemplateQuestion;
	onUpdate: (question: TemplateQuestion) => void;
	onSave: () => void;
}

export default ({ question, onUpdate, onSave }: Props) => {
	const handleUpdateQuestionTitle = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		onUpdate({ ...question, title: e.target.value });
	};

	const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			onSave();
		}
	};

	const isOther = (questionTitle: string) => {
		return (
			questionTitle !== 'Serial Number' && questionTitle !== 'Model Number'
		);
	};

	const handleChangeAssetCaptureType = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		if (!isOther(e.target.value)) {
			return handleUpdateQuestionTitle(e);
		}

		onUpdate({
			...question,
			title: '',
		});
	};

	const handleChangeAssetCategory = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		onUpdate({ ...question, asset_category: e.target.value });
	};

	return (
		<>
			<FormTextField
				select
				title="Asset Capture Type"
				value={isOther(question.title) ? 'Other' : question.title}
				onChange={handleChangeAssetCaptureType}>
				<MenuItem value="Serial Number">Serial Number</MenuItem>
				<MenuItem value="Model Number">Model Number</MenuItem>
				<MenuItem value="Other">Other</MenuItem>
			</FormTextField>

			<FormTextField
				select
				title="Asset Category"
				value={question.asset_category || ''}
				placeholder="Select an asset category"
				required
				onChange={handleChangeAssetCategory}>
				<MenuItem value="placeholder" sx={{ display: 'none', color: 'red' }}>
					Select an asset category
				</MenuItem>
				{ASSET_CATEGORIES.map((assetCategory) => (
					<MenuItem value={assetCategory}>{assetCategory}</MenuItem>
				))}
			</FormTextField>

			{isOther(question.title) && (
				<FormTextField
					required
					autoFocus
					title="Question Text"
					value={question.title}
					placeholder="Add a question. Example: Please capture the microwave manufacture date."
					onChange={handleUpdateQuestionTitle}
					onKeyPress={handleKeyPress}
				/>
			)}
		</>
	);
};
