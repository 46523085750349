import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Checkbox, Menu, TableCell, TableHead, TableRow } from '@mui/material';
import { Rules } from '@rentcheck/biz';
import { ApiUserDigest } from '@rentcheck/types';
import { ActionsChip } from 'components';
import IconButton from 'components/icon-button';
import MenuItem from 'components/menu-item';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';
import { useIsMobileBreakpoint } from 'utils/hooks';
import {
	handleDeactivateUsers,
	handleEditUsersPermissionGroups,
} from './common';

interface Props {
	selected: boolean;
	indeterminate: boolean;
	selectedIds: string[];
	data: ApiUserDigest[];
	onSelect: () => void;
}

export default ({
	selected,
	indeterminate,
	selectedIds,
	data,
	onSelect,
}: Props) => {
	const dispatch = useDispatch<Dispatch>();
	const isMobile = useIsMobileBreakpoint('md');
	const profile = useTypedSelector((state) => state.activeProfile);
	const subscription = useTypedSelector((state) => state.subscription);

	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const handleClick = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		setAnchorEl(e.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const selectedUsers = useMemo(
		() => data.filter((user) => selectedIds.includes(user.id)),
		[data, selectedIds]
	);

	const deleteUsersVisible = selectedUsers.some(
		(u) => Rules.Teammates.canBeDeactivated(u, profile).result
	);

	const { result: canEdit, reason: editReason } = Rules.Teammates.canEdit(
		profile,
		subscription
	);
	const {
		result: canEditPermissionGroups,
		reason: editPermissionGroupsReason,
	} = Rules.PermissionGroups.canEdit(profile);

	if (isMobile) {
		return (
			<TableHead>
				<TableRow>
					<TableCell sx={{ width: 30 }}>
						<Checkbox
							size="small"
							checked={selected}
							onChange={onSelect}
							indeterminate={indeterminate}
						/>
					</TableCell>
					<TableCell>User Info</TableCell>
					<TableCell align="center" sx={{ width: 30 }}>
						<IconButton
							icon={regular('ellipsis-v')}
							disabled={selectedIds.length === 0}>
							<MenuItem
								visible={deleteUsersVisible}
								tooltip={editReason}
								disabled={!canEdit}
								onClick={() =>
									handleDeactivateUsers(profile, selectedUsers, dispatch)
								}>
								Deactivate Users
							</MenuItem>
							<MenuItem
								tooltip={editPermissionGroupsReason}
								disabled={!canEditPermissionGroups}
								onClick={() =>
									handleEditUsersPermissionGroups(selectedUsers, dispatch)
								}>
								Edit Permission Groups
							</MenuItem>
						</IconButton>
					</TableCell>
				</TableRow>
			</TableHead>
		);
	}

	return (
		<TableHead>
			<TableRow>
				<TableCell sx={{ width: 30 }}>
					<Checkbox
						size="small"
						checked={selected}
						onChange={onSelect}
						indeterminate={indeterminate}
					/>
				</TableCell>
				<TableCell>Name</TableCell>
				<TableCell>Email</TableCell>
				<TableCell>Teams</TableCell>
				<TableCell>Permission Groups</TableCell>
				<TableCell>User Status</TableCell>
				<TableCell align="center" sx={{ width: 30 }}>
					<>
						<ActionsChip ids={selectedIds} openMenu={handleClick} />
						<Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
							<MenuItem
								visible={deleteUsersVisible}
								tooltip={editReason}
								disabled={!canEdit}
								onClick={() => {
									handleClose();
									handleDeactivateUsers(profile, selectedUsers, dispatch);
								}}>
								Deactivate Users
							</MenuItem>
							<MenuItem
								tooltip={editPermissionGroupsReason}
								disabled={!canEditPermissionGroups}
								onClick={() =>
									handleEditUsersPermissionGroups(selectedUsers, dispatch)
								}>
								Edit Permission Groups
							</MenuItem>
						</Menu>
					</>
				</TableCell>
			</TableRow>
		</TableHead>
	);
};
