import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, MenuItem, Typography } from '@mui/material';
import { TemplateQuestion, TemplateQuestionAnswer } from '@rentcheck/types';
import { Row, SpacedRow } from 'components';
import FormTextField from 'components/form-text-field';
import IconButton from 'components/icon-button';
import ToggleButton from 'components/toggle-button';
import { v4 as uuid } from 'uuid';

interface Props {
	question: TemplateQuestion;
	onUpdate: (question: TemplateQuestion) => void;
	onDeleteAnswer: (
		question: TemplateQuestion,
		answer: TemplateQuestionAnswer
	) => void;
	onSave: () => void;
}

export default ({ question, onUpdate, onSave, onDeleteAnswer }: Props) => {
	const handleUpdateQuestionTitle = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		onUpdate({ ...question, title: e.target.value });
	};

	const handleToggleRequiresPhoto = (answer: TemplateQuestionAnswer) => {
		const updatedAnswers = question.answers.map((a) => {
			if (a.id === answer.id) {
				return {
					...a,
					requires_photo: !a.requires_photo,
				};
			}

			return a;
		});

		onUpdate({
			...question,
			answers: updatedAnswers,
		});
	};

	const handleToggleRequiresNote = (answer: TemplateQuestionAnswer) => {
		const updatedAnswers = question.answers.map((a) => {
			if (a.id === answer.id) {
				return {
					...a,
					requires_note: !a.requires_note,
				};
			}

			return a;
		});

		onUpdate({
			...question,
			answers: updatedAnswers,
		});
	};

	const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			onSave();
		}
	};

	const handleChangeAnswerType = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newType = e.target.value as TemplateQuestion['answer_type'];

		onUpdate({
			...question,
			answer_type: newType,
		});
	};

	const handleUpdateAnswerTitle = (
		answer: TemplateQuestionAnswer,
		value: string
	) => {
		const updatedAnswers = question.answers.map((a) => {
			if (a.id === answer.id) {
				return {
					...a,
					title: value,
				};
			}

			return a;
		});

		onUpdate({
			...question,
			answers: updatedAnswers,
		});
	};

	const handleDeleteAnswer = (answer: TemplateQuestionAnswer) => {
		onDeleteAnswer(question, answer);
	};

	const handleAddAnswer = () => {
		const newAnswer: TemplateQuestionAnswer = {
			id: uuid(),
			title: '',
			requires_photo: false,
			requires_note: false,
		};

		onUpdate({
			...question,
			answers: [...question.answers, newAnswer],
		});
	};

	return (
		<>
			<FormTextField
				required
				autoFocus
				title="Question Text"
				value={question.title}
				placeholder="Add a question. Example: Are the walls free from any major cracks, holes, or stains?"
				onChange={handleUpdateQuestionTitle}
				onKeyPress={handleKeyPress}
			/>

			<FormTextField
				select
				title="Answer Type"
				value={question.answer_type}
				onChange={handleChangeAnswerType}>
				<MenuItem value="single">
					Single-select - Only one option can be selected. One option is
					required.
				</MenuItem>
				<MenuItem value="multi">
					Multi-select - Multiple options can be selected. At least one option
					is required.
				</MenuItem>
			</FormTextField>

			<Typography variant="overline" mb={2}>
				Answers
			</Typography>
			{question.answers.map((answer) => (
				<SpacedRow key={answer.id} style={{ marginBottom: 8 }}>
					<FormTextField
						fullWidth
						value={answer.title}
						onChange={(e) => handleUpdateAnswerTitle(answer, e.target.value)}
						autoFocus={!answer.title}
						inputSx={{ mt: 0, mb: 0 }}
						helperText="0-50 Characters"
						inputProps={{ maxLength: 50 }}
					/>

					<Row style={{ marginTop: -8 }}>
						<ToggleButton
							icon={regular('message-lines')}
							selected={answer.requires_note}
							tooltip={
								answer.requires_note
									? 'Notes required for this answer'
									: 'Notes not required for this answer'
							}
							onClick={() => handleToggleRequiresNote(answer)}
						/>
						<ToggleButton
							icon={regular('image')}
							selected={answer.requires_photo}
							onClick={() => handleToggleRequiresPhoto(answer)}
							tooltip={
								answer.requires_photo
									? 'Photo required for selection'
									: 'Photo not required for selection'
							}
						/>
						<IconButton
							icon={regular('trash')}
							tooltip="Delete"
							disabled={question.answers.length <= 2}
							onClick={() => handleDeleteAnswer(answer)}
						/>
					</Row>
				</SpacedRow>
			))}
			<div>
				<Button
					variant="text"
					onClick={handleAddAnswer}
					startIcon={<FontAwesomeIcon icon={regular('plus')} />}>
					Add Answer
				</Button>
			</div>
		</>
	);
};
