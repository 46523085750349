import { FilterChip } from 'components/table/filter-button-v2';
import { useDispatch } from 'react-redux';
import { actionType, icon, useFilter } from './common';

export default () => {
	const dispatch = useDispatch();
	const filter = useFilter();

	const handleDeleted = () => {
		dispatch({
			type: actionType,
			value: [],
		});
	};

	if (!filter.length) {
		return null;
	}

	return (
		<FilterChip title="Assigned To Me" icon={icon} onDelete={handleDeleted} />
	);
};
