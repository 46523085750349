import { ApiInspectionWithTemplate } from '@rentcheck/types';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { SpacedRow } from 'components';
import IconButton from 'components/icon-button';
import { useDispatch } from 'react-redux';
import { ModalFlowActions } from 'store/actions';
import { Dispatch } from 'types';

import { Rules } from '@rentcheck/biz';
import { useState } from 'react';
import GridItem from '../grid-item';
import AssigneeValue from './assignee-value';
import EditAssigneesDialog from './edit-assignees-dialog';

interface Props {
	inspection: ApiInspectionWithTemplate;
}

export default ({ inspection }: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const [editAssigneeDialogOpen, setEditAssigneeDialogOpen] = useState(false);

	const handleEditAssignee = async () => {
		if (inspection.inspection_status === 'Scheduled') {
			return setEditAssigneeDialogOpen(true);
		}

		if (['emails', 'residents'].includes(inspection.assigned_recipients.type)) {
			return dispatch(ModalFlowActions.showEditRecipientsModal({ inspection }));
		}

		setEditAssigneeDialogOpen(true);
	};

	if (!Rules.Inspections.canShowAssignee(inspection)) {
		return null;
	}

	return (
		<>
			<GridItem
				title="Assignee"
				value={
					<>
						<SpacedRow>
							<AssigneeValue inspection={inspection} />

							{Rules.Inspections.canEditAssignee(inspection) && (
								<IconButton
									tooltip="Edit Assignees"
									onClick={handleEditAssignee}
									icon={regular('edit')}
									buttonSx={{ mb: -1, mt: -2 }}
								/>
							)}
						</SpacedRow>
					</>
				}
			/>
			<EditAssigneesDialog
				open={editAssigneeDialogOpen}
				onClose={() => setEditAssigneeDialogOpen(false)}
				inspection={inspection}
			/>
		</>
	);
};
