import { Masonry } from '@mui/lab';
import { Typography } from '@mui/material';
import useResizeObserver from '@react-hook/resize-observer';
import { Rules } from '@rentcheck/biz';
import { ApiInspectionWithTemplate } from '@rentcheck/types';
import { Column } from 'components';
import { Image, ImageBadges, MediaTypeIcon } from 'components/features';
import _ from 'lodash';
import { useMemo, useRef, useState } from 'react';
import { FeatureWithMetadata } from 'store/reducers/features';
import { getFeatureMedia, isEmbeddedInMobileApp } from 'utils/helpers';

import Flags from './flags';
import ImageTools from './image-tools';
import LinkedQuestions from './linked-questions';

interface Props {
	feature: FeatureWithMetadata;
	features: FeatureWithMetadata[];
	inspection: ApiInspectionWithTemplate;
}

export default ({ feature, features, inspection }: Props) => {
	const media = getFeatureMedia(feature);

	const masonryRef = useRef<HTMLDivElement>(null);
	const [containerWidth, setContainerWidth] = useState(1200);

	useResizeObserver(masonryRef, (entry) => {
		setContainerWidth(entry.contentRect.width);
	});

	const minImageWidth = 410;

	const columns = useMemo(() => {
		/**
		 * We want to maximize the number of columns we show while keeping the
		 * image width above a certain threshold.
		 * We also want to ensure no more than 5 columns are shown, this is because
		 * the images become too small to be useful on ultra wide screens.
		 * Additionally we always want at least one column to be shown.
		 */
		const rawValue = Math.floor(containerWidth / minImageWidth);
		const value = _.clamp(rawValue, 1, 5);

		/**
		 * To prevent some glitchy changes in layout when transitioning between
		 * xl and lg screens we want to ensure that the number of columns is at least 3
		 * when the screen is wider than 1535px.
		 */
		if (window.innerWidth > 1535) {
			return Math.max(3, value);
		}

		return value;
	}, [containerWidth]);

	const imageSize = useMemo(
		() =>
			/**
			 * Multiply by 1.5 if embedded in a mobile app to
			 * account for the higher pixel density of mobile devices.
			 */
			Math.floor((containerWidth / columns) * 1.5) *
			(isEmbeddedInMobileApp() ? 1.5 : 1),
		[columns]
	);

	if (feature.not_applicable) {
		return null;
	}

	return (
		<Column>
			<Typography variant="overline" mt={2}>
				Media
			</Typography>
			{!media.length && (
				<Typography variant="subtitle2" mt={1}>
					No media
				</Typography>
			)}
			<Masonry
				ref={masonryRef}
				columns={columns}
				spacing={columns === 1 ? 0 : 2}
				sx={{ mt: 1.5, flex: 1 }}>
				{media.map((item, index) => (
					<div
						key={item + index}
						style={{ position: 'relative', display: 'flex' }}>
						<Image
							feature={feature}
							features={features}
							inspection={inspection}
							imageUri={item}
							imageSize={imageSize}
							mb={columns === 1 ? 16 : 0}
						/>
						<ImageTools
							feature={feature}
							inspection={inspection}
							image={item}
						/>
						<ImageBadges
							inspection={inspection}
							feature={feature}
							image={item}
							mode={['damage', 'revision', 'device-media']}
							mt={
								Rules.Inspections.canPerformFeatureActions(inspection) ? 40 : 0
							}
						/>
						<MediaTypeIcon image={item} />
						<Flags feature={feature} image={item} inspection={inspection} />
						<LinkedQuestions feature={feature} image={item} />
					</div>
				))}
				{media.length < columns &&
					new Array(columns - media.length)
						.fill(null)
						.map((_, index) => (
							<div key={index} style={{ width: imageSize, height: 1 }} />
						))}
			</Masonry>
		</Column>
	);
};
