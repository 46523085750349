//TODO probably remove invites from state entirely
const inviteReducer = (state = {}, action) => {
	switch (action.type) {
		case 'CREATE_INVITE':
			if (action.invite.ID) {
				return {
					[action.invite.ID]: action.invite,
					...state,
				};
			} else if (action.invite.id) {
				return {
					[action.invite.id]: action.invite,
					...state,
				};
			} else {
				return state;
			}

		case 'RECIEVE_INVITE':
			if (action.invite.ID) {
				return {
					[action.invite.ID]: action.invite,
					...state,
				};
			} else if (action.invite.id) {
				return {
					[action.invite.id]: action.invite,
					...state,
				};
			} else {
				return state;
			}
		case 'CREATE_INVITE_ERROR':
			// TODO- error handling
			return state;

		case 'SET_UNITS':
			return {
				...action.invites,
			};
		case 'DELETE_INVITE': {
			const invites = {
				...state,
			};

			delete invites[action.id];
			return invites;
		}
		case 'DELETE_INVITE_ERROR':
			// TODO- error handling
			return state;

		case 'SIGNOUT_SUCCESS':
			return {};
		default:
			return state;
	}
};

export default inviteReducer;
