import { FeaturesApi, FoxyAiApi } from '@rentcheck/api-frontend';
import { Feature, ImageMetadata, MaintenanceFlag } from '@rentcheck/types';
import { objectFromDocumentSnapshot } from 'api/helpers';
import firebase from 'firebase/app';
import _ from 'lodash';

import { Dispatch } from 'types';

import { FeaturesApi as FeaturesApiFb } from 'api';
import { FeatureWithMetadata } from 'store/reducers/features';
import * as SnackbarActions from './snackbar-actions';

export const updateFeature = (
	id: string,
	payload: Omit<Partial<Feature>, 'id'>
) => {
	return async (dispatch: Dispatch) => {
		const db = firebase.firestore();

		await db
			.collection('inspection_feature')
			.doc(id)
			.update({ ...payload, updated_date: new Date() });

		const updatedFeature = await db
			.collection('inspection_feature')
			.doc(id)
			.get()
			.then((doc) => objectFromDocumentSnapshot<Feature>(doc));

		if (!updatedFeature) return;

		const featuresWithMetadata: FeatureWithMetadata[] =
			await FeaturesApiFb.addMetadataToFeatures([updatedFeature]);

		return dispatch({
			type: 'UPDATE_FEATURES',
			features: featuresWithMetadata,
		});
	};
};

export const postMetadataFeedback = (
	imageMetadata: ImageMetadata,
	isCorrect: boolean
) => {
	return async (dispatch: Dispatch) => {
		await FoxyAiApi.postMetadataFeedback(imageMetadata.id, isCorrect);

		const db = firebase.firestore();
		const updatedFeature = await db
			.collection('inspection_feature')
			.doc(imageMetadata.inspection_feature.id)
			.get()
			.then((doc) => objectFromDocumentSnapshot<Feature>(doc));

		if (!updatedFeature) return;

		const featuresWithMetadata: FeatureWithMetadata[] =
			await FeaturesApiFb.addMetadataToFeatures([updatedFeature]);

		return dispatch({
			type: 'UPDATE_FEATURES',
			features: featuresWithMetadata,
		});
	};
};

export const upsertMaintenanceFlag = (
	feature: Feature,
	flag: MaintenanceFlag
) => {
	return async (dispatch: Dispatch) => {
		const db = firebase.firestore();

		const updatedFlags: MaintenanceFlag[] = _.unionBy(
			[flag],
			feature.maintenance_flags ?? [],
			(f) => f.id
		);

		await db.collection('inspection_feature').doc(feature.id).update({
			maintenance_flags: updatedFlags,
		});

		const updatedFeature = await db
			.collection('inspection_feature')
			.doc(feature.id)
			.get()
			.then((doc) => objectFromDocumentSnapshot<Feature>(doc));

		if (!updatedFeature) {
			throw new Error("Couldn't update feature");
		}

		const featuresWithMetadata: FeatureWithMetadata[] =
			await FeaturesApiFb.addMetadataToFeatures([updatedFeature]);

		dispatch({
			type: 'UPDATE_FEATURES',
			features: featuresWithMetadata,
		});

		return updatedFeature;
	};
};

export const deleteflagFromFeature = (
	feature: Feature,
	flag: MaintenanceFlag
) => {
	return async (dispatch: Dispatch) => {
		const db = firebase.firestore();

		await db
			.collection('inspection_feature')
			.doc(feature.id)
			.update({
				maintenance_flags: feature.maintenance_flags?.filter(
					(f) => f.id !== flag.id
				),
			});

		const updatedFeature = await db
			.collection('inspection_feature')
			.doc(feature.id)
			.get()
			.then((doc) => objectFromDocumentSnapshot<Feature>(doc));

		if (!updatedFeature) return;

		const featuresWithMetadata: FeatureWithMetadata[] =
			await FeaturesApiFb.addMetadataToFeatures([updatedFeature]);

		return dispatch({
			type: 'UPDATE_FEATURES',
			features: featuresWithMetadata,
		});
	};
};

export const requestInspectionFeatures = (inspectionId: string) => {
	return async (dispatch: Dispatch) => {
		const features = await FeaturesApiFb.getForInspectionId(inspectionId);

		dispatch({
			type: 'UPDATE_FEATURES',
			features,
		});

		return features;
	};
};

export const rotatePhoto = (
	featureId: string,
	photoURL: string,
	angle: number
) => {
	return async (dispatch: Dispatch) => {
		try {
			await FeaturesApi.rotatePhoto(featureId, photoURL, angle);

			const updatedFeature = await firebase
				.firestore()
				.collection('inspection_feature')
				.doc(featureId)
				.get()
				.then((doc) => objectFromDocumentSnapshot<Feature>(doc));

			if (!updatedFeature) return;

			const featuresWithMetadata: FeatureWithMetadata[] =
				await FeaturesApiFb.addMetadataToFeatures([updatedFeature]);

			return dispatch({
				type: 'UPDATE_FEATURES',
				features: featuresWithMetadata,
			});
		} catch (e) {
			dispatch(SnackbarActions.showError((e as Error).message));
		}
	};
};
