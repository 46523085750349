import { Typography } from '@mui/material';
import { spacing } from 'theme';
import { Spacer, TextInput } from '../../..';

interface Props {
	floors: string;
	setFloors: (floors: string) => void;
}

const Floors = ({ floors, setFloors }: Props) => {
	const floorsNumber = parseInt(floors);
	const error = isNaN(floorsNumber) || floorsNumber < 1;

	return (
		<div>
			<Typography variant="h6">How many floors are there?</Typography>
			<Spacer height={7} />
			<TextInput
				label="Floors"
				width={spacing(98)}
				onChange={(v) => setFloors(v)}
				value={floors.toString()}
				error={error ? 'Must be a number greater than zero.' : undefined}
				type="number"
			/>
			<Spacer height={7} />
		</div>
	);
};

export default Floors;
