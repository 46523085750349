import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { LoadingButton } from '@mui/lab';
import { Button, Typography } from '@mui/material';
import { Spacer, TextInput } from 'components';
import { Modal } from 'components/Common';
import * as authActions from 'store/actions/auth-actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { colors, spacingPx } from 'theme';
import { Dispatch } from 'types';

interface Props {
	cancelPressed: () => void;
	reAuthSuccesfull: () => void;
	loading?: boolean;
}

const ReauthenticateModal = ({
	cancelPressed,
	reAuthSuccesfull,
	loading,
}: Props) => {
	const dispatch: Dispatch = useDispatch();

	const profile = useTypedSelector((state) => state.activeProfile);

	const [email, setEmail] = useState(profile.email);
	const [password, setPassword] = useState('');

	const [error, setError] = useState(false);
	const [authLoading, setAuthLoading] = useState(false);

	const handleConfirm = async () => {
		setError(false);
		setAuthLoading(true);

		dispatch(authActions.reAuthenticateUser(email, password))
			.then(reAuthSuccesfull)
			.catch((e) => setError(e.message))
			.finally(() => setAuthLoading(false));
	};

	return (
		<Modal>
			<CenteredColumn>
				<Spacer height={5} />
				<Typography variant="h5">Confirm your credentials</Typography>
				<Spacer height={2} />
				<PaddedBodyText>
					We need you to confirm your current login credentials in order to make
					this change
				</PaddedBodyText>
				<Spacer height={10} />
				<TextInput
					label="Email"
					value={email}
					onChange={(v) => setEmail(v)}
					width={400}
				/>
				<Spacer height={6} />
				<TextInput
					label="Password"
					value={password}
					onChange={(v) => setPassword(v)}
					type="password"
					width={400}
				/>
				<Spacer height={6} />
				{!!error && <Typography color={colors.error}>{error}</Typography>}
				<Spacer height={6} />
				<Divider />
				<Spacer height={5} />
			</CenteredColumn>

			<SpacedRow>
				<ButtonContainer left>
					<Button variant="outlined" color="secondary" onClick={cancelPressed}>
						Cancel
					</Button>
				</ButtonContainer>
				<ButtonContainer right>
					<LoadingButton
						variant="contained"
						loading={authLoading || loading}
						onClick={handleConfirm}>
						Confirm
					</LoadingButton>
				</ButtonContainer>
			</SpacedRow>
		</Modal>
	);
};

const Row = styled.div`
	display: flex;
	flex-direction: row;
`;

const CenteredColumn = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const SpacedRow = styled(Row)`
	justify-content: space-between;
`;

const PaddedBodyText = styled(Typography)`
	padding: 0 ${spacingPx(10)} 0 ${spacingPx(10)};
`;

const ButtonContainer = styled.div<{ right?: boolean; left?: boolean }>`
	display: flex;
	flex-direction: column;
	padding-left: ${({ right }) => (right ? spacingPx(2) : 0)};
	padding-right: ${({ left }) => (left ? spacingPx(2) : 0)};
`;

const Divider = styled.div`
	height: 1px;
	width: 100%;
	background-color: ${colors.contentBorder};
`;

export default ReauthenticateModal;
