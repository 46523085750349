import { LoadingButton } from '@mui/lab';
import { Button, Typography } from '@mui/material';
import { APIBuilding, UpdateBuildingParams } from '@rentcheck/types';
import { SpacedRow, Spacer } from 'components';
import { Modal } from 'components/Common';
import { Address } from 'components/properties/CreateBuilding';
import { Floors } from 'components/properties/CreateBuilding/Components';
import { SelectRooms } from 'components/properties/CreateUnit';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { PropertiesActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import styled from 'styled-components';
import { colors, spacingPx } from 'theme';
import { Dispatch } from 'types';
import { RoomNames } from '../../constants';

interface Props {
	section: EditBuildingSection;
	property: APIBuilding;
	setHidden: () => void;
}

export type EditBuildingSection =
	| 'Property Address'
	| 'Floors'
	| 'Amenities & Common Areas';

const EditProperty = ({ section, property, setHidden }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const accountSettings = useTypedSelector((state) => state.accountSettings);

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | undefined>(undefined);

	const [name, setName] = useState(property.name);
	const [address, setAddress] = useState(property.address);
	const [city, setCity] = useState(property.city);
	const [zipcode, setZipcode] = useState(property.zip_code);
	const [floors, setFloors] = useState(property.floors.toString());
	const [roomNames, setRoomNames] = useState(property.room_names);

	const saveAndClose = () => {
		setLoading(true);
		const updatePayload: UpdateBuildingParams['data'] = {};

		if (section === 'Property Address' && address && city && zipcode) {
			updatePayload.name = name;
			updatePayload.address = address;
			updatePayload.city = city;
			updatePayload.zip_code = zipcode;
		}

		if (section === 'Amenities & Common Areas') {
			updatePayload.rooms = roomNames;
		}

		if (section === 'Floors') {
			updatePayload.floors = parseInt(floors);
		}

		dispatch(PropertiesActions.buildings.update(property.id, updatePayload))
			.then(() => setHidden())
			.catch((e) => setError(e.message))
			.finally(() => setLoading(false));
	};

	const renderContent = () => {
		const customRooms = accountSettings?.rooms.building ?? [];

		switch (section) {
			case 'Property Address':
				return (
					<Address
						name={name}
						address={address}
						city={city}
						zipcode={zipcode}
						setName={setName}
						setAddress={setAddress}
						setCity={setCity}
						setZipcode={setZipcode}
					/>
				);
			case 'Floors':
				return <Floors floors={floors} setFloors={setFloors} />;
			case 'Amenities & Common Areas':
				return (
					<SelectRooms
						roomNames={roomNames}
						options={[...RoomNames.building, ...customRooms.map((r) => r.name)]}
						setValue={setRoomNames}
						title={
							'Select all amenities and common areas that apply to this Building'
						}
					/>
				);
		}
	};

	return (
		<Modal targetId="edit-property" setHidden={setHidden}>
			<Typography variant="h6">Update {section}</Typography>
			<Typography>
				{property.address}, {property.city} {property.zip_code}
			</Typography>
			<Spacer height={7} />
			{renderContent()}
			<Spacer height={7} />
			<Divider />
			<Spacer height={5} />
			{!!error && (
				<div>
					<Typography color={colors.danger}>{error}</Typography>
					<Spacer height={5} />
				</div>
			)}
			<SpacedRow>
				<ButtonContainer left>
					<Button variant="text" color="secondary" onClick={setHidden}>
						Cancel
					</Button>
				</ButtonContainer>
				<ButtonContainer right>
					<LoadingButton loading={loading} onClick={saveAndClose}>
						Save & Close
					</LoadingButton>
				</ButtonContainer>
			</SpacedRow>
		</Modal>
	);
};

const Divider = styled.div`
	height: 1px;
	width: 100%;
	background-color: ${colors.contentBorder};
`;

const ButtonContainer = styled.div<{ right?: boolean; left?: boolean }>`
	display: flex;
	flex-direction: column;
	padding-left: ${({ right }) => (right ? spacingPx(2) : 0)};
	padding-right: ${({ left }) => (left ? spacingPx(2) : 0)};
`;

export default EditProperty;
