import { Column, Row } from 'components';
import { FilterType } from 'components/lists/filters';
import FilterButton from 'components/table/filter-button';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { appliedFiltersCount } from 'store/reducers/properties/filters/buildings';
import { useTypedSelector } from 'store/reducers/rootReducer';
import styled from 'styled-components';
import * as theme from 'theme';

import {
	FilterInspectionDateDetails,
	FilterInspectionDateTitle,
} from './filter-last-inspection';
import { FilterTeamDetails, FilterTeamTitle } from './filter-team';

const Filters = () => {
	const dispatch = useDispatch();

	const filters = useTypedSelector(
		(state) => state.properties.filters.buildings
	);

	const [selectedFilter, setSelectedFilter] = useState<
		FilterType | undefined
	>();

	const handleResetFilters = () => {
		dispatch({ type: 'RESET_BUILDINGS_FILTERS' });
	};

	return (
		<FilterButton
			resetFilters={handleResetFilters}
			badgeCount={appliedFiltersCount(filters)}>
			<Row style={{ alignItems: 'flex-start' }}>
				<FilterTitles>
					<FilterInspectionDateTitle
						setFilterDetails={setSelectedFilter}
						selectedFilter={selectedFilter}
					/>
					<FilterTeamTitle
						setFilterDetails={setSelectedFilter}
						selectedFilter={selectedFilter}
					/>
				</FilterTitles>

				<FilterDetails>
					<FilterInspectionDateDetails selectedFilter={selectedFilter} />
					<FilterTeamDetails selectedFilter={selectedFilter} />
				</FilterDetails>
			</Row>
		</FilterButton>
	);
};

const FilterTitles = styled(Column)`
	flex: 1;
	padding: ${theme.spacingPx(2)} 0;
	border-right: 1px solid ${theme.colors.lightGray};
`;

const FilterDetails = styled(Column)`
	flex: 1;
	margin-left: -1px;
	border-left: 1px solid ${theme.colors.lightGray};
	padding: ${theme.spacingPx(2)} 0;
`;

export default Filters;
