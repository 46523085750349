import { MenuItem, SelectChangeEvent } from '@mui/material';
import { Utils } from '@rentcheck/biz';
import { MembershipRole } from '@rentcheck/types';
import FormTextField from 'components/form-text-field';
import { ChangeEvent, useEffect } from 'react';
import { useTypedSelector } from 'store/reducers/rootReducer';

interface Props {
	value?: MembershipRole;
	setValue: (value: MembershipRole) => void;
}

export default ({ value, setValue }: Props) => {
	const profile = useTypedSelector((state) => state.activeProfile);

	const isTeamAdmin = Utils.Users.isTeamAdmin(profile);

	useEffect(() => {
		if (!isTeamAdmin) {
			setValue('manager');
		}
	}, []);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const selectEvent = e as SelectChangeEvent<string>;
		const newValue = selectEvent.target.value;
		setValue(newValue as MembershipRole);
	};

	return (
		<FormTextField
			title="Assign Role"
			caption="Select a team role."
			placeholder="Select Role"
			inputSx={{ width: 250 }}
			onChange={handleChange}
			disabled={!isTeamAdmin}
			select
			required
			value={value}>
			<MenuItem value="admin">Admin</MenuItem>
			<MenuItem value="manager">Manager</MenuItem>
		</FormTextField>
	);
};
