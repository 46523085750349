import { Checkbox, Typography } from '@mui/material';
import { ApiInspection, APIProperty } from '@rentcheck/types';
import { Row } from 'components';
import { CreateWorkOrderMetadata } from 'store/reducers/modal-flows';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { simplePropertyType } from 'utils/helpers';
import { hasAppfolioWorkOrderAddons } from 'utils/integrations';

interface Props {
	inspection: ApiInspection;
	property: APIProperty;
	value?: CreateWorkOrderMetadata['provider'];
	setValue: (value?: CreateWorkOrderMetadata['provider']) => void;
}

const AppFolioWorkOrderIntegration = ({ property, value, setValue }: Props) => {
	const subscription = useTypedSelector((state) => state.subscription);

	if (!hasAppfolioWorkOrderAddons(subscription)) {
		return null;
	}

	if (property?.sync_data?.vendor !== 'appfolio') {
		return (
			<Typography sx={{ marginTop: 3 }}>
				*This {simplePropertyType(property)} is not synced with AppFolio.
			</Typography>
		);
	}

	return (
		<Row style={{ marginTop: 24, marginLeft: -12 }}>
			<Checkbox
				checked={value === 'AppFolio'}
				onChange={() => setValue(value ? undefined : 'AppFolio')}
			/>
			<Typography>Create AppFolio Work Order</Typography>
		</Row>
	);
};

export default AppFolioWorkOrderIntegration;
