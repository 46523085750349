import { DatePicker } from '@mui/lab';
import { Button, TextField, Typography } from '@mui/material';
import { Row, Spacer } from 'components';
import moment from 'moment';
import styled from 'styled-components';

export const recurringOptions = [
	'Does not repeat',
	'Repeat daily',
	'Repeat weekly',
	'Repeat monthly',
	'Repeat quarterly',
	'Repeat semi-annually',
	'Repeat annually',
] as const;

export type RecurringOption = (typeof recurringOptions)[number];

interface Props {
	inspectionDate: Date;
	recurrenceOption: RecurringOption;
	setRecurrenceOption: (recurrenceOption: RecurringOption) => void;
	recurrenceEndDate?: Date;
	setRecurrenceEndDate: (date?: Date) => void;
}

const RecurrenceStep = ({
	inspectionDate,
	recurrenceOption,
	setRecurrenceOption,
	recurrenceEndDate,
	setRecurrenceEndDate,
}: Props) => {
	const isDaily = (option: RecurringOption) => option === 'Repeat daily';

	const defaultDailyEndDate = moment(inspectionDate).add(1, 'month').toDate();

	const defaultEndDate = moment(inspectionDate).add(2, 'years').toDate();

	const maxDate = isDaily(recurrenceOption) ? defaultDailyEndDate : undefined;

	const fixEndDateIfNecessary = (
		newRecurrenceOption: RecurringOption,
		oldRecurrenceOption: RecurringOption
	) => {
		if (isDaily(newRecurrenceOption) === isDaily(oldRecurrenceOption)) return;
		setRecurrenceEndDate(
			isDaily(newRecurrenceOption) ? defaultDailyEndDate : defaultEndDate
		);
	};

	return (
		<>
			<Typography variant="h6">Is this inspection recurring?</Typography>
			<Spacer height={6} />

			<WrappingRow>
				{recurringOptions.map((o) => (
					<Button
						sx={{ marginRight: 1, marginBottom: 1 }}
						variant={o === recurrenceOption ? 'contained' : 'outlined'}
						color={o === recurrenceOption ? 'primary' : 'secondary'}
						onClick={() => {
							setRecurrenceOption(o);
							fixEndDateIfNecessary(o, recurrenceOption);
						}}>
						{o}
					</Button>
				))}
			</WrappingRow>

			{recurrenceOption === 'Does not repeat' && <Spacer height={60} />}
			{recurrenceOption !== 'Does not repeat' && (
				<>
					<Spacer height={6} />
					<Typography variant="h4">Ends On</Typography>
					<Spacer height={2} />
					<DatePicker
						inputFormat="MM/dd/yy"
						value={recurrenceEndDate ?? defaultEndDate}
						onChange={(date) => date && setRecurrenceEndDate(date as Date)}
						disablePast={true}
						maxDate={maxDate}
						renderInput={(props) => <TextField {...props} variant="filled" />}
					/>
					<Spacer height={38.25} />
				</>
			)}
		</>
	);
};

const WrappingRow = styled(Row)`
	flex-wrap: wrap;
`;

export default RecurrenceStep;
