import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import {
	ApiInspectionEvent,
	InspectionEventEditedMetadata,
} from '@rentcheck/types';

import BaseTimelineEvent from '../base-event';

interface Props {
	event: ApiInspectionEvent;
}

const EditedTimelineEvent = ({ event }: Props) => {
	const metadata = event.metadata as InspectionEventEditedMetadata;

	return (
		<BaseTimelineEvent
			icon={solid('file-edit')}
			title="Inspection Edited"
			subtitle={`Edited by ${metadata.edited_by}`}
			timestamp={event.timestamp}
		/>
	);
};

export default EditedTimelineEvent;
