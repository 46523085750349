import CircularProgress from '@mui/material/CircularProgress';
import { UserRequestsApi } from 'api';
import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useTypedSelector } from 'store/reducers/rootReducer';
import styled from 'styled-components';
interface Props {
	match: any;
}

export default function RequestContainer({ match }: Props) {
	const profile = useTypedSelector((state) => state.activeProfile);
	const [userRequest, setUserRequest] = useState<any>(null);
	const [error, setError] = useState(false);

	const fetchRequestAndMakeNecessaryUpdates = async () => {
		try {
			const request = await UserRequestsApi.getUserRequest(match.params.id);
			if (request.recipient_email !== profile.email) {
				await UserRequestsApi.reassignAndAcceptAllUnacceptedUserRequestsForEmail(
					request,
					profile.email,
					profile.id
				);
			} else {
				await UserRequestsApi.acceptUserRequest(request);
				await UserRequestsApi.assignRequestInspectionToUser(
					request,
					profile.id
				);
			}

			setUserRequest(request);
		} catch (e) {
			setError(true);
		}
	};

	useEffect(() => {
		if (match.params && match.params.id && profile) {
			fetchRequestAndMakeNecessaryUpdates();
		}
	}, [profile]);

	if (userRequest)
		return <Redirect to={'/inspections/' + userRequest.inspection_id} />;

	if (error) return <Redirect to="/inspections/" />;

	return (
		<Wrapper>
			<CircularProgress />
		</Wrapper>
	);
}

const Wrapper = styled.div`
	height: 90vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;
