import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import { Feature } from '@rentcheck/types';
import { Column, Row, SpacedRow } from 'components';
import { useFeatureImageModalData } from 'hooks/modals';
import { featureDisplayName, formattedAddress } from 'utils/helpers';

import { colors } from 'theme';
import Close from './close';

interface Props {
	feature: Feature;
}

const Header = ({ feature }: Props) => {
	const modalData = useFeatureImageModalData();

	const inspection = modalData?.metadata?.inspection;

	if (!inspection) {
		return null;
	}

	return (
		<SpacedRow>
			<Column>
				<Typography variant="h4">{featureDisplayName(feature)}</Typography>
				<Row>
					<Typography>
						{formattedAddress(inspection.property)} -{' '}
						{inspection.inspection_template.name}
					</Typography>
					{inspection.fast_track && (
						<FontAwesomeIcon
							icon={solid('bolt')}
							color={colors.primary}
							style={{ fontSize: 14, marginLeft: 8 }}
						/>
					)}
				</Row>
			</Column>

			<Close />
		</SpacedRow>
	);
};

export default Header;
