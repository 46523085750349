import { Button, Card, CardContent, Typography } from '@mui/material';
import { BaseIntegration } from '@rentcheck/types';
import { Spacer } from 'components';
import { useState } from 'react';

import { colors } from 'theme';
import { TeamAssignmentType } from '../types';
import TeamAssignmentModal from './modal';

interface Props {
	vendor: string;
	teamAssignment: TeamAssignmentType;
	teamMappings?: BaseIntegration['team_mappings'];
	setTeamMappings: (value?: BaseIntegration['team_mappings']) => void;
	error: boolean;
}

const TeamAssignment = ({
	vendor,
	teamAssignment,
	teamMappings,
	setTeamMappings,
	error,
}: Props) => {
	const [modalOpen, setModalOpen] = useState(false);

	const handleCloseModal = () => {
		setModalOpen(false);
	};

	const handleOpenModal = () => {
		setModalOpen(true);
	};

	const buttonTitle = teamMappings?.default
		? 'Edit team assignment'
		: 'Set up team assignment';

	return (
		<>
			<Card sx={{ marginBottom: 4 }}>
				<CardContent>
					<Typography variant="subtitle1">Team Assignment</Typography>
					<Spacer height={2} />
					<Typography variant="body2" color="#00000099">
						Map {vendor} properties to RentCheck teams. As updates are made in
						{vendor}, RentCheck team assignments will be also be automatically
						updated in RentCheck on a daily basis.
					</Typography>

					<Spacer height={4} />

					{error && (
						<Typography color={colors.error} sx={{ marginBottom: 2 }}>
							Please select a default team for unmapped properties
						</Typography>
					)}

					<Button variant="outlined" onClick={handleOpenModal}>
						{buttonTitle}
					</Button>
				</CardContent>
			</Card>

			<TeamAssignmentModal
				open={modalOpen}
				handleClose={handleCloseModal}
				vendor={vendor}
				teamMappings={teamMappings}
				setTeamMappings={setTeamMappings}
				teamAssignment={teamAssignment}
			/>
		</>
	);
};

export default TeamAssignment;
