import { Grid } from '@mui/material';
import assets from 'assets';
import { Screens, Spacer } from 'components';

import List from './list';
import MainCta from './main-cta';

export default () => {
	return (
		<Screens.Container>
			<Screens.Header
				showBreadCrumbs
				title="Imports"
				subtitle="Easily import and manage data for your units and residents—view past imports and keep your RentCheck data up to date."
			/>
			<Grid container columns={{ xs: 1, sm: 2 }} spacing={2}>
				<MainCta
					title="Unit Import"
					subtitle="Upload a file to add or delete units in RentCheck."
					image={assets.imports.unitImport}
					onClick={() => {}}
				/>
				<MainCta
					title="Resident Import"
					subtitle="Upload a file to add or update resident information in RentCheck."
					image={assets.imports.residentImport}
					onClick={() => {}}
				/>
			</Grid>
			<Spacer height={6} />
			<List />
		</Screens.Container>
	);
};
