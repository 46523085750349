import { useCreateWorkOrderModalData } from 'hooks/modals';

import { useSkills } from 'hooks/skills';
import CreateWorkOrders from './create-work-orders';
import ReviewSkills from './review-skills';
import Skeleton from './skeleton';

const Content = () => {
	const modalData = useCreateWorkOrderModalData('AppFolio');
	const { inspection, flags } = modalData?.metadata ?? {};

	const { skills, loading } = useSkills(inspection?.id);
	const appFolioSkills = skills.filter((s) => s.vendor === 'AppFolio');

	if (!flags.length) {
		return null;
	}

	if (loading) {
		return <Skeleton />;
	}

	const skillsNeedReview = !!flags.find(
		(f) => !appFolioSkills.find((s) => s.title === f.skill)
	);

	if (skillsNeedReview) {
		return <ReviewSkills validSkills={appFolioSkills} />;
	}

	return <CreateWorkOrders />;
};

export default Content;
