import {
	Checkbox,
	TableCell,
	TableHead,
	TableRow,
	TableSortLabel,
} from '@mui/material';
import { ApiMaintenanceReport } from '@rentcheck/types';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/reducers/rootReducer';
import ActionsMenu from './actions-menu';

interface Props {
	selectedIds: string[];
	selectAllIds: () => void;
	setSelectedIds: (ids: string[]) => void;
	reports: ApiMaintenanceReport[];
}

const headers = [
	{ id: 'property_address', title: 'Address' },
	{ id: 'property_city', title: 'City' },
	{ id: 'property_zip_code', title: 'ZIP Code' },
	{ id: 'name', title: 'Report Name' },
	{ id: 'inspection_template', title: 'Template' },
	{ id: 'created_date', title: 'Date Created' },
];

const Header = ({
	selectedIds,
	selectAllIds,
	setSelectedIds,
	reports,
}: Props) => {
	const dispatch = useDispatch();

	const sortBy = useTypedSelector(
		(state) => state.maintenance.filters.reports.sortBy
	);
	const sortType = useTypedSelector(
		(state) => state.maintenance.filters.reports.sortType
	);

	const switchSortDirection = () => {
		dispatch({
			type: 'TOGGLE_REPORTS_SORT_TYPE',
		});
	};

	const handleSort = (headerId: string) => {
		if (headerId === sortBy) {
			return switchSortDirection();
		}

		dispatch({
			type: 'SET_REPORTS_SORT_BY',
			value: headerId,
		});
	};

	const checked =
		selectedIds.length === reports.length && selectedIds.length > 0;

	const indeterminate =
		selectedIds.length > 0 && selectedIds.length !== reports.length;

	return (
		<TableHead>
			<TableRow>
				<TableCell>
					<Checkbox
						size="small"
						onChange={selectAllIds}
						indeterminate={indeterminate}
						checked={checked}
					/>
				</TableCell>
				{headers.map((h) => (
					<TableCell key={h.id}>
						<TableSortLabel
							active={sortBy === h.id}
							direction={sortType.toLowerCase() as 'asc' | 'desc'}
							onClick={() => handleSort(h.id)}>
							{h.title}
						</TableSortLabel>
					</TableCell>
				))}
				<TableCell align="center">
					<ActionsMenu ids={selectedIds} setSelectedIds={setSelectedIds} />
				</TableCell>
			</TableRow>
		</TableHead>
	);
};

export default Header;
