import { combineReducers } from 'redux';
import filtersReducer, { State as FiltersState } from './filters';
import reportReducer, { State as ReportState } from './report';
import reportsReducer, { State as ReportsState } from './reports';
import workOrderReducer, { State as WorkOrderState } from './work-order';
import workOrdersReducer, { State as WorkOrdersState } from './work-orders';

export type State = {
	filters: FiltersState;
	reports: ReportsState;
	workOrders: WorkOrdersState;
	report: ReportState;
	workOrder: WorkOrderState;
};

const reducer = combineReducers({
	filters: filtersReducer,
	reports: reportsReducer,
	report: reportReducer,
	workOrders: workOrdersReducer,
	workOrder: workOrderReducer,
});

export default reducer;
