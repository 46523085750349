import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

const SyncSwitch = styled(Switch)(() => ({
	padding: 8,
	height: 42,
	width: 62,
	'& .MuiSwitch-track': {
		borderRadius: 42 / 2,
		'&:before, &:after': {
			content: '""',
			position: 'absolute',
			top: '50%',
			transform: 'translateY(-50%)',
			width: 20,
			height: 20,
		},
	},
	'& .MuiSwitch-thumb': {
		boxShadow: 'none',
		width: 20,
		height: 20,
		margin: 2,
	},
}));

export default SyncSwitch;
