import { Button, Snackbar, SnackbarContent, Typography } from '@mui/material';
import { useState } from 'react';

import { useRemoteSettings } from 'utils/hooks';

export default () => {
	const { settings } = useRemoteSettings();

	const [closedByUser, setClosedByUser] = useState(false);

	const handleClose = () => {
		setClosedByUser(true);
	};

	const open =
		(settings.maintenance_etl || settings.maintenance_admin_panel) &&
		!closedByUser;

	return (
		<Snackbar
			open={open}
			sx={{ marginTop: 6 }}
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
			<SnackbarContent
				style={{ backgroundColor: 'black' }}
				message={
					<>
						<Typography
							style={{ color: 'white', maxWidth: 400, fontWeight: 'bold' }}>
							🔧 SITE MAINTENANCE IN PROGRESS 🔧
						</Typography>
						<Typography style={{ color: 'white', maxWidth: 400 }}>
							Hang tight- We'll be back up and running soon! ✨
						</Typography>
					</>
				}
				action={
					<Button
						variant="text"
						onClick={handleClose}
						style={{ color: '#ACFFC6' }}>
						Ok
					</Button>
				}
			/>
		</Snackbar>
	);
};
