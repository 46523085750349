import { useTypedSelector } from 'store/reducers/rootReducer';

import SnapshotRow from './snapshot-row';

const RevisionRequested = () => {
	const dashboardData = useTypedSelector((state) => state.dashboard.data);

	const count = dashboardData?.todo_list.revision_requested_inspections_count;

	return (
		<SnapshotRow
			count={count}
			label="Revision Requested"
			link="/dashboard/revision-requested"
		/>
	);
};

export default RevisionRequested;
