import { WorkOrder } from '@rentcheck/api-frontend';

import IntegrationDetails from './common';

interface Props {
	workOrder: WorkOrder;
}

export default ({ workOrder }: Props) => {
	if (!workOrder.rentmanager) {
		return null;
	}

	const details = [
		{
			title: 'Service Details:',
			value: workOrder.rentmanager.description,
		},
		{
			title: 'Category:',
			value: workOrder.rentmanager.category,
		},
		{
			title: 'Priority:',
			value: workOrder.rentmanager.priority,
		},
		{
			title: 'Ok to enter:',
			value: workOrder.rentmanager.ok_to_enter ? 'Yes' : 'No',
		},
	];

	return <IntegrationDetails details={details} />;
};
