import { Skeleton } from '@mui/material';
import { Column, Row, Spacer } from 'components';

export default () => {
	return (
		<Column>
			<Row style={{ marginBottom: 28 }}>
				<Skeleton variant="circular" width={38} height={38} />
				<Column style={{ marginLeft: 12, marginTop: 8 }}>
					<Row style={{ alignItems: 'flex-start' }}>
						<Skeleton variant="text" width={60} height={14} />
						<Spacer width={3} />
						<Column>
							<Skeleton variant="text" width={210} height={18} />
							<Skeleton variant="text" width={250} height={14} />
							<Skeleton variant="text" width={180} height={14} />
						</Column>
					</Row>
				</Column>
			</Row>
			<Row style={{ marginBottom: 28 }}>
				<Skeleton variant="circular" width={38} height={38} />
				<Column style={{ marginLeft: 12, marginTop: 8 }}>
					<Row style={{ alignItems: 'flex-start' }}>
						<Skeleton variant="text" width={60} height={14} />
						<Spacer width={3} />
						<Column>
							<Skeleton variant="text" width={210} height={18} />
							<Skeleton variant="text" width={250} height={14} />
							<Skeleton variant="text" width={180} height={14} />
						</Column>
					</Row>
				</Column>
			</Row>
			<Row style={{ marginBottom: 28 }}>
				<Skeleton variant="circular" width={38} height={38} />
				<Column style={{ marginLeft: 12, marginTop: 8 }}>
					<Row style={{ alignItems: 'flex-start' }}>
						<Skeleton variant="text" width={60} height={14} />
						<Spacer width={3} />
						<Column>
							<Skeleton variant="text" width={210} height={18} />
							<Skeleton variant="text" width={250} height={14} />
							<Skeleton variant="text" width={180} height={14} />
						</Column>
					</Row>
				</Column>
			</Row>
			<Row style={{ marginBottom: 28 }}>
				<Skeleton variant="circular" width={38} height={38} />
				<Column style={{ marginLeft: 12, marginTop: 8 }}>
					<Row style={{ alignItems: 'flex-start' }}>
						<Skeleton variant="text" width={60} height={14} />
						<Spacer width={3} />
						<Column>
							<Skeleton variant="text" width={210} height={18} />
							<Skeleton variant="text" width={250} height={14} />
							<Skeleton variant="text" width={180} height={14} />
						</Column>
					</Row>
				</Column>
			</Row>
			<Row style={{ marginBottom: 28 }}>
				<Skeleton variant="circular" width={38} height={38} />
				<Column style={{ marginLeft: 12, marginTop: 8 }}>
					<Row style={{ alignItems: 'flex-start' }}>
						<Skeleton variant="text" width={60} height={14} />
						<Spacer width={3} />
						<Column>
							<Skeleton variant="text" width={210} height={18} />
							<Skeleton variant="text" width={250} height={14} />
							<Skeleton variant="text" width={180} height={14} />
						</Column>
					</Row>
				</Column>
			</Row>
			<Row style={{ marginBottom: 28 }}>
				<Skeleton variant="circular" width={38} height={38} />
				<Column style={{ marginLeft: 12, marginTop: 8 }}>
					<Row style={{ alignItems: 'flex-start' }}>
						<Skeleton variant="text" width={60} height={14} />
						<Spacer width={3} />
						<Column>
							<Skeleton variant="text" width={210} height={18} />
							<Skeleton variant="text" width={250} height={14} />
							<Skeleton variant="text" width={180} height={14} />
						</Column>
					</Row>
				</Column>
			</Row>
			<Row style={{ marginBottom: 28 }}>
				<Skeleton variant="circular" width={38} height={38} />
				<Column style={{ marginLeft: 12, marginTop: 8 }}>
					<Row style={{ alignItems: 'flex-start' }}>
						<Skeleton variant="text" width={60} height={14} />
						<Spacer width={3} />
						<Column>
							<Skeleton variant="text" width={210} height={18} />
							<Skeleton variant="text" width={250} height={14} />
							<Skeleton variant="text" width={180} height={14} />
						</Column>
					</Row>
				</Column>
			</Row>
		</Column>
	);
};
