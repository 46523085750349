import { Skeleton } from '@mui/material';

import { Row, SpacedRow } from 'components';

const ResidentsSkeleton = () => {
	const headerHeight = 60;
	const rowHeight = 68.5;

	const rows = Array.from(Array(20).keys());

	return (
		<div>
			<Skeleton
				variant="rectangular"
				width="100%"
				height={headerHeight}
				sx={{ borderRadius: 1 }}
			/>
			{rows.map((i) => (
				<Row
					key={i}
					style={{
						height: rowHeight,
						width: '100%',
						marginBottom: 1,
					}}>
					<SpacedRow style={{ flex: 1, paddingLeft: 24, paddingRight: 56 }}>
						<Skeleton variant="text" width="100%" height={30} />
					</SpacedRow>
				</Row>
			))}
		</div>
	);
};

export default ResidentsSkeleton;
