import { Divider, Typography } from '@mui/material';
import { useEffect, useRef } from 'react';
import { useHover } from 'usehooks-ts';

import { Feature, TemplateQuestion } from '@rentcheck/types';

import { Column, SpacedRow } from 'components';
import styled from 'styled-components';

interface Props {
	feature: Feature;
	question: TemplateQuestion;
	answer: string;
	hoveredImage?: string;
	setHoveredQuestion?: (question?: TemplateQuestion) => void;
	showDivider?: boolean;
}

export default ({
	feature,
	hoveredImage,
	question,
	answer,
	setHoveredQuestion,
	showDivider = true,
}: Props) => {
	const hoverRef = useRef(null);
	const isHovering = useHover(hoverRef);

	useEffect(() => {
		if (answer !== 'No') return;

		if (!setHoveredQuestion) return;

		if (isHovering) {
			setHoveredQuestion(question);
		} else {
			setHoveredQuestion(undefined);
		}
	}, [isHovering]);

	const isNoAnswer = answer === 'No';
	const forceHighlight = !!feature.image_links?.find(
		(link) =>
			link.image_uri === hoveredImage &&
			link.linked_object === question.title &&
			link.type === 'no_answer'
	);

	return (
		<Column>
			<HighlitableSpacedRow
				isNoAnswer={isNoAnswer}
				forceHighlight={forceHighlight}
				ref={hoverRef}>
				<Typography color="#00000099">{question.title}</Typography>
				<Typography variant="subtitle2" color={isNoAnswer ? '#F59E17' : ''}>
					{answer}
				</Typography>
			</HighlitableSpacedRow>
			{showDivider && <Divider />}
		</Column>
	);
};

const HighlitableSpacedRow = styled(SpacedRow)<{
	isNoAnswer: boolean;
	forceHighlight: boolean;
}>`
	margin-bottom: 12px;
	margin-top: 12px;

	transition: all 0.3s;

	${({ forceHighlight }) =>
		forceHighlight ? 'background-color: #FEF7EC;' : ''}

	&:hover {
		background-color: ${({ isNoAnswer }) =>
			isNoAnswer ? '#FEF7EC' : 'transparent'};
	}
`;
