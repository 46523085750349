import { Divider, Grid, Typography } from '@mui/material';
import { Column } from 'components';
import { ReactNode } from 'react';

import { Link } from 'react-router-dom';

interface Props {
	title: string;
	value: ReactNode;
	colSpan?: number;
	linkTo?: string;
}

export default ({ colSpan = 1, title, value, linkTo }: Props) => {
	return (
		<Grid
			item
			xs={colSpan}
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
			}}>
			<Column>
				<Typography variant="overline">{title}</Typography>
				<Typography variant="body2" mt={1} mb={1}>
					{!!linkTo && <Link to={linkTo}>{value}</Link>}
					{!linkTo && value}
				</Typography>
			</Column>
			<Divider />
		</Grid>
	);
};
