import {
	Timeline,
	TimelineConnector,
	TimelineContent,
	TimelineDot,
	TimelineItem,
	timelineItemClasses,
	TimelineSeparator,
} from '@mui/lab';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Typography,
} from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { success } from 'assets';
import { Column, DialogTitle, Spacer } from 'components';

interface Props {
	onInstall: () => void;
	vendor: string;
	parentUrl: string;
}

export default ({ onInstall, parentUrl, vendor }: Props) => {
	const history = useHistory();
	const location = useLocation();

	const handleInstall = () => {
		handleClose();
		onInstall();
	};

	const handleClose = () => {
		history.push(parentUrl);
	};

	const open = location.pathname.startsWith(parentUrl + '/upgrade-success');

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle
				title="Upgrade Successful!"
				onClose={handleClose}
				icon={solid('puzzle-piece')}
			/>

			<DialogContent>
				<Column>
					<img
						style={{
							width: 400,
							height: 180,
							objectFit: 'contain',
							alignSelf: 'center',
						}}
						src={success.appFolioUpgrade}
					/>
					<Spacer height={2} />

					<Timeline
						sx={{
							[`& .${timelineItemClasses.root}:before`]: {
								flex: 0,
								padding: 0,
							},
						}}>
						<TimelineItem>
							<TimelineSeparator>
								<TimelineDot
									variant="outlined"
									sx={{ width: 18, height: 18 }}></TimelineDot>
								<TimelineConnector />
							</TimelineSeparator>
							<TimelineContent>
								<Typography variant="subtitle2" sx={{ marginTop: 1.2 }}>
									Install {vendor} integration
								</Typography>
							</TimelineContent>
						</TimelineItem>
						<TimelineItem>
							<TimelineSeparator>
								<TimelineDot
									variant="outlined"
									sx={{ width: 18, height: 18 }}></TimelineDot>
							</TimelineSeparator>
							<TimelineContent>
								<Typography variant="subtitle2">
									Set up sync and adjust settings
								</Typography>
								<Typography>Sync data the way you want.</Typography>
							</TimelineContent>
						</TimelineItem>
					</Timeline>
				</Column>
			</DialogContent>

			<DialogActions>
				<div />
				<Button onClick={handleInstall}>Install</Button>
			</DialogActions>
		</Dialog>
	);
};
