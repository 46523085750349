import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Rules } from '@rentcheck/biz';
import { ApiInspectionWithTemplate } from '@rentcheck/types';
import { CenteredRow } from 'components';
import { FlagButton } from 'components/features';
import IconButton from 'components/icon-button';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FeatureActions } from 'store/actions';
import { FeatureWithMetadata } from 'store/reducers/features';
import { Dispatch } from 'types';

interface Props {
	feature: FeatureWithMetadata;
	inspection: ApiInspectionWithTemplate;
	image: string;
}

export default ({ feature, inspection, image }: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const [loadingRotateLeft, setLoadingRotateLeft] = useState(false);
	const [loadingRotateRight, setLoadingRotateRight] = useState(false);

	const handleRotateLeft = () => {
		setLoadingRotateLeft(true);
		dispatch(FeatureActions.rotatePhoto(feature.id, image, 270)).finally(() =>
			setLoadingRotateLeft(false)
		);
	};

	const handleRotateRight = () => {
		setLoadingRotateRight(true);
		dispatch(FeatureActions.rotatePhoto(feature.id, image, 90)).finally(() =>
			setLoadingRotateRight(false)
		);
	};

	if (!Rules.Inspections.canPerformFeatureActions(inspection)) {
		return null;
	}

	return (
		<CenteredRow
			style={{
				position: 'absolute',
				top: 0,
				right: 0,
				backgroundColor: '#FFFFFF99',
				height: 40,
				width: '100%',
			}}>
			<FlagButton feature={feature} inspection={inspection} image={image} />

			{Rules.Features.canRotateImage(image) && (
				<IconButton
					loading={loadingRotateLeft}
					icon={solid('rotate-left')}
					onClick={handleRotateLeft}
					tooltip="Rotate Left"
					disabled={loadingRotateLeft || loadingRotateRight}
				/>
			)}
			{Rules.Features.canRotateImage(image) && (
				<IconButton
					loading={loadingRotateRight}
					icon={solid('rotate-right')}
					onClick={handleRotateRight}
					tooltip="Rotate Right"
					disabled={loadingRotateLeft || loadingRotateRight}
				/>
			)}
		</CenteredRow>
	);
};
