import { Row, SpacedRow, Spacer } from 'components';
import QueryTotals from 'components/lists/query-totals';
import ExportButton from 'components/table/export-button';
import SearchBar from 'components/table/search';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PropertiesActions, SnackbarActions } from 'store/actions';
import { RootState, useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';

import Skeleton from '../common/skeleton';
import FiltersButton from './filters';
import Table from './table';

const BuildingsList = () => {
	const dispatch: Dispatch = useDispatch();

	const filters = useTypedSelector(
		(state) => state.properties.filters.buildings
	);

	const [initialLoading, setInitialLoading] = useState(true);
	const [selectedIds, setSelectedIds] = useState<string[]>([]);

	useEffect(() => {
		dispatch(PropertiesActions.buildings.getAll(0, filters))
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => {
				setInitialLoading(false);
			});
	}, []);

	const searchExtractor = (state: RootState) => {
		return state.properties.filters.buildings.search;
	};

	const totalExtractor = (state: RootState) => {
		return state.properties.buildings.total;
	};

	if (initialLoading) {
		return <Skeleton />;
	}

	return (
		<>
			<SpacedRow>
				<QueryTotals
					totalExtractor={totalExtractor}
					selectedIds={selectedIds}
				/>
				<Row>
					<SearchBar stateExtractor={searchExtractor} type="buildings" />
					<FiltersButton />
					<ExportButton variant="buildings" />
				</Row>
			</SpacedRow>
			<Spacer height={6} />
			<Table selectedIds={selectedIds} setSelectedIds={setSelectedIds} />
		</>
	);
};

export default BuildingsList;
