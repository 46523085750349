import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CardContent, Paper, Typography } from '@mui/material';
import { Button, PlanGate, SpacedRow, Spacer } from 'components';
import { ReactNode, ReactNodeArray } from 'react';

interface Props {
	title: string;
	children: ReactNode | ReactNodeArray;
	onAdd?: () => void;
}

export default ({ title, children, onAdd }: Props) => {
	return (
		<Paper elevation={0} sx={{ backgroundColor: '#0000000A', marginBottom: 2 }}>
			<CardContent sx={{ padding: 2 }}>
				<SpacedRow>
					<Typography variant="overline">{title}</Typography>
					{!!onAdd && (
						<PlanGate variant="professional">
							<Button
								onClick={onAdd}
								startIcon={<FontAwesomeIcon icon={solid('plus')} />}>
								Add
							</Button>
						</PlanGate>
					)}
				</SpacedRow>
				<Spacer height={4} />

				{children}
			</CardContent>
		</Paper>
	);
};
