import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
	ApiInspectionEvent,
	InspectionEventRequestAcceptedMetadata,
} from '@rentcheck/types';
import BaseTimelineEvent from '../base-event';

interface Props {
	event: ApiInspectionEvent;
}

const RequestAcceptedTimelineEvent = ({ event }: Props) => {
	const metadata = event.metadata as InspectionEventRequestAcceptedMetadata;

	return (
		<BaseTimelineEvent
			icon={solid('user-check')}
			title="Inspection request accepted"
			subtitle={`Accepted by ${metadata.accepted_by}`}
			timestamp={event.timestamp}
		/>
	);
};

export default RequestAcceptedTimelineEvent;
