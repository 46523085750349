import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useDispatch } from 'react-redux';

import { ApiInspectionWithTemplate, Feature } from '@rentcheck/types';

import { Rules } from '@rentcheck/biz';
import ToggleButton from 'components/toggle-button';
import { ModalFlowActions } from 'store/actions';

interface Props {
	feature: Feature;
	inspection: ApiInspectionWithTemplate;
	buttonSx?: React.CSSProperties;
	disabled?: boolean;
}

export default ({ feature, buttonSx, inspection, disabled }: Props) => {
	const dispatch = useDispatch();

	const hasRevisionNote = !!feature.revision_notes;

	const handleClick = () => {
		dispatch(ModalFlowActions.showRevisionNotesModal({ feature }));
	};

	if (!Rules.Inspections.canSetRevisionNotes(inspection, feature)) {
		return null;
	}

	return (
		<ToggleButton
			selected={hasRevisionNote}
			onClick={handleClick}
			buttonSx={buttonSx}
			icon={regular('message-lines')}
			disabled={disabled}
			tooltip={hasRevisionNote ? 'Edit Revision Note' : 'Add Revision Note'}
		/>
	);
};
