import { Skeleton } from '@mui/material';
import { Row, SpacedRow, Spacer } from 'components';

const PropertyDetailsSkeleton = () => {
	const headerHeight = 55;
	const rowHeight = 68.5;

	const rows = Array.from(Array(5).keys());

	return (
		<div id="details-skeleton">
			<Spacer height={5} />

			<Skeleton
				variant="rectangular"
				width="100%"
				height={headerHeight}
				sx={{ borderRadius: 1 }}
			/>

			{rows.map((i) => (
				<Row
					key={i}
					style={{
						height: rowHeight,
						width: '100%',
						marginBottom: 1,
					}}>
					<SpacedRow style={{ flex: 1, paddingLeft: 24, paddingRight: 56 }}>
						<Skeleton variant="text" width={'100%'} height={30} />
					</SpacedRow>
				</Row>
			))}

			<Spacer height={7} />

			<Row style={{ justifyContent: 'flex-end' }}>
				<Skeleton variant="text" width={50} height={30} />

				<Spacer width={4} />

				<Skeleton
					variant="rectangular"
					width={10}
					height={20}
					sx={{ borderRadius: 1 }}
				/>

				<Spacer width={7} />

				<Skeleton
					variant="rectangular"
					width={10}
					height={20}
					sx={{ borderRadius: 1 }}
				/>

				<Spacer width={2} />
			</Row>
		</div>
	);
};

export default PropertyDetailsSkeleton;
