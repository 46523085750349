import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	InputAdornment,
	TextField,
	Typography,
} from '@mui/material';
import {
	ApiTenant,
	CreateTenantV2Params,
	UpdateTenantV2Params,
} from '@rentcheck/types';
import MuiPhoneNumber from 'mui-phone-number';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { DialogTitle, Spacer } from 'components';
import ResponsiveForm from 'components/responsive-form';
import {
	ModalFlowActions,
	SnackbarActions,
	TenantsActions,
} from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';
import { isEmailValid } from 'utils/emails';

interface Props {
	resident?: ApiTenant;
	setShowLease?: () => void;
}

const ResidentCreate = ({ resident, setShowLease }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const profile = useTypedSelector((state) => state.activeProfile);

	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');

	const [errors, setErrors] = useState<string[]>([]);

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setFirstName(resident?.first_name ?? '');
		setLastName(resident?.last_name ?? '');
		setEmail(resident?.emails[0] ?? '');
		setPhoneNumber(resident?.phone_numbers[0] ?? '');
	}, [resident]);

	const handleClose = () => {
		if (resident) {
			dispatch({ type: 'UPDATE_TENANTS', tenants: [{ ...resident }] });
			return;
		}

		dispatch(ModalFlowActions.closeCurrentModal());
	};

	const validate = () => {
		const errors: string[] = [];

		if (!firstName) {
			errors.push('first_name');
		}

		if (!lastName) {
			errors.push('last_name');
		}

		if (!email) {
			errors.push('email');
		}

		if (!isEmailValid(email)) {
			errors.push('email');
		}

		setErrors(errors);

		return errors.length === 0;
	};

	const handleCreate = () => {
		const payload: CreateTenantV2Params['data'] = {
			first_name: firstName,
			last_name: lastName,
			emails: [email],
			phone_numbers: [],
		};

		if (phoneNumber) {
			payload.phone_numbers = [phoneNumber];
		}

		if (profile.teams.length) {
			payload.organization_id = profile.teams[0].id;
		}

		dispatch(TenantsActions.create(payload))
			.then(() => {
				dispatch(SnackbarActions.showSuccess('Resident created'));
				if (setShowLease) setShowLease();
			})
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	const handleUpdate = () => {
		if (!resident) {
			return;
		}

		const payload: UpdateTenantV2Params['data'] = {
			first_name: firstName,
			last_name: lastName,
			emails: [email],
			phone_numbers: [],
		};

		if (phoneNumber.length > 1) {
			payload.phone_numbers = [phoneNumber];
		}

		if (profile.teams.length) {
			payload.organization_id = profile.teams[0].id;
		}

		dispatch(TenantsActions.update(resident.id, payload))
			.then(() =>
				dispatch(() =>
					dispatch(SnackbarActions.showSuccess('Resident updated'))
				)
			)
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	const handleSave = () => {
		if (!validate()) {
			return;
		}

		setLoading(true);

		if (!resident) {
			return handleCreate();
		}

		handleUpdate();
	};

	return (
		<Dialog open onClose={handleClose}>
			<DialogTitle
				title="Resident Details"
				icon={solid('user-circle')}
				onClose={handleClose}
			/>

			<DialogContent>
				<Typography variant="overline">Contact Details</Typography>
				<Spacer height={4} />
				<ResponsiveForm onSubmit={handleSave}>
					<TextField
						label="First Name"
						variant="filled"
						helperText="Required"
						value={firstName}
						onChange={(e) => setFirstName(e.target.value)}
						error={errors.includes('first_name')}
						fullWidth
					/>
					<TextField
						label="Last Name"
						variant="filled"
						helperText="Required"
						value={lastName}
						onChange={(e) => setLastName(e.target.value)}
						error={errors.includes('last_name')}
						fullWidth
					/>
					<TextField
						label="Email"
						variant="filled"
						helperText="Required"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						error={errors.includes('email')}
						fullWidth
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<FontAwesomeIcon icon={regular('envelope')} />
								</InputAdornment>
							),
						}}
					/>
					<PhoneNumberTextField
						label="Phone Number"
						defaultCountry={'us'}
						variant="filled"
						helperText=" "
						value={phoneNumber}
						onChange={(e) => setPhoneNumber(e as string)}
						fullWidth
					/>
				</ResponsiveForm>
			</DialogContent>

			<DialogActions>
				<Button variant="text" color="secondary" onClick={handleClose}>
					Cancel
				</Button>

				<LoadingButton
					id="button-confirm"
					variant="contained"
					loading={loading}
					onClick={handleSave}>
					{resident ? 'Save' : 'Next'}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

const PhoneNumberTextField = styled(MuiPhoneNumber)`
	.MuiInputAdornment-root {
		filter: grayscale();
	}

	.MuiIconButton-root {
		min-width: 20px !important;
		height: 20px !important;
	}
`;

export default ResidentCreate;
