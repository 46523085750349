import { useState } from 'react';

import { LoadingButton } from '@mui/lab';
import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	MenuItem,
	Select,
	SelectChangeEvent,
	Typography,
} from '@mui/material';
import _ from 'lodash';

import { ApiLatchelIntegration } from '@rentcheck/types';

import { Column, Row, Spacer } from 'components';
import { colors } from 'theme';

import { useLatchelIntegrations } from 'hooks/latchel-integrations';
import { formatDate } from 'utils/helpers';

interface Props {
	internalLabel: string;
	setApiKey: (apiKey: string | null) => void;
	nextStep: () => void;
	back: () => void;
	loading: boolean;
}

const Latchel = ({
	internalLabel,
	nextStep,
	back,
	setApiKey,
	loading,
}: Props) => {
	const { latchelIntegrations } = useLatchelIntegrations();
	const integrations = _.uniqBy(latchelIntegrations, 'api_key');

	const [integration, setIntegration] = useState<ApiLatchelIntegration>();

	const handleSetIntegation = (e: SelectChangeEvent<string>) => {
		const apiKey = e.target.value;
		setIntegration(integrations.find((i) => i.api_key === apiKey));
		setApiKey(apiKey);
	};

	return (
		<>
			<DialogTitle>
				<Column>
					Enable Integrations
					<Spacer height={1} />
					<Typography color={colors.gray}>{internalLabel}</Typography>
				</Column>
			</DialogTitle>

			<DialogContent>
				<Column>
					<Typography>
						Enable the Latchel integration for this team by selecting a
						connection below. You can also enable this later from the
						Integrations page.
					</Typography>
					<Spacer height={6} />

					<Typography variant="h5">Select one (Optional)</Typography>
					<Spacer height={3} />

					<Select
						id="select-integration"
						variant="filled"
						value={integration?.api_key ?? 'placeholder'}
						onChange={handleSetIntegation}>
						<MenuItem value="placeholder" sx={{ display: 'none' }}></MenuItem>
						{integrations.map((i) => {
							if (!i) return null;
							return (
								<MenuItem
									key={i?.api_key}
									value={i.api_key}
									id={`select-integration-${i.api_key}`}>
									<Row>
										{i.company_name}
										<Spacer width={2} />
										<Typography color={colors.gray} fontSize={12}>
											Installed by {i.created_by.name} on{' '}
											{formatDate(i.created_date)}
										</Typography>
									</Row>
								</MenuItem>
							);
						})}
					</Select>
				</Column>
			</DialogContent>

			<DialogActions>
				<Button variant="outlined" onClick={back}>
					Back
				</Button>

				<LoadingButton variant="contained" loading={loading} onClick={nextStep}>
					Complete Team
				</LoadingButton>
			</DialogActions>
		</>
	);
};

export default Latchel;
