import { Rules } from '@rentcheck/biz';
import { ApiInspection } from '@rentcheck/types';
import MenuItem from 'components/menu-item';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { InspectionsActions, ModalFlowActions } from 'store/actions';
import { Dispatch } from 'types';

interface Props {
	inspection: ApiInspection;
	onSelected: () => void;
}

export default ({ inspection, onSelected }: Props) => {
	const dispatch = useDispatch<Dispatch>();
	const history = useHistory();

	const handleClick = () => {
		onSelected();

		const handleConfirm = () => {
			dispatch(ModalFlowActions.closeConfirmationModal());

			dispatch(InspectionsActions.deleteInspections([inspection.id]))
				.then(() => history.push('/inspections'))
				.catch(() => {});
		};

		dispatch(
			ModalFlowActions.showConfirmationModal({
				title: 'Are you sure?',
				body1: [
					'Are you sure you want to delete this inspection?',
					'This action is not reversible',
				],
				body2: [],
				buttons: [
					ModalFlowActions.cancelButton(dispatch),
					{
						title: 'Delete & Exit',
						color: 'error',
						variant: 'contained',
						onClick: handleConfirm,
					},
				],
			})
		);
	};

	if (!Rules.Inspections.canDelete(inspection)) {
		return null;
	}

	return <MenuItem onClick={handleClick}>Delete</MenuItem>;
};
