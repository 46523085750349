import {
	styled,
	Tooltip as MuiTooltip,
	tooltipClasses,
	Typography,
} from '@mui/material';
import { Column, Spacer } from 'components';

interface Props {
	title: string;
	body: string;
	cta: string;
	handleCta: () => void;
	children: React.ReactNode | React.ReactNodeArray;
}
const Tooltip = ({ title, body, cta, handleCta, children }: Props) => {
	return (
		<LightTooltip
			sx={{ width: 250 }}
			title={
				<Column style={{ padding: 6 }}>
					<Typography fontWeight={600} color="#445567">
						{title}
					</Typography>
					<Spacer height={2} />
					<Typography color="#445567">{body}</Typography>
					<Spacer height={2} />
					<Typography
						color="#222EDB"
						sx={{ cursor: 'pointer' }}
						onClick={handleCta}>
						{cta}
					</Typography>
				</Column>
			}>
			{children}
		</LightTooltip>
	);
};

const LightTooltip = styled(({ className, ...props }) => (
	<MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.white,
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[1],
		fontSize: 11,
	},
}));

export default Tooltip;
