import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip, Skeleton } from '@mui/material';
import { LatchelProperty, LatchelTenant } from '@rentcheck/api-frontend';

interface Props {
	property: LatchelProperty | null;
	tenants: LatchelTenant[];
	loadingTenants: boolean;
}

const VacancyChip = ({ property, tenants, loadingTenants }: Props) => {
	if (!property) {
		return <div style={{ height: 30 }} />;
	}

	if (loadingTenants) {
		return (
			<Skeleton
				sx={{ borderRadius: 10 }}
				variant="rectangular"
				width={95}
				height={30}
			/>
		);
	}

	if (tenants.length > 0) {
		return (
			<Chip
				id="chip-occupied"
				sx={{
					height: 30,
					fontWeight: 600,
					backgroundColor: '#e7eafd',
				}}
				label="Occupied"
				icon={<FontAwesomeIcon icon={solid('user')} />}
			/>
		);
	}

	return (
		<Chip
			id="chip-vacant"
			sx={{
				height: 30,
				fontWeight: 600,
				backgroundColor: '#e6f1ea',
			}}
			label="Vacant"
			icon={<FontAwesomeIcon icon={solid('user-alt-slash')} />}
		/>
	);
};

export default VacancyChip;
