import React from 'react';
import {
	Typography,
	TableHead,
	TableRow,
	TableCell,
	TableContainer,
	Paper,
	Table,
	TableFooter,
	TablePagination,
	IconButton,
} from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

import { Column, Spacer, Row } from 'components';

interface TablePaginationActionsProps {
	count: number;
	page: number;
	rowsPerPage: number;
	onPageChange: (
		event: React.MouseEvent<HTMLButtonElement>,
		newPage: number
	) => void;
}

const TablePaginationActions = (props: TablePaginationActionsProps) => {
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleBackButtonClick = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		onPageChange(event, page + 1);
	};

	return (
		<Row>
			<IconButton onClick={handleBackButtonClick} disabled={page === 0}>
				<KeyboardArrowLeft />
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
				<KeyboardArrowRight />
			</IconButton>
		</Row>
	);
};

interface Props {
	headers: string[];
	children: JSX.Element | JSX.Element[];
	length: number;
	pageSize: number;
	startIndex: number;
	setStartIndex: (index: number) => void;
}

const DetailsTable = ({
	headers,
	children,
	length,
	pageSize,
	startIndex,
	setStartIndex,
}: Props) => {
	const handleChangePage = (
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number
	) => {
		setStartIndex(newPage);
	};

	return (
		<Column>
			<Spacer height={5} />

			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							{headers.map((header) => {
								return (
									<TableCell key={header}>
										<Typography fontWeight={600} variant="body1">
											{header}
										</Typography>
									</TableCell>
								);
							})}
						</TableRow>
					</TableHead>

					{children}

					<TableFooter>
						<TableRow>
							<TablePagination
								rowsPerPageOptions={[]}
								count={length}
								rowsPerPage={pageSize}
								page={startIndex}
								onPageChange={handleChangePage}
								ActionsComponent={TablePaginationActions}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
		</Column>
	);
};

export default DetailsTable;
