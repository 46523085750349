import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
	ApiInspectionEvent,
	InspectionEventReopenedMetadata,
} from '@rentcheck/types';
import BaseTimelineEvent from '../base-event';

interface Props {
	event: ApiInspectionEvent;
}

const ReopenedTimelineEvent = ({ event }: Props) => {
	const metadata = event.metadata as InspectionEventReopenedMetadata;

	return (
		<BaseTimelineEvent
			icon={solid('file-edit')}
			title="Inspection Reopened"
			subtitle={`Reopened by ${metadata.reopened_by}`}
			timestamp={event.timestamp}
		/>
	);
};

export default ReopenedTimelineEvent;
