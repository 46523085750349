import { PropertiesApi } from '@rentcheck/api-frontend';
import { ReduxFn } from 'types';

import * as buildings from './buildings';
import * as communities from './communities';
import * as units from './units';

export const get = (id: string) => {
	const fn: ReduxFn = async (dispatch) => {
		const property = await PropertiesApi.getById(id);

		if (!property) throw new Error('Property not found');

		dispatch({ type: 'FETCHED_PROPERTY', property });

		return property;
	};
	return fn;
};

export { buildings, communities, units };
