import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import { Column, Row, SpacedRow } from 'components';
import { featureDisplayName } from 'utils/helpers';

import { useLocation } from 'react-router-dom';
import { colors } from 'theme';

const Header = () => {
	const { search } = useLocation();

	const searchParams = new URLSearchParams(search);

	const room = searchParams.get('room');
	const feature = searchParams.get('feature');
	const address = searchParams.get('address');
	const inspectionType = searchParams.get('inspection_type');
	const fastTrack = searchParams.get('fast_track') === 'true';
	const completedDate = searchParams.get('completed_date');

	if (!room || !feature || !address || !inspectionType) {
		return null;
	}

	return (
		<SpacedRow>
			<Column>
				<Typography variant="h4">
					{featureDisplayName({ room, feature })}
				</Typography>
				<Row>
					<Typography>
						{address} - {inspectionType}
					</Typography>
					{fastTrack && (
						<FontAwesomeIcon
							icon={solid('bolt')}
							color={colors.primary}
							style={{ fontSize: 14, marginLeft: 8 }}
						/>
					)}
					{!!completedDate && (
						<Typography ml={0.5}>- {completedDate}</Typography>
					)}
				</Row>
			</Column>
		</SpacedRow>
	);
};

export default Header;
