import { BillingModalOption } from '@rentcheck/types';
import { createContext } from 'react';

export type BillingModalPlanVariant = 'free-trial' | 'full-plan';
export type BillingModalContextHandler = (
	plan?: BillingModalOption['plan'],
	variant?: BillingModalPlanVariant
) => void;

export const BillingOptionContext = createContext<BillingModalContextHandler>(
	() => {}
);
