import { Skeleton } from '@mui/material';

interface Props {
	loading: boolean;
}
const FiltersSkeleton = ({ loading }: Props) => {
	if (!loading) return null;

	return (
		<Skeleton
			variant="rectangular"
			width="100%"
			height={2000}
			sx={{ borderRadius: 1, backgroundColor: '#fffa' }}
			style={{ marginBottom: -2000, zIndex: 999 }}
		/>
	);
};

export default FiltersSkeleton;
