import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Chip,
	ChipTypeMap,
	Paper,
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { SubscriptionsApi } from '@rentcheck/api-frontend';
import { ChargebeeAPIInvoice } from '@rentcheck/types';
import IconButton from 'components/icon-button';
import { useInvoices } from 'hooks/billing';
import _ from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import themeTypography from 'theme/typography';
import { downloadCORSInhibitedBlob } from 'utils/helpers';

const Header = () => {
	const headerStyle = {
		...themeTypography.MuiTypography.styleOverrides.overline,
		color: 'black !important',
		fontWeight: '500 !important',
	};

	return (
		<TableHead>
			<TableRow>
				<TableCell sx={headerStyle}>Invoice</TableCell>
				<TableCell sx={headerStyle}>Invoice date</TableCell>
				<TableCell sx={headerStyle} align="right" />
			</TableRow>
		</TableHead>
	);
};

const StatusChip = ({ status }: { status: ChargebeeAPIInvoice['status'] }) => {
	const label = _.capitalize(status.replaceAll('_', ' ')).replaceAll(
		'Payment due',
		'Due'
	);

	const icons: Record<ChargebeeAPIInvoice['status'], IconDefinition> = {
		payment_due: solid('triangle-exclamation'),
		paid: solid('check'),
		not_paid: solid('circle-exclamation'),
		voided: solid('delete-right'),
		posted: solid('thumbtack'),
		pending: solid('spinner'),
	};

	const variants: Record<
		ChargebeeAPIInvoice['status'],
		ChipTypeMap['props']['variant']
	> = {
		payment_due: 'outlined',
		paid: 'filled',
		not_paid: 'outlined',
		voided: 'outlined',
		posted: 'outlined',
		pending: 'outlined',
	};

	const colors: Record<
		ChargebeeAPIInvoice['status'],
		ChipTypeMap['props']['color']
	> = {
		payment_due: 'error',
		paid: 'default',
		not_paid: 'error',
		voided: 'default',
		posted: 'default',
		pending: 'default',
	};

	return (
		<Chip
			label={label}
			color={colors[status]}
			variant={variants[status]}
			style={{ marginRight: 8 }}
			icon={<FontAwesomeIcon icon={icons[status]} />}
		/>
	);
};

const InvoiceRow = ({ invoice }: { invoice: ChargebeeAPIInvoice }) => {
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);

	const handleDownload = (invoice: ChargebeeAPIInvoice) => {
		setLoading(true);
		SubscriptionsApi.getInvoiceDownloadUrl(invoice.id)
			.then(downloadCORSInhibitedBlob)
			.catch((e) => dispatch(SnackbarActions.showError(e)))
			.finally(() => setLoading(false));
	};

	return (
		<TableRow>
			<TableCell>
				<StatusChip status={invoice.status} />

				{invoice.id}
			</TableCell>
			<TableCell>{moment(invoice.date * 1000).format('MM/DD/YYYY')}</TableCell>
			<TableCell align="right">
				<IconButton
					loading={loading}
					icon={solid('download')}
					tooltip="Download Invoice"
					onClick={() => handleDownload(invoice)}
				/>
			</TableCell>
		</TableRow>
	);
};

export default () => {
	const subscription = useTypedSelector((state) => state.subscription);

	const { invoices, loading } = useInvoices();

	if (!subscription) {
		return null;
	}

	if (loading) {
		return (
			<Skeleton
				variant="rectangular"
				height={200}
				width="100%"
				sx={{ borderRadius: 1, mb: 3, mt: 3 }}
			/>
		);
	}

	if (invoices.length === 0) {
		return null;
	}

	return (
		<TableContainer
			component={Paper}
			elevation={0}
			sx={{ border: '1px solid #0000001F', marginTop: 3 }}>
			<Table>
				<Header />
				<TableBody>
					{invoices.map((invoice) => (
						<InvoiceRow key={invoice.id} invoice={invoice} />
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
