import { Column, Row } from 'components';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import TopControls from '../image/top-controls';

export default () => {
	const { search } = useLocation();

	const containerRef = useRef<HTMLDivElement>(null);

	const [imageUri, setImageUri] = useState<string>();

	useEffect(() => {
		const searchParams = new URLSearchParams(search);
		setImageUri(searchParams.get('image_url') ?? undefined);
	}, [search]);

	if (!imageUri) {
		return null;
	}

	return (
		<Column style={{ width: '100%', height: '100%' }}>
			<TopControls imageUri={imageUri} />
			<Row style={{ width: '100%', height: 'calc(100% - 44px)' }}>
				<div
					ref={containerRef}
					style={{
						width: '100%',
						height: '100%',
						overflow: 'hidden',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					<video
						controls={true}
						height="100%"
						width={'100%'}
						style={{ background: '#000' }}
						src={imageUri}
					/>
				</div>
			</Row>
		</Column>
	);
};
