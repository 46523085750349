import { Dispatch } from '../../types/Dispatch';

export interface Payload {
	value: number | string | boolean;
}

export type ActionType = 'SET_BILLING_SETUP_DISMISSED';

export const actionTypes: { [index: string]: ActionType } = {
	SET_BILLING_SETUP_DISMISSED: 'SET_BILLING_SETUP_DISMISSED',
};

export const setBillingSetupDismissed = (value: boolean) => {
	return async (dispatch: Dispatch): Promise<void> => {
		dispatch({
			type: 'SET_BILLING_SETUP_DISMISSED',
			sessionOptionsPayload: { value },
		});
	};
};
