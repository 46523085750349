import { Select, Typography } from '@mui/material';
import { Column, Spacer } from 'components';

interface Props {
	value: string[];
	setValue: (value: string[]) => void;
}

const Photos = ({ value }: Props) => {
	const handleRenderValue = () => {
		if (!value.length) return 'Select images to attach';
		return `Selected ${value.length} image${value.length === 1 ? '' : 's'}`;
	};

	if (!value.length) {
		return null;
	}

	return (
		<Column>
			<Typography variant="h6">Attached Photos:</Typography>
			<Spacer height={4} />
			<Select
				multiple
				disabled
				variant="filled"
				value={value.length ? value : ['placeholder']}
				renderValue={handleRenderValue}
				id="select-photos"></Select>
			<Spacer height={8} />
		</Column>
	);
};

export default Photos;
