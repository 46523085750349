import {
	ApiInspectionEvent,
	InspectionEventSignatureRequestedMetadata,
} from '@rentcheck/types';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import BaseTimelineEvent from '../base-event';

interface Props {
	event: ApiInspectionEvent;
}

const SignatureRequestedTimelineEvent = ({ event }: Props) => {
	const metadata = event.metadata as InspectionEventSignatureRequestedMetadata;

	return (
		<BaseTimelineEvent
			icon={solid('file-signature')}
			title="Signature Requested"
			subtitle={`Requested by ${metadata.shared_by}`}
			timestamp={event.timestamp}
			sentTo={metadata.emails_sent}
			note={metadata.note}
		/>
	);
};

export default SignatureRequestedTimelineEvent;
