import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { TemplateSection, TemplateSectionLogic } from '@rentcheck/types';
import { DialogTitle } from 'components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';
import { v4 as uuid } from 'uuid';

import _ from 'lodash';
import LogicCard from '../logic-card/bulk';

interface Props {
	sections: TemplateSection[];
	onUpdate: (sections: TemplateSection[]) => void;
	open: boolean;
	onClose: () => void;
}

export interface BulkTemplateSectionLogic extends TemplateSectionLogic {
	sections: TemplateSection[];
}

export default ({ sections, onUpdate, open, onClose }: Props) => {
	const dispatch = useDispatch();

	const newLogicCondition: BulkTemplateSectionLogic = {
		id: uuid(),
		trigger_id: '',
		trigger_type: 'question',
		condition: 'is-any-of',
		action: 'show',
		condition_value: [],
		sections: [],
	};

	const [logicCondition, setLogicCondition] =
		useState<BulkTemplateSectionLogic>(newLogicCondition);

	useEffect(() => {
		setLogicCondition(newLogicCondition);
	}, [open]);

	const handleSave = () => {
		onClose();

		const logicWithoutExtraKeys: TemplateSectionLogic = _.omit(
			newLogicCondition,
			'sections'
		);

		const updatedSections = sections.map((s) => {
			/**
			 * If bulk update doesn't affect this section return as is
			 */
			if (!logicCondition.sections.some((lcs) => lcs.id === s.id)) {
				return s;
			}

			/**
			 * If bulk update affects this section, add the new logic condition
			 * to the section and set the id to a new uuid to ensure uniqueness
			 */
			return {
				...s,
				logic: [...s.logic, { ...logicWithoutExtraKeys, id: uuid() }],
			};
		});

		onUpdate(updatedSections);
		dispatch(SnackbarActions.showSuccess('Success! Logic saved.'));
	};

	const hasInvalidLogic =
		!logicCondition.trigger_id ||
		!logicCondition.condition_value.length ||
		!logicCondition.sections.length;

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle
				onClose={onClose}
				title="Section Logic"
				bottomSubtitle="Add logic to multiple sections at once"
				icon={regular('split')}
			/>
			<DialogContent sx={{ minHeight: '60vh', backgroundColor: '#F6F5FF' }}>
				<LogicCard
					logic={logicCondition}
					onUpdate={setLogicCondition}
					sections={sections}
				/>
			</DialogContent>
			<DialogActions>
				<Button variant="text" color="secondary" onClick={onClose}>
					Cancel
				</Button>
				<Button onClick={handleSave} disabled={hasInvalidLogic}>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};
