import { CircularProgress } from '@mui/material';
import { Column, Row } from 'components';
import { useEffect, useRef, useState } from 'react';
import { ReactPhotoSphereViewer } from 'react-photo-sphere-viewer';
import { useLocation } from 'react-router-dom';
import TopControls from '../image/top-controls';

type ReactPhotoSphereViewerRef = typeof ReactPhotoSphereViewer & {
	animate: (data: { zoom: number; speed: string }) => void;
};

const zoomStep = 25;

export default () => {
	const { search } = useLocation();

	const containerRef = useRef<HTMLDivElement>(null);
	const photoSphereRef = useRef<ReactPhotoSphereViewerRef>(null);

	const [zoom, setZoom] = useState(50);
	const [imageUri, setImageUri] = useState<string>();

	useEffect(() => {
		const searchParams = new URLSearchParams(search);
		setImageUri(searchParams.get('image_url') ?? undefined);
	}, [search]);

	const handleZoomIn = () => {
		photoSphereRef.current?.animate({
			zoom: zoom + zoomStep,
			speed: '15rpm',
		});
	};

	const handleZoomOut = () => {
		photoSphereRef.current?.animate({
			zoom: zoom - zoomStep,
			speed: '10rpm',
		});
	};

	const onZoomChange = (data: { zoomLevel: number }) => {
		setZoom(data.zoomLevel);
	};

	if (!imageUri) {
		return null;
	}

	return (
		<Column style={{ width: '100%', height: '100%' }}>
			<TopControls
				imageUri={imageUri}
				handleZoomIn={handleZoomIn}
				handleZoomOut={handleZoomOut}
			/>
			<Row style={{ width: '100%', height: 'calc(100% - 44px)' }}>
				<div
					ref={containerRef}
					style={{
						width: '100%',
						height: '100%',
						overflow: 'hidden',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					<CircularProgress style={{ position: 'absolute' }} />
					<ReactPhotoSphereViewer
						key={imageUri}
						ref={photoSphereRef}
						src={imageUri}
						height={'100vh'}
						width={'100%'}
						container={''}
						onZoomChange={onZoomChange}
					/>
				</div>
			</Row>
		</Column>
	);
};
