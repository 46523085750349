import { useMediaQuery, useTheme } from '@mui/material';
import { Column } from 'components/layout/Column';
import { Row } from 'components/layout/Row';
import Spacer from 'components/Spacer';
import _ from 'lodash';
import { ReactNode, ReactNodeArray } from 'react';

interface Props {
	children: ReactNodeArray | ReactNode;
	columns?: number;
	marginBottom?: number;
	onSubmit: () => void;
	fullWidth?: boolean;
}

export default ({
	children,
	onSubmit,
	columns = 2,
	marginBottom = 24,
	fullWidth = true,
}: Props) => {
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down('md'));

	const multiple = !!(children as ReactNodeArray).map;
	const chunks = _.chunk(
		multiple ? (children as ReactNodeArray) : [children as ReactNode],
		mobile ? 1 : columns
	);

	return (
		<form
			onSubmit={() => {}}
			style={{ width: fullWidth || mobile ? '100%' : '60%' }}>
			<Column>
				{chunks.map((childrenChunk) => (
					<Row style={{ marginBottom }}>
						{childrenChunk.map((children, index) => (
							<>
								{index !== 0 && <Spacer width={2} />}
								{children}
								{index !== childrenChunk.length - 1 && <Spacer width={2} />}
							</>
						))}
					</Row>
				))}
			</Column>
			<input
				style={{ display: 'none' }}
				type="submit"
				onClick={(e) => {
					e.preventDefault();
					onSubmit();
				}}
			/>
		</form>
	);
};
