import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import { MaintenanceFlag } from '@rentcheck/types';
import { FeaturesApi } from 'api';
import { Column, Row, SpacedRow, Spacer } from 'components';
import IconButton from 'components/icon-button';
import { useCreateWorkOrderModalData } from 'hooks/modals';
import { useDispatch } from 'react-redux';
import { ModalFlowActions } from 'store/actions';
import { featureDisplayName, numberAndPluralizeWord } from 'utils/helpers';

interface Props {
	value: MaintenanceFlag[];
}

export default ({ value }: Props) => {
	const dispatch = useDispatch();

	const modalData = useCreateWorkOrderModalData('AppFolio');
	const { inspection, flags } = modalData?.metadata ?? {};

	const handleViewFlag = async (flag: MaintenanceFlag) => {
		const feature = await FeaturesApi.getById(flag.feature_id);

		if (!feature) {
			return;
		}

		dispatch(
			ModalFlowActions.showFlagFeatureModal({
				inspection,
				feature,
				flag,
				images: flag.image_urls,
				disableDelete: true,
				disableCreateWorkOrder: true,
			})
		);
	};

	const handleRemoveFlag = (flag: MaintenanceFlag) => {
		dispatch(
			ModalFlowActions.removeFlagFromModals({
				flag,
			})
		);
	};

	return (
		<>
			<Column>
				<Typography variant="overline">Flags</Typography>
			</Column>

			<Spacer height={2} />

			{value.map((flag) => {
				return (
					<SpacedRow style={{ marginBottom: 12 }}>
						<Row>
							<Spacer width={2} />
							<FontAwesomeIcon icon={solid('flag')} color="#2D3CE6" />
							<Spacer width={4} />

							<Column>
								<Typography variant="subtitle1">
									{featureDisplayName(flag)}
								</Typography>
								<Typography variant="body2">
									{numberAndPluralizeWord(
										flag.image_urls?.length ?? 0,
										'image'
									)}
								</Typography>
							</Column>
						</Row>
						<Row>
							<IconButton
								icon={solid('eye')}
								onClick={() => handleViewFlag(flag)}
							/>
							{flags.length > 1 && (
								<IconButton
									icon={solid('times')}
									onClick={() => handleRemoveFlag(flag)}
								/>
							)}
						</Row>
					</SpacedRow>
				);
			})}

			<Spacer height={1} />
		</>
	);
};
