import { WorkOrder } from '@rentcheck/api-frontend';
import _ from 'lodash';

export interface State {
	data: WorkOrder[];
	total: number;
}

const initialState: State = {
	data: [],
	total: 0,
};

type Action = {
	type: string;
	value?: number;
	workOrder?: WorkOrder;
	workOrders?: WorkOrder[];
};

const reducer = (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case 'SIGNOUT_SUCCESS':
		case 'CREATE_WORK_ORDER':
		case 'IMPERSONATE':
			return initialState;

		case 'FETCHED_INITIAL_WORK_ORDERS':
			if (!action.workOrders) return state;
			return {
				...state,
				data: action.workOrders,
			};

		case 'FETCHED_WORK_ORDERS_PAGE':
			if (!action.workOrders) return state;
			return {
				...state,
				data: _.uniqBy([...state.data, ...action.workOrders], (wo) => wo.id),
			};

		case 'SET_TOTAL_WORK_ORDERS':
			if (!action.value) return state;
			return {
				...state,
				total: action.value,
			};

		default:
			return state;
	}
};

export default reducer;
