import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import FormTextField from 'components/form-text-field';
import _ from 'lodash';

interface Props {
	value: string;
	setValue: (value: string) => void;
	error: string;
}

const Cost = ({ value, setValue, error }: Props) => {
	const removeExtraPeriods = (input: string) => {
		const components = input.split('.');
		return (
			components.shift() + (components.length ? '.' + components.join('') : '')
		);
	};

	const removeInvalidCharacters = (input: string) => {
		return input.replaceAll(/[^\d\\.]/g, '');
	};

	const limitMaxValue = (input: string) => {
		const components = input.split('.');

		return components
			.map((c) => _.truncate(c, { length: 10, omission: '' }))
			.join('.');
	};

	const removeExtraDecimals = (input: string) => {
		const components = input.split('.');

		if (components.length === 2 && components[1].length >= 2) {
			return `${parseFloat(input).toFixed(2)}`;
		}

		return input;
	};

	const sanitizeInput = (input: string) => {
		setValue(
			removeExtraDecimals(
				limitMaxValue(removeInvalidCharacters(removeExtraPeriods(input)))
			)
		);
	};

	return (
		<FormTextField
			title="Cost"
			value={value}
			onChange={(e) => sanitizeInput(e.target.value)}
			error={!!error}
			icon={regular('dollar-sign')}
		/>
	);
};

export default Cost;
