import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { DialogTitle, Spacer } from 'components';

import { LoadingButton } from '@mui/lab';
import {
	Dialog,
	DialogActions,
	DialogContent,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { ActiveProfileActions, SnackbarActions } from 'store/actions';
import { Dispatch } from 'types';
import { Analytics } from 'utils';
import { isInvalidPassword } from 'utils/helpers';

interface Props {
	open: boolean;
}

const TemporaryPasswordModal = ({ open }: Props) => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
	const dispatch: Dispatch = useDispatch();

	const [loading, setLoading] = useState(false);

	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');

	const handleSave = () => {
		if (password.length < 8) {
			setError('Password must be at least 8 characters');
			return;
		}

		if (isInvalidPassword(password)) {
			setError(
				"Make sure your password doesn't consist entirely of repeated or sequential characters"
			);
			return;
		}
		setError('');
		setLoading(true);

		dispatch(
			ActiveProfileActions.update({
				name: `${firstName} ${lastName}`,
				password,
			})
		)
			.then(() => Analytics.trackEvent('onboarding set name and password'))
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	return (
		<Dialog open={open} onClose={() => {}} fullScreen={fullScreen}>
			<DialogTitle
				title="Welcome to RentCheck!"
				bottomSubtitle="To get started using RentCheck, please enter your name and create a password."
			/>
			<DialogContent>
				<Typography>First Name</Typography>
				<Spacer height={1} />
				<TextField
					id="first-name"
					fullWidth
					value={firstName}
					onChange={(e) => setFirstName(e.target.value)}
				/>

				<Spacer height={5} />
				<Typography>Last Name</Typography>
				<Spacer height={1} />
				<TextField
					id="last-name"
					fullWidth
					value={lastName}
					onChange={(e) => setLastName(e.target.value)}
				/>

				<Spacer height={5} />
				<Typography>Password</Typography>
				<Spacer height={1} />
				<TextField
					fullWidth
					id="password"
					type="password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					error={!!error}
					helperText={error}
				/>
				<Spacer height={8} />
			</DialogContent>
			<DialogActions>
				<div />
				<LoadingButton
					variant="contained"
					onClick={handleSave}
					disabled={!firstName || !lastName || !password}
					loading={loading}>
					Save & Continue
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export default TemporaryPasswordModal;
