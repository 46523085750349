import { Typography } from '@mui/material';
import { Column } from 'components';
import React from 'react';
import styled from 'styled-components';
import * as theme from 'theme';

interface Props {
	label: string;
	subtitle?: string;
	isSelected: boolean;
	onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const FilterTitleOption = ({ label, subtitle, isSelected, onClick }: Props) => {
	const titleColor = isSelected ? theme.colors.black : theme.colors.gray;

	return (
		<OptionItemWrapper isSelected={isSelected} onClick={onClick}>
			<Column>
				<Typography fontWeight="500" color={titleColor}>
					{label}
				</Typography>
				<Typography variant="caption">{subtitle}</Typography>
			</Column>
		</OptionItemWrapper>
	);
};

const OptionItemWrapper = styled.button<{ isSelected: boolean }>`
	display: flex;
	justify-content: space-between;
	background-color: ${theme.colors.transparent};
	border-color: ${({ isSelected }) =>
		isSelected ? theme.colors.primary : theme.colors.transparent};
	border-width: 0;
	border-left-width: 3px;
	border-style: solid;
	padding: ${theme.spacingPx(2)};
	text-align: left;
	transition: all 0.2s ease-in-out;
	width: 100%;

	&:hover {
		background-color: ${theme.colors.lightGray};
		border-color: ${theme.colors.primary};
	}
`;

export default FilterTitleOption;
