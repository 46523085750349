import { Divider, Typography } from '@mui/material';

import { Feature } from '@rentcheck/types';
import { Spacer } from 'components';

interface Props {
	feature: Feature;
}

export default ({ feature }: Props) => {
	const note = feature.notes?.trim();
	const reviewNote = feature.review_notes?.trim();

	return (
		<>
			<Typography variant="overline">Notes</Typography>
			<Spacer height={2} />

			{!note && !reviewNote && (
				<Typography variant="subtitle2" style={{ wordBreak: 'break-word' }}>
					No Notes
				</Typography>
			)}

			{!!note && (
				<Typography variant="subtitle2" style={{ wordBreak: 'break-word' }}>
					{note}
				</Typography>
			)}

			{!!note && !!reviewNote && <Divider sx={{ mt: 2, mb: 2 }} />}

			{!!reviewNote && (
				<>
					<Typography variant="overline" mb={1}>
						Note from {feature.reviewed_by?.name ?? 'the reviewer'}
					</Typography>
					<Typography variant="subtitle2" style={{ wordBreak: 'break-word' }}>
						{reviewNote}
					</Typography>
				</>
			)}
		</>
	);
};
