import { Autocomplete, Switch, TextField, Typography } from '@mui/material';
import {
	LatchelApi,
	LatchelProperty,
	LatchelVendor,
} from '@rentcheck/api-frontend';
import * as Sentry from '@sentry/react';
import { SpacedRow, Spacer } from 'components';
import { useCreateWorkOrderModalData } from 'hooks/modals';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';

interface Props {
	selectedFlagIds: string[];
	property: LatchelProperty;
	skill: string | null;
	value: LatchelVendor | null;
	setValue: (value: LatchelVendor | null) => void;
	useDefaultDispatch: boolean;
	setUseDefaultDispatch: (value: boolean) => void;
}

const Dispatch = ({
	selectedFlagIds,
	property,
	skill,
	value,
	setValue,
	useDefaultDispatch,
	setUseDefaultDispatch,
}: Props) => {
	const dispatch = useDispatch();

	const modalData = useCreateWorkOrderModalData('Latchel');
	const { inspection, flags } = modalData?.metadata ?? {};

	const [vendors, setVendors] = useState<LatchelVendor[]>([]);

	useEffect(() => {
		setValue(null);
	}, [useDefaultDispatch]);

	useEffect(() => {
		if (!flags) {
			return;
		}

		const selectedFlags = flags.filter((f) => selectedFlagIds.includes(f.id));
		const effectiveSkill = skill || _.first(selectedFlags)?.skill;

		if (!effectiveSkill || !inspection?.team?.id) {
			return;
		}

		LatchelApi.vendors
			.getPrioritized(inspection.team.id, property.property_id, effectiveSkill)
			.then((data) => setVendors(data))
			.catch((e) => {
				Sentry.captureException(e);
				dispatch(SnackbarActions.showError());
			});
	}, [flags, skill]);

	return (
		<>
			<SpacedRow>
				<Typography fontWeight={600}>Use default dispatch settings</Typography>
				<Switch
					id="switch-default_dispatch"
					size="small"
					checked={useDefaultDispatch}
					onChange={(e) => setUseDefaultDispatch(e.target.checked)}
				/>
			</SpacedRow>
			{!useDefaultDispatch && (
				<>
					<Spacer height={4} />
					<Typography>
						Choose a vendor from your preferred vendor list
					</Typography>
					<Spacer height={2} />
					<Autocomplete
						disablePortal
						options={vendors}
						getOptionLabel={(option) => option.name}
						value={value}
						onChange={(_, newValue) => setValue(newValue)}
						id="textinput-vendor"
						noOptionsText="Loading..."
						ListboxProps={{ style: { maxHeight: 210 } }}
						renderInput={(params) => (
							<TextField
								variant="filled"
								placeholder="Select one"
								{...params}
							/>
						)}
					/>
				</>
			)}
		</>
	);
};

export default Dispatch;
