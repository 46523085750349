import { Alert, AlertColor, CircularProgress, Snackbar } from '@mui/material';
import { useDispatch } from 'react-redux';

import { useTypedSelector } from 'store/reducers/rootReducer';

import { SnackbarState } from 'store/reducers/snackbar';
import { SnackbarActions } from '../../store/actions';

interface Props {
	item: SnackbarState;
}

const SnackbarItem = ({ item }: Props) => {
	const dispatch = useDispatch();

	const onClose = () => {
		dispatch(SnackbarActions.hideSnackbar(item.id));
	};

	const titleLines = item.title.split('\n');

	if (item.type === 'progress') {
		return (
			<Alert
				icon={<CircularProgress size={20} style={{ color: 'rgb(1,67,97)' }} />}
				sx={{ marginTop: 1 }}
				severity="info"
				onClose={onClose}>
				{titleLines.map((t) => (
					<div>{t}</div>
				))}
			</Alert>
		);
	}

	if (item.type === 'error') {
		return (
			<div>
				{titleLines.map((t) => (
					<Alert
						key={t}
						sx={{ marginTop: 1 }}
						severity={item.type as AlertColor}
						onClose={onClose}>
						{t}
					</Alert>
				))}
			</div>
		);
	}

	return (
		<Alert
			sx={{ marginTop: 1 }}
			severity={item.type as AlertColor}
			onClose={onClose}>
			{titleLines.map((t) => (
				<div>{t}</div>
			))}
		</Alert>
	);
};

const SnackbarController = () => {
	const snackbars = useTypedSelector((state) => state.snackbar);

	return (
		<Snackbar open autoHideDuration={null}>
			<div>
				{snackbars.map((item) => (
					<SnackbarItem item={item} key={item.id} />
				))}
			</div>
		</Snackbar>
	);
};

export default SnackbarController;
