import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button } from 'components/button';
import { IntercomAPI } from 'react-intercom';

export default () => {
	const handleChatToUpgrade = () => {
		IntercomAPI('showNewMessage');
	};

	return (
		<Button
			variant="outlined"
			onClick={handleChatToUpgrade}
			startIcon={<FontAwesomeIcon icon={solid('message-lines')} />}>
			Chat to Upgrade
		</Button>
	);
};
