import {
	Typography,
	TextField,
	InputAdornment,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import { colors } from 'theme';

interface Props {
	inspectionLabel: string;
	setInspectionLabel: (value: string) => void;
}

const MoreOptions = ({ inspectionLabel, setInspectionLabel }: Props) => {
	return (
		<Accordion style={{ marginTop: 14, boxShadow: 'none' }}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography variant="overline">More Options</Typography>
			</AccordionSummary>

			<AccordionDetails>
				<TextField
					fullWidth
					variant="filled"
					type="string"
					label="Label this inspection"
					value={inspectionLabel}
					onChange={(e) => setInspectionLabel(e.target.value)}
					helperText="Optional"
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<FontAwesomeIcon
									icon={regular('file-pen')}
									color={colors.black}
								/>
							</InputAdornment>
						),
					}}
				/>
			</AccordionDetails>
		</Accordion>
	);
};

export default MoreOptions;
