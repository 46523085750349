import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { ApiInspectionWithTemplate } from '@rentcheck/types';
import { FeatureWithMetadata } from 'store/reducers/features';
import styled from 'styled-components';

import { Rules } from '@rentcheck/biz';
import Actions from './actions';
import Media from './media';
import Rating from './rating';

interface Props {
	feature: FeatureWithMetadata;
	inspection: ApiInspectionWithTemplate;
	onClick: () => void;
}

export default ({ feature, inspection, onClick }: Props) => {
	return (
		<StyledTableRow
			id={`row-${feature.id}`}
			sx={{
				height: feature.not_applicable ? undefined : 113,
				'&:last-child td, &:last-child th': { border: 0 },
				cursor: 'pointer',
			}}>
			<TableCell id="cell-room" onClick={onClick}>
				{feature.edited_by_reviewer ? '*' : ''}
				{feature.section.name_with_ordinal}
			</TableCell>

			<TableCell id="cell-feature" onClick={onClick}>
				<Typography color="#445567">{feature.name}</Typography>
			</TableCell>

			<TableCell id="cell-photos" onClick={onClick}>
				<Media feature={feature} />
			</TableCell>

			<TableCell sx={{ width: 350 }} id="cell-notes" onClick={onClick}>
				<Typography
					sx={{
						display: '-webkit-box',
						overflow: 'hidden',
						WebkitBoxOrient: 'vertical',
						WebkitLineClamp: 3,
						wordBreak: 'break-word',
					}}
					color="#445567">
					{feature.notes || '-'}
				</Typography>
			</TableCell>

			<TableCell id="cell-rating" onClick={onClick}>
				<Rating feature={feature} />
			</TableCell>

			{Rules.Inspections.canPerformFeatureActions(inspection) && (
				<TableCell
					id="cell-flag"
					align="right"
					sx={{ padding: 0, whiteSpace: 'nowrap' }}>
					<Actions feature={feature} inspection={inspection} />
				</TableCell>
			)}

			<TableCell
				id="cell-arrow"
				align="left"
				width={20}
				sx={{ paddingLeft: 0 }}
				onClick={onClick}>
				<Tooltip title="View Details">
					<div>
						{/* <div> is needed to trigger the tooltip */}
						<FontAwesomeIcon
							icon={regular('chevron-right')}
							color="#61768F"
							size="lg"
						/>
					</div>
				</Tooltip>
			</TableCell>
		</StyledTableRow>
	);
};

const StyledTableRow = styled(TableRow)`
	td:last-child {
		opacity: 0;
		transition: 0.3s opacity ease-in-out;
	}

	td:nth-last-child(2) {
		padding-right: 12px;
		padding-left: 12px;
		transition: 0.3s padding ease-in-out;
	}

	:hover {
		td:last-child {
			opacity: 1;
		}

		td:nth-last-child(2) {
			padding-right: 20px;
			padding-left: 4px;
		}
	}
`;
