import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, Typography } from '@mui/material';
import { ReactNode, ReactNodeArray } from 'react';

import { Column, Row, SpacedRow, Spacer } from 'components';
import { colors } from 'theme';

interface SettingProps {
	label: string;
	value: string;
	children?: ReactNode | ReactNodeArray;
}

export default ({ label, value, children }: SettingProps) => {
	return (
		<Column>
			<SpacedRow style={{ alignItems: 'flex-start', marginBottom: 16 }}>
				<Row style={{ marginTop: 16 }}>
					<FontAwesomeIcon icon={regular('gear')} color={colors.gray} />
					<Spacer width={3} />
					<Typography variant="subtitle1">{label}</Typography>
				</Row>

				<Column
					style={{
						width: '45%',
						backgroundColor: '#232E3A0D',
						padding: 8,
						borderRadius: 4,
						minHeight: 37,
						justifyContent: 'center',
					}}>
					{!!value && <Typography variant="subtitle1">{value}</Typography>}
					{children}
				</Column>
			</SpacedRow>
			<Divider sx={{ marginBottom: 2 }} />
		</Column>
	);
};
