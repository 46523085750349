import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Checkbox, FormControlLabel } from '@mui/material';
import {
	FilterChip,
	FilterChipContent,
} from 'components/table/filter-button-v2';
import _ from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FilterChipRef } from '..';

interface Props<T extends string> {
	title: string;
	icon: IconDefinition;
	value: T[] | undefined;
	setValue: (value: T[] | undefined) => void;
	options: T[];
}

const MultiSelectFilterChip = <T extends string>({
	title,
	icon,
	value,
	setValue,
	options,
}: Props<T>) => {
	const ref = useRef<FilterChipRef>(null);

	const [checkboxValues, setCheckboxValues] = useState<T[]>([]);

	useEffect(() => {
		initializeState(value);
	}, [value]);

	const initializeState = (value: T[] | undefined) => {
		setCheckboxValues(value ?? []);
	};

	const handleDeleted = () => {
		setValue(undefined);
	};

	const handleDismissed = () => {
		initializeState(value);

		if (!value?.length) {
			handleDeleted();
		}
	};

	const label = useMemo(() => {
		if (!value?.length) {
			return undefined;
		}

		if (value.length === 1) {
			return `is ${value[0]}`;
		}

		return `is any of these (${value.length})`;
	}, [value]);

	const handleToggleOption = (option: T) => {
		setCheckboxValues(_.xor(checkboxValues, [option]));
	};

	const handleApply = () => {
		ref.current?.close();

		return setValue(checkboxValues);
	};

	if (!value) {
		return null;
	}

	return (
		<FilterChip
			ref={ref}
			title={title}
			label={label}
			icon={icon}
			onDelete={handleDeleted}
			onDismiss={handleDismissed}>
			<FilterChipContent title={title} onApply={handleApply}>
				{options.map((option) => (
					<FormControlLabel
						key={option}
						label={option}
						control={
							<Checkbox
								defaultChecked
								size="small"
								checked={checkboxValues.includes(option)}
								onChange={() => handleToggleOption(option)}
							/>
						}
					/>
				))}
			</FilterChipContent>
		</FilterChip>
	);
};

export default MultiSelectFilterChip;
