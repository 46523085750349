import { Row, SpacedRow, Spacer } from 'components';
import QueryTotals from 'components/lists/query-totals';
import ExportButton from 'components/table/export-button';
import SearchBar from 'components/table/search';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { MaintenanceActions, SnackbarActions } from 'store/actions';
import { RootState, useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';

import Skeleton from '../common/skeleton';
import FiltersButton from './filters';
import Table from './table';

const ReportsList = () => {
	const dispatch: Dispatch = useDispatch();

	const filters = useTypedSelector(
		(state) => state.maintenance.filters.reports
	);

	const [initialLoading, setInitialLoading] = useState(true);

	useEffect(() => {
		dispatch(MaintenanceActions.reports.getAll(0, filters as any))
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => {
				setInitialLoading(false);
			});
	}, []);

	const searchExtractor = (state: RootState) => {
		return state.maintenance.filters.reports.search;
	};

	const totalExtractor = (state: RootState) => {
		return state.maintenance.reports.total;
	};

	if (initialLoading) {
		return <Skeleton showExport />;
	}

	return (
		<>
			<SpacedRow>
				<QueryTotals totalExtractor={totalExtractor} />
				<Row>
					<SearchBar stateExtractor={searchExtractor} type="reports" />
					<FiltersButton />
					<ExportButton variant="maintenance reports" />
				</Row>
			</SpacedRow>
			<Spacer height={6} />
			<Table />
		</>
	);
};

export default ReportsList;
