import { ChangeEvent, useEffect, useMemo } from 'react';
import 'react-phone-input-2/lib/style.css';
import _ from 'lodash';
import { MenuItem, SelectChangeEvent, Typography } from '@mui/material';

import { TemplateQuestion, TemplateFeature } from '@rentcheck/types';
import { Row } from 'components';

import FormTextField from 'components/form-text-field';

interface Props {
	question?: TemplateQuestion;
	features: TemplateFeature[];
	allFeatures: TemplateFeature[];
	onUpdateFeatures: (features: TemplateFeature[]) => void;
}

export default ({
	question,
	features,
	allFeatures,
	onUpdateFeatures,
}: Props) => {
	useEffect(() => {
		onUpdateFeatures([]);
	}, [question]);

	const handleUpdateFeatures = (e: ChangeEvent<HTMLInputElement>) => {
		const selectEvent = e as SelectChangeEvent<string[]>;
		const newValue = selectEvent.target.value;

		const selectedFeatures = allFeatures.filter((f) => newValue.includes(f.id));
		onUpdateFeatures(selectedFeatures);
	};

	const sortedFeatures = useMemo(
		() => _.sortBy(allFeatures, 'name'),
		[allFeatures]
	);

	return (
		<Row>
			<Typography
				variant="subtitle2"
				mb={3.5}
				sx={{ maxWidth: 180, minWidth: 180 }}>
				Show features:
			</Typography>
			<Row style={{ flex: 1 }}>
				<FormTextField
					select
					required
					fullWidth
					multiple
					disabled={!question}
					inputSx={{ mt: 0, mb: 1 }}
					helperText="Required"
					placeholder="Select Features"
					value={
						features.length === 0 ? ['placeholder'] : features.map((f) => f.id)
					}
					onChange={handleUpdateFeatures}
					InputProps={{
						sx: {
							'.MuiSelect-select': {
								flex: 1,
								width: '10px',
							},
						},
					}}>
					{sortedFeatures.map((f) => {
						const questionsForFeature = f.questions.map((q) => q);

						const disabledForContainingQuestion = questionsForFeature.some(
							(q) => q.id === question?.id
						);

						const disabledForUsingQuestionForLogic = f.logic.some(
							(l) =>
								l.trigger_id === question?.id && l.trigger_type === 'question'
						);

						const disabled =
							disabledForContainingQuestion || disabledForUsingQuestionForLogic;

						const tooltipText = disabledForContainingQuestion
							? ' (Selected question belongs to this feature)'
							: disabledForUsingQuestionForLogic
								? ' (Selected question is already used in logic for this feature)'
								: '';

						return (
							<MenuItem key={f.id} value={f.id} disabled={disabled}>
								{f.name}
								{tooltipText}
							</MenuItem>
						);
					})}
				</FormTextField>
			</Row>
		</Row>
	);
};
