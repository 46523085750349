import { Typography } from '@mui/material';

interface RowProps {
	feature: string;
}
const RejectedFeatureRow = ({ feature }: RowProps) => {
	if (!feature) {
		return null;
	}

	return (
		<Typography variant="body2" color="#4D4D4D">
			{feature}
		</Typography>
	);
};

interface Props {
	featuresList?: string[];
}

const RejectedFeaturesList = ({ featuresList }: Props) => {
	if (!featuresList?.length) {
		return null;
	}

	return (
		<>
			{featuresList.map((feature: string) => (
				<RejectedFeatureRow feature={feature} />
			))}
		</>
	);
};

export default RejectedFeaturesList;
