import { WorkOrder } from '@rentcheck/api-frontend';

export type State = WorkOrder | null;

const initialState: State = null;

type Action = {
	type: string;
	workOrder?: WorkOrder;
};

const reducer = (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case 'SIGNOUT_SUCCESS':
		case 'IMPERSONATE':
			return initialState;

		case 'FETCHED_WORK_ORDER':
			if (!action.workOrder) return state;
			return action.workOrder;

		default:
			return state;
	}
};

export default reducer;
