import { FileRequestDocumentType } from '@rentcheck/types';

export type State = FileRequest[];

export interface FileRequest {
	id: string;
	document_id: string;
	document_type: FileRequestDocumentType;
	type: 'download' | 'copy';
	title: string;
	subtitle: string;
	location?: string;
	error?: string;
}

type Action = {
	type:
		| 'ADD_FILE_REQUEST'
		| 'REMOVE_FILE_REQUEST'
		| 'UPDATE_FILE_REQUEST'
		| 'CLEAR_FILE_REQUESTS'
		| 'IMPERSONATE'
		| 'SIGNOUT_SUCCESS';
	value: FileRequest | ({ id: string } | Partial<FileRequest>);
};

export default (state: State = [], action: Action) => {
	switch (action.type) {
		case 'SIGNOUT_SUCCESS':
		case 'IMPERSONATE':
		case 'CLEAR_FILE_REQUESTS':
			return [];
		case 'ADD_FILE_REQUEST':
			return [action.value, ...state];
		case 'UPDATE_FILE_REQUEST':
			return state.map((fileRequest) => {
				if (fileRequest.id === action.value.id) {
					return { ...fileRequest, ...action.value };
				}

				return fileRequest;
			});
		case 'REMOVE_FILE_REQUEST':
			return state.filter((fileRequest) => fileRequest.id !== action.value.id);
		default:
			return state;
	}
};
