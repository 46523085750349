import { CardContent, Paper, Table, TableContainer } from '@mui/material';

import AwaitingReview from './awaiting-review';
import AlmostDue from './almost-due';
import Overdue from './overdue';
import Undelivered from './undelivered';
import RevisionRequested from './revision-requested';
import AwaitingSignature from './awaiting-signature';
import UnitsAtRisk from './units-at-risk';
import Unassigned from './unassigned';

const Content = () => {
	return (
		<CardContent sx={{ backgroundColor: '#EFF1F4' }}>
			<TableContainer component={Paper}>
				<Table>
					<AwaitingReview />
					<AlmostDue />
					<Overdue />
					<Unassigned />
					<Undelivered />
					<RevisionRequested />
					<AwaitingSignature />
					<UnitsAtRisk />
				</Table>
			</TableContainer>
		</CardContent>
	);
};

export default Content;
