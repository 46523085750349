import { useMediaQuery, useTheme } from '@mui/material';

import { landing } from 'assets';

import Authentication from '../authentication';
import Container from '../container';

const ApmLanding = () => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Container
			fullScreen={fullScreen}
			webLogo={landing.apm.logo}
			mobileLogo={landing.apm.logoWhite}>
			<Authentication planId="apm-revshare" />
		</Container>
	);
};

export default ApmLanding;
