import { useTypedSelector } from 'store/reducers/rootReducer';
import SetupBilling from './setup-billing';
import TemporaryPasswordModal from './temporary-password';
import UserNameModal from './user-name';

const ModalBlockers = () => {
	const profile = useTypedSelector((state) => state.authenticatedUser);

	const temporaryPasswordModalOpen = !!profile?.temporary_password;
	const userNameModalOpen =
		!temporaryPasswordModalOpen && !!profile && !profile.name;
	const billingModalOpen =
		!temporaryPasswordModalOpen &&
		!userNameModalOpen &&
		profile?.prospect_data?.payment_info_provided === false;

	return (
		<>
			<TemporaryPasswordModal open={temporaryPasswordModalOpen} />
			<UserNameModal open={userNameModalOpen} />
			<SetupBilling open={billingModalOpen} />
		</>
	);
};

export default ModalBlockers;
