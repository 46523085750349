import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DatePicker } from '@mui/lab';
import {
	Divider,
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { Column, Spacer } from 'components';
import {
	RecurringOption,
	recurringOptions,
} from 'components/inspections/create-steps/recurrence';
import moment from 'moment';
import { useEffect } from 'react';

import { ApiInspectionTemplateDigest } from '@rentcheck/types';
import RowButton from '../common/row-button';

interface Props {
	value?: Date;
	setValue: (value: Date | undefined) => void;
	recurrenceValue?: RecurringOption;
	setRecurrenceValue: (recurrenceValue: RecurringOption | undefined) => void;
	recurrenceEndDateValue?: Date;
	setRecurrenceEndDateValue: (value: Date | undefined) => void;
	disabled?: boolean;
	template?: ApiInspectionTemplateDigest;
}

const canSetRecurrence = (dueDate?: Date) => !!dueDate;

const DueDate = ({
	disabled,
	value,
	setValue,
	template,
	recurrenceValue,
	setRecurrenceValue,
	recurrenceEndDateValue,
	setRecurrenceEndDateValue,
}: Props) => {
	const theme = useTheme();
	const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));

	useEffect(() => {
		if (!canSetRecurrence(value)) {
			setRecurrenceValue(undefined);
			setRecurrenceEndDateValue(undefined);
		}
	}, [template]);

	useEffect(() => {
		if (!value) {
			return;
		}

		if (!recurrenceValue || recurrenceValue === 'Does not repeat') {
			setRecurrenceEndDateValue(undefined);
			return;
		}

		if (recurrenceValue === 'Repeat daily') {
			setRecurrenceEndDateValue(moment(value).add(1, 'M').toDate());
			return;
		}

		setRecurrenceEndDateValue(moment(value).add(2, 'y').toDate());
	}, [recurrenceValue, value]);

	if (disabled) {
		return (
			<>
				<RowButton
					disabled={disabled}
					halfWidth={!mobileScreen}
					title="Select Due Date"
					icon={regular('calendar-day')}
				/>
				<Divider />
			</>
		);
	}

	const recurrenceMaxDate =
		recurrenceValue === 'Repeat daily'
			? moment(value).add(1, 'M').toDate()
			: moment(value).add(2, 'y').toDate();

	return (
		<>
			<Column style={{ width: mobileScreen ? '100%' : '60%' }}>
				<Spacer height={4.5} />

				<DatePicker
					inputFormat="MM/dd/yy"
					disablePast={true}
					value={value}
					mask="__/__/__"
					renderInput={(props) => (
						<TextField
							{...props}
							id="input-due-date"
							variant="filled"
							label="Due date"
						/>
					)}
					onChange={(date) => {
						if (!date) setValue(undefined);
						setValue(date as Date);
					}}
				/>

				{canSetRecurrence(value) && (
					<FormControl variant="filled" sx={{ marginTop: 2 }}>
						<InputLabel>Set recurrence</InputLabel>
						<Select
							fullWidth={false}
							variant="filled"
							value={recurrenceValue}
							onChange={(e) =>
								setRecurrenceValue(e.target.value as RecurringOption)
							}>
							{recurringOptions.map((o) => (
								<MenuItem value={o}>{o}</MenuItem>
							))}
						</Select>
						<FormHelperText>Optional</FormHelperText>
					</FormControl>
				)}

				{!!recurrenceValue && recurrenceValue !== 'Does not repeat' && (
					<>
						<Spacer height={2} />
						<DatePicker
							maxDate={recurrenceMaxDate}
							inputFormat="MM/dd/yy"
							disablePast={true}
							value={recurrenceEndDateValue}
							mask="__/__/__"
							renderInput={(props) => (
								<TextField
									{...props}
									variant="filled"
									label="Recurrence end date"
								/>
							)}
							onChange={(date) => {
								date && setRecurrenceEndDateValue(date as Date);
							}}
						/>
					</>
				)}

				<Spacer height={4.5} />
			</Column>
			<Divider />
		</>
	);
};

export default DueDate;
