import { Button } from '@mui/material';
import { ApiTeam, TeamUserInfo } from '@rentcheck/types';
import { useDispatch } from 'react-redux';
import { ModalFlowActions, SnackbarActions, TeamActions } from 'store/actions';
import { Dispatch } from 'types';

interface Props {
	team: ApiTeam;
	user: TeamUserInfo;
}

const RemoveTeammateButton = ({ team, user }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const handleRemoveTeammate = (user: TeamUserInfo) => {
		dispatch(
			ModalFlowActions.showConfirmationModal({
				title: 'Are you sure?',
				body1: [
					`Are you sure you want to remove ${user.user_name} from the ${team.internal_label} team on RentCheck?`,
				],
				body2: [],
				buttons: [
					ModalFlowActions.cancelButton(dispatch),
					{
						title: 'Remove',
						color: 'error',
						variant: 'contained',
						onClick: () => {
							removeTeammate(user.id);
							dispatch(ModalFlowActions.closeConfirmationModal());
						},
					},
				],
			})
		);
	};

	const removeTeammate = (teammateId: string) => {
		dispatch(TeamActions.removeTeammate(team.id, teammateId))
			.then(() => {
				dispatch(SnackbarActions.showSuccess('User successfully removed.'));
			})
			.catch((e) => dispatch(SnackbarActions.showError(e.message)));
	};

	return (
		<Button
			color="error"
			variant="text"
			onClick={() => handleRemoveTeammate(user)}>
			Remove
		</Button>
	);
};

export default RemoveTeammateButton;
