import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import FormTextField from 'components/form-text-field';
import { useFlagFeatureModalData } from 'hooks/modals';
import { useProperty } from 'hooks/properties';
import { useMemo } from 'react';

interface Props {
	value: string;
	setValue: (value: string) => void;
	error: string;
}

const Notes = ({ value, setValue, error }: Props) => {
	const modalData = useFlagFeatureModalData();
	const { inspection } = modalData.metadata;

	const { property } = useProperty(inspection?.property.id);

	const caption = useMemo(() => {
		if (!property) return '';

		if (property.sync_data?.vendor === 'jenark') {
			return "Provide a description of the issue. This field maps to the work order field 'Details' in Jenark.";
		}

		if (property.sync_data?.vendor === 'appfolio') {
			return "Provide a description of the issue. This is the work order field 'JobDescription' in AppFolio.";
		}

		if (property.sync_data?.vendor === 'rentmanager') {
			return "Provide a description of the issue. This is the Service Issue Details field 'Description' in Rent Manager";
		}
	}, [property]);

	return (
		<FormTextField
			title="Notes"
			helperText="Required"
			caption={caption}
			icon={regular('message-lines')}
			multiline
			error={!!error}
			value={value}
			onChange={(e) => setValue(e.target.value)}
		/>
	);
};

export default Notes;
