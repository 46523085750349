import { Skeleton } from '@mui/material';
import { Column } from 'components';

const List = () => {
	return (
		<Column>
			{'skeletons'.split('').map(() => (
				<Skeleton
					variant="text"
					width={150}
					height={30}
					sx={{ marginLeft: 6, marginTop: 2, marginBottom: 1 }}
				/>
			))}
		</Column>
	);
};

export default List;
