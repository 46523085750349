import { ApiInspection } from '@rentcheck/types';
import styled from 'styled-components';

import { spacingPx } from 'theme';
import { shortDisplayDate } from 'utils/helpers';
import { Column } from '../../../';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';

interface Props {
	inspection: ApiInspection;
	canEdit: boolean;
	onEdit: () => void;
}

const InspectionInfoBox = ({ inspection, onEdit, canEdit }: Props) => {
	return (
		<Wrapper>
			<Content>
				<SpacedRow>
					<Column>
						<Typography variant="h4">
							{inspection.inspection_template.name} Inspection
						</Typography>
						<Typography variant="h6">
							{shortDisplayDate(inspection.completed_date)}
						</Typography>
					</Column>
					{canEdit && <EditIcon icon={solid('edit')} onClick={onEdit} />}
				</SpacedRow>
			</Content>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	width: ${spacingPx(64)};
	display: flex;
	flex-direction: column;
	border: 1px solid #ededed;
	border-radius: 4px;
`;

const Content = styled.div`
	padding: ${spacingPx(2)};
`;

const SpacedRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

const EditIcon = styled(FontAwesomeIcon)`
	font-size: ${spacingPx(4)};
	margin-top: ${spacingPx(1)};
	cursor: pointer;
`;

export default InspectionInfoBox;
