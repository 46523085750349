import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import { ApiInspection } from '@rentcheck/types';
import { Column, Row, Spacer } from 'components';

import Section from './section';

interface Props {
	inspections: ApiInspection[];
}

export default ({ inspections }: Props) => {
	if (inspections.length === 1) {
		const inspection = inspections[0];

		return (
			<Section title="Address">
				<Row>
					<Spacer width={3} />
					<FontAwesomeIcon icon={solid('location-dot')} size="lg" />
					<Spacer width={5} />
					<Column>
						<Typography fontWeight={500} fontSize={16}>
							{inspection.inspection_template.name}
						</Typography>
						<Typography fontSize={14}>{inspection.property.address}</Typography>
						<Typography fontSize={14}>
							{inspection.property.city} {inspection.property.zip_code}
						</Typography>
					</Column>
				</Row>
			</Section>
		);
	}

	return (
		<Section title="Address">
			<Row>
				<Spacer width={3} />
				<FontAwesomeIcon
					icon={solid('rectangle-history-circle-plus')}
					size="lg"
				/>
				<Spacer width={5} />
				<Column>
					<Typography fontWeight={500} fontSize={16}>
						Bulk inspection selection
					</Typography>
					<Typography fontSize={14}>
						{inspections.length} inspections
					</Typography>
				</Column>
			</Row>
		</Section>
	);
};
