import firebase from 'firebase/app';

import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firebase-storage';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/remote-config';

const config = {
	apiKey: process.env.firebase_apiKey,
	authDomain: process.env.firebase_authDomain,
	databaseURL: process.env.firebase_databaseURL,
	projectId: process.env.firebase_projectId,
	storageBucket: process.env.firebase_storageBucket,
	messagingSenderId: process.env.firebase_messagingSenderId,
	appId: process.env.firebase_appId,
	measurementId: process.env.firebase_measurementId,
};

firebase.initializeApp(config);
firebase.analytics().setAnalyticsCollectionEnabled(true);

if (process.env.firebase_use_local_functions_emulator === 'true') {
	firebase.functions().useFunctionsEmulator('http://localhost:5001');
}

export default firebase;
