import {
	Checkbox,
	TableCell,
	TableHead,
	TableRow,
	TableSortLabel,
} from '@mui/material';
import { APICommunity } from '@rentcheck/types';
import { useDispatch } from 'react-redux';

import { useTypedSelector } from 'store/reducers/rootReducer';

import ActionsMenu from './actions-menu';
import { Utils } from '@rentcheck/biz';
import { useAppFolioIntegration } from 'hooks/appfolio-integration';
import { useRentManagerIntegrations } from 'hooks/rentmanager-integration';

const headers = [
	{ id: 'name', title: 'Name', sortable: true },
	{ id: 'address', title: 'Address', sortable: true },
	{ id: 'city', title: 'City', sortable: false },
	{ id: 'zip_code', title: 'ZIP Code', sortable: false },
	{ id: 'team', title: 'Team', sortable: false },
	{
		id: 'last_completed_inspection',
		title: 'Last Inspection Completed',
		sortable: true,
	},
];

interface Props {
	selectedIds: string[];
	setSelectedIds: (ids: string[]) => void;
	selectAllIds: () => void;
	communities: APICommunity[];
}

const CommunityTableHeader = ({
	selectedIds,
	setSelectedIds,
	selectAllIds,
	communities,
}: Props) => {
	const dispatch = useDispatch();

	const { appFolioIntegration } = useAppFolioIntegration();
	const { rentManagerIntegrations } = useRentManagerIntegrations();

	const sortBy = useTypedSelector(
		(state) => state.properties.filters.communities.sortBy
	);
	const sortType = useTypedSelector(
		(state) => state.properties.filters.communities.sortType
	);

	const switchSortDirection = () => {
		dispatch({
			type: 'TOGGLE_COMMUNITIES_SORT_TYPE',
		});
	};

	const handleSort = (headerId: string, sortable: boolean) => {
		if (!sortable) return;

		if (headerId === sortBy) {
			return switchSortDirection();
		}

		dispatch({
			type: 'SET_COMMUNITIES_SORT_BY',
			value: headerId,
		});
	};

	const checked =
		selectedIds.length === communities.length && selectedIds.length > 0;

	const indeterminate =
		selectedIds.length > 0 && selectedIds.length !== communities.length;

	const disableDeletion = communities.some(
		(community) =>
			Utils.Properties.propertyHasIntegrationSync(community, {
				appFolioIntegration,
				rentManagerIntegrations,
			}) && selectedIds.includes(community.id)
	);

	return (
		<TableHead>
			<TableRow>
				<TableCell>
					<Checkbox
						id="checkbox-building-header"
						size="small"
						onChange={selectAllIds}
						indeterminate={indeterminate}
						checked={checked}
					/>
				</TableCell>
				{headers.map((h) => (
					<TableCell key={h.id}>
						<TableSortLabel
							active={sortBy === h.id}
							direction={sortType}
							hideSortIcon={!h.sortable}
							onClick={() => handleSort(h.id, h.sortable)}>
							{h.title}
						</TableSortLabel>
					</TableCell>
				))}
				<TableCell align="center" variant="head">
					<ActionsMenu
						disableDeletion={disableDeletion}
						communityIds={selectedIds}
						setCommunityIds={setSelectedIds}
					/>
				</TableCell>
			</TableRow>
		</TableHead>
	);
};

export default CommunityTableHeader;
