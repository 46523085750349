import { Utils } from '@rentcheck/biz';
import { ApiInspectionTemplateDigest } from '@rentcheck/types';

export type ActionTypes =
	| 'SET_INSPECTION_TEMPLATES'
	| 'ADD_INSPECTION_TEMPLATE'
	| 'EDITED_INSPECTION_TEMPLATE_SECTIONS'
	| 'EDITED_INSPECTION_TEMPLATE_DETAILS'
	| 'REMOVE_INSPECTION_TEMPLATE'
	| 'SIGNOUT_SUCCESS';

export type State = ApiInspectionTemplateDigest[];

interface Action {
	type: ActionTypes;
	data: ApiInspectionTemplateDigest[] | ApiInspectionTemplateDigest;
}

export default (state: State = [], action: Action): State => {
	switch (action.type) {
		case 'SET_INSPECTION_TEMPLATES':
			if (!action.data) return state;
			return action.data as ApiInspectionTemplateDigest[];

		case 'ADD_INSPECTION_TEMPLATE':
			if (!action.data) return state;

			if (
				state.find(
					(i) => i.id === (action.data as ApiInspectionTemplateDigest).id
				)
			)
				return state.map((it) =>
					it.id === (action.data as ApiInspectionTemplateDigest).id
						? (action.data as ApiInspectionTemplateDigest)
						: it
				);

			return [...state, action.data as ApiInspectionTemplateDigest].sort(
				Utils.InspectionTemplates.sortFn
			);

		case 'EDITED_INSPECTION_TEMPLATE_DETAILS':
			if (!action.data) return state;

			if (
				state.find(
					(i) => i.id === (action.data as ApiInspectionTemplateDigest).id
				)
			)
				return state.map((it) =>
					it.id === (action.data as ApiInspectionTemplateDigest).id
						? (action.data as ApiInspectionTemplateDigest)
						: it
				);

			return [...state, action.data as ApiInspectionTemplateDigest];

		case 'REMOVE_INSPECTION_TEMPLATE':
			if (!action.data) return state;

			return state.filter(
				(i) => i.id !== (action.data as ApiInspectionTemplateDigest).id
			);

		case 'SIGNOUT_SUCCESS':
			return [];

		default:
			return state;
	}
};
