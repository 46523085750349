import {
	ButtonBase,
	CardContent,
	Grid,
	Card as MuiCard,
	Typography,
} from '@mui/material';
import styled from 'styled-components';

interface Props {
	title: string;
	subtitle: string;
	image: string;

	onClick: () => void;
}

export default ({ title, subtitle, image, onClick }: Props) => {
	return (
		<Grid item xs={1}>
			<ButtonBase
				sx={{ height: '100%', width: '100%', textAlign: 'left' }}
				onClick={onClick}>
				<Card elevation={0}>
					<CardContent
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}>
						<img src={image} alt={title} style={{ maxWidth: 105 }} />
						<Typography variant="h6" mt={3}>
							{title}
						</Typography>
						<Typography mt={1} textAlign="center">
							{subtitle}
						</Typography>
					</CardContent>
				</Card>
			</ButtonBase>
		</Grid>
	);
};

const Card = styled(MuiCard)`
	border: 1px solid #0000001f;
	flex-grow: 1;
	height: 100%;
	transition: background-color 0.2s ease-in-out;

	:hover {
		background-color: #f6f5ff;
	}
`;
