import { Typography } from '@mui/material';
import { spacing } from 'theme';
import { Row, Spacer, TextInput } from '../../..';

interface Props {
	name: string;
	address?: string;
	city?: string;
	zipcode?: string;
	setName: (name: string) => void;
	setAddress: (address: string) => void;
	setCity: (city: string) => void;
	setZipcode: (zipcode: string) => void;
	title?: string;
	firstLabel?: string;
	secondLabel?: string;
}

const Address = ({
	name,
	address,
	city,
	zipcode,
	setName,
	setCity,
	setZipcode,
	setAddress,
	title = 'Where is the building located?',
	firstLabel = 'Building Name',
	secondLabel = 'Address',
}: Props) => {
	return (
		<div>
			<Typography variant="h6">{title}</Typography>
			<Spacer height={7} />
			<TextInput
				label={firstLabel}
				width={spacing(98)}
				onChange={(v) => setName(v)}
				value={name}
			/>
			<Spacer height={7} />
			<TextInput
				label={secondLabel}
				width={spacing(98)}
				onChange={(v) => setAddress(v)}
				value={address}
			/>
			<Spacer height={7} />
			<Row>
				<TextInput label="City" onChange={(v) => setCity(v)} value={city} />
				<Spacer width={4} />
				<TextInput
					label="Zip Code"
					onChange={(v) => setZipcode(v)}
					value={zipcode}
				/>
				<Spacer width={36} />
			</Row>
			<Spacer height={7} />
		</div>
	);
};

export default Address;
