import { Skeleton } from '@mui/material';
import { Row, SpacedRow, Spacer } from 'components';

interface Props {
	variant?: 'inspections' | 'properties';
}

const SkeletonTable = ({ variant = 'inspections' }: Props) => {
	const headerHeight = 70;
	const rowHeight = 68.5;

	const rows = Array.from(Array(6).keys());

	return (
		<div>
			<Skeleton variant="text" height={20} width={50} />
			<Spacer height={2} />
			<Skeleton
				variant="rectangular"
				width="100%"
				height={headerHeight}
				sx={{ borderRadius: 1 }}
			/>
			{rows.map((i) => (
				<Row
					key={i}
					style={{
						height: rowHeight,
						width: '100%',
						marginBottom: 1,
					}}>
					<SpacedRow style={{ flex: 1, paddingLeft: 24, paddingRight: 56 }}>
						<Skeleton variant="text" width={20} height={30} />
						<Spacer width={8} />
						<Skeleton variant="text" width={'80%'} height={30} />
						<Spacer width={8} />
						{variant === 'inspections' && (
							<>
								<Skeleton
									variant="rectangular"
									width={170}
									height={32}
									sx={{ borderRadius: 4 }}
								/>
								<Spacer width={20} />
							</>
						)}
						<Skeleton
							variant="rectangular"
							width={10}
							height={25}
							sx={{ borderRadius: 1 }}
						/>
					</SpacedRow>
				</Row>
			))}
		</div>
	);
};

export default SkeletonTable;
