import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	InputAdornment,
	Rating,
	TextField,
	Typography,
	styled,
} from '@mui/material';
import { Column, Spacer } from 'components';

interface Props {
	rating: number | undefined;
	setRating: (value: number | undefined) => void;
	privateNote: string;
	setPrivateNote: (value: string) => void;
}

export default ({ rating, setRating, privateNote, setPrivateNote }: Props) => {
	return (
		<Accordion
			style={{
				marginTop: 4,
				boxShadow: 'none',
			}}>
			<AccordionSummary
				sx={{ paddingLeft: 0, paddingRight: 0 }}
				expandIcon={
					<FontAwesomeIcon icon={solid('caret-down')} color="#00000099" />
				}>
				<Typography variant="overline">More Options</Typography>
			</AccordionSummary>

			<AccordionDetails sx={{ padding: 0 }}>
				<Typography>
					How was the quality of this inspection report? Your rating will help
					RentCheck improve. We won't publish your rating or share it with
					anyone.
				</Typography>

				<Spacer height={4} />
				<RatingContainer>
					<Column>
						<Rating
							size="large"
							value={rating}
							onChange={(_, newValue) => {
								newValue && setRating(newValue);
							}}
						/>
					</Column>
				</RatingContainer>
				<Spacer height={4} />

				<TextField
					fullWidth
					label="Note"
					helperText="Optional"
					placeholder="Add Feedback for RentCheck"
					variant="filled"
					value={privateNote}
					onChange={(e) => setPrivateNote(e.target.value)}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<FontAwesomeIcon icon={regular('message-lines')} />
							</InputAdornment>
						),
					}}
				/>
			</AccordionDetails>
		</Accordion>
	);
};

const RatingContainer = styled(Column)`
	width: 100%;
	height: 90px;
	background-color: #0000000a;
	border: 1px solid #0000001f;
	border-radius: 8px;
	align-items: center;
	justify-content: center;
`;
