import { Skeleton } from '@mui/material';

import { Column, Spacer } from 'components';

export default () => {
	return (
		<Column>
			<Spacer height={8} />
			<Skeleton
				variant="rectangular"
				width="20%"
				height={20}
				sx={{ borderRadius: 1 }}
			/>

			<Spacer height={2} />
			<Skeleton
				variant="rectangular"
				width="100%"
				height={60}
				sx={{ borderRadius: 1 }}
			/>

			<Spacer height={4} />
			<Skeleton
				variant="rectangular"
				width="20%"
				height={20}
				sx={{ borderRadius: 1 }}
			/>

			<Spacer height={2} />
			<Skeleton
				variant="rectangular"
				width="100%"
				height={60}
				sx={{ borderRadius: 1 }}
			/>

			<Spacer height={4} />
			<Skeleton
				variant="rectangular"
				width="20%"
				height={20}
				sx={{ borderRadius: 1 }}
			/>

			<Spacer height={2} />
			<Skeleton
				variant="rectangular"
				width="100%"
				height={60}
				sx={{ borderRadius: 1 }}
			/>
		</Column>
	);
};
