import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Box,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	Link,
	Typography,
} from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import {
	ApiSubscription,
	BaseIntegration,
	FirebaseUpdatePayload,
} from '@rentcheck/types';

import { Column, Row, SpacedRow, Spacer } from 'components';
import {
	ModalFlowActions,
	SnackbarActions,
	SubscriptionActions,
} from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { colors } from 'theme';
import { Dispatch } from 'types';

import { LoadingButton } from '@mui/lab';
import IconButton from 'components/icon-button';
import { useIsMobileBreakpoint } from 'utils/hooks';
import MappingSection from './common/mapping-section';
import EditSnackbar from './edit-snackbar';
import IngestionFilter from './ingestion-filter';
import PropertyTypeFilter from './property-type-filter';
import ReviewDetails from './review-details';
import SyncConfirmation from './sync-confirmation';
import TeamAssignment from './team-assignment';
import * as Types from './types';

export type MappingSectionType = Types.MappingSection;
export type MappingType = Types.MappingType;
export type TeamAssignmentType = Types.TeamAssignmentType;
export type ExternalTeamType = Types.ExternalTeam;
export type IngestionFilterType = Types.IngestionFilter;
export type IngestionFilterOptionType = Types.IngestionFilterOption;
export type PropertyTypeFilterType = Types.PropertyTypeFilter;
export type DefaultSkillType = Types.DefaultSkill;
export type DefaultWorkOrderStatusType = Types.DefaultWorkOrderStatus;

interface Props<T> {
	vendor: string;
	parentUrl: string;
	learnMoreLink: string;

	getIntegration: (s: ApiSubscription) => Promise<T | undefined>;
	mappingSections: MappingSectionType[];
	teamAssignment: TeamAssignmentType;
	ingestionFilter?: IngestionFilterType;
	propertyTypeFilter?: PropertyTypeFilterType;
	defaultSkill?: DefaultSkillType;
	defaultWorkOrderStatus?: DefaultWorkOrderStatusType;

	handleSaveIntegration: (
		integration: BaseIntegration,
		basePayload: FirebaseUpdatePayload<BaseIntegration>,
		firstTimeSetup: boolean,
		propertyTypes?: string[],
		ingestionFilters?: IngestionFilterOptionType[]
	) => Promise<void>;
}

export default <T extends BaseIntegration>({
	vendor,
	parentUrl,
	learnMoreLink,
	getIntegration,
	mappingSections,
	teamAssignment,
	ingestionFilter,
	propertyTypeFilter,
	handleSaveIntegration,
	defaultSkill,
	defaultWorkOrderStatus,
}: Props<T>) => {
	const dispatch: Dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();

	const isMobile = useIsMobileBreakpoint('sm');

	const profile = useTypedSelector((state) => state.activeProfile);
	const subscription = useTypedSelector((state) => state.subscription);

	const [editMode, setEditMode] = useState(false);
	const [loading, setLoading] = useState(false);
	const [reviewModal, setReviewModal] = useState(false);
	const [confirmationModal, setConfirmationModal] = useState(false);

	const [integration, setIntegration] = useState<BaseIntegration>();

	const [teamMappings, setTeamMappings] =
		useState<BaseIntegration['team_mappings']>();
	const [teamError, setTeamError] = useState(false);
	const [types, setTypes] = useState<string[]>([]);
	const [typesError, setTypesError] = useState(false);
	const [skill, setSkill] = useState<DefaultSkillType['value']>();
	const [workOrderStatus, setWorkOrderStatus] =
		useState<DefaultWorkOrderStatusType['value']>();
	const [filterOptions, setFilterOptions] = useState<
		IngestionFilterOptionType[]
	>([]);

	const [unitToggle, setUnitToggle] = useState(false);
	const [buildingToggle, setBuildingToggle] = useState(false);
	const [residentToggle, setResidentToggle] = useState(false);
	const [inspectionToggle, setInspectionToggle] = useState(false);
	const [workOrdersToggle, setWorkOrdersToggle] = useState(false);

	const [editTitle, setEditTitle] = useState('Set Up Sync');

	useEffect(() => {
		if (!subscription) return;

		getIntegration(subscription).then((result) => {
			if (!result) return;

			setIntegration(result);

			setTeamMappings(_.get(result, 'team_mappings'));
			setUnitToggle(_.get(result, 'sync_details.units.active'));
			setBuildingToggle(_.get(result, 'sync_details.buildings.active'));
			setResidentToggle(_.get(result, 'sync_details.residents.active'));
			setInspectionToggle(_.get(result, 'sync_details.inspections.active'));
			setWorkOrdersToggle(_.get(result, 'sync_details.work_orders.active'));

			!!defaultSkill && setSkill(_.get(result, 'default_skill'));

			!!defaultWorkOrderStatus &&
				setWorkOrderStatus(_.get(result, 'default_work_order_status'));

			!!propertyTypeFilter &&
				setTypes(_.get(result, propertyTypeFilter.selector));

			!!ingestionFilter &&
				setFilterOptions(_.get(result, ingestionFilter.selector));
		});
	}, [subscription]);

	useEffect(() => {
		setEditMode(false);
		setReviewModal(false);
		setTeamError(false);
		setTypesError(false);

		if (integration) {
			setTeamMappings(_.get(integration, 'team_mappings'));
			setUnitToggle(_.get(integration, 'sync_details.units.active'));
			setBuildingToggle(_.get(integration, 'sync_details.buildings.active'));
			setResidentToggle(_.get(integration, 'sync_details.residents.active'));
			setInspectionToggle(
				_.get(integration, 'sync_details.inspections.active')
			);
			setWorkOrdersToggle(
				_.get(integration, 'sync_details.work_orders.active')
			);

			!!propertyTypeFilter &&
				setTypes(_.get(integration, propertyTypeFilter.selector));

			!!ingestionFilter &&
				setFilterOptions(_.get(integration, ingestionFilter.selector));

			!!defaultSkill && setSkill(_.get(integration, 'default_skill'));

			!!defaultWorkOrderStatus &&
				setWorkOrderStatus(_.get(integration, 'default_work_order_status'));

			const propertyTypeFilterIsSetup =
				!propertyTypeFilter ||
				(!!propertyTypeFilter &&
					_.get(integration, propertyTypeFilter.selector).length);

			const hasDefaultMapping = integration.team_mappings?.default;

			const integrationIsSetUp = propertyTypeFilterIsSetup && hasDefaultMapping;

			setEditTitle(integrationIsSetUp ? 'Edit Sync' : 'Set Up Sync');
		}
	}, [location.pathname]);

	useEffect(() => {
		setTeamError(false);
	}, [teamMappings]);

	const closeModal = () => {
		history.push(parentUrl);
	};

	const handleClose = () => {
		if (!integration) return;

		const propertyTypeHasChanged =
			propertyTypeFilter &&
			!_.isEqual(types, _.get(integration, propertyTypeFilter.selector));

		const ingestionFilterHasChanged =
			ingestionFilter &&
			!_.isEqual(filterOptions, _.get(integration, ingestionFilter.selector));

		if (
			teamMappings !== integration.team_mappings ||
			propertyTypeHasChanged ||
			ingestionFilterHasChanged ||
			unitToggle !== integration.sync_details.units.active ||
			buildingToggle !== integration.sync_details.buildings.active ||
			residentToggle !== integration.sync_details.residents.active ||
			inspectionToggle !== integration.sync_details.inspections.active ||
			workOrdersToggle !== integration.sync_details.work_orders.active
		) {
			dispatch(
				ModalFlowActions.showConfirmationModal({
					title: 'Are you sure?',
					body1: [
						'Your sync updates will not be saved if you exit without saving.',
					],
					body2: [],
					buttons: [
						{
							title: 'Cancel',
							color: 'secondary',
							variant: 'outlined',
							onClick: () => {
								dispatch(ModalFlowActions.closeConfirmationModal());
							},
						},
						{
							title: 'Exit without saving',
							color: 'primary',
							variant: 'contained',
							onClick: () => {
								dispatch(ModalFlowActions.closeConfirmationModal());
								closeModal();
							},
						},
					],
				})
			);
		} else {
			closeModal();
		}
	};

	const handleSave = () => {
		setTeamError(false);
		setTypesError(false);

		if (!editMode) {
			setEditMode(true);
			return;
		}

		if (!integration) {
			return;
		}

		const hasTeamError = !teamMappings?.default;
		const hasTypesError = propertyTypeFilter && !types.length;

		setTeamError(hasTeamError);
		setTypesError(hasTypesError ?? false);

		if (hasTeamError || hasTypesError) {
			return;
		}

		if (
			unitToggle ||
			buildingToggle ||
			residentToggle ||
			inspectionToggle ||
			workOrdersToggle
		) {
			setReviewModal(true);
			return;
		}

		handleConfirm(false);
	};

	const handleUnitSyncNecessary = (
		setOriginalToggle: (value: boolean) => void
	) => {
		dispatch(
			ModalFlowActions.showConfirmationModal({
				title: 'Turn on unit sync',
				body1: [
					'In order to turn on the resident, inspection or work order sync, the unit sync must also be turned on.',
				],
				body2: [],
				buttons: [
					{
						title: 'Back',
						color: 'secondary',
						variant: 'outlined',
						onClick: () => {
							dispatch(ModalFlowActions.closeConfirmationModal());
						},
					},
					{
						title: 'Turn on unit sync',
						color: 'primary',
						variant: 'contained',
						onClick: () => {
							setUnitToggle(true);
							setOriginalToggle(true);
							dispatch(ModalFlowActions.closeConfirmationModal());
						},
					},
				],
			})
		);
	};

	const handleUnitToggle = (toggle: boolean) => {
		if (inspectionToggle || workOrdersToggle) {
			dispatch(
				SnackbarActions.showError(
					'Cannot turn off until Inspection Report and Work Order syncs are all turned off as well'
				)
			);
		} else {
			setUnitToggle(toggle);
			if (!toggle) handleResidentToggle(toggle);
		}
	};

	const handleResidentToggle = (toggle: boolean) => {
		if (!unitToggle) {
			handleUnitSyncNecessary(setResidentToggle);
		} else {
			setResidentToggle(toggle);
		}
	};

	const handleInspectionToggle = (toggle: boolean) => {
		if (!unitToggle) {
			handleUnitSyncNecessary(setInspectionToggle);
		} else {
			setInspectionToggle(toggle);
		}
	};

	const handleWorkOrdersToggle = (toggle: boolean) => {
		if (!unitToggle) {
			handleUnitSyncNecessary(setWorkOrdersToggle);
		} else {
			setWorkOrdersToggle(toggle);
		}
	};

	const handleConfirm = async (reviewShown: boolean) => {
		if (!integration) return;
		if (!teamMappings?.default) return;
		if (!profile) return;

		setLoading(true);

		const payload: FirebaseUpdatePayload<BaseIntegration> = {
			team_mappings: teamMappings,

			sync_details: {
				units: { ...integration.sync_details.units, active: unitToggle },
				buildings: {
					...integration.sync_details.buildings,
					active: buildingToggle,
				},
				residents: {
					...integration.sync_details.residents,
					active: residentToggle,
				},
				work_orders: {
					...integration.sync_details.work_orders,
					active: workOrdersToggle,
				},
				inspections: {
					...integration.sync_details.inspections,
					active: inspectionToggle,
				},
			},
		};

		if (skill) {
			payload.default_skill = skill;
		}

		if (workOrderStatus) {
			payload.default_work_order_status = workOrderStatus;
		}

		return handleSaveIntegration(
			integration,
			payload,
			editTitle === 'Set Up Sync',
			types,
			filterOptions
		)
			.then(() => {
				dispatch(SubscriptionActions.getSubscription());

				setLoading(false);
				closeModal();

				if (reviewShown) {
					setConfirmationModal(true);
				} else {
					dispatch(SnackbarActions.showSuccess('Sync details saved'));
				}
			})
			.catch((e) => {
				setLoading(false);
				dispatch(SnackbarActions.showError(e.message));
			});
	};

	const syncStatusForMappingType = (type: MappingType) => {
		switch (type) {
			case 'Units':
				return unitToggle;
			case 'Buildings':
				return buildingToggle;
			case 'Residents':
				return residentToggle;
			case 'Inspection Reports':
				return inspectionToggle;
			case 'Work Orders':
			case 'Service Issues':
				return workOrdersToggle;
		}
	};

	const setSyncStatusForMappingType = (type: MappingType) => {
		switch (type) {
			case 'Units':
				return handleUnitToggle;
			case 'Buildings':
				return setBuildingToggle;
			case 'Residents':
				return handleResidentToggle;
			case 'Inspection Reports':
				return handleInspectionToggle;
			case 'Work Orders':
			case 'Service Issues':
				return handleWorkOrdersToggle;
		}
	};

	const syncDetailsExtractorForMappingType = (type: MappingType) => {
		switch (type) {
			case 'Units':
				return (i?: BaseIntegration) => i?.sync_details.units;
			case 'Buildings':
				return (i?: BaseIntegration) => i?.sync_details.buildings;
			case 'Residents':
				return (i?: BaseIntegration) => i?.sync_details.residents;
			case 'Inspection Reports':
				return (i?: BaseIntegration) => i?.sync_details.inspections;
			case 'Work Orders':
			case 'Service Issues':
				return (i?: BaseIntegration) => i?.sync_details.work_orders;
		}
	};

	const open = location.pathname.startsWith(parentUrl + '/data-sync');

	const readMappings = mappingSections.filter((m) => m.mode === 'read');
	const writeMappings = mappingSections.filter((m) => m.mode === 'write');

	const defaultSkillWithSetter: DefaultSkillType | undefined = defaultSkill
		? {
				...(defaultSkill ?? ({} as DefaultSkillType)),
				value: skill,
				setValue: setSkill,
			}
		: undefined;

	const defaultWorkOrderStatusWithSetter:
		| DefaultWorkOrderStatusType
		| undefined = defaultWorkOrderStatus
		? {
				...(defaultWorkOrderStatus ?? ({} as DefaultWorkOrderStatusType)),
				value: workOrderStatus,
				setValue: setWorkOrderStatus,
			}
		: undefined;

	const isWorkOrderSection = (mapping: MappingSectionType) =>
		mapping.type === 'Work Orders' || mapping.type === 'Service Issues';

	return (
		<>
			<Dialog
				open={open}
				maxWidth="md"
				onClose={() => {
					if (!editMode) closeModal();
				}}
				disableEscapeKeyDown
				fullWidth
				fullScreen={isMobile}>
				<DialogTitle sx={{ borderBottom: '1px solid #21212114' }}>
					<SpacedRow style={{ alignItems: 'flex-start' }}>
						<Column>
							<Typography variant="h4">{vendor} Data Sync Settings</Typography>
							<Typography
								variant="body2"
								sx={{ marginTop: 1.5 }}
								color="#4D4D4D">
								Automate data in and out of RentCheck so you can focus on what
								matters.{' '}
								<Link
									color={colors.darkGray}
									target="_blank"
									href={learnMoreLink}>
									Learn more.
								</Link>
							</Typography>
						</Column>
						<Row style={{ marginTop: 8, marginLeft: 2 }}>
							<Box sx={{ display: { xs: 'none', sm: 'inline-flex' } }}>
								<LoadingButton
									loading={loading}
									onClick={handleSave}
									sx={{ whiteSpace: 'nowrap' }}>
									{editMode ? 'Save' : editTitle}
								</LoadingButton>
							</Box>
							<Spacer width={2} />
							<IconButton onClick={handleClose} icon={solid('times')} />
						</Row>
					</SpacedRow>
					<Box mt={2} sx={{ display: { sm: 'none' } }}>
						<LoadingButton fullWidth loading={loading} onClick={handleSave}>
							{editMode ? 'Save' : editTitle}
						</LoadingButton>
					</Box>
				</DialogTitle>
				<DialogContent
					style={{
						position: 'relative',
						backgroundColor: '#00000005',
						overflow: !editMode ? 'hidden' : undefined,
					}}>
					<Spacer height={6} />

					{!!ingestionFilter && (
						<IngestionFilter
							selectedFilterOptions={filterOptions}
							setSelectedFilterOptions={setFilterOptions}
							vendor={vendor}
							ingestionFilter={ingestionFilter}
						/>
					)}

					{!!propertyTypeFilter && (
						<PropertyTypeFilter
							types={types}
							setTypes={setTypes}
							error={typesError}
							vendor={vendor}
							propertyTypeFilter={propertyTypeFilter}
						/>
					)}

					<TeamAssignment
						vendor={vendor}
						teamMappings={teamMappings}
						setTeamMappings={setTeamMappings}
						teamAssignment={teamAssignment}
						error={teamError}
					/>

					<Divider sx={{ marginBottom: 2 }} />

					<Row>
						<Typography variant="h5">{vendor}</Typography>
						<Spacer width={5} />

						<FontAwesomeIcon icon={regular('long-arrow-right')} />
						<Spacer width={5} />

						<Typography variant="h5">RentCheck</Typography>
					</Row>
					<Spacer height={5} />
					{readMappings.map((m) => (
						<MappingSection
							title={m.title}
							body={m.body}
							provider={vendor}
							mode={m.mode}
							objectType={m.type}
							mappings={m.mappings}
							frequency={m.frequency}
							integration={integration}
							syncToggle={syncStatusForMappingType(m.type)}
							setSyncToggle={setSyncStatusForMappingType(m.type)}
							syncDetailsExtractor={syncDetailsExtractorForMappingType(m.type)}
						/>
					))}

					<Row>
						<Typography variant="h5">RentCheck</Typography>
						<Spacer width={5} />

						<FontAwesomeIcon icon={regular('long-arrow-right')} />
						<Spacer width={5} />

						<Typography variant="h5">{vendor}</Typography>
					</Row>
					<Spacer height={5} />
					{writeMappings.map((m) => (
						<MappingSection
							title={m.title}
							body={m.body}
							provider={vendor}
							mode={m.mode}
							objectType={m.type}
							mappings={m.mappings}
							frequency={m.frequency}
							integration={integration}
							syncToggle={syncStatusForMappingType(m.type)}
							setSyncToggle={setSyncStatusForMappingType(m.type)}
							syncDetailsExtractor={syncDetailsExtractorForMappingType(m.type)}
							requiredAddons={m.requiredAddons}
							defaultSkill={
								isWorkOrderSection(m) ? defaultSkillWithSetter : undefined
							}
							defaultWorkOrderStatus={
								isWorkOrderSection(m)
									? defaultWorkOrderStatusWithSetter
									: undefined
							}
						/>
					))}

					{!editMode && (
						<div
							style={{
								height: '100%',
								width: '100%',
								position: 'absolute',
								backgroundColor: '#232E3A33',
								top: 0,
								left: 0,
							}}
						/>
					)}
				</DialogContent>
			</Dialog>

			<ReviewDetails
				open={reviewModal}
				handleClose={() => setReviewModal(false)}
				handleConfirm={handleConfirm}
				vendor={vendor}
				teamMappings={teamMappings}
				propertyTypes={types}
				filterOptions={filterOptions}
				ingestionFilter={ingestionFilter}
				propertyTypeFilter={propertyTypeFilter}
				syncSections={mappingSections.map((ms) => ({
					...ms,
					value: syncStatusForMappingType(ms.type),
				}))}
			/>

			<SyncConfirmation
				vendor={vendor}
				open={confirmationModal}
				handleClose={() => setConfirmationModal(false)}
			/>

			<EditSnackbar editMode={editMode} />
		</>
	);
};
