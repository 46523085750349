import { Button, DialogActions, DialogContent, Skeleton } from '@mui/material';

import { Column, SpacedRow, Spacer } from 'components';
import { useDispatch } from 'react-redux';
import { ModalFlowActions } from 'store/actions';

import { handleNativeAppClose } from '../common/native-app';

export default () => {
	const dispatch = useDispatch();

	const handleClose = () => {
		handleNativeAppClose();
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	return (
		<>
			<DialogContent>
				<Column>
					<Skeleton width="70%" height={20} />
					<Spacer height={4} />
					<Skeleton
						variant="rectangular"
						width="100%"
						height={40}
						sx={{ borderRadius: 1 }}
					/>

					<Spacer height={4} />

					<SpacedRow>
						<Column>
							<Skeleton width="100px" height={20} />
							<Skeleton width="260px" height={20} />
						</Column>
						<Skeleton
							variant="rectangular"
							width="200px"
							height={40}
							sx={{ borderRadius: 1 }}
						/>
					</SpacedRow>

					<Spacer height={3} />
					<SpacedRow>
						<Column>
							<Skeleton width="100px" height={20} />
							<Skeleton width="260px" height={20} />
						</Column>
						<Skeleton
							variant="rectangular"
							width="200px"
							height={40}
							sx={{ borderRadius: 1 }}
						/>
					</SpacedRow>

					<Spacer height={3} />
					<SpacedRow>
						<Column>
							<Skeleton width="100px" height={20} />
							<Skeleton width="260px" height={20} />
						</Column>
						<Skeleton
							variant="rectangular"
							width="200px"
							height={40}
							sx={{ borderRadius: 1 }}
						/>
					</SpacedRow>
				</Column>
			</DialogContent>
			<DialogActions>
				<Button color="secondary" variant="outlined" onClick={handleClose}>
					Dismiss
				</Button>
				<Button disabled variant="contained">
					Create
				</Button>
			</DialogActions>
		</>
	);
};
