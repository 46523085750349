import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import SegmentChip from 'components/segment-chip';
import _ from 'lodash';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { incompleteInspectionStatuses } from './constants';

const startOfToday = moment().startOf('day').valueOf();
const endOf3DaysToCome = moment().endOf('day').add(3, 'days').valueOf();

export default () => {
	const dispatch = useDispatch();

	const filters = useTypedSelector((state) => state.inspections.filters);

	const handleClick = () => {
		dispatch({ type: 'RESET_INSPECTION_FILTERS' });

		dispatch({
			type: 'SET_INSPECTION_DUE_DATE_RANGE_FILTER',
			dateRange: { start: startOfToday, end: endOf3DaysToCome },
		});

		dispatch({
			type: 'SET_INSPECTION_STATUS_FILTER',
			value: {
				condition: 'any_of',
				value: incompleteInspectionStatuses,
			},
		});
	};

	const selected =
		_.xor(filters.inspectionStatus?.value, incompleteInspectionStatuses)
			.length === 0 &&
		!filters.inspectionTemplate?.value.length &&
		!filters.team?.value.length &&
		filters.dueDateRange?.start === startOfToday &&
		filters.dueDateRange?.end === endOf3DaysToCome &&
		!filters.completedDateRange &&
		!filters.archived;

	return (
		<SegmentChip
			variant="outlined"
			icon={solid('exclamation-triangle')}
			label="Almost Due"
			title="Almost Due"
			onClick={handleClick}
			color="warning"
			selected={selected}
		/>
	);
};
