import { Divider, Typography } from '@mui/material';
import { Column, SpacedRow, Spacer } from 'components';
import { ReactNode, ReactNodeArray } from 'react';

interface Props {
	title: string;
	rightTitle?: string;
	children: ReactNode | ReactNodeArray;
	isLastSection?: boolean;
}

export default ({ title, children, isLastSection, rightTitle }: Props) => {
	return (
		<Column>
			<SpacedRow>
				<Typography variant="overline">{title}</Typography>
				<Typography variant="overline" sx={{ marginRight: 2 }}>
					{rightTitle}
				</Typography>
			</SpacedRow>
			<Spacer height={3} />
			{children}
			<Spacer height={5} />
			{!isLastSection && <Divider />}
			<Spacer height={9} />
		</Column>
	);
};
