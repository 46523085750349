import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress, Grid, IconButton, Paper } from '@mui/material';

import { Feature } from '@rentcheck/types';

import { Row } from 'components';
import { imageUrlFromGCPUri } from 'utils/helpers';

interface Props {
	uri: string;
	featurePayload: Partial<Feature>;
	setFeaturePayload: (payload: Partial<Feature>) => void;
}

const ImageItem = ({ uri, featurePayload, setFeaturePayload }: Props) => {
	const handleDelete = () => {
		const updatedImages = featurePayload.images?.filter((i) => i !== uri);

		setFeaturePayload({
			...featurePayload,
			images: updatedImages,
		});
	};

	if (uri.startsWith('upload://')) {
		return (
			<Grid
				item
				sx={{
					width: '33%',
				}}>
				<Paper
					elevation={1}
					sx={{
						height: 223,
						width: '100%',
						backgroundColor: '#eff1f4',
						alignItems: 'center',
						justifyContent: 'center',
						display: 'flex',
					}}>
					<CircularProgress color="secondary" />
				</Paper>
			</Grid>
		);
	}

	return (
		<Grid
			item
			sx={{
				width: '33%',
			}}>
			<Paper
				elevation={1}
				sx={{
					height: 223,
					width: '100%',
					backgroundColor: 'gray',
					background: `url(${imageUrlFromGCPUri(uri, 500, 500)})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
				}}>
				<Row style={{ justifyContent: 'flex-end' }}>
					<IconButton id={`button-remove-image-${uri}`} onClick={handleDelete}>
						<FontAwesomeIcon icon={solid('times-circle')} color="#232e3a" />
					</IconButton>
				</Row>
			</Paper>
		</Grid>
	);
};

export default ImageItem;
