import { Skeleton } from '@mui/material';
import { Column, SpacedRow } from 'components';

const Row = () => {
	return (
		<SpacedRow style={{ marginTop: 16 }}>
			<Column>
				<Skeleton animation="wave" variant="text" width={200} height={16} />
				<Skeleton animation="wave" variant="text" width={160} height={16} />
			</Column>
			<Skeleton
				animation="wave"
				variant="rectangular"
				width={18}
				height={18}
				sx={{ borderRadius: 1, mr: 2.25 }}
			/>
		</SpacedRow>
	);
};

export default () => {
	return (
		<Column>
			<Row />
			<Row />
			<Row />
			<Row />
			<Row />
			<Row />
			<Row />
			<Row />
			<Row />
			<Row />
			<Row />
			<Row />
			<Row />
			<Row />
			<Row />
			<Row />
		</Column>
	);
};
