import { ApiInspectionWithTemplate, Inspection } from '@rentcheck/types';

export type State = ApiInspectionWithTemplate | null;

const initialState = null;

type Action = {
	type: string;
	inspection?: Inspection;
};

const reducer = (state: State = initialState, action: Action) => {
	switch (action.type) {
		case 'SIGNOUT_SUCCESS':
		case 'IMPERSONATE':
			return initialState;

		case 'UPDATED_INSPECTION':
			if (!state) return state;
			if (!action.inspection) return state;
			if (action.inspection.id !== state.id) return state;
			return action.inspection;

		case 'DELETE_INSPECTION':
			if (!state) return state;
			if (!action.inspection) return state;
			if (action.inspection.id !== state.id) return state;
			return null;

		case 'FETCHED_INSPECTION_WITH_TEMPLATE':
			if (!action.inspection) return state;
			return action.inspection;

		default:
			return state;
	}
};

export default reducer;
