import { Typography } from '@mui/material';
import { Column } from 'components';
import { isEmailValid } from 'utils/emails';
import MuiPhoneNumber from 'mui-phone-number';
import styled from 'styled-components';
import FormTextField from 'components/form-text-field';
type Props = {
	email: string;
	phone: string;
	setEmail: (value: string) => void;
	setPhone: (value: string) => void;
};
const ContactInfo = ({ email, phone, setEmail, setPhone }: Props) => {
	const showError = !isEmailValid(email) && !!email.trim();

	const handleEmailChange = (email: string) => {
		const validString = email.replaceAll(',', '').replaceAll(' ', '').trim();
		setEmail(validString);
	};

	return (
		<>
			<Typography variant="overline">Inspection Contact Information</Typography>
			<Typography variant="body2" mt={1} mb={3}>
				Add company contact information for residents to use when they have
				specific requests or questions about an inspection. These contact
				details will be included in RentCheck inspection invitations and
				scheduled reminders.
			</Typography>

			<Column>
				<FormTextField
					style={{ maxWidth: 326 }}
					title="Email Address"
					type="email"
					placeholder="Add email address"
					value={email}
					onChange={(e) => handleEmailChange(e.target.value || '')}
					error={showError}
					helperText={showError ? 'Email is invalid' : undefined}
					inputMode="email"
				/>
			</Column>
			<Column>
				<Typography variant="overline" mb={1}>
					Phone Number
				</Typography>
				<PhoneNumberTextField
					sx={{ maxWidth: 326 }}
					variant="filled"
					placeholder="Add phone number"
					defaultCountry={'us'}
					value={phone}
					onChange={(e) => setPhone(e as string)}
				/>
			</Column>
		</>
	);
};

const PhoneNumberTextField = styled(MuiPhoneNumber)`
	margin-bottom: 6px;
	max-width: 326px;

	.MuiInputAdornment-root {
		filter: grayscale();
		padding-top: 0;
		margin-bottom: 12px;
	}

	.MuiFilledInput-root {
		padding: 12px 12px 16px 12px;
	}

	.MuiIconButton-root {
		min-width: 20px !important;
	}

	.MuiFilledInput-input {
		padding: 0;
		margin-bottom: -6px;
	}
`;

export default ContactInfo;
