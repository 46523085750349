import { useCreateWorkOrderModalData } from 'hooks/modals';

import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { getModalConfig } from '../common/modal-config';
import CreateWorkOrders from './create-work-orders';
import ReviewSkills from './review-skills';
import Skeleton from './skeleton';

const Content = () => {
	const profile = useTypedSelector((state) => state.activeProfile);

	const modalData = useCreateWorkOrderModalData('Rent Manager');
	const { flags, inspection, provider } = modalData?.metadata ?? {};

	const modalConfig = getModalConfig(provider);

	const [validSkillNames, setValidSkillNames] = useState<string[]>([]);

	useEffect(() => {
		modalConfig
			.getSkills(inspection.property.id, profile.id)
			.then((s) => _.uniq(s))
			.then((s) => _.sortBy(s))
			.then(setValidSkillNames);
	}, [flags]);

	if (!flags.length || !validSkillNames.length) {
		return <Skeleton />;
	}

	const skillsNeedReview = flags.some(
		(f) => !validSkillNames.includes(f.skill)
	);

	if (skillsNeedReview) {
		return <ReviewSkills validSkills={validSkillNames} />;
	}

	return <CreateWorkOrders />;
};

export default Content;
