import { Divider, LinearProgress, Typography } from '@mui/material';

import { AccountSettings, ApiInspectionWithTemplate } from '@rentcheck/types';
import { Column, SpacedRow, Spacer } from 'components';

import { useTypedSelector } from 'store/reducers/rootReducer';

import { defaultRooms } from 'screens/account/property-configuration/common/constants';
import AreaGroup from './area-group';

interface Props {
	inspection: ApiInspectionWithTemplate;
}

const isRoomInterior = (room: string, accountSettings?: AccountSettings) => {
	const customInteriorRooms = (accountSettings?.rooms.unit ?? [])
		.filter((cr) => cr.category === 'interior')
		.map((cr) => cr.name);
	const defaultInteriorRooms = defaultRooms.units.interior;

	return [...customInteriorRooms, ...defaultInteriorRooms].includes(room);
};

const isRoomExterior = (room: string, accountSettings?: AccountSettings) => {
	const customExteriorRooms = (accountSettings?.rooms.unit ?? [])
		.filter((cr) => cr.category === 'exterior')
		.map((cr) => cr.name);
	const defaultExteriorRooms = defaultRooms.units.exterior;

	return [...customExteriorRooms, ...defaultExteriorRooms].includes(room);
};

export default ({ inspection }: Props) => {
	const accountSettings = useTypedSelector((state) => state.accountSettings);

	const interiorRooms = inspection.sections_progress.filter(
		(sp) =>
			sp.section.type === 'room' &&
			isRoomInterior(sp.section.name, accountSettings)
	);

	const exteriorRooms = inspection.sections_progress.filter(
		(sp) =>
			sp.section.type === 'room' &&
			isRoomExterior(sp.section.name, accountSettings)
	);

	/**
	 * These names change depending on the inspection template type (specific-features/all-rooms)
	 * This is a known issue that will not be fixed for now, so we need to check both here.
	 */
	const bedrooms = inspection.sections_progress.filter(
		(sp) =>
			sp.section.type === 'room' &&
			['Bedrooms', 'Bedroom'].includes(sp.section.name)
	);

	const halfBathrooms = inspection.sections_progress.filter(
		(sp) =>
			sp.section.type === 'room' &&
			['Half Bathrooms', 'Half Bathroom'].includes(sp.section.name)
	);

	const fullBathrooms = inspection.sections_progress.filter(
		(sp) =>
			sp.section.type === 'room' &&
			['Full Bathrooms', 'Full Bathroom'].includes(sp.section.name)
	);

	const buildingRooms = inspection.sections_progress.filter(
		(sp) =>
			sp.section.type === 'room' &&
			inspection.inspection_template.property_type === 'building'
	);

	const communityRooms = inspection.sections_progress.filter(
		(sp) =>
			sp.section.type === 'room' &&
			inspection.inspection_template.property_type === 'community'
	);

	const sections = inspection.sections_progress.filter(
		(sp) => sp.section.type === 'section'
	);

	return (
		<Column>
			<Typography variant="subtitle2">Completed:</Typography>
			<SpacedRow>
				<LinearProgress
					sx={{
						flex: 1,
						backgroundColor: '#C8FFD9',
						'.MuiLinearProgress-bar': {
							backgroundColor: '#00C14F',
						},
					}}
					color="success"
					value={inspection.progress_pct}
					variant="determinate"
				/>
				<Spacer width={2} />
				<Typography variant="subtitle2" color="#354351">
					{inspection.progress_pct?.toFixed(0)}%
				</Typography>
			</SpacedRow>

			<Divider sx={{ mt: 3, mb: 4 }} />

			<AreaGroup roomType="Bedrooms" sections={bedrooms} />
			<AreaGroup roomType="Bathrooms" sections={fullBathrooms} />
			<AreaGroup roomType="Half Bathrooms" sections={halfBathrooms} />
			<AreaGroup roomType="Interior" sections={interiorRooms} />
			<AreaGroup roomType="Exterior" sections={exteriorRooms} />
			<AreaGroup roomType="Building Areas" sections={buildingRooms} />
			<AreaGroup roomType="Community Areas" sections={communityRooms} />
			<AreaGroup roomType="Other" sections={sections} />
		</Column>
	);
};
