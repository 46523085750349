const bedroomsAndBathrooms = [
	'Bedroom',
	'Bathroom',
	'Full Bathroom',
	'Half Bathroom',
	'First Bedroom',
	'First Bathroom',
	'First Full Bathroom',
	'First Half Bathroom',
	'Second Bedroom',
	'Second Bathroom',
	'Second Full Bathroom',
	'Second Half Bathroom',
	'Third Bedroom',
	'Third Bathroom',
	'Third Full Bathroom',
	'Third Half Bathroom',
	'Fourth Bedroom',
	'Fourth Bathroom',
	'Fourth Full Bathroom',
	'Fourth Half Bathroom',
	'Fifth Bedroom',
	'Fifth Bathroom',
	'Fifth Full Bathroom',
	'Fifth Half Bathroom',
	'Sixth Bedroom',
	'Sixth Bathroom',
	'Sixth Full Bathroom',
	'Sixth Half Bathroom',
	'Seventh Bedroom',
	'Seventh Bathroom',
	'Seventh Full Bathroom',
	'Seventh Half Bathroom',
	'Eighth Bedroom',
	'Eighth Bathroom',
	'Eighth Full Bathroom',
	'Eighth Half Bathroom',
	'Ninth Bedroom',
	'Ninth Bathroom',
	'Ninth Full Bathroom',
	'Ninth Half Bathroom',
	'Tenth Bedroom',
	'Tenth Bathroom',
	'Tenth Full Bathroom',
	'Tenth Half Bathroom',
];

const unitAreas = [
	'Front Door',
	'Entry Way',
	'Family Room',
	'Living Room',
	'Dining Room',
	'Den',
	'Sitting Room',
	'Kitchen',
	'Hallway',
	'Stairway',
	'Stairs',
	...bedroomsAndBathrooms,
	'Laundry Room',
	'Office',
	'Storage Room',
	'Safety',
	'Structure',
	'Basement',
	'Attic',
	'Loft',
	'Mechanical',
	'Garage',
	'Carport',
	'General',
	'Back Door',
	'Patio',
	'Balcony',
	'Exterior',
	'Pool',
	'Property Exterior & Grounds',
	'Multi-Unit Common Areas',
];

const kitchen = [
	'Stovetop',
	'Oven',
	'Dishwasher',
	'Fridge & Freezer',
	'Water and Ice Dispenser',
	'Kitchen Sink',
	'Under Kitchen Sink',
	'Garbage Disposal',
	'Kitchen Cabinets',
	'Kitchen Cabinets & Counters',
	'Kitchen Counters',
	'Microwave',
	'Kitchen Floor',
	'Kitchen Floor, Ceiling, Walls',
	'Kitchen Ceiling',
	'Kitchen Walls',
	'Kitchen Windows',
];

const diningRoom = [
	'Dining Room Floor, Ceiling, Walls',
	'Dining Room Ceiling',
	'Dining Room Walls',
	'Dining Room Windows',
	'Dining Room Floor',
];

const livingRoom = [
	'Living Room Floor, Ceiling, Walls',
	'Living Room Ceiling',
	'Living Room Walls',
	'Living Room Windows',
	'Living Room Floor',
];

const familyRoom = [
	'Family Room Floor, Ceiling, Walls',
	'Family Room Ceiling',
	'Family Room Walls',
	'Family Room Windows',
	'Family Room Floor',
];

const den = [
	'Den Floor, Ceiling, Walls',
	'Den Ceiling',
	'Den Walls',
	'Den Windows',
	'Den Floor',
];

const office = [
	'Office Floor, Ceiling, Walls',
	'Office Ceiling',
	'Office Walls',
	'Office Windows',
	'Office Floor',
];

const bedroom = [
	'Bedroom Door - Exterior and Interior',
	'Bedroom Floor, Ceiling, Walls',
	'Bedroom Ceiling',
	'Bedroom Walls',
	'Bedroom Windows',
	'Bedroom Floor',
	'Bedroom Closet',
];

const bathroom = [
	'Bathroom Door - Exterior and Interior',
	'Bathroom Ceiling',
	'Bathroom Floor, Ceiling, Walls',
	'Half Bathroom Floor, Ceiling, Walls',
	'Bathroom Walls',
	'Bathroom Floor',
	'Bathroom Toilet',
	'Half Bathroom Toilet',
	'Bathroom Shower and Tub',
	'Bathroom Fixtures',
	'Bathroom Sink(s)',
	'Bathroom Sink',
	'Half Bathroom Sink',
	'Under Bathroom Sink',
	'Bathroom Windows',
	'Bathroom Door - Exterior and Interior',
	'Bathroom Ceiling',
	'Bathroom Walls',
	'Bathroom Floor',
	'Bathroom Toilet',
	'Bathroom Sink',
	'Under Bathroom Sink',
	'Bathroom Windows',
];

const laundryRoom = [
	'Washer - Exterior and Interior',
	'Dryer - Exterior and Interior',
	'Washer and Dryer',
	'Laundry Room Floor, Ceiling, Walls',
	'Laundry Room Floor',
	'Laundry Room Ceiling',
	'Laundry Room Walls',
	'Laundry Room Windows',
];

const stairway = [
	'Stairway Steps, Ceiling, Walls',
	'Stairway Ceiling',
	'Stairway Walls & Handrail',
	'Stairway Windows',
	'Stairway Steps',
];

const hallway = [
	'Hallway Floor, Ceiling, Walls',
	'Hallway Walls',
	'Hallway Windows',
	'Hallway Floor',
];

const basement = [
	'Basement',
	'Basement Stairway',
	'Basement Ceiling',
	'Basement Floor',
	'Basement Windows',
	'Basement Walls',
];

const loft = [
	'Loft Floor, Ceiling, Walls',
	'Loft Ceiling',
	'Loft Walls',
	'Loft Windows',
	'Loft Floor',
];

const attic = [
	'Attic Floor, Ceiling/Roof, Walls',
	'Attic Ceiling/Roof',
	'Attic Walls',
	'Attic Windows',
	'Attic Floor',
];

const storageRoom = [
	'Storage Room Floor, Ceiling, Walls',
	'Storage Room Ceiling',
	'Storage Room Walls',
	'Storage Room Windows',
	'Storage Room Floor',
];

const utilityRoom = ['Water', 'Electric', 'Sewer'];

const garage = ['Garage Door', 'Garage Walls', 'Garage Ceiling'];

const general = [
	'Air Filter',
	'Air Filter - Step 1',
	'Air Filter - Step 2',
	'Carbon Monoxide Detector',
	'Fire Extinguisher',
	'General',
];

const exterior = [
	'Front Yard',
	'Front Porch',
	'Side Porch',
	'Backyard',
	'Back Porch or Deck',
	'Pool',
];

const buildingAreas = [
	'Building Exterior',
	'Grounds & Landscaping',
	'Fencing & Gates',
	'Building Signage',
	'Exterior Lighting',
	'Exterior Walkways',
	'Building Doors',
	'Exterior Stairs & Railings',
	'Building Exterior (Rear)',
	'Lobby',
	'Mail / Package / Storage Area',
	'Elevators',
	'Interior Hallways',
	'Interior Stairs & Railings',
	'Interior Lights',
	'Interior Signage',
	'Fire Extinguishers',
	'Shared Laundry Room(s)',
	'Exercise Room(s)',
	'Trash Room(s)',
	'Trash Chute(s)',
	'Boiler Room',
	'Balconies',
	'Patio(s)',
	'Porches',
	'Courtyard(s)',
	'Playground Area(s)',
	'Roof Deck',
	'Windows/Screens',
	'Roof & Gutters',
	'Exterior Trash Area(s)',
	'Parking Areas',
	'Pool(s)',
	'Maintenance Shop',
];

export default {
	unitAreas,
	buildingAreas,
	kitchen,
	diningRoom,
	livingRoom,
	familyRoom,
	den,
	office,
	bedroom,
	bathroom,
	laundryRoom,
	stairway,
	hallway,
	basement,
	loft,
	attic,
	storageRoom,
	utilityRoom,
	garage,
	general,
	exterior,
};
