import {
	FormControl,
	FormHelperText,
	MenuItem,
	Select,
	Typography,
} from '@mui/material';
import { Row, Spacer } from 'components';
import { OccupancyT, OkToEnterT } from '.';

interface Props {
	occupancy: OccupancyT;
	value: OkToEnterT;
	setValue: (value: OkToEnterT) => void;
}

const OkToEnter = ({ occupancy, value, setValue }: Props) => {
	if (occupancy !== 'Occupied') {
		if (value !== 'No') {
			setValue('No');
		}

		return null;
	}

	return (
		<>
			<Row>
				<Typography variant="h6">Ok to enter</Typography>
			</Row>

			<Spacer height={2} />

			<FormControl fullWidth>
				<Select
					variant="filled"
					value={value}
					onChange={(e) => setValue(e.target.value as OkToEnterT)}>
					<MenuItem id="No" value="No">
						No
					</MenuItem>
					<MenuItem id="Yes" value="Yes">
						Yes
					</MenuItem>
				</Select>
				<FormHelperText>
					This is the work order field ‘Ok to Enter’ in Yardi.
				</FormHelperText>
			</FormControl>

			<Spacer height={4} />
		</>
	);
};

export default OkToEnter;
