import { Card, CardContent } from '@mui/material';
import { TemplateQuestion, TemplateSection } from '@rentcheck/types';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import { featureDisplayName } from 'utils/helpers';

import SelectSections from '../select-sections';
import SelectAnswers from '../../../../shared-components/select-answers';
import SelectQuestion from '../../../../shared-components/select-question';

import { BulkTemplateSectionLogic } from '../../bulk-modal';

interface Props {
	sections: TemplateSection[];
	logic: BulkTemplateSectionLogic;
	onUpdate: (logic: BulkTemplateSectionLogic) => void;
}

export default ({ sections, logic, onUpdate }: Props) => {
	const [questions, setQuestions] = useState<TemplateQuestion[]>([]);
	const [question, setQuestion] = useState<TemplateQuestion>();

	useEffect(() => {
		const questions = _.sortBy(
			sections.flatMap((s) =>
				s.features.flatMap((f) =>
					f.questions
						.filter((q) => q.type === 'yes/no' || q.type === 'multiple choice')
						.map((q) => ({
							...q,
							title: `${featureDisplayName({ room: s.name, feature: f.name }, ': ')}: ${q.title}`,
						}))
				)
			),
			'title'
		);

		setQuestions(questions);
	}, []);

	useEffect(() => {
		if (!questions.length) {
			return;
		}

		setQuestion(questions.find((q) => q.id === logic.trigger_id));
	}, [questions, logic.trigger_id]);

	const handleUpdateTriggerId = (triggerId?: string) => {
		if (triggerId === logic.trigger_id) {
			return;
		}

		onUpdate({ ...logic, trigger_id: triggerId ?? '', condition_value: [] });
	};

	const handleUpdateConditionValue = (value: string[]) => {
		onUpdate({
			...logic,
			condition_value: value,
		});
	};

	const handleUpdateSections = (sections: TemplateSection[]) => {
		onUpdate({ ...logic, sections });
	};

	/**
	 * We need to wait until we can find the question that
	 * matches this trigger id
	 */
	if (logic.trigger_id && !question) {
		return null;
	}

	return (
		<Card sx={{ marginBottom: 2 }}>
			<CardContent sx={{ display: 'flex', p: 2, flexDirection: 'column' }}>
				<SelectQuestion
					question={question}
					questions={questions}
					onUpdateTriggerId={handleUpdateTriggerId}
				/>

				<SelectAnswers
					question={question}
					conditionValue={logic.condition_value}
					onUpdateConditionValue={handleUpdateConditionValue}
				/>

				<SelectSections
					question={question}
					sections={logic.sections}
					allSections={sections}
					onUpdateSections={handleUpdateSections}
				/>
			</CardContent>
		</Card>
	);
};
