import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DashboardData } from '@rentcheck/api-frontend';

import CircularProgress from './components/circular-progress';
import Container from './components/container';
import ItemSkeleton from './components/skeleton';
import Tooltip from './components/tooltip';

interface Props {
	data: DashboardData | null;
	loading?: boolean;
}

const RevisionRate = ({ data, loading }: Props) => {
	if (!data) {
		return <ItemSkeleton />;
	}

	if (data.stats.overall_total_inspections < 10) {
		return null;
	}

	const handleCta = () => {
		window.open(
			'https://help.getrentcheck.com/en/articles/6574817-the-rentcheck-dashboard'
		);
	};

	const progressValue =
		data.stats.total_rejected_inspections /
		Math.max(data.stats.total_requested_inspections, 1);

	return (
		<Container
			loading={loading}
			title="Revision Request Rate"
			subtitle={`Requested Inspections: ${data.stats.total_requested_inspections}`}>
			<CircularProgress progress={progressValue} />
			<Tooltip
				title="What’s this?"
				body="The percentage of requested inspections with a due date in a given date range that have had revisions requested."
				cta="Learn More"
				handleCta={handleCta}>
				<FontAwesomeIcon
					icon={solid('info-circle')}
					color="#8F9FB2"
					size="sm"
				/>
			</Tooltip>
		</Container>
	);
};

export default RevisionRate;
