import React from 'react';
import styled from 'styled-components';
import { colors } from '../../theme';

interface Props {
	setFile: (file: File | null) => void;
	file: File | null;
}

const Uploader = ({ setFile, file }: Props) => {
	const ref = React.useRef(document.createElement('input'));

	return (
		<>
			<ClickableText
				underlined={file ? false : true}
				onClick={() => {
					if (!file) {
						ref.current.click();
					}
				}}>
				{file ? file.name : 'Browse to select a file'}
			</ClickableText>
			<input
				type="file"
				accept=".csv"
				ref={ref}
				hidden
				onChange={(event) => {
					setFile(event.target.files && event.target.files[0]);
				}}
			/>
		</>
	);
};

const ClickableText = styled.a<{ underlined?: boolean }>`
	color: ${({ underlined }) => (underlined ? colors.primary : colors.black)};
	${({ underlined }) => (underlined ? `text-decoration: underline;` : ``)};
	&:hover {
		${({ underlined }) => (underlined ? `cursor: pointer;` : ``)};
	}
`;

export default Uploader;
