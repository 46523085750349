import { Typography } from '@mui/material';
import { ApiTeam } from '@rentcheck/types';
import { Spacer } from 'components';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
	team: ApiTeam;
}

const MenuItem = ({ team }: Props) => {
	const location = useLocation();

	const route = `/account/teams/${team.id}`;

	const selected = location.pathname.startsWith(route);

	return (
		<Content to={route} selected={selected}>
			<Image src={team.logo ?? ''} />
			<Spacer width={2} />
			<Typography fontWeight={600} variant="subtitle2" color="#4d4d4d">
				{team.internal_label}
			</Typography>
		</Content>
	);
};

const Content = styled(Link)<{ selected: boolean }>`
	display: flex;
	flex-direction: row;
	align-items: center;
	transition: background-color 0.3s;
	padding: 15px 35px;
	cursor: pointer;
	color: #000;

	&:hover {
		background-color: #232e3a08;
	}

	${({ selected }) => selected && `background-color: #232e3a0d;`}
`;

const Image = styled.img`
	min-width: 22px;
	max-width: 22px;
	max-height: 22px;
	border-radius: 4px;
	object-fit: contain;
`;

export default MenuItem;
