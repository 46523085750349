import { Column, DialogTitle, Row, Spacer } from 'components';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Typography,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { ModalFlowActions } from 'store/actions';
import { ErrorModalData } from 'store/reducers/modal-flows';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { colors } from 'theme';

interface Props {
	open: boolean;
}

const ConfirmationModal = ({ open }: Props) => {
	const dispatch = useDispatch();

	const errorModalData = useTypedSelector(
		(state) => state.modalFlows.error_modal as ErrorModalData
	);

	const handleClose = () => {
		dispatch(ModalFlowActions.closeErrorModal());
	};

	if (!errorModalData) {
		return null;
	}

	return (
		<Dialog open={open} maxWidth="xs" onClose={handleClose}>
			<DialogTitle title={errorModalData.title} />

			<DialogContent>
				{errorModalData.errors.map((e) => (
					<Row>
						<FontAwesomeIcon
							size="xl"
							icon={e.type === 'sms' ? regular('mobile-notch') : regular('at')}
						/>
						<Spacer width={4} />
						<Column>
							<Typography>{e.destination}</Typography>
							<Typography color={colors.error} fontSize={12}>
								{e.reason}
							</Typography>
						</Column>
					</Row>
				))}
			</DialogContent>

			<DialogActions
				sx={{
					display: 'flex',
					justifyContent: 'center',
				}}>
				<Button id={`error-modal-ok`} onClick={handleClose}>
					Ok
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmationModal;
