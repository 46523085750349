import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Rules } from '@rentcheck/biz';
import { ApiInspection } from '@rentcheck/types';
import IconButton from 'components/icon-button';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';
import { Dispatch } from 'types';

interface Props {
	inspection: ApiInspection;
}

export default ({ inspection }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const [copied, setCopied] = useState<boolean>(false);

	const handleCopy = () => {
		if (!navigator.clipboard) return;

		navigator.clipboard.writeText(
			`https://rentcheck.app.link/app?$deeplink_path=inspections/${inspection.id}`
		);
		setCopied(true);

		dispatch(
			SnackbarActions.showSuccess('The link was copied to your clipboard')
		);

		setTimeout(() => {
			setCopied(false);
		}, 3000);
	};

	if (!Rules.Inspections.canCopyInspectionDeeplink(inspection)) {
		return null;
	}

	return (
		<IconButton
			icon={regular('link')}
			color={copied ? 'success' : undefined}
			onClick={handleCopy}
			tooltip={
				copied
					? 'Copied'
					: 'Copy inspection link to share with recipients and teammates'
			}
		/>
	);
};
