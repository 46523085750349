import { Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

interface Props {
	count: number;
}

const ReviewMarker = ({ count }: Props) => {
	if (count > 0) {
		return <Typography>Review</Typography>;
	}

	return (
		<FontAwesomeIcon
			icon={regular('check-circle')}
			color="#36B45F"
			style={{
				width: 20,
				height: 19,
			}}
		/>
	);
};

export default ReviewMarker;
