import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AccordionSummary, CardContent, Typography } from '@mui/material';
import { Column, Row, SpacedRow, Spacer } from 'components';
import React from 'react';
import { useHistory } from 'react-router-dom';

import ActiveChip from './active-chip';
import BetaChip from './beta-chip';
import ComingSoonChip from './coming-soon-chip';
import LegacyChip from './legacy-chip';

interface Props {
	icon: string;
	title: string;
	body: string;
	route: string;
	active: boolean;
	isBeta?: boolean;
	isComingSoon?: boolean;
	isLegacy?: boolean;
	extra?: React.ReactNode;
}

const Header = ({
	icon,
	title,
	body,
	route,
	active,
	isBeta,
	isComingSoon,
	isLegacy,
	extra,
}: Props) => {
	const history = useHistory();

	const showComingSoon = isComingSoon && !active;
	const showBeta = isBeta && (active || (!active && !isComingSoon));

	return (
		<AccordionSummary
			sx={{ marginRight: 2 }}
			expandIcon={
				<FontAwesomeIcon icon={regular('chevron-down')} color="#8F9FB2" />
			}
			onClick={() => history.push(route)}>
			<CardContent style={{ flex: 1 }}>
				<Row>
					<img
						style={{ width: 70, height: 70, objectFit: 'contain' }}
						src={icon}
					/>
					<Spacer width={8} />

					<Column style={{ flex: 1 }}>
						<SpacedRow>
							<Typography variant="h5">{title}</Typography>
							<Row>
								<LegacyChip active={isLegacy} title={title} />
								<BetaChip active={showBeta} title={title} />
								<ComingSoonChip active={showComingSoon} title={title} />
								<ActiveChip active={active} title={title} />
							</Row>
						</SpacedRow>
						<Spacer height={3} />
						<Typography>{body}</Typography>
						{extra}
					</Column>
				</Row>
			</CardContent>
		</AccordionSummary>
	);
};

export default Header;
