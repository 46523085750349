import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, IconButton, TableCell, TableRow } from '@mui/material';
import { APIBuilding } from '@rentcheck/types';
import { useHistory } from 'react-router-dom';

import LastCompletedInspection from '../../common/last-completed-inspection';
import ActionsMenu from './actions-menu';

import { Utils } from '@rentcheck/biz';

interface Props {
	building: APIBuilding;
	selectedIds: string[];
	setSelectedIds: (ids: string[]) => void;
	selectId: (id: string) => void;
}

const BuildingTableRow = ({
	building,
	selectedIds,
	setSelectedIds,
	selectId,
}: Props) => {
	const history = useHistory();

	const handleClick = () => {
		history.push(`/properties/${building.id}`);
	};

	const stillInSync = Utils.Properties.propertyIsStillInSync(building);

	return (
		<TableRow
			sx={{
				'&:last-child td, &:last-child th': { border: 0 },
				cursor: 'pointer',
			}}>
			<TableCell>
				<Checkbox
					id={`checkbox-building-${building.id}`}
					size="small"
					checked={selectedIds.includes(building.id)}
					onChange={() => selectId(building.id)}
				/>
			</TableCell>
			<TableCell onClick={handleClick}>{building.name}</TableCell>
			<TableCell onClick={handleClick}>{building.address}</TableCell>
			<TableCell onClick={handleClick}>{building.city}</TableCell>
			<TableCell onClick={handleClick}>{building.zip_code}</TableCell>
			<TableCell onClick={handleClick}>
				{building.team?.internal_label}
			</TableCell>
			<LastCompletedInspection property={building} onClick={handleClick} />
			<TableCell align="center">
				<ActionsMenu
					setBuildingIds={setSelectedIds}
					buildingIds={[building.id]}
					disableDeletion={stillInSync}>
					<IconButton
						id={`action-building-${building.id}`}
						size="small"
						sx={{ width: 28, marginLeft: -2 }}>
						<FontAwesomeIcon icon={solid('ellipsis-v')} color="gray" />
					</IconButton>
				</ActionsMenu>
			</TableCell>
		</TableRow>
	);
};

export default BuildingTableRow;
