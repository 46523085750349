import { Box } from '@mui/material';
import { ApiInspection } from '@rentcheck/types';
import { useMemo } from 'react';
import { useTypedSelector } from 'store/reducers/rootReducer';

import InspectionLabel from './inspection-label';

interface Props {
	inspection: ApiInspection;
}

export default ({ inspection }: Props) => {
	const teams = useTypedSelector((state) => state.teams);

	const templateInternalLabel = useMemo(() => {
		if (
			inspection.inspection_template.name ===
			inspection.inspection_template.internal_label
		) {
			return undefined;
		}

		if (teams.some((t) => t.id === inspection.team?.id)) {
			return inspection.inspection_template.internal_label;
		}

		return undefined;
	}, [inspection, teams]);

	return (
		<>
			{!!templateInternalLabel && <Box mb={1}>{templateInternalLabel}</Box>}
			<InspectionLabel inspection={inspection} />
		</>
	);
};
