import { Paper } from '@mui/material';
import { Row, SearchBar } from 'components';
import { useDispatch } from 'react-redux';
import { TeammatesActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';

export default () => {
	const dispatch = useDispatch();

	const filters = useTypedSelector(
		(state) => state.teammates.permissionGroupsFilters
	);

	const handleSearchChanged = (value: string) => {
		dispatch(TeammatesActions.PermissionGroups.setFilters({ search: value }));
	};

	return (
		<Paper
			elevation={0}
			sx={{
				borderRadius: 2,
				border: '1px solid rgba(0, 0, 0, 0.12)',
				p: 1.5,
				mt: 2,
			}}>
			<Row style={{ justifyContent: 'flex-end' }}>
				<div />
				<SearchBar value={filters.search} onChange={handleSearchChanged} />
			</Row>
		</Paper>
	);
};
