import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';

import { colors } from '@rentcheck/theme';

interface Props {
	image: string;
}

export default ({ image }: Props) => {
	if (!image.includes('revision')) return null;

	return (
		<div
			style={{
				height: 32,
				width: 32,
				borderRadius: 32,
				marginLeft: 8,
				backgroundColor: '#FFFFFFBD',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}>
			<Tooltip title="Image uploaded during revision">
				<FontAwesomeIcon
					icon={solid('pen-swirl')}
					color={colors.primary.default}
				/>
			</Tooltip>
		</div>
	);
};
