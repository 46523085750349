import { AppFolioIntegration } from '@rentcheck/types';
import { IntegrationsApi } from 'api';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { SnackbarActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';

export const useAppFolioIntegration = () => {
	const dispatch: Dispatch = useDispatch();

	const subscription = useTypedSelector((state) => state.subscription);

	const [appFolioIntegration, setAppfolioIntegration] = useState<
		AppFolioIntegration | undefined
	>(undefined);
	const [loadingAppFolioIntegration, setLoadingAppFolioIntegration] =
		useState(true);

	useEffect(() => {
		if (!subscription) {
			setLoadingAppFolioIntegration(false);
			return;
		}

		setLoadingAppFolioIntegration(true);

		IntegrationsApi.getAppFolioIntegrationForSubscriptionId(subscription.id)
			.then((data) => setAppfolioIntegration(data))
			.catch(() => dispatch(SnackbarActions.showError()))
			.finally(() => setLoadingAppFolioIntegration(false));
	}, [subscription]);

	return {
		loading: loadingAppFolioIntegration,
		appFolioIntegration,
	};
};
