import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, CardContent, Typography } from '@mui/material';
import { BillingModalOption } from '@rentcheck/types';

interface Props {
	features: BillingModalOption['features'];
}

export default ({ features }: Props) => {
	if (!features.length) return null;

	return (
		<CardContent sx={{ p: 2, paddingBottom: '16px !important' }}>
			<Typography variant="subtitle2" color="#4D4D4D">
				Features
			</Typography>

			{features.map((f) => (
				<>
					<Box
						mt={2}
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
						}}>
						<FontAwesomeIcon icon={solid('check')} color="#01D44F" />
						<Typography ml={1}>{f.name}</Typography>
					</Box>

					{f.sub_features?.map((sf) => (
						<Typography color="#4D4D4D" ml={3} mt={1}>
							{' • '}
							{sf.name}
						</Typography>
					))}
				</>
			))}
		</CardContent>
	);
};
