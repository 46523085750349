import { WorkOrder } from '@rentcheck/api-frontend';

import IntegrationDetails from './common';

interface Props {
	workOrder: WorkOrder;
}

const AppFolio = ({ workOrder }: Props) => {
	if (!workOrder.appfolio) {
		return null;
	}

	const details = [
		{
			title: 'Job Description:',
			value: workOrder.appfolio.job_description,
		},
		{
			title: 'Vendor Trade:',
			value: workOrder.appfolio.vendor_trade,
		},
		{
			title: 'Priority:',
			value: workOrder.appfolio.priority,
		},
		{
			title: 'Ok to enter:',
			value: workOrder.appfolio.ok_to_enter ? 'Yes' : 'No',
		},
	];

	return <IntegrationDetails details={details} />;
};

export default AppFolio;
