import React from 'react';
import moment from 'moment';
import { Popover, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/reducers/rootReducer';
import {
	FilterTitle,
	FilterTitleOption,
	FilterDetailsProps,
	FilterTitleProps,
	FilterType,
} from 'components/lists/filters';
import { Column, Row, Spacer } from 'components';
import { Calendar, DayRange, DayValue } from 'react-modern-calendar-datepicker';
import { colors, spacingPx } from 'theme';
import { DateRange } from 'store/reducers/inspections/filters';
import styled from 'styled-components';

const title: FilterType = 'Last Inspection Completed Date';

const dateRanges = {
	threeMonthsAgo: {
		start: moment().startOf('day').subtract(100, 'years').valueOf(),
		end: moment().subtract(3, 'month').valueOf(),
	},
	sixMonthsAgo: {
		start: moment().startOf('day').subtract(100, 'years').valueOf(),
		end: moment().subtract(6, 'month').valueOf(),
	},
};

const dayValueFromTimestamp = (timestamp: number): DayValue => {
	const date = moment(timestamp);
	return { day: date.date(), month: date.month() + 1, year: date.year() };
};

const dayRangeFromDateRange = (dateRange?: DateRange): DayRange => {
	if (!dateRange?.start) {
		return { from: null, to: null };
	}

	if (!dateRange.end) {
		return {
			from: dayValueFromTimestamp(dateRange.start),
			to: null,
		};
	}

	return {
		from: dayValueFromTimestamp(dateRange.start),
		to: dayValueFromTimestamp(dateRange.end),
	};
};

const timestampFromDayValue = (dayValue: DayValue) => {
	if (!dayValue) return 0;

	const date = { ...dayValue };
	date.month = date.month - 1;

	const momentDate = moment(date);
	momentDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

	return momentDate.valueOf();
};

const FilterInspectionDateTitle = ({
	selectedFilter,
	setFilterDetails,
}: FilterTitleProps) => {
	const dueDateFilter = useTypedSelector(
		(state) => state.properties.filters.communities.dateRange
	);

	return (
		<FilterTitle
			label={title}
			isActive={!!dueDateFilter}
			isSelected={selectedFilter === title}
			onClick={() => setFilterDetails(title)}
		/>
	);
};

const FilterInspectionDateDetails = ({
	selectedFilter,
}: FilterDetailsProps) => {
	const dispatch = useDispatch();

	const dueDateFilter = useTypedSelector(
		(state) => state.properties.filters.communities.dateRange
	);

	const [calendarAnchor, setCalendarAnchor] =
		React.useState<HTMLButtonElement | null>(null);

	if (selectedFilter !== title) {
		return null;
	}

	const handleThisWeek = () => {
		dispatch({
			type: 'SET_COMMUNITIES_DATE_RANGE_FILTER',
			dateRange: dateRanges.threeMonthsAgo,
		});
	};

	const handleThisMonth = () => {
		dispatch({
			type: 'SET_COMMUNITIES_DATE_RANGE_FILTER',
			dateRange: dateRanges.sixMonthsAgo,
		});
	};

	const handleCustomDateRange = (e: React.MouseEvent<HTMLButtonElement>) => {
		setCalendarAnchor(e.currentTarget);
	};

	const handleCustomDateRangeChanged = (value: DayRange) => {
		const start = timestampFromDayValue(value.from);
		const end = timestampFromDayValue(value.to);

		dispatch({
			type: 'SET_COMMUNITIES_DATE_RANGE_FILTER',
			dateRange: { start, end },
		});
	};

	const handleClear = () => {
		setCalendarAnchor(null);
		dispatch({
			type: 'SET_COMMUNITIES_DATE_RANGE_FILTER',
			dateRange: undefined,
		});
	};

	const handleOk = () => {
		setCalendarAnchor(null);
	};

	const dateRangeString = () => {
		const components: string[] = [];

		if (dueDateFilter?.start) {
			components.push(moment(dueDateFilter.start).format('M/D/YYYY'));
		}

		if (dueDateFilter?.end) {
			components.push(moment(dueDateFilter.end).format('M/D/YYYY'));
		}

		return components.join(' - ');
	};

	const thisWeekSelected =
		dueDateFilter?.start === dateRanges.threeMonthsAgo.start &&
		dueDateFilter?.end === dateRanges.threeMonthsAgo.end;

	const thisMonthSelected =
		dueDateFilter?.start === dateRanges.sixMonthsAgo.start &&
		dueDateFilter?.end === dateRanges.sixMonthsAgo.end;

	const customRangeSelected =
		!!dueDateFilter?.start && !thisWeekSelected && !thisMonthSelected;

	const customDateSubtitle = customRangeSelected
		? dateRangeString()
		: undefined;

	return (
		<>
			<FilterTitleOption
				label={'3+ Months Ago'}
				isSelected={thisWeekSelected}
				onClick={handleThisWeek}
			/>
			<FilterTitleOption
				label={'6+ Months Ago'}
				isSelected={thisMonthSelected}
				onClick={handleThisMonth}
			/>
			<FilterTitleOption
				label={'Custom Date Range'}
				subtitle={customDateSubtitle}
				isSelected={customRangeSelected}
				onClick={handleCustomDateRange}
			/>

			<Popover
				open={!!calendarAnchor}
				anchorEl={calendarAnchor}
				onClose={() => setCalendarAnchor(null)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				sx={{ padding: 1 }}>
				<Column>
					<Calendar
						value={dayRangeFromDateRange(dueDateFilter)}
						onChange={handleCustomDateRangeChanged}
						colorPrimary={colors.primary}
						colorPrimaryLight={colors.primaryLight}
					/>
					<ButtonsRow>
						<Button variant="contained" onClick={handleClear}>
							Clear
						</Button>
						<Spacer width={2} />
						<Button variant="contained" onClick={handleOk}>
							Ok
						</Button>
					</ButtonsRow>
				</Column>
			</Popover>
		</>
	);
};

const ButtonsRow = styled(Row)`
	justify-content: flex-end;
	padding: 0 ${spacingPx(6)} ${spacingPx(4)} ${spacingPx(6)};
`;

export { FilterInspectionDateDetails, FilterInspectionDateTitle };
