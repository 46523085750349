import { ApiTenant } from '@rentcheck/types';
import { useTypedSelector } from 'store/reducers/rootReducer';
import Active from './active';
import Inactive from './inactive';

interface Props {
	resident: ApiTenant;
}

export default ({ resident }: Props) => {
	const filters = useTypedSelector((state) => state.tenants.filters);
	if (filters.active_only) {
		return <Active resident={resident} />;
	}

	return <Inactive resident={resident} />;
};
