import { useState } from 'react';
import {
	TextField,
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material';

import { Column, Spacer } from 'components';

interface Props {
	name: string;
	internalLabel: string;
	setName: (name: string) => void;
	setInternalLabel: (label: string) => void;
	nextStep: () => void;
	cancel: () => void;
}

const TeamName = ({
	name,
	internalLabel,
	setName,
	setInternalLabel,
	nextStep,
	cancel,
}: Props) => {
	const [nameValue, setNameValue] = useState(name);
	const [internalLabelValue, setInternalLabelValue] = useState(internalLabel);

	const disabled = !nameValue || !internalLabelValue;

	return (
		<>
			<DialogTitle>Create Team</DialogTitle>

			<DialogContent>
				<Column>
					<TextField
						id="textinput-name"
						variant="filled"
						placeholder="Name (used in resident communications & usually company name)"
						onChange={(e) => setNameValue(e.target.value)}
						value={nameValue}
					/>
					<Spacer height={6} />

					<TextField
						id="textinput-internal_label"
						variant="filled"
						placeholder="Internal Label (internal name displayed to you and team members)"
						onChange={(e) => setInternalLabelValue(e.target.value)}
						value={internalLabelValue}
					/>
					<Spacer height={10} />
				</Column>
			</DialogContent>

			<DialogActions>
				<Button variant="outlined" onClick={cancel}>
					Back
				</Button>

				<Button
					disabled={disabled}
					onClick={() => {
						setName(nameValue);
						setInternalLabel(internalLabelValue);
						nextStep();
					}}>
					Next
				</Button>
			</DialogActions>
		</>
	);
};

export default TeamName;
