import {
	Autocomplete,
	Box,
	Divider,
	FilterOptionsState,
	InputAdornment,
	Paper,
	TextField,
	Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApiTeamDetails, TeamUserInfo } from '@rentcheck/types';
import { Row, Spacer } from 'components';
import { AssignedTeammates } from 'screens/modal-flows-controller/create-inspection/common/assigned-teammates';
import { numberAndPluralizeWord } from 'utils/helpers';
import SelectAll from './select-all';

interface Props {
	team: ApiTeamDetails;
	assignedTeammates: AssignedTeammates;
	setAssignedTeammates: (teammates: AssignedTeammates) => void;
}

export default ({ team, assignedTeammates, setAssignedTeammates }: Props) => {
	const [assignedTeammatesForTeam, setAssignedTeammatesForTeam] = useState<
		TeamUserInfo[]
	>([]);
	const [inputValue, setInputValue] = useState('');

	useEffect(() => {
		setAssignedTeammatesForTeam(assignedTeammates[team.id] ?? []);
	}, [team, assignedTeammates]);

	const filterOptions = (
		options: TeamUserInfo[],
		state: FilterOptionsState<TeamUserInfo>
	) => {
		const searchTerm = state.inputValue.toLowerCase();

		return options.filter((o) => {
			return (
				o.user_name.toLowerCase().includes(searchTerm) ||
				o.email.toLowerCase().includes(searchTerm)
			);
		});
	};

	const handleOnChange = (value: TeamUserInfo[]) => {
		const newAssignedTeammates = { ...assignedTeammates };
		newAssignedTeammates[team.id] = value;

		setAssignedTeammates(newAssignedTeammates);
	};

	return (
		<>
			<Spacer height={2} />

			<Autocomplete
				multiple
				fullWidth
				disablePortal
				disableCloseOnSelect
				sx={{ height: 56 }}
				options={team.users}
				filterOptions={filterOptions}
				onInputChange={(_, v) => setInputValue(v)}
				value={assignedTeammatesForTeam}
				isOptionEqualToValue={(option, value) => option.email === value.email}
				getOptionLabel={(option) => option.email}
				inputValue={inputValue}
				onChange={(_, v) => handleOnChange(v)}
				renderTags={(value) => (
					<Row>
						<Spacer width={2} />
						<Typography color="#666666">
							{numberAndPluralizeWord(value.length, 'Teammate')} selected
						</Typography>
					</Row>
				)}
				renderInput={(params) => (
					<TextField
						{...params}
						label="Select teammates"
						InputProps={{
							...params.InputProps,
							startAdornment: (
								<InputAdornment position="start">
									<FontAwesomeIcon icon={regular('users')} color="#666666" />
									{params.InputProps.startAdornment}
								</InputAdornment>
							),
						}}
					/>
				)}
				PaperComponent={(PaperProps) => {
					const { children, ...restPaperProps } = PaperProps;

					return (
						<Paper {...restPaperProps}>
							<Box onMouseDown={(e) => e.preventDefault()}>
								<SelectAll
									teammates={team.users}
									team={team}
									assignedTeammates={assignedTeammates}
									setAssignedTeammates={setAssignedTeammates}
								/>
							</Box>

							<Divider />

							{children}
						</Paper>
					);
				}}
			/>

			<Spacer height={4} />
		</>
	);
};
