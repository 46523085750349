import {
	Badge,
	MenuItem,
	Paper,
	SxProps,
	Theme,
	Typography,
} from '@mui/material';
import 'react-phone-input-2/lib/style.css';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { TemplateSection } from '@rentcheck/types';
import { Column, Row } from 'components';
import FormTextField from 'components/form-text-field';
import IconButton from 'components/icon-button';
import { useEffect, useRef, useState } from 'react';
import {
	Draggable,
	DraggingStyle,
	NotDraggingStyle,
} from 'react-beautiful-dnd';
import { InitializedTemplateSection } from 'screens/modal-flows-controller/inspection-template/helpers/sections';
import { colors } from 'theme';
import { useOnClickOutside } from 'usehooks-ts';
import { numberAndPluralizeWord } from 'utils/helpers';

import SectionLogicModal from './section-logic/modal';

import { containsIncompleteFeatures } from '../features/helpers';
import { shouldIgnoreClick } from '../helpers/click-outside';

interface Props {
	section: InitializedTemplateSection;
	sections: InitializedTemplateSection[];
	index: number;
	editing: boolean;
	onDelete: (section: TemplateSection) => void;
	onUpdate: (section: TemplateSection) => void;
	onSelect: (section: TemplateSection) => void;
	selected?: boolean;
}

const getItemStyle = (
	section: TemplateSection,
	selected?: boolean,
	draggableStyle?: DraggingStyle | NotDraggingStyle
): SxProps<Theme> => ({
	mb: 1,
	minHeight: 54,
	paddingLeft: 4,
	paddingRight: 2,
	paddingTop: 1,
	paddingBottom: 1,
	backgroundColor: section.type === 'room' ? '#f6f5ff' : '#E9E8FD',
	border: selected ? '1px solid #2D3CE6' : '1px solid #0000001F',
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	alignItems: 'center',
	transition: 'elevation 0.2s ease-in-out',
	cursor: 'pointer',
	opacity: section.features.length === 0 ? 0.6 : 1,

	// styles we need to apply on draggables
	...(draggableStyle as any),
});

export default ({
	section,
	sections,
	index,
	editing,
	onDelete,
	onUpdate,
	onSelect,
	selected,
}: Props) => {
	const [sectionLogicModalOpen, setSectionLogicModalOpen] = useState(false);
	const [editingThisSection, setEditingThisSection] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	const clickOutsideRef = useRef<HTMLDivElement>(null);

	useOnClickOutside(clickOutsideRef, (e) => {
		if (shouldIgnoreClick(e.target as any)) {
			return;
		}

		handleSave();
	});

	useEffect(() => {
		if (editing && !section.name) {
			setEditingThisSection(true);
		}
	}, [section]);

	const handleEditSection = () => {
		setEditingThisSection(true);
	};

	const handleDeleteSection = () => {
		onDelete(section);
	};

	const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
		onUpdate({ ...section, name: e.target.value });
	};

	const handleSelect = (e: React.MouseEvent<HTMLDivElement>) => {
		/**
		 * If the user didn't click on the card itself, but rather
		 * on the overflow menu or the text field don't select.
		 */
		const classList = (e.target as any).classList;
		if (
			!classList.contains('MuiPaper-root') &&
			!classList.contains('MuiTypography-root')
		) {
			return;
		}

		onSelect(section);
	};

	const handleSave = (e?: React.MouseEvent<HTMLButtonElement>) => {
		e?.preventDefault();
		e?.stopPropagation();

		if (isDuplicateName) {
			setErrorMessage('Section name must be unique');
			return;
		}

		if (!section.name) {
			if (section.features.length === 0) {
				handleDeleteSection();
				return;
			}

			if (section.features.length > 0) {
				/**
				 * If the user has added features to the section we
				 * can't delete it so we do nothing
				 */
				setErrorMessage('Required');
				return;
			}
		}

		setEditingThisSection(false);
	};

	const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			handleSave();
		}
	};

	const otherSections = sections.filter((s) => s.id !== section.id);
	const isDuplicateName = otherSections.some(
		(s) => s.name.trim() === section.name.trim()
	);

	return (
		<>
			<Draggable
				key={section.id}
				draggableId={section.id}
				isDragDisabled={!editing || editingThisSection}
				index={index}>
				{(provided, snapshot) => (
					<div ref={clickOutsideRef} style={{ position: 'relative' }}>
						{section.isNewSection && (
							<Badge
								badgeContent="New"
								sx={{
									position: 'absolute',
									right: 10,
									top: 5,
									span: {
										backgroundColor: colors.primary,
										color: colors.white,
									},
								}}
							/>
						)}
						<Paper
							onClick={handleSelect}
							ref={provided.innerRef}
							elevation={snapshot.isDragging ? 1 : 0}
							{...provided.draggableProps}
							{...provided.dragHandleProps}
							sx={getItemStyle(
								section,
								selected,
								provided.draggableProps.style
							)}>
							{!editingThisSection && (
								<>
									<Column>
										<Typography
											variant="subtitle2"
											sx={{ wordBreak: 'break-word' }}>
											{section.name}
										</Typography>
										<Typography variant="caption">
											{numberAndPluralizeWord(section.features, 'Feature')}
										</Typography>
									</Column>
									<Row>
										{section.features.length === 0 && (
											<IconButton icon={regular('eye-slash')} />
										)}
										{editing && (
											<>
												{section.logic.length > 0 && (
													<IconButton
														tooltip="Edit Logic"
														icon={regular('split')}
														onClick={() => setSectionLogicModalOpen(true)}
													/>
												)}

												<IconButton
													icon={regular('ellipsis-v')}
													disabled={containsIncompleteFeatures(sections)}>
													{section.type === 'section' && (
														<>
															<MenuItem onClick={handleDeleteSection}>
																Delete Section
															</MenuItem>
															<MenuItem onClick={handleEditSection}>
																Edit Section
															</MenuItem>
														</>
													)}
													<MenuItem
														onClick={() => setSectionLogicModalOpen(true)}>
														{section.logic.length ? 'Edit Logic' : 'Add Logic'}
													</MenuItem>
												</IconButton>
											</>
										)}
									</Row>
								</>
							)}
							{editing && editingThisSection && (
								<>
									<FormTextField
										autoFocus
										fullWidth
										required
										inputSx={{ mt: 0, mb: 0 }}
										value={section.name}
										onChange={handleChangeName}
										onKeyPress={handleKeyPress}
										error={!!errorMessage}
										helperText={errorMessage}
									/>
									<IconButton
										icon={regular('circle-check')}
										onClick={handleSave}
									/>
								</>
							)}
						</Paper>
					</div>
				)}
			</Draggable>
			<SectionLogicModal
				section={section}
				sections={sections}
				onUpdate={onUpdate}
				open={sectionLogicModalOpen}
				onClose={() => setSectionLogicModalOpen(false)}
			/>
		</>
	);
};
