import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Typography } from '@mui/material';
import { TemplateQuestion, TemplateQuestionAnswer } from '@rentcheck/types';
import { Row, SpacedRow } from 'components';
import FormTextField from 'components/form-text-field';
import ToggleButton from 'components/toggle-button';

interface Props {
	question: TemplateQuestion;
	onUpdate: (question: TemplateQuestion) => void;
	onSave: () => void;
}

export default ({ question, onUpdate, onSave }: Props) => {
	const handleUpdateQuestionTitle = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		onUpdate({ ...question, title: e.target.value });
	};

	const handleToggleRequiresPhoto = (answer: TemplateQuestionAnswer) => {
		const updatedAnswers = question.answers.map((a) => {
			if (a.id === answer.id) {
				return {
					...a,
					requires_photo: !a.requires_photo,
				};
			}

			return a;
		});

		onUpdate({
			...question,
			answers: updatedAnswers,
		});
	};

	const handleToggleRequiresNote = (answer: TemplateQuestionAnswer) => {
		const updatedAnswers = question.answers.map((a) => {
			if (a.id === answer.id) {
				return {
					...a,
					requires_note: !a.requires_note,
				};
			}

			return a;
		});

		onUpdate({
			...question,
			answers: updatedAnswers,
		});
	};

	const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			onSave();
		}
	};

	return (
		<>
			<FormTextField
				required
				autoFocus
				title="Question Text"
				value={question.title}
				placeholder="Add a question. Example: Are the walls free from any major cracks, holes, or stains?"
				onChange={handleUpdateQuestionTitle}
				onKeyPress={handleKeyPress}
			/>

			<Typography variant="overline" mb={2}>
				Answers
			</Typography>
			{question.answers.map((answer) => (
				<SpacedRow key={answer.title} style={{ marginBottom: 8 }}>
					<Row
						style={{
							flex: 1,
							height: 55,
							backgroundColor: '#21212114',
							borderRadius: 4,
						}}>
						<Typography variant="subtitle1" ml={2}>
							{answer.title}
						</Typography>
					</Row>
					<ToggleButton
						icon={regular('message-lines')}
						selected={answer.requires_note}
						tooltip={
							answer.requires_note
								? 'Notes required for this answer'
								: 'Notes not required for this answer'
						}
						onClick={() => handleToggleRequiresNote(answer)}
					/>
					{answer.title === 'No' && (
						<ToggleButton
							icon={regular('image')}
							selected={answer.requires_photo}
							tooltip={
								answer.requires_photo
									? 'Photo required for selection'
									: 'Photo not required for selection'
							}
							onClick={() => handleToggleRequiresPhoto(answer)}
						/>
					)}
				</SpacedRow>
			))}
		</>
	);
};
