import {
	ApiUserDigest,
	BulkEditUserPermissionGroupsParams,
	GetAllUsersV2Params,
	GetAllUsersV2Result,
	InviteUsersParams,
	InviteUsersResult,
	UpdateUserAccessParams,
} from '@rentcheck/types';
import _ from 'lodash';
import { State as FiltersState } from 'store/reducers/teammates/users-filters';
import { ReduxFn } from 'types';

import { TeamInvitesApi, UsersApi } from '@rentcheck/api-frontend';
import { Utils } from '@rentcheck/biz';
import * as SnackbarActions from '../snackbar-actions';

const defaultSort: GetAllUsersV2Params['options']['sort'] = {
	sort_by: 'user_name',
	sort_type: 'ASC',
};

const buildFilters = (
	filters: FiltersState
): GetAllUsersV2Params['filters'] => {
	const response: GetAllUsersV2Params['filters'] = {};

	if (filters.search) {
		response.search = filters.search;
	}

	if (filters.status) {
		response.status = filters.status;
	}

	if (filters.teamIds) {
		response.team_ids = filters.teamIds.join(',');
	}

	if (filters.permissionGroupIds) {
		response.permission_group_ids = filters.permissionGroupIds.join(',');
	}

	return response;
};

export const getAll = (
	offset: number = 0,
	filters: FiltersState,
	pageSize: number = 20
): ReduxFn<GetAllUsersV2Result> => {
	const fn: ReduxFn = async (dispatch) => {
		const pageNumber = Math.ceil(offset / pageSize);

		return UsersApi.getAll(
			pageNumber,
			pageSize,
			buildFilters(filters),
			defaultSort
		)
			.then((res) => {
				dispatch({
					type: offset === 0 ? 'SET_USERS' : 'APPEND_USERS',
					value: res.data,
					totalResults: res.total_results,
				});

				return res;
			})
			.catch((e) => {
				dispatch(SnackbarActions.showError(e));

				return {
					data: [],
					total_results: 0,
				};
			});
	};

	return fn;
};

export const setFilters = (value: Partial<FiltersState>): ReduxFn => {
	const fn: ReduxFn = async (dispatch) => {
		const tuples = _.toPairs(value);

		tuples.forEach((t) => {
			dispatch({
				type: 'UPDATE_USERS_FILTERS',
				key: t[0],
				value: t[1],
			});
		});
	};

	return fn;
};

export const deactivate = (id: string): ReduxFn => {
	const fn: ReduxFn = async (dispatch) =>
		UsersApi.deactivate(id)
			.then(() =>
				dispatch({
					type: 'DELETE_USERS',
					value: [{ id }],
				})
			)
			.catch((e) => dispatch(SnackbarActions.showError(e)));

	return fn;
};

export const invite = (params: InviteUsersParams['data']): ReduxFn<void> => {
	const fn: ReduxFn = async (dispatch, getState) =>
		UsersApi.invite(params)
			.then(async (result) => {
				const { success, failure } = result.data;

				await dispatch(
					getAll(
						0,
						getState().teammates.usersFilters,
						getState().teammates.users.data.length + params.emails.length
					)
				);

				if (success.users.length > 0) {
					dispatch(SnackbarActions.showSuccess('Invite sent successfully!'));
				}

				failure.errors.forEach((error) => {
					dispatch(
						SnackbarActions.showError(
							`Invite failed: ${error.email} (${error.error})`
						)
					);
				});
			})
			.catch((e) => {
				dispatch(SnackbarActions.showError(e));
			});

	return fn;
};

export const editPermissionGroups = (
	params: BulkEditUserPermissionGroupsParams['data']
): ReduxFn<InviteUsersResult> => {
	const fn: ReduxFn = async (dispatch) =>
		UsersApi.editPermissionGroups(params)
			.then((result) => {
				if (result.data.length > 0) {
					dispatch(
						SnackbarActions.showSuccess(
							`${Utils.Helpers.numberAndPluralizedWord(params.user_ids.length, 'user')} updated successfully!`
						)
					);

					dispatch({
						type: 'UPDATE_USERS',
						value: result.data,
					});
				}

				return result;
			})
			.catch((e) => {
				dispatch(SnackbarActions.showError(e));

				return;
			});

	return fn;
};

export const deleteAllInvitesForUser = (user: ApiUserDigest): ReduxFn => {
	const fn: ReduxFn = async (dispatch) =>
		TeamInvitesApi.deleteAllInvitesForUser(user.id)
			.then(() => dispatch({ type: 'DELETE_USERS', value: [user] }))
			.then(() =>
				dispatch(SnackbarActions.showSuccess('Invite deleted successfully'))
			)
			.catch((e) => dispatch(SnackbarActions.showError(e)));

	return fn;
};

export const updateAccess = (
	user: ApiUserDigest,
	data: UpdateUserAccessParams['data']
): ReduxFn => {
	const fn: ReduxFn = async (dispatch) =>
		UsersApi.updateAccess(user.id, data)
			.then((res) => {
				const message = `Success! You've updated access for ${user.name}`;
				dispatch(SnackbarActions.showSuccess(message));
				dispatch({ type: 'UPDATE_USERS', value: [res] });
			})
			.catch((e) => dispatch(SnackbarActions.showError(e)));

	return fn;
};
