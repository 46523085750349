import styled from 'styled-components';

import { Row } from 'components';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/reducers/rootReducer';
import ChipActive from './chip-active';
import ChipAll from './chip-all';

const QuickFilters = () => {
	const dispatch = useDispatch();

	const isActiveOnly = useTypedSelector(
		(state) => state.tenants.filters.active_only
	);

	useEffect(() => {
		dispatch({ type: 'SET_TENANTS_SEARCH_FILTER', value: '' });
	}, [isActiveOnly]);

	return (
		<WrappingRow>
			<ChipActive />
			<ChipAll />
		</WrappingRow>
	);
};

const WrappingRow = styled(Row)`
	flex-wrap: wrap;
`;

export default QuickFilters;
