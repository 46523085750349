import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
	Button,
	Link,
	Paper,
	Portal,
	Snackbar,
	Typography,
} from '@mui/material';
import { Rules, Utils } from '@rentcheck/biz';
import { ApiInspectionWithTemplate, Feature } from '@rentcheck/types';
import { Row } from 'components/layout/Row';
import ToggleButton from 'components/toggle-button';
import _ from 'lodash';
import { SyntheticEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	FeatureActions,
	ModalFlowActions,
	SnackbarActions,
} from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';

interface Props {
	feature: Feature;
	inspection: ApiInspectionWithTemplate;
	buttonSx?: React.CSSProperties;
	disabled?: boolean;
}

export default ({ feature, buttonSx, inspection, disabled }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const features = useTypedSelector((state) =>
		Utils.InspectionFeatures.sortFeaturesBySection(
			_.values(state.features[inspection.id] ?? {}),
			inspection.inspection_template
		).filter((f) => f.revision_requested)
	);

	const [loading, setLoading] = useState(false);
	const [snackbarOpen, setSnackbarOpen] = useState(false);

	const hasRevisionRequested = feature.revision_requested;

	const handleToggleRevisionRequested = async () => {
		setLoading(true);
		dispatch(
			FeatureActions.updateFeature(feature.id, {
				revision_requested: !hasRevisionRequested,
				revision_notes: '',
			})
		)
			.then(() => setSnackbarOpen(!hasRevisionRequested))
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	const handleHideSnackbar = (e: Event | SyntheticEvent<any, Event>) => {
		e.stopPropagation();
		setSnackbarOpen(false);
	};

	const handleCreateRevision = () => {
		setSnackbarOpen(false);

		dispatch(
			ModalFlowActions.showInspectionRevisionModal({ inspection, features })
		);
	};

	if (!Rules.Inspections.canReview(inspection)) {
		return null;
	}

	return (
		<>
			<ToggleButton
				selected={hasRevisionRequested}
				loading={loading}
				onClick={handleToggleRevisionRequested}
				buttonSx={buttonSx}
				icon={regular('pen-swirl')}
				disabled={disabled}
				tooltip={
					hasRevisionRequested
						? 'Remove from Revision Request'
						: 'Add to Revision Request'
				}
			/>

			<Portal>
				<Snackbar
					open={snackbarOpen}
					autoHideDuration={6000}
					onClose={handleHideSnackbar}>
					<Paper
						sx={{
							backgroundColor: 'black',
							padding: '14px 16px',
							maxWidth: 345,
							textAlign: 'left',
						}}>
						<Typography color="#fff">
							Feature added to your revision request queue.{' '}
						</Typography>
						<Typography color="#fff" sx={{ textWrap: 'wrap' }}>
							<Link color="#fff" onClick={handleCreateRevision}>
								Click 'Revision'
							</Link>{' '}
							when you are ready to reopen the inspection and send a revision
							request to {inspection.current_performer?.name}.
						</Typography>

						<Row style={{ justifyContent: 'flex-end', marginTop: 8 }}>
							<Button
								variant="text"
								color="success"
								sx={{ color: '#ACFFC6' }}
								onClick={handleHideSnackbar}>
								Done
							</Button>
						</Row>
					</Paper>
				</Snackbar>
			</Portal>
		</>
	);
};
