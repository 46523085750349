import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Divider,
	Typography,
} from '@mui/material';

import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Feature, MaintenanceFlag } from '@rentcheck/types';
import { Column, SpacedRow } from 'components';

import { Rules } from '@rentcheck/biz';
import { FlagChip } from 'components/features';
import {
	useFeatureDetailsModalData,
	useFeatureImageModalData,
} from 'hooks/modals';
import { MouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import { ModalFlowActions } from 'store/actions';

interface Props {
	feature: Feature;
	setHoveredFlag: (flag?: MaintenanceFlag) => void;
}

export default ({ feature, setHoveredFlag }: Props) => {
	const dispatch = useDispatch();

	/**
	 * This component is shown from 2 different modals, so we need to check
	 * both metadata sources to get the right info
	 */
	const featureDetailsModalData = useFeatureDetailsModalData();
	const featureImagesModalData = useFeatureImageModalData();

	const inspection =
		featureDetailsModalData?.metadata?.inspection ??
		featureImagesModalData?.metadata?.inspection;

	const handleAddFlag = (e: MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		e.preventDefault();

		dispatch(ModalFlowActions.showFlagFeatureModal({ feature, inspection }));
	};

	if (!inspection) return null;

	if (feature.not_applicable) return null;

	if (!Rules.Inspections.canPerformFeatureActions(inspection)) {
		return null;
	}

	return (
		<Accordion defaultExpanded disableGutters elevation={0}>
			<AccordionSummary
				expandIcon={<FontAwesomeIcon icon={solid('caret-down')} />}>
				<SpacedRow style={{ flex: 1 }}>
					<Typography variant="subtitle2">Flags</Typography>
					<Button
						variant="text"
						color="secondary"
						onClick={handleAddFlag}
						sx={{ marginRight: 1 }}
						startIcon={<FontAwesomeIcon icon={regular('plus')} />}>
						Add Flag
					</Button>
				</SpacedRow>
			</AccordionSummary>
			<AccordionDetails>
				<Divider sx={{ marginTop: -1, marginBottom: 2 }} />

				{!feature.maintenance_flags?.length && (
					<Typography variant="subtitle2" color="#00000099">
						No flags
					</Typography>
				)}

				<Column>
					{feature.maintenance_flags?.map((flag) => (
						<FlagChip
							key={flag.id}
							flag={flag}
							feature={feature}
							inspection={inspection}
							setHoveredFlag={setHoveredFlag}
						/>
					))}
				</Column>
			</AccordionDetails>
		</Accordion>
	);
};
