import { Typography } from '@mui/material';

import { ApiInspectionWithTemplate } from '@rentcheck/types';
import { Column } from 'components';

import Timeline from 'components/inspections/timeline';

interface Props {
	inspection: ApiInspectionWithTemplate;
}

export default ({ inspection }: Props) => {
	return (
		<Column>
			<Typography variant="subtitle2" mb={3}>
				Timeline Events:
			</Typography>
			<Timeline inspection={inspection} />
		</Column>
	);
};
