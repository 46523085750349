import { Utils } from '@rentcheck/biz';
import { MaintenanceFlag } from '@rentcheck/types';
import { FirebaseMediaDiv, Spacer, StartRow } from 'components';
import { useFeature } from 'hooks/flagged-features';
import { useDispatch } from 'react-redux';
import { ModalFlowActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import styled from 'styled-components';
import { spacing, spacingPx } from 'theme';

interface Props {
	flag: MaintenanceFlag;
}

const Photos = ({ flag }: Props) => {
	const dispatch = useDispatch();

	const inspection = useTypedSelector((state) => state.inspections.inspection);
	const feature = useFeature(inspection?.id ?? '', flag.room, flag.feature);

	const images = flag.image_urls ?? [];
	const videos = flag.video_urls ?? [];

	const media = [...images, ...videos];

	const handleClick = () => {
		if (!inspection || !feature) {
			return;
		}

		dispatch(
			ModalFlowActions.showFeatureDetails({
				features: [feature],
				inspection,
				initialFeature: feature,
			})
		);
	};

	return (
		<>
			{media.length > 0 && (
				<StartRow onClick={handleClick}>
					<FirebaseMediaDiv
						style={imageStyle}
						src={media[0]}
						isVideo={Utils.Media.isVideo(media[0])}
					/>

					{media.length > 1 && (
						<>
							<Spacer width={7} />
							<FirebaseMediaDiv
								style={imageStyle}
								src={media[1]}
								isVideo={Utils.Media.isVideo(media[1])}>
								<MoreImagesOverlay>
									<MoreImagesText>+ {media.length - 1} more</MoreImagesText>
								</MoreImagesOverlay>
							</FirebaseMediaDiv>
						</>
					)}
				</StartRow>
			)}
		</>
	);
};

const imageStyle = {
	width: spacing(20),
	height: spacing(20),
	borderRadius: spacing(2),
};

const MoreImagesOverlay = styled.div`
	background-color: #00000030;
	border-radius: ${spacingPx(2)};
	height: 100%;
	align-items: center;
	justify-content: center;
	display: flex;
`;

const MoreImagesText = styled.div`
	color: white;
	font-weight: bold;
`;

export default Photos;
