import { Grid } from '@mui/material';
import { ApiInspectionWithTemplate } from '@rentcheck/types';
import { Screens } from 'components';

import Header from '../../header';
import Navigation from '../common/navigation';

import RoomsAndFeatures from './rooms-and-features';
import TimelineEvents from './timeline-events';

interface Props {
	inspection: ApiInspectionWithTemplate;
}

const InspectionInProgress = ({ inspection }: Props) => {
	return (
		<>
			<Navigation inspection={inspection} />
			<Screens.Container>
				<Header inspection={inspection} />

				<Grid container columns={{ xs: 1, md: 20 }} spacing={{ xs: 2, md: 8 }}>
					<Grid item xs={1} md={8}>
						<RoomsAndFeatures inspection={inspection} />
					</Grid>
					<Grid item xs={1} md={12}>
						<TimelineEvents inspection={inspection} />
					</Grid>
				</Grid>
			</Screens.Container>
		</>
	);
};

export default InspectionInProgress;
