import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	ToggleButton as MuiToggleButton,
	ToggleButtonGroup,
	Tooltip,
} from '@mui/material';
import { Rules } from '@rentcheck/biz';
import { ApiInspection, InspectionViewMode } from '@rentcheck/types';
import { Spacer } from 'components';
import { useUserSettings } from 'hooks/user-settings';
import { useLocation } from 'react-router-dom';
import { useTypedSelector } from 'store/reducers/common';
import styled from 'styled-components';
import { colors } from 'theme';
import { Analytics } from 'utils';

interface Props {
	inspection: ApiInspection;
}

export default ({ inspection }: Props) => {
	const location = useLocation();

	const { userSettings, updateUserSettings } = useUserSettings();

	const subscription = useTypedSelector((state) => state.subscription);

	const handleChange = (
		event: React.MouseEvent<HTMLElement>,
		newValue: InspectionViewMode | null
	) => {
		if (!newValue) return;

		updateUserSettings({
			inspection_view_mode: newValue,
		});

		if (newValue === 'review') {
			Analytics.trackEvent('media_view_activated', {
				subscription_id: subscription?.id ?? '',
				chargebee_subscription_id: subscription?.plan.sub_id ?? '',
				chargebee_customer_id: subscription?.rentcheck_contact.company_id ?? '',
			});
		}
	};

	if (location.pathname.includes('flagged-items')) {
		return null;
	}

	if (!Rules.Inspections.canShowViewModeToggle(inspection)) {
		return null;
	}

	const listSelected = userSettings.inspection_view_mode === 'list';
	const reviewSelected = userSettings.inspection_view_mode === 'review';

	const iconFontSize = 14;

	return (
		<ToggleButtonGroup
			value={userSettings.inspection_view_mode}
			exclusive
			onChange={handleChange}
			sx={{ display: 'flex', flex: { xs: 1, sm: 0 } }}>
			<Tooltip title="List View">
				<ToggleButton
					value="list"
					selected={listSelected}
					sx={{
						borderTopLeftRadius: '16px',
						borderBottomLeftRadius: '16px',
					}}>
					<FontAwesomeIcon
						icon={solid('check')}
						color={listSelected ? colors.success : colors.transparent}
						style={{ fontSize: iconFontSize }}
					/>
					<Spacer width={0.5} />
					<FontAwesomeIcon
						icon={solid('list')}
						color={listSelected ? colors.black : colors.secondary}
						style={{ fontSize: iconFontSize }}
					/>
				</ToggleButton>
			</Tooltip>

			<Tooltip title="Media View">
				<ToggleButton
					value="review"
					selected={reviewSelected}
					sx={{
						borderTopRightRadius: '16px',
						borderBottomRightRadius: '16px',
					}}>
					<FontAwesomeIcon
						icon={solid('user-magnifying-glass')}
						color={reviewSelected ? colors.black : colors.secondary}
						style={{ fontSize: iconFontSize }}
					/>
					<Spacer width={0.25} />
					<FontAwesomeIcon
						icon={solid('check')}
						color={reviewSelected ? colors.success : colors.transparent}
						style={{ fontSize: iconFontSize }}
					/>
				</ToggleButton>
			</Tooltip>
		</ToggleButtonGroup>
	);
};

const ToggleButton = styled(MuiToggleButton)`
	height: 32px;
	color: #4d4d4d;
	display: flex;
	flex: 1;
`;
