import { Skeleton } from '@mui/material';

import { Column, SpacedRow } from 'components';

export default () => {
	return (
		<Column>
			<SpacedRow>
				<Column>
					<Skeleton variant="text" width={160} height={20} />
					<Skeleton variant="text" width={100} height={15} />
					<Skeleton variant="text" width={110} height={15} />
				</Column>
				<Skeleton
					sx={{ mr: 1.5, borderRadius: 0.5 }}
					variant="rectangular"
					width={17}
					height={17}
				/>
			</SpacedRow>
			<SpacedRow style={{ marginTop: 8 }}>
				<Column>
					<Skeleton variant="text" width={160} height={20} />
					<Skeleton variant="text" width={100} height={15} />
					<Skeleton variant="text" width={110} height={15} />
				</Column>
				<Skeleton
					sx={{ mr: 1.5, borderRadius: 0.5 }}
					variant="rectangular"
					width={17}
					height={17}
				/>
			</SpacedRow>
		</Column>
	);
};
