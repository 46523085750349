import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import useImports from 'hooks/imports';
import { useIsMobileBreakpoint } from 'utils/hooks';

import InfiniteScrollV2 from 'components/infinite-scroll-v2';
import Row from './row';
import Skeleton from './skeleton';

export default () => {
	const isMobile = useIsMobileBreakpoint('md');

	const { loading, imports, loadMore } = useImports(20);

	const handleLoadMore = async () => {
		return loadMore();
	};

	if (loading) {
		return <Skeleton />;
	}

	if (!imports.length) {
		return null;
	}

	return (
		<TableContainer component={Paper}>
			<Table>
				{!isMobile && (
					<TableHead>
						<TableRow>
							<TableCell>Type</TableCell>
							<TableCell>New Records</TableCell>
							<TableCell>Deleted Records</TableCell>
							<TableCell>Error Count</TableCell>
							<TableCell>User</TableCell>
							<TableCell>Status</TableCell>
						</TableRow>
					</TableHead>
				)}
				<TableBody>
					{imports.map((i) => (
						<Row key={i.id} object={i} />
					))}
				</TableBody>
			</Table>
			<InfiniteScrollV2
				onLoadMore={handleLoadMore}
				data={imports}
				pageSize={20}
			/>
		</TableContainer>
	);
};
