import {
	Checkbox,
	TableCell,
	TableHead,
	TableRow,
	TableSortLabel,
} from '@mui/material';
import { useDispatch } from 'react-redux';

import { useTypedSelector } from 'store/reducers/rootReducer';

import { APIBuilding } from '@rentcheck/types';
import ActionsMenu from './actions-menu';
import { Utils } from '@rentcheck/biz';

const headers = [
	{ id: 'name', title: 'Name', sortable: true },
	{ id: 'address', title: 'Address', sortable: true },
	{ id: 'city', title: 'City', sortable: false },
	{ id: 'zip_code', title: 'ZIP Code', sortable: false },
	{ id: 'team', title: 'Team', sortable: false },
	{
		id: 'last_completed_inspection',
		title: 'Last Inspection Completed',
		sortable: true,
	},
];

interface Props {
	selectedIds: string[];
	setSelectedIds: (ids: string[]) => void;
	selectAllIds: () => void;
	buildings: APIBuilding[];
}

const BuildingTableHeader = ({
	selectedIds,
	setSelectedIds,
	selectAllIds,
	buildings,
}: Props) => {
	const dispatch = useDispatch();

	const sortBy = useTypedSelector(
		(state) => state.properties.filters.buildings.sortBy
	);
	const sortType = useTypedSelector(
		(state) => state.properties.filters.buildings.sortType
	);

	const switchSortDirection = () => {
		dispatch({
			type: 'TOGGLE_BUILDINGS_SORT_TYPE',
		});
	};

	const handleSort = (headerId: string, sortable: boolean) => {
		if (!sortable) return;

		if (headerId === sortBy) {
			return switchSortDirection();
		}

		dispatch({
			type: 'SET_BUILDINGS_SORT_BY',
			value: headerId,
		});
	};

	const checked =
		selectedIds.length === buildings.length && selectedIds.length > 0;

	const indeterminate =
		selectedIds.length > 0 && selectedIds.length !== buildings.length;

	const disableDeletion = buildings.some(
		(building) =>
			Utils.Properties.propertyIsStillInSync(building) &&
			selectedIds.includes(building.id)
	);
	return (
		<TableHead>
			<TableRow>
				<TableCell>
					<Checkbox
						id="checkbox-building-header"
						size="small"
						onChange={selectAllIds}
						indeterminate={indeterminate}
						checked={checked}
					/>
				</TableCell>
				{headers.map((h) => (
					<TableCell key={h.id}>
						<TableSortLabel
							active={sortBy === h.id}
							direction={sortType}
							hideSortIcon={!h.sortable}
							onClick={() => handleSort(h.id, h.sortable)}>
							{h.title}
						</TableSortLabel>
					</TableCell>
				))}
				<TableCell align="center" variant="head">
					<ActionsMenu
						buildingIds={selectedIds}
						setBuildingIds={setSelectedIds}
						disableDeletion={disableDeletion}
					/>
				</TableCell>
			</TableRow>
		</TableHead>
	);
};

export default BuildingTableHeader;
