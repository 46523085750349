import { Skeleton } from '@mui/material';
import { Column, Row, SpacedRow, Spacer } from 'components';

const SkeletonRow = () => {
	return (
		<SpacedRow style={{ height: 113, paddingLeft: 20, paddingRight: 50 }}>
			<Skeleton variant="text" width={100} />
			<Spacer width={2} />
			<Skeleton variant="text" width={150} />
			<Spacer width={2} />
			<Row>
				<Skeleton
					variant="rectangular"
					width={80}
					height={80}
					sx={{ borderRadius: 2 }}
				/>
				<Spacer width={7} />
				<Skeleton
					variant="rectangular"
					width={80}
					height={80}
					sx={{ borderRadius: 2 }}
				/>
			</Row>
			<Spacer width={2} />
			<Column>
				<Skeleton variant="text" width={350} />
				<Skeleton variant="text" width={325} />
				<Skeleton variant="text" width={335} />
			</Column>
			<Spacer width={2} />
			<Skeleton
				variant="rectangular"
				width={70}
				height={30}
				sx={{ borderRadius: 5 }}
			/>
			<Spacer width={2} />
			<Skeleton
				variant="rectangular"
				width={38}
				height={38}
				sx={{ borderRadius: 5 }}
			/>
		</SpacedRow>
	);
};
const ListSkeleton = () => {
	return (
		<Column>
			<Skeleton
				variant="rectangular"
				width="100%"
				height={56}
				sx={{ borderRadius: 1 }}
			/>

			{'skeleton'.split('').map(() => (
				<SkeletonRow />
			))}
		</Column>
	);
};

export default ListSkeleton;
