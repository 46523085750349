import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Button, Divider, Menu } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Rules } from '@rentcheck/biz';

import { signOut } from 'store/actions/auth-actions';
import { useTypedSelector } from 'store/reducers/rootReducer';

import MenuItem from './menu-item';

const ProfileButton = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const profile = useTypedSelector((state) => state.activeProfile);
	const subscription = useTypedSelector((state) => state.subscription);
	const authenticatedUser = useTypedSelector(
		(state) => state.authenticatedUser
	);

	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const profileInitials = authenticatedUser?.name
		.split(' ')
		.map((s) => s.charAt(0))
		.slice(0, 2)
		.join('');

	const handleClick = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		setAnchorEl(e.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleRoute = (route: string) => {
		handleClose();
		history.push(route);
	};

	if (!authenticatedUser) {
		return null;
	}

	return (
		<>
			<Button onClick={handleClick} sx={{ marginLeft: 4 }} size="small">
				{profileInitials}
			</Button>

			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={!!anchorEl}
				onClose={handleClose}>
				<MenuItem
					title="Account"
					icon={regular('user-gear')}
					onClick={() => handleRoute('/account')}
				/>
				<Divider />
				{Rules.Subscriptions.canViewProfileButtonOption(
					profile,
					subscription
				) && (
					<>
						<MenuItem
							title="Teams"
							icon={regular('users')}
							onClick={() => handleRoute('/account/teams')}
						/>
						<Divider />
					</>
				)}
				{Rules.Teammates.canAccess(profile, subscription) && (
					<>
						<MenuItem
							title="Teammates"
							icon={regular('handshake')}
							onClick={() => handleRoute('/account/teammates')}
						/>
						<Divider />
					</>
				)}
				{Rules.Subscriptions.canViewProfileButtonOption(
					profile,
					subscription
				) && (
					<>
						<MenuItem
							title="Dashboard"
							icon={regular('chart-line')}
							onClick={() => handleRoute('/dashboard')}
						/>
						<Divider />
					</>
				)}
				{Rules.Subscriptions.canViewProfileButtonOption(
					profile,
					subscription
				) && (
					<>
						<MenuItem
							title="Integrations"
							icon={regular('puzzle-piece')}
							onClick={() => handleRoute('/account/integrations')}
						/>
						<Divider />
					</>
				)}
				{authenticatedUser.internal_sales && (
					<>
						<MenuItem
							title="Sales"
							icon={regular('user-cowboy')}
							onClick={() => handleRoute('/account/sales')}
						/>
						<Divider />
					</>
				)}

				{authenticatedUser.internal_admin && (
					<>
						<MenuItem
							title="Admin"
							icon={regular('user-ninja')}
							onClick={() => handleRoute('/account/admin')}
						/>
						<Divider />
					</>
				)}

				<MenuItem
					title="Logout"
					icon={regular('sign-out')}
					onClick={() => {
						handleClose();
						dispatch(signOut());
					}}
				/>
			</Menu>
		</>
	);
};

export default ProfileButton;
