import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { TableCell } from '@mui/material';
import { TeamInvitesApi } from '@rentcheck/api-frontend';
import { ApiTeamDetails, ApiTeamInvite } from '@rentcheck/types';
import IconButton from 'components/icon-button';

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarActions, TeamInvitesActions } from 'store/actions';
import { Dispatch } from 'types';

interface Props {
	invite: ApiTeamInvite;
	team: ApiTeamDetails;
}

const SendReminderAction = ({ invite, team }: Props) => {
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);

	const sendReminder = () => {
		setLoading(true);

		TeamInvitesApi.sendReminder(team.id, invite.id)
			.then(() => dispatch(SnackbarActions.showSuccess('Reminder sent')))
			.catch((e) => dispatch(SnackbarActions.showError(e)))
			.finally(() => setLoading(false));
	};

	return (
		<IconButton
			tooltip="Send Reminder"
			onClick={sendReminder}
			loading={loading}
			icon={regular('bell')}
		/>
	);
};

const DeleteInviteAction = ({ invite, team }: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const [loading, setLoading] = useState(false);

	const deleteInvite = () => {
		setLoading(true);

		dispatch(TeamInvitesActions.deleteInvite(team.id, invite.id))
			.then(() => dispatch(SnackbarActions.showSuccess('Team invite deleted')))
			.catch((e) => dispatch(SnackbarActions.showError(e)))
			.finally(() => setLoading(false));
	};

	return (
		<IconButton
			tooltip="Delete Invite"
			onClick={deleteInvite}
			loading={loading}
			icon={regular('trash')}
			color="error"
		/>
	);
};
export default ({ invite, team }: Props) => {
	return (
		<TableCell>
			<SendReminderAction invite={invite} team={team} />
			<DeleteInviteAction invite={invite} team={team} />
		</TableCell>
	);
};
