import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import { PopoverDropdown, Row, Spacer } from 'components';
import CreateTeamModal from 'components/teams/create';
import { useStoreTeams } from 'hooks/teams';
import _ from 'lodash';
import { useState } from 'react';
import styled from 'styled-components';
import { colors, spacingPx } from 'theme';

interface Props {
	filterNonAdmin?: boolean;
	organizationId?: string;
	type?: 'Building' | 'Community';
	setOrganizationId: (id: string) => void;
	additionalOrganizationIds: string[];
	setAdditionalOrganizationIds: (ids: string[]) => void;
}

const Team = ({
	type = 'Building',
	organizationId,
	setOrganizationId,
	additionalOrganizationIds,
	setAdditionalOrganizationIds,
	filterNonAdmin,
}: Props) => {
	const { teams, loading } = useStoreTeams();

	const [createTeamModalVisible, setCreateTeamModalVisible] = useState(false);

	const popoverItems =
		teams
			.filter((t) => (filterNonAdmin ? t.user_role === 'admin' : true))
			.map((o) => ({
				title: o.internal_label,
				selected: organizationId === o.id,
				onClick: () => {
					setOrganizationId(o.id);
					removeAdditionalOrganizationId(o.id);
				},
			})) ?? [];

	const additionalPopoverItems =
		teams
			.filter((o) => (filterNonAdmin ? o.user_role === 'admin' : true))
			.filter((o) => organizationId !== o.id)
			.map((o) => ({
				title: o.internal_label,
				selected: additionalOrganizationIds.includes(o.id),
				onClick: () => toggleAdditionalOrganizationId(o.id),
			})) ?? [];

	const toggleAdditionalOrganizationId = (id: string) => {
		setAdditionalOrganizationIds(_.xor(additionalOrganizationIds, [id]));
	};

	const removeAdditionalOrganizationId = (id: string) => {
		setAdditionalOrganizationIds(
			additionalOrganizationIds.filter((oid) => oid !== id)
		);
	};

	const additionalTeamsTitle = () => {
		const genericSelectMessage = 'Select additional teams';

		if (additionalOrganizationIds.length === 0) return genericSelectMessage;

		const firstOrganization = teams?.find(
			(o) => o.id === additionalOrganizationIds[0]
		);

		if (!firstOrganization) return genericSelectMessage;

		if (additionalOrganizationIds.length === 1)
			return firstOrganization.internal_label;

		return `${firstOrganization.internal_label} + ${
			additionalOrganizationIds.length - 1
		} Others`;
	};

	const selectedOrganization = teams?.find((o) => o.id === organizationId);

	const organizationsLength = teams?.length ?? 0;
	const organizationName = selectedOrganization?.internal_label ?? '';

	return (
		<div>
			<Typography variant="h6">
				Which team manages this {type.toLowerCase()}?
			</Typography>
			<Spacer height={7} />

			{organizationsLength === 0 && !loading && (
				<DropdownAnchor onClick={() => setCreateTeamModalVisible(true)}>
					<FontAwesomeIcon icon={solid('plus')} />
					<Spacer width={4} />
					<Typography variant="h6">Add New Team</Typography>
				</DropdownAnchor>
			)}

			{(organizationsLength > 0 || loading) && (
				<PopoverDropdown
					type="radio"
					options={popoverItems}
					width={134}
					loading={loading}
					title={organizationName || 'Select a team'}
					titleIsSelectedOption={!!organizationName}
				/>
			)}

			{organizationsLength > 1 && !!organizationId && (
				<>
					<Spacer height={14} />
					<Typography variant="h4">
						Are there additional teams that should have access to this{' '}
						{type.toLowerCase()}?
					</Typography>
					<Spacer height={7} />
					<PopoverDropdown
						type="checkbox"
						options={additionalPopoverItems}
						width={134}
						loading={false}
						title={additionalTeamsTitle()}
						titleIsSelectedOption={false}
					/>
				</>
			)}

			{createTeamModalVisible && (
				<CreateTeamModal
					setModalVisible={setCreateTeamModalVisible}
					creationCallback={(createdTeam) => {
						setOrganizationId(createdTeam.id);
					}}
				/>
			)}
		</div>
	);
};

const DropdownAnchor = styled(Row)`
	height: ${spacingPx(15)};
	border: 1px solid ${colors.lightGray};
	padding: 0 ${spacingPx(5)};
	cursor: pointer;
`;

export default Team;
