import { Button, MenuItem, Paper, Tooltip, Typography } from '@mui/material';
import 'react-phone-input-2/lib/style.css';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TemplateFeature, TemplateSection } from '@rentcheck/types';
import { Column, SpacedRow } from 'components';
import IconButton from 'components/icon-button';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { numberAndPluralizeWord } from 'utils/helpers';
import { useIsMobileBreakpoint } from 'utils/hooks';
import { v4 as uuid } from 'uuid';

import { useState } from 'react';
import { containsIncompleteFeatures } from '../features/helpers';
import SectionCard from './section-card';
import SectionLogicModal from './section-logic/bulk-modal';

interface Props {
	editing: boolean;

	currentSection?: TemplateSection;
	setCurrentSection: (section: TemplateSection) => void;

	sections: TemplateSection[];
	setSections: (sections: TemplateSection[]) => void;

	currentFeature?: TemplateFeature;
}

export default ({
	editing,
	sections,
	setSections,
	currentSection,
	setCurrentSection,
	currentFeature,
}: Props) => {
	const isSmallScreen = useIsMobileBreakpoint('sm');

	const [sectionLogicModalOpen, setSectionLogicModalOpen] = useState(false);

	const handleDragEnd = (result: DropResult) => {
		/**
		 * Section was dropped outside the list, don't do anything
		 */
		if (!result.destination) {
			return;
		}

		/**
		 * Remove section from array then re-insert at the
		 * correct location
		 */
		const reordered = Array.from(sections);
		const [removed] = reordered.splice(result.source.index, 1);
		reordered.splice(result.destination.index, 0, removed);

		setSections(reordered);
	};

	const handleAddSection = () => {
		const newSection: TemplateSection = {
			id: uuid(),
			name: '',
			features: [],
			type: 'section',
			logic: [],
		};

		setSections([newSection, ...sections]);

		if (!isSmallScreen) setCurrentSection(newSection);
	};

	const handleAddLogic = () => {
		setSectionLogicModalOpen(true);
	};

	const handleDeleteSection = (section: TemplateSection) => {
		setSections(sections.filter((s) => s.id !== section.id));
	};

	const handleUpdateSection = (section: TemplateSection) => {
		setSections(sections.map((s) => (s.id === section.id ? section : s)));
	};

	const handleUpdateSections = (sections: TemplateSection[]) => {
		setSections(sections);
	};

	const disabled = containsIncompleteFeatures(sections);

	const handleSelectSection = (section: TemplateSection) => {
		if (disabled) {
			return;
		}

		setCurrentSection(section);
	};

	return (
		<SectionsContainer
			sectionSelected={!!currentSection}
			featureSelected={!!currentFeature}>
			<InnerSectionsContainer>
				<SpacedRow
					style={{ padding: '8px 16px 24px 32px', alignItems: 'center' }}>
					<Column>
						<Typography variant="h6">Sections</Typography>
						<Typography variant="caption">
							{numberAndPluralizeWord(sections.length, 'Section')}
						</Typography>
					</Column>
					{editing && (
						<IconButton icon={regular('ellipsis-vertical')} disabled={disabled}>
							<MenuItem onClick={handleAddSection}>
								Add Section
								<Tooltip title="New sections apply to all inspections in this template regardless of the property mapping.">
									<FontAwesomeIcon
										icon={regular('info-circle')}
										style={{ marginLeft: 8 }}
									/>
								</Tooltip>
							</MenuItem>
							<MenuItem onClick={handleAddLogic}>Add Logic</MenuItem>
						</IconButton>
					)}
				</SpacedRow>
				{!sections.length && (
					<Paper
						elevation={0}
						sx={{
							margin: '8px 0px',
							padding: '16px 32px',
							backgroundColor: '#DFFFE9',
						}}>
						<Typography variant="h6">Add Sections</Typography>
						<Typography variant="subtitle2" mt={1}>
							Add a section to get started creating your custom inspection
							template.
						</Typography>
					</Paper>
				)}
				{!sections.length && editing && (
					<Button
						onClick={handleAddSection}
						startIcon={<FontAwesomeIcon icon={regular('plus')} />}>
						Add Section
					</Button>
				)}
				<DragDropContext onDragEnd={handleDragEnd}>
					<Droppable droppableId="droppable">
						{(provided) => (
							<div {...provided.droppableProps} ref={provided.innerRef}>
								{sections.map((section, index) => (
									<SectionCard
										key={section.id}
										section={section}
										sections={sections}
										index={index}
										editing={editing}
										onDelete={handleDeleteSection}
										onUpdate={handleUpdateSection}
										onSelect={handleSelectSection}
										selected={currentSection?.id === section.id}
									/>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</InnerSectionsContainer>
			<SectionLogicModal
				sections={sections}
				onUpdate={handleUpdateSections}
				open={sectionLogicModalOpen}
				onClose={() => setSectionLogicModalOpen(false)}
			/>
		</SectionsContainer>
	);
};

const SectionsContainer = styled(Column)<{
	sectionSelected: boolean;
	featureSelected: boolean;
}>`
	width: 20%;
	height: 100%;
	overflow-y: scroll;
	overflow-x: clip;
	background-color: #f6f5ff;
	border-right: 1px solid #0000001f;

	transition: width 0.2s ease-in-out;

	@media (max-width: 1199px) {
		width: ${({ featureSelected }) => (featureSelected ? '0%' : '50%')};
	}

	@media (max-width: 599px) {
		width: ${({ sectionSelected }) => (sectionSelected ? '0%' : '100%')};
	}
`;

const InnerSectionsContainer = styled(Column)`
	margin: 12px;
	margin-bottom: 4px;
`;
