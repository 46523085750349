import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Utils } from '@rentcheck/biz';
import { BillingModalOption } from '@rentcheck/types';
import { LoadingButtonProps } from 'components/button';
import { EditBillingPlanButton } from 'components/edit-billing-plan-button';
import { useContext } from 'react';
import { IntercomAPI } from 'react-intercom';
import { useTypedSelector } from 'store/reducers/common';
import { colors } from 'theme';
import { BillingOptionContext } from '../../../../context';

interface Props {
	plan: BillingModalOption['plan'];
}

export default ({ plan }: Props) => {
	const billingOptionContext = useContext(BillingOptionContext);

	const subscription = useTypedSelector((state) => state.subscription);

	const handleChatToUpdatePlan = () => {
		IntercomAPI('showNewMessage');
	};

	const currentPlanId = subscription?.plan?.plan_id;

	const isCurrentPlan =
		(Utils.Subscriptions.planIdIsPro(currentPlanId) &&
			Utils.Subscriptions.planIdIsPro(plan.id)) ||
		(Utils.Subscriptions.planIdIsEssential(currentPlanId) &&
			Utils.Subscriptions.planIdIsEssential(plan.id));

	const commonProps: LoadingButtonProps = {
		fullWidth: true,
		sx: { mt: 2 },
	};

	if (isCurrentPlan) {
		return (
			<EditBillingPlanButton
				{...commonProps}
				sx={{ mt: 2, borderColor: colors.primary }}
				style={{ pointerEvents: 'none' }}
				variant="outlined"
				color="primary">
				Current Plan
			</EditBillingPlanButton>
		);
	}

	const isDowngrade =
		Utils.Subscriptions.planIdIsPro(currentPlanId) &&
		Utils.Subscriptions.planIdIsEssential(plan.id);

	if (isDowngrade) {
		return (
			<EditBillingPlanButton
				{...commonProps}
				startIcon={<FontAwesomeIcon icon={solid('message-lines')} />}
				onClick={handleChatToUpdatePlan}>
				Chat to update plan
			</EditBillingPlanButton>
		);
	}

	const hasUsedFreeTrial = subscription?.plan_free_trials?.some(
		(pft) => pft.id === plan.free_trial.id
	);

	if (hasUsedFreeTrial) {
		return (
			<EditBillingPlanButton
				{...commonProps}
				startIcon={<FontAwesomeIcon icon={solid('sparkles')} />}
				onClick={() => billingOptionContext(plan, 'full-plan')}>
				Select Plan
			</EditBillingPlanButton>
		);
	}

	return (
		<EditBillingPlanButton
			{...commonProps}
			startIcon={<FontAwesomeIcon icon={solid('sparkles')} />}
			onClick={() => billingOptionContext(plan, 'free-trial')}>
			Try for free ({plan.free_trial.duration} days)
		</EditBillingPlanButton>
	);
};
