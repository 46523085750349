import { Row } from 'components';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ApiInspection } from '@rentcheck/types';
import _ from 'lodash';
import { useTypedSelector } from 'store/reducers/rootReducer';

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import SegmentChip, {
	Props as SegmentChipProps,
} from 'components/segment-chip';
import { ChipFilterType, UseStateType } from '../../types';

interface Props {
	inspection: ApiInspection;
	chipFilterState: UseStateType<ChipFilterType[]>;
}

export default ({ inspection, chipFilterState }: Props) => {
	const features = useTypedSelector((state) =>
		_.values(state.features[inspection.id] ?? {})
	);

	const [chipFilter, setChipFilter] = chipFilterState;

	const filters: {
		label: string;
		icon?: IconDefinition;
		tooltip?: string;
		color: SegmentChipProps['color'];
		variant: SegmentChipProps['variant'];
		matchIconColor?: boolean;
	}[] = [
		{
			label: 'All',
			icon: regular('inbox'),
			color: 'primary',
			variant: 'filled',
			matchIconColor: true,
		},
		{
			label: 'Poor',
			icon: regular('frown'),
			tooltip: "Features rated 'Poor'",
			color: 'error',
			variant: 'outlined',
		},
		{
			label: 'Fair',
			icon: regular('meh'),
			tooltip: "Features rated 'Fair'",
			color: 'warning',
			variant: 'outlined',
		},
		{
			label: 'Good',
			icon: regular('smile'),
			tooltip: "Features rated 'Good'",
			color: 'success',
			variant: 'outlined',
		},
		{
			label: 'Media Required',
			icon: regular('image'),
			tooltip: 'Features with linked media',
			color: 'info',
			variant: 'outlined',
		},
		{
			label: 'N/A',
			tooltip: "Features rated 'Not Applicable'",
			color: 'info',
			variant: 'outlined',
		},
		{
			label: 'Notes',
			icon: regular('messages-question'),
			tooltip: 'Features with notes',
			color: 'info',
			variant: 'outlined',
		},
	];

	if (inspection.review) {
		filters.push({
			label: 'Edited by Reviewer',
			icon: regular('user-pen'),
			tooltip: 'Features edited by reviewer',
			color: 'info',
			variant: 'outlined',
		});
	}

	const labelForFilter = (filter: string): string => {
		if (filter === 'All') {
			return `All`;
		}

		if (['Good', 'Fair', 'Poor'].includes(filter)) {
			return `${features.filter((f) => f.rating === filter).length}`;
		}

		if (filter === 'NO') {
			return `${features.filter((f) => f.responses.includes('No')).length}`;
		}

		if (filter === 'N/A') {
			return `${features.filter((f) => f.not_applicable).length}`;
		}

		if (filter === 'Notes') {
			return `${features.filter((f) => f.notes).length}`;
		}

		if (filter === 'Media Required') {
			return `${features.filter((f) => f.image_links?.length > 0 || f.video_links?.length > 0).length}`;
		}

		if (filter === 'Edited by Reviewer') {
			return `${features.filter((f) => f.edited_by_reviewer).length}`;
		}

		return ``;
	};

	const handleChip = (filter: string) => {
		if (filter === 'All') {
			setChipFilter([]);
			return;
		}

		setChipFilter(_.xor(chipFilter, [filter]) as ChipFilterType[]);
	};

	return (
		<Row style={{ flexWrap: 'wrap', gap: 8 }}>
			{filters.map((f) => (
				<SegmentChip
					title={f.label}
					label={labelForFilter(f.label)}
					tooltip={f.tooltip}
					icon={f.icon}
					color={f.color}
					variant={f.variant}
					matchIconColor={f.matchIconColor}
					onClick={() => handleChip(f.label)}
					selected={
						f.label === 'All'
							? chipFilter.length === 0
							: chipFilter.includes(f.label as ChipFilterType)
					}
				/>
			))}
		</Row>
	);
};
