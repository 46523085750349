import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import SegmentChip from 'components/segment-chip';
import _ from 'lodash';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { incompleteInspectionStatuses } from './constants';

const thePast = moment('2016-01-01').startOf('day').valueOf();
const endOfYesterday = moment().endOf('day').subtract(1, 'days').valueOf();

export default () => {
	const dispatch = useDispatch();

	const filters = useTypedSelector((state) => state.inspections.filters);

	const handleClick = () => {
		dispatch({ type: 'RESET_INSPECTION_FILTERS' });

		dispatch({
			type: 'SET_INSPECTION_DUE_DATE_RANGE_FILTER',
			dateRange: { start: thePast, end: endOfYesterday },
		});

		dispatch({
			type: 'SET_INSPECTION_STATUS_FILTER',
			value: {
				condition: 'any_of',
				value: incompleteInspectionStatuses,
			},
		});
	};

	const selected =
		_.xor(filters.inspectionStatus?.value, incompleteInspectionStatuses)
			.length === 0 &&
		!filters.inspectionTemplate?.value.length &&
		!filters.team?.value.length &&
		filters.dueDateRange?.start === thePast &&
		filters.dueDateRange?.end === endOfYesterday &&
		!filters.completedDateRange &&
		!filters.archived;

	return (
		<SegmentChip
			variant="outlined"
			icon={solid('exclamation-triangle')}
			label="Overdue"
			title="Overdue"
			onClick={handleClick}
			color="error"
			selected={selected}
		/>
	);
};
