import { Paper, Table, TableContainer, Typography } from '@mui/material';
import styled from 'styled-components';

import { emptyStates } from 'assets';
import { CenteredColumn } from 'components';
import { useAppliedFiltersCount } from 'store/reducers/inspections/filters';

import { ReactNode, ReactNodeArray } from 'react';
import TableHeader from '../table/header';

const Container = ({ children }: { children?: ReactNode | ReactNodeArray }) => {
	return (
		<div>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }}>
					<TableHeader
						selectedIds={[]}
						setSelectedIds={() => {}}
						selectAllIds={() => {}}
						inspections={[]}
					/>
				</Table>
				<CenteredColumn
					style={{ marginTop: '8vh', marginLeft: 24, marginRight: 24 }}>
					{children}
				</CenteredColumn>
			</TableContainer>
		</div>
	);
};
const EmptyState = () => {
	const appliedFiltersCount = useAppliedFiltersCount();

	if (appliedFiltersCount > 0) {
		return (
			<Container>
				<Typography variant="h6" align="center" color="#4D4D4D">
					No inspections found
				</Typography>

				<Typography
					variant="body2"
					mt={3}
					mb={3}
					textAlign="center"
					color="#4d4d4d">
					You can find what you're looking for by adjusting your filters or
					broadening your search.
					<br />
					Stay centered and keep searching.
				</Typography>

				<EmptyStateImage
					src={emptyStates.inspectionsWithFilters}
					alt="empty-inspections"
				/>
			</Container>
		);
	}

	return (
		<Container>
			<Typography variant="h6" align="center" color="#4D4D4D">
				No inspections yet
			</Typography>

			<Typography
				variant="body2"
				mt={3}
				mb={3}
				textAlign="center"
				color="#4d4d4d">
				Set up an inspection and invite residents to complete it.
				<br />
				We'll walk them through an easy-to-follow guided inspection and you can
				rest easy knowing the condition of your unit is thoroughly documented.
				<br />
				You can also perform inspections yourself.
			</Typography>

			<EmptyStateImage src={emptyStates.inspections} alt="empty-inspections" />
		</Container>
	);
};

const EmptyStateImage = styled.img`
	height: 250px;
	width: 250px;
	resize-mode: contain;
`;

export default EmptyState;
