import { useDispatch } from 'react-redux';

import { ApiInspection } from '@rentcheck/types';

import { LoadingButton } from '@mui/lab';
import { Rules } from '@rentcheck/biz';
import { useState } from 'react';
import { InspectionActions, SnackbarActions } from 'store/actions';
import { Dispatch } from 'types';

interface Props {
	inspection: ApiInspection;
}

export default ({ inspection }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const [loading, setLoading] = useState(false);

	const handleCancelRequest = () => {
		setLoading(true);
		dispatch(InspectionActions.cancelReview(inspection.id))
			.catch((e) => dispatch(SnackbarActions.showError(e)))
			.finally(() => setLoading(false));
	};

	if (!Rules.Inspections.canCancelReviewRequest(inspection)) {
		return null;
	}

	return (
		<LoadingButton
			loading={loading}
			variant={'outlined'}
			color={'secondary'}
			sx={{ whiteSpace: 'nowrap' }}
			onClick={handleCancelRequest}>
			Cancel Review Request
		</LoadingButton>
	);
};
