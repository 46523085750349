import { AccountSettingsApi, SubscriptionsApi } from '@rentcheck/api-frontend';
import {
	ApiSubscription,
	UpdateUserSubscriptionParams,
} from '@rentcheck/types';
import { Dispatch } from 'types';

export const waitForSubscriptionWebhook = () => async (dispatch: Dispatch) =>
	new Promise<ApiSubscription>((resolve, reject) => {
		const maxSubscriptionRetries = 15;
		let pollCount = 0;

		const intervalId = setInterval(() => {
			pollCount++;

			SubscriptionsApi.get()
				.then((subscription) => {
					if (!subscription) return;

					clearInterval(intervalId);
					dispatch({ type: 'SET_SUBSCRIPTION', subscription } as any);

					AccountSettingsApi.get().then((accountSettings) =>
						dispatch({ type: 'SET_ACCOUNT_SETTINGS', value: accountSettings })
					);

					resolve(subscription);
				})
				.catch(() => {});

			if (pollCount === maxSubscriptionRetries) {
				clearInterval(intervalId);
				reject();
			}
		}, 1000);
	});

export const getSubscription = () => async (dispatch: Dispatch) => {
	const sub = await SubscriptionsApi.get();

	if (!sub) {
		return sub;
	}

	dispatch({ type: 'SET_SUBSCRIPTION', subscription: sub } as any);

	AccountSettingsApi.get().then((accountSettings) =>
		dispatch({ type: 'SET_ACCOUNT_SETTINGS', value: accountSettings })
	);

	return sub;
};

export const update =
	(data: UpdateUserSubscriptionParams['data']) =>
	async (dispatch: Dispatch) => {
		return SubscriptionsApi.update(data).then((response) => {
			dispatch({ type: 'SET_SUBSCRIPTION', subscription: response } as any);

			AccountSettingsApi.get().then((accountSettings) =>
				dispatch({ type: 'SET_ACCOUNT_SETTINGS', value: accountSettings })
			);
		});
	};
