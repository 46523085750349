import { Autocomplete, MenuItem, Typography } from '@mui/material';
import { UserInfo } from '@rentcheck/types';
import FormTextField from 'components/form-text-field';
import useUsers from 'hooks/users';
import { useMemo } from 'react';

interface Props {
	value: UserInfo[];
	setValue: (value: UserInfo[]) => void;
}

export default ({ value, setValue }: Props) => {
	const { users: rawUsers } = useUsers(200);

	const users: UserInfo[] = useMemo(() => {
		return rawUsers.map((user) => ({
			id: user.id,
			name: user.name,
			email: user.email,
			user_type: user.type,
		}));
	}, [rawUsers]);

	return (
		<Autocomplete
			fullWidth
			disableClearable
			disableCloseOnSelect
			multiple
			options={users}
			value={value}
			onChange={(_, newValue) => setValue(newValue)}
			isOptionEqualToValue={(o, v) => o.id === v.id}
			getOptionLabel={(option) => option.name}
			renderOption={(props, option) => (
				<MenuItem {...props} key={option.id}>
					{option.name || option.email}
				</MenuItem>
			)}
			renderInput={(params) => (
				<FormTextField
					{...params}
					title="Add Users"
					placeholder="Select Users"
				/>
			)}
			renderTags={(value) =>
				value.map((v, idx) => {
					const isLast = idx === value.length - 1;

					return (
						<Typography sx={{ fontSize: 16, mr: isLast ? 1.5 : 0.5 }}>
							{v.name || v.email}
							{isLast ? '' : ','}
						</Typography>
					);
				})
			}
		/>
	);
};
