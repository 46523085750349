import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
	Button,
	Card,
	Dialog,
	DialogActions,
	DialogContent,
	Grid,
} from '@mui/material';
import { useEffect, useState } from 'react';

import { Feature } from '@rentcheck/types';

import { DialogTitle } from 'components';

import AddImage from './add-image';
import ImageItem from './image-item';

interface Props {
	open: boolean;
	handleClose: () => void;
	feature: Feature;
	featurePayload: Partial<Feature>;
	setFeaturePayload: (payload: Partial<Feature>) => void;
}

export default ({
	open,
	handleClose,
	feature,
	featurePayload,
	setFeaturePayload,
}: Props) => {
	const [tempPayload, setTempPayload] = useState(featurePayload);

	useEffect(() => setTempPayload(featurePayload), [featurePayload]);

	const handleSave = () => {
		setFeaturePayload(tempPayload);
		handleClose();
	};

	const handleCancel = () => {
		setTempPayload(featurePayload);
		handleClose();
	};

	const saveDisabled = tempPayload.images?.some((i) =>
		i.startsWith('upload://')
	);

	return (
		<Dialog open={open} onClose={handleCancel}>
			<DialogTitle
				title="Edit Images"
				bottomSubtitle="Add or delete feature images"
				icon={regular('image')}
				onClose={handleClose}
			/>

			<DialogContent>
				<Card
					elevation={0}
					sx={{
						height: 456,
						overflowY: 'scroll',
						border: '1px solid #AEBBC9',
					}}>
					<Grid
						container
						columns={3}
						spacing={1}
						justifyContent="flex-start"
						alignItems="flex-start"
						sx={{ padding: 1 }}
						style={{ transition: 'all .5s ease-in-out;' }}>
						<AddImage
							feature={feature}
							featurePayload={tempPayload}
							setFeaturePayload={setTempPayload}
						/>
						{tempPayload.images?.map((i) => (
							<ImageItem
								uri={i}
								key={i}
								featurePayload={tempPayload}
								setFeaturePayload={setTempPayload}
							/>
						))}
					</Grid>
				</Card>
			</DialogContent>

			<DialogActions>
				<Button variant="outlined" color="secondary" onClick={handleCancel}>
					Cancel
				</Button>
				<Button
					id="button-save-images"
					onClick={handleSave}
					disabled={saveDisabled}>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};
