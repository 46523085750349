import { Utils } from '@rentcheck/biz';
import { Feature } from '@rentcheck/types';
import { FirebaseMediaDiv, StartRow } from 'components';
import styled from 'styled-components';
import { spacing, spacingPx } from 'theme';
import { useWindowSize } from 'usehooks-ts';
import { getFeatureMedia } from 'utils/helpers';
import { useIsMobileBreakpoint } from 'utils/hooks';

interface Props {
	feature: Feature;
}

const calculateMaxMediaToDisplay = (screenWidth: number) => {
	const mediaWidth = 80;
	const mediaMargin = 16;
	const screenMargin = 32 * 2 + 16 * 2;

	return Math.floor((screenWidth - screenMargin) / (mediaWidth + mediaMargin));
};

const Media = ({ feature }: Props) => {
	const isSmallerThanMd = useIsMobileBreakpoint('md');
	const { width: screenWidth } = useWindowSize();

	const media = getFeatureMedia(feature);
	const totalMediaCount = media.length;

	if (totalMediaCount === 0) {
		return null;
	}

	const maxMediaToDisplay = isSmallerThanMd
		? calculateMaxMediaToDisplay(screenWidth)
		: 2;

	const mediaToDisplay = media.slice(0, maxMediaToDisplay);

	return (
		<StartRow style={{ marginTop: isSmallerThanMd ? 16 : 0 }}>
			{mediaToDisplay.map((media, index) => {
				const isLast = index === mediaToDisplay.length - 1;

				return (
					<FirebaseMediaDiv
						isVideo={Utils.Media.isVideo(media)}
						style={mediaStyle}
						src={media}>
						{isLast && totalMediaCount > maxMediaToDisplay && (
							<MoreMediasOverlay>
								<MoreMediasText>
									+ {totalMediaCount - maxMediaToDisplay} more
								</MoreMediasText>
							</MoreMediasOverlay>
						)}
					</FirebaseMediaDiv>
				);
			})}
		</StartRow>
	);
};

const mediaStyle = {
	width: spacing(20),
	height: spacing(20),
	borderRadius: spacing(2),
	marginRight: spacing(4),
};

const MoreMediasOverlay = styled.div`
	background-color: #00000030;
	border-radius: ${spacingPx(2)};
	height: 100%;
	align-items: center;
	justify-content: center;
	display: flex;
`;

const MoreMediasText = styled.div`
	color: white;
	font-weight: bold;
`;

export default Media;
