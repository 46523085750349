import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { TeamsApi } from '@rentcheck/api-frontend';
import { GetAllTeamsParams, ListOptions } from '@rentcheck/types';
import useApiModel from 'hooks/common/api-hook';

import { SnackbarActions, TeamActions, TeamsActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';

export const useStoreTeams = (
	filters: GetAllTeamsParams['filters'] = {
		include_all_teams: false,
	}
) => {
	const dispatch: Dispatch = useDispatch();

	const profile = useTypedSelector((state) => state.activeProfile);
	const teams = useTypedSelector((state) => state.teams);

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (!profile) return;

		setLoading(true);

		dispatch(TeamsActions.getAll(filters))
			.catch((e) => dispatch(SnackbarActions.showError(e)))
			.finally(() => setLoading(false));
	}, [profile]);

	return {
		loading,
		teams,
	};
};

export const useStoreTeam = (teamId?: string) => {
	const dispatch: Dispatch = useDispatch();

	const team = useTypedSelector((state) => state.team);

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (!teamId) return;

		setLoading(true);

		dispatch(TeamActions.getById(teamId))
			.catch((e) => dispatch(SnackbarActions.showError(e)))
			.finally(() => setLoading(false));
	}, [teamId]);

	if (!teamId) return { loading: false, team: undefined };

	if (teamId !== team?.id) {
		return { loading, team: undefined };
	}

	return {
		loading,
		team,
	};
};

const defaultSort: ListOptions['sort'] = {
	sort_by: 'name',
	sort_type: 'ASC',
};

export const useTeams = (
	filters: GetAllTeamsParams['filters'] = {
		include_all_teams: false,
	}
) => {
	const hookData = useApiModel(
		TeamsApi,
		{ include_all_teams: filters.include_all_teams },
		defaultSort,
		100
	);

	return {
		teams: hookData.data,
		..._.omit(hookData, 'data'),
	};
};
