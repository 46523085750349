import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import {
	ApiInspectionSectionsProgress,
	ApiInspectionWithTemplate,
} from '@rentcheck/types';
import { Column, SpacedRow } from 'components';
import { testId } from 'utils/helpers';

interface Props {
	roomType: string;
	sections?: ApiInspectionWithTemplate['sections_progress'];
}

const ProgressIcon = ({
	section,
}: {
	section: ApiInspectionSectionsProgress;
}) => {
	if (section.is_na) {
		return (
			<Typography variant="body2" color="#4D4D4D">
				N/A
			</Typography>
		);
	}

	if (section.progress === 100) {
		return (
			<FontAwesomeIcon
				id={`check-icon-${testId(section.section.name_with_ordinal)}`}
				icon={regular('check')}
				color="#51C978"
			/>
		);
	}

	return null;
};

const AreaGroup = ({ roomType, sections = [] }: Props) => {
	if (!sections.length) {
		return null;
	}

	return (
		<Column style={{ marginBottom: 20 }}>
			<Typography variant="overline">{roomType}</Typography>
			{sections.map((r) => {
				return (
					<SpacedRow
						key={r.section.name_with_ordinal}
						style={{ marginTop: 12 }}>
						<Typography variant="body2" color="#4D4D4D">
							{r.section.name_with_ordinal}
						</Typography>
						<ProgressIcon section={r} />
					</SpacedRow>
				);
			})}
		</Column>
	);
};

export default AreaGroup;
