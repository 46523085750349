import { ApiMaintenanceReport } from '@rentcheck/types';
import _ from 'lodash';

export interface State {
	data: ApiMaintenanceReport[];
	total: number;
}

const initialState: State = {
	data: [],
	total: 0,
};

type Action = {
	type: string;
	value?: number;
	ids?: string[];
	report?: ApiMaintenanceReport;
	reports?: ApiMaintenanceReport[];
};

const reducer = (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case 'SIGNOUT_SUCCESS':
		case 'IMPERSONATE':
			return initialState;

		case 'FETCHED_INITIAL_REPORTS':
			if (!action.reports) return state;
			return {
				...state,
				data: action.reports,
			};

		case 'FETCHED_REPORTS_PAGE':
			if (!action.reports) return state;
			return {
				...state,
				data: _.uniqBy([...state.data, ...action.reports], (mr) => mr.id),
			};

		case 'FETCHED_REPORT':
			if (!action.report) return state;
			return {
				...state,
				data: _.uniqBy([...state.data, action.report], (mr) => mr.id),
			};

		case 'DELETED_REPORTS':
			if (!action.ids) return state;
			return {
				...state,
				total: state.total - action.ids.length,
				data: state.data.filter((mr) => !action.ids?.includes(mr.id)),
			};

		case 'SET_TOTAL_REPORTS':
			if (!action.value) return state;
			return {
				...state,
				total: action.value,
			};

		default:
			return state;
	}
};

export default reducer;
