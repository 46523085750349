import { ApiTeamDetails } from '@rentcheck/types';
import { TeamActionType, actionTypes } from '../actions/team';

export type State = ApiTeamDetails | null;

type Action = {
	type: TeamActionType;
	team: ApiTeamDetails;
};

export default (state: State = null, { type, team }: Action) => {
	switch (type) {
		case actionTypes.SET_TEAM:
			return team;
		case actionTypes.IMPERSONATE:
			return null;
		default:
			return state;
	}
};
