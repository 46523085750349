import { Divider, Typography } from '@mui/material';
import {
	MULTIPLE_CHOICE_RESPONSE_SEPARATOR,
	TemplateQuestion,
} from '@rentcheck/types';

import { Column, Spacer } from 'components';

interface Props {
	question: TemplateQuestion;
	answer: string;
	showDivider?: boolean;
}

export default ({ question, answer, showDivider = true }: Props) => {
	const answers: string[] = answer
		? answer.split(MULTIPLE_CHOICE_RESPONSE_SEPARATOR)
		: [];
	return (
		<Column
			style={{
				marginBottom: 6,
				marginTop: 12,
			}}>
			<Typography color="#00000099">{question.title}</Typography>
			<Spacer height={1} />
			{answers?.map((answer) => (
				<Typography variant="subtitle2" sx={{ wordBreak: 'break-word' }}>
					{answer}
				</Typography>
			))}

			{showDivider && <Divider sx={{ marginTop: 1.5 }} />}
		</Column>
	);
};
