import { ISettings } from '@flatfile/adapter';

const skills: ISettings = {
	type: 'Maintenance Skills',
	fields: [
		{
			label: 'Category',
			key: 'category',
			validators: [{ validate: 'required' }],
		},
		{
			label: 'Sub Category',
			key: 'sub_category',
		},
		{
			label: 'Default Skill',
			key: 'is_default',
			type: 'checkbox',
			validators: [{ validate: 'required' }],
		},
	],
};

export default { skills };
