import { UnitsApi } from '@rentcheck/api-frontend';
import { CreateUnitParams, UpdateUnitParams } from '@rentcheck/types';
import * as Sentry from '@sentry/react';
import pLimit from 'p-limit';
import {
	State as FilterState,
	initialState,
} from 'store/reducers/properties/filters/units';
import { ReduxFn } from 'types';

import { Utils } from '@rentcheck/biz';
import * as ModalFlowActions from '../modal-flows';
import * as SnackbarActions from '../snackbar-actions';

export const pageSize = 20;

export const getAll = (
	offset: number = 0,
	filterState: FilterState = initialState
): ReduxFn => {
	const fn: ReduxFn = async (dispatch) => {
		const pageNumber = Math.floor(offset / pageSize);

		const filters = {
			search: filterState.search,
			teamId: filterState.team,
			buildingId: filterState.building,
			inspectionType: filterState.inspectionTemplate,
			dateRange: filterState.dateRange?.start
				? filterState.dateRange
				: undefined,
		};

		const sorting = {
			sortBy: filterState.sortBy,
			sortType: filterState.sortType,
		};

		dispatch({ type: 'SET_LOADING_UNITS', loading: true });

		const { data, totalResults } = await UnitsApi.getAll(
			pageNumber,
			pageSize,
			filters,
			sorting
		);

		const dispatchType =
			offset === 0 ? 'FETCHED_INITIAL_UNITS' : 'FETCHED_UNITS_PAGE';

		dispatch({ type: dispatchType, properties: data as any[] });
		dispatch({ type: 'SET_TOTAL_UNITS', value: totalResults });
		dispatch({ type: 'SET_LOADING_UNITS', loading: false });

		return data;
	};

	return fn;
};

export const remove = (ids: string[]): ReduxFn => {
	const fn: ReduxFn = async (dispatch) => {
		try {
			const numberofUnits = `${ids.length} unit${ids.length === 1 ? '' : 's'}`;

			dispatch(ModalFlowActions.closeConfirmationModal());

			const progressSnackbar = await dispatch(
				SnackbarActions.showProgress(`Deleting ${numberofUnits}`)
			);

			await UnitsApi.remove(ids);
			dispatch({ type: 'DELETE_UNITS', ids });

			dispatch(SnackbarActions.hideSnackbar(progressSnackbar.id));

			dispatch(
				SnackbarActions.showSuccess(`Deleted ${numberofUnits} successfully!`)
			);
		} catch (e) {
			Sentry.captureException(e);
			throw e;
		}
	};

	return fn;
};

export const create = (payload: CreateUnitParams['data']) => {
	const fn: ReduxFn = async (dispatch) => {
		const newUnit = await UnitsApi.create(payload);

		dispatch({ type: 'FETCHED_UNIT', property: newUnit });

		return newUnit;
	};

	return fn;
};

export const update = (
	id: string | string[],
	payload: UpdateUnitParams['data']
) => {
	const fn: ReduxFn = async (dispatch) => {
		const idsToUpdate = typeof id === 'string' ? [id] : id;

		const limit = pLimit(5);

		await Promise.all(
			idsToUpdate.map((id) =>
				limit(() =>
					UnitsApi.update(id, payload).then((updatedUnit) => {
						dispatch({ type: 'FETCHED_PROPERTY', property: updatedUnit });
						dispatch({ type: 'UPDATED_UNITS', properties: [updatedUnit] });
					})
				)
			)
		);
	};

	return fn;
};

export type DiffResult = {
	roomName: string;
	operation: 'add' | 'remove';
};

export const applyUnitDiff = (id: string, diff: DiffResult[]) => {
	const fn: ReduxFn = async (dispatch) => {
		const unit = await UnitsApi.getById(id);

		if (!unit) {
			throw new Error('Unit not found');
		}

		let rooms = unit.room_names.filter(
			(r) => !Utils.Sections.isBedroom(r) && !Utils.Sections.isBathroom(r)
		);

		let bedrooms = unit.bedrooms;
		let fullBathrooms = unit.full_bathrooms;
		let halfBathrooms = unit.half_bathrooms;

		diff.forEach((dr) => {
			if (Utils.Sections.isBedroom(dr.roomName)) {
				if (dr.operation === 'add') bedrooms += 1;
				if (dr.operation === 'remove') bedrooms -= 1;

				return;
			}

			if (Utils.Sections.isFullBathroom(dr.roomName)) {
				if (dr.operation === 'add') fullBathrooms += 1;
				if (dr.operation === 'remove') fullBathrooms -= 1;

				return;
			}

			if (Utils.Sections.isHalfBathroom(dr.roomName)) {
				if (dr.operation === 'add') halfBathrooms += 1;
				if (dr.operation === 'remove') halfBathrooms -= 1;

				return;
			}

			if (dr.operation === 'add') {
				rooms = [...rooms, dr.roomName];
			}

			if (dr.operation === 'remove') {
				rooms = rooms.filter((r) => r !== dr.roomName);
			}
		});

		return dispatch(
			update(id, {
				rooms: rooms,
				bedrooms: bedrooms,
				full_bathrooms: fullBathrooms,
				half_bathrooms: halfBathrooms,
			})
		);
	};

	return fn;
};
