import { Button } from '@mui/material';
import { Spacer } from 'components';
import { useDispatch } from 'react-redux';
import { ActiveProfileActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';

export default function ImpersonateButton() {
	const activeProfile = useTypedSelector((state) => state.activeProfile);
	const authenticatedUser = useTypedSelector(
		(state) => state.authenticatedUser
	);
	const dispatch = useDispatch();
	if (!authenticatedUser) {
		return null;
	}

	if (!authenticatedUser?.internal_admin) {
		return null;
	}

	if (authenticatedUser?.id === activeProfile?.id) {
		return null;
	}

	return (
		<>
			<Spacer height={6} />
			<Button
				onClick={() => dispatch(ActiveProfileActions.clearImpersonatedUser())}>
				{authenticatedUser?.email} Is Impersonating {activeProfile?.email}!!
				Click to stop!
			</Button>
		</>
	);
}
