import { Typography } from '@mui/material';
import { ApiTeam } from '@rentcheck/types';
import { Column, Spacer } from 'components';
import Modal, { Footer } from 'components/Common/Modal';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { TeamActions } from 'store/actions';
import { Dispatch } from 'types';

interface Props {
	team: ApiTeam;
	setModalVisible: (visible: boolean) => void;
}

const EditLogoModal = ({ setModalVisible, team }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const [file, setFile] = useState<File | undefined>(undefined);

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	const handleSave = async () => {
		if (!file) return;

		setError('');
		setLoading(true);
		dispatch(TeamActions.update(team.id, { logo: file }))
			.then(() => setModalVisible(false))
			.catch((e) => setError(e.message))
			.finally(() => setLoading(false));
	};

	const disabled = !file;

	return (
		<Modal setHidden={() => setModalVisible(false)}>
			<Column>
				<Typography variant="h5">Edit Team</Typography>
				<Spacer height={6} />
				<Typography>
					Add your company logo to your inspection reports by adding it here.
					Your logo will appear at the top right of inspection reports in place
					of the RentCheck logo. It will also be added to RentCheck inspection
					invite and reminder emails. For best results attach your logo as a
					.png or .jpg with a 3:1 aspect ratio (i.e. 360 px wide and 120 px
					tall).
				</Typography>
				<Spacer height={6} />
				<Typography variant="h4">Attach logo (Optional)</Typography>
				<Spacer height={3} />
				<input
					type="file"
					accept="image/*"
					onChange={(event) => {
						const selectedFile = event.target.files?.[0];
						setFile(selectedFile);
					}}
				/>
				<Footer
					leftButtonTitle="Cancel"
					leftButtonOnClick={() => setModalVisible(false)}
					rightButtonTitle="Save & Close"
					rightButtonOnClick={handleSave}
					error={error}
					disabled={disabled}
					loading={loading}
				/>
			</Column>
		</Modal>
	);
};

export default EditLogoModal;
