import { Dialog } from '@mui/material';
import { useEffect, useState } from 'react';

import { CreateInspectionsResult } from '@rentcheck/types';

import Actions from './actions';
import Content from './content';

interface Props {
	errorModal: boolean;
	setErrorModal: (value: boolean) => void;
	creationResult?: CreateInspectionsResult;
}

const ErrorModal = ({ errorModal, setErrorModal, creationResult }: Props) => {
	const [renderInfo, setRenderInfo] = useState<boolean>(
		Boolean(creationResult?.errors.length || creationResult?.warnings.length)
	);

	useEffect(() => {
		setRenderInfo(
			Boolean(creationResult?.errors.length || creationResult?.warnings.length)
		);
	}, [creationResult]);

	const handleClose = () => {
		setErrorModal(false);
	};

	if (!creationResult) return null;

	return (
		<Dialog open={errorModal} onClose={handleClose} maxWidth="xs">
			<Content
				renderAlmostThereModal={renderInfo}
				creationResult={creationResult}
				handleClose={handleClose}
			/>

			<Actions
				type={creationResult.errors?.length ? 'Errors' : 'Warnings'}
				renderAlmostThereModal={renderInfo}
				showRenderInfo={() => setRenderInfo(false)}
				handleClose={handleClose}
			/>
		</Dialog>
	);
};

export default ErrorModal;
