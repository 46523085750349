import { Masonry } from '@mui/lab';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { Feature } from '@rentcheck/types';

import { Column } from 'components';
import { useFeatureDetailsModalData } from 'hooks/modals';
import _ from 'lodash';

import { MediaTypeIcon } from 'components/features';
import Image from './image';

interface Props {
	feature: Feature;
}

export default ({ feature }: Props) => {
	const theme = useTheme();
	const md = useMediaQuery(theme.breakpoints.up(905));
	const lg = useMediaQuery(theme.breakpoints.up(1380));

	const modalData = useFeatureDetailsModalData();
	const inspection = modalData?.metadata.inspection;

	const columns = Math.min(lg ? 3 : md ? 2 : 1, feature.images.length);
	const spacing = !lg && !md ? 0 : 2;
	const marginBottom = !lg && !md ? 16 : 0;
	const imageSize = _.floor(3000 / columns);

	if (!inspection) return null;

	if (feature.not_applicable) {
		return (
			<Masonry columns={1} spacing={spacing}>
				<Column
					style={{
						border: '1px solid #0000001F',
						borderRadius: 4,
						padding: 14,
					}}>
					<Typography color="#00000099">
						This feature was marked 'Not Applicable' by{' '}
						{inspection.current_performer?.name ?? 'the performer'}.
					</Typography>
				</Column>
			</Masonry>
		);
	}

	return (
		<div style={{ width: '100%', maxHeight: '100%', overflowY: 'scroll' }}>
			<Masonry columns={columns} spacing={spacing}>
				{feature.images.map((item, index) => (
					<div key={item + index} style={{ position: 'relative' }}>
						<Image imageUri={item} imageSize={imageSize} mb={marginBottom} />
						<MediaTypeIcon image={item} />
					</div>
				))}
			</Masonry>
		</div>
	);
};
