import { Typography } from '@mui/material';
import { Column, Spacer } from 'components';

import Content from './content';
import Filters from './filters';

interface Props {
	loading: boolean;
}

const KeyMetrics = ({ loading }: Props) => {
	return (
		<Column>
			<Typography variant="h5">Dashboard</Typography>
			<Spacer height={7} />
			<Filters />
			<Spacer height={10} />
			<Content loading={loading} />
		</Column>
	);
};

export default KeyMetrics;
