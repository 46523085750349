import { Collapse, Paper, Snackbar, Typography } from '@mui/material';
import { Column, Row, Spacer } from 'components';
import { useEffect, useState } from 'react';
import { useTypedSelector } from 'store/reducers/rootReducer';

import { FileRequestApi } from '@rentcheck/api-frontend';
import { useDispatch } from 'react-redux';
import { InspectionsActions, MaintenanceActions } from 'store/actions';
import {
	FileRequest,
	State as FileRequestsState,
} from 'store/reducers/file-requests';
import { Dispatch } from 'types';
import { Analytics } from 'utils';
import { numberAndPluralizeWord } from 'utils/helpers';
import FileRequestRow from './file-request-row';
import Header from './header';

export default () => {
	const dispatch: Dispatch = useDispatch();

	const fileRequests = useTypedSelector((state) => state.fileRequests);

	const [hidden, setHidden] = useState(false);
	const [closed, setClosed] = useState(false);

	const handleDownload = (fileRequest: FileRequest) => {
		window.open(fileRequest.location);

		if (fileRequest.document_type === 'INSPECTION_REPORT') {
			Analytics.trackEvent('downloaded_inspection_report', {
				inspection_id: fileRequest.document_id,
			});
		}

		if (fileRequest.document_type === 'MAINTENANCE_REPORT') {
			Analytics.trackEvent('downloaded_maintenance_report', {
				report_id: fileRequest.document_id,
			});
		}
	};

	const updateFileRequestsStatus = (
		fileRequests: FileRequestsState,
		dispatch: Dispatch
	) => {
		const fileRequestsInProgress = fileRequests.filter(
			(fr) => !fr.location && !fr.error
		);

		Promise.all(
			fileRequestsInProgress.map((fr) =>
				FileRequestApi.getById(fr.id)
					.then((updatedFileRequest: any) => {
						if (updatedFileRequest.status === 'ERROR') {
							dispatch({
								type: 'UPDATE_FILE_REQUEST',
								value: {
									id: fr.id,
									error: updatedFileRequest.error_message,
								},
							});
						}

						if (!updatedFileRequest.file_location) return;

						dispatch({
							type: 'UPDATE_FILE_REQUEST',
							value: {
								id: fr.id,
								location: updatedFileRequest.file_location,
							},
						});

						if (updatedFileRequest.document_type === 'INSPECTION_REPORT') {
							dispatch(InspectionsActions.refreshInspection());
						}

						if (updatedFileRequest.document_type === 'MAINTENANCE_REPORT') {
							dispatch(MaintenanceActions.reports.refreshReport());
						}

						if (fr.type === 'download') {
							handleDownload({
								...fr,
								location: updatedFileRequest.file_location,
							});
						}
					})
					.catch(() => {})
			)
		);
	};

	useEffect(() => {
		const interval = setInterval(() => {
			updateFileRequestsStatus(fileRequests, dispatch);
		}, 5000);

		return () => clearInterval(interval);
	}, [fileRequests]);

	useEffect(() => {
		setHidden(false);
		setClosed(false);
	}, [fileRequests.length]);

	const open = !closed && fileRequests.length > 0;
	const inProgressCount = fileRequests.filter((fr) => !fr.location).length;

	return (
		<Snackbar open={open} style={{ bottom: 0, zIndex: 100000000 }}>
			<Paper sx={{ width: 336, borderRadius: '6px 6px 0px 0px' }}>
				<Header
					hidden={hidden}
					setHidden={setHidden}
					closed={closed}
					setClosed={setClosed}
				/>
				<Collapse in={!hidden}>
					{inProgressCount > 0 && (
						<Row style={{ padding: '8px 20px', backgroundColor: '#2D3CE614' }}>
							<Typography variant="body2">
								Preparing {numberAndPluralizeWord(inProgressCount, 'report')}...
							</Typography>
						</Row>
					)}
					<Column style={{ maxHeight: 332, overflowY: 'scroll' }}>
						{fileRequests.map((fr) => (
							<FileRequestRow
								key={fr.id}
								fileRequest={fr}
								onDownload={handleDownload}
							/>
						))}
						<Spacer height={4} />
					</Column>
				</Collapse>
			</Paper>
		</Snackbar>
	);
};
