import _ from 'lodash';
import moment from 'moment';

import { DateRangeFilter, MultiValueFilter } from '@rentcheck/types';

/**
 * Builds a Multi Value filter from a Multi Value filter of objects
 */
export const buildMVFilter = (
	filter: MultiValueFilter<{ id: string }> | undefined
): MultiValueFilter<string> | undefined => {
	if (!filter) {
		return undefined;
	}

	if (!filter.value.length) {
		return undefined;
	}

	return {
		value: _.map(filter.value, 'id'),
		condition: filter.condition,
	};
};

/**
 * Builds a Multi Value filter from a string array
 */
export const buildSMVFilter = <T>(
	filter: T[] | undefined
): MultiValueFilter<T> | undefined => {
	if (!filter) {
		return undefined;
	}

	if (!filter.length) {
		return undefined;
	}

	return {
		value: filter,
		condition: 'any_of',
	};
};

/**
 * Builds a DateRange filter from a DateRange object
 */
export const buildDRFilter = (
	filter: { start?: number; end?: number } | undefined
): DateRangeFilter | undefined => {
	if (!filter) {
		return undefined;
	}

	return {
		start_date: filter.start
			? moment(filter.start).format('YYYY-MM-DD')
			: undefined,
		end_date: filter.end ? moment(filter.end).format('YYYY-MM-DD') : undefined,
	};
};
