import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Chip,
	DialogContent,
	IconButton,
	TableCell as MuiTableCell,
	SxProps,
	Typography,
	styled,
} from '@mui/material';
import { ApiInspection } from '@rentcheck/types';
import { Spacer } from 'components';
import { useHistory } from 'react-router-dom';
import ActionsMenu from 'screens/inspections/list/table/actions-menu';
import { colors } from 'theme';
import { formatDate, formattedAddress } from 'utils/helpers';

import Table from './table';

interface Props {
	open: boolean;
	total: number;
	data: ApiInspection[];
	fetch: (from: number) => Promise<ApiInspection[]>;
}

const InspectionsTable = ({ open, fetch, total, data }: Props) => {
	const headers = [
		'Address, City, Zip',
		'Inspection Type',
		'Due Date',
		'Date Completed',
		'Status',
	];

	return (
		<DialogContent>
			<Table
				open={open}
				fetch={fetch}
				data={data}
				total={total}
				renderRow={(d) => <InspectionsTableRow inspection={d} />}
				objectName="Units"
				headers={headers}
			/>
			<Spacer height={8} />
		</DialogContent>
	);
};

interface RowProps {
	inspection: ApiInspection;
}

const InspectionsTableRow = ({ inspection }: RowProps) => {
	const history = useHistory();

	const handleClick = () => {
		history.push(`/inspections/${inspection.id}`);
	};

	return (
		<>
			<TableCell onClick={handleClick}>
				{formattedAddress(inspection.property)}
			</TableCell>
			<TableCell onClick={handleClick}>
				<Typography>
					{inspection.inspection_template.name}
					{inspection.fast_track && (
						<FontAwesomeIcon
							icon={solid('bolt')}
							color={colors.primary}
							style={{ marginLeft: 10 }}
						/>
					)}
				</Typography>
				<Typography color="#61768F">{inspection.inspection_label}</Typography>
			</TableCell>
			<TableCell onClick={handleClick} align="center">
				{formatDate(inspection.due_date)}
			</TableCell>
			<TableCell onClick={handleClick} align="center">
				{formatDate(inspection.completed_date)}
			</TableCell>
			<TableCell onClick={handleClick}>
				{<InspectionStatus inspection={inspection} />}
			</TableCell>
			<TableCell align="center">
				<ActionsMenu inspectionIds={[inspection.id]} archived={false}>
					<IconButton size="small" sx={{ width: 28, marginLeft: -2 }}>
						<FontAwesomeIcon icon={solid('ellipsis-v')} color="gray" />
					</IconButton>
				</ActionsMenu>
			</TableCell>
		</>
	);
};

const InspectionStatus = ({ inspection }: RowProps) => {
	const createSx = (status: string): SxProps => {
		const needsAttention = ['Awaiting Review', 'Revision Review'].includes(
			status
		);
		const isComplete = ['Completed', 'Approved'].includes(status);

		const baseSx = {
			width: 170,
			fontWeight: 500,
			backgroundColor: '#eaeaea',
			color: '#353535',
		};

		if (needsAttention) {
			return {
				...baseSx,
				backgroundColor: '#101010',
				color: '#ffffff',
			};
		}

		if (isComplete) {
			return {
				...baseSx,
				border: '2px solid #8d8d8d',
				backgroundColor: '#ffffff',
				color: '#535353',
			};
		}

		return baseSx;
	};

	return (
		<Chip
			sx={createSx(inspection.inspection_status)}
			label={inspection.inspection_status}
		/>
	);
};

const TableCell = styled(MuiTableCell)`
	cursor: pointer;
`;

export default InspectionsTable;
