import { Accordion, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { integrations } from 'assets';
import { Spacer } from 'components';
import { useTypedSelector } from 'store/reducers/rootReducer';

import { Permissions } from '@rentcheck/biz';
import { useLatchelIntegrations } from 'hooks/latchel-integrations';
import Content from '../common/content';
import Header from '../common/header';
import Active from './active';
import Inactive from './inactive';

const headerProps = {
	icon: integrations.latchel,
	title: 'Latchel',
	body: 'Create Latchel Work Orders instantly from maintenance issues identified during RentCheck inspections.',
	route: '/account/integrations/latchel',
};

const Latchel = () => {
	const location = useLocation();

	const subscription = useTypedSelector((state) => state.subscription);
	const { latchelIntegrations } = useLatchelIntegrations();

	const expanded = location.pathname.startsWith(headerProps.route);
	const canInstall = Permissions.Integrations.Latchel.canInstall(subscription);

	return (
		<Accordion expanded={expanded}>
			<Header {...headerProps} active={latchelIntegrations.length > 0} />
			<Content>
				<Typography>
					The Latchel integration allows you to create Latchel Work Orders from
					items flagged on RentCheck inspections. Select one or multiple flags
					to send to Latchel as a work order.
				</Typography>
				<Spacer height={8} />
				{canInstall && <Active />}
				{!canInstall && <Inactive />}
			</Content>
		</Accordion>
	);
};

export default Latchel;
