import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip, SxProps } from '@mui/material';
import { Feature, FeatureRating } from '@rentcheck/types';
import { useRef } from 'react';

interface Props {
	feature: Feature;
	fullWidth?: boolean;
	editable?: boolean;
	onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const Rating = ({ feature, fullWidth, onClick, editable }: Props) => {
	const chipRef = useRef<HTMLDivElement>(null);

	if (feature.not_applicable) {
		return (
			<Chip
				variant="outlined"
				label={'N/A'}
				sx={createChipSx(
					'#354351',
					'#232E3A1A',
					'#232E3A3D',
					'#232E3A1A',
					'#232E3A1A',
					fullWidth
				)}
			/>
		);
	}

	if (!feature.rating) return null;

	const icon = {
		Good: regular('smile'),
		Fair: regular('meh'),
		Poor: regular('frown'),
	}[feature.rating];

	const handleEditClick = (e: React.MouseEvent<HTMLElement>) => {
		if (!chipRef.current) return;

		const redirectedEvent = { ...e };

		redirectedEvent.target = chipRef.current;
		redirectedEvent.currentTarget = chipRef.current;

		onClick?.(redirectedEvent);
	};

	return (
		<Chip
			ref={chipRef}
			variant="outlined"
			label={feature.rating}
			sx={chipSx(feature.rating, fullWidth)}
			icon={<FontAwesomeIcon icon={icon} />}
			deleteIcon={
				editable ? (
					<FontAwesomeIcon
						icon={solid('chevron-down')}
						color="#000"
						style={{
							fontSize: 12,
							color: '#000',
							position: 'absolute',
							right: 10,
						}}
					/>
				) : undefined
			}
			onDelete={editable ? handleEditClick : undefined}
			onClick={onClick}
		/>
	);
};

const createChipSx = (
	iconColor: string,
	backgroundColor: string,
	hoverColor: string,
	borderColor: string,
	borderHoverColor: string,
	fullWidth?: boolean
): SxProps => {
	return {
		fontWeight: 500,
		backgroundColor: backgroundColor,
		borderColor: borderColor,
		marginRight: 1,
		marginTop: 0.5,
		marginBottom: 0.5,
		width: fullWidth ? '100%' : 100,

		'.MuiChip-icon': {
			color: iconColor,
		},

		'&&:hover': {
			backgroundColor: hoverColor,
			borderColor: borderHoverColor,
		},
	};
};

const chipSx = (rating: FeatureRating, fullWidth?: boolean) => {
	switch (rating) {
		case 'Good':
			return createChipSx(
				'#36B45F',
				'#36B45F1F',
				'#36B45F3D',
				'#36B45F1F',
				'#36B45F1F',
				fullWidth
			);
		case 'Fair':
			return createChipSx(
				'#DB5C09',
				'#EB830D1F',
				'#EB830D3D',
				'#EB830D1F',
				'#EB830D1F',
				fullWidth
			);
		case 'Poor':
			return createChipSx(
				'#B90B17',
				'#F04D4C1F',
				'#F04D4C3D',
				'#F04D4C1F',
				'#F04D4C1F',
				fullWidth
			);
	}
};

export default Rating;
