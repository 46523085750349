import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Typography } from '@mui/material';
import IconButton from 'components/icon-button';
import { ReactNode, ReactNodeArray } from 'react';
import styled from 'styled-components';
import * as theme from 'theme';

interface Props {
	children: ReactNode | ReactNodeArray;
	resetFilters: () => void;
	badgeCount: number;
}

const FilterButton = ({ children, badgeCount, resetFilters }: Props) => {
	return (
		<IconButton
			tooltip="Filters"
			badgeCount={badgeCount}
			icon={regular('sliders-h')}
			dismissMenuOnClick={false}
			width={300}>
			<FilterMenuHeader resetFilters={resetFilters} />
			{children}
		</IconButton>
	);
};

interface HeaderProps {
	resetFilters: () => void;
}

const FilterMenuHeader = ({ resetFilters }: HeaderProps) => {
	return (
		<HeaderContainer>
			<Typography variant="caption" color="#445567" sx={{ fontWeight: 600 }}>
				Filter by
			</Typography>
			<ResetFiltersButton onClick={resetFilters}>
				<Typography variant="caption">Reset</Typography>
			</ResetFiltersButton>
		</HeaderContainer>
	);
};

const HeaderContainer = styled.div`
	border-bottom: 1px solid ${theme.colors.lightGray};
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: ${theme.spacingPx(3)};
`;

const ResetFiltersButton = styled.button`
	background-color: ${theme.colors.transparent};
	border: 0;
	padding: 0;
	transition: opacity 0.2s ease-in-out;
	color: ${theme.colors.primary};

	&:hover {
		opacity: 0.6;
	}
`;

export default FilterButton;
