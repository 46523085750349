import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Typography } from '@mui/material';
import { CenteredRow, Spacer, TopSpacer } from 'components';
import { useTypedSelector } from 'store/reducers/rootReducer';

const LockedNotice = () => {
	const data = useTypedSelector((state) => state.dashboard.data);

	if (!data || data.stats.overall_total_inspections >= 10) {
		return <TopSpacer />;
	}

	return (
		<CenteredRow
			style={{
				height: 50,
				backgroundColor: '#E9F7EC',
				borderRight: '1px solid #CCD6E1',
				borderLeft: '1px solid #CCD6E1',
				borderBottom: '2px solid #51C978',
			}}>
			<FontAwesomeIcon icon={regular('bell-on')} color="#1B823E" />
			<Spacer width={3} />
			<Typography color="#232E3A">
				Create 10 inspections to access dashboard data. The data below is
				sampled.{' '}
				<Link
					color="#232E3A"
					href="https://help.getrentcheck.com/en/articles/6574817-the-rentcheck-dashboard"
					target="_blank">
					Learn More
				</Link>
			</Typography>
		</CenteredRow>
	);
};

export default LockedNotice;
