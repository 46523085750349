import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApiInspectionTemplateDigest } from '@rentcheck/types';
import { landing } from 'assets';
import BaseCard from 'components/template-card/base-card';
import _ from 'lodash';
import { ReactNode } from 'react';

interface Props {
	template: ApiInspectionTemplateDigest;
	onClick: (template: ApiInspectionTemplateDigest) => void;
	actions?: ReactNode;
	selected: boolean;
}

const Icon = ({ template }: Pick<Props, 'template'>) => {
	const icon = {
		unit: solid('home'),
		building: solid('buildings'),
		community: solid('bench-tree'),
	}[template.property_type];

	if (template.is_rc_template) {
		return <img src={landing.rentCheckLogoNotext} style={{ height: 17 }} />;
	}

	return <FontAwesomeIcon icon={icon} color="white" />;
};

export default ({ template, onClick, actions, selected }: Props) => {
	const handleClick = () => {
		onClick(template);
	};

	const templateType = `${
		template.is_rc_template ? 'RentCheck' : ''
	} ${_.capitalize(template.property_type)} Inspection`;

	return (
		<BaseCard
			title={template.name}
			subtitle={template.internal_label}
			templateType={templateType}
			description={template.description}
			backgroundColor={selected ? '#F6F5FF' : '#fff'}
			hoverBackgroundColor={selected ? '#e5e4ee' : '#eee'}
			opacity={1}
			icon={<Icon template={template} />}
			iconBackgroundColor={template.is_rc_template ? '#F6F5FF' : '#2D3CE6'}
			onClick={handleClick}
			actions={actions}
		/>
	);
};
