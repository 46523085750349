import { TextField, Typography } from '@mui/material';
import { Feature, TemplateQuestion } from '@rentcheck/types';
import React from 'react';

import { Column, Spacer } from 'components';

interface Props {
	index: number;
	question: TemplateQuestion;
	featurePayload: Partial<Feature>;
	setFeaturePayload: (payload: Partial<Feature>) => void;
}

const FreeTextQuestion = ({
	index,
	question,
	featurePayload,
	setFeaturePayload,
}: Props) => {
	const responses = featurePayload.responses ?? [];
	const answer = responses[index];

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const updatedResponses = [...responses];
		updatedResponses[index] = e.target.value;

		setFeaturePayload({
			...featurePayload,
			responses: updatedResponses,
		});
	};

	return (
		<Column
			style={{
				marginBottom: 8,
				marginTop: 12,
			}}>
			<Typography color="#00000099">{question.title}</Typography>
			<Spacer height={2} />

			<TextField
				id="free-text-response"
				value={answer ?? ''}
				onChange={handleChange}
				variant="filled"
				multiline
			/>
		</Column>
	);
};

export default FreeTextQuestion;
