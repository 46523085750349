import { ApiUser } from '@rentcheck/types';
import firebase from 'firebase/app';

export const createResponse = (response: string, user: ApiUser) => {
	const ref = firebase.firestore().collection('integration_surveys').doc();
	return ref.set({
		response,
		email: user.email,
		name: user.name,
		id: ref.id,
	});
};
