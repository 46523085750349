import { Chip, Typography } from '@mui/material';
import { Utils } from '@rentcheck/biz';
import { ApiInspection } from '@rentcheck/types';
import { Column, SpacedRow } from 'components';
import { useStoreFeatures } from 'hooks/features';

interface Props {
	inspection: ApiInspection;
}

export default ({ inspection }: Props) => {
	const features = useStoreFeatures(inspection.id);

	if (!inspection.health_score) return null;

	const score = inspection.health_score;

	score.score = Math.min(score.score, 100);

	return (
		<Column
			style={{
				margin: 16,
			}}>
			<SpacedRow>
				<Column>
					<Typography variant="overline" mr={2}>
						Inspection Score:
					</Typography>
					<Typography variant="caption">
						{Utils.Helpers.numberAndPluralizedWord(features.length, 'feature')}
					</Typography>
				</Column>
				<Chip label={`${score.score.toFixed(0)}/100`} />
			</SpacedRow>
			{!!score.areas_of_concern?.length && (
				<Column style={{ marginTop: 16 }}>
					<Typography variant="body2" mb={0.25} color="#B00020">
						Areas of Concern:
					</Typography>
					{score.areas_of_concern.map((aoc) => (
						<Typography variant="caption">{aoc}</Typography>
					))}
				</Column>
			)}
		</Column>
	);
};
