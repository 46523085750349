import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Rules } from '@rentcheck/biz';
import { ApiInspection } from '@rentcheck/types';
import IconButton from 'components/icon-button';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FileRequestsActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';
import { Analytics } from 'utils';
import { getReportLocationForTemplate } from 'utils/helpers';

interface Props {
	inspection: ApiInspection;
}

export default ({ inspection }: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const accountSettings = useTypedSelector((state) => state.accountSettings);

	const [loading, setLoading] = useState(false);

	const handleDownloadPDF = async () => {
		const existingPdfLocation = getReportLocationForTemplate(
			inspection,
			accountSettings
		);

		if (existingPdfLocation) {
			Analytics.trackEvent('downloaded_inspection_report', {
				inspection_id: inspection.id,
			});

			window.open(existingPdfLocation);

			return;
		}

		setLoading(true);

		dispatch(
			FileRequestsActions.create({
				documentType: 'INSPECTION_REPORT',
				documentId: inspection.id,
				exportType: 'PDF',
				dontNotify: true,
				title: 'Inspection PDF Report',
				subtitle: `${inspection.inspection_template.name}, ${inspection.property.address}`,
				type: 'download',
			})
		).finally(() => {
			setLoading(false);
		});
	};

	if (!Rules.Inspections.canDownloadReport(inspection)) {
		return null;
	}

	return (
		<IconButton
			loading={loading}
			icon={regular('download')}
			tooltip="Download"
			onClick={handleDownloadPDF}
		/>
	);
};
