import { LoadingButton } from '@mui/lab';
import { Card, CardContent, TextField, Typography } from '@mui/material';
import { ApiUser } from '@rentcheck/types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Column, SpacedRow, Spacer } from 'components';
import { SnackbarActions } from 'store/actions';
import { Dispatch } from 'types';

import { AdminToolsApi } from '@rentcheck/api-frontend';
import BuildingsUploader from './buildings';
import ResidentsUploader from './residents';
import UnitsUploader from './units';

export default () => {
	const [email, setEmail] = useState('');
	const [loading, setLoading] = useState(false);

	const [user, setUser] = useState<ApiUser | undefined>(undefined);

	const dispatch: Dispatch = useDispatch();

	const search = async (email: string) => {
		setUser(undefined);
		setLoading(true);

		AdminToolsApi.getUserByEmailOrId(email)
			.then((response) => {
				if (!response) {
					dispatch(SnackbarActions.showError('User not found'));
					return;
				}

				setUser(response.user);
			})
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	return (
		<Card>
			<CardContent>
				<Typography variant="h5">Uploaders</Typography>
				<Spacer height={2} />
				<Typography>Search by user email:</Typography>
				<Spacer height={4} />

				<FormRow
					onSubmit={(e) => {
						e.preventDefault();
						search(email);
					}}>
					<TextField
						fullWidth
						variant="filled"
						type="string"
						placeholder="Email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>

					<Spacer width={6} />
					<LoadingButton
						type="submit"
						variant="contained"
						onClick={() => search(email)}
						loading={loading}>
						Search
					</LoadingButton>
				</FormRow>

				{!!user && (
					<SpacedRow style={{ alignItems: 'flex-start', paddingTop: 32 }}>
						<Column style={{ width: '50%' }}>
							<Card>
								<CardContent>
									<Typography variant="h6">{user.name}</Typography>
									<Typography>{user.type}</Typography>
									<Typography>{user.email}</Typography>
								</CardContent>
							</Card>
						</Column>

						<Column style={{ width: '48%', alignItems: 'flex-start' }}>
							<UnitsUploader user={user} />
							<Spacer height={4} />
							<BuildingsUploader user={user} />
							<Spacer height={4} />
							<ResidentsUploader user={user} />
						</Column>
					</SpacedRow>
				)}
			</CardContent>
		</Card>
	);
};

const FormRow = styled.form`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	text-align: center;
	width: 50%;
`;
