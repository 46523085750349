import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import firebase from 'firebase/app';
import { useEffect, useState } from 'react';
import { clarity } from 'react-microsoft-clarity';
import { BrowserRouter } from 'react-router-dom';
import { createTheme } from 'theme';

import Intercom from 'react-intercom';
import AuthController from 'screens/auth-controller';
import NavBar from 'screens/nav-bar';

import Routes from 'routes';

import ModalFlowsController from 'screens/modal-flows-controller';
import SnackbarController from 'screens/snackbar-controller';

import MaintenanceNotice from 'components/maintenance-notice';
import ImpersonateNotice from 'screens/account/admin/impersonate/notice';

import ModalBlockers from 'screens/modal-blockers';

import { APIConfig, IntercomApi } from '@rentcheck/api-frontend';
import { useTypedSelector } from 'store/reducers/rootReducer';

import { useUserSettings } from 'hooks/user-settings';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import FileRequests from 'screens/file-requests';
import styled from 'styled-components';
import { Analytics } from 'utils';
import { isEmbeddedInMobileApp } from 'utils/helpers';

/**
 * Initialize Microsoft Clarity only on prod
 */
if (process.env.firebase_projectId === 'imperent-699e4') {
	clarity.init(process.env.clarity_project_id);
}

/**
 * API Initialization
 */
APIConfig.setAppId(process.env.api_x_app_id);
APIConfig.setAppSecret(process.env.api_x_app_secret);
APIConfig.setBaseUrl(process.env.api_base_url);
APIConfig.setAuthProvider(firebase);

const App = () => {
	const authenticatedProfile = useTypedSelector(
		(state) => state.authenticatedUser
	);
	const activeProfile = useTypedSelector((state) => state.activeProfile);
	const subscription = useTypedSelector((state) => state.subscription);

	/**
	 * We're not using this hook yet, but we're initializing it here so it's
	 * available for use anywhere in the app with the correct data pre-loaded
	 */
	useUserSettings();

	const [defaultMaterialTheme] = useState(createTheme());

	useEffect(() => {
		const remoteConfig = firebase.remoteConfig();
		remoteConfig.settings.minimumFetchIntervalMillis = 43200000;
		remoteConfig.defaultConfig = {
			chargebee_site: 'getrentcheck',
			maintenance_settings_enabled: false,
			chargebee_url:
				'https://site_api_key:test_7vS9F21yTGjoG1iAekAIgPWsBdnHNB5U@getrentcheck-test.chargebee.com',
		};
		remoteConfig
			.fetch()
			.then(remoteConfig.activate())
			.catch((e) => Sentry.captureException(e));
	}, []);

	useEffect(() => {
		if (isEmbeddedInMobileApp()) {
			Analytics.keepButtonHidden();
		}

		if (!authenticatedProfile) {
			Analytics.logOut();
			return;
		}

		if (!activeProfile) {
			return;
		}

		/**
		 * If we're impersonating a user then we don't want to initialize the profile
		 * or attach the user to a subscription
		 */
		if (authenticatedProfile.id !== activeProfile.id) {
			return;
		}

		Analytics.initializeProfile(authenticatedProfile, subscription);

		if (subscription?.rentcheck_contact?.company_id) {
			IntercomApi.users
				.attachCompanyToCustomer({
					companyId: subscription.rentcheck_contact.company_id,
				})
				.catch(() => {});
		}
	}, [activeProfile?.id, authenticatedProfile, subscription]);

	const company = subscription?.rentcheck_contact?.company_id
		? {
				id: subscription?.rentcheck_contact?.company_id,
				name: subscription?.rentcheck_contact?.company,
				company_id: subscription?.rentcheck_contact?.company_id,
			}
		: undefined;

	const intercomUser = authenticatedProfile
		? {
				user_id: authenticatedProfile.id,
				email: authenticatedProfile.email,
				name: authenticatedProfile.name,
				user_type: authenticatedProfile.type,
				company,
			}
		: {};

	return (
		<BrowserRouter>
			<StyledEngineProvider injectFirst>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<ThemeProvider theme={defaultMaterialTheme}>
						<AppContainer>
							<AuthController />
							<NavBar />
							<ImpersonateNotice />
							<MaintenanceNotice />
							<ModalFlowsController />
							<SnackbarController />
							<ModalBlockers />
							<FileRequests />
							<Intercom appID={process.env.intercom_app_id} {...intercomUser} />
							<Routes />
						</AppContainer>
					</ThemeProvider>
				</LocalizationProvider>
			</StyledEngineProvider>
		</BrowserRouter>
	);
};

const AppContainer = styled.div`
	margin-top: ${isEmbeddedInMobileApp() ? '0' : '56px'};
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export default App;
