import React from 'react';
import { useHistory } from 'react-router-dom';
import { TableRow, TableCell, Checkbox, Typography } from '@mui/material';

import { WorkOrder } from '@rentcheck/api-frontend';

import { Column } from 'components';
import { formatDate } from 'utils/helpers';

interface Props {
	workOrder: WorkOrder;
	selectedIds: string[];
	selectId: (id: string) => void;
}

const WorkOrderRow = ({ workOrder, selectedIds, selectId }: Props) => {
	const history = useHistory();

	const handleClick = () => {
		history.push(`/maintenance/work-orders/${workOrder.id}`);
	};

	return (
		<TableRow
			sx={{
				'&:last-child td, &:last-child th': { border: 0 },
				cursor: 'pointer',
			}}>
			<TableCell>
				<Checkbox
					size="small"
					checked={selectedIds.includes(workOrder.id)}
					onChange={() => selectId(workOrder.id)}
				/>
			</TableCell>

			<TableCell onClick={handleClick}>{workOrder.property.address}</TableCell>
			<TableCell onClick={handleClick}>{workOrder.property.city}</TableCell>
			<TableCell onClick={handleClick}>{workOrder.property.zip_code}</TableCell>
			<TableCell onClick={handleClick}>{workOrder.name}</TableCell>
			<TableCell onClick={handleClick}>
				{workOrder.inspection.template.name}
			</TableCell>
			<TableCell onClick={handleClick}>
				<Column>
					<Typography>
						{workOrder.rentmanager?.status ?? workOrder.status}
					</Typography>
					<Typography color="#61768F">{workOrder.latchel?.status}</Typography>
				</Column>
			</TableCell>
			<TableCell onClick={handleClick}>
				{formatDate(workOrder.created_date)}
			</TableCell>
		</TableRow>
	);
};

export default WorkOrderRow;
