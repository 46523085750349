import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Button,
	Card,
	CardContent,
	Dialog,
	DialogActions,
	DialogContent,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { ApiSubscription, ChargebeeAddon } from '@rentcheck/types';
import { ChargebeeApi } from 'api';
import { DialogTitle, Row, Spacer } from 'components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	ActiveProfileActions,
	ModalFlowActions,
	SessionOptionsActions,
	SubscriptionActions,
} from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { colors } from 'theme';
import { Dispatch } from 'types';
import { Analytics } from 'utils';

import { Utils } from '@rentcheck/biz';
import SuccessDialog from './success';

interface Props {
	open: boolean;
}

const addonDescription = (addon: ChargebeeAddon) => {
	const price = `$${addon.price / 100}`;
	const priceModel =
		addon.pricing_model === 'flat_fee' ? '/ month' : 'per unit / month';
	return `${price} ${priceModel}`;
};

const addonPropsForChargebeeButton = (addons: ChargebeeAddon[]) => {
	const addonProps: { [key: string]: string } = {};

	addons.forEach((addon, index) => {
		addonProps[`data-cb-addons_id_${index}`] = addon.id;
		addonProps[`data-cb-addons_quantity_${index}`] = '1';
	});

	return addonProps;
};

const SetupBilling = ({ open }: Props) => {
	const dispatch: Dispatch = useDispatch();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	const profile = useTypedSelector((state) => state.activeProfile);
	const billingSetupDismissed = useTypedSelector(
		(state) => state.sessionOptions.billingSetupDismissed
	);

	const [checkoutSuccess, setCheckoutSuccess] = useState(false);
	const [checkoutClosed, setCheckoutClosed] = useState(false);

	useEffect(() => {
		if (!profile) return;
		if (!open) return;

		ChargebeeApi.initChargebeeModal(
			profile,
			() => setCheckoutClosed(true),
			() => setCheckoutSuccess(true)
		);
	}, [profile, open, billingSetupDismissed]);

	useEffect(() => {
		if (!checkoutSuccess || !checkoutClosed) return;

		Analytics.trackEvent('added payment details', {
			billing_email: profile.email,
			plan_id: profile.prospect_data?.chargebee_plan.id ?? '',
			estimated_portfolio_size: profile.prospect_data?.portfolio_size ?? 0,
		});

		dispatch(SubscriptionActions.waitForSubscriptionWebhook())
			.then(() => {
				if (!profile.prospect_data) {
					return;
				}

				dispatch(
					ActiveProfileActions.update({
						payment_info_provided: true,
					})
				);
			})
			.then(() => dispatch(SubscriptionActions.getSubscription()))
			.then((subscription: ApiSubscription) => {
				if (Utils.Subscriptions.isOnEssentialsPlan(subscription)) {
					Analytics.trackEvent('essential trial signup', {
						chargebee_customer_id: subscription.plan.customer_id,
						chargebee_sub_id: subscription.plan.sub_id,
					});
				}
			})
			.catch(() => {});
	}, [checkoutSuccess, checkoutClosed]);

	const handleClose = () => {
		dispatch(
			ModalFlowActions.showConfirmationModal({
				title: 'Are you sure?',
				body1: [
					'Do you want to exit and lose all your progress in setting up your account?',
				],
				body2: [
					'You will be directed to the free RentCheck experience and will be prompted to setup your account again when you log back in.',
				],
				buttons: [
					{
						title: 'Go Back',
						color: 'secondary',
						variant: 'outlined',
						onClick: () => dispatch(ModalFlowActions.closeConfirmationModal()),
					},
					{
						title: 'Exit & Close',
						color: 'error',
						variant: 'contained',
						onClick: () => {
							Analytics.trackEvent('onboarding dismissed billing setup');

							dispatch(SessionOptionsActions.setBillingSetupDismissed(true));
							dispatch(ModalFlowActions.closeConfirmationModal());
						},
					},
				],
			})
		);
	};

	if (!profile?.prospect_data) return null;

	const rootOpen =
		open && !billingSetupDismissed && !(checkoutSuccess && checkoutClosed);
	const finishedChargebeeFlow =
		!billingSetupDismissed && checkoutSuccess && checkoutClosed;

	const planIsPerUnit =
		profile.prospect_data.chargebee_plan.charge_model === 'per_unit';

	const isEssentialPlan =
		profile.prospect_data.chargebee_plan.id.startsWith('essential-plan');

	const dialogSubtitle = isEssentialPlan
		? 'Below are your plan details. Click "Get Started" to activate your subscription and confirm your account details.'
		: 'Below are your plan details. Click “Enter Payment Details” to finish setting up your account.';

	const buttonText = isEssentialPlan ? 'Get Started' : 'Enter Payment Details';

	return (
		<>
			<Dialog open={rootOpen} fullScreen={fullScreen}>
				<DialogTitle title="Plan Overview" bottomSubtitle={dialogSubtitle} />
				<DialogContent>
					<Row>
						<FontAwesomeIcon
							icon={solid('check-circle')}
							color={colors.primary}
							size="lg"
						/>
						<Spacer width={4} />
						<Typography variant="h5">
							{profile.prospect_data.chargebee_plan.name}
						</Typography>
					</Row>

					<Spacer height={6} />

					<Card>
						<CardContent>
							<Typography variant="subtitle2">Description:</Typography>
							<Spacer height={2} />
							<Typography>
								{profile.prospect_data.chargebee_plan.description}
							</Typography>
						</CardContent>
					</Card>

					{profile.prospect_data.chargebee_addons.length > 0 && (
						<>
							<Spacer height={3} />
							<Card>
								<CardContent>
									<Typography variant="subtitle2">Addons:</Typography>
									<Spacer height={2} />

									{profile.prospect_data.chargebee_addons.map((addon) => (
										<Typography key={addon.id}>
											{addon.name} - {addonDescription(addon)}
										</Typography>
									))}
								</CardContent>
							</Card>
						</>
					)}
				</DialogContent>

				<DialogActions>
					<Button variant="outlined" color="secondary" onClick={handleClose}>
						Close
					</Button>
					<Button
						variant="contained"
						data-cb-type="checkout"
						data-cb-plan-id={profile.prospect_data.chargebee_plan.id}
						data-cb-plan-quantity={planIsPerUnit ? 0 : undefined}
						{...addonPropsForChargebeeButton(
							profile.prospect_data.chargebee_addons
						)}>
						{buttonText}
					</Button>
				</DialogActions>
			</Dialog>

			<SuccessDialog open={finishedChargebeeFlow} />
		</>
	);
};

export default SetupBilling;
