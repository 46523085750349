import { Card, CardContent, Divider, Typography } from '@mui/material';
import { LatchelProperty, LatchelVendor } from '@rentcheck/api-frontend';
import { Spacer } from 'components';
import { useCreateWorkOrderModalData } from 'hooks/modals';

import Description from './description';
import Dispatch from './dispatch';
import VendorInstructions from './vendor-instructions';

interface Props {
	selectedFlagIds: string[];
	property: LatchelProperty;
	skill: string | null;
	description: string;
	setDescription: (value: string) => void;
	vendor: LatchelVendor | null;
	setVendor: (value: LatchelVendor | null) => void;
	vendorInstructions: string;
	setVendorInstructions: (value: string) => void;
	useDefaultDispatch: boolean;
	setUseDefaultDispatch: (value: boolean) => void;
}

const DispatchSettings = ({
	selectedFlagIds,
	skill,
	property,
	description,
	setDescription,
	vendor,
	setVendor,
	vendorInstructions,
	setVendorInstructions,
	useDefaultDispatch,
	setUseDefaultDispatch,
}: Props) => {
	const modalData = useCreateWorkOrderModalData('Latchel');
	const inspection = modalData?.metadata?.inspection;

	if (!inspection) return null;

	return (
		<Card>
			<CardContent>
				<Typography fontWeight={600}>
					Latchel will notify residents after work order is created.
				</Typography>

				<Divider sx={{ marginTop: 2, marginBottom: 2 }} />
				<Dispatch
					value={vendor}
					setValue={setVendor}
					skill={skill}
					property={property}
					selectedFlagIds={selectedFlagIds}
					useDefaultDispatch={useDefaultDispatch}
					setUseDefaultDispatch={setUseDefaultDispatch}
				/>
				<Divider sx={{ marginTop: 2, marginBottom: 2 }} />
				<Description
					selectedFlagIds={selectedFlagIds}
					value={description}
					setValue={setDescription}
				/>
				<Spacer height={4} />
				<VendorInstructions
					value={vendorInstructions}
					setValue={setVendorInstructions}
				/>
			</CardContent>
		</Card>
	);
};

export default DispatchSettings;
