import { Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';
import { Dispatch, ReduxFn } from 'types';

import { CenteredColumn } from 'components/layout/Column';
import { Row } from 'components/layout/Row';
import LottieWrapper from 'components/lottie-wrapper';
import Spacer from 'components/Spacer';
import { useOnScreen } from 'utils/hooks';

interface ActionInterface {
	getAll: (offset: number | undefined, filterState: any) => ReduxFn<any[]>;
	pageSize?: number;
}

interface Props {
	objectName: string;
	action: ActionInterface;
	filterState: any;
	offset: number;
}

const InfiniteScroll = ({
	action: { getAll, pageSize = 20 },
	offset,
	filterState,
	objectName,
}: Props) => {
	const isVisibleRef = useRef<HTMLDivElement>(null);
	const isVisible = useOnScreen(isVisibleRef);

	const isOutOfResults = (offset: number) => {
		return offset < pageSize;
	};

	const dispatch: Dispatch = useDispatch();

	const [needsToFetch, setNeedsToFetch] = useState(false);
	const [loading, setLoading] = useState(false);
	const [outOfResults, setOutOfResults] = useState(isOutOfResults(offset));

	useEffect(() => {
		if (!isVisible) {
			return;
		}

		setNeedsToFetch(true);
	}, [isVisible]);

	const handleFetch = () => {
		setLoading(true);
		setNeedsToFetch(false);
		dispatch(getAll(offset, filterState))
			.then((data) => setOutOfResults(data.length < pageSize))
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		if (!needsToFetch) {
			return;
		}

		if (outOfResults) {
			return;
		}

		if (loading) {
			return;
		}

		handleFetch();
	}, [needsToFetch, loading, outOfResults]);

	useEffect(() => {
		setOutOfResults(false);
	}, [filterState]);

	useEffect(() => {
		isOutOfResults(offset) && setOutOfResults(true);
	}, [offset]);

	if (outOfResults) return null;

	return (
		<Row ref={isVisibleRef} style={{ justifyContent: 'center' }}>
			<CenteredColumn>
				<Spacer height={4} />
				<LottieWrapper variant="dog-fetching" width={150} height={150} />

				<Typography color="#232e3a" align="center" fontWeight="500">
					We're fetching your {objectName}...
				</Typography>
			</CenteredColumn>
		</Row>
	);
};

export default InfiniteScroll;
