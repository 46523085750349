import { Chip } from '@mui/material';
import { WorkOrder } from '@rentcheck/api-frontend';

interface Props {
	workOrder: WorkOrder;
}

const StatusChip = ({ workOrder }: Props) => {
	if (workOrder.rentmanager?.status) {
		return <Chip variant="filled" label={workOrder.rentmanager.status} />;
	}

	return <Chip variant="filled" label={workOrder.status} />;
};

export default StatusChip;
