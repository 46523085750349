import { TableCell, TableRow } from '@mui/material';
import { ImportDigest } from '@rentcheck/types';
import Creator from './creator';
import Errors from './errors';
import Status from './status';
import Type from './type';

interface Props {
	object: ImportDigest;
}

export default ({ object }: Props) => {
	return (
		<TableRow>
			<TableCell>
				<Type object={object} />
			</TableCell>
			<TableCell>{object.new_count}</TableCell>
			<TableCell>{object.deleted_count}</TableCell>
			<TableCell>
				<Errors object={object} />
			</TableCell>
			<TableCell>
				<Creator object={object} />
			</TableCell>
			<TableCell>
				<Status object={object} />
			</TableCell>
		</TableRow>
	);
};
