import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import { ImportDigest } from '@rentcheck/types';
import { useIsMobileBreakpoint } from 'utils/hooks';

interface Props {
	object: ImportDigest;
}

export default ({ object }: Props) => {
	const isMobile = useIsMobileBreakpoint('md');

	if (object.errors_file_path) {
		return (
			<Tooltip title={`Download errors`}>
				<a
					href={object.errors_file_path}
					target="_blank"
					style={{
						color: 'inherit',
						textDecoration: 'underline',
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
					}}>
					{isMobile && (
						<FontAwesomeIcon
							style={{ marginRight: 8 }}
							icon={solid('exclamation-triangle')}
							color="#DB5C09"
						/>
					)}

					{object.error_count}

					{!isMobile && (
						<FontAwesomeIcon
							style={{ marginLeft: 8 }}
							icon={solid('exclamation-triangle')}
							color="#DB5C09"
						/>
					)}
				</a>
			</Tooltip>
		);
	}
	return <div>{object.error_count}</div>;
};
