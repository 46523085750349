import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent } from '@mui/material';
import { LatchelProperty, LatchelVendor } from '@rentcheck/api-frontend';
import * as Sentry from '@sentry/react';
import { Spacer } from 'components';
import { useCreateWorkOrderModalData } from 'hooks/modals';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FeatureActions, SnackbarActions } from 'store/actions';

import { Step } from '..';

import DispatchSettings from './dispatch-settings';
import Flags from './flags';
import Name from './name';

interface Props {
	property: LatchelProperty;
	skill: string | null;
	createWorkOrder: () => Promise<void>;
	nextStep: (step: Step) => void;
	previousStep: () => void;
	name: string;
	setName: (value: string) => void;
	selectedFlagIds: string[];
	setSelectedFlagIds: (value: string[]) => void;
	description: string;
	setDescription: (value: string) => void;
	vendor: LatchelVendor | null;
	setVendor: (value: LatchelVendor | null) => void;
	vendorInstructions: string;
	setVendorInstructions: (value: string) => void;
}

const WorkOrderDetails = ({
	createWorkOrder,
	nextStep,
	previousStep,
	skill,
	property,
	name,
	setName,
	selectedFlagIds,
	setSelectedFlagIds,
	description,
	setDescription,
	vendor,
	setVendor,
	vendorInstructions,
	setVendorInstructions,
}: Props) => {
	const dispatch = useDispatch();

	const modalData = useCreateWorkOrderModalData('Latchel');
	const { inspection, flags } = modalData?.metadata ?? {};

	const [useDefaultDispatch, setUseDefaultDispatch] = useState(true);
	const [loading, setLoading] = useState(false);

	const handleNext = () => {
		if (!inspection) return;

		setLoading(true);

		createWorkOrder()
			.then(() =>
				dispatch(FeatureActions.requestInspectionFeatures(inspection.id))
			)
			.then(() => nextStep('Success'))
			.catch((e) => {
				Sentry.captureException(e);
				dispatch(SnackbarActions.showError());
			})
			.finally(() => setLoading(false));
	};

	const handleBack = () => {
		setName('');
		setSelectedFlagIds(flags.map((f) => f.id));
		setDescription('');
		setVendorInstructions('');

		previousStep();
	};

	const isNextDisabled =
		!name || selectedFlagIds.length === 0 || (!useDefaultDispatch && !vendor);

	return (
		<>
			<DialogContent>
				<Spacer height={10} />
				<Name value={name} setValue={setName} />
				<Spacer height={10} />
				<Flags value={selectedFlagIds} setValue={setSelectedFlagIds} />
				<Spacer height={5} />
				<DispatchSettings
					skill={skill}
					property={property}
					selectedFlagIds={selectedFlagIds}
					description={description}
					setDescription={setDescription}
					vendor={vendor}
					setVendor={setVendor}
					vendorInstructions={vendorInstructions}
					setVendorInstructions={setVendorInstructions}
					useDefaultDispatch={useDefaultDispatch}
					setUseDefaultDispatch={setUseDefaultDispatch}
				/>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" color="secondary" onClick={handleBack}>
					Back
				</Button>
				<LoadingButton
					variant="contained"
					onClick={handleNext}
					disabled={isNextDisabled}
					loading={loading}>
					Next
				</LoadingButton>
			</DialogActions>
		</>
	);
};

export default WorkOrderDetails;
