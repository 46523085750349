import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DialogContent, Divider, Typography } from '@mui/material';
import {
	CreateError,
	CreateInspectionsResult,
	CreateWarning,
} from '@rentcheck/types';
import { CenteredColumn, Column, DialogTitle, Spacer } from 'components';
import { colors } from 'theme';
import { formattedAddress } from 'utils/helpers';

type InfoType = 'Error' | 'Warning';
const getStatus = (info: CreateError | CreateWarning, type: InfoType) => {
	if (info.status?.length) {
		return <Typography>Status: {info.status}</Typography>;
	}

	if (type === 'Error') {
		return <Typography>Status: Inspection not created</Typography>;
	}

	if (type === 'Warning') {
		return <Typography>Status: Inspection created with warnings</Typography>;
	}
};

const InfoRow = ({
	info,
	type,
}: {
	info: CreateError | CreateWarning;
	type: InfoType;
}) => {
	return (
		<Column style={{ marginTop: 12 }}>
			<Typography variant="subtitle2">
				{formattedAddress(info.property)}
			</Typography>
			<Spacer height={1} />
			{getStatus(info, type)}
			<Spacer height={1} />
			<Typography>
				{type}: {info.reason}
			</Typography>
			<Spacer height={3} />
			<Divider />
		</Column>
	);
};

interface Props {
	renderAlmostThereModal: boolean;
	creationResult: CreateInspectionsResult;
	handleClose: () => void;
}

const Content = ({
	renderAlmostThereModal,
	creationResult,
	handleClose,
}: Props) => {
	if (renderAlmostThereModal) {
		return (
			<DialogContent>
				<CenteredColumn>
					<FontAwesomeIcon
						icon={solid('roller-coaster')}
						color={colors.warning}
					/>
					<Spacer height={2} />
					<Typography variant="h6">Almost there</Typography>
				</CenteredColumn>

				<Spacer height={4} />

				<Typography>
					{creationResult?.success?.length || 0} inspections created
					successfully.
				</Typography>
				<Spacer height={2} />
				<Typography>
					{creationResult?.warnings?.length || 0} inspection warnings.
				</Typography>
				<Spacer height={2} />
				<Typography>
					{creationResult?.errors?.length || 0} inspection errors.
				</Typography>
			</DialogContent>
		);
	}

	return (
		<>
			{creationResult?.errors?.length ? (
				<>
					<DialogTitle
						title="Inspection Errors"
						icon={solid('calendar-circle-exclamation')}
						iconColor={colors.error}
						onClose={handleClose}
					/>
					<DialogContent>
						{creationResult.errors.map((e) => (
							<InfoRow info={e} type="Error" />
						))}
					</DialogContent>
				</>
			) : (
				<></>
			)}
			{creationResult?.warnings?.length ? (
				<>
					<DialogTitle
						title="Inspection Warnings"
						icon={solid('calendar-circle-exclamation')}
						iconColor={colors.warning}
						onClose={creationResult?.errors?.length ? undefined : handleClose}
					/>
					<DialogContent>
						{creationResult.warnings.map((e) => (
							<InfoRow info={e} type="Warning" />
						))}
					</DialogContent>
				</>
			) : (
				<></>
			)}
		</>
	);
};

export default Content;
