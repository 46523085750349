import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { InspectionsApi } from '@rentcheck/api-frontend';
import { ApiInspectionWithTemplate } from '@rentcheck/types';

import { Screens } from 'components';
import { ModalFlowActions } from 'store/actions';

import { handleClose } from './common';
import Skeleton from './skeleton';

export default () => {
	const location = useLocation();
	const dispatch = useDispatch();

	const URLParameters = new URLSearchParams(location.search);
	const inspectionId = URLParameters.get('inspection_id');
	const nonce = URLParameters.get('nonce');

	const [loading, setLoading] = useState(true);

	const handleShare = (inspection: ApiInspectionWithTemplate) => {
		dispatch(
			ModalFlowActions.showShareInspectionModal({
				object: inspection,
			})
		);
	};

	useEffect(() => {
		if (!inspectionId) {
			setLoading(false);

			return;
		}

		setLoading(true);

		InspectionsApi.getById(inspectionId)
			.then((inspection) => handleShare(inspection))
			.catch(handleClose)
			.finally(() => setLoading(false));
	}, [location.pathname, nonce, inspectionId]);

	if (loading) {
		return <Skeleton />;
	}

	return <Screens.Container />;
};
