import { ApiInspection } from '@rentcheck/types';

interface Props {
	inspection: ApiInspection;
}

export default ({ inspection }: Props) => {
	if (
		inspection.inspection_template.display_name ===
		inspection.inspection_template.name
	) {
		return null;
	}

	return (
		<div style={{ marginBottom: 4 }}>
			{inspection.inspection_template.display_name}
		</div>
	);
};
