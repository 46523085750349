import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ApiMaintenanceReport } from '@rentcheck/types';
import IconButton from 'components/icon-button';
import { useDispatch } from 'react-redux';
import { ModalFlowActions } from 'store/actions';

interface Props {
	report: ApiMaintenanceReport;
}

export default ({ report }: Props) => {
	const dispatch = useDispatch();

	const handleShare = () => {
		dispatch(
			ModalFlowActions.showShareMaintenanceReportModal({
				object: report,
			})
		);
	};

	return (
		<IconButton icon={regular('share')} tooltip="Share" onClick={handleShare} />
	);
};
