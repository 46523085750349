import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import SegmentChip from 'components/segment-chip';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/reducers/rootReducer';

export default () => {
	const dispatch = useDispatch();

	const filters = useTypedSelector((state) => state.inspections.filters);
	const profile = useTypedSelector((state) => state.activeProfile);

	const handleClick = () => {
		dispatch({ type: 'RESET_INSPECTION_FILTERS' });
		dispatch({
			type: 'SET_INSPECTION_ASSIGNED_TO_FILTER',
			value: [profile.id],
		});
	};

	const selected = !!filters.assignedTo?.length;

	return (
		<SegmentChip
			variant="outlined"
			icon={solid('user-circle')}
			label="Assigned to me"
			title="Assigned to me"
			onClick={handleClick}
			color="primary"
			selected={selected}
		/>
	);
};
