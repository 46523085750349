import { Checkbox, FormControlLabel } from '@mui/material';
import { InspectionReportTemplate } from '@rentcheck/types';
import { Column } from 'components';
import _ from 'lodash';

export interface Props {
	isEditing: boolean;
	payload: InspectionReportTemplate;
	setPayload: (value: InspectionReportTemplate) => void;
	option: ReportOption;
	payloadKey: keyof InspectionReportTemplate;
}

export interface ReportOption {
	label: string;
	key: string;
	marginTop?: number;
	options?: ReportOption[];
}

const OptionGroup = ({
	isEditing,
	payload,
	setPayload,
	option,
	payloadKey,
}: Props) => {
	const thisSection = _.get(payload, payloadKey);

	const handleToggleValue = (key: string) => {
		const newPayload = { ...payload };
		const fullKey = `${payloadKey}.${key}`;
		const currentValue = _.get(payload, fullKey) as boolean;

		const keyElements = key.split('.');
		if (keyElements.length === 2 && !currentValue) {
			_.set(newPayload, `${payloadKey}.${keyElements[0]}.section_active`, true);
		}

		_.set(newPayload, fullKey, !currentValue);
		setPayload(newPayload);
	};

	return (
		<>
			<FormControlLabel
				sx={{
					marginTop: option.marginTop,
				}}
				label={option.label}
				control={
					<Checkbox
						disabled={!isEditing}
						checked={_.get(thisSection, option.key)}
						onChange={() => handleToggleValue(option.key)}
					/>
				}
			/>
			{!!option.options && (
				<Column style={{ marginLeft: 6 }}>
					{option.options.map((subOption) => (
						<OptionGroup
							isEditing={isEditing}
							payload={payload}
							setPayload={setPayload}
							option={subOption}
							payloadKey={payloadKey}
						/>
					))}
				</Column>
			)}
		</>
	);
};

export default OptionGroup;
