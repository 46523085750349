import { AccordionDetails as MuiAccordionDetails } from '@mui/material';

import { ApiInspectionWithTemplate } from '@rentcheck/types';
import { FeatureWithMetadata } from 'store/reducers/features';

import styled from 'styled-components';
import Flags from './flags';
import Questions from './questions';

interface Props {
	feature: FeatureWithMetadata;
	inspection: ApiInspectionWithTemplate;
}

export default ({ feature, inspection }: Props) => {
	if (feature.not_applicable) {
		return <AccordionDetails />;
	}

	return (
		<AccordionDetails>
			<Questions feature={feature} />
			<Flags feature={feature} inspection={inspection} />
		</AccordionDetails>
	);
};

const AccordionDetails = styled(MuiAccordionDetails)`
	padding: 0;
	margin-bottom: 0;
`;
