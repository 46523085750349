import {
	Checkbox,
	Table as MuiTable,
	Paper,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { CenteredRow, Column, LottieWrapper, Spacer } from 'components';
import _ from 'lodash';
import { ReactNode, useEffect, useState } from 'react';
import InspectionsActionMenu from 'screens/inspections/list/table/actions-menu';
import UnitsActionMenu from 'screens/properties/list/units/table/actions-menu';
import { useTypedSelector } from 'store/reducers/rootReducer';

import { APIProperty } from '@rentcheck/types';
import InfiniteScroll from './infinite-scroll';
import SkeletonTable from './skeleton-table';

interface Props<ItemT = any> {
	open: boolean;
	total: number;
	data: ItemT[];
	fetch: (from: number) => Promise<ItemT[]>;
	renderRow: (object: ItemT) => ReactNode;
	objectName: string;
	headers: string[];
	variant?: 'inspections' | 'properties';
}

const Table = ({
	open,
	data,
	fetch,
	total,
	renderRow,
	objectName,
	headers,
	variant = 'inspections',
}: Props) => {
	const profile = useTypedSelector((state) => state.activeProfile);

	const [loading, setLoading] = useState(false);

	const [selectedIds, setSelectedIds] = useState<string[]>([]);

	useEffect(() => {
		if (!open) {
			return;
		}

		// This is set to true before returning, a null profile
		// means the whole app is actually loading, if that isn't
		// the case (because the user is logged out, for example),
		// the router will redirect the user to the sign in page.
		setLoading(true);

		if (!profile) {
			return;
		}

		fetch(0).finally(() => setLoading(false));
	}, [profile, open]);

	useEffect(() => {
		const existingIds = data.map((d) => d.id);
		setSelectedIds(selectedIds.filter((id) => existingIds.includes(id)));
	}, [data]);

	const handleSelectAll = () => {
		if (selectedIds.length === data.length) {
			return setSelectedIds([]);
		}

		setSelectedIds(data.map((f) => f.id));
	};

	const handleSelect = (p: APIProperty) => {
		setSelectedIds(_.xor(selectedIds, [p.id]));
	};

	if (loading) {
		return (
			<>
				<SkeletonTable variant={variant} />
				<Spacer height={8} />
			</>
		);
	}

	const checked = selectedIds.length === data.length && selectedIds.length > 0;
	const indeterminate =
		selectedIds.length > 0 && selectedIds.length !== data.length;

	const headerAlignment = (h: string) => {
		return h.includes('Date') || ['Status'].includes(h) ? 'center' : 'left';
	};

	return (
		<>
			<Typography fontWeight={600} color="#445567">
				{total} Total
			</Typography>
			<Spacer height={2} />
			<TableContainer component={Paper}>
				<MuiTable>
					<TableHead>
						<TableRow>
							<TableCell>
								{total !== 0 && (
									<Checkbox
										onChange={handleSelectAll}
										checked={checked}
										indeterminate={indeterminate}
									/>
								)}
							</TableCell>
							{headers.map((h) => (
								<TableCell
									align={headerAlignment(h)}
									sx={{ fontWeight: 600, color: '#666666' }}>
									{h}
								</TableCell>
							))}
							<TableCell align="center">
								{total !== 0 && variant === 'inspections' && (
									<InspectionsActionMenu
										inspectionIds={selectedIds}
										setSelectedIds={setSelectedIds}
										archived={false}
									/>
								)}
								{total !== 0 && variant === 'properties' && (
									<UnitsActionMenu
										unitIds={selectedIds}
										setUnitIds={setSelectedIds}
									/>
								)}
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data.map((d) => (
							<TableRow>
								<TableCell>
									<Checkbox
										onChange={() => handleSelect(d)}
										checked={selectedIds.includes(d.id)}
									/>
								</TableCell>
								{renderRow(d)}
							</TableRow>
						))}
					</TableBody>
				</MuiTable>
			</TableContainer>
			{total === 0 && (
				<CenteredRow>
					<Column>
						<Spacer height={2} />
						<LottieWrapper variant="dog-sleeping" width={300} height={300} />
						<Typography>
							You're all caught up. Nothing here but a sleepy pup.
						</Typography>
					</Column>
				</CenteredRow>
			)}
			{!loading && (
				<InfiniteScroll
					objectName={objectName}
					fetch={fetch}
					pageSize={20}
					offset={data.length}
				/>
			)}
		</>
	);
};

export default Table;
