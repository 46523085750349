import { useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import {
	Draggable,
	DraggingStyle,
	NotDraggingStyle,
} from 'react-beautiful-dnd';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Paper, SxProps, Theme, Typography, MenuItem } from '@mui/material';

import { TemplateFeature, TemplateSection } from '@rentcheck/types';

import { Column, Row } from 'components';
import IconButton from 'components/icon-button';
import { numberAndPluralizeWord } from 'utils/helpers';

import FeatureLogicModal from './feature-logic/modal';

interface Props {
	feature: TemplateFeature;
	index: number;
	editing: boolean;
	selected?: boolean;
	onSelect: (feature: TemplateFeature) => void;
	sections: TemplateSection[];
	handleUpdateSingleFeature: (feature: TemplateFeature) => void;
}

const getItemStyle = (
	selected?: boolean,
	draggableStyle?: DraggingStyle | NotDraggingStyle
): SxProps<Theme> => ({
	mb: 1,
	minHeight: 54,
	paddingLeft: 4,
	paddingRight: 2,
	paddingTop: 1,
	paddingBottom: 1,
	backgroundColor: '#f6f5ff',
	border: selected ? '1px solid #2D3CE6' : '1px solid #0000001F',
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	alignItems: 'center',
	transition: 'elevation 0.2s ease-in-out',
	cursor: 'pointer',

	// styles we need to apply on draggables
	...(draggableStyle as any),
});

export default ({
	feature,
	index,
	editing,
	selected,
	onSelect,
	handleUpdateSingleFeature,
	sections,
}: Props) => {
	const [featureLogicModalOpen, setFeatureLogicModalOpen] = useState(false);

	const handleAddLogic = () => {
		setFeatureLogicModalOpen(true);
	};

	const handleSelect = (e: React.MouseEvent<HTMLDivElement>) => {
		/**
		 * If the user didn't click on the card itself, but rather
		 * on the overflow menu or the text field don't select.
		 */
		const classList = (e.target as any).classList;
		if (
			!classList.contains('MuiPaper-root') &&
			!classList.contains('MuiTypography-root')
		) {
			return;
		}

		onSelect(feature);
	};

	return (
		<>
			<Draggable
				key={feature.id}
				draggableId={feature.id}
				isDragDisabled={!editing}
				index={index}>
				{(provided, snapshot) => (
					<Paper
						ref={provided.innerRef}
						onClick={handleSelect}
						elevation={snapshot.isDragging ? 1 : 0}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						sx={getItemStyle(selected, provided.draggableProps.style)}>
						<>
							<Column>
								<Typography
									variant="subtitle2"
									sx={{ wordBreak: 'break-word' }}>
									{feature.name || 'New Feature'}
								</Typography>
								<Typography variant="caption">
									{numberAndPluralizeWord(feature.questions.length, 'Question')}
								</Typography>
							</Column>

							<Row>
								{editing && (
									<>
										{feature.logic.length > 0 && (
											<IconButton
												tooltip="Edit Logic"
												icon={regular('split')}
												onClick={handleAddLogic}
											/>
										)}

										<IconButton icon={regular('ellipsis-vertical')}>
											<MenuItem onClick={handleAddLogic}>
												{feature.logic.length ? 'Edit Logic' : 'Add Logic'}
											</MenuItem>
										</IconButton>
									</>
								)}
							</Row>
						</>
					</Paper>
				)}
			</Draggable>
			<FeatureLogicModal
				feature={feature}
				sections={sections}
				onUpdate={handleUpdateSingleFeature}
				open={featureLogicModalOpen}
				onClose={() => setFeatureLogicModalOpen(false)}
			/>
		</>
	);
};
