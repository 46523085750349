import { FilterChip } from 'components/table/filter-button-v2';
import { useDispatch } from 'react-redux';
import { actionType, icon, useFilter } from './common';

export default () => {
	const dispatch = useDispatch();
	const filter = useFilter();

	const handleDeleted = () => {
		dispatch({ type: actionType, value: false });
	};

	if (!filter) {
		return null;
	}

	return <FilterChip title="Archived" icon={icon} onDelete={handleDeleted} />;
};
