import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { ApiMaintenanceReport } from '@rentcheck/types';
import { FeaturesApi } from 'api';
import IconButton from 'components/icon-button';
import _ from 'lodash';
import { ReactNode, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ModalFlowActions, SnackbarActions } from 'store/actions';
import styled from 'styled-components';
import { formatCurrency } from 'utils/helpers';

interface Props {
	flags: ApiMaintenanceReport['maintenance_flags'];
	report: ApiMaintenanceReport;
}

const FlagTableRow = ({
	flag,
	report,
}: {
	flag: ApiMaintenanceReport['maintenance_flags'][0];
	report: ApiMaintenanceReport;
}) => {
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);

	const handleViewFlag = (
		flag: ApiMaintenanceReport['maintenance_flags'][0]
	) => {
		setLoading(true);

		FeaturesApi.getById(flag.feature_id)
			.then((feature) => {
				const flagFromFirebase = feature?.maintenance_flags.find(
					(f) => f.id === flag.id
				);

				if (!flagFromFirebase) {
					throw new Error('Maintenance flag not found');
				}

				dispatch(
					ModalFlowActions.showFlagImages({
						flags: [flagFromFirebase],
						initialFlag: flagFromFirebase,
						property: report.property,
					})
				);
			})
			.catch((e) => {
				dispatch(SnackbarActions.showError(e));
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const getMediaCount = (
		flag: ApiMaintenanceReport['maintenance_flags'][0]
	) => {
		const imagesCount = flag.image_urls.length;
		const videosCount = flag.video_urls.length;

		return imagesCount + videosCount;
	};

	const cells: Record<string, ReactNode> = {
		Responsibility: _.capitalize(flag.responsibility),
		Room: flag.section.name_with_ordinal,
		Feature: flag.feature,
		Notes: flag.note || '-',
		Media: getMediaCount(flag) || '-',
		Skill: flag.skill,
		Cost: formatCurrency(flag.cost),
		View: (
			<IconButton
				loading={loading}
				icon={regular('image')}
				color="primary"
				onClick={() => handleViewFlag(flag)}
				disabled={!getMediaCount(flag)}
				tooltip={getMediaCount(flag) ? 'View Images' : 'No Images'}
			/>
		),
	};

	const sortedCells = {
		responsibility: [
			cells.Responsibility,
			cells.Room,
			cells.Feature,
			cells.Notes,
			cells.Media,
			cells.Skill,
			cells.Cost,
			cells.View,
		],
		room: [
			cells.Room,
			cells.Feature,
			cells.Notes,
			cells.Skill,
			cells.Media,
			cells.Responsibility,
			cells.Cost,
			cells.View,
		],
		skill: [
			cells.Skill,
			cells.Feature,
			cells.Room,
			cells.Notes,
			cells.Media,
			cells.Responsibility,
			cells.Cost,
			cells.View,
		],
	};

	return (
		<TableRow>
			{sortedCells[report.organize_by].map((cell) => (
				<TableCell>{cell}</TableCell>
			))}
		</TableRow>
	);
};
export default ({ flags, report }: Props) => {
	if (!flags.length) {
		return null;
	}

	const totalCost = flags.reduce((acc, flag) => acc + (flag.cost ?? 0), 0);

	return (
		<>
			<TableBody
				sx={{
					td: {
						border: 'none',
					},
					'tr:nth-child(2n + 1)': {
						backgroundColor: '#47474708',
					},
				}}>
				{flags.map((flag) => (
					<FlagTableRow flag={flag} report={report} />
				))}

				{totalCost > 0 && (
					<LastTableRow
						sx={{
							borderTop: '1px solid rgba(224,224,224,1)',
							backgroundColor: 'transparent !important',
						}}>
						<TableCell colSpan={5} style={{ borderBottom: 'none' }} />
						<TableCell align="right" style={{ borderBottom: 'none' }}>
							Total
						</TableCell>
						<TableCell style={{ borderBottom: 'none' }}>
							{formatCurrency(totalCost)}
						</TableCell>
						<TableCell style={{ borderBottom: 'none' }} />
					</LastTableRow>
				)}

				{totalCost === 0 && (
					<LastTableRow>
						<TableCell colSpan={8} style={{ borderBottom: 'none' }} />
					</LastTableRow>
				)}
			</TableBody>
		</>
	);
};

const LastTableRow = styled(TableRow)`
	height: 90px;
	vertical-align: top;
	border-bottom: 	none;

	&:hover {
		background-color: #fff !important;
`;
