import { Link, Typography } from '@mui/material';

import Filters from './filters';
import List from './list';

export default () => {
	return (
		<>
			<Typography color="#4d4d4d">
				Permission groups are custom sets of permissions you can create and
				assign to users in your account. Learn more about{' '}
				<Link
					href="https://help.getrentcheck.com/en/articles/9457781-permission-groups"
					target="_blank">
					permission groups
				</Link>
				.
			</Typography>
			<Filters />
			<List />
		</>
	);
};
