import { Row } from 'components';
import { FeatureWithMetadata } from 'store/reducers/features';

import { ApiInspection } from '@rentcheck/types';
import Damage from './damage';
import DeviceMedia from './device-media';
import Flag from './flag';
import LinkedMedia from './linked-media';
import Revision from './revision';

type ImageBadgeMode =
	| 'revision'
	| 'damage'
	| 'flag'
	| 'linked-media'
	| 'device-media';

interface Props {
	inspection: ApiInspection;
	feature: FeatureWithMetadata;
	image: string;
	mode?: ImageBadgeMode[];
	mt?: number;
}

export default ({
	inspection,
	feature,
	image,
	mode = ['damage', 'revision', 'flag', 'linked-media', 'device-media'],
	mt = 0,
}: Props) => {
	return (
		<Row
			style={{
				position: 'absolute',
				top: mt + 8,
				right: 8,
			}}>
			{mode.includes('revision') && <Revision image={image} />}
			{mode.includes('damage') && <Damage feature={feature} image={image} />}
			{mode.includes('flag') && (
				<Flag feature={feature} image={image} inspection={inspection} />
			)}
			{mode.includes('linked-media') && (
				<LinkedMedia feature={feature} image={image} />
			)}
			{mode.includes('device-media') && <DeviceMedia image={image} />}
		</Row>
	);
};
