import { SpacedRow } from 'components';

import FiltersSkeleton from './menu/skeleton';
import QuickFiltersSkeleton from './segments/skeleton';

export default () => {
	return (
		<SpacedRow style={{ alignItems: 'flex-end' }}>
			<QuickFiltersSkeleton />
			<FiltersSkeleton />
		</SpacedRow>
	);
};
