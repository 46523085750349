import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent, Skeleton } from '@mui/material';

import { Spacer } from 'components';
import { useDispatch } from 'react-redux';
import { ModalFlowActions } from 'store/actions';

import { handleNativeAppClose } from '../common/native-app';

export default () => {
	const dispatch = useDispatch();

	const handleClose = () => {
		handleNativeAppClose();
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	return (
		<>
			<DialogContent>
				<Skeleton
					variant="rectangular"
					width="60%"
					height={14}
					sx={{ borderRadius: 1 }}
				/>

				<Spacer height={6} />
				<Skeleton
					variant="rectangular"
					width="10%"
					height={20}
					sx={{ borderRadius: 1 }}
				/>
				<Spacer height={1} />
				<Skeleton
					variant="rectangular"
					width="30%"
					height={20}
					sx={{ borderRadius: 1 }}
				/>
				<Spacer height={1} />
				<Skeleton
					variant="rectangular"
					width="10%"
					height={14}
					sx={{ borderRadius: 1 }}
				/>
				<Spacer height={1} />
				<Skeleton
					variant="rectangular"
					width="100%"
					height={60}
					sx={{ borderRadius: 1 }}
				/>

				<Spacer height={6} />
				<Skeleton
					variant="rectangular"
					width="10%"
					height={20}
					sx={{ borderRadius: 1 }}
				/>
				<Spacer height={1} />
				<Skeleton
					variant="rectangular"
					width="30%"
					height={20}
					sx={{ borderRadius: 1 }}
				/>
				<Spacer height={1} />
				<Skeleton
					variant="rectangular"
					width="10%"
					height={14}
					sx={{ borderRadius: 1 }}
				/>
				<Spacer height={1} />
				<Skeleton
					variant="rectangular"
					width="100%"
					height={60}
					sx={{ borderRadius: 1 }}
				/>
			</DialogContent>
			<DialogActions>
				<Button color="secondary" variant="outlined" onClick={handleClose}>
					Dismiss
				</Button>
				<LoadingButton disabled variant="contained">
					Next
				</LoadingButton>
			</DialogActions>
			;
		</>
	);
};
