import { Divider, Skeleton } from '@mui/material';
import { Column, SpacedRow, Spacer } from 'components';
import { useIsMobileBreakpoint } from 'utils/hooks';

export default () => {
	const isMobile = useIsMobileBreakpoint();

	if (isMobile) {
		return (
			<Column>
				<Skeleton variant="rectangular" width="100%" height={100} />

				<Column style={{ width: '100%' }}>
					<Spacer height={4} />
					<Skeleton variant="text" width="50%" />
					<Skeleton variant="text" width="40%" />
					<Skeleton variant="text" width="100%" height={60} />
				</Column>
				<Divider />

				<Column style={{ width: '100%' }}>
					<Spacer height={4} />
					<Skeleton variant="text" width="50%" />
					<Skeleton variant="text" width="40%" />
					<Skeleton variant="text" width="100%" height={60} />
				</Column>
				<Divider />

				<Column style={{ width: '100%' }}>
					<Spacer height={4} />
					<Skeleton variant="text" width="50%" />
					<Skeleton variant="text" width="40%" />
					<Skeleton variant="text" width="100%" height={60} />
				</Column>
				<Divider />

				<Column style={{ width: '100%' }}>
					<Spacer height={4} />
					<Skeleton variant="text" width="50%" />
					<Skeleton variant="text" width="40%" />
					<Skeleton variant="text" width="100%" height={60} />
				</Column>
			</Column>
		);
	}

	return (
		<Column>
			<Skeleton variant="rectangular" width="100%" height={50} />
			<SpacedRow style={{ height: 66 }}>
				<Column style={{ width: '50%' }}>
					<Skeleton variant="text" width="80%" />
					<Skeleton variant="text" width="70%" />
				</Column>
				<Skeleton variant="text" width="40%" height={60} />
			</SpacedRow>
			<Divider />
			<SpacedRow style={{ height: 66 }}>
				<Column style={{ width: '50%' }}>
					<Skeleton variant="text" width="80%" />
				</Column>
				<Skeleton variant="text" width="40%" height={60} />
			</SpacedRow>
			<Divider />
			<SpacedRow style={{ height: 66 }}>
				<Column style={{ width: '50%' }}>
					<Skeleton variant="text" width="80%" />
				</Column>
				<Skeleton variant="text" width="40%" height={60} />
			</SpacedRow>
			<Divider />
			<SpacedRow style={{ height: 66 }}>
				<Column style={{ width: '50%' }}>
					<Skeleton variant="text" width="80%" />
				</Column>
				<Skeleton variant="text" width="40%" height={60} />
			</SpacedRow>
		</Column>
	);
};
