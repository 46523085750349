export const ASSET_CATEGORIES = [
	'Air Filter',
	'Air Conditioner',
	'Air Handler Unit (AHU)',
	'Barbecue Grill',
	'Bathroom Heater',
	'Cooktop',
	'Condenser',
	'Dishwasher',
	'Dryer',
	'Electric Fireplace',
	'Evaporator',
	'Freezer',
	'Furnace',
	'Furnace (AHU)',
	'Garbage Disposal',
	'Heat Pump',
	'Microwave',
	'Package Unit',
	'Pool Heater',
	'PTAC',
	'Range Hood',
	'Range',
	'Refrigerator',
	'Stackable Laundry',
	'Thermostat',
	'Wall Oven',
	'Washer',
	'Water Heater',
	'Other',
];
