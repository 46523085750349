import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ApiMaintenanceReport } from '@rentcheck/types';
import IconButton from 'components/icon-button';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FileRequestsActions, SnackbarActions } from 'store/actions';
import { Dispatch } from 'types';
import { Analytics } from 'utils';

interface Props {
	report: ApiMaintenanceReport;
}

export default ({ report }: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const [loading, setLoading] = useState(false);

	const existingPdfLocation = report.pdf_report_location;

	const handleDownloadPDF = async () => {
		if (existingPdfLocation) {
			navigator.clipboard?.writeText(existingPdfLocation);

			dispatch(
				SnackbarActions.showSuccess('The link was copied to your clipboard')
			);

			Analytics.trackEvent('copied_maintenance_report_link', {
				report_id: report.id,
			});

			return;
		}

		setLoading(true);

		dispatch(
			FileRequestsActions.create({
				documentType: 'MAINTENANCE_REPORT',
				documentId: report.id,
				exportType: 'PDF',
				dontNotify: true,
				title: `${report.name} Report`,
				subtitle: report.property.address,
				type: 'copy',
			})
		).finally(() => {
			setLoading(false);
		});
	};

	return (
		<IconButton
			loading={loading}
			icon={regular('link')}
			tooltip={existingPdfLocation ? 'Copy Link' : 'Prepare Link'}
			onClick={handleDownloadPDF}
		/>
	);
};
