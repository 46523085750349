import {
	ApiInspectionTemplateDigest,
	MultiValueFilter,
} from '@rentcheck/types';
import AutocompleteFilterChip from 'components/table/filter-button-v2/components/filter-chip/autocomplete';
import { useInspectionTemplates } from 'hooks/inspection-templates';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { actionType, icon, useFilter } from './common';

export default () => {
	const dispatch = useDispatch();
	const filter = useFilter();

	const { loading, templates } = useInspectionTemplates({
		include_deleted: true,
		include_unavailable: true,
		published_only: false,
		active_only: true,
	});

	const handleSetValue = (
		value: MultiValueFilter<ApiInspectionTemplateDigest> | undefined
	) => {
		if (!value || !value.value || value.value.length === 0) {
			dispatch({
				type: actionType,
				value: undefined,
			});

			return;
		}

		dispatch({
			type: actionType,
			value,
		});
	};

	const sortedTemplates = useMemo(() => {
		return templates.sort((a, b) => {
			if (a.is_deleted && !b.is_deleted) {
				return 1;
			}

			if (!a.is_deleted && b.is_deleted) {
				return -1;
			}

			if (a.is_published && !b.is_published) {
				return -1;
			}

			if (!a.is_published && b.is_published) {
				return 1;
			}

			return 0;
		});
	}, [templates]);

	if (!filter) {
		return null;
	}

	const getOptionChipProps = (option: ApiInspectionTemplateDigest) => {
		if (option.is_deleted) {
			return {
				label: 'Deleted',
				color: '#FFF',
			};
		}

		if (!option.is_published) {
			return {
				label: 'Unpublished',
				color: '#00000014',
			};
		}

		return {
			label: 'Active',
			color: '#E9F7EC',
		};
	};

	return (
		<AutocompleteFilterChip
			multiple
			title="Template"
			icon={icon}
			loading={loading}
			value={filter.value}
			condition={filter.condition}
			setFilterValue={handleSetValue}
			options={sortedTemplates}
			getOptionLabel={(option) => option.name}
			getOptionSubLabel={(option) => option.internal_label}
			getOptionChipProps={getOptionChipProps}
			isOptionEqualToValue={(option, value) => option.id === value.id}
		/>
	);
};
