import { TextField, Typography } from '@mui/material';
import { Column, Spacer } from 'components';

interface Props {
	value: string;
	setValue: (value: string) => void;
}

const Title = ({ value, setValue }: Props) => {
	return (
		<>
			<Column>
				<Typography variant="overline">Job Description</Typography>
				<Typography variant="caption" sx={{ marginTop: -0.5 }}>
					This field maps to the work order field 'Job Description' in AppFolio
				</Typography>
			</Column>

			<Spacer height={2} />

			<TextField
				fullWidth
				multiline
				variant="filled"
				label="Job Description"
				placeholder="Add a name for this Work Order"
				value={value}
				onChange={(e) => setValue(e.target.value)}
			/>

			<Spacer height={4} />
		</>
	);
};

export default Title;
