import { TextField, Typography } from '@mui/material';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, Redirect, useLocation } from 'react-router-dom';

import { CenteredColumn, Row, Spacer } from 'components';
import * as AuthActions from 'store/actions/auth-actions';
import { useTypedSelector } from 'store/reducers/rootReducer';

import { LoadingButton } from '@mui/lab';
import styled from 'styled-components';
import { Dispatch } from 'types';
import Container from '../container';

type Errors = 'email' | 'password' | '';

const SignIn = () => {
	const location = useLocation();
	const dispatch: Dispatch = useDispatch();

	const auth = useTypedSelector((state) => state.firebase.auth);

	const search = location.search;
	const URLParameters = queryString.parse(search);

	const [loading, setLoading] = useState(false);

	const [email, setEmail] = useState(
		(URLParameters.email as unknown as string) ?? ''
	);
	const [password, setPassword] = useState(
		(URLParameters.password as unknown as string) ?? ''
	);
	const [error, setError] = useState<Errors>('');
	const [defaultPage, setDefaultPage] = useState('/');
	const [signUpPage, setSignUpPage] = useState('/signup');

	useEffect(() => {
		if (!search) {
			return;
		}

		const defaultSegment = URLParameters.redirect;

		if (defaultSegment) {
			setDefaultPage(defaultSegment as string);
			setSignUpPage(`/signup/${search}`);
		}

		if (URLParameters.auth_token) {
			dispatch(
				AuthActions.signInWithToken(URLParameters.auth_token as string)
			).finally(() => setLoading(false));
		}
	}, [search]);

	useEffect(() => {
		if (!URLParameters.email || !URLParameters.password) {
			return;
		}

		window.history.replaceState(null, 'Sign In', '/signin');
		setTimeout(() => login(), 300);
	}, [URLParameters.email, URLParameters.password]);

	const login = () => {
		if (!email) {
			setError('email');
			return;
		}
		if (!password) {
			setError('password');
			return;
		}

		setError('');
		setLoading(true);

		dispatch(AuthActions.signIn(email, password)).finally(() =>
			setLoading(false)
		);
	};

	const resetPassword = () => {
		if (!email) {
			setError('email');
			return;
		}

		setError('');

		dispatch(AuthActions.resetPassword(email));
	};

	if (auth.uid) return <Redirect to={defaultPage} />;

	return (
		<Container>
			<CenteredColumn>
				<Form
					onSubmit={(e) => {
						e.preventDefault();
						login();
					}}>
					<TextField
						fullWidth
						id="email"
						variant="filled"
						type="email"
						label="Email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						error={error === 'email'}
						helperText={error === 'email' ? 'Email is missing' : ' '}
					/>
					<TextField
						fullWidth
						id="password"
						variant="filled"
						type="password"
						label="Password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						error={error === 'password'}
						helperText={error === 'password' ? 'Password is missing' : ' '}
					/>

					<Spacer height={10} />

					<LoadingButton
						fullWidth
						loading={loading}
						variant="contained"
						type="submit"
						onClick={login}>
						Login
					</LoadingButton>
				</Form>

				<Spacer height={8} />

				<Link to="/signin" onClick={resetPassword}>
					Reset Password
				</Link>
				<Spacer height={2} />
				<Row>
					<Typography>New to RentCheck?</Typography>
					<Spacer width={1} />
					<Link to={signUpPage}>Sign up</Link>
				</Row>
			</CenteredColumn>
		</Container>
	);
};

const Form = styled.form`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
`;

export default SignIn;
