import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Typography } from '@mui/material';
import {
	ApiInspectionEvent,
	InspectionEventRoomsAddedMetadata,
} from '@rentcheck/types';
import BaseTimelineEvent from '../base-event';

interface Props {
	event: ApiInspectionEvent;
}

const RoomsAddedTimelineEvent = ({ event }: Props) => {
	const metadata = event.metadata as InspectionEventRoomsAddedMetadata;

	return (
		<BaseTimelineEvent
			icon={solid('plus-circle')}
			title="Added room(s) or area(s)"
			subtitle={`Added by ${metadata.added_by}`}
			timestamp={event.timestamp}
			additionalContent={
				<>
					{metadata.rooms.map((r) => (
						<Typography variant="body2" color="#4D4D4D" key={r}>
							• {r}
						</Typography>
					))}
				</>
			}
		/>
	);
};

export default RoomsAddedTimelineEvent;
