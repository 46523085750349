import { APIConfig } from '@rentcheck/api-frontend';
import { ApiUser } from '@rentcheck/types';
import { ActiveProfileActionTypes } from '../actions/active-profile';

type State = ApiUser | null;

type Action = {
	type: ActiveProfileActionTypes;
	profile?: ApiUser;
	value?: any;
};

const activeProfileReducer = (state: State = null, action: Action) => {
	switch (action.type) {
		case 'SET_ACTIVE_PROFILE':
			return action.profile;
		case 'UPDATE_USER':
			return action.profile;
		case 'LOGIN_ERROR':
			return state;
		case 'LOGIN_DETECTED':
			return action.profile;
		case 'LOGIN_SUCCESS':
			return action.profile;
		case 'SIGNOUT_SUCCESS':
			APIConfig.setUserId(undefined);
			return null;
		default:
			return state;
	}
};

export default activeProfileReducer;
