import { Rules } from '@rentcheck/biz';
import { ApiInspectionWithTemplate } from '@rentcheck/types';
import { formatDate } from 'utils/helpers';

import GridItem from '../grid-item';

interface Props {
	inspection: ApiInspectionWithTemplate;
}

export default ({ inspection }: Props) => {
	if (!Rules.Inspections.canShowCompletedDate(inspection)) {
		return null;
	}

	return (
		<GridItem
			title="Date Completed"
			value={formatDate(inspection.completed_date)}
		/>
	);
};
