import { MenuItem, Tooltip } from '@mui/material';
import { Spacer } from 'components';

interface DeleteButtonProps {
	disabled?: boolean;
	onClick?: () => void;
}

export const DeleteActionButton = ({
	disabled,
	onClick,
}: DeleteButtonProps) => {
	if (disabled)
		return (
			<Tooltip
				title="Cannot delete property when integration sync is active"
				placement="bottom">
				<div>
					<MenuItem disabled>Delete</MenuItem>
					<Spacer width={4} />
				</div>
			</Tooltip>
		);

	return (
		<MenuItem onClick={onClick}>
			Delete
			<Spacer width={4} />
		</MenuItem>
	);
};
