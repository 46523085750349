"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.overline = exports.caption = exports.button = exports.body2 = exports.body1 = exports.subtitle2 = exports.subtitle1 = exports.heading7 = exports.heading6 = exports.heading5 = exports.heading4 = exports.heading3 = exports.heading2 = exports.heading1 = void 0;
const fonts = require("./fonts");
exports.heading1 = {
    fontFamily: fonts.semiBold,
    fontSize: 40,
    lineHeight: 60,
};
exports.heading2 = {
    fontFamily: fonts.bold,
    fontSize: 36,
    lineHeight: 50,
};
exports.heading3 = {
    fontFamily: fonts.bold,
    fontSize: 30,
    lineHeight: 42,
};
exports.heading4 = {
    fontFamily: fonts.semiBold,
    fontSize: 26,
    lineHeight: 32,
};
exports.heading5 = {
    fontFamily: fonts.bold,
    fontSize: 24,
    lineHeight: 30,
};
exports.heading6 = {
    fontFamily: fonts.bold,
    fontSize: 20,
    lineHeight: 22,
};
exports.heading7 = {
    fontFamily: fonts.bold,
    fontSize: 17,
    lineHeight: 20,
};
exports.subtitle1 = {
    fontFamily: fonts.semiBold,
    fontSize: 17,
    lineHeight: 24,
};
exports.subtitle2 = {
    fontFamily: fonts.bold,
    fontSize: 13,
    lineHeight: 16,
};
exports.body1 = {
    fontFamily: fonts.regular,
    fontSize: 14,
    lineHeight: 18,
};
exports.body2 = {
    fontFamily: fonts.medium,
    fontSize: 13,
    lineHeight: 18,
};
exports.button = {
    fontFamily: fonts.bold,
    fontSize: 12,
    lineHeight: 14,
};
exports.caption = {
    fontFamily: fonts.regular,
    fontSize: 11,
    lineHeight: 14,
};
exports.overline = {
    fontFamily: fonts.semiBold,
    fontSize: 12,
    lineHeight: 14,
};
