import EmailInput from 'components/form-text-field/email-input';

interface Props {
	value: string[];
	setValue: (value: string[]) => void;
}

export default ({ value, setValue }: Props) => {
	const handleOnChange = (values: string[]) => {
		const lowercaseEmails = values.map((email: string) => email.toLowerCase());
		setValue(lowercaseEmails);
	};

	return (
		<EmailInput
			title="Email Address"
			caption="Add multiple emails by separating each email address with a space or comma."
			placeholder="Add Emails"
			emails={value}
			onChange={handleOnChange}
			required
		/>
	);
};
