import { Typography } from '@mui/material';
import { WorkOrder } from '@rentcheck/api-frontend';
import { formatDate } from 'utils/helpers';

interface Props {
	workOrder: WorkOrder;
}

export default ({ workOrder }: Props) => {
	if (workOrder.yardi?.last_sync) {
		return (
			<Typography variant="inherit" mt={1}>
				Synced with Yardi on {formatDate(workOrder.yardi.last_sync)}
			</Typography>
		);
	}

	if (workOrder.appfolio?.last_sync) {
		return (
			<Typography variant="inherit" mt={1}>
				Synced with AppFolio on {formatDate(workOrder.appfolio.last_sync)}
			</Typography>
		);
	}

	if (workOrder.rentmanager?.last_sync) {
		return (
			<Typography variant="inherit" mt={1}>
				Synced with Rent Manager on{' '}
				{formatDate(workOrder.rentmanager.last_sync)}
			</Typography>
		);
	}

	return null;
};
