import { Dialog, useMediaQuery, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';

import { integrations } from 'assets';
import { DialogTitle } from 'components';
import { useCreateWorkOrderModalData } from 'hooks/modals';
import { ModalFlowActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';
import { formattedAddress, isEmbeddedInMobileApp } from 'utils/helpers';

import { getModalConfig } from './common/modal-config';
import { handleNativeAppClose } from './common/native-app';
import Content from './content';

interface Props {
	open: boolean;
}

const CreateWorkOrder = ({ open }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	const profile = useTypedSelector((state) => state.activeProfile);

	const modalData = useCreateWorkOrderModalData('Rent Manager');
	const { inspection, flags, provider } = modalData?.metadata ?? {};

	if (!profile || !inspection || !flags || !provider) return null;

	const modalConfig = getModalConfig(provider);

	const handleClose = () => {
		handleNativeAppClose();
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			fullScreen={isMobile || isEmbeddedInMobileApp()}>
			<DialogTitle
				title={modalConfig.dialogTitle}
				bottomSubtitle={formattedAddress(inspection.property)}
				onClose={handleClose}
				image={integrations.rentManager}
			/>
			<Content />
		</Dialog>
	);
};

export default CreateWorkOrder;
