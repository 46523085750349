import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { MaintenanceFlag } from '@rentcheck/types';
import { FeaturesApi } from 'api';
import { Column } from 'components';
import IconButton from 'components/icon-button';
import { useCreateWorkOrderModalData } from 'hooks/modals';
import { useDispatch } from 'react-redux';
import { ModalFlowActions } from 'store/actions';
import { colors } from 'theme';
import { featureDisplayName } from 'utils/helpers';

interface Props {
	value: MaintenanceFlag[];
}

export default ({ value }: Props) => {
	const dispatch = useDispatch();

	const modalData = useCreateWorkOrderModalData('Rent Manager');
	const { inspection, flags } = modalData?.metadata ?? {};

	const handleViewFlag = async (flag: MaintenanceFlag) => {
		const feature = await FeaturesApi.getById(flag.feature_id);

		if (!feature) {
			return;
		}

		dispatch(
			ModalFlowActions.showFlagFeatureModal({
				inspection,
				feature,
				flag,
				images: flag.image_urls,
				disableDelete: true,
				disableCreateWorkOrder: true,
			})
		);
	};

	const handleRemoveFlag = (flag: MaintenanceFlag) => {
		dispatch(
			ModalFlowActions.removeFlagFromModals({
				flag,
			})
		);
	};

	return (
		<Column>
			<Typography variant="overline">Flags</Typography>

			<TableContainer
				component={Paper}
				sx={{ mt: 1, mb: 3, border: '1px solid #0000001F' }}
				elevation={0}>
				<Table>
					<TableHead>
						<TableCell>Flag Details</TableCell>
						<TableCell align="right">Actions</TableCell>
					</TableHead>
					<TableBody>
						{value.map((flag) => {
							return (
								<TableRow>
									<TableCell>
										<Column>
											<Typography variant="subtitle2">
												{featureDisplayName(flag)}
											</Typography>
											<Typography variant="body2" color={colors.secondary}>
												Media:{' '}
												{(flag.image_urls?.length ?? 0) +
													(flag.video_urls?.length ?? 0)}
											</Typography>
										</Column>
									</TableCell>
									<TableCell align="right">
										<IconButton
											icon={regular('eye')}
											onClick={() => handleViewFlag(flag)}
											tooltip="View"
										/>
										{flags.length > 1 && (
											<IconButton
												icon={regular('times')}
												onClick={() => handleRemoveFlag(flag)}
												tooltip="Remove"
											/>
										)}
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</Column>
	);
};
