import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import { BaseIntegration } from '@rentcheck/types';
import { Column, SpacedRow } from 'components';
import _ from 'lodash';

interface Props {
	teamMappings: BaseIntegration['team_mappings'];
}

export default ({ teamMappings }: Props) => {
	const values = _.values(teamMappings).sort((a, b) => {
		if (a.external_team_name === 'default') {
			return -1;
		}

		if (b.external_team_name === 'default') {
			return 1;
		}

		return a.external_team_name.localeCompare(b.external_team_name);
	});

	return (
		<Column>
			{values.map((tm, idx) => (
				<SpacedRow style={{ marginBottom: idx !== values.length - 1 ? 6 : 0 }}>
					<Typography variant="subtitle1">
						{tm.external_team_name === 'default'
							? 'Default Mapping'
							: tm.external_team_name}
						<FontAwesomeIcon
							icon={regular('arrow-right')}
							style={{ marginLeft: 4, marginRight: 4 }}
						/>
						{tm.team_name}
					</Typography>
				</SpacedRow>
			))}
		</Column>
	);
};
