import _ from 'lodash';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
	APIBuilding,
	APICommunity,
	APIProperty,
	APIUnit,
} from '@rentcheck/types';

import { colors, spacingPx } from 'theme';
import { Column } from '../layout/Column';

import { Typography } from '@mui/material';
import {
	BuildingsApi,
	CommunitiesApi,
	UnitsApi,
} from '@rentcheck/api-frontend';
import { CreateInspectionFlowType } from 'store/reducers/modal-flows';

interface Props {
	selectedProperty: APIProperty | null;
	setSelectedProperty: (prop: APIProperty) => void;
	closeModal?: (forceClose?: boolean) => void;
	showCreatePropertyModal?: () => void;
	showType: CreateInspectionFlowType;
}

const filterBuildingsAndCommunities = <
	T extends { name: string; address: string },
>(
	properties: T[],
	searchTerm: string
) => {
	return properties.filter(
		(p) =>
			p.address.toLowerCase().includes(searchTerm) ||
			p.name.toLowerCase().includes(searchTerm)
	);
};

const SelectPropertyDropDown = ({
	selectedProperty,
	setSelectedProperty,
	closeModal,
	showCreatePropertyModal,
	showType,
}: Props) => {
	const [units, setUnits] = useState<APIUnit[]>([]);
	const [buildings, setBuildings] = useState<APIBuilding[]>([]);
	const [communities, setCommunities] = useState<APICommunity[]>([]);

	const [searchValue, setSearchValue] = useState('');
	const [showDropdown, setShowDropdown] = useState(false);

	useEffect(() => {
		if (showType === 'unit') {
			UnitsApi.getAll(0, 20, {
				search: '',
				teamId: [],
				buildingId: [],
				inspectionType: [],
			}).then((data) => setUnits(data.data));
		}

		if (showType === 'building') {
			BuildingsApi.getAll(0, 20, {
				search: '',
				teamId: [],
			}).then((data) => setBuildings(data.data));
		}

		if (showType === 'community') {
			CommunitiesApi.getAll(0, 20, {
				search: '',
				teamId: [],
			}).then((data) => setCommunities(data.data));
		}
	}, []);

	const lowerCaseSearch = searchValue.toLowerCase();

	let filteredProperties: APIProperty[] = [];

	if (showType === 'unit') {
		filteredProperties = units.filter((u) =>
			u.address.toLowerCase().includes(lowerCaseSearch)
		);
	}

	if (showType === 'building') {
		filteredProperties = filterBuildingsAndCommunities(
			buildings,
			lowerCaseSearch
		);
	}

	if (showType === 'community') {
		filteredProperties = filterBuildingsAndCommunities(
			communities,
			lowerCaseSearch
		);
	}

	const capitalizedPropertyType = _.capitalize(showType);

	return (
		<>
			{!showDropdown && (
				<SearchBarContainer
					onClick={() => {
						setSearchValue('');
						setShowDropdown(true);
					}}>
					<Typography color={selectedProperty ? colors.black : colors.gray}>
						{selectedProperty
							? (selectedProperty as APIBuilding).name ??
								selectedProperty.address
							: `Select ${capitalizedPropertyType}`}
					</Typography>
					<FontAwesomeIcon icon={solid('search')} color={colors.gray} />
				</SearchBarContainer>
			)}

			{showDropdown && (
				<DropdownAnchor>
					<DropdownContent>
						<AutocompleteRow key="text-input">
							<AddressInput
								autoFocus
								className="browser-default"
								type="text"
								placeholder={`Select ${capitalizedPropertyType}`}
								value={searchValue}
								onChange={(e) => setSearchValue(e.target.value)}
							/>
							<FontAwesomeIcon icon={solid('search')} color={colors.gray} />
						</AutocompleteRow>

						{!!showCreatePropertyModal && !!closeModal && (
							<>
								<Divider />
								<AutocompleteRow
									key="add-property"
									onClick={() => {
										showCreatePropertyModal();
										closeModal(true);
									}}>
									<Typography color={colors.primary}>
										+ Add New {capitalizedPropertyType}
									</Typography>
								</AutocompleteRow>
							</>
						)}

						<Scroll>
							{filteredProperties.map((p: any) => (
								<AutocompleteRow
									key={p.id}
									onClick={() => {
										setSelectedProperty(p);
										setSearchValue(p.address);
										setShowDropdown(false);
									}}>
									<AutocompleteContent>
										<Typography>{p.name ?? p.address}</Typography>
									</AutocompleteContent>
								</AutocompleteRow>
							))}
						</Scroll>
					</DropdownContent>
				</DropdownAnchor>
			)}
		</>
	);
};

const Row = styled.div`
	display: flex;
	flex-direction: row;
`;

const SpacedRow = styled(Row)`
	justify-content: space-between;
`;

const SearchBarContainer = styled(SpacedRow)`
	height: ${spacingPx(14)};
	padding: 0 ${spacingPx(6)} 0 ${spacingPx(6)};
	align-items: center;
	border: 1px solid #e6eef0;
	cursor: text;
`;

const DropdownAnchor = styled(Column)`
	height: ${spacingPx(14)};
	flex: 1;
`;

const AutocompleteRow = styled(Row)`
	height: ${spacingPx(14)};
	padding: 0 ${spacingPx(6)} 0 ${spacingPx(6)};
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
`;

const AutocompleteContent = styled(Row)`
	flex: 1;
	height: 100%;
	border-top: 1px solid ${colors.contentBorder};
	align-items: center;
`;

const DropdownContent = styled(Column)`
	background-color: white;
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
	flex: 1;
	z-index: 1;
`;

const Divider = styled.div`
	width: 100%;
	height: 1px;
	background-color: ${colors.contentBorder};
`;

const Scroll = styled.div`
	overflow: auto;
	height: ${spacingPx(56)};
`;

const AddressInput = styled.input`
	font-family: 'Poppins';
	font-size: 14px;
	height: ${spacingPx(10)};
	outline: none;
	font-weight: 500;
	line-height: 1.45;
	border: 0;
	flex: 1;
`;

export default SelectPropertyDropDown;
