import React from 'react';
import { TabBar } from 'components';

const PropertiesTabBar = () => {
	const tabBarOptions = [
		{
			label: 'Maintenance',
			url: '/maintenance/reports',
			isDefault: true,
		},
		{ label: 'Work Orders', url: '/maintenance/work-orders' },
	];

	return <TabBar large options={tabBarOptions} baseRoute="/maintenance" />;
};

export default PropertiesTabBar;
