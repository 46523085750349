import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, Divider, Typography } from '@mui/material';
import { ApiInspectionTemplateDigest } from '@rentcheck/types';

import { Column, Row, Spacer } from 'components';
import { colors } from 'theme';

interface Props {
	fastTrack: boolean;
	setFastTrack: (value: boolean) => void;
	template?: ApiInspectionTemplateDigest;
}

const FastTrack = ({ fastTrack, setFastTrack, template }: Props) => {
	if (!template || !template.is_fast_track_enabled) {
		return <Divider />;
	}

	return (
		<>
			<Row>
				<Checkbox
					id="checkbox-fast-track"
					checked={fastTrack}
					onClick={() => (fastTrack ? setFastTrack(false) : setFastTrack(true))}
				/>

				<Column>
					<Row>
						<Typography variant="subtitle1">Fast-Track</Typography>
						<Spacer width={2} />
						<FontAwesomeIcon icon={solid('bolt')} color={colors.primary} />
					</Row>

					<Typography color="#00000099">
						A faster inspection experience designed for property management
						professionals.
					</Typography>
				</Column>

				<FontAwesomeIcon
					style={{ cursor: 'pointer' }}
					icon={regular('info-circle')}
					color="#8F9FB2"
					size="1x"
					onClick={() =>
						window.open(
							'https://help.getrentcheck.com/en/articles/6151175-fast-track-inspections'
						)
					}
				/>
			</Row>

			<Spacer height={4} />
			<Divider />
		</>
	);
};

export default FastTrack;
