import { FilterMenuItem } from 'components/table/filter-button-v2';
import { useDispatch } from 'react-redux';
import { actionType, icon, useFilter } from './common';

export default () => {
	const dispatch = useDispatch();
	const filter = useFilter();

	const handleClicked = () => {
		dispatch({
			type: actionType,
			value: [],
		});
	};

	const disabled = !!filter;
	const tooltip = disabled ? 'This filter is already in use' : undefined;

	return (
		<FilterMenuItem
			title="Feature Rating"
			icon={icon}
			onClick={handleClicked}
			{...{ disabled, tooltip }}
		/>
	);
};
