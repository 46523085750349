import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
	faCcAmex,
	faCcDinersClub,
	faCcDiscover,
	faCcJcb,
	faCcMastercard,
	faCcVisa,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Divider, Paper, Typography } from '@mui/material';
import { SubscriptionPaymentMethod } from '@rentcheck/types';
import { Column, Row, Spacer } from 'components';
import _ from 'lodash';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';
import { handleUpdatePaymentMethod } from './common';

const CreditCardIcon = (props: {
	paymentMethod: SubscriptionPaymentMethod;
}) => {
	if (props.paymentMethod.type === 'direct_debit') {
		return <FontAwesomeIcon icon={solid('bank')} color="#4d4d4d" />;
	}

	const card = props.paymentMethod.card;

	if (!card) {
		return null;
	}

	const icon = {
		visa: faCcVisa,
		mastercard: faCcMastercard,
		american_express: faCcAmex,
		jcb: faCcJcb,
		diners_club: faCcDinersClub,
		discover: faCcDiscover,
		bancontact: solid('credit-card'),
		other: solid('credit-card'),
		not_applicable: solid('credit-card'),
	};

	//@ts-ignore
	return <FontAwesomeIcon icon={icon[card.brand]} color="#4d4d4d" />;
};

const BankAccount = (props: { paymentMethod: SubscriptionPaymentMethod }) => {
	const { paymentMethod } = props;

	if (!paymentMethod.bank_account) {
		return null;
	}

	return (
		<>
			<Row>
				<CreditCardIcon paymentMethod={paymentMethod} />
				<Typography variant="subtitle1" ml={2}>
					{_.capitalize(paymentMethod.bank_account.bank_name ?? 'Bank Account')}{' '}
					****
					{paymentMethod.bank_account.last4}
				</Typography>
			</Row>
		</>
	);
};

const CreditCard = (props: { paymentMethod: SubscriptionPaymentMethod }) => {
	const { paymentMethod } = props;

	if (!paymentMethod.card) {
		return null;
	}

	return (
		<>
			<Row>
				<CreditCardIcon paymentMethod={paymentMethod} />
				<Typography variant="subtitle1" ml={2}>
					{_.capitalize(paymentMethod.card.brand)} ****
					{paymentMethod.card.last4}
				</Typography>
			</Row>
			<Typography variant="body2" mt={2}>
				Expires {moment(paymentMethod.card.expiry_month, 'M').format('MMMM')}{' '}
				{paymentMethod.card.expiry_year}
			</Typography>
		</>
	);
};

export default () => {
	const dispatch = useDispatch<Dispatch>();

	const profile = useTypedSelector((state) => state.activeProfile);
	const subscription = useTypedSelector((state) => state.subscription);

	const canUpdate =
		profile.email.toLowerCase() === subscription?.rentcheck_contact.email;

	if (!subscription) {
		return null;
	}

	if (!subscription.payment_method) {
		return null;
	}

	if (
		subscription.payment_method.type !== 'card' &&
		subscription.payment_method.type !== 'direct_debit'
	) {
		return null;
	}

	const paymentMethod = subscription.payment_method;

	return (
		<Paper
			elevation={0}
			sx={{ border: '1px solid #0000001F', padding: '8px 0', marginTop: 3 }}>
			<Typography variant="overline" sx={{ margin: '0 16px 16px 16px' }}>
				Payment method
			</Typography>
			<Divider sx={{ marginTop: 1 }} />
			<Column style={{ padding: '12px 16px' }}>
				<BankAccount paymentMethod={paymentMethod} />
				<CreditCard paymentMethod={paymentMethod} />
				<Spacer height={3} />
				<Row>
					<Button
						variant="outlined"
						onClick={() =>
							handleUpdatePaymentMethod(dispatch, profile, subscription)
						}
						startIcon={
							<FontAwesomeIcon
								icon={canUpdate ? solid('credit-card') : solid('lock')}
							/>
						}>
						Update payment method
					</Button>
				</Row>
			</Column>
		</Paper>
	);
};
