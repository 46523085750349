import { LoadingButton } from '@mui/lab';
import {
	Autocomplete,
	Card,
	CardContent,
	TextField,
	Typography,
} from '@mui/material';
import { Inspection, InspectionStatus } from '@rentcheck/types';
import { InspectionsApi } from 'api';
import { SpacedRow, Spacer } from 'components';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';
import { Dispatch } from 'types';

interface Props {
	inspection: Inspection;
	refreshInspection: () => void;
}

const ChangeStatus = ({ inspection, refreshInspection }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const [newValue, setNewValue] = useState<InspectionStatus | null>(null);
	const [loading, setLoading] = useState(false);

	const options: InspectionStatus[] = [
		'Continue Inspection',
		'Review Inspection',
		'Inspection Complete',
	];

	const handleConfirm = async () => {
		if (!inspection) {
			return;
		}

		if (!newValue) {
			return;
		}

		if (!inspection.renterID) {
			dispatch(
				SnackbarActions.showError(
					"Can't change status for an inspection without a renterID"
				)
			);
			return;
		}

		setLoading(true);

		await InspectionsApi.update(inspection.id, {
			inspection_status: newValue,
			performed_by: inspection.performed_by || inspection.renterID,
		}).catch((e) => dispatch(SnackbarActions.showError(e.message)));

		refreshInspection();

		setLoading(false);
	};

	return (
		<Card sx={{ width: '100%' }}>
			<CardContent>
				<Typography variant="h6">Change Status</Typography>
				<Spacer height={2} />
				<SpacedRow>
					<Autocomplete
						fullWidth
						id="autocomplete-inspection-type"
						options={options}
						value={newValue}
						onChange={(_, newNewValue) => setNewValue(newNewValue)}
						renderInput={(params) => (
							<TextField
								variant="filled"
								placeholder="Inspection Status"
								{...params}
							/>
						)}
					/>

					<Spacer width={4} />

					<LoadingButton loading={loading} onClick={handleConfirm}>
						Confirm
					</LoadingButton>
				</SpacedRow>
			</CardContent>
		</Card>
	);
};

export default ChangeStatus;
