import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
	ApiInspectionEvent,
	InspectionEventReminderSentMetadata,
} from '@rentcheck/types';

import BaseTimelineEvent from '../base-event';

interface Props {
	event: ApiInspectionEvent;
}

const ReminderTimelineEvent = ({ event }: Props) => {
	const metadata = event.metadata as InspectionEventReminderSentMetadata;

	const isScheduledReminder = () => {
		return event.type === 'inspection_reminder_sent_scheduled';
	};

	const title = isScheduledReminder()
		? 'Scheduled reminder sent'
		: 'Email reminder sent';

	return (
		<BaseTimelineEvent
			icon={solid('bell')}
			title={title}
			subtitle={
				!isScheduledReminder() && metadata.sent_by
					? `Sent by ${metadata.sent_by}`
					: undefined
			}
			timestamp={event.timestamp}
			sentTo={metadata.emails_sent}
			note={metadata.note}
		/>
	);
};

export default ReminderTimelineEvent;
