import React from 'react';
import { Button, DialogActions, DialogContent } from '@mui/material';
import { Spacer } from 'components';

import { Step } from '..';
import Skill from './skill';

interface Props {
	skill: string | null;
	setSkill: (value: string) => void;
	nextStep: (step: Step) => void;
	previousStep: () => void;
}

const SelectSkill = ({ nextStep, previousStep, skill, setSkill }: Props) => {
	const handleNext = () => {
		nextStep('Work Order Details');
	};

	const handleBack = () => {
		setSkill('');
		previousStep();
	};

	return (
		<>
			<DialogContent>
				<Spacer height={10} />
				<Skill value={skill} setValue={setSkill} />
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" color="secondary" onClick={handleBack}>
					Back
				</Button>
				<Button onClick={handleNext} disabled={!skill}>
					Next
				</Button>
			</DialogActions>
		</>
	);
};

export default SelectSkill;
