import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Autocomplete, FilterOptionsState, TextField } from '@mui/material';
import {
	FilterChip,
	FilterChipContent,
} from 'components/table/filter-button-v2';
import { useEffect, useMemo, useRef, useState } from 'react';

import { MultiValueFilter } from '@rentcheck/types';
import MenuItem from 'components/menu-item';
import _ from 'lodash';
import { FilterChipRef } from '..';
import OptionMenuItem from './option-menu-item';
import Tags from './tags';

interface Props<T> {
	title: string;
	icon: IconDefinition;
	value: T[] | undefined;
	condition: MultiValueFilter['condition'];

	setFilterValue: (value: MultiValueFilter<T> | undefined) => void;

	/**
	 * If `true`, the component is in a loading state.
	 * This shows the `loadingText` in place of suggestions (only if there are no suggestions to show, e.g. `options` are empty).
	 * @default false
	 */
	loading?: boolean;

	/**
	 * If `true`, `value` must be an array and the menu will support multiple selections.
	 * @default false
	 */
	multiple?: boolean;

	/**
	 * Array of options.
	 */
	options: T[];

	/**
	 * Used to determine the string value for a given option.
	 * It's used to fill the input (and the list box options if `renderOption` is not provided).
	 *
	 * @param {T} option
	 * @returns {string}
	 * @default (option) => option.label ?? option
	 */
	getOptionLabel?: (option: T) => string;

	/**
	 * Used to determine the string subtitle value for a given option.
	 *
	 * @param {T} option
	 * @returns {string}
	 * @default (option) => option.label ?? option
	 */
	getOptionSubLabel?: (option: T) => string;

	/**
	 * Used to determine the chip label value for a given option.
	 *
	 * @param {T} option
	 * @returns {string}
	 * @default (option) => option.label ?? option
	 */
	getOptionChipProps?: (option: T) => {
		label: string;
		color: string;
	};

	/**
	 * Used to determine if the option represents the given value.
	 * Uses strict equality by default.
	 * ⚠️ Both arguments need to be handled, an option can only match with one value.
	 *
	 * @param {T} option The option to test.
	 * @param {T} value The value to test against.
	 * @returns {boolean}
	 */
	isOptionEqualToValue?: (option: T, value: T) => boolean;
}

const AutocompleteFilterChip = <T,>({
	title,
	icon,
	condition,
	value,
	setFilterValue,
	options,
	multiple,
	loading,
	getOptionLabel,
	getOptionSubLabel,
	getOptionChipProps,
	isOptionEqualToValue,
}: Props<T>) => {
	const chipRef = useRef<FilterChipRef>(null);
	const textFieldRef = useRef<HTMLInputElement>(null);

	/**
	 * Once the user has interacted with the autocomplete input, and blurs
	 * it, we want to show the required error if the user hasn't selected
	 * any options.
	 */
	const [canShowRequiredError, setCanShowRequiredError] = useState(false);

	const [selectedOptions, setSelectedOptions] = useState<T[]>([]);
	const [selectedCondition, setSelectedCondition] =
		useState<MultiValueFilter['condition']>('any_of');

	/**
	 * This saves the focus state of the autocomplete input.
	 * See below why this is needed.
	 */
	const [focusState, setFocusState] = useState<'focus' | 'blur'>('blur');

	useEffect(() => {
		initializeState(value, condition);
	}, [value, condition]);

	const initializeState = (
		value: T[] | undefined,
		condition: MultiValueFilter['condition']
	) => {
		setSelectedOptions(value ?? []);
		setSelectedCondition(condition);
	};

	const handleDeleted = () => {
		setFilterValue(undefined);
	};

	const handleDismissed = () => {
		initializeState(value, condition);

		if (!value?.length) {
			handleDeleted();
		}
	};

	const label = useMemo(() => {
		if (!value?.length) {
			return undefined;
		}

		if (value.length === 1) {
			const option = value[0];
			const conditionLabel = condition === 'any_of' ? 'is' : 'is not';
			const optionsLabel = _.truncate(
				getOptionLabel?.(option) ?? (option as string),
				{ length: 20 }
			);

			return `${conditionLabel} ${optionsLabel}`;
		}

		const conditionLabel =
			condition === 'any_of' ? 'is any of these' : 'is none of these';
		const optionsLabel = `${value.length}`;

		return `${conditionLabel} (${optionsLabel})`;
	}, [value, condition]);

	const handleApply = () => {
		chipRef.current?.close(true);

		return setFilterValue({
			value: selectedOptions,
			condition: selectedCondition,
		});
	};

	const handleShowed = () => {
		textFieldRef.current?.focus();
	};

	const filterOptions = (options: T[], state: FilterOptionsState<T>) => {
		const searchTerm = state.inputValue.toLowerCase();

		return options.filter((option) => {
			const label = getOptionLabel?.(option) ?? option;
			const subLabel = getOptionSubLabel?.(option) ?? '';
			const chipLabel = getOptionChipProps?.(option)?.label ?? '';

			return `${label} ${subLabel} ${chipLabel}`
				.toLowerCase()
				.includes(searchTerm);
		});
	};

	/**
	 * We need to do this because the Autocomplete component
	 * uses popper, which doesn't capture all click events outside of it.
	 * This causes the whole chip popover to be dismissed when most likely
	 * the user just wanted to blur the input.
	 */
	const handleFocus = () => {
		setTimeout(() => setFocusState('focus'), 200);
	};

	const handleBlur = () => {
		setCanShowRequiredError(true);
		setTimeout(() => setFocusState('blur'), 200);
	};

	if (!value) {
		return null;
	}

	return (
		<FilterChip
			ref={chipRef}
			title={title}
			label={label}
			icon={icon}
			onDelete={handleDeleted}
			onDismiss={handleDismissed}
			onShow={handleShowed}
			preventDismiss={focusState === 'focus'}>
			<FilterChipContent
				title={title}
				onApply={handleApply}
				applyDisabled={selectedOptions.length === 0}>
				<TextField
					select
					sx={{ mb: 2 }}
					value={selectedCondition}
					onChange={(e) =>
						setSelectedCondition(
							e.target.value as MultiValueFilter['condition']
						)
					}>
					<MenuItem value="any_of">Is any of these</MenuItem>
					<MenuItem value="none_of">Is none of these</MenuItem>
				</TextField>
				<Autocomplete
					openOnFocus
					loading={loading}
					clearIcon={null}
					sx={{ width: 300, mb: 2 }}
					filterOptions={filterOptions}
					ListboxProps={{ style: { maxHeight: 200 } }}
					multiple={multiple}
					options={options}
					disableCloseOnSelect={multiple}
					value={selectedOptions}
					onChange={(e, value) => setSelectedOptions(value as any)}
					getOptionLabel={getOptionLabel}
					isOptionEqualToValue={isOptionEqualToValue}
					onFocus={handleFocus}
					onBlur={handleBlur}
					renderTags={(value, getTagProps) => (
						<Tags
							value={value}
							getTagProps={getTagProps}
							getOptionLabel={getOptionLabel}
						/>
					)}
					renderOption={(props, option) => (
						<OptionMenuItem
							option={option}
							autocompleteProps={props}
							getOptionLabel={getOptionLabel}
							getOptionSubLabel={getOptionSubLabel}
							getOptionChipProps={getOptionChipProps}
						/>
					)}
					renderInput={(params) => (
						<TextField
							{...params}
							helperText="Required"
							error={canShowRequiredError && !selectedOptions.length}
							inputRef={textFieldRef}
							sx={{ '.MuiInputBase-root': { minHeight: 56 } }}
							placeholder={`Enter or select ${title.toLowerCase()}`}
						/>
					)}
				/>
			</FilterChipContent>
		</FilterChip>
	);
};

export default AutocompleteFilterChip;
