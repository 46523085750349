import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { CircularProgress, Divider, Typography } from '@mui/material';
import { Column, Row, SpacedRow } from 'components';
import IconButton from 'components/icon-button';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';
import { FileRequest } from 'store/reducers/file-requests';
import { Analytics } from 'utils';

interface Props {
	fileRequest: FileRequest;
	onDownload: (fileRequest: FileRequest) => void;
}

export default ({ fileRequest, onDownload }: Props) => {
	const dispatch = useDispatch();

	const handleDownload = () => {
		onDownload(fileRequest);
	};

	const handleCopy = () => {
		if (!fileRequest.location) return;
		if (!navigator.clipboard) return;

		navigator.clipboard.writeText(fileRequest.location);

		dispatch(
			SnackbarActions.showSuccess('The link was copied to your clipboard')
		);

		if (fileRequest.document_type === 'INSPECTION_REPORT') {
			Analytics.trackEvent('copied_inspection_report_link', {
				inspection_id: fileRequest.document_id,
			});
		}

		if (fileRequest.document_type === 'MAINTENANCE_REPORT') {
			Analytics.trackEvent('copied_maintenance_report_link', {
				report_id: fileRequest.document_id,
			});
		}
	};

	return (
		<Column>
			<SpacedRow style={{ padding: '12px 20px' }}>
				<Column
					style={{
						flex: 1,
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					}}>
					<Typography mb={1}>{fileRequest.title}</Typography>
					<Typography variant="body2" noWrap>
						{fileRequest.subtitle}
					</Typography>
				</Column>
				<div style={{ marginLeft: 8 }}>
					{fileRequest.error && (
						<IconButton
							icon={regular('triangle-exclamation')}
							tooltip={fileRequest.error}
							color="error"
						/>
					)}
					{!fileRequest.error && !fileRequest.location && (
						<CircularProgress size={18} />
					)}
					{!!fileRequest.location && (
						<Row>
							<IconButton
								icon={regular('link')}
								tooltip="Copy Link"
								onClick={handleCopy}
								color="primary"
							/>
							<IconButton
								icon={regular('download')}
								tooltip="Download"
								onClick={handleDownload}
								color="primary"
							/>
						</Row>
					)}
				</div>
			</SpacedRow>
			<Divider />
		</Column>
	);
};
