import { CreateInspectionFlowType } from 'store/reducers/modal-flows';
import { postMessageToNativeApp } from 'utils/helpers';

export const handleClose = () => {
	postMessageToNativeApp({
		type: 'closed-modal',
		modal: 'create-inspection',
	});
};

export const getFlowType = (pathname: string): CreateInspectionFlowType => {
	if (pathname === '/inspections/create/building') {
		return 'building';
	}

	if (pathname === '/inspections/create/community') {
		return 'community';
	}

	return 'unit';
};
