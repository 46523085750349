import { TextField, Typography } from '@mui/material';
import { MaintenanceFlag } from '@rentcheck/types';
import { Spacer } from 'components';
import { useCreateWorkOrderModalData } from 'hooks/modals';
import React, { useEffect, useState } from 'react';
import { featureDisplayName } from 'utils/helpers';

interface Props {
	selectedFlagIds: string[];
	value: string;
	setValue: (value: string) => void;
}

const buildInitialNotes = (flags: MaintenanceFlag[]) => {
	return flags
		.map((flag) => `${featureDisplayName(flag)}: ${flag.note}`)
		.join('\n\n');
};

const Dispatch = ({ selectedFlagIds, value, setValue }: Props) => {
	const modalData = useCreateWorkOrderModalData('Latchel');
	const { flags } = modalData?.metadata ?? {};

	const [userEdited, setUserEdited] = useState(false);

	useEffect(() => {
		if (userEdited) return;

		const selectedFlags = flags.filter((f) => selectedFlagIds.includes(f.id));
		setValue(buildInitialNotes(selectedFlags));
	}, [selectedFlagIds]);

	const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setUserEdited(true);
		setValue(e.target.value);
	};

	return (
		<>
			<Typography fontWeight={600}>Work Order Description</Typography>
			<Spacer height={4} />
			<TextField
				id="textinput-work_order_description"
				multiline
				label="Work Order Description"
				fullWidth
				value={value}
				onChange={handleOnChange}
				sx={{ '.MuiInputBase-input': { fontWeight: 400 } }}
			/>
		</>
	);
};

export default Dispatch;
