import { LoadingButton } from '@mui/lab';
import { Card, CardContent, Typography } from '@mui/material';
import { AdminToolsApi } from '@rentcheck/api-frontend';
import { APIProperty } from '@rentcheck/types';
import { Column, SpacedRow, Spacer } from 'components';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';
import { Dispatch } from 'types';

interface Props {
	property: APIProperty;
	refreshProperty: () => void;
}

const RestoreProperty = ({ property, refreshProperty }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const [loading, setLoading] = useState(false);

	const handleSoftDelete = () => {
		if (!property) {
			return;
		}

		setLoading(true);

		AdminToolsApi.softDeleteProperty(property.id)
			.then(() => {
				refreshProperty();
				dispatch(SnackbarActions.showSuccess('Property Deleted'));
			})
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	return (
		<Card sx={{ mt: 2, width: '100%' }}>
			<CardContent>
				<Typography variant="h6">Soft Delete</Typography>
				<Spacer height={2} />
				<SpacedRow>
					<Column style={{ flex: 1 }}>
						<Typography>Soft deletes a property</Typography>
					</Column>

					<Spacer width={4} />

					<LoadingButton
						loading={loading}
						onClick={handleSoftDelete}
						sx={{ minWidth: 90, maxWidth: 90 }}>
						Delete
					</LoadingButton>
				</SpacedRow>
			</CardContent>
		</Card>
	);
};

export default RestoreProperty;
