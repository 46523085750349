import { useCreateInspectionModalData } from 'hooks/modals';

import { ApiInspectionTemplateDigest } from '@rentcheck/types';
import { useInspectionTemplates } from 'hooks/inspection-templates';
import TemplateCard from './select-template-card';

interface ListProps {
	template?: ApiInspectionTemplateDigest;
	onClick: (value: ApiInspectionTemplateDigest) => void;
	search: string;
}

export const SelectionList = ({ template, onClick, search }: ListProps) => {
	const modalData = useCreateInspectionModalData();
	const { property_type: propertyType } = modalData?.metadata ?? {};
	const { templates } = useInspectionTemplates({
		property_types: [propertyType],
		search,
		published_only: true,
		include_unavailable: false,
	});

	if (!propertyType) return null;

	const sortedTemplates = templates.sort((a, b) => {
		if (a.id === template?.id) return -1;
		if (b.id === template?.id) return 1;
		return 1;
	});

	return (
		<>
			{sortedTemplates.map((t) => (
				<TemplateCard
					template={t}
					onClick={onClick}
					selected={t.id === template?.id}
				/>
			))}
		</>
	);
};
