import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Autocomplete } from '@mui/material';
import { Occupancy, occupancyStatuses } from '@rentcheck/types';
import FormTextField from 'components/form-text-field';
import _ from 'lodash';

interface Props {
	leaseStatus: Occupancy['status'] | undefined;
	setLeaseStatus: (value: Occupancy['status'] | undefined) => void;
	errors: string[];
}

export default ({ errors, leaseStatus, setLeaseStatus }: Props) => {
	return (
		<Autocomplete
			fullWidth
			disablePortal
			disableClearable
			options={occupancyStatuses}
			value={leaseStatus}
			onChange={(_, newValue) => setLeaseStatus(newValue ?? undefined)}
			getOptionLabel={(o) => _.capitalize(o)}
			renderInput={(params) => (
				<FormTextField
					{...params}
					title="Lease Status"
					placeholder="Lease Status"
					helperText="Required"
					error={errors.includes('lease_status')}
					icon={regular('calendar-day')}
				/>
			)}
		/>
	);
};
