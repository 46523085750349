import React from 'react';
import { useDispatch } from 'react-redux';

import { WorkOrderStatus } from '@rentcheck/types';
import { useTypedSelector } from 'store/reducers/rootReducer';
import {
	FilterTitle,
	FilterTitleOption,
	FilterDetailsProps,
	FilterTitleProps,
	FilterType,
} from 'components/lists/filters';

const title: FilterType = 'Status';

const FilterStatusTitle = ({
	selectedFilter,
	setFilterDetails,
}: FilterTitleProps) => {
	const statusFilter = useTypedSelector(
		(state) => state.maintenance.filters.workOrders.status
	);

	return (
		<FilterTitle
			label={title}
			isActive={statusFilter.length > 0}
			isSelected={selectedFilter === title}
			onClick={() => setFilterDetails(title)}
		/>
	);
};

const statuses: WorkOrderStatus[] = [
	'Canceled',
	'Created',
	'In Progress',
	'Completed',
];

const FilterStatusDetails = ({ selectedFilter }: FilterDetailsProps) => {
	const dispatch = useDispatch();

	const statusFilter = useTypedSelector(
		(state) => state.maintenance.filters.workOrders.status
	);

	if (selectedFilter !== title) return null;

	const handleClick = (status: string) => {
		dispatch({
			type: 'TOGGLE_WORK_ORDERS_STATUS_FILTER',
			value: status,
		});
	};

	return (
		<>
			{statuses.map((s) => (
				<div id={s}>
					<FilterTitleOption
						key={s}
						label={s}
						isSelected={statusFilter.includes(s)}
						onClick={() => handleClick(s)}
					/>
				</div>
			))}
		</>
	);
};

export { FilterStatusTitle, FilterStatusDetails };
