"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.success = exports.caution = exports.error = exports.gray = exports.secondary = exports.primary = exports.modalBackground = exports.transparentWhite = exports.transparentBlack = exports.transparent = exports.black = exports.white = void 0;
exports.white = '#ffffff';
exports.black = '#000000';
exports.transparent = '#ffffff00';
exports.transparentBlack = '#00000080';
exports.transparentWhite = '#FFFFFF70';
exports.modalBackground = '#F6F5FF';
exports.primary = {
    default: '#3d50ff',
    900: '#061aca',
    800: '#222edb',
    700: '#2d3ce6',
    600: '#3847F3',
    500: '#3d50ff',
    400: '#5e6eff',
    300: '#7d8cff',
    200: '#d2d6f8',
    100: '#e0e1fa',
    50: '#f2f2fd',
};
exports.secondary = {
    default: '#232e3a',
    900: '#232e3a',
    800: '#354351',
    700: '#445567',
    600: '#54687E',
    500: '#61768F',
    400: '#798aa0',
    300: '#8F9FB2',
    200: '#AEBBC9',
    100: '#ccd6e1',
    50: '#eff1f4',
};
exports.gray = {
    default: '#101010',
    900: '#101010',
    800: '#353535',
    700: '#535353',
    600: '#666666',
    500: '#8d8d8d',
    400: '#aeaeae',
    300: '#cccaca',
    200: '#e4e4e4',
    100: '#efefef',
    50: '#f8f8f8',
    dark: '#4D4D4D',
};
exports.error = {
    default: '#e63030',
    900: '#b90b17',
    800: '#c71d23',
    700: '#d4252a',
    600: '#e63030',
    500: '#f53b31',
    400: '#f04d4c',
    300: '#e67070',
    200: '#f09898',
    100: '#ffccd1',
    50: '#feeaee',
};
exports.caution = {
    default: '#eb830d',
    900: '#db5c09',
    800: '#e4740d',
    700: '#eb830d',
    600: '#f19314',
    500: '#f59e17',
    400: '#f6ac30',
    300: '#f7bb54',
    200: '#f8cf84',
    100: '#fbe1b5',
    50: '#fcf4e1',
};
exports.success = {
    default: '#36b45f',
    900: '#06622b',
    800: '#1b823e',
    700: '#249449',
    600: '#36b45f',
    500: '#51c978',
    400: '#70db93',
    300: '#93e8af',
    200: '#b4f0c8',
    100: '#cbf7d9',
    50: '#e9f7ec',
};
