import { MaintenanceFlag } from '@rentcheck/types';

import { Divider, MenuItem, Typography } from '@mui/material';
import { SpacedRow } from 'components';
import { useEffect, useState } from 'react';

import FormTextField from 'components/form-text-field';
import { useCreateWorkOrderModalData } from 'hooks/modals';
import _ from 'lodash';
import { getModalConfig } from 'screens/modal-flows-controller/create-work-order-rent-manager/common/modal-config';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { workOrderDefaultDescription } from 'utils/helpers';
import { OkToEnterT, WorkOrderInfo, okToEnterEnum } from '../../common';
import Flags from './flags';

interface Props {
	skill: string;

	index: number;
	total: number;

	flags: MaintenanceFlag[];
	isLast: boolean;
	onChange: (value: WorkOrderInfo) => void;
}

export default ({ flags, skill, index, total, isLast, onChange }: Props) => {
	const modalData = useCreateWorkOrderModalData('Rent Manager');
	const { provider, inspection } = modalData?.metadata ?? {};

	const profile = useTypedSelector((state) => state.activeProfile);

	const [validPriorityNames, setValidPriorityNames] = useState<string[]>([]);

	const [priority, setPriority] = useState<string>('');
	const [okToEnter, setOkToEnter] = useState<OkToEnterT>('No');
	const [description, setDescription] = useState(
		flags.map(workOrderDefaultDescription).join('; \n')
	);

	const modalConfig = getModalConfig(provider);

	useEffect(() => {
		modalConfig
			.getPriorities(inspection.property.id, profile.id)
			.then((data) => _.uniq(data))
			.then((data) => {
				setValidPriorityNames(data);
				setPriority(data[0]);
			});
	}, []);

	useEffect(() => {
		onChange({ priority, okToEnter, description });
	}, [priority, description, okToEnter]);

	useEffect(() => {
		setDescription(flags.map(workOrderDefaultDescription).join('; \n'));
	}, [flags.length]);

	return (
		<>
			<SpacedRow style={{ marginBottom: 8 }}>
				<Typography variant="overline">
					{modalConfig.mappedSkillName}
				</Typography>
				<Typography variant="overline">
					{modalConfig.mappedWorkOrderName} {index + 1}/{total}
				</Typography>
			</SpacedRow>
			<Typography variant="h6">{skill}</Typography>
			<Divider sx={{ mt: 1, mb: 2 }} />

			<FormTextField
				title="Job Description"
				helperText={`This field maps to the service issue field '${modalConfig.mappedDescriptionName}' in ${modalConfig.vendor}`}
				value={description}
				onChange={(e) => setDescription(e.target.value)}
			/>

			<Flags value={flags} />

			<FormTextField
				select
				title="Ok to enter"
				helperText={`This is the ${modalConfig.mappedWorkOrderName.toLowerCase()} field ‘${
					modalConfig.mappedOkToEnterName
				}’ in ${modalConfig.vendor}`}
				value={okToEnter}
				onChange={(e) => setOkToEnter(e.target.value as OkToEnterT)}>
				{okToEnterEnum.map((o) => (
					<MenuItem key={o} value={o}>
						{o}
					</MenuItem>
				))}
			</FormTextField>

			<FormTextField
				select
				title="Priority"
				helperText={`This is the ${modalConfig.mappedWorkOrderName.toLowerCase()} field ‘${
					modalConfig.mappedPriorityName
				}’ in ${modalConfig.vendor}`}
				value={priority}
				onChange={(e) => setPriority(e.target.value)}>
				{validPriorityNames.map((o) => (
					<MenuItem key={o} value={o}>
						{o}
					</MenuItem>
				))}
			</FormTextField>

			{!isLast && <Divider sx={{ mb: 4 }} />}
		</>
	);
};
