import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Rating, Typography } from '@mui/material';
import { DashboardData } from '@rentcheck/api-frontend';
import { CenteredColumn } from 'components';

import Container from './components/container';
import ItemSkeleton from './components/skeleton';
import Tooltip from './components/tooltip';

interface Props {
	data: DashboardData | null;
	loading?: boolean;
}

const ResidentRating = ({ data, loading }: Props) => {
	if (!data) {
		return <ItemSkeleton />;
	}

	if (data.stats.overall_total_inspections < 10) {
		return null;
	}

	const handleCta = () => {
		window.open(
			'https://help.getrentcheck.com/en/articles/6574817-the-rentcheck-dashboard'
		);
	};

	return (
		<Container
			loading={loading}
			title="Resident Rating"
			subtitle={`Ratings: ${data.stats.total_inspection_ratings}`}>
			<CenteredColumn>
				<Typography fontSize={18} fontWeight={400}>
					{data.stats.average_inspection_rating.toFixed(2)}
				</Typography>
				<Typography color="#798AA0" style={{ marginTop: -4 }}>
					out of 5
				</Typography>
				<Rating
					value={data.stats.average_inspection_rating}
					readOnly
					precision={0.05}
					size="small"
					sx={{
						'& .MuiRating-iconFilled': {
							color: '#3847F3',
						},
					}}
				/>
			</CenteredColumn>
			<Tooltip
				title="What’s this?"
				body="The average rating your residents have given indicating their level of satisfaction with RentCheck."
				cta="Learn More"
				handleCta={handleCta}>
				<FontAwesomeIcon
					icon={solid('info-circle')}
					color="#8F9FB2"
					size="sm"
				/>
			</Tooltip>
		</Container>
	);
};

export default ResidentRating;
