import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import { TenantsApi } from '@rentcheck/api-frontend';
import { ListOptions, Occupancy } from '@rentcheck/types';

import { Dispatch } from 'types';
import { buildSMVFilter } from 'utils/filters';
import { SnackbarActions, TenantsActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { State as FiltersState } from 'store/reducers/tenants/filters';

import useApiModel from './common/api-hook';

export const useSortedFilteredTenants = (overwriteFilters?: FiltersState) => {
	const dispatch: Dispatch = useDispatch();

	const profile = useTypedSelector((state) => state.activeProfile);
	const tenantsState = useTypedSelector((state) => state.tenants.tenants);
	const filters = useTypedSelector((state) => state.tenants.filters);

	const [loading, setLoading] = useState(false);
	const [initialLoading, setInitialLoading] = useState(true);

	const fetchResidents = (filters: FiltersState) => {
		setLoading(true);

		dispatch(TenantsActions.getAll(0, filters))
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => {
				setLoading(false);
				setInitialLoading(false);
			});
	};

	const fetchMore = () => {};

	const effectiveFilters = overwriteFilters || filters;

	useEffect(() => {
		if (!profile) return;

		fetchResidents(filters);
	}, [
		profile,
		effectiveFilters.active_only,
		effectiveFilters.search,
		effectiveFilters.propertyId,
		effectiveFilters.email,
		effectiveFilters.sortBy,
		effectiveFilters.sortType,
	]);

	return {
		initialLoading,
		loading,
		fetchMore,
		filters,
		tenants: tenantsState,
	};
};

export const useTenants = (
	pageSize: number = 20,
	filters?: {
		search?: string;
		deleted?: boolean;
		active?: boolean;
		occupancy_status?: Occupancy['status'][];
		emails?: string;
		property_id?: string;
	}
) => {
	const defaultSort: ListOptions['sort'] = {
		sort_by: 'first_name',
		sort_type: 'ASC',
	};

	const getAllFilters = filters
		? {
				search: filters.search,
				deleted: filters.deleted,
				active: filters.active,
				occupancy_status: buildSMVFilter(filters.occupancy_status),
				emails: filters.emails,
				property_id: filters.property_id,
			}
		: {};

	const hookData = useApiModel(
		TenantsApi,
		getAllFilters,
		defaultSort,
		pageSize
	);

	return {
		tenants: hookData.data,
		..._.omit(hookData, 'data'),
	};
};
