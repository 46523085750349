import { Screens } from 'components';

import HeaderSkeleton from './header/skeleton';

const InspectionDetailsSkeleton = () => {
	return (
		<>
			<Screens.Container>
				<HeaderSkeleton />
			</Screens.Container>
		</>
	);
};

export default InspectionDetailsSkeleton;
