import { Alert, Snackbar } from '@mui/material';
import { useEffect, useState } from 'react';

interface Props {
	editMode: boolean;
}

const EditSnackbar = ({ editMode }: Props) => {
	const [open, setOpen] = useState(true);

	useEffect(() => {
		setOpen(true);
	}, [editMode]);

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Snackbar
			open={editMode && open}
			onClose={handleClose}
			autoHideDuration={null}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			sx={{ marginBottom: 2 }}>
			<Alert
				severity="info"
				onClose={handleClose}
				icon={false}
				sx={{ color: '#fff', backgroundColor: '#232E3A' }}>
				Sync will not start until the Save button is pressed above.
				<br />
				You will have a chance to review final sync settings before starting the
				sync.
			</Alert>
		</Snackbar>
	);
};

export default EditSnackbar;
