import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Divider,
	Typography,
} from '@mui/material';
import { BaseIntegration } from '@rentcheck/types';
import { DialogTitle } from 'components';
import { useEffect, useState } from 'react';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { useIsMobileBreakpoint } from 'utils/hooks';

import { ExternalTeam, TeamAssignmentType } from '../types';

import List from './list';
import Skeleton from './skeleton';

interface Props {
	vendor: string;
	teamMappings?: BaseIntegration['team_mappings'];
	setTeamMappings: (value?: BaseIntegration['team_mappings']) => void;
	open: boolean;
	handleClose: () => void;
	teamAssignment: TeamAssignmentType;
}

export default ({
	open,
	handleClose,
	vendor,
	teamMappings,
	setTeamMappings,
	teamAssignment,
}: Props) => {
	const isMobile = useIsMobileBreakpoint('sm');

	const subscription = useTypedSelector((state) => state.subscription);

	const [value, setValue] = useState<BaseIntegration['team_mappings']>();
	const [externalTeams, setExternalTeams] = useState<ExternalTeam[]>([]);
	const [hasError, setHasError] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(true);

		teamAssignment.getExternalTeams().then((result) => {
			setExternalTeams(result);
			setLoading(false);
		});
	}, [subscription]);

	useEffect(() => {
		setValue(teamMappings);
	}, [teamMappings, open]);

	useEffect(() => {
		setHasError(false);
	}, [value]);

	const handleSave = () => {
		if (!value?.default) {
			setHasError(true);
			return;
		}

		setTeamMappings(value);
		handleClose();
	};

	return (
		<Dialog open={open} onClose={handleClose} fullScreen={isMobile}>
			<DialogTitle
				title="Team Assignment"
				onClose={handleClose}
				icon={solid('users')}
			/>
			<DialogContent>
				<Typography variant="body2" color="#00000099">
					New units and buildings imported will be assigned to a team in your
					RentCheck account. You can map your {vendor}{' '}
					{teamAssignment.extTeamName}s to RentCheck teams below. If the{' '}
					{teamAssignment.extTeamName} is updated in {vendor} the team
					assignment will also be automatically updated in RentCheck on a daily
					basis.
				</Typography>
				<Divider sx={{ marginTop: 2, marginBottom: 2 }} />

				{loading && <Skeleton />}
				{!loading && (
					<List
						vendor={vendor}
						extTeamName={teamAssignment.extTeamName}
						externalTeams={externalTeams}
						teamMappings={value}
						setTeamMappings={setValue}
						error={hasError}
					/>
				)}
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" color="secondary" onClick={handleClose}>
					Back
				</Button>
				<Button onClick={handleSave}>Save</Button>
			</DialogActions>
		</Dialog>
	);
};
