import { TableCell, TableRow, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

import { ApiTenant } from '@rentcheck/types';

import { ModalFlowActions } from 'store/actions';

interface Props {
	resident: ApiTenant;
}

export default ({ resident }: Props) => {
	const dispatch = useDispatch();

	const showResident = (tenant: ApiTenant) => {
		dispatch(ModalFlowActions.showResidentModal({ resident: tenant }));
	};

	return (
		<TableRow sx={{ cursor: 'pointer' }} onClick={() => showResident(resident)}>
			<TableCell>
				<Typography>{resident.first_name}</Typography>
			</TableCell>
			<TableCell>
				<Typography>{resident.last_name}</Typography>
			</TableCell>
			<TableCell>
				<Typography>{resident.emails[0] || '-'}</Typography>
			</TableCell>
		</TableRow>
	);
};
