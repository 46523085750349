import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Button,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import {
	ApiTeamDetails,
	MembershipRole,
	TeamInvitesInfo,
} from '@rentcheck/types';
import { CenteredRow, PopoverMenu } from 'components';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { SnackbarActions, TeamInvitesActions } from 'store/actions';
import styled from 'styled-components';
import { spacingPx } from 'theme';
import { Dispatch } from 'types';
import { shortDisplayDate } from 'utils/helpers';
import RowActions from './row-actions';

interface Props {
	team: ApiTeamDetails;
}

interface TeamInviteRowProps {
	team: ApiTeamDetails;
	invite: TeamInvitesInfo;
}

const TeamInviteRow = ({ invite, team }: TeamInviteRowProps) => {
	const dispatch = useDispatch<Dispatch>();

	const userCanEdit = team.user_role === 'admin' ?? false;

	const editInviteRole = (role: MembershipRole) => {
		dispatch(TeamInvitesActions.update(team.id, invite.id, { role })).catch(
			(e) => dispatch(SnackbarActions.showError(e))
		);
	};

	const popoverOptions = [
		{ title: 'Admin', onClick: () => editInviteRole('admin') },
		{
			title: 'Manager',
			onClick: () => editInviteRole('manager'),
			isLast: true,
		},
	];

	return (
		<TableRow>
			<TableCell>{invite.email}</TableCell>
			<TableCell>Sent</TableCell>
			<TableCell>{shortDisplayDate(invite.created_at)}</TableCell>
			<TableCell>
				{!userCanEdit && (
					<Button variant="outlined" color="secondary">
						{_.capitalize(invite.role)}
					</Button>
				)}
				{userCanEdit && (
					<RoleContainer>
						<PopoverMenu
							width={50}
							topSpace={12}
							triggerNode={
								<Button
									variant="outlined"
									color="secondary"
									endIcon={<RightIcon icon={solid('sort-down')} />}>
									{_.capitalize(invite.role)}
								</Button>
							}
							options={popoverOptions}
						/>
					</RoleContainer>
				)}
			</TableCell>
			<RowActions invite={invite} team={team} />
		</TableRow>
	);
};

const Invites = ({ team }: Props) => {
	const headers = ['Email', 'Status', 'Invited', 'Role', ''];

	return (
		<TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow>
						{headers.map((header) => {
							return <TableCell key={header}>{header}</TableCell>;
						})}
					</TableRow>
				</TableHead>

				<TableBody>
					{team.pending_users.map((invite) => (
						<TeamInviteRow key={invite.id} invite={invite} team={team} />
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

const RightIcon = styled(FontAwesomeIcon)`
	margin-bottom: ${spacingPx(2)};
`;

const RoleContainer = styled(CenteredRow)`
	width: ${spacingPx(30)};
`;

export default Invites;
