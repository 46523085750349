import { Divider, Paper } from '@mui/material';

import Header from './header';
import Content from './content';

const DailySnapshot = () => {
	return (
		<Paper sx={{ flex: 1, border: '1px solid #CCD6E1' }}>
			<Header />
			<Divider />
			<Content />
		</Paper>
	);
};

export default DailySnapshot;
