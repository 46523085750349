import { Button, Typography } from '@mui/material';
import { ApiInspection } from '@rentcheck/types';
import { useEffect, useState } from 'react';
import { InspectionActions } from 'store/actions';
import styled from 'styled-components';
import { colors, spacingPx } from 'theme';
import { Spacer } from '../../../';
import Modal from '../../../Common/Modal';
import ComparisonRow from './ComparisonRow';

interface Props {
	currentInspections: ApiInspection[];
	editIndex: number;
	setHidden: () => void;
}

const EditComparisonModal = ({
	editIndex,
	setHidden,
	currentInspections,
}: Props) => {
	const [inspections, setInspections] = useState<ApiInspection[]>([]);
	const [selectedInspection, setSelectedInspection] = useState(
		currentInspections[editIndex - 1]
	);

	useEffect(() => {
		if (!currentInspections.length) {
			return;
		}

		InspectionActions.getInspectionsForComparison(
			currentInspections[0].property.id
		).then((inspections) => setInspections(inspections));
	}, [currentInspections]);

	const inspectionBeingEdited = currentInspections[editIndex - 1];
	const otherInspection = currentInspections[editIndex === 1 ? 1 : 0];

	return (
		<Modal targetId="edit-comparison" setHidden={setHidden}>
			<Typography variant="h6">Select New Inspection #{editIndex}</Typography>
			<Typography>
				This will replace the current Inspection #{editIndex} in the comparison
			</Typography>
			<Spacer height={5} />
			<InspectionsContainer>
				{inspections.map((i) => (
					<ComparisonRow
						inspection={i}
						currentSelection={inspectionBeingEdited}
						selected={i.id === selectedInspection.id}
						disabled={i.id === otherInspection.id}
						setSelectedInspection={(inspection) =>
							setSelectedInspection(inspection)
						}
					/>
				))}
			</InspectionsContainer>
			<Spacer height={5} />
			<Divider />
			<Spacer height={5} />
			<SpacedRow>
				<ButtonContainer left>
					<Button variant="outlined" color="secondary" onClick={setHidden}>
						Cancel
					</Button>
				</ButtonContainer>
				<ButtonContainer right>
					<Button
						onClick={() =>
							window.open(
								`/inspections/comparison/${selectedInspection.id}-${otherInspection.id}`,
								'_self'
							)
						}>
						Update Comparison
					</Button>
				</ButtonContainer>
			</SpacedRow>
		</Modal>
	);
};

const Divider = styled.div`
	height: 1px;
	width: 100%;
	background-color: ${colors.contentBorder};
`;

const SpacedRow = styled.div`
	flex-direction: row;
	display: flex;
	justify-content: space-between;
`;

const ButtonContainer = styled.div<{ right?: boolean; left?: boolean }>`
	display: flex;
	flex-direction: column;

	padding-left: ${({ right }) => (right ? spacingPx(2) : 0)};
	padding-right: ${({ left }) => (left ? spacingPx(2) : 0)};
`;

const InspectionsContainer = styled.div`
	max-height: ${spacingPx(84)};
	overflow: scroll;
	padding-right: ${spacingPx(3)};
`;

export default EditComparisonModal;
