import { Rules } from '@rentcheck/biz';
import usePermissionGroups from 'hooks/permission-groups';
import { useTypedSelector } from 'store/reducers/common';
import { LoadingButton, LoadingButtonProps } from 'components/button';

export const EditBillingPlanButton = ({
	children,
	...props
}: LoadingButtonProps) => {
	const profile = useTypedSelector((state) => state.activeProfile);
	const subscription = useTypedSelector((state) => state.subscription);
	const { permissionGroups, loading } = usePermissionGroups(100, {
		user_id: profile.id,
	});

	const disabled = !Rules.Billing.canEditPlan({
		user: profile,
		subscription,
		permissionGroups,
	});

	const tooltip =
		disabled && !loading
			? "Heads up! You don't have permissions to modify this subscription."
			: '';

	return (
		<LoadingButton
			tooltip={tooltip}
			disabled={disabled}
			loading={loading}
			{...props}>
			{children}
		</LoadingButton>
	);
};
