import { Checkbox, Typography } from '@mui/material';
import { ApiInspection } from '@rentcheck/types';
import { Row } from 'components';
import { Settings } from './common';

import Section from './section';

interface Props {
	inspections: ApiInspection[];
	settings: Settings;
	setSettings: (value: Settings) => void;
}

export default ({ settings, setSettings }: Props) => {
	const handleEmailAndSMS = () => {
		setSettings('Email and SMS');
	};

	const handleEmail = () => {
		setSettings('Email only');
	};

	return (
		<Section title="Settings" isLastSection>
			<Row>
				<Checkbox
					size="small"
					onClick={handleEmailAndSMS}
					checked={settings === 'Email and SMS'}
				/>
				<Typography>Email and SMS</Typography>
			</Row>
			<Row>
				<Checkbox
					size="small"
					onClick={handleEmail}
					checked={settings === 'Email only'}
				/>
				<Typography>Email only</Typography>
			</Row>
		</Section>
	);
};
