import { Box, Link } from '@mui/material';
import { ApiInspection } from '@rentcheck/types';
import { useState } from 'react';

import { Rules } from '@rentcheck/biz';
import EditLabelModal from './edit-label-modal';

interface Props {
	inspection: ApiInspection;
}

export default ({ inspection }: Props) => {
	const [inspectionLabelModalOpen, setInspectionLabelModalOpen] =
		useState(false);

	const handleAddInspectionLabel = () => {
		setInspectionLabelModalOpen(true);
	};

	/**
	 * If the user can't edit the label then we only show the label
	 * UI if the label exists and don't let them edit it.
	 */
	if (!Rules.Inspections.canEditLabel(inspection)) {
		return (
			<>
				{!!inspection.inspection_label && (
					<Box>Inspection Label: {inspection.inspection_label}</Box>
				)}
			</>
		);
	}

	return (
		<>
			{!!inspection.inspection_label && (
				<Box>
					Inspection Label:{' '}
					<Link href="javascript: void(0)" onClick={handleAddInspectionLabel}>
						{inspection.inspection_label}
					</Link>
				</Box>
			)}
			{!inspection.inspection_label && (
				<Link href="javascript: void(0)" onClick={handleAddInspectionLabel}>
					Add Inspection Label
				</Link>
			)}
			<EditLabelModal
				inspection={inspection}
				open={inspectionLabelModalOpen}
				onClose={() => setInspectionLabelModalOpen(false)}
			/>
		</>
	);
};
