import { useState } from 'react';
import { Menu, MenuItem, Button } from '@mui/material';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
	title: string;
	menu: Record<string, () => void>;
}

export default ({ title, menu }: Props) => {
	const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

	const handleOpenMenu = (e: React.MouseEvent<HTMLElement>) => {
		setAnchorElement(e.currentTarget);
	};

	const handleCloseMenu = () => {
		setAnchorElement(null);
	};

	return (
		<>
			<Button
				variant="outlined"
				onClick={handleOpenMenu}
				endIcon={<FontAwesomeIcon icon={solid('caret-down')} />}>
				{title}
			</Button>

			<Menu
				open={!!anchorElement}
				anchorEl={anchorElement}
				onClose={handleCloseMenu}
				transformOrigin={{ vertical: 'top', horizontal: 'left' }}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
				{Object.keys(menu).map((m) => (
					<MenuItem
						onClick={() => {
							/**
							 * Had to add this here to prevent a user from dismissing this
							 * menu and quickly tapping the revision button, which causes
							 * overlapping options to fire instead of the actual revision modal
							 */
							if (!anchorElement) return;
							handleCloseMenu();
							menu[m]();
						}}>
						{m}
					</MenuItem>
				))}
			</Menu>
		</>
	);
};
