import { LoadingButton } from '@mui/lab';
import {
	Button,
	DialogActions,
	DialogContent,
	Divider,
	MenuItem,
	Typography,
} from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { OrganizeBy } from '@rentcheck/types';

import { Spacer } from 'components';
import FormTextField from 'components/form-text-field';
import { useCreateMaintenanceReportModalData } from 'hooks/modals';
import _ from 'lodash';
import {
	MaintenanceActions,
	ModalFlowActions,
	SnackbarActions,
} from 'store/actions';
import { Dispatch } from 'types';
import { Analytics } from 'utils';
import { isEmbeddedInMobileApp } from 'utils/helpers';

const MaintenanceReportModal = () => {
	const history = useHistory();

	const modalData = useCreateMaintenanceReportModalData();
	const { inspection, flags } = modalData?.metadata ?? {};

	const [reportName, setReportName] = useState(
		`${inspection.property.address} Maintenance`
	);
	const [organizeBy, setOrganizeBy] = useState<OrganizeBy>('room');
	const [loading, setLoading] = useState(false);

	const dispatch: Dispatch = useDispatch();

	const handleBackToInspection = () => {
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	const handleCreate = async () => {
		setLoading(true);

		dispatch(
			MaintenanceActions.reports.create(
				reportName,
				organizeBy,
				flags.map((f) => _.pick(f, ['id', 'feature_id']))
			)
		)
			.then((report) => {
				Analytics.trackEvent('created maintenance report', {
					id: report.id,
					inspection_id: inspection.id,
					property_id: inspection.property.id,
					property_address: inspection.property.address,
					created_date: new Date(),
				});

				dispatch(
					SnackbarActions.showSuccess('Success! Maintenance Report created')
				);

				if (isEmbeddedInMobileApp()) {
					dispatch(ModalFlowActions.closeCurrentModal());
				} else {
					history.push(`/maintenance/reports/${report.id}`);
				}
			})
			.catch(() => dispatch(SnackbarActions.showError()))
			.finally(() => {
				setLoading(false);
			});
	};

	if (!flags.length) {
		return null;
	}

	const organizeByOptions = [
		{ label: 'Room/Area', value: 'room' },
		{ label: 'Responsibility', value: 'responsibility' },
		{ label: 'Skill/Vendor Trade', value: 'skill' },
	];

	return (
		<>
			<DialogContent>
				<FormTextField
					title="Maintenance Report Name"
					fullWidth
					placeholder="New maintenance title"
					value={reportName}
					onChange={(e) => setReportName(e.target.value)}
				/>

				<FormTextField
					title="Organize Report By"
					select
					value={organizeBy}
					onChange={(e) => setOrganizeBy(e.target.value as OrganizeBy)}>
					{organizeByOptions.map((r) => (
						<MenuItem key={r.value} value={r.value}>
							{r.label}
						</MenuItem>
					))}
				</FormTextField>

				<Spacer height={4} />
				<Divider />
				<Spacer height={4} />

				<Typography variant="subtitle2">Total flags: {flags.length}</Typography>
				<Spacer height={20} />
			</DialogContent>

			<DialogActions>
				<Button
					onClick={handleBackToInspection}
					variant="text"
					color="secondary">
					Cancel
				</Button>

				<LoadingButton
					variant="contained"
					loading={loading}
					disabled={!reportName.length}
					onClick={handleCreate}>
					Create Report
				</LoadingButton>
			</DialogActions>
		</>
	);
};

export default MaintenanceReportModal;
