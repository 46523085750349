import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DialogTitle, IconButton } from '@mui/material';
import { Column, Row, SpacedRow, Spacer } from 'components';
import { useHistory } from 'react-router-dom';

import Chip, {
	SnapshotLabel,
} from 'screens/dashboard/daily-snapshot/content/snapshot-row/chip';

interface Props {
	title: string;
	chipTitle: SnapshotLabel;
}

const Title = ({ title, chipTitle }: Props) => {
	const history = useHistory();

	const handleClose = () => {
		history.push('/dashboard');
	};

	return (
		<DialogTitle>
			<SpacedRow>
				<Column>
					{title}
					<Spacer height={2} />
					<Row>
						<Chip label={chipTitle} />
					</Row>
				</Column>
				<IconButton sx={{ width: 38.5 }} onClick={handleClose}>
					<FontAwesomeIcon icon={regular('times')} />
				</IconButton>
			</SpacedRow>
		</DialogTitle>
	);
};

export default Title;
