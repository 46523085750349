import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { useDispatch } from 'react-redux';

import { DialogTitle } from 'components';
import {
	InspectionActions,
	ModalFlowActions,
	SnackbarActions,
} from 'store/actions';
import { Dispatch } from 'types';

import { LoadingButton } from '@mui/lab';
import FormTextField from 'components/form-text-field';
import EmailInput from 'components/form-text-field/email-input';
import { useRequestSignaturesModalData } from 'hooks/modals';
import { useEffect, useState } from 'react';
import { Analytics } from 'utils';
import { useIsMobileBreakpoint } from 'utils/hooks';

interface Props {
	open: boolean;
}

export default ({ open }: Props) => {
	const dispatch: Dispatch = useDispatch();
	const isMobile = useIsMobileBreakpoint();

	const modalData = useRequestSignaturesModalData();
	const inspection = modalData?.metadata.inspection;

	const [emails, setEmails] = useState<string[]>([]);
	const [note, setNote] = useState('');
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setEmails([]);
		setNote('');
	}, [open]);

	const handleClose = () => {
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	const handleRequestSignatures = async () => {
		if (!inspection) return;

		setLoading(true);

		dispatch(InspectionActions.requestSignature(emails, inspection, note))
			.then(() => {
				dispatch(SnackbarActions.showSuccess('Signatures Requested'));

				Analytics.trackEvent('requested signature', {
					address: inspection.property.address,
					inspection_id: inspection.id,
					inspection_template: inspection.inspection_template.name,
					recipients: emails.join(', '),
				}),
					handleClose();
			})
			.catch(() => {})
			.finally(() => setLoading(false));
	};

	return (
		<Dialog open={open} onClose={handleClose} fullScreen={isMobile}>
			<DialogTitle
				title="Request Signatures"
				icon={solid('signature')}
				onClose={handleClose}
			/>

			<DialogContent>
				<EmailInput
					required
					title="Requested Email Addresses"
					caption="Each email address added below will be sent an email requesting their signature on the inspection report. Add multiple emails by separating each email address with a space or comma. Click the X to remove an email from the list."
					placeholder="Add Emails"
					emails={emails}
					onChange={setEmails}
				/>
				<FormTextField
					title="Note to recipients"
					caption="This will be sent in the email along with the request."
					placeholder="Additional Comments"
					value={note}
					onChange={(e) => setNote(e.target.value)}
				/>
			</DialogContent>
			<DialogActions>
				<Button variant="text" color="secondary" onClick={handleClose}>
					Cancel
				</Button>
				<LoadingButton
					loading={loading}
					onClick={handleRequestSignatures}
					disabled={!emails.length}>
					Request Signatures
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};
