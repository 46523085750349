import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import { APIBuilding, APIProperty } from '@rentcheck/types';
import { Column, Row, SpacedRow, Spacer } from 'components';
import IconButton from 'components/icon-button';
import { useCreateInspectionModalData } from 'hooks/modals';
import _ from 'lodash';
import styled from 'styled-components';
import {
	formattedAddress,
	numberAndPluralizeWord,
	propertyTypePlurals,
} from 'utils/helpers';

interface Props {
	properties: APIProperty[];
	setValue: (value: APIProperty[]) => void;
}

export default ({ properties, setValue }: Props) => {
	const modalData = useCreateInspectionModalData();
	const { property_type: propertyType } = modalData?.metadata ?? {};

	const handleRemove = () => {
		setValue([]);
	};

	if (properties.length === 0) {
		return <Spacer height={4.5} />;
	}

	if (properties.length > 1) {
		return (
			<Container>
				<Row>
					<FontAwesomeIcon icon={solid('rectangle-history-circle-plus')} />
					<Spacer width={4} />
					<Column>
						<Typography>Bulk {propertyType} selection</Typography>
						<Typography>
							{numberAndPluralizeWord(
								properties.length,
								_.capitalize(propertyType),
								propertyTypePlurals[propertyType]
							)}
						</Typography>
					</Column>
				</Row>
				<IconButton icon={solid('times')} onClick={handleRemove} />
			</Container>
		);
	}

	const property = properties[0];

	return (
		<Container>
			<Row>
				<FontAwesomeIcon icon={solid('map-marker-alt')} />
				<Spacer width={4} />
				<Column>
					{!!(property as APIBuilding).name && (
						<>
							<Typography>{(property as APIBuilding).name}</Typography>
							<Typography variant="body2" color="#00000099">
								{formattedAddress(property)}
							</Typography>
						</>
					)}

					{!(property as APIBuilding).name && (
						<>
							<Typography>{property.address}</Typography>
							<Typography variant="body2" color="#00000099">
								{property.city}, {property.zip_code}
							</Typography>
						</>
					)}
				</Column>
			</Row>
			<IconButton icon={solid('times')} onClick={handleRemove} />
		</Container>
	);
};

const Container = styled(SpacedRow)`
	padding: 16px;
`;
