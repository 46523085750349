import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { useState } from 'react';

import { BaseIntegration } from '@rentcheck/types';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Column, DialogTitle } from 'components';

import { useIsMobileBreakpoint } from 'utils/hooks';
import {
	IngestionFilter,
	IngestionFilterOption,
	MappingSection,
	PropertyTypeFilter,
} from '../types';
import Setting from './setting';
import Sync from './sync';
import TeamMapping from './team-mapping';

interface SyncSection extends MappingSection {
	value: boolean;
}

interface Props {
	open: boolean;
	vendor: string;
	handleClose: () => void;
	teamMappings?: BaseIntegration['team_mappings'];
	propertyTypes: string[];
	filterOptions: IngestionFilterOption[];
	syncSections: SyncSection[];
	handleConfirm: (reviewShown: boolean) => Promise<void>;
	ingestionFilter?: IngestionFilter;
	propertyTypeFilter?: PropertyTypeFilter;
}

export default ({
	open,
	vendor,
	handleClose,
	teamMappings,
	propertyTypes,
	filterOptions,
	syncSections,
	handleConfirm,
	ingestionFilter,
	propertyTypeFilter,
}: Props) => {
	const isMobile = useIsMobileBreakpoint('sm');

	const [loading, setLoading] = useState(false);

	const sync = () => {
		setLoading(true);
		handleConfirm(true).finally(() => setLoading(false));
	};

	const joinValuesOrNone = (values: string[]) => {
		if (values.length === 0) {
			return 'None selected';
		}

		return values.join(', ');
	};

	return (
		<Dialog open={open} onClose={handleClose} fullScreen={isMobile}>
			<DialogTitle
				title="Review Sync Details"
				icon={solid('sync')}
				onClose={handleClose}
			/>

			<DialogContent>
				<Column>
					{!!ingestionFilter && (
						<Setting
							label={`${ingestionFilter.name} Selection`}
							value={joinValuesOrNone(filterOptions.map((g) => g.name))}
						/>
					)}
					{!!propertyTypeFilter && (
						<Setting
							label={`${propertyTypeFilter.name} Selection`}
							value={joinValuesOrNone(propertyTypes)}
						/>
					)}
					<Setting
						label="Team Assignment"
						value=""
						children={<TeamMapping teamMappings={teamMappings} />}
					/>

					{syncSections.map((section, idx) => (
						<Sync
							vendor={vendor}
							label={section.title}
							toggle={section.value}
							operation={section.mode}
							hideDivider={idx === syncSections.length - 1}
						/>
					))}
				</Column>
			</DialogContent>

			<DialogActions>
				<Button variant="outlined" color="secondary" onClick={handleClose}>
					Back
				</Button>

				<LoadingButton variant="contained" onClick={sync} loading={loading}>
					Confirm
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};
