import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { ModalFlowActions } from 'store/actions';

interface Props {
	color?: string;
}

const CloseButton = ({ color = 'black' }: Props) => {
	const dispatch = useDispatch();

	const handleClick = () => {
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	return (
		<Button
			variant="text"
			onClick={handleClick}
			sx={{ width: 64, height: 64, marginRight: -2 }}>
			<FontAwesomeIcon icon={light('times')} color={color} size="lg" />
		</Button>
	);
};

export default CloseButton;
