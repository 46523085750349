import { FeatureRating, InspectionStatusDisplay } from '@rentcheck/types';
import MultiSelectFilterChip from 'components/table/filter-button-v2/components/filter-chip/multi-select';
import { useDispatch } from 'react-redux';
import { actionType, icon, useFilter } from './common';

export default () => {
	const dispatch = useDispatch();
	const filter = useFilter();

	const handleSetValue = (value: FeatureRating[] | undefined) => {
		const sanitizedValue = value?.length ? value : undefined;

		dispatch({
			type: actionType,
			value: sanitizedValue,
		});

		if (!sanitizedValue) {
			return;
		}

		const validStatuses: InspectionStatusDisplay[] = [
			'Awaiting Review',
			'Completed',
			'Approved',
			'Revision Requested',
			'In Review',
			'Revision Review',
		];

		dispatch({
			type: 'SET_INSPECTION_STATUS_FILTER',
			value: {
				condition: 'any_of',
				value: validStatuses,
			},
		});
	};

	if (!filter) {
		return null;
	}

	const options: FeatureRating[] = ['Poor', 'Fair', 'Good'];

	return (
		<MultiSelectFilterChip
			title="Feature Rating"
			icon={icon}
			value={filter}
			setValue={handleSetValue}
			options={options}
		/>
	);
};
