import { DashboardData } from '@rentcheck/api-frontend';
import { Row } from 'components';
import { useTypedSelector } from 'store/reducers/rootReducer';

import CompletionRate from './completion-rate';
import ResidentRating from './resident-rating';
import RevisionRate from './revision-rate';

const LockedState = () => {
	const data = useTypedSelector((state) => state.dashboard.data);

	if (!data || data.stats.overall_total_inspections >= 10) {
		return null;
	}

	const lockedData: DashboardData = {
		stats: {
			all_star_status: 'Rookie',
			total_inspections: 1100,
			overall_total_inspections: 1100,
			total_inspections_on_time: 913,
			total_rejected_inspections: 275,
			total_inspection_ratings: 1100,
			average_inspection_rating: 4.25,
			total_inspections_completed: 913,
			total_requested_inspections: 1100,
			total_properties: 0,
		},
		todo_list: {
			awaiting_signature_inspections_count: 0,
			undelivered_emails_count: 0,
			inspections_awaiting_review_count: 0,
			almost_due_inspections_count: 0,
			overdue_inspections_count: 0,
			revision_requested_inspections_count: 0,
			properties_at_risk_count: 0,
			unassigned_inspections_count: 0,
		},
		last_updated_date: '',
	};

	return (
		<Row style={{ flex: 3.5, marginRight: 16, opacity: 0.3 }}>
			<CompletionRate data={lockedData} />
			<RevisionRate data={lockedData} />
			<ResidentRating data={lockedData} />
		</Row>
	);
};

export default LockedState;
