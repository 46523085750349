import { GetAllUsersV2Params } from '@rentcheck/types';
import { Column, Row } from 'components';
import { FilterType } from 'components/lists/filters';
import FilterButton from 'components/table/filter-button';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { InspectionTemplatesActions, TeammatesActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import styled from 'styled-components';
import * as theme from 'theme';

import _ from 'lodash';
import {
	FilterPermissionGroupDetails,
	FilterPermissionGroupTitle,
} from './filter-permission-group';
import { FilterTeamDetails, FilterTeamTitle } from './filter-team';
import {
	FilterUserStatusDetails,
	FilterUserStatusTitle,
} from './filter-user-status';

export default () => {
	const dispatch = useDispatch();

	const profile = useTypedSelector((state) => state.activeProfile);
	const filters = useTypedSelector((state) => state.teammates.usersFilters);

	const [selectedFilter, setSelectedFilter] = useState<FilterType | undefined>(
		'Team'
	);

	useEffect(() => {
		if (!profile) return;

		dispatch(InspectionTemplatesActions.getAll(true));
	}, [profile]);

	const handleResetFilters = () => {
		dispatch(
			TeammatesActions.Users.setFilters({
				teamIds: [],
				permissionGroupIds: [],
				status: undefined,
			})
		);
	};

	const appliedFiltersCount = (filters: GetAllUsersV2Params['filters']) => {
		return Object.values(_.omit(filters, 'search')).filter((v) => v?.length)
			.length;
	};

	return (
		<FilterButton
			resetFilters={handleResetFilters}
			badgeCount={appliedFiltersCount(filters)}>
			<Row style={{ alignItems: 'flex-start' }}>
				<FilterTitles>
					<FilterTeamTitle
						setFilterDetails={setSelectedFilter}
						selectedFilter={selectedFilter}
					/>
					<FilterPermissionGroupTitle
						setFilterDetails={setSelectedFilter}
						selectedFilter={selectedFilter}
					/>
					<FilterUserStatusTitle
						setFilterDetails={setSelectedFilter}
						selectedFilter={selectedFilter}
					/>
				</FilterTitles>

				<FilterDetails>
					<FilterTeamDetails selectedFilter={selectedFilter} />
					<FilterPermissionGroupDetails selectedFilter={selectedFilter} />
					<FilterUserStatusDetails selectedFilter={selectedFilter} />
				</FilterDetails>
			</Row>
		</FilterButton>
	);
};

const FilterTitles = styled(Column)`
	flex: 1;
	padding: ${theme.spacingPx(2)} 0;
	border-right: 1px solid ${theme.colors.lightGray};
	width: 185px;
`;

const FilterDetails = styled(Column)`
	flex: 1;
	margin-left: -1px;
	border-left: 1px solid ${theme.colors.lightGray};
	padding: ${theme.spacingPx(2)} 0;
	max-height: 300px;
	width: 185px;
	overflow-y: scroll;
`;
