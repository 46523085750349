import { WorkOrder } from '@rentcheck/api-frontend';

import IntegrationDetails from './common';

interface Props {
	workOrder: WorkOrder;
}

const Latchel = ({ workOrder }: Props) => {
	if (!workOrder.latchel) {
		return null;
	}

	const extractDescription = (description?: string) => {
		if (!description) return 'None';
		return description.split('\\n')[0];
	};

	const details = [
		{
			title: 'Default Dispatch Settings:',
			value: workOrder.latchel?.vendor_name || 'On',
		},
		{
			title: 'Work Order Description:',
			value: extractDescription(workOrder.latchel?.description),
		},
		{
			title: 'Specific Instructions for the Vendor:',
			value: workOrder.latchel?.vendor_instructions ?? 'None',
		},
	];

	return <IntegrationDetails details={details} />;
};

export default Latchel;
