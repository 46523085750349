import { RefObject, useRef, useState } from 'react';

import { Column, Row } from 'components';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Collapse } from '@mui/material';
import ToggleButton, { ToggleButtonRef } from 'components/toggle-button';
import { FeatureWithMetadata } from 'store/reducers/features';
import { useIsMobileBreakpoint } from 'utils/hooks';
import Sidebar from '../../feature/sidebar';
import { FeatureImageViewerRef } from '../image';

interface Props {
	feature: FeatureWithMetadata;
	mediaUri: string;
	imageViewerRef?: RefObject<FeatureImageViewerRef>;
}

export default ({ feature, mediaUri, imageViewerRef }: Props) => {
	const isMobileBreakpoint = useIsMobileBreakpoint(601);

	const ref = useRef<ToggleButtonRef>(null);

	const [showInfo, setShowInfo] = useState(false);

	if (isMobileBreakpoint) {
		return (
			<Row style={{ marginBottom: 12, marginRight: -40 }}>
				<ToggleButton
					ref={ref}
					selectedWhenMenuOpen
					icon={regular('info-circle')}
					tooltip={showInfo ? 'Hide Info' : 'View Info'}
					onClick={() => setShowInfo(!showInfo)}
					closeOnClickInside={false}>
					<Sidebar
						maxWidth={300}
						feature={feature}
						setHoveredFlag={() => {}}
						setHoveredQuestion={() => {}}
						mode="full-screen-image"
						mediaUri={mediaUri}
						imageViewerRef={imageViewerRef}
					/>
				</ToggleButton>
			</Row>
		);
	}

	if (!isMobileBreakpoint) {
		return (
			<Column
				style={{
					marginRight: -40,
					height: '100%',
					position: 'relative',
				}}>
				<Row style={{ marginBottom: 12 }}>
					<ToggleButton
						selected={showInfo}
						icon={regular('info-circle')}
						tooltip={showInfo ? 'Hide Info' : 'View Info'}
						onClick={() => setShowInfo(!showInfo)}
					/>
				</Row>
				<Collapse
					in={showInfo}
					orientation="horizontal"
					sx={{ height: '100%', overflow: 'hidden' }}>
					<Sidebar
						style={{ marginRight: 56 }}
						maxWidth={300}
						feature={feature}
						setHoveredFlag={() => {}}
						setHoveredQuestion={() => {}}
						mode="full-screen-image"
						mediaUri={mediaUri}
						imageViewerRef={imageViewerRef}
					/>
				</Collapse>
			</Column>
		);
	}

	return null;
};
