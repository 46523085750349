import { Paper } from '@mui/material';
import { InspectionReportTemplate } from '@rentcheck/types';
import { Column } from 'components';

import OptionGroup, { ReportOption } from '../common/option-group';
import SectionHeader from '../common/section-header';

interface Props {
	isEditing: boolean;
	payload: InspectionReportTemplate;
	setPayload: (value: InspectionReportTemplate) => void;
}

export default ({ isEditing, payload, setPayload }: Props) => {
	const payloadKey = 'summary_section';

	const options: ReportOption[] = [
		{
			label: 'Inspection Label',
			key: 'inspection_label',
		},
		{
			label: 'Resident Name & Contact Details',
			key: 'resident_info',
		},
		{
			label: 'Summary Page',
			key: 'summary_page',
		},
		{
			label: 'Table of Contents',
			key: 'table_of_contents',
		},
		{
			label: 'Summary Note',
			key: 'summary_note',
		},
	];

	return (
		<Paper
			elevation={0}
			sx={{ border: '1px solid #0000001F', padding: '8px 0' }}>
			<SectionHeader
				title="Inspection Summary"
				isEditing={isEditing}
				payload={payload}
				setPayload={setPayload}
				payloadKey={payloadKey}
			/>
			<Column style={{ marginLeft: 24 }}>
				{options.map((option) => (
					<OptionGroup
						isEditing={isEditing}
						payload={payload}
						setPayload={setPayload}
						payloadKey={payloadKey}
						option={option}
					/>
				))}
			</Column>
		</Paper>
	);
};
