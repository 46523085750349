import { useTypedSelector } from 'store/reducers/rootReducer';

import SnapshotRow from './snapshot-row';

const AwaitingSignature = () => {
	const dashboardData = useTypedSelector((state) => state.dashboard.data);

	return (
		<SnapshotRow
			label="Awaiting Signature"
			link="/dashboard/awaiting-signature"
			count={dashboardData?.todo_list.awaiting_signature_inspections_count}
		/>
	);
};

export default AwaitingSignature;
