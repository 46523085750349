import { createTransform } from 'redux-persist';
import { State as InspectionsState } from '../reducers/inspections';
import { initialState as inspectionsInitialState } from '../reducers/inspections/inspections';

export const inspectionsTransform = createTransform(
	(inboundState) => {
		return inboundState as InspectionsState;
	},
	(outboundState: InspectionsState) => {
		/**
		 * At this point we only want to persist the filters, not
		 * the inspections themselves.
		 */
		return {
			inspections: inspectionsInitialState,
			inspection: null,
			filters: outboundState.filters,
		};
	},
	{ whitelist: ['inspections'] }
);
