import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import {
	FilterChip,
	FilterChipContent,
} from 'components/table/filter-button-v2';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Calendar, DayRange } from 'react-modern-calendar-datepicker';
import { DateRange } from 'store/reducers/inspections/filters';
import { colors } from 'theme';
import { formatDate } from 'utils/helpers';
import { FilterChipRef } from '..';

type RadioValueT = 'this-week' | 'this-month' | 'custom-date-range';
type DateRangePreset = { name: string; label: string; value: DateRange };

export const dateRangePresets: Record<string, DateRangePreset> = {
	thisWeek: {
		name: 'This week',
		label: 'is this week',
		value: {
			start: moment().startOf('week').valueOf(),
			end: moment().endOf('week').valueOf(),
		},
	},
	thisMonth: {
		name: 'This month',
		label: 'is this month',
		value: {
			start: moment().startOf('month').valueOf(),
			end: moment().endOf('month').valueOf(),
		},
	},
};

const isDateRangeEqual = (a: DateRange, b: DateRange) => {
	return a.start === b.start && a.end === b.end;
};

const convertDatesToDayRange = (a: Date | null, b: Date | null): DayRange => {
	const from = a
		? {
				year: a.getFullYear(),
				month: a.getMonth() + 1,
				day: a.getDate(),
			}
		: null;

	const to = b
		? {
				year: b.getFullYear(),
				month: b.getMonth() + 1,
				day: b.getDate(),
			}
		: null;

	return { from, to };
};

interface Props {
	title: string;
	icon: IconDefinition;
	value: DateRange | undefined;
	setValue: (value: DateRange | undefined) => void;
	presets: DateRangePreset[];
}

const DateRangeFilterChip = ({
	title,
	icon,
	value,
	setValue,
	presets,
}: Props) => {
	const ref = useRef<FilterChipRef>(null);

	const [radioValue, setRadioValue] = useState<string>();
	const [customDateStart, setCustomDateStart] = useState<Date | null>(null);
	const [customDateEnd, setCustomDateEnd] = useState<Date | null>(null);

	useEffect(() => {
		initializeState(value);
	}, [value]);

	const initializeState = (value: DateRange | undefined) => {
		if (!value) {
			setRadioValue(undefined);
			setCustomDateStart(null);
			setCustomDateEnd(null);

			return;
		}

		const preset = presets.find((preset) =>
			isDateRangeEqual(value, preset.value)
		);

		if (preset) {
			setRadioValue(preset.name);
			setCustomDateStart(null);
			setCustomDateEnd(null);

			return;
		}

		if (value.start || value.end) {
			setRadioValue('custom-date-range');
			setCustomDateStart(value.start ? new Date(value.start) : null);
			setCustomDateEnd(value.end ? new Date(value.end) : null);
		}
	};

	const handleDeleted = () => {
		setValue(undefined);
	};

	const handleDismissed = () => {
		initializeState(value);

		if (!value?.start && !value?.end) {
			handleDeleted();
		}
	};

	const label = useMemo(() => {
		if (!value?.start && !value?.end) {
			return undefined;
		}

		const preset = presets.find((preset) =>
			isDateRangeEqual(value, preset.value)
		);

		if (preset) {
			return preset.label;
		}

		const start = formatDate(value.start);
		const end = formatDate(value.end);

		return `is ${[start, end].filter(Boolean).join(' - ')}`;
	}, [value?.start, value?.end]);

	const handleRadioChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
		const radioValue = event.target.value as RadioValueT;
		setRadioValue(radioValue);

		if (radioValue !== 'custom-date-range') {
			setCustomDateStart(null);
			setCustomDateEnd(null);
		}
	};

	const handleApply = () => {
		ref.current?.close();

		if (radioValue === 'custom-date-range') {
			return setValue({
				start: customDateStart?.valueOf(),
				end: customDateEnd?.valueOf(),
			});
		}

		const preset = presets.find((preset) => preset.name === radioValue);

		if (preset) {
			return setValue(preset.value);
		}
	};

	const handleCalendarChanged = (dayRange: DayRange) => {
		if (dayRange.from) {
			setCustomDateStart(
				new Date(dayRange.from.year, dayRange.from.month - 1, dayRange.from.day)
			);
		} else {
			setCustomDateStart(null);
		}

		if (dayRange.to) {
			setCustomDateEnd(
				new Date(dayRange.to.year, dayRange.to.month - 1, dayRange.to.day)
			);
		} else {
			setCustomDateEnd(null);
		}
	};

	if (!value) {
		return null;
	}

	const customDateValue = [customDateStart, customDateEnd]
		.filter(Boolean)
		.map((d) => formatDate(d))
		.join(' - ');

	const applyDisabled =
		!radioValue ||
		(radioValue === 'custom-date-range' &&
			(!customDateStart || !customDateEnd));

	return (
		<FilterChip
			ref={ref}
			title={title}
			label={label}
			icon={icon}
			onDelete={handleDeleted}
			onDismiss={handleDismissed}>
			<FilterChipContent
				title={title}
				onApply={handleApply}
				applyDisabled={applyDisabled}>
				<RadioGroup value={radioValue} onChange={handleRadioChanged}>
					{presets.map((preset) => (
						<FormControlLabel
							value={preset.name}
							label={preset.name}
							control={<Radio size="small" />}
							sx={{ mb: 0.25, mt: 0.25 }}
						/>
					))}
					<FormControlLabel
						value="custom-date-range"
						control={<Radio size="small" />}
						label="Custom date range"
						sx={{ mb: 0.25, mt: 0.25 }}
					/>
					{radioValue === 'custom-date-range' && (
						<>
							<Typography
								variant="body2"
								ml={4}
								color={customDateValue ? undefined : colors.gray}>
								{customDateValue || 'Select a date range'}
							</Typography>
							<Calendar
								colorPrimary={colors.primary}
								colorPrimaryLight={colors.primaryLight}
								value={convertDatesToDayRange(customDateStart, customDateEnd)}
								onChange={handleCalendarChanged}
							/>
						</>
					)}
				</RadioGroup>
			</FilterChipContent>
		</FilterChip>
	);
};

export default DateRangeFilterChip;
