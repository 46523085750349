import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';

import { Row, Spacer } from 'components';
import { useTypedSelector } from 'store/reducers/rootReducer';

import Tooltip from '../../key-metrics/content/components/tooltip';
import SkeletonHeader from './skeleton-header';

const LastUpdated = () => {
	const dashboardData = useTypedSelector((state) => state.dashboard.data);

	const lastUpdatedDate = dashboardData?.last_updated_date;

	if (!lastUpdatedDate) {
		return <SkeletonHeader />;
	}

	const lastUpdatedTime = new Date(lastUpdatedDate).toLocaleString('en-US', {
		timeZone: 'UTC',
		hour: 'numeric',
		minute: 'numeric',
		hour12: true,
	});

	const handleCta = () => {
		window.open(
			'https://help.getrentcheck.com/en/articles/6574817-the-rentcheck-dashboard'
		);
	};

	return (
		<Row>
			<Typography variant="body2" style={{ width: 190 }}>
				Last updated today at {lastUpdatedTime}
			</Typography>

			<Spacer width={2} />

			<Tooltip
				title="What's this?"
				body="You can think of your Daily Snapshot as your to-do list to help ensure inspections are on track and completed on time. Updated daily at a minimum."
				cta="Learn More"
				handleCta={handleCta}>
				<FontAwesomeIcon
					icon={regular('info-circle')}
					color="#8F9FB2"
					size="1x"
				/>
			</Tooltip>
		</Row>
	);
};

export default LastUpdated;
