import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
	Button,
	CardContentProps,
	CardProps,
	Dialog,
	DialogActions,
	DialogContent,
	Divider,
	Card as MuiCard,
	CardContent as MuiCardContent,
	Typography,
} from '@mui/material';

import { UnitsApi } from '@rentcheck/api-frontend';
import { BillingModalOption } from '@rentcheck/types';
import { Column, DialogTitle, Spacer } from 'components';
import { LoadingButton } from 'components/button';
import moment from 'moment';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { handleUpdatePaymentMethod } from 'screens/account/billing/subscribed/common';
import { SnackbarActions, SubscriptionActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/common';
import { borders } from 'theme';
import { Dispatch } from 'types';
import { formatDate } from 'utils/helpers';
import { BillingModalPlanVariant, BillingOptionContext } from '../../context';
import CardRow from './card-row';

interface Props {
	open: boolean;
	selectedPlan: BillingModalOption['plan'];
	selectedPlanVariant: BillingModalPlanVariant;
	onUpgradeSuccess: () => void;
}

export default ({
	open,
	selectedPlan,
	selectedPlanVariant,
	onUpgradeSuccess,
}: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const profile = useTypedSelector((state) => state.activeProfile);
	const subscription = useTypedSelector((state) => state.subscription);

	const setBillingPlanAndVariant = useContext(BillingOptionContext);

	const [activeUnitsLoading, setActiveUnitsLoading] = useState(true);
	const [updatePlanLoading, setUpdatePlanLoading] = useState(false);
	const [activeUnits, setActiveUnits] = useState<number>();

	const planId =
		selectedPlanVariant === 'free-trial'
			? selectedPlan.free_trial.id
			: selectedPlan.id;

	useEffect(() => {
		setActiveUnitsLoading(true);

		UnitsApi.getAll()
			.then((response) => {
				setActiveUnits(response.totalResults);
			})
			.catch(() => {})
			.finally(() => setActiveUnitsLoading(false));
	}, []);

	useEffect(() => {
		/**
		 * Explicitly re-register the chargebee script after the
		 * dialog has opened to ensure the action button is recognized by
		 * it. This is required because the chargebee drop in script is not
		 * smart enough to recognize changes in the DOM.
		 *
		 * https://www.chargebee.com/checkout-portal-docs/dropin-reference.html#registeragain
		 */
		setTimeout(() => {
			//@ts-expect-error
			const chargebee = window.Chargebee;
			chargebee.registerAgain();
		}, 500);
	}, []);

	const handleBack = () => {
		setBillingPlanAndVariant(undefined, undefined);
	};

	const handleUpdatePlan = () => {
		/**
		 * If the user doesn't have an active subscription then we need to
		 * handle the plan update via the chargebee checkout script.
		 */
		if (!subscription) {
			return;
		}

		/**
		 * If the user has an active subscription but no payment method and wants to
		 * upgrade to a full plan we need to present them with the chargebee update
		 * payment method flow. They will later have to come back to this screen,
		 * refresh and checkout again.
		 */
		if (!subscription.payment_method && selectedPlanVariant === 'full-plan') {
			handleUpdatePaymentMethod(dispatch, profile, subscription);
			return;
		}

		/**
		 * If the user has an active subscription then we need to handle the
		 * plan update via the API.
		 */
		setUpdatePlanLoading(true);

		dispatch(SubscriptionActions.update({ plan: { id: planId } }))
			.then(onUpgradeSuccess)
			.catch((e) => dispatch(SnackbarActions.showError(e)))
			.finally(() => {
				setUpdatePlanLoading(false);
			});
	};

	const bottomDisclaimer = useMemo(() => {
		const paymentMethod = subscription?.payment_method;
		const trialEndDate = moment().add(selectedPlan.free_trial.duration, 'days');
		const formattedTrialEndDate = formatDate(trialEndDate);

		if (selectedPlanVariant === 'free-trial' && paymentMethod) {
			return (
				`You won't be charged today. If you don't cancel your trial by ${formattedTrialEndDate}, ` +
				`your new plan will kick in, and you'll be charged accordingly.`
			);
		}

		if (selectedPlanVariant === 'free-trial' && !paymentMethod) {
			return (
				`Your free trial will start immediately. If you don't upgrade to a paid plan ` +
				`by ${formattedTrialEndDate}, you'll lose access to your account. Don't worry, ` +
				`though - your data will be safely stored if you decide to come back.`
			);
		}

		if (selectedPlanVariant === 'full-plan') {
			return (
				`Your next billing statement may include prorated charges that reflects ` +
				`the difference between your old and new plans.`
			);
		}
	}, [subscription, selectedPlanVariant]);

	/**
	 * These are used by the chargebee checkout script to
	 * determine what to show in the checkout modal.
	 *
	 * If the user is on a subscription already we need to handle this
	 * via API so we disable the chargebee handler by setting the type
	 * to an empty string.
	 */
	const chargebeeProps: Record<string, string> = {};
	chargebeeProps['data-cb-type'] = subscription ? '' : 'checkout';
	chargebeeProps['data-cb-plan-id'] = planId;

	return (
		<Dialog open={open}>
			<DialogTitle
				icon={solid('sparkles')}
				title="Confirmation of Plan "
				bottomSubtitle="Please review and confirm the updates to your plan."
				onClose={handleBack}
			/>

			<DialogContent>
				<Card>
					<CardContent>
						<Column style={{ gap: 16 }}>
							<CardRow
								title="New Plan:"
								value={selectedPlan.full_name}
								loading={activeUnitsLoading}
							/>
							<CardRow
								title="Current active units:"
								value={`${activeUnits}`}
								loading={activeUnitsLoading}
							/>
							<Divider sx={{ mt: 1, mb: 1 }} />
							<CardRow
								title="Today's charge:"
								value="$0.00 USD"
								loading={activeUnitsLoading}
							/>
						</Column>
					</CardContent>
				</Card>

				<Spacer height={4} />

				<Card>
					<CardContent>
						<Typography>
							<span style={{ fontWeight: 500 }}>Heads up: </span>
							{bottomDisclaimer}
						</Typography>
					</CardContent>
				</Card>
			</DialogContent>
			<DialogActions>
				<Button variant="text" color="secondary" onClick={handleBack}>
					Back
				</Button>
				<div {...chargebeeProps}>
					<LoadingButton onClick={handleUpdatePlan} loading={updatePlanLoading}>
						Update Plan
					</LoadingButton>
				</div>
			</DialogActions>
		</Dialog>
	);
};

const Card = (props: CardProps) => (
	<MuiCard
		{...props}
		elevation={0}
		sx={{
			minWidth: { xs: undefined, md: 350 },
			border: borders.cardBorder,
		}}
	/>
);

const CardContent = (props: CardContentProps) => (
	<MuiCardContent {...props} sx={{ p: 2, paddingBottom: '16px !important' }} />
);
