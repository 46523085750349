import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Feature } from '@rentcheck/types';

interface Props {
	feature: Feature;
	image: string;
}

export default ({ feature, image }: Props) => {
	const hasLinkedMedia =
		!!feature.image_links?.find(
			(link) => link.image_uri === image && link.type === 'no_answer'
		) ||
		!!feature.video_links?.find(
			(link) => link.video_uri === image && link.type === 'no_answer'
		);

	if (!hasLinkedMedia) return null;

	return (
		<div
			style={{
				height: 32,
				width: 32,
				borderRadius: 32,
				marginLeft: 8,
				backgroundColor: '#FFFFFFBD',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}>
			<FontAwesomeIcon icon={solid('image')} color="#F59E17" />
		</div>
	);
};
