import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

import { colors, spacingPx } from 'theme';
import { Column, Spacer, TopSpacer } from '../..';
import InspectionComparisonDetail from './InspectionComparisonDetail';
import InspectionsComparisonDetailNavbar from './InspectionsComparisonDetailNavbar';

import { Typography } from '@mui/material';
import { InspectionsApi, PropertiesApi } from '@rentcheck/api-frontend';
import { APIProperty, ApiInspectionWithTemplate } from '@rentcheck/types';
import { formatDate, formattedAddress } from 'utils/helpers';

interface Props extends RouteComponentProps {
	match: any;
}

function InspectionsComparison({ match }: Props) {
	const [firstID, secondID] = match.params.query.split('-');

	const [property, setProperty] = useState<APIProperty>();
	const [firstInspection, setFirstInspection] =
		useState<ApiInspectionWithTemplate>();
	const [secondInspection, setSecondInspection] =
		useState<ApiInspectionWithTemplate>();

	useEffect(() => {
		const getInspections = async () => {
			try {
				const firstInspection = await InspectionsApi.getById(firstID);
				const secondInspection = await InspectionsApi.getById(secondID);

				setFirstInspection(firstInspection);
				setSecondInspection(secondInspection);

				const property = await PropertiesApi.getById(
					firstInspection.property.id
				);
				setProperty(property);
			} catch (e) {
				Sentry.captureException(e);
			}
		};

		getInspections();
	}, []);

	const subheaderMessage = () => {
		if (!firstInspection || !secondInspection) {
			return 'Loading...';
		}

		if (!firstInspection.completed_date || !secondInspection.completed_date) {
			return 'One or more inspections are incomplete';
		}

		return `Comparing ${
			firstInspection.inspection_template.name
		} Inspection (${formatDate(firstInspection.completed_date)}) with ${
			secondInspection.inspection_template.name
		} Inspection (${formatDate(secondInspection.completed_date)})`;
	};

	const address = ` ${formattedAddress(firstInspection?.property)}`;

	return (
		<div>
			<TopSpacer />
			<ScreenContainer>
				<Header>
					<Typography variant="h5">Inspection Comparison</Typography>
					<Spacer height={2} />
					<Typography>{subheaderMessage()}</Typography>
					<Typography>{address}</Typography>
				</Header>

				<Content>
					<NavbarContainer>
						<InspectionsComparisonDetailNavbar
							firstID={firstID}
							secondID={secondID}
						/>
					</NavbarContainer>

					<ContentContainer>
						<InspectionComparisonDetail
							firstID={firstID}
							secondID={secondID}
							firstInspection={firstInspection}
							secondInspection={secondInspection}
							property={property}
						/>
					</ContentContainer>
				</Content>
			</ScreenContainer>
		</div>
	);
}

const Header = styled(Column)`
	border-bottom: 1px solid #d9d7d7;
	padding: ${spacingPx(4)} ${spacingPx(10)};
`;

const Content = styled.div`
	display: flex;
	flex-direction: row;
	height: calc(100vh - 240px);
`;

const NavbarContainer = styled.div`
	display: flex;
	flex-basis: row;
	overflow-y: scroll;
	overflow-x: hidden;
	width: 270px;
`;

const ContentContainer = styled(Column)`
	width: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
	padding: ${spacingPx(4)} ${spacingPx(10)};
`;

const ScreenContainer = styled.div`
	width: 80vw;
	background-color: ${colors.contentBackground};
	border: 1px solid ${colors.contentBorder};
	border-radius: 3px;
	-webkit-box-shadow: 0 0 1px ${colors.shadow};
	box-shadow: 0 0 1px ${colors.shadow};
	position: relative;
	margin-bottom: ${spacingPx(10)};
`;

export default InspectionsComparison;
