import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonBase, Typography } from '@mui/material';
import { Column, Spacer } from 'components';
import { ReactNode, ReactNodeArray } from 'react';

interface Props {
	caption?: string;
	title: string;
	icon: IconDefinition;
	disabled?: boolean;
	halfWidth?: boolean;
	hasContent?: boolean;
	onClick?: () => void;

	children?: ReactNode | ReactNodeArray;
}

export default ({
	caption,
	title,
	icon,
	halfWidth,
	disabled,
	onClick,
	hasContent,
	children,
}: Props) => {
	return (
		<>
			<ButtonBase
				id={`row-button-${title}`}
				disabled={disabled}
				onClick={onClick}
				style={{
					marginTop: 14,
					backgroundColor: '#21212114',
					borderRadius: hasContent ? '4px 4px 0 0 ' : '4px',
					borderBottom: hasContent ? '1px solid #00000099' : 'none',
					minHeight: '52px',
					padding: '12px 16px',
					cursor: 'pointer',
					width: halfWidth ? '50%' : '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'flex-start',
					opacity: disabled ? 0.4 : 1,
				}}>
				<FontAwesomeIcon icon={icon} />
				<Spacer width={4} />
				<Column
					style={{ alignItems: 'flex-start', justifyContent: 'space-between' }}>
					{!!caption && (
						<Typography variant="caption" color="#00000099" textAlign="left">
							{caption}
						</Typography>
					)}
					<Typography
						variant={caption ? 'body1' : 'subtitle1'}
						textAlign="left">
						{title}
					</Typography>
				</Column>
			</ButtonBase>
			{children}
			{!children && <Spacer height={4.5} />}
		</>
	);
};
