import { Typography } from '@mui/material';

interface Props {
	value?: string;
}
export default ({ value }: Props) => {
	if (!value) {
		return null;
	}

	return (
		<Typography variant="body2" color="#4D4D4D" mt={1}>
			Note Added: {value}
		</Typography>
	);
};
