import { Dialog, DialogContent, Typography } from '@mui/material';

import { CenteredColumn, CenteredRow, LottieWrapper, Spacer } from 'components';

interface Props {
	open: boolean;
}

export default ({ open }: Props) => {
	return (
		<Dialog open={open}>
			<CenteredRow style={{ marginTop: 40, marginBottom: -12 }}>
				<LottieWrapper variant="dog-computing" width={160} height={150} />
			</CenteredRow>
			<DialogContent>
				<CenteredColumn>
					<Typography variant="h6" align="center">
						Paws and relax! We’re almost there...
					</Typography>
					<Typography align="center" color="#4d4d4d" mt={2}>
						Your RentCheck account is being set up as we speak.
						<br />
						<br />
						Hang tight while we unlock all the powerful features designed to
						streamline inspections and keep transparency at the heart of
						everything you do.
					</Typography>
				</CenteredColumn>
				<Spacer height={10} />
			</DialogContent>
		</Dialog>
	);
};
