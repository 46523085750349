import {
	Dialog,
	DialogContent,
	DialogTitle,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import styled from 'styled-components';

import { authentication } from 'assets';
import { CenteredColumn, CenteredRow, Spacer } from 'components';
import rentCheckLogo from 'images/RentCheck-Logo-Blue-lg.png';
import { mediaSize, spacingPx } from 'theme';

interface Props {
	children: React.ReactNode;
}

const Container = ({ children }: Props) => {
	return (
		<CenteredColumn
			style={{
				backgroundColor: '#E6E7F3',
				width: '100%',
				height: '100vh',
				justifyContent: 'center',
			}}>
			<Background />

			<StyledDialog open hideBackdrop maxWidth="xs">
				<DialogTitle
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					<img
						src={rentCheckLogo}
						alt="rentcheck"
						style={{ height: spacingPx(12) }}
					/>
				</DialogTitle>
				<Spacer height={4} />
				<DialogContent>{children}</DialogContent>
				<Spacer height={4} />
			</StyledDialog>
		</CenteredColumn>
	);
};

const Background = () => {
	const theme = useTheme();
	const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

	if (smallScreen) {
		return (
			<CenteredRow style={{ alignSelf: 'center' }}>
				<img
					src={authentication.bgMobile}
					style={{ position: 'absolute', bottom: 40, maxWidth: 400 }}
				/>
			</CenteredRow>
		);
	}

	return (
		<CenteredRow style={{ alignSelf: 'center' }}>
			<img src={authentication.bgLeft} style={{ height: spacingPx(80) }} />
			<Spacer width={55} />
			<img src={authentication.bgRight} style={{ height: spacingPx(80) }} />
		</CenteredRow>
	);
};

const StyledDialog = styled(Dialog)`
	@media ${mediaSize.mobile} {
		.MuiDialog-container {
			align-items: flex-start;
		}
	}
`;

export default Container;
