import { SpacedRow, Spacer } from 'components';

import { useSortedFilteredTenants } from 'hooks/residents';

import QuickFilters from './quick-filters';
import QuickFiltersSkeleton from './quick-filters/skeleton';
import SearchFilter from './search';
import SearchSkeleton from './search/skeleton';
import Table from './table';
import TableSkeleton from './table/skeleton';

const List = () => {
	const { loading, initialLoading, tenants } = useSortedFilteredTenants();

	if (initialLoading) {
		return (
			<>
				<SpacedRow style={{ alignItems: 'flex-end' }}>
					<QuickFiltersSkeleton />
					<SearchSkeleton />
				</SpacedRow>
				<Spacer height={6} />
				<TableSkeleton />
			</>
		);
	}

	return (
		<>
			<SpacedRow style={{ alignItems: 'flex-end' }}>
				<QuickFilters />
				<SearchFilter />
			</SpacedRow>

			<Spacer height={6} />
			<Table loading={loading} data={tenants.data} />
		</>
	);
};

export default List;
