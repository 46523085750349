import { UsersApi } from '@rentcheck/api-frontend';
import { ApiUserDigest, GetAllUsersV2Params } from '@rentcheck/types';
import _ from 'lodash';
import useApiModel from './common/api-hook';

const initialFilters: GetAllUsersV2Params['filters'] = {
	search: '',
	status: undefined,
	team_ids: '',
	permission_group_ids: '',
};

const defaultSort: GetAllUsersV2Params['options']['sort'] = {
	sort_by: 'user_name',
	sort_type: 'ASC',
};

const useUsers = (pageSize: number = 20) => {
	const hookData = useApiModel(UsersApi, initialFilters, defaultSort, pageSize);

	return {
		users: hookData.data as ApiUserDigest[],
		..._.omit(hookData, 'data'),
	};
};

export default useUsers;
