import { ApiInspectionTemplateDigest, AssignMethod } from '@rentcheck/types';

import AssignMethodRow from './assign-method-row';

interface Props {
	template: ApiInspectionTemplateDigest;
	onChange: (value: AssignMethod) => void;
	noTeams: boolean;
}

const AssignMethodList = ({ template, onChange, noTeams }: Props) => {
	return (
		<>
			{template.property_type === 'unit' && (
				<AssignMethodRow variant="residents" onClick={onChange} />
			)}

			<AssignMethodRow
				variant="teammates"
				onClick={onChange}
				noTeams={noTeams}
			/>

			<AssignMethodRow
				variant="self-perform"
				onClick={onChange}
				noTeams={noTeams}
			/>

			<AssignMethodRow variant="myself" onClick={onChange} />
			<AssignMethodRow variant="emails" onClick={onChange} />
		</>
	);
};

export default AssignMethodList;
