import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent } from '@mui/material';
import { WorkOrdersApi } from '@rentcheck/api-frontend';
import { useCreateWorkOrderModalData } from 'hooks/modals';
import { getCategoryAndSubcategoryFromSkill } from 'hooks/skills';
import _ from 'lodash';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ModalFlowActions, SnackbarActions } from 'store/actions';
import { Dispatch } from 'types';

import AccessInstructions from './access-instructions';
import Occupancy from './occupancy';
import OkToEnter from './ok-to-enter';
import Title from './title';

export type OccupancyT = 'Occupied' | 'Vacant';
export type OkToEnterT = 'Yes' | 'No';

const Content = () => {
	const dispatch: Dispatch = useDispatch();

	const modalData = useCreateWorkOrderModalData('Yardi');
	const { flags } = modalData?.metadata ?? {};

	const [title, setTitle] = useState('');
	const [occupancy, setOccupancy] = useState<OccupancyT>('Occupied');
	const [okToEnter, setOkToEnter] = useState<OkToEnterT>('No');
	const [accessInstructions, setAccessInstructions] = useState('None Provided');

	const [loading, setLoading] = useState(false);

	const handleClose = () => {
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	const handleSuccess = () => {
		dispatch(ModalFlowActions.closeCurrentModal());
		dispatch(SnackbarActions.showSuccess('Work Order Created!'));
	};

	const handleCreate = () => {
		const firstFlag = _.first(flags);

		if (!firstFlag) {
			return;
		}

		const { category, subcategory } = getCategoryAndSubcategoryFromSkill(
			firstFlag.skill
		);

		setLoading(true);

		WorkOrdersApi.create({
			type: 'Yardi',
			name: title,
			okToEnter,
			occupancy,
			accessInstructions,
			flags,
			description: title,
			category,
			subcategory,
		})
			.then(handleSuccess)
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	const disabled = !title.length;

	return (
		<>
			<DialogContent>
				<Title value={title} setValue={setTitle} />
				<Occupancy value={occupancy} setValue={setOccupancy} />
				<OkToEnter
					value={okToEnter}
					setValue={setOkToEnter}
					occupancy={occupancy}
				/>
				<AccessInstructions
					value={accessInstructions}
					setValue={setAccessInstructions}
				/>
			</DialogContent>
			<DialogActions>
				<Button color="secondary" variant="outlined" onClick={handleClose}>
					Back
				</Button>
				<LoadingButton
					disabled={disabled}
					variant="contained"
					loading={loading}
					onClick={handleCreate}>
					Create Work Order
				</LoadingButton>
			</DialogActions>
		</>
	);
};

export default Content;
