import { Typography } from '@mui/material';
import styled from 'styled-components';

import { emptyStates } from 'assets';
import { CenteredColumn, Spacer } from 'components';
import { spacingPx } from 'theme';

interface Props {
	propertyType: 'Units' | 'Buildings' | 'Communities';
}

const EmptyState = ({ propertyType }: Props) => {
	const renderIllustration = () => {
		switch (propertyType) {
			case 'Units':
				return <EmptyStateImage src={emptyStates.units} alt="empty-units" />;
			case 'Buildings':
				return (
					<EmptyStateImage src={emptyStates.buildings} alt="empty-buildings" />
				);
			case 'Communities':
				return (
					<EmptyStateImage
						src={emptyStates.communities}
						alt="empty-communities"
					/>
				);
		}
	};

	return (
		<Container>
			{renderIllustration()}

			<Typography variant="h4" align="center">
				No {propertyType} Yet
			</Typography>
			<Spacer height={4} />
			<Typography sx={{ maxWidth: 600 }}>
				Add your first property and you'll be on your way to setting up
				inspections in no time!
			</Typography>
		</Container>
	);
};

const EmptyStateImage = styled.img`
	height: 35vh;
	resize-mode: contain;
`;

const Container = styled(CenteredColumn)`
	padding: 0 ${spacingPx(40)};
`;

export default EmptyState;
