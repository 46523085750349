import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MembershipRole } from '@rentcheck/types';
import { PopoverMenu } from 'components';
import { useTypedSelector } from 'store/reducers/rootReducer';
import styled from 'styled-components';
import { spacingPx } from 'theme';

import { LoadingButton } from '@mui/lab';
import {
	Button,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { ApiTeamDetails, TeamUserInfo } from '@rentcheck/types';
import _ from 'lodash';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarActions, TeamActions } from 'store/actions';
import { Dispatch } from 'types';
import RemoveTeammateButton from './remove-teammate-button';

interface Props {
	team: ApiTeamDetails;
}

interface TeamUserRowProps {
	team: ApiTeamDetails;
	teammate: TeamUserInfo;
}

const TeamUserRow = ({ teammate, team }: TeamUserRowProps) => {
	const dispatch = useDispatch<Dispatch>();

	const profile = useTypedSelector((state) => state.activeProfile);

	const [loading, setLoading] = useState(false);

	const editTeammateRole = (role: MembershipRole) => {
		setLoading(true);

		dispatch(TeamActions.updateTeammate(team.id, teammate.id, { role }))
			.catch((e) => dispatch(SnackbarActions.showError(e)))
			.finally(() => setLoading(false));
	};

	const popoverOptions = [
		{ title: 'Admin', onClick: () => editTeammateRole('admin') },
		{
			title: 'Manager',
			onClick: () => editTeammateRole('manager'),
			isLast: true,
		},
	];

	const userCanEdit = team.user_role === 'admin';

	return (
		<TableRow>
			<TableCell>{teammate.user_name}</TableCell>
			<TableCell>{teammate.email}</TableCell>

			<TableCell>
				{!userCanEdit && (
					<Button variant="outlined" color="secondary">
						{_.capitalize(teammate.role)}
					</Button>
				)}
				{userCanEdit && (
					<RoleContainer>
						<PopoverMenu
							width={50}
							topSpace={12}
							options={popoverOptions}
							triggerNode={
								<LoadingButton
									loading={loading}
									variant="outlined"
									color="secondary"
									endIcon={<RightIcon icon={solid('sort-down')} />}>
									{_.capitalize(teammate.role)}
								</LoadingButton>
							}
						/>
					</RoleContainer>
				)}
			</TableCell>
			<TableCell>
				{userCanEdit && teammate.id !== profile.id && (
					<RemoveTeammateButton team={team} user={teammate} />
				)}
			</TableCell>
		</TableRow>
	);
};

export default ({ team }: Props) => {
	const headers = ['Name', 'Email', 'Role', ''];

	return (
		<TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow>
						{headers.map((header) => {
							return <TableCell key={header}>{header}</TableCell>;
						})}
					</TableRow>
				</TableHead>

				<TableBody>
					{team.users.map((teammate) => (
						<TeamUserRow key={teammate.id} teammate={teammate} team={team} />
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

const RightIcon = styled(FontAwesomeIcon)`
	margin-bottom: ${spacingPx(2)};
`;

const RoleContainer = styled.div`
	width: ${spacingPx(30)};
`;
