import { Row, Screens } from 'components';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { TeamInvitesActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';

import TeamDetails from './details';
import TeamList from './list';
import TeamSetup from './setup';

const Teams = () => {
	const dispatch = useDispatch();
	const location = useLocation();

	const user = useTypedSelector((state) => state.activeProfile);

	useEffect(() => {
		if (!user) return;

		dispatch(TeamInvitesActions.acceptTeamInvites());
	}, [user]);

	if (!location.pathname.startsWith('/account/teams')) {
		return null;
	}

	return (
		<Screens.Container>
			<Screens.Header
				title="Teams"
				subtitle="Teams are a way to organize the properties in your portfolio and give groups of teammates access to them."
			/>
			<Row>
				<TeamList />
				<TeamDetails />
				<TeamSetup />
			</Row>
		</Screens.Container>
	);
};

export default Teams;
