import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { PlanGate } from 'components';

import { useAppFolioIntegration } from 'hooks/appfolio-integration';
import { useRentManagerIntegrations } from 'hooks/rentmanager-integration';
import AccountCard from '../account-card';

const Account = () => {
	const history = useHistory();

	const { loading: afLoading, appFolioIntegration } = useAppFolioIntegration();
	const { loading: rmLoading, rentManagerIntegrations } =
		useRentManagerIntegrations();

	if (appFolioIntegration || rentManagerIntegrations.length) {
		return null;
	}

	return null;

	return (
		<Grid item xs={1}>
			<PlanGate variant="any">
				<AccountCard
					loading={rmLoading || afLoading}
					newUntil="2025-01-31"
					title="Imports"
					icon={regular('file-import')}
					body="Easily import and manage data for your units and residents—view past imports and keep your RentCheck data up to date."
					onClick={() => history.push('/account/imports')}
				/>
			</PlanGate>
		</Grid>
	);
};

export default Account;
