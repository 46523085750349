import { Button, Snackbar, SnackbarContent, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Utils } from '@rentcheck/biz';

import { IntercomAPI } from 'react-intercom';
import { useTypedSelector } from 'store/reducers/rootReducer';

export default () => {
	const history = useHistory();
	const location = useLocation();

	const subscription = useTypedSelector((state) => state.subscription);

	const [open, setOpen] = useState(false);

	useEffect(() => {
		const subscriptionIsCancelledOrPaused =
			Utils.Subscriptions.isCancelled(subscription) ||
			Utils.Subscriptions.isPaused(subscription);

		setOpen(subscriptionIsCancelledOrPaused);

		if (!subscriptionIsCancelledOrPaused) {
			return;
		}

		if (location.pathname !== '/account/billing') {
			history.push('/account/billing');
		}
	}, [subscription, location]);

	const handleClose = () => {
		setOpen(false);
	};

	const handleGetHelp = () => {
		IntercomAPI('showNewMessage');
	};

	return (
		<Snackbar
			open={open}
			sx={{ marginTop: 6 }}
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
			<SnackbarContent
				style={{ backgroundColor: 'black' }}
				message={
					<>
						{Utils.Subscriptions.isCancelled(subscription) && (
							<Typography style={{ color: 'white', maxWidth: 400 }}>
								Your access is currently limited because your RentCheck
								subscription is no longer active. Upgrade now to regain access
								to features available on a RentCheck subscription.
							</Typography>
						)}
						{Utils.Subscriptions.isPaused(subscription) && (
							<Typography style={{ color: 'white', maxWidth: 400 }}>
								Your subscription has been paused and access is currently
								limited.{' '}
								<a
									href="javascript: void(0)"
									onClick={handleGetHelp}
									style={{ color: 'white', textDecoration: 'underline' }}>
									Chat to get help.
								</a>
							</Typography>
						)}
					</>
				}
				action={
					<Button
						variant="text"
						onClick={handleClose}
						style={{ color: '#ACFFC6' }}>
						Ok
					</Button>
				}
			/>
		</Snackbar>
	);
};
