import { useTypedSelector } from 'store/reducers/rootReducer';

import SnapshotRow from './snapshot-row';

const Undelivered = () => {
	const dashboardData = useTypedSelector((state) => state.dashboard.data);

	const undeliveredData = dashboardData?.todo_list.undelivered_emails_count;

	return (
		<SnapshotRow
			label="Undelivered"
			link="/dashboard/undelivered"
			count={undeliveredData}
		/>
	);
};

export default Undelivered;
