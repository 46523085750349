import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { MenuItem, Tooltip } from '@mui/material';
import { Rules } from '@rentcheck/biz';
import {
	ApiInspectionWithTemplate,
	Feature,
	MaintenanceFlag,
} from '@rentcheck/types';
import { Spacer } from 'components';
import ToggleButton, { ToggleButtonRef } from 'components/toggle-button';
import { MouseEvent, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { ModalFlowActions } from 'store/actions';
import { FlagFeatureMetadata } from 'store/reducers/modal-flows';
import { testId } from 'utils/helpers';

interface Props {
	feature: Feature;
	inspection: ApiInspectionWithTemplate;
	image?: string;
	buttonSx?: React.CSSProperties;
	disabled?: boolean;
}

const Flag = ({ feature, inspection, image, buttonSx, disabled }: Props) => {
	const dispatch = useDispatch();

	const buttonRef = useRef<ToggleButtonRef>(null);

	const handleClickUnflagged = () => {
		const payload: FlagFeatureMetadata = { feature, inspection };

		if (image) {
			payload.images = [image];
		}

		dispatch(ModalFlowActions.showFlagFeatureModal(payload));
	};

	const handleClose = () => {
		buttonRef.current?.close();
	};

	const handleAddNewFlag = (e: MouseEvent<HTMLLIElement>) => {
		e.stopPropagation();

		const payload: FlagFeatureMetadata = { feature, inspection };

		if (image) {
			payload.images = [image];
		}

		dispatch(ModalFlowActions.showFlagFeatureModal(payload));
		handleClose();
	};

	const handleMenuClick = (flag: MaintenanceFlag) => {
		handleClose();
		dispatch(
			ModalFlowActions.showFlagFeatureModal({ feature, flag, inspection })
		);
	};

	const relevantMaintenanceFlags = image
		? feature.maintenance_flags?.filter(
				(mf) => mf.image_urls?.includes(image) || mf.video_urls?.includes(image)
			)
		: feature.maintenance_flags;

	if (!Rules.Inspections.canPerformFeatureActions(inspection)) {
		return null;
	}

	if (!relevantMaintenanceFlags?.length) {
		return (
			<ToggleButton
				selected={false}
				buttonSx={buttonSx}
				tooltip="Flag for maintenance"
				icon={regular('flag')}
				onClick={handleClickUnflagged}
				disabled={disabled}
			/>
		);
	}

	return (
		<ToggleButton
			selected
			ref={buttonRef}
			buttonSx={buttonSx}
			icon={regular('flag')}
			tooltip="Flag for maintenance"
			disabled={disabled}>
			<MenuItem onClick={handleAddNewFlag} sx={{ width: 262 }}>
				Add another maintenance flag
				<Spacer width={3} />
			</MenuItem>
			{relevantMaintenanceFlags?.map((flag) => (
				<Tooltip
					title={flag.note}
					componentsProps={{
						popper: { placement: 'left' },
					}}>
					<MenuItem
						onClick={(e) => {
							e.stopPropagation();
							handleMenuClick(flag);
						}}
						id={`carousel-header-flagged-menu-${testId(flag.skill)}`}>
						{flag.skill}
						<Spacer width={3} />
					</MenuItem>
				</Tooltip>
			))}
		</ToggleButton>
	);
};

export default Flag;
