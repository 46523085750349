import { ApiInspection } from '@rentcheck/types';

import { Tooltip, Typography } from '@mui/material';
import { Column, SpacedRow } from 'components';
import TooltipCopyButton from 'components/tooltip-copy-button';
import _ from 'lodash';
import { summarizeList } from 'utils/helpers';

interface Props {
	title?: string;
	bodyTitle?: string;
	recipients?: ApiInspection['invites'];
	children?: React.ReactNode | React.ReactNode[];
}

export default ({
	title = 'Assigned Recipients',
	recipients,
	bodyTitle,
	children,
}: Props) => {
	const emailsList = summarizeList(_.map(recipients, 'email') ?? [], 3);

	return (
		<Tooltip
			title={
				<>
					<Column>
						<Typography variant="overline" color="#fff" mb={1}>
							{title}
						</Typography>
						{recipients?.map((i) => (
							<SpacedRow>
								{i.email} {!!i.reference_code && `(${i.reference_code})`}
								<TooltipCopyButton text={i.email} />
							</SpacedRow>
						))}

						{children}
					</Column>
				</>
			}>
			<span>{bodyTitle || emailsList}</span>
		</Tooltip>
	);
};
