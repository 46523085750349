import { Chip } from '@mui/material';
import typography from 'theme/typography';
import { testId } from 'utils/helpers';

interface Props {
	active?: boolean;
	title: string;
}

const ComingSoonChip = ({ active, title }: Props) => {
	if (!active) return null;

	return (
		<Chip
			id={`coming-soon-badge-${testId(title)}`}
			label="Coming Soon"
			sx={{
				marginLeft: 2,
				backgroundColor: '#2D3CE61F',
				...typography.MuiTypography.styleOverrides.body2,
				color: '#232E3A',
			}}
		/>
	);
};

export default ComingSoonChip;
