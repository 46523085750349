import { Paper, Table, TableBody, TableContainer } from '@mui/material';
import _ from 'lodash';

import { MaintenanceFlag } from '@rentcheck/types';

import TableHeader from './header';
import FeatureTableRow from './row';

interface Props {
	flags: MaintenanceFlag[];
	selectedIds: string[];
	setSelectedIds: (ids: string[]) => void;
	setSortBy: (key: keyof MaintenanceFlag) => void;
	sortBy: keyof MaintenanceFlag;
	setSortDirection: (direction: 'asc' | 'desc') => void;
	sortDirection: 'asc' | 'desc';
}

const FeaturesTable = ({
	flags,
	selectedIds,
	setSelectedIds,
	setSortBy,
	sortBy,
	setSortDirection,
	sortDirection,
}: Props) => {
	const handleSelectAll = () => {
		if (selectedIds.length === flags.length) {
			return setSelectedIds([]);
		}

		setSelectedIds(flags.map((f) => f.id));
	};

	const handleSelectId = (id: string) => {
		setSelectedIds(_.xor(selectedIds, [id]));
	};

	return (
		<TableContainer component={Paper}>
			<Table sx={{ minWidth: 650 }} aria-label="simple table">
				<TableHeader
					flags={flags}
					selectedIds={selectedIds}
					selectAllIds={handleSelectAll}
					setSortBy={setSortBy}
					sortBy={sortBy}
					setSortDirection={setSortDirection}
					sortDirection={sortDirection}
				/>

				<TableBody>
					{flags.map((f) => (
						<FeatureTableRow
							key={f.id}
							flag={f}
							selectedIds={selectedIds}
							selectId={handleSelectId}
						/>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default FeaturesTable;
