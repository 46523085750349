import { useState } from 'react';
import styled from 'styled-components';

import { ApiInspection, APIProperty } from '@rentcheck/types';

import { colors, spacingPx } from 'theme';
import { formatDateAndTime } from 'utils/helpers';

import { Button, Typography } from '@mui/material';
import { Analytics } from 'utils';
import { Spacer } from '../../..';
import EditComparisonModal from '../Components/EditComparisonModal';

interface Props {
	property: APIProperty;
	firstInspection?: ApiInspection;
	secondInspection?: ApiInspection;
	setCurrentStepOne: () => void;
	setCurrentStepTwo: () => void;
	setModalVisible: (modalVisible: boolean) => void;
}

type Label = 'Add Inspection 1' | 'Add Inspection 2';

const InspectionsToCompare = ({
	property,
	firstInspection,
	secondInspection,
	setCurrentStepOne,
	setCurrentStepTwo,
	setModalVisible,
}: Props) => {
	const [editModalVisible, setEditModalVisible] = useState(false);
	const [editModalIndex, setEditModalIndex] = useState(1);
	const renderHeading = () => {
		if (firstInspection && secondInspection) {
			return (
				<>
					<Typography variant="h5">Select Inspections to Compare</Typography>
					<Spacer height={2} />
					<Typography>
						Press Compare to see these inspections side-by-side.
					</Typography>
				</>
			);
		}
		if (firstInspection || secondInspection) {
			return (
				<>
					<Typography variant="h5">Select Inspections to Compare</Typography>
					<Spacer height={2} />
					<Typography>
						Select 1 more inspection from {property.address} to compare
					</Typography>
				</>
			);
		}
		return (
			<>
				<Typography variant="h5">Select Inspections to Compare</Typography>
				<Spacer height={2} />
				<Typography>
					Select 2 completed inspections from {property.address} to compare
				</Typography>
			</>
		);
	};

	const renderContent = (
		inspection: ApiInspection | undefined,
		setCurrentStep: () => void,
		label: Label,
		index: number
	) => {
		if (inspection) {
			return (
				<Wrapper>
					<Typography>
						{inspection.inspection_template.name} Inspection
					</Typography>
					<Spacer height={2} />
					<SpacedRow>
						<Typography>Status</Typography>
						<Typography>{inspection.inspection_status}</Typography>
					</SpacedRow>
					<Spacer height={2} />
					<SpacedRow>
						<Typography>Completed by</Typography>
						<Typography>{inspection.current_performer?.name}</Typography>
					</SpacedRow>
					<Spacer height={2} />
					<SpacedRow>
						<Typography>Completion Date</Typography>
						<Typography>
							{formatDateAndTime(inspection.completed_date)}
						</Typography>
					</SpacedRow>
					<Spacer height={2} />
					<EditRow
						onClick={() => {
							setEditModalVisible(true);
							setEditModalIndex(index);
						}}>
						<Typography color={colors.primary}>Edit</Typography>
					</EditRow>
				</Wrapper>
			);
		}
		return (
			<UnselectedWrapper onClick={setCurrentStep}>
				<Typography variant="h5" color={colors.primary}>
					+
				</Typography>
				<Typography variant="h5" color={colors.primary}>
					{label}
				</Typography>
			</UnselectedWrapper>
		);
	};
	return (
		<>
			{renderHeading()}
			<Spacer height={5} />

			<InspectionBlockRow>
				{renderContent(
					firstInspection,
					setCurrentStepOne,
					'Add Inspection 1',
					1
				)}
				{renderContent(
					secondInspection,
					setCurrentStepTwo,
					'Add Inspection 2',
					2
				)}
			</InspectionBlockRow>

			<Spacer height={5} />

			<SpacedRow>
				<ButtonContainer left>
					<Button
						variant="outlined"
						color="secondary"
						onClick={() => setModalVisible(false)}>
						Cancel
					</Button>
				</ButtonContainer>

				<ButtonContainer right>
					<Button
						disabled={!firstInspection || !secondInspection}
						onClick={() => {
							if (firstInspection && secondInspection) {
								Analytics.trackEvent('Compared inspections', {
									inspection_templates: `${firstInspection.inspection_template.name}, ${secondInspection.inspection_template.name}`,
									inspection_ids: `${firstInspection.id}, ${secondInspection.id}`,
								});

								window.open(
									`/inspections/comparison/${firstInspection.id}-${secondInspection.id}`,
									'_self'
								);
							}
						}}>
						Compare
					</Button>
				</ButtonContainer>
			</SpacedRow>

			{editModalVisible && firstInspection && secondInspection && (
				<EditComparisonModal
					currentInspections={[firstInspection, secondInspection]}
					editIndex={editModalIndex}
					setHidden={() => setEditModalVisible(false)}
				/>
			)}
		</>
	);
};

const Wrapper = styled.div`
	width: 40%;
	padding: ${spacingPx(3)} ${spacingPx(3)};
	border: 1px solid ${colors.contentBorder};
	border-radius: ${spacingPx(1)};
	display: flex;
	flex-direction: column;
`;

const UnselectedWrapper = styled(Wrapper)`
	height: ${spacingPx(39)};
	align-items: center;
	justify-content: center;
	border: 1px solid ${colors.primary};
	cursor: pointer;
	&:hover {
		opacity: 0.7;
	}
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
`;

const InspectionBlockRow = styled(Row)`
	justify-content: space-around;
`;

const SpacedRow = styled(Row)`
	justify-content: space-between;
`;

const EditRow = styled(SpacedRow)`
	width: ${spacingPx(7)};
	cursor: pointer;
	&:hover {
		opacity: 0.7;
	}
`;

const ButtonContainer = styled.div<{ right?: boolean; left?: boolean }>`
	display: flex;
	flex-direction: column;
	padding-left: ${({ right }) => (right ? spacingPx(2) : 0)};
	padding-right: ${({ left }) => (left ? spacingPx(2) : 0)};
`;

export default InspectionsToCompare;
