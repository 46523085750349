import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import IconButton from 'components/icon-button';
import _ from 'lodash';
import { FeatureWithMetadata } from 'store/reducers/features';
import { scrollToElement } from 'utils/helpers';

interface Props {
	feature: FeatureWithMetadata;
	features: FeatureWithMetadata[];
}

export default ({ feature, features }: Props) => {
	const isFirst = _.first(features)?.id === feature.id;
	const isLast = _.last(features)?.id === feature.id;

	const handleGoToNext = () => {
		const index = features.findIndex((f) => f.id === feature.id);
		const nextFeature = features[index + 1];

		if (!nextFeature) {
			return;
		}

		scrollToElement(`feature-accordion-${nextFeature.id}`, 'start', -56);
	};

	const handleGoToPrevious = () => {
		const index = features.findIndex((f) => f.id === feature.id);
		const previousFeature = features[index - 1];

		if (!previousFeature) {
			return;
		}

		scrollToElement(`feature-accordion-${previousFeature.id}`, 'start', -56);
	};

	return (
		<div onClick={(e) => e.stopPropagation()} style={{ whiteSpace: 'nowrap' }}>
			<IconButton
				icon={solid('arrow-up')}
				tooltip="Previous Feature"
				disabled={isFirst}
				onClick={handleGoToPrevious}
			/>
			<IconButton
				icon={solid('arrow-down')}
				tooltip="Next Feature"
				disabled={isLast}
				onClick={handleGoToNext}
			/>
		</div>
	);
};
