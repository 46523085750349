import { Feature, TemplateQuestion } from '@rentcheck/types';
import FreeTextQuestion from './question-free-text';
import MultipleChoiceQuestion from './question-multiple-choice';
import YesNoQuestion from './question-yes-no';

interface Props {
	feature: Feature;
	question: TemplateQuestion;
	index: number;
	hoveredImage?: string;
	setHoveredQuestion?: (question?: TemplateQuestion) => void;
}

export default ({
	feature,
	question,
	index,
	hoveredImage,
	setHoveredQuestion,
}: Props) => {
	const answer = feature.responses[index];

	if (question.type === 'yes/no') {
		return (
			<YesNoQuestion
				feature={feature}
				question={question}
				answer={answer}
				showDivider={false}
				hoveredImage={hoveredImage}
				setHoveredQuestion={setHoveredQuestion}
			/>
		);
	}

	if (question.type === 'free text') {
		return (
			<FreeTextQuestion
				question={question}
				answer={answer}
				showDivider={false}
			/>
		);
	}

	if (question.type === 'asset capture') {
		return (
			<FreeTextQuestion
				inline
				question={question}
				answer={answer}
				showDivider={false}
			/>
		);
	}

	if (question.type === 'multiple choice') {
		return (
			<MultipleChoiceQuestion
				question={question}
				answer={answer}
				showDivider={false}
			/>
		);
	}

	return null;
};
