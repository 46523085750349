import { useTypedSelector } from 'store/reducers/rootReducer';

import SnapshotRow from './snapshot-row';

const UnitsAtRisk = () => {
	const dashboardData = useTypedSelector((state) => state.dashboard.data);

	const count = dashboardData?.todo_list.properties_at_risk_count;

	return (
		<SnapshotRow
			count={count}
			label="Units at Risk"
			link="/dashboard/units-at-risk"
		/>
	);
};

export default UnitsAtRisk;
