import { FileRequestApi } from '@rentcheck/api-frontend';
import { FiltersConfig } from '@rentcheck/api-frontend/dist/file-request/post';
import {
	FileRequestDocumentType,
	FileRequestExportType,
} from '@rentcheck/types';
import { Dispatch } from 'types';

import * as SnackbarActions from './snackbar-actions';

export const create =
	(payload: {
		/**
		 * File Request Creation parameters
		 */
		documentType: FileRequestDocumentType;
		documentId: string;
		exportType: FileRequestExportType;
		filters?: FiltersConfig;
		dontNotify?: boolean;

		/**
		 * File Request Drawer Parameters
		 */
		title: string;
		subtitle: string;
		type: 'download' | 'copy';
	}) =>
	async (dispatch: Dispatch) => {
		return FileRequestApi.create(
			payload.documentType,
			payload.documentId,
			payload.exportType,
			payload.filters,
			payload.dontNotify
		)
			.then((response) => {
				dispatch({
					type: 'ADD_FILE_REQUEST',
					value: {
						id: response.fileRequestId,
						document_id: payload.documentId,
						document_type: payload.documentType,
						title: payload.title,
						subtitle: payload.subtitle,
						type: payload.type,
					},
				});
			})
			.catch((error) => {
				dispatch(SnackbarActions.showError(error));
			});
	};
