import { Rules } from '@rentcheck/biz';
import { ApiInspection } from '@rentcheck/types';
import MenuItem from 'components/menu-item';
import { useDispatch } from 'react-redux';
import { InspectionActions, ModalFlowActions } from 'store/actions';
import { Dispatch } from 'types';

interface Props {
	inspection: ApiInspection;
	onSelected: () => void;
}

export default ({ inspection, onSelected }: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const handleClick = () => {
		onSelected();

		dispatch(
			ModalFlowActions.showConfirmationModal({
				title: 'Are you sure?',
				body1: [
					'Are you sure you want to mark this inspection as completed?',
					'Any features in progress that have not been uploaded and saved from the mobile app will be lost.',
				],
				buttons: [
					ModalFlowActions.cancelButton(dispatch),
					{
						title: 'Confirm',
						color: 'primary',
						variant: 'contained',
						onClick: () => {
							dispatch(ModalFlowActions.closeConfirmationModal());
							dispatch(InspectionActions.markAsCompleted(inspection));
						},
					},
				],
			})
		);
	};

	if (!Rules.Inspections.canMarkAsComplete(inspection)) {
		return null;
	}

	return <MenuItem onClick={handleClick}>Mark as Complete</MenuItem>;
};
