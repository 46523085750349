import { Typography } from '@mui/material';
import { Spacer } from '../index';

const PropertyText = () => {
	return (
		<>
			<Typography>
				Add units to RentCheck by uploading your unit data onto the RentCheck
				platform.
			</Typography>

			<Spacer height={3} />
			<Typography>
				Click
				<a
					target="_blank"
					href="https://help.getrentcheck.com/en/articles/5170518-how-to-upload-your-property-and-resident-information-to-rentcheck">
					{' here '}
				</a>
				for instructions on how to download your unit data from a number of
				common property management platforms and to view a sample unit upload
				file. Once you have your file ready, simply attach it below.
			</Typography>
		</>
	);
};

const ResidentText = () => {
	return (
		<>
			<Typography>
				Add residents to RentCheck by uploading your resident data onto the
				RentCheck platform. Having your resident data in RentCheck makes it
				easier to request inspections and schedule them in advance.
			</Typography>

			<Spacer height={3} />
			<Typography>
				Residents are only added to existing units in RentCheck so it’s
				important that the units have already been added to your account for any
				residents you are uploading. We will match the resident to a unit by
				street address and postal code (included in your CSV file).
			</Typography>

			<Spacer height={3} />
			<Typography>
				Once a match is found, new residents will be added. If an email address
				in your CSV matches an existing resident for a given unit, it will be
				updated. Otherwise, a new resident will be created.
			</Typography>

			<Spacer height={3} />
			<Typography>
				Click
				<a
					target="_blank"
					href="https://help.getrentcheck.com/en/articles/5170518-how-to-upload-your-property-and-resident-information-to-rentcheck">
					{' here '}
				</a>
				for instructions on how to download your resident data from a number of
				common property management platforms and to view a sample resident
				upload file. Once you have your file ready, attach it below.
			</Typography>
		</>
	);
};

export type UploadType = 'property' | 'resident';

interface Props {
	type: UploadType;
}

export default ({ type }: Props) => {
	if (type === 'property') return <PropertyText />;
	if (type === 'resident') return <ResidentText />;
	return null;
};
