import { Skeleton } from '@mui/material';

const ItemSkeleton = () => {
	return (
		<Skeleton
			variant="rectangular"
			sx={{
				borderRadius: 2,
				marginRight: 2,
				flex: 1,
				':last-child': {
					marginRight: 0,
				},
			}}
			height={118}
		/>
	);
};

export default ItemSkeleton;
