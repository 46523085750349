import { Checkbox, Typography } from '@mui/material';
import {
	ApiInspection,
	APIProperty,
	JenarkIntegration,
} from '@rentcheck/types';
import { IntegrationsApi } from 'api';
import { Row } from 'components';
import { useEffect, useState } from 'react';
import { CreateWorkOrderMetadata } from 'store/reducers/modal-flows';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { simplePropertyType } from 'utils/helpers';
import { hasJenarkWorkOrderAddons } from 'utils/integrations';

interface Props {
	inspection: ApiInspection;
	property: APIProperty;
	value?: CreateWorkOrderMetadata['provider'];
	setValue: (value?: CreateWorkOrderMetadata['provider']) => void;
}

const JenarkWorkOrderIntegration = ({
	inspection,
	property,
	value,
	setValue,
}: Props) => {
	const subscription = useTypedSelector((state) => state.subscription);

	const [integration, setIntegration] = useState<JenarkIntegration | null>(
		null
	);

	useEffect(() => {
		if (!subscription) {
			return;
		}

		IntegrationsApi.getJenarkIntegrationForSubscriptionId(subscription.id)
			.then(setIntegration)
			.catch(() => {});
	}, [inspection, subscription]);

	if (!integration) {
		return null;
	}

	if (!hasJenarkWorkOrderAddons(subscription)) {
		return null;
	}

	if (property?.sync_data?.vendor !== 'jenark') {
		return (
			<Typography sx={{ marginTop: 3 }}>
				*This {simplePropertyType(property)} is not synced with Jenark.
			</Typography>
		);
	}

	return (
		<Row style={{ marginTop: 24, marginLeft: -12 }}>
			<Checkbox
				checked={value === 'Jenark'}
				onChange={() => setValue(value ? undefined : 'Jenark')}
			/>
			<Typography>Create Jenark Work Order</Typography>
		</Row>
	);
};

export default JenarkWorkOrderIntegration;
