import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Rules } from '@rentcheck/biz';
import { ApiInspection } from '@rentcheck/types';
import IconButton from 'components/icon-button';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FileRequestsActions, SnackbarActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';
import { Analytics } from 'utils';
import { getReportLocationForTemplate } from 'utils/helpers';

interface Props {
	inspection: ApiInspection;
}

export default ({ inspection }: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const accountSettings = useTypedSelector((state) => state.accountSettings);

	const [loading, setLoading] = useState(false);

	const existingPdfLocation = getReportLocationForTemplate(
		inspection,
		accountSettings
	);

	const handleDownloadPDF = async () => {
		if (existingPdfLocation) {
			navigator.clipboard?.writeText(existingPdfLocation);

			dispatch(
				SnackbarActions.showSuccess('The link was copied to your clipboard')
			);

			Analytics.trackEvent('copied_inspection_report_link', {
				inspection_id: inspection.id,
			});

			return;
		}

		setLoading(true);

		dispatch(
			FileRequestsActions.create({
				documentType: 'INSPECTION_REPORT',
				documentId: inspection.id,
				exportType: 'PDF',
				dontNotify: true,
				title: 'Inspection PDF Report',
				subtitle: `${inspection.inspection_template.name}, ${inspection.property.address}`,
				type: 'copy',
			})
		).finally(() => {
			setLoading(false);
		});
	};

	if (!Rules.Inspections.canDownloadReport(inspection)) {
		return null;
	}

	return (
		<IconButton
			loading={loading}
			icon={regular('link')}
			tooltip={existingPdfLocation ? 'Copy Link' : 'Prepare Link'}
			onClick={handleDownloadPDF}
		/>
	);
};
