import { Button, Typography } from '@mui/material';
import { emptyStates } from 'assets';
import { useDispatch } from 'react-redux';
import { ModalFlowActions } from 'store/actions';
import styled from 'styled-components';
import { Analytics } from 'utils';
import { CenteredColumn, SpacedRow, Spacer } from '../../..';
import Modal from '../../../Common/Modal';

interface Props {
	cancel: () => void;
}

const Paywall = ({ cancel }: Props) => {
	const dispatch = useDispatch();

	const handleUpgradeSubscription = () => {
		Analytics.trackEvent('selected_button_to_upgrade_plan');
		dispatch(ModalFlowActions.showUpgradeSubscriptionModal());
	};

	return (
		<Modal>
			<CenteredColumn>
				<PaywallImage src={emptyStates.buildings} alt="empty-logo" />
				<Spacer height={3} />
				<Typography variant="h5">Maximum Units Reached</Typography>
				<Spacer height={3} />
				<Typography>
					You have reached the maximum number of units included in our free
					plan. Upgrade to the Pay As You Grow plan to add additional units.
				</Typography>
				<Spacer height={10} />
			</CenteredColumn>
			<SpacedRow>
				<Button variant="outlined" color="secondary" onClick={() => cancel()}>
					Not Now
				</Button>
				<Button onClick={handleUpgradeSubscription}>Upgrade Plan</Button>
			</SpacedRow>
		</Modal>
	);
};

const PaywallImage = styled.img`
	width: 65%;
	margin-top: 27px;
`;

export default Paywall;
