import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { match as Match } from 'react-router-dom';

import { InspectionsActions, SnackbarActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';

import { Screens } from 'components';
import { useProperty } from 'hooks/properties';
import InspectionDetailHeader from '../details/header';
import MaintenanceFlagsList from './list';

interface MatchParams {
	id: string;
}

interface Props {
	match: Match<MatchParams>;
}

const FlaggedDetail = ({ match }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const inspectionId = match.params.id;
	const inspection = useTypedSelector((state) =>
		state.inspections.inspection?.id === inspectionId
			? state.inspections.inspection
			: null
	);

	const { property } = useProperty(inspection?.property.id);

	useEffect(() => {
		dispatch(InspectionsActions.getById(inspectionId)).catch((e) =>
			dispatch(SnackbarActions.showError(e.message))
		);
	}, []);

	if (!inspection) return null;
	if (!property) return null;

	return (
		<Screens.Container>
			<InspectionDetailHeader inspection={inspection} />
			<MaintenanceFlagsList inspection={inspection} property={property} />
		</Screens.Container>
	);
};

export default FlaggedDetail;
