import { DatePicker } from '@mui/lab';
import { Divider, TextField, Typography } from '@mui/material';
import { Column, Spacer } from 'components';
import moment from 'moment';
import { useIsMobileBreakpoint } from 'utils/hooks';

interface Props {
	dueDate?: Date;
	setDueDate: (value?: Date) => void;
}

export default ({ dueDate, setDueDate }: Props) => {
	const isMobile = useIsMobileBreakpoint();

	return (
		<Column>
			<Spacer height={5} />
			<Typography variant="overline">Due date</Typography>
			<Spacer height={3} />

			<DatePicker
				inputFormat="MM/dd/yy"
				minDate={moment().add(1, 'd').toDate()}
				disablePast={true}
				value={dueDate}
				mask="__/__/__"
				renderInput={(props) => (
					<TextField
						{...props}
						fullWidth={isMobile}
						sx={isMobile ? undefined : { width: 300 }}
						id="input-due-date"
						variant="filled"
						label="Due date"
					/>
				)}
				onChange={(date) => {
					if (!date) setDueDate(undefined);
					setDueDate(date as Date);
				}}
			/>

			<Spacer height={4} />
			<Divider />
		</Column>
	);
};
