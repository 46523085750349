import { FileRequestApi } from '@rentcheck/api-frontend';
import { Rules } from '@rentcheck/biz';
import { ApiInspection } from '@rentcheck/types';
import MenuItem from 'components/menu-item';
import { useStoreFeatures } from 'hooks/features';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';
import { Dispatch } from 'types';

interface Props {
	inspection: ApiInspection;
	onSelected: () => void;
}

export default ({ inspection, onSelected }: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const features = useStoreFeatures(inspection.id);

	if (!Rules.Inspections.canDownloadImages(inspection, features)) {
		return null;
	}

	const handleClick = () => {
		onSelected();

		if (inspection.pdf_images_location) {
			window.open(inspection.pdf_images_location);

			return;
		}
		const message = `Images PDF download request is being queued. You should receive an email with a link to the report shortly.`;

		FileRequestApi.create('INSPECTION_IMAGES', inspection.id, 'PDF');

		dispatch(SnackbarActions.showSuccess(message));
	};

	return <MenuItem onClick={handleClick}>Download Images (PDF)</MenuItem>;
};
