import { Typography } from '@mui/material';
import { Column } from 'components';
import React from 'react';
import styled from 'styled-components';
import * as theme from 'theme';

interface Props {
	label: string;
	isActive: boolean;
	isSelected: boolean;
	onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const FilterTitle = ({ label, isActive, onClick }: Props) => {
	return (
		<OptionItemWrapper isActive={isActive} onClick={onClick}>
			<Column>
				<Typography>{label}</Typography>
			</Column>
		</OptionItemWrapper>
	);
};

const OptionItemWrapper = styled.button<{ isActive: boolean }>`
	display: flex;
	justify-content: space-between;
	background-color: ${theme.colors.transparent};
	border-color: ${({ isActive }) =>
		isActive ? theme.colors.primary : theme.colors.transparent};
	border-width: 0;
	border-left-width: 3px;
	border-style: solid;
	padding: ${theme.spacingPx(2)};
	text-align: left;
	transition: all 0.2s ease-in-out;
	width: 100%;

	&:hover {
		background-color: ${theme.colors.lightGray};
		border-color: ${theme.colors.primary};
	}
`;

export default FilterTitle;
