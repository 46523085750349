import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { TemplateFeature, TemplateSection } from '@rentcheck/types';
import { Row } from 'components';
import IconButton from 'components/icon-button';
import { containsIncompleteFeatures, isFeatureIncomplete } from '../helpers';
import SettingsButton from './settings-button';

interface Props {
	feature: TemplateFeature;
	editing: boolean;
	editingThisFeature: boolean;
	sections: TemplateSection[];
	onDelete: (feature: TemplateFeature) => void;
	onAdd: (feature: TemplateFeature) => void;
	onCopy: (feature: TemplateFeature) => void;
	onSave: (feature: TemplateFeature) => void;
	onUpdate: (feature: TemplateFeature) => void;
	onAddQuestion: () => void;
}

export default ({
	feature,
	editing,
	editingThisFeature,
	sections,
	onDelete,
	onAdd,
	onCopy,
	onSave,
	onUpdate,
	onAddQuestion,
}: Props) => {
	const handleDelete = () => {
		onDelete(feature);
	};

	const handleAddFeature = () => {
		onAdd(feature);
	};

	const handleCopyFeature = () => {
		onCopy(feature);
	};

	const handleSave = () => {
		onSave(feature);
	};

	const handleAddQuestion = () => {
		onAddQuestion();
	};

	if (!editing) {
		return null;
	}

	const disabled = containsIncompleteFeatures(sections);

	if (editingThisFeature) {
		return (
			<Row style={{ alignSelf: 'flex-start', marginTop: 20 }}>
				<IconButton
					tabIndex={-1}
					disabled={isFeatureIncomplete(feature)}
					icon={regular('check-circle')}
					tooltip="Save"
					onClick={handleSave}
				/>
			</Row>
		);
	}

	return (
		<Row>
			<IconButton
				tabIndex={-1}
				disabled={disabled}
				icon={regular('plus-circle')}
				tooltip="Add Question"
				onClick={handleAddQuestion}
			/>
			<IconButton
				tabIndex={-1}
				disabled={disabled}
				icon={regular('copy')}
				tooltip="Clone Feature"
				onClick={handleCopyFeature}
			/>
			<IconButton
				tabIndex={-1}
				disabled={disabled}
				icon={regular('down-from-dotted-line')}
				tooltip="Add Feature"
				onClick={handleAddFeature}
			/>
			<IconButton
				tabIndex={-1}
				disabled={disabled}
				icon={regular('trash')}
				tooltip="Delete"
				onClick={handleDelete}
			/>
			<SettingsButton
				feature={feature}
				disabled={disabled}
				onUpdate={onUpdate}
			/>
		</Row>
	);
};
