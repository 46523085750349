import { Spacer } from 'components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { InspectionsActions, SnackbarActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';

import Filters from './filters';
import FiltersSkeleton from './filters/skeleton';
import Table from './table';
import TableSkeleton from './table/skeleton';

const List = () => {
	const dispatch: Dispatch = useDispatch();

	const profile = useTypedSelector((state) => state.activeProfile);
	const filters = useTypedSelector((state) => state.inspections.filters);

	const [loading, setLoading] = useState(true);
	const [selectedIds, setSelectedIds] = useState<string[]>([]);

	useEffect(() => {
		if (!profile) return;

		dispatch(InspectionsActions.getAll(0, filters))
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => {
				setLoading(false);
			});
	}, [profile]);

	if (loading) {
		return (
			<>
				<FiltersSkeleton />
				<Spacer height={6} />
				<TableSkeleton />
			</>
		);
	}

	return (
		<>
			<Filters />
			<Spacer height={6} />
			<Table selectedIds={selectedIds} setSelectedIds={setSelectedIds} />
		</>
	);
};

export default List;
