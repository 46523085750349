import { ImportsApi } from '@rentcheck/api-frontend';
import { GetAllImportsParams, ListOptions } from '@rentcheck/types';
import _ from 'lodash';
import useApiModel from './common/api-hook';

type FiltersT = GetAllImportsParams['filters'];
type SortT = ListOptions['sort'];

const initialFilters: FiltersT = {};

const defaultSort: SortT = {
	sort_by: 'created_at',
	sort_type: 'DESC',
};

const useImports = (
	pageSize: number = 20,
	filters: FiltersT = initialFilters
) => {
	const hookData = useApiModel(ImportsApi, filters, defaultSort, pageSize);

	return {
		imports: hookData.data,
		..._.omit(hookData, 'data'),
	};
};

export default useImports;
