import { LoadingButton } from '@mui/lab';
import { Card, CardContent, Typography } from '@mui/material';
import { AdminToolsApi } from '@rentcheck/api-frontend';
import { APIProperty } from '@rentcheck/types';
import { Column, Row, Spacer } from 'components';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';
import { Dispatch } from 'types';

interface Props {
	property: APIProperty;
	refreshProperty: () => void;
}

const RestoreProperty = ({ property, refreshProperty }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const [loading, setLoading] = useState(false);

	const canRestore = property?.team?.id;

	const handleRestore = () => {
		if (!property) {
			return;
		}

		if (!canRestore) {
			return;
		}

		setLoading(true);

		AdminToolsApi.restoreProperty(property.id)
			.then(() => {
				refreshProperty();
				dispatch(SnackbarActions.showSuccess('Property Restored'));
			})
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	const cantRestoreReason = () => {
		if (!property.team?.id) {
			return "Property doesn't have an organization id";
		}

		return '';
	};

	return (
		<Card>
			<CardContent>
				<Typography variant="h6">Restore Property</Typography>
				<Spacer height={2} />
				<Row>
					<Column>
						<Typography>
							In order to be restored the target property needs to have a valid{' '}
							<Typography variant="code">organization_id</Typography>,
							additionally in order to be considered a restorable property the{' '}
							<Typography variant="code">authorized_user_ids</Typography> array
							can't include the{' '}
							<Typography variant="code">organization_id</Typography>:
						</Typography>

						{!canRestore && (
							<>
								<Spacer height={2} />
								<Typography variant="error">{cantRestoreReason()}</Typography>
							</>
						)}
					</Column>

					<Spacer width={4} />

					<LoadingButton
						loading={loading}
						onClick={handleRestore}
						disabled={!canRestore}
						sx={{ minWidth: 90, maxWidth: 90 }}>
						Restore
					</LoadingButton>
				</Row>
			</CardContent>
		</Card>
	);
};

export default RestoreProperty;
