import { actionTypes, TeammatesActionTypes } from '../../actions/teammates';

export type State = {
	search: string;
};

type Action = {
	type: TeammatesActionTypes;
	key: keyof State;
	value: State[keyof State];
};

const initialState: State = {
	search: '',
};

export default (state: State = initialState, { type, key, value }: Action) => {
	switch (type) {
		case actionTypes.SIGNOUT_SUCCESS:
		case actionTypes.IMPERSONATE:
			return initialState;

		case actionTypes.UPDATE_PGS_FILTERS:
			return {
				...state,
				[key]: value,
			};

		default:
			return state;
	}
};
