import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Menu } from '@mui/material';
import { ApiInspection } from '@rentcheck/types';
import { useState } from 'react';

import { useIsMobileBreakpoint } from 'utils/hooks';
import Archive from './archive';
import CancelReviewRequest from './cancel-review-request';
import CancelRevisionRequest from './cancel-revision-request';
import CreateMaintenanceReport from './create-maintenance-report';
import Delete from './delete';
import DownloadImagesPdf from './download-images-pdf';
import DownloadImagesZip from './download-images-zip';
import DownloadMaintenanceFlags from './download-maintenance-flags';
import InspectionTimeline from './inspection-timeline';
import MarkAsComplete from './mark-as-complete';
import Reopen from './reopen';
import RequestSignatures from './request-signatures';
import SendReminder from './send-reminder';
import Unarchive from './unarchive';

interface Props {
	inspection: ApiInspection;
}

export default ({ inspection }: Props) => {
	const isSmallerThanMd = useIsMobileBreakpoint('md');

	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const handleOpenMenu = (e: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(e.currentTarget);
	};

	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<Button
				variant="outlined"
				sx={{
					width: 170,
					justifyContent: 'space-between',
					color: '#101010',
					flex: isSmallerThanMd ? 1 : undefined,
				}}
				endIcon={<FontAwesomeIcon icon={solid('caret-down')} />}
				onClick={handleOpenMenu}>
				Manage
			</Button>

			<Menu
				open={!!anchorEl}
				anchorEl={anchorEl}
				onClose={handleCloseMenu}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
				<InspectionTimeline
					inspection={inspection}
					onSelected={handleCloseMenu}
				/>
				<Archive inspection={inspection} onSelected={handleCloseMenu} />
				<Unarchive inspection={inspection} onSelected={handleCloseMenu} />
				<Reopen inspection={inspection} onSelected={handleCloseMenu} />
				<Delete inspection={inspection} onSelected={handleCloseMenu} />
				<SendReminder inspection={inspection} onSelected={handleCloseMenu} />
				<MarkAsComplete inspection={inspection} onSelected={handleCloseMenu} />
				<DownloadImagesPdf
					inspection={inspection}
					onSelected={handleCloseMenu}
				/>
				<DownloadImagesZip
					inspection={inspection}
					onSelected={handleCloseMenu}
				/>
				<DownloadMaintenanceFlags
					inspection={inspection}
					onSelected={handleCloseMenu}
				/>
				<RequestSignatures
					inspection={inspection}
					onSelected={handleCloseMenu}
				/>
				<CreateMaintenanceReport
					inspection={inspection}
					onSelected={handleCloseMenu}
				/>
				<CancelReviewRequest
					inspection={inspection}
					onSelected={handleCloseMenu}
				/>
				<CancelRevisionRequest
					inspection={inspection}
					onSelected={handleCloseMenu}
				/>
			</Menu>
		</>
	);
};
