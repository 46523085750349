import { Typography } from '@mui/material';
import { Button, Column, PlanGate, Row, Spacer } from 'components';
import { useStoreTeams } from 'hooks/teams';
import { useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useTypedSelector } from 'store/reducers/rootReducer';
import styled from 'styled-components';
import { spacingPx } from 'theme';
import CreateTeamModal from '../../../../components/teams/create';

const Setup = () => {
	const location = useLocation();
	const profile = useTypedSelector((state) => state.activeProfile);
	const { teams } = useStoreTeams();

	const [createModalVisible, setCreateModalVisible] = useState(false);

	if (!profile) return null;

	if (
		location.pathname !== '/account/teams' &&
		location.pathname !== '/account/teams/'
	) {
		return null;
	}

	if (teams.length) {
		return <Redirect to={`/account/teams/${teams[0].id}`} />;
	}

	return (
		<Container>
			<MainColumn>
				<Typography variant="h6">Set up a team account</Typography>
				<Spacer height={3} />
				<Typography>
					With a team account you can work with others in your company in
					RentCheck. Invite as many team members to join as you like. We will
					send them an email invitation and once accepted you'll see them here.
					You can also set up multiple teams if you'd like to give individuals
					access to specific groups of units within your portfolio. Add
					teammates to one or more teams and they will only be able to view and
					manage the units and inspections for the team or teams to which
					they've been invited.
				</Typography>
				<Spacer height={10} />
				<PlanGate variant="any">
					<Button onClick={() => setCreateModalVisible(true)}>
						Set up Team
					</Button>
				</PlanGate>
			</MainColumn>
			{createModalVisible && (
				<CreateTeamModal setModalVisible={setCreateModalVisible} />
			)}
		</Container>
	);
};

const Container = styled(Row)`
	align-items: flex-start;
	justify-content: space-between;
`;

const MainColumn = styled(Column)`
	max-width: ${spacingPx(150)};
	width: 75%;
`;

export default Setup;
