import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fab } from '@mui/material';
import useScrollPosition from '@react-hook/window-scroll';
import { Column } from 'components';
import { useMemo } from 'react';
import { isEmbeddedInMobileApp, scrollToTop } from 'utils/helpers';

export default () => {
	const scrollY = useScrollPosition(25 /*fps*/);
	const visible = useMemo(() => {
		const scrollMarker = document.getElementById('scroll-marker');

		if (!scrollMarker) {
			return false;
		}

		const bcr = scrollMarker.getBoundingClientRect();
		const bottom = bcr.bottom;

		return bottom < 100;
	}, [scrollY]);

	const handleClick = () => {
		scrollToTop();
	};

	if (!visible) {
		return <div id="scroll-marker" />;
	}

	return (
		<>
			<div id="scroll-marker" />
			<Fab
				onClick={handleClick}
				sx={{
					margin: 0,
					top: 'auto',
					right: 20,
					bottom: isEmbeddedInMobileApp() ? 20 : 76,
					left: 'auto',
					position: 'fixed',
					height: 48,
					width: 48,
					backgroundColor: 'white',
					zIndex: 1000,
				}}>
				<Column>
					<FontAwesomeIcon
						icon={regular('chevron-up')}
						style={{ marginBottom: -4 }}
					/>
					Top
				</Column>
			</Fab>
		</>
	);
};
