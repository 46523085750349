import { MenuItem, SelectChangeEvent, Typography } from '@mui/material';
import { TemplateQuestion, TemplateSection } from '@rentcheck/types';
import { Row } from 'components';
import FormTextField from 'components/form-text-field';
import _ from 'lodash';
import { ChangeEvent, useEffect, useMemo } from 'react';
import 'react-phone-input-2/lib/style.css';

interface Props {
	question?: TemplateQuestion;
	sections: TemplateSection[];
	allSections: TemplateSection[];
	onUpdateSections: (sections: TemplateSection[]) => void;
}

export default ({
	question,
	sections,
	allSections,
	onUpdateSections,
}: Props) => {
	useEffect(() => {
		onUpdateSections([]);
	}, [question]);

	const handleUpdateSections = (e: ChangeEvent<HTMLInputElement>) => {
		const selectEvent = e as SelectChangeEvent<string[]>;
		const newValue = selectEvent.target.value;

		const selectedSections = allSections.filter((s) => newValue.includes(s.id));
		onUpdateSections(selectedSections);
	};

	const sortedSections = useMemo(
		() => _.sortBy(allSections, 'name'),
		[allSections]
	);

	return (
		<Row>
			<Typography
				variant="subtitle2"
				mb={3.5}
				sx={{ maxWidth: 180, minWidth: 180 }}>
				Show sections:
			</Typography>
			<Row style={{ flex: 1 }}>
				<FormTextField
					select
					required
					fullWidth
					multiple
					disabled={!question}
					inputSx={{ mt: 0, mb: 1 }}
					helperText="Required"
					placeholder="Select Sections"
					value={
						sections.length === 0 ? ['placeholder'] : sections.map((s) => s.id)
					}
					onChange={handleUpdateSections}
					InputProps={{
						sx: {
							'.MuiSelect-select': {
								flex: 1,
								width: '10px',
							},
						},
					}}>
					{sortedSections.map((s) => {
						const questionsForSection = s.features.flatMap((f) => f.questions);

						const disabledForContainingQuestion = questionsForSection.some(
							(q) => q.id === question?.id
						);

						const disabledForUsingQuestionForLogic = s.logic.some(
							(l) =>
								l.trigger_id === question?.id && l.trigger_type === 'question'
						);

						const disabled =
							disabledForContainingQuestion || disabledForUsingQuestionForLogic;

						const tooltipText = disabledForContainingQuestion
							? ' (Selected question belongs to this section)'
							: disabledForUsingQuestionForLogic
								? ' (Selected question is already used in logic for this section)'
								: '';

						return (
							<MenuItem key={s.id} value={s.id} disabled={disabled}>
								{s.name}
								{tooltipText}
							</MenuItem>
						);
					})}
				</FormTextField>
			</Row>
		</Row>
	);
};
