import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import { Utils } from '@rentcheck/biz';
import { Screens } from 'components';
import { Button } from 'components/button';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ModalFlowActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import PermissionGroups from './permission-groups';
import Users from './users';

const validPaths = ['users', 'permission-groups'] as const;
type ValidPathT = (typeof validPaths)[number];

export default () => {
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();

	const pathName = location.pathname.split('/').pop();
	const isValidPath = validPaths.includes(pathName as ValidPathT);
	const [value, setValue] = useState(
		isValidPath ? (pathName as ValidPathT) : 'users'
	);

	const user = useTypedSelector((state) => state.activeProfile);
	const userIsAdmin = Utils.Users.isTeamAdmin(user);

	const handleTabChanged = (
		_event: React.SyntheticEvent,
		newValue: ValidPathT
	) => {
		setValue(newValue);
		history.replace(`/account/teammates/${newValue}`);
	};

	const handleInviteUser = () => {
		dispatch(ModalFlowActions.showInviteUsers({ teams: [] }));
	};

	const handleAddPermissionGroup = () => {
		dispatch(ModalFlowActions.showCreatePermissionGroup({}));
	};

	return (
		<Screens.Container>
			<Screens.Header
				title="Teammates"
				subtitle="Invite new users, customize user permissions, and control user access to your account. "
				actions={[
					value === 'users' ? (
						<Button
							tooltip={
								userIsAdmin ? '' : 'To invite users, you must be an admin'
							}
							disabled={!userIsAdmin}
							onClick={handleInviteUser}
							startIcon={<FontAwesomeIcon icon={regular('plus')} />}>
							Invite User
						</Button>
					) : (
						<Button
							tooltip={
								userIsAdmin
									? ''
									: 'To create permission groups, you must be an admin'
							}
							disabled={!userIsAdmin}
							onClick={handleAddPermissionGroup}
							startIcon={<FontAwesomeIcon icon={regular('plus')} />}>
							Add Permission Group
						</Button>
					),
				]}
			/>
			<TabContext value={value}>
				<TabList
					onChange={handleTabChanged}
					sx={{
						'.MuiTabs-flexContainer': { borderBottom: '1px solid #E0E4EC' },
					}}>
					<Tab label="Users" value="users" />
					<Tab label="Permission Groups" value="permission-groups" />
				</TabList>

				<TabPanel value="users" sx={{ pl: 0, pr: 0 }}>
					<Users />
				</TabPanel>
				<TabPanel value="permission-groups" sx={{ pl: 0, pr: 0 }}>
					<PermissionGroups />
				</TabPanel>
			</TabContext>
		</Screens.Container>
	);
};
