import {
	Autocomplete,
	Card,
	CardContent,
	Checkbox,
	Typography,
} from '@mui/material';
import { Spacer } from 'components';
import FormTextField from 'components/form-text-field';
import { useEffect, useState } from 'react';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { IngestionFilter, IngestionFilterOption } from '../types';

interface Props {
	vendor: string;
	ingestionFilter: IngestionFilter;
	selectedFilterOptions: IngestionFilterOption[];
	setSelectedFilterOptions: (value: IngestionFilterOption[]) => void;
}

export default ({
	selectedFilterOptions,
	setSelectedFilterOptions,
	vendor,
	ingestionFilter,
}: Props) => {
	const subscription = useTypedSelector((state) => state.subscription);

	const [loading, setLoading] = useState(true);
	const [filterOptions, setFilterOptions] = useState<IngestionFilterOption[]>(
		[]
	);

	useEffect(() => {
		ingestionFilter.getFilterOptions().then((result) => {
			setFilterOptions(result);
			setLoading(false);
		});
	}, [subscription]);

	return (
		<Card sx={{ marginBottom: 4 }}>
			<CardContent>
				<Typography variant="subtitle1">
					{ingestionFilter.name} Selection
				</Typography>
				<Spacer height={2} />
				<Typography variant="body2" color="#4D4D4D">
					Control which {vendor} {ingestionFilter.name.toLowerCase()}s to sync
					with RentCheck. These {ingestionFilter.name.toLowerCase()}s will not
					sync until you save and sync.
				</Typography>

				<Spacer height={3} />
				<Autocomplete
					loading={loading}
					multiple
					disableCloseOnSelect
					sx={{ '.MuiAutocomplete-inputRoot': { paddingLeft: 1 } }}
					options={filterOptions}
					getOptionLabel={(o) => o.name}
					value={selectedFilterOptions}
					onChange={(e, v) => setSelectedFilterOptions(v)}
					limitTags={2}
					isOptionEqualToValue={(option, value) => option.id === value.id}
					renderInput={(params) => (
						<FormTextField
							{...params}
							placeholder={`Select ${vendor} ${ingestionFilter.name}s`}
						/>
					)}
					renderOption={(props, option, { selected }) => (
						<li {...props}>
							<Checkbox checked={selected} />
							<Typography>{option.name}</Typography>
						</li>
					)}
				/>
			</CardContent>
		</Card>
	);
};
