import { LoadingButton } from '@mui/lab';
import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
} from '@mui/material';
import { useState } from 'react';

import { Column, FileInput, Spacer } from 'components';
import { useLatchelIntegrations } from 'hooks/latchel-integrations';
import { colors } from 'theme';

interface Props {
	internalLabel: string;
	setLogo: (file?: File) => void;
	nextStep: () => void;
	back: () => void;
	loading: boolean;
}

const TeamLogo = ({
	internalLabel,
	nextStep,
	back,
	setLogo,
	loading,
}: Props) => {
	const { latchelIntegrations } = useLatchelIntegrations();

	const [file, setFile] = useState<File | undefined>(undefined);

	const hasLatchelIntegrations = latchelIntegrations.length > 0;

	return (
		<>
			<DialogTitle>
				<Column>
					Create Team
					<Spacer height={1} />
					<Typography color={colors.gray}>{internalLabel}</Typography>
				</Column>
			</DialogTitle>

			<DialogContent>
				<Column>
					<Typography>
						Add your company logo to your inspection reports by adding it here.
						Your logo will appear at the top right of inspection reports in
						place of the RentCheck logo. It will also be added to RentCheck
						inspection invite and reminder emails. For best results attach your
						logo as a .png or .jpg with a 3:1 aspect ratio (i.e. 360 px wide and
						120 px tall).
					</Typography>
					<Spacer height={6} />

					<Typography variant="h5">Attach logo (Optional)</Typography>
					<Spacer height={3} />

					<FileInput
						setFile={(f) => {
							setFile(f);
							setLogo(f);
						}}
						file={file}
						accept="image/*"
					/>
				</Column>
			</DialogContent>

			<DialogActions>
				<Button
					variant="outlined"
					onClick={() => {
						setLogo(undefined);
						back();
					}}>
					Back
				</Button>

				<LoadingButton
					variant="contained"
					loading={loading}
					onClick={() => {
						setLogo(file);
						nextStep();
					}}>
					{hasLatchelIntegrations ? 'Next' : 'Create Team'}
				</LoadingButton>
			</DialogActions>
		</>
	);
};

export default TeamLogo;
