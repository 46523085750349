import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';

import { Button, PlanGate } from 'components';
import PopoverMenu, { PopoverItemProps } from 'components/PopoverMenu';
import { ModalFlowActions } from 'store/actions';
import { CreateInspectionTemplateMetadata } from 'store/reducers/modal-flows';
import { inspectionTemplateAddonIds } from 'utils/integrations';

export default () => {
	const dispatch = useDispatch();

	const handleCreateTemplate = (
		mode: CreateInspectionTemplateMetadata['mode']
	) => {
		dispatch(ModalFlowActions.showCreateInspectionTemplateModal({ mode }));
	};

	const popoverItems: PopoverItemProps[] = [
		{
			title: 'Unit Inspection Template',
			onClick: () => handleCreateTemplate('unit'),
		},
		{
			title: 'Building Inspection Template',
			onClick: () => handleCreateTemplate('building'),
		},
		{
			title: 'Community Inspection Template',
			onClick: () => handleCreateTemplate('community'),
		},
	];

	return (
		<PlanGate variant="professional" addonIds={inspectionTemplateAddonIds}>
			<PopoverMenu
				options={popoverItems}
				topSpace={1}
				anchorPosition="bottom-right"
				triggerNode={
					<Button
						startIcon={<FontAwesomeIcon icon={solid('plus')} />}
						sx={{ ml: 1 }}>
						Create
					</Button>
				}
			/>
		</PlanGate>
	);
};
