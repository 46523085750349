import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Paper, Table, TableContainer, Typography } from '@mui/material';
import { FlagResponsibility } from '@rentcheck/types';
import { Screens, Spacer } from 'components';
import IconButton from 'components/icon-button';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
	MaintenanceActions,
	ModalFlowActions,
	SnackbarActions,
} from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';
import { DownloadImagesUtils } from 'utils';
import { formatDate } from 'utils/helpers';
import DownloadButton from './download-button';
import FlagsList from './flags-list';
import LinkButton from './link-button';
import ShareButton from './share-button';
import Skeleton from './skeleton';
import TableHeader from './table-header';

export default () => {
	const dispatch: Dispatch = useDispatch();
	const history = useHistory();

	const match = useRouteMatch<{ id: string }>();
	const report = useTypedSelector((state) => {
		return state.maintenance.report?.id === match.params.id
			? state.maintenance.report
			: undefined;
	});

	const [downloadImagesLoading, setDownloadImagesLoading] = useState(false);

	useEffect(() => {
		if (report) {
			return;
		}

		dispatch(MaintenanceActions.reports.getById(match.params.id)).catch((e) =>
			dispatch(SnackbarActions.showError(e.message))
		);
	}, []);

	if (!report) {
		return (
			<Screens.Container>
				<Skeleton />
			</Screens.Container>
		);
	}

	const handleDownloadImages = () => {
		setDownloadImagesLoading(true);
		DownloadImagesUtils.maintenanceReport(report)
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setDownloadImagesLoading(false));
	};

	const handleDelete = () => {
		const body = [
			'Are you sure you want to delete this report?',
			'This action cannot be undone.',
		];

		dispatch(
			ModalFlowActions.showConfirmationModal({
				title: 'Are you sure?',
				body1: body,
				body2: [],
				buttons: [
					ModalFlowActions.cancelButton(dispatch),
					{
						title: 'Delete',
						color: 'error',
						variant: 'contained',
						onClick: deleteReport,
					},
				],
			})
		);
	};

	const deleteReport = async () => {
		dispatch(ModalFlowActions.setConfirmationModalLoading(true));
		dispatch(MaintenanceActions.reports.remove([report.id]))
			.then(() => history.push('/maintenance'))
			.then(() => dispatch(ModalFlowActions.closeConfirmationModal()))
			.catch((e) => dispatch(ModalFlowActions.showErrorModal(e.message)))
			.finally(() =>
				dispatch(ModalFlowActions.setConfirmationModalLoading(false))
			);
	};

	const mappedFlags = report.maintenance_flags.map((mf) => ({
		...mf,
		responsibility: (mf.responsibility ||
			'Uncategorized') as FlagResponsibility,
	}));

	report.organize_by = report.organize_by ?? 'responsibility';

	const flags = _.groupBy(mappedFlags, report.organize_by);
	const flagGroups = _.keys(flags).sort();

	return (
		<Screens.Container>
			<Screens.Header
				showBreadCrumbs
				breadcrumbReplacements={{ [report.id]: report.name }}
				title={report.name}
				subtitle={`Created on ${formatDate(report.created_date)} by ${
					report.created_by.name
				}`}
				actions={[
					<IconButton
						tooltip="Delete"
						icon={regular('trash')}
						onClick={handleDelete}
					/>,
					<LinkButton report={report} />,
					<ShareButton report={report} />,
					<IconButton
						tooltip="Download Images"
						icon={regular('folder-image')}
						onClick={handleDownloadImages}
						loading={downloadImagesLoading}
					/>,
					<DownloadButton report={report} />,
				]}
			/>

			<Typography variant="overline">Maintenance report contents</Typography>
			<Spacer height={8} />

			<TableContainer component={Paper} elevation={0}>
				<Table>
					<TableHeader report={report} />
					{flagGroups.map((group) => (
						<FlagsList key={group} flags={flags[group]} report={report} />
					))}
				</Table>
			</TableContainer>
		</Screens.Container>
	);
};
