import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	TimelineDot as MuiTimelineDot,
	TimelineItem as MuiTimelineItem,
	TimelineConnector,
	TimelineContent,
	TimelineSeparator,
} from '@mui/lab';
import { Typography } from '@mui/material';
import {
	InspectionEventEmailSent,
	InspectionEventSMSReminderSentMetadata,
} from '@rentcheck/types';
import { Column } from 'components/layout/Column';
import { Row } from 'components/layout/Row';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { formatDate } from 'utils/helpers';

import Note from './note';
import SentTo from './sent-to';
import SentToSMS from './sent-to-sms';

interface Props {
	icon: IconDefinition;
	title: string;
	subtitle?: string;
	timestamp: string;
	sentTo?: InspectionEventEmailSent[];
	sentToSMS?: InspectionEventSMSReminderSentMetadata['numbers_sent'];
	note?: string;
	additionalContent?: ReactNode;
}

export default ({
	icon,
	title,
	subtitle,
	timestamp,
	sentTo,
	sentToSMS,
	note,
	additionalContent,
}: Props) => {
	return (
		<TimelineItem>
			<TimelineSeparator>
				<TimelineDot>
					<FontAwesomeIcon icon={icon} />
				</TimelineDot>
				<TimelineConnector />
			</TimelineSeparator>
			<TimelineContent mt={0.75}>
				<Row style={{ alignItems: 'flex-start' }}>
					<Typography
						sx={{ width: 60, marginRight: '16px', marginTop: '1px' }}
						variant="body2"
						color="#4D4D4D">
						{formatDate(timestamp)}
					</Typography>
					<Typography
						variant="subtitle2"
						color="#101010"
						style={{ marginTop: -3 }}>
						{title}
					</Typography>
				</Row>
				<Row>
					<div style={{ width: 76 }} />
					<Column style={{ flex: 1 }}>
						{!!subtitle && (
							<Typography variant="body2" color="#4D4D4D">
								{subtitle}
							</Typography>
						)}

						<Note value={note} />
						<SentTo value={sentTo} />
						<SentToSMS value={sentToSMS} />

						{additionalContent}
					</Column>
				</Row>
			</TimelineContent>
		</TimelineItem>
	);
};

const TimelineItem = styled(MuiTimelineItem)`
	:last-child {
		.MuiTimelineConnector-root {
			display: none;
		}
	}
`;

const TimelineDot = styled(MuiTimelineDot)`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #8f9fb2;
	width: 22px;
	height: 22px;
	box-shadow: none;
`;
