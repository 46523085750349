import { Row, SearchBar } from 'components';
import _ from 'lodash';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/reducers/rootReducer';

const SearchFilter = () => {
	const dispatch = useDispatch();

	const searchFilter = useTypedSelector(
		(state) => state.tenants.filters.search
	);

	const [value, setValue] = useState(searchFilter);

	const debouncedDispatchSearchChange = useCallback(
		_.debounce(
			(value: string) => dispatch({ type: 'SET_TENANTS_SEARCH_FILTER', value }),
			300
		),
		[]
	);

	const handleChange = (value: string) => {
		setValue(value);
		debouncedDispatchSearchChange(value);
	};

	return (
		<Row>
			<SearchBar value={value} onChange={handleChange} />
		</Row>
	);
};

export default SearchFilter;
