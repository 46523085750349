import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Divider, MenuItem, Typography } from '@mui/material';
import { ApiOccupancy } from '@rentcheck/types';
import _ from 'lodash';

import { Column, SpacedRow, Spacer } from 'components';
import IconButton, { IconButtonRef } from 'components/icon-button';
import { useRef } from 'react';
import { formatCurrency, formatDate } from 'utils/helpers';

interface Props {
	occupancy: ApiOccupancy;
	onEdit: (occupancy: ApiOccupancy) => void;
	onDelete: (occupancy: ApiOccupancy) => void;
}

const DataRow = (props: { title: string; value: string }) => {
	const { title, value } = props;

	return (
		<>
			<SpacedRow>
				<Typography color="#666">{title}</Typography>
				<Spacer width={2} />
				<Typography>{value}</Typography>
			</SpacedRow>
			<Spacer height={2} />
		</>
	);
};

export default ({ occupancy, onEdit, onDelete }: Props) => {
	const iconButtonRef = useRef<IconButtonRef>(null);

	const handleEdit = () => {
		iconButtonRef.current?.close();
		onEdit(occupancy);
	};

	const handleDelete = () => {
		iconButtonRef.current?.close();
		onDelete(occupancy);
	};

	return (
		<Column>
			<SpacedRow>
				<Typography variant="h6" fontSize={14}>
					{occupancy.property.address}, {occupancy.property.city}{' '}
					{occupancy.property.zip_code}
				</Typography>
				<IconButton
					ref={iconButtonRef}
					badgeCount={0}
					icon={solid('ellipsis-vertical')}>
					<MenuItem onClick={handleEdit}>Edit</MenuItem>
					<MenuItem onClick={handleDelete}>Delete</MenuItem>
				</IconButton>
			</SpacedRow>

			<Spacer height={4} />

			<DataRow title="Lease status" value={_.capitalize(occupancy.status)} />
			<DataRow title="Lease start" value={formatDate(occupancy.lease_start)} />
			<DataRow title="Lease end" value={formatDate(occupancy.lease_end)} />
			<DataRow title="Move-in" value={formatDate(occupancy.move_in_date)} />
			<DataRow title="Move-out" value={formatDate(occupancy.move_out_date)} />
			<DataRow title="Monthly rent" value={formatCurrency(occupancy.rent)} />
			<DataRow
				title="Security deposit"
				value={formatCurrency(occupancy.security_deposit)}
			/>
			<DataRow
				title="Notice given"
				value={occupancy.tags.includes('notice') ? 'Yes' : 'No'}
			/>
			<DataRow
				title="Eviction"
				value={occupancy.tags.includes('evict') ? 'Yes' : 'No'}
			/>

			<Divider sx={{ marginTop: 3, marginBottom: 3 }} />
		</Column>
	);
};
