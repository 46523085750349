import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
	ButtonBase,
	Card,
	CardContent,
	CardHeader,
	CardMedia,
	DialogContent,
	Grid,
	Typography,
} from '@mui/material';
import { ApiInspectionTemplateDigest } from '@rentcheck/types';
import assets from 'assets';
import { DialogTitle, Row } from 'components';
import { useCreateInspectionTemplateModalData } from 'hooks/modals';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ModalFlowActions } from 'store/actions';
import { Dispatch } from 'types';
import SelectBaseTemplate from './select-base-template';
import TemplateSettings from './template-settings';

export default () => {
	const dispatch = useDispatch<Dispatch>();

	const modalData = useCreateInspectionTemplateModalData();
	const mode = modalData?.metadata?.mode;
	const metadataTemplate = modalData?.metadata?.template;
	const metadataBaseTemplate = modalData?.metadata?.baseTemplate;

	const [scratchOrClone, setScratchOrClone] = useState<'scratch' | 'clone'>();
	const [baseTemplate, setBaseTemplate] =
		useState<ApiInspectionTemplateDigest>();

	useEffect(() => {
		/**
		 * If baseTemplate is set in the metadata, we are cloning from a template.
		 * If not we need to reset the modal state.
		 */
		if (metadataBaseTemplate) {
			setScratchOrClone('clone');
			setBaseTemplate(metadataBaseTemplate);
		} else {
			setScratchOrClone(undefined);
			setBaseTemplate(undefined);
		}
	}, [metadataBaseTemplate]);

	if (!mode) {
		return null;
	}

	const handleBackFromSettings = () => {
		/**
		 * When pressing back from the settings page, we need to check if we are
		 * cloning from a template or creating a new template from scratch.
		 * If we are cloning from a template, we need to dismiss the modal completely.
		 */
		if (metadataBaseTemplate) {
			dispatch(ModalFlowActions.closeCurrentModal());
			return;
		}

		setBaseTemplate(undefined);
		setScratchOrClone(undefined);
	};

	const handleBackForEditing = () => {
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	if (metadataTemplate) {
		/**
		 * Editing a template so we just open the settings page
		 * and clicking back closes the modal
		 */
		return <TemplateSettings onBack={handleBackForEditing} />;
	}

	if (scratchOrClone === 'scratch') {
		/**
		 * Creating a new template, back will be handled by unsetting
		 * `scratchOrClone`.
		 */
		return <TemplateSettings onBack={handleBackFromSettings} />;
	}

	if (scratchOrClone === 'clone') {
		/**
		 * Cloning from a template
		 * Back will be handled by unsetting `scratchOrClone` and `baseTemplate`
		 * If `baseTemplate` is set, we will show a prepopulated template settings
		 * modal, otherwise we show a modal with templates to select from
		 */
		if (baseTemplate) {
			return (
				<TemplateSettings
					onBack={handleBackFromSettings}
					baseTemplate={baseTemplate}
				/>
			);
		}

		return (
			<SelectBaseTemplate
				onSelect={setBaseTemplate}
				onBack={handleBackFromSettings}
			/>
		);
	}

	const handleStartFromScratch = () => {
		setScratchOrClone('scratch');
	};

	const handleStartFromTemplate = () => {
		setScratchOrClone('clone');
	};

	const handleClose = () => {
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	return (
		<>
			<DialogTitle
				title="Create Inspection Template"
				icon={solid('folder-plus')}
				onClose={handleClose}
			/>
			<DialogContent>
				<Row style={{ marginBottom: 30 }}>
					<Grid container columns={{ xs: 1, sm: 2, xl: 2 }} spacing={2}>
						<Grid item xs={1}>
							<ButtonBase onClick={handleStartFromScratch}>
								<Card>
									<CardHeader title="Start from scratch" />
									<CardMedia
										component="img"
										image={assets.inspectionTemplates.fromScratch}
									/>
									<CardContent>
										<Typography color="text.secondary" align="left">
											Create a brand new inspection template, tailoring it to
											your specific use case and configuration needs.
										</Typography>
									</CardContent>
								</Card>
							</ButtonBase>
						</Grid>
						<Grid item xs={1}>
							<ButtonBase onClick={handleStartFromTemplate}>
								<Card>
									<CardHeader title="Start from a template" />
									<CardMedia
										component="img"
										image={assets.inspectionTemplates.fromTemplate}
									/>
									<CardContent>
										<Typography color="text.secondary" align="left">
											Save time by beginning with one of RentCheck's standard
											inspection templates or a previously crafted template.
										</Typography>
									</CardContent>
								</Card>
							</ButtonBase>
						</Grid>
					</Grid>
				</Row>
			</DialogContent>
		</>
	);
};
