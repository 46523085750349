import { ApiUser } from '@rentcheck/types';
import _ from 'lodash';
import { signOut } from 'store/actions/auth-actions';
import { Dispatch } from 'types';

const handleDeeplink = (
	deeplinkPath: string,
	dispatch: Dispatch,
	profile?: ApiUser
) => {
	const components = deeplinkPath.split('/');

	if (_.first(components) === '') {
		components.shift();
	}

	const firstComponent = components.shift();

	if (firstComponent === 'login') {
		const response = handleLoginDeeplink(components, dispatch, profile);
		return response;
	}

	return { redirect: `/${deeplinkPath}` };
};

const handleLoginDeeplink = (
	deeplinkComponents: string[],
	dispatch: Dispatch,
	profile?: ApiUser
) => {
	const email = deeplinkComponents.shift();

	if (!email) {
		return;
	}

	if (profile?.email === email) {
		return;
	}

	const password = deeplinkComponents.shift();

	dispatch(signOut());

	return {
		redirect: `/signin?email=${encodeURIComponent(
			email
		)}&password=${encodeURIComponent(password ?? '')}`,
	};
};

export default {
	handleDeeplink,
};
