import { Typography } from '@mui/material';

import Filters from './filters';
import List from './list';

export default () => {
	return (
		<>
			<Typography color="#4d4d4d">
				Invite users and manage their access in RentCheck
			</Typography>
			<Filters />
			<List />
		</>
	);
};
