import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import SegmentChip from 'components/segment-chip';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/reducers/rootReducer';

export default () => {
	const dispatch = useDispatch();

	const filters = useTypedSelector((state) => state.inspections.filters);

	const handleClick = () => {
		dispatch({ type: 'RESET_INSPECTION_FILTERS' });
	};

	const selected =
		!filters.inspectionStatus?.value.length &&
		!filters.team?.value.length &&
		!filters.inspectionTemplate?.value.length &&
		(filters.ratings ?? []).length === 0 &&
		filters.assignedTo.length === 0 &&
		!filters.dueDateRange &&
		!filters.completedDateRange &&
		!filters.archived;

	return (
		<SegmentChip
			variant="filled"
			icon={solid('inbox')}
			label="All"
			title="All"
			onClick={handleClick}
			color="primary"
			selected={selected}
		/>
	);
};
