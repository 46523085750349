import { MaintenanceFlag } from '@rentcheck/types';

import { Divider, Typography } from '@mui/material';
import { Spacer } from 'components';
import { useEffect, useState } from 'react';

import { workOrderDefaultDescription } from 'utils/helpers';
import { OkToEnterT, PriorityT, WorkOrderInfo } from '../../common';
import Description from './description';
import Flags from './flags';
import OkToEnter from './ok-to-enter';
import Priority from './priority';

interface Props {
	skill: string;
	index: number;
	flags: MaintenanceFlag[];
	isLast: boolean;
	onChange: (value: WorkOrderInfo) => void;
}

export default ({ flags, skill, index, isLast, onChange }: Props) => {
	const [priority, setPriority] = useState<PriorityT>('Normal');
	const [okToEnter, setOkToEnter] = useState<OkToEnterT>('No');
	const [description, setDescription] = useState(
		flags.map(workOrderDefaultDescription).join('\n')
	);

	useEffect(() => {
		onChange({
			priority,
			okToEnter,
			description,
		});
	}, [priority, description, okToEnter]);

	useEffect(() => {
		setDescription(flags.map(workOrderDefaultDescription).join('\n'));
	}, [flags.length]);

	return (
		<>
			<Spacer height={4} />
			<Typography
				variant="subtitle2"
				color="#00000099"
				sx={{ marginBottom: -0.5 }}>
				Work order {index + 1}
			</Typography>
			<Typography variant="h6">{skill}</Typography>

			<Spacer height={4} />

			<Description value={description} setValue={setDescription} />
			<Flags value={flags} />
			<OkToEnter value={okToEnter} setValue={setOkToEnter} />
			<Priority value={priority} setValue={setPriority} />
			{!isLast && <Divider />}
		</>
	);
};
