import { ApiInspectionWithTemplate } from '@rentcheck/types';
import { FeatureWithMetadata } from 'store/reducers/features';
import { useIsMobileBreakpoint } from 'utils/hooks';

import { useDispatch } from 'react-redux';
import { ModalFlowActions } from 'store/actions';
import Md from './md';
import Xs from './xs';

interface Props {
	feature: FeatureWithMetadata;
	inspection: ApiInspectionWithTemplate;
	allFeatures: FeatureWithMetadata[];
}

const FeatureRow = ({ feature, inspection, allFeatures }: Props) => {
	const isSmallerThanMd = useIsMobileBreakpoint('md');
	const dispatch = useDispatch();

	const handleClick = () => {
		dispatch(
			ModalFlowActions.showFeatureDetails({
				inspection,
				features: allFeatures,
				initialFeature: feature,
			})
		);
	};

	if (isSmallerThanMd) {
		return (
			<Xs feature={feature} inspection={inspection} onClick={handleClick} />
		);
	}

	return <Md feature={feature} inspection={inspection} onClick={handleClick} />;
};

export default FeatureRow;
