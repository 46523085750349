import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Checkbox,
	InputAdornment,
	Radio,
	TextField,
	Typography,
} from '@mui/material';
import { Row as RowDiv } from 'components';
import styled from 'styled-components';

interface TitleProps {
	title: string;
}

const Title = ({ title }: TitleProps) => {
	return (
		<Typography color="#445567" fontSize={12} sx={{ margin: '8px 24px' }}>
			{title}
		</Typography>
	);
};

interface SearchBarProps {
	placeholder: string;
	value: string;
	setValue: (value: string) => void;
}

const SearchBar = ({ placeholder, value, setValue }: SearchBarProps) => {
	return (
		<div style={{ padding: '4px 24px 8px' }}>
			<TextField
				value={value}
				placeholder={placeholder}
				onChange={(e) => setValue(e.target.value)}
				InputProps={{
					style: { height: 36 },
					endAdornment: (
						<InputAdornment position="end">
							<FontAwesomeIcon icon={regular('search')} />
						</InputAdornment>
					),
				}}
			/>
		</div>
	);
};

interface SelectableRowProps {
	title: string;
	selected: boolean;
	onClick: () => void;
	variant: 'radio' | 'checkbox';
}

const SelectableRow = ({
	title,
	selected,
	onClick,
	variant,
}: SelectableRowProps) => {
	const StyledRow = styled(RowDiv)<{ selected: boolean }>`
		padding: 0 48px 0 14px;
		background-color: ${({ selected }) => (selected ? '#2D3CE60D' : '#FFF')};
		cursor: pointer;

		transition: background-color 0.3s;

		:hover {
			background-color: ${({ selected }) =>
				selected ? '#2D3CE61D' : '#2D3CE609'};
		}

		:last-child {
			margin-bottom: 8px;
		}
	`;

	return (
		<StyledRow selected={selected} onClick={onClick}>
			{variant === 'radio' && <Radio size="small" checked={selected} />}
			{variant === 'checkbox' && <Checkbox size="small" checked={selected} />}
			<Typography>{title}</Typography>
		</StyledRow>
	);
};

interface RowProps {
	title: string;
	onClick: () => void;
}

const Row = ({ title, onClick }: RowProps) => {
	const StyledRow = styled(RowDiv)`
		padding: 7.5px 64px 7.5px 24px;
		background-color: #fff;
		cursor: pointer;

		transition: background-color 0.3s;

		:hover {
			background-color: #2d3ce609;
		}

		:last-child {
			margin-bottom: 8px;
		}
	`;

	return (
		<StyledRow onClick={onClick}>
			<Typography>{title}</Typography>
		</StyledRow>
	);
};

export default { Title, SearchBar, SelectableRow, Row };
