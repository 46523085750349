import { MaintenanceFlag, TemplateQuestion } from '@rentcheck/types';

import { Column } from 'components';
import { CSSProperties, RefObject } from 'react';
import { FeatureWithMetadata } from 'store/reducers/features';
import { EditFeatureMetadata } from 'store/reducers/modal-flows';
import { FeatureImageViewerRef } from '../../image/image';
import Details from './details';
import Flags from './flags';

interface Props {
	style?: CSSProperties;
	feature: FeatureWithMetadata;
	fullWidth?: boolean;
	hoveredImage?: string;
	setHoveredQuestion: (question?: TemplateQuestion) => void;
	setHoveredFlag: (flag?: MaintenanceFlag) => void;
	maxWidth?: number;
	mode: EditFeatureMetadata['mode'];
	mediaUri?: string;
	imageViewerRef?: RefObject<FeatureImageViewerRef>;
}

export default ({
	mode,
	style = {},
	feature,
	fullWidth,
	hoveredImage,
	setHoveredQuestion,
	setHoveredFlag,
	maxWidth = 450,
	mediaUri,
	imageViewerRef,
}: Props) => {
	return (
		<Column
			style={{
				border: '1px solid #0000001F',
				borderRadius: 4,
				width: fullWidth ? '100%' : maxWidth,
				maxHeight: 'calc(100% - 2px)',
				overflowY: 'scroll',
				...style,
			}}>
			<Details
				feature={feature}
				hoveredImage={hoveredImage}
				setHoveredQuestion={setHoveredQuestion}
				mode={mode}
				mediaUri={mediaUri}
				imageViewerRef={imageViewerRef}
			/>
			<Flags feature={feature} setHoveredFlag={setHoveredFlag} />
		</Column>
	);
};
