import { combineReducers } from 'redux';
import reportsReducer, { State as ReportsState } from './reports';
import workOrdersReducer, { State as WorkOrdersState } from './work-orders';

export type State = {
	reports: ReportsState;
	workOrders: WorkOrdersState;
};

const reducer = combineReducers({
	reports: reportsReducer,
	workOrders: workOrdersReducer,
});

export default reducer;
