import { useDispatch } from 'react-redux';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Typography } from '@mui/material';

import {
	TemplateFeature,
	TemplateQuestion,
	TemplateQuestionAnswer,
	TemplateSection,
} from '@rentcheck/types';

import { Column } from 'components';
import { ModalFlowActions } from 'store/actions';

import { createQuestion } from './helpers';
import QuestionCard from './question-card';
import { containsIncompleteFeatures } from '../../helpers';

interface Props {
	editing: boolean;
	sections: TemplateSection[];
	feature: TemplateFeature;
	onUpdateFeature: (feature: TemplateFeature) => void;
	onUpdateSection: (section: TemplateSection) => void;
}

export default ({
	editing,
	feature,
	sections,
	onUpdateFeature,
	onUpdateSection,
}: Props) => {
	const dispatch = useDispatch();

	if (!editing && !feature.questions.length) {
		return null;
	}

	const handleDragEnd = (result: DropResult) => {
		/**
		 * feature was dropped outside the list, don't do anything
		 */
		if (!result.destination) {
			return;
		}

		/**
		 * Remove feature from array then re-insert at the
		 * correct location
		 */
		const reordered = Array.from(feature.questions);
		const [removed] = reordered.splice(result.source.index, 1);
		reordered.splice(result.destination.index, 0, removed);

		onUpdateFeature({ ...feature, questions: reordered });
	};

	const insertQuestion = (
		question: TemplateQuestion,
		after?: TemplateQuestion
	) => {
		const indexToInsert = after
			? feature.questions.findIndex((f) => f.id === after.id) + 1
			: 0;

		/**
		 * Need this auxiliary variable because splice returns the
		 * deleted elements, not the updated array
		 */
		const updatedQuestions = [...feature.questions];
		updatedQuestions.splice(indexToInsert, 0, question);

		onUpdateFeature({
			...feature,
			questions: updatedQuestions,
		});
	};

	const handleAddQuestion = (after?: TemplateQuestion) => {
		return insertQuestion(createQuestion('yes/no'), after);
	};

	const handleCopyQuestion = (question: TemplateQuestion) => {
		const newQuestion: TemplateQuestion = {
			...question,
			id: uuid(),
			title: `${question.title} - COPY`,
			answers: question.answers.map((a) => ({
				...a,
				id: uuid(),
			})),
		};

		return insertQuestion(newQuestion, question);
	};

	const handleUpdateQuestion = (question: TemplateQuestion) => {
		for (const section of sections) {
			for (const feature of section.features) {
				const foundLogic = feature.logic.find(
					(l) => l.trigger_id === question.id
				);
				if (!foundLogic) continue;

				foundLogic.condition_value = foundLogic.condition_value.filter((v) =>
					question.answers.find((a) => a.title === v)
				);

				feature.logic = feature.logic.map((l) =>
					l.id === foundLogic.id ? foundLogic : l
				);

				onUpdateSection({
					...section,
					features: section.features.map((f) =>
						f.id === feature.id ? feature : f
					),
				});
			}
		}

		onUpdateFeature({
			...feature,
			questions: feature.questions.map((q) =>
				q.id === question.id ? question : q
			),
		});
	};

	const handleDeleteQuestion = (
		question: TemplateQuestion,
		featuresWithLogic: TemplateFeature[]
	) => {
		for (const section of sections) {
			for (const featureWithLogic of featuresWithLogic) {
				const foundFeature = section.features.find(
					(f) => f.id === featureWithLogic.id
				);
				if (!foundFeature) continue;

				const foundLogic = foundFeature.logic.find(
					(l) => l.trigger_id === question.id
				);
				if (!foundLogic) continue;

				foundFeature.logic = foundFeature.logic.filter(
					(l) => l.trigger_id !== question.id
				);

				onUpdateSection({
					...section,
					features: section.features.map((f) =>
						f.id === foundFeature.id ? foundFeature : f
					),
				});
			}
		}

		onUpdateFeature({
			...feature,
			questions: feature.questions.filter((q) => q.id !== question.id),
		});
	};

	const handleConfirmDeleteQuestion = (question: TemplateQuestion) => {
		const sectionsWithLogic = sections.filter((s) =>
			s.logic.some((l) => l.trigger_id === question.id)
		);

		const sectionFeatures = _.flatten(sections.map((s) => s.features));
		const featuresWithLogic = sectionFeatures.filter((f) =>
			f.logic.some((l) => l.trigger_id === question.id)
		);

		if (sectionsWithLogic.length === 0 && featuresWithLogic.length === 0) {
			handleDeleteQuestion(question, featuresWithLogic);
			return;
		}

		dispatch(
			ModalFlowActions.showConfirmationModal({
				title: 'Are you sure?',
				icon: solid('trash'),
				body1: ['Deleting this question will delete added logic'],
				body2: [
					sectionsWithLogic.map((s) => s.name).join(', '),
					featuresWithLogic.map((f) => f.name).join(', '),
				],
				buttons: [
					{
						title: 'Cancel',
						onClick: () => {
							dispatch(ModalFlowActions.closeConfirmationModal());
						},
					},
					{
						title: 'Delete',
						color: 'error',
						onClick: () => {
							dispatch(ModalFlowActions.closeConfirmationModal());
							handleDeleteQuestion(question, featuresWithLogic);
						},
					},
				],
			})
		);
	};

	const handleDeleteAnswer = (
		sectionsWithLogic: TemplateSection[],
		featuresWithLogic: TemplateFeature[],
		question: TemplateQuestion,
		answer: TemplateQuestionAnswer
	) => {
		const updatedAnswers = question.answers.filter((a) => a.id !== answer.id);

		/**
		 * Remove the answer from section logic
		 */
		for (const section of sectionsWithLogic) {
			/**
			 * Remove the answer from the condition_value array
			 */
			for (const logic of section.logic) {
				if (logic.trigger_id !== question.id) {
					continue;
				}

				logic.condition_value = logic.condition_value.filter(
					(a) => a !== answer.title
				);
			}

			/**
			 * remove all logic with no/empty condition_values
			 */
			const validSectionLogic = section.logic.filter(
				(l) => l.condition_value.length
			);

			section.logic = validSectionLogic;

			onUpdateSection(section);
		}

		for (const section of sections) {
			for (const featureWithLogic of featuresWithLogic) {
				const foundFeature = section.features.find(
					(f) => f.id === featureWithLogic.id
				);
				if (!foundFeature) continue;

				const foundLogic = foundFeature.logic.find(
					(l) => l.trigger_id === question.id
				);
				if (!foundLogic) continue;

				foundLogic.condition_value = foundLogic.condition_value.filter(
					(a) => a !== answer.title
				);

				foundFeature.logic = foundFeature.logic.filter(
					(l) => l.condition_value.length
				);

				onUpdateSection({
					...section,
					features: section.features.map((f) =>
						f.id === foundFeature.id ? foundFeature : f
					),
				});
			}
		}

		handleUpdateQuestion({
			...question,
			answers: updatedAnswers,
		});
	};

	const handleConfirmDeleteAnswer = (
		question: TemplateQuestion,
		answer: TemplateQuestionAnswer
	) => {
		const sectionsWithLogic = sections.filter((s) =>
			s.logic.some(
				(l) =>
					l.trigger_id === question.id &&
					l.condition_value.includes(answer.title)
			)
		);

		const sectionFeatures = _.flatten(sections.map((s) => s.features));
		const featuresWithLogic = sectionFeatures.filter((f) =>
			f.logic.some(
				(l) =>
					l.trigger_id === question.id &&
					l.condition_value.includes(answer.title)
			)
		);

		if (sectionsWithLogic.length === 0 && featuresWithLogic.length === 0) {
			handleDeleteAnswer(
				sectionsWithLogic,
				featuresWithLogic,
				question,
				answer
			);
			return;
		}

		dispatch(
			ModalFlowActions.showConfirmationModal({
				title: 'Are you sure?',
				icon: solid('trash'),
				body1: ['Deleting this answer will delete added logic'],
				body2: [
					...sectionsWithLogic.map(
						(s) => `${s.name}: ${feature.name}: ${question.title}\n\n`
					),
					...featuresWithLogic.map((f) => `${f.name}: ${question.title}\n\n`),
				],

				buttons: [
					{
						title: 'Cancel',
						onClick: () => {
							dispatch(ModalFlowActions.closeConfirmationModal());
						},
					},
					{
						title: 'Delete',
						color: 'error',
						onClick: () => {
							dispatch(ModalFlowActions.closeConfirmationModal());
							handleDeleteAnswer(
								sectionsWithLogic,
								featuresWithLogic,
								question,
								answer
							);
						},
					},
				],
			})
		);
	};

	return (
		<Column style={{ marginTop: 16 }}>
			<Typography variant="overline" mb={2}>
				Questions
			</Typography>
			{!feature.questions.length && (
				<div>
					<Button
						disabled={containsIncompleteFeatures(sections)}
						variant="outlined"
						onClick={() => handleAddQuestion()}
						startIcon={<FontAwesomeIcon icon={regular('plus')} />}>
						Add Question
					</Button>
				</div>
			)}
			<DragDropContext onDragEnd={handleDragEnd}>
				<Droppable droppableId="droppable">
					{(provided) => (
						<div {...provided.droppableProps} ref={provided.innerRef}>
							{feature.questions.map((q, index) => (
								<QuestionCard
									index={index}
									key={q.id}
									question={q}
									feature={feature}
									editing={editing}
									onAdd={handleAddQuestion}
									onCopy={handleCopyQuestion}
									onDelete={handleConfirmDeleteQuestion}
									onUpdate={handleUpdateQuestion}
									onDeleteAnswer={handleConfirmDeleteAnswer}
								/>
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</Column>
	);
};
