import { ImageMetadata } from '@rentcheck/types';
import firebase from 'config/fbConfig';
import _ from 'lodash';
import { objectsFromQuerySnapshot } from './helpers';

export const getForFeatureAndImage = (featureId: string, imageKey: string) => {
	return firebase
		.firestore()
		.collection('image_metadata')
		.where('inspection_feature.id', '==', featureId)
		.where('inspection_feature.image_key', '==', imageKey)
		.get()
		.then((result) => objectsFromQuerySnapshot<ImageMetadata>(result))
		.then((result) => _.first(result));
};

export const getForInspection = (inspectionId: string) => {
	return firebase
		.firestore()
		.collection('image_metadata')
		.where('inspection_feature.inspection_id', '==', inspectionId)
		.get()
		.then((result) => objectsFromQuerySnapshot<ImageMetadata>(result));
};
