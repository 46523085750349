import { UploadType } from '../components/UploadEmailModal/UploadModalText';
import fbConfig from '../config/fbConfig';
import { getBase64 } from '../utils/helpers';

export const sendUploadEmail = async (
	userName: string,
	file: File,
	type: UploadType
) => {
	const sendEmailFunction = fbConfig
		.functions()
		.httpsCallable('modelCallableSendUploadEmail');

	return getBase64(file).then((convertedFile) =>
		sendEmailFunction({ userName, orgName: '', convertedFile, type })
			.then(() => true)
			.catch(() => false)
	);
};
