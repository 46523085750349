import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Rules } from '@rentcheck/biz';
import { StorageApi } from 'api';
import { CenteredRow } from 'components';
import {
	DamageButton,
	FlagButton,
	RevisionButton,
	RevisionNotesButton,
} from 'components/features';
import IconButton from 'components/icon-button';
import { useFeatureImageModalData } from 'hooks/modals';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FeatureActions } from 'store/actions';
import { FeatureWithMetadata } from 'store/reducers/features';
import { Dispatch } from 'types';
import {
	downloadCORSInhibitedBlob,
	isEmbeddedInMobileApp,
} from 'utils/helpers';
import { ViewerProps } from '../common';

interface Props {
	imageUri: string;
	feature: FeatureWithMetadata;
	handleZoomIn?: () => void;
	handleZoomOut?: () => void;
	mode?: ViewerProps['mode'];
}

export default ({
	imageUri,
	feature,
	handleZoomIn,
	handleZoomOut,
	mode = 'view',
}: Props) => {
	const dispatch: Dispatch = useDispatch();

	const modalData = useFeatureImageModalData();
	const inspection = modalData?.metadata?.inspection;

	const [loadingRotateLeft, setLoadingRotateLeft] = useState(false);
	const [loadingRotateRight, setLoadingRotateRight] = useState(false);
	const [loadingDownload, setLoadingDownload] = useState(false);

	const handleDownload = () => {
		setLoadingDownload(true);

		StorageApi.getDownloadUrl(imageUri)
			.then(downloadCORSInhibitedBlob)
			.finally(() => setLoadingDownload(false));
	};

	const handleRotateLeft = () => {
		setLoadingRotateLeft(true);
		dispatch(FeatureActions.rotatePhoto(feature.id, imageUri, 270)).finally(
			() => setLoadingRotateLeft(false)
		);
	};

	const handleRotateRight = () => {
		setLoadingRotateRight(true);
		dispatch(FeatureActions.rotatePhoto(feature.id, imageUri, 90)).finally(() =>
			setLoadingRotateRight(false)
		);
	};

	if (!inspection) {
		return null;
	}

	const controlsDisabled = mode === 'edit';
	const loadingRotate = loadingRotateRight || loadingRotateLeft;

	return (
		<CenteredRow style={{ marginBottom: 12 }}>
			{!!handleZoomOut && (
				<IconButton
					icon={regular('magnifying-glass-minus')}
					onClick={handleZoomOut}
					tooltip="Zoom Out"
					disabled={controlsDisabled}
				/>
			)}

			{!!handleZoomIn && (
				<IconButton
					icon={regular('magnifying-glass-plus')}
					onClick={handleZoomIn}
					tooltip="Zoom In"
					disabled={controlsDisabled}
				/>
			)}
			{!isEmbeddedInMobileApp() && (
				<IconButton
					icon={regular('download')}
					onClick={handleDownload}
					tooltip="Download Media"
					loading={loadingDownload}
					disabled={controlsDisabled}
				/>
			)}
			<RevisionNotesButton
				feature={feature}
				inspection={inspection}
				disabled={controlsDisabled}
			/>
			<RevisionButton
				feature={feature}
				inspection={inspection}
				disabled={controlsDisabled}
			/>
			<FlagButton
				feature={feature}
				inspection={inspection}
				image={imageUri}
				disabled={controlsDisabled}
			/>
			<DamageButton
				feature={feature}
				image={imageUri}
				disabled={controlsDisabled}
			/>
			{Rules.Inspections.canPerformFeatureActions(inspection) &&
				Rules.Features.canRotateImage(imageUri) && (
					<>
						<IconButton
							loading={loadingRotateLeft}
							icon={regular('rotate-left')}
							onClick={handleRotateLeft}
							tooltip="Rotate Left"
							disabled={controlsDisabled || loadingRotate}
						/>
						<IconButton
							loading={loadingRotateRight}
							icon={regular('rotate-right')}
							onClick={handleRotateRight}
							tooltip="Rotate Right"
							disabled={controlsDisabled || loadingRotate}
						/>
					</>
				)}
		</CenteredRow>
	);
};
