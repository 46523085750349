import { useCallback } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { ButtonBase, Grid, Typography } from '@mui/material';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Feature } from '@rentcheck/types';

import { StorageApi } from 'api';
import { CenteredColumn, Spacer } from 'components';
import { UploadResult } from 'api/storage';
import { SnackbarActions } from 'store/actions';

interface Props {
	feature: Feature;
	featurePayload: Partial<Feature>;
	setFeaturePayload: (payload: Partial<Feature>) => void;
}

// Using a container here to always have access to the most recent
// feature Payload in the updatePayloadImages function, otherwise
// we only get a snapshot of the payload from when the function was
// called
const payloadContainer: { payload: Partial<Feature> } = { payload: {} };

const AddImage = ({ feature, featurePayload, setFeaturePayload }: Props) => {
	const dispatch = useDispatch();

	payloadContainer.payload = featurePayload;

	const updatePayloadImages = useCallback(
		(result: UploadResult) => {
			const featurePayload = payloadContainer.payload;

			const updatedImages = (featurePayload.images ?? [])
				.filter((i) => !result.fail.find((f) => `upload://${f.fileName}` === i))
				.map((i) => {
					const successResult = result.success.find(
						(s) => `upload://${s.fileName}` === i
					);

					if (!successResult) {
						return i;
					}

					return successResult.gcpPath;
				});

			setFeaturePayload({ ...featurePayload, images: updatedImages });

			if (result.fail.length) {
				dispatch(
					SnackbarActions.showError(
						'There was an error upload some of your images. Please try again.'
					)
				);
			}
		},
		[featurePayload]
	);

	const handleUploads = async (acceptedFiles: File[]) => {
		StorageApi.uploadInspectionImages(acceptedFiles, feature)
			.then(updatePayloadImages)
			.catch(() => dispatch(SnackbarActions.showError()));
	};

	const handleDrop = (acceptedFiles: File[]) => {
		const paths = acceptedFiles.map((af) => `upload://${af.name}`);
		setFeaturePayload({
			...featurePayload,
			images: _.concat(featurePayload.images ?? [], ...paths),
		});

		setTimeout(() => handleUploads(acceptedFiles), 300);
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop: handleDrop,
		accept: {
			'image/png': ['.png'],
			'image/jpeg': ['.jpg', '.jpeg'],
		},
	});

	return (
		<Grid item sx={{ width: '33%' }}>
			<ButtonBase sx={{ width: '100%' }}>
				<CenteredColumn
					{...getRootProps()}
					style={{
						height: 223,
						width: '100%',
						borderRadius: 6,
						cursor: 'pointer',
						justifyContent: 'center',
						backgroundColor: isDragActive ? '#d2d6f8' : '#EFF1F4',
						border: isDragActive ? '2px solid #5e6eff' : '1px solid #AEBBC9',
						transition: 'all .3s',
					}}>
					<input {...getInputProps()} />

					<FontAwesomeIcon
						icon={regular('file-image')}
						color="#445567"
						size="2x"
					/>
					<Spacer height={2} />
					<Typography fontSize={14} color="#445567">
						Add Image
					</Typography>
				</CenteredColumn>
			</ButtonBase>
		</Grid>
	);
};

export default AddImage;
