import { OccupanciesApi, TenantsApi } from '@rentcheck/api-frontend';
import {
	ApiTenant,
	CreateOccupancyParamsData,
	CreateTenantV2Params,
	UpdateOccupancyParamsData,
	UpdateTenantV2Params,
} from '@rentcheck/types';

import _ from 'lodash';
import { State as FiltersState } from 'store/reducers/tenants/filters';
import { ReduxFn } from 'types';

export type TenantActionTypes =
	| 'SIGNOUT_SUCCESS'
	| 'IMPERSONATE'
	| 'SET_TENANT'
	| 'CREATE_TENANTS'
	| 'UPDATE_TENANTS'
	| 'DELETE_TENANT'
	| 'RECIEVE_TENANT'
	| 'RECIEVE_TENANTS'
	| 'APPEND_TENANTS'
	| 'SET_TENANTS_TOTAL';

export const actionTypes: { [index: string]: TenantActionTypes } = {
	SIGNOUT_SUCCESS: 'SIGNOUT_SUCCESS',
	IMPERSONATE: 'IMPERSONATE',
	SET_TENANT: 'SET_TENANT',
	RECIEVE_TENANTS: 'RECIEVE_TENANTS',
	CREATE_TENANTS: 'CREATE_TENANTS',
	UPDATE_TENANTS: 'UPDATE_TENANTS',
	DELETE_TENANT: 'DELETE_TENANT',
	APPEND_TENANTS: 'APPEND_TENANTS',
	SET_TENANTS_TOTAL: 'SET_TENANTS_TOTAL',
};

export const getAll = (
	offset: number = 0,
	filters: FiltersState
): ReduxFn<ApiTenant[]> => {
	const fn: ReduxFn = async (dispatch) => {
		const pageSize = 20;
		const pageNumber = Math.floor((offset ?? 0) / pageSize);

		const { data, total_results } = await TenantsApi.getAll(
			pageNumber,
			pageSize,
			{
				search: filters.search,
				active: filters.active_only,
			},
			{
				sort_by: filters.sortBy,
				sort_type: filters.sortType,
			}
		);

		const mainActionType =
			offset > 0 ? actionTypes.APPEND_TENANTS : actionTypes.RECIEVE_TENANTS;

		dispatch({
			type: mainActionType,
			tenants: data,
		});

		dispatch({
			type: actionTypes.SET_TENANTS_TOTAL,
			value: total_results,
		});

		return data;
	};
	return fn;
};

const sanitizePayload = (
	payload: CreateTenantV2Params['data'] | UpdateTenantV2Params['data']
) => {
	if (payload.phone_numbers && _.isArray(payload.phone_numbers)) {
		/**
		 * Remove all extra characters except digits and plus sign
		 * from phone numbers to comply with the expected format
		 */
		payload.phone_numbers = payload.phone_numbers.map((p) =>
			p.replaceAll(/[^\d\\+]/g, '')
		);
	}
};

export const create = (payload: CreateTenantV2Params['data']) => {
	const fn: ReduxFn = async (dispatch) => {
		sanitizePayload(payload);

		const tenant = await TenantsApi.create(payload);

		tenant &&
			dispatch({
				type: actionTypes.CREATE_TENANTS,
				tenants: [tenant],
			});

		return tenant;
	};

	return fn;
};

export const update = (
	id: UpdateTenantV2Params['residentId'],
	data: UpdateTenantV2Params['data']
) => {
	const fn: ReduxFn = async (dispatch) => {
		sanitizePayload(data);

		const tenant = await TenantsApi.update(id, data);

		tenant &&
			dispatch({
				type: actionTypes.UPDATE_TENANTS,
				tenants: [tenant],
			});

		return tenant;
	};
	return fn;
};

export const remove = (id: string) => {
	const fn: ReduxFn = async (dispatch) => {
		await TenantsApi.remove(id);

		dispatch({
			type: actionTypes.DELETE_TENANT,
			tenants: [{ id } as ApiTenant],
		});
	};

	return fn;
};

export const createOccupancy = (
	tenantId: string,
	payload: CreateOccupancyParamsData
) => {
	const fn: ReduxFn = async (dispatch) => {
		const occupancy = await OccupanciesApi.createOccupancy(
			tenantId,
			payload as any
		);

		const tenant = await TenantsApi.get(occupancy.tenant_id);

		tenant &&
			dispatch({
				type: actionTypes.UPDATE_TENANTS,
				tenants: [tenant],
			});

		return tenant;
	};

	return fn;
};

export const updateOccupancy = (
	tenantId: string,
	occupancyId: string,
	payload: UpdateOccupancyParamsData
) => {
	const fn: ReduxFn = async (dispatch) => {
		const occupancy = await OccupanciesApi.updateOccupancy(
			tenantId,
			occupancyId,
			payload
		);

		const tenant = await TenantsApi.get(occupancy.tenant_id);

		tenant &&
			dispatch({
				type: actionTypes.UPDATE_TENANTS,
				tenants: [tenant],
			});

		return tenant;
	};

	return fn;
};

export const deleteOccupancy = (tenantId: string, occupancyId: string) => {
	const fn: ReduxFn = async (dispatch) => {
		await OccupanciesApi.deleteOccupancy(tenantId, occupancyId);

		const tenant = await TenantsApi.get(tenantId);

		tenant &&
			dispatch({
				type: actionTypes.UPDATE_TENANTS,
				tenants: [tenant],
			});

		return tenant;
	};

	return fn;
};
