import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import { APIBuilding } from '@rentcheck/types';
import {
	Column,
	Row,
	ScreenContentSideBar,
	SpacedRow,
	Spacer,
} from 'components';
import { useState } from 'react';
import styled from 'styled-components';
import { colors, spacingPx } from 'theme';
import EditPropertyModal, { EditBuildingSection } from './edit-property-modal';

interface Props {
	property: APIBuilding;
}

const EditButton = () => (
	<FontAwesomeIcon icon={solid('edit')} color={colors.gray} size="xs" />
);

const Checkmark = () => <FontAwesomeIcon icon={solid('check')} size="xs" />;

const PropertyInformation = ({ property }: Props) => {
	const [editModalVisible, setEditModalVisible] = useState(false);
	const [editModalSection, setEditModalSection] =
		useState<EditBuildingSection>('Property Address');

	const renderRooms = () => {
		const title = 'Amenities & Common Areas';
		const rooms = property.room_names;

		return (
			<Column>
				<EditableRow
					onClick={() => {
						setEditModalSection(title);
						setEditModalVisible(true);
					}}>
					<Typography variant="subtitle2">{title}</Typography>
					<Spacer width={1} />
					<EditButton />
				</EditableRow>
				<Spacer height={1} />
				<InnerContentDetails>
					{rooms.length === 0 && <Typography>None</Typography>}
					{rooms.length > 0 &&
						rooms.map((r) => (
							<RoomRow key={r}>
								<Typography>{r}</Typography>
								<Checkmark />
							</RoomRow>
						))}
				</InnerContentDetails>
				<Spacer height={1} />
			</Column>
		);
	};

	return (
		<ScreenContentSideBar hasTitleRow>
			<InnerContent>
				<Typography variant="subtitle1">Building Information</Typography>
				<InnerContentDetails>{renderRooms()}</InnerContentDetails>
			</InnerContent>

			<HorizontalDivider />

			<InnerContent>
				<Typography variant="subtitle1">Number of Floors</Typography>
				<InnerContentDetails>
					<EditableRow
						onClick={() => {
							setEditModalSection('Floors');
							setEditModalVisible(true);
						}}>
						<Typography>{property.floors}</Typography>
						<Spacer width={2} />
						<Typography>Floors</Typography>
						<Spacer width={2} />
						<EditButton />
					</EditableRow>
				</InnerContentDetails>
			</InnerContent>

			{editModalVisible && (
				<EditPropertyModal
					section={editModalSection}
					property={property}
					setHidden={() => setEditModalVisible(false)}
				/>
			)}
		</ScreenContentSideBar>
	);
};

const InnerContent = styled(Column)`
	padding: ${spacingPx(8)};
`;

const InnerContentDetails = styled(Column)`
	padding: ${spacingPx(2)};
`;

const EditableRow = styled(Row)`
	cursor: pointer;
	align-items: center;
`;

const RoomRow = styled(SpacedRow)`
	margin-bottom: ${spacingPx(2)};
`;

const HorizontalDivider = styled.div`
	background-color: #bfbfbf;
	flex-shrink: 0;
	height: 1px;
	width: auto;
`;

export default PropertyInformation;
