import { Button, Typography } from '@mui/material';
import { ApiInspection, Inspection } from '@rentcheck/types';
import { useState } from 'react';
import styled from 'styled-components';
import { colors, spacingPx } from 'theme';
import { Spacer } from '../../..';
import ComparisonRow from '../Components/ComparisonRow';

interface Props {
	inspections: ApiInspection[];
	setConfirmedInspection: any;
	firstInspection?: ApiInspection;
	secondInspection?: ApiInspection;
	currentStep: string;
	setCurrentStep: () => void;
}

const SelectInspection = ({
	inspections,
	setConfirmedInspection,
	firstInspection,
	secondInspection,
	currentStep,
	setCurrentStep,
}: Props) => {
	const [currentSelection, setCurrentSelection] = useState<ApiInspection>();

	let disabled: ApiInspection | undefined;
	let confirmed: ApiInspection | undefined;

	if (currentStep === 'select_inspection_1') {
		confirmed = firstInspection;
		disabled = secondInspection;
	}

	if (currentStep === 'select_inspection_2') {
		confirmed = secondInspection;
		disabled = firstInspection;
	}

	const determineSelected = (i?: Inspection) => {
		if (i) {
			if (currentSelection === null && confirmed === null) {
				return false;
			}
			if (currentSelection) {
				return i.id === currentSelection.id;
			}
			if (confirmed) {
				return i.id === confirmed.id;
			}
		}
		if (currentSelection === null && confirmed === null) {
			return true;
		}
		return false;
	};

	const renderHeading = () => {
		if (currentStep === 'select_inspection_1') {
			return (
				<>
					<Typography variant="h5">Select Inspection #1</Typography>
					<Spacer height={2} />
					<Typography>
						You'll pick which inspection to compare this to next.
					</Typography>
				</>
			);
		}
		if (currentStep === 'select_inspection_2') {
			return (
				<>
					<Typography variant="h5">Select Inspection #2</Typography>
					<Spacer height={2} />
					<Typography>You'll finalize this comparison next.</Typography>
				</>
			);
		}
	};
	return (
		<>
			{renderHeading()}

			<Spacer height={5} />

			<InspectionsContainer>
				{inspections.map((i: any) => {
					return (
						<ComparisonRow
							inspection={i}
							selected={determineSelected(i)}
							disabled={disabled ? i.id === disabled.id : false}
							setSelectedInspection={(inspection) =>
								setCurrentSelection(inspection)
							}
						/>
					);
				})}
			</InspectionsContainer>
			<Separator />

			<Spacer height={5} />

			<SpacedRow>
				<ButtonContainer left>
					<Button variant="outlined" color="secondary" onClick={setCurrentStep}>
						Back
					</Button>
				</ButtonContainer>

				<ButtonContainer right>
					<Button
						disabled={determineSelected()}
						onClick={() => {
							setConfirmedInspection(currentSelection || confirmed);
							setCurrentStep();
						}}>
						Add to Comparison
					</Button>
				</ButtonContainer>
			</SpacedRow>
		</>
	);
};

const Separator = styled.div`
	width: 100%;
	height: 1px;
	background-color: ${colors.contentBorder};
`;

const InspectionsContainer = styled.div`
	max-height: ${spacingPx(100)};
	overflow: scroll;
	padding-right: ${spacingPx(3)};
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
`;

const SpacedRow = styled(Row)`
	justify-content: space-between;
`;

const ButtonContainer = styled.div<{ right?: boolean; left?: boolean }>`
	display: flex;
	flex-direction: column;
	padding-left: ${({ right }) => (right ? spacingPx(2) : 0)};
	padding-right: ${({ left }) => (left ? spacingPx(2) : 0)};
`;

export default SelectInspection;
