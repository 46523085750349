import { TableCell, TableRow, Typography } from '@mui/material';
import { ApiInspectionWithTemplate } from '@rentcheck/types';
import { Column, Row, SpacedRow } from 'components';
import { FeatureWithMetadata } from 'store/reducers/features';

import Actions from './actions';
import Media from './media';
import Rating from './rating';

interface Props {
	feature: FeatureWithMetadata;
	inspection: ApiInspectionWithTemplate;
	onClick: () => void;
}

export default ({ feature, inspection, onClick }: Props) => {
	return (
		<TableRow
			id={`row-${feature.id}`}
			sx={{
				'&:last-child td, &:last-child th': { border: 0 },
				cursor: 'pointer',
			}}>
			<TableCell>
				<Column onClick={onClick}>
					<Row>
						<Column style={{ marginRight: 32 }}>
							<Typography variant="overline" color="#4d4d4d">
								Area/Room
							</Typography>
							<Typography variant="body2" color="#101010" mt={1}>
								{feature.edited_by_reviewer ? '*' : ''}
								{feature.section.name_with_ordinal}
							</Typography>
						</Column>
						<Column>
							<Typography variant="overline" color="#4d4d4d">
								Feature
							</Typography>
							<Typography variant="body2" color="#101010" mt={1}>
								{feature.name}
							</Typography>
						</Column>
					</Row>

					{!!feature.notes && (
						<>
							<Typography variant="overline" color="#4d4d4d" mt={2}>
								Notes
							</Typography>
							<Typography
								variant="body2"
								color="#101010"
								mt={1}
								sx={{ flex: 1, wordBreak: 'break-word' }}>
								{feature.notes}
							</Typography>
						</>
					)}

					<Media feature={feature} />
				</Column>
				<Column>
					<SpacedRow style={{ marginTop: 16 }} onClick={onClick}>
						<div style={{ flex: 1 }}>
							<Rating feature={feature} />
						</div>
						<Actions feature={feature} inspection={inspection} />
					</SpacedRow>
				</Column>
			</TableCell>
		</TableRow>
	);
};
