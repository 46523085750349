import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuItemProps, Tooltip } from '@mui/material';
import MenuItem from 'components/menu-item';
import { MouseEventHandler } from 'react';

interface Props extends MenuItemProps {
	title: string;
	tooltip?: string;
	icon: IconDefinition;
}

export default ({ title, icon, tooltip = '', ...other }: Props) => {
	const dismissParentMenu = () => {
		const backdrops = document.getElementsByClassName('MuiBackdrop-root');

		for (const backdrop of backdrops) {
			(backdrop as HTMLElement).click();
		}
	};

	const handleClick: MouseEventHandler<HTMLLIElement> = (e) => {
		dismissParentMenu();
		other.onClick?.(e);
	};

	return (
		<Tooltip
			title={tooltip}
			PopperProps={{
				modifiers: [
					{
						name: 'offset',
						options: {
							offset: [50, -16],
						},
					},
				],
			}}>
			<div>
				<MenuItem
					sx={{ padding: '12px 24px 12px 0 ' }}
					{...other}
					onClick={handleClick}>
					<FontAwesomeIcon
						icon={icon}
						color="#2D3CE6"
						style={{ marginRight: 4, fontSize: 16, width: 32 }}
					/>
					{title}
				</MenuItem>
			</div>
		</Tooltip>
	);
};
