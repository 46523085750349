import { Skeleton, TableCell, TableRow } from '@mui/material';

const SkeletonRow = () => {
	return (
		<TableRow style={{ height: 80 }}>
			<TableCell>
				<Skeleton
					sx={{ borderRadius: 4 }}
					variant="rectangular"
					height={30}
					width={30}
				/>
			</TableCell>
			<TableCell>
				<Skeleton
					sx={{ borderRadius: 4 }}
					variant="rectangular"
					width={190}
					height={30}
				/>
			</TableCell>
			<TableCell align="right">
				<Skeleton variant="text" width={110} height={25} />
			</TableCell>
		</TableRow>
	);
};

export default SkeletonRow;
