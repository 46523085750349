import { MenuItem, MenuItemProps, Tooltip } from '@mui/material';

interface Props extends MenuItemProps {
	visible?: boolean;
	tooltip?: string;
}

export default ({ tooltip, visible, ...props }: Props) => {
	if (visible === false) {
		return null;
	}

	if (tooltip) {
		return (
			<Tooltip title={tooltip}>
				<span>
					<MenuItem {...props} />
				</span>
			</Tooltip>
		);
	}

	return <MenuItem {...props} />;
};
