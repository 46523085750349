import { LoadingButton } from '@mui/lab';
import { Card, CardContent, TextField, Typography } from '@mui/material';
import { AdminToolsApi } from '@rentcheck/api-frontend';
import { ApplicationSettings } from '@rentcheck/types';
import { Row, Spacer } from 'components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';
import styled from 'styled-components';
import { useRemoteSettings } from 'utils/hooks';

export default function AdminPanel() {
	const dispatch = useDispatch();

	const { settings: applicationSettings } = useRemoteSettings();

	const [loading, setLoading] = useState(false);
	const [input, setInput] = useState<string>('');

	useEffect(() => {
		setInput(JSON.stringify(applicationSettings.subscription_plans, null, 2));
	}, [applicationSettings]);

	const handleUpdate = async (value: string) => {
		setLoading(true);

		try {
			const data = JSON.parse(
				value
			) as ApplicationSettings['subscription_plans'];

			await AdminToolsApi.updateApplicationSettings({
				subscription_plans: data,
			}).catch((e) => {
				dispatch(SnackbarActions.showError(e));
				return undefined;
			});
		} catch (error) {
			dispatch(SnackbarActions.showError(error as Error));
		} finally {
			setLoading(false);
		}
	};

	return (
		<Card>
			<CardContent>
				<Typography variant="h5">Update Billing Plans</Typography>
				<Spacer height={2} />
				<Typography>Change billing plan JSON:</Typography>
				<Spacer height={2} />

				<FormRow
					onSubmit={(e) => {
						e.preventDefault();
						handleUpdate(input);
					}}>
					<TextField
						fullWidth
						variant="filled"
						type="email"
						placeholder="Email"
						multiline
						maxRows={15}
						value={input}
						onChange={(e) => setInput(e.target.value)}
					/>
					<Spacer width={6} />
					<Row>
						<LoadingButton type="submit" variant="contained" loading={loading}>
							Update
						</LoadingButton>
					</Row>
				</FormRow>
			</CardContent>
		</Card>
	);
}

const FormRow = styled.form`
	display: flex;
	flex-direction: row;
	width: 50%;
	justify-content: space-between;
	text-align: center;
`;
