import { Feature } from '@rentcheck/types';
import * as Sentry from '@sentry/react';
import firebase from 'firebase/app';

export const getDownloadUrl = async (path: string): Promise<string> => {
	const ref = firebase.storage().ref().child(path);
	return ref.getDownloadURL().catch((e) => {
		Sentry.captureException(e);
	});
};

export const uploadInspectionImage = async (
	file: ArrayBuffer,
	feature: Feature,
	fileName: string
): Promise<string> => {
	const path = [
		feature.inspection_id,
		feature.section.name_with_ordinal,
		fileName,
	].join('/');
	const ref = firebase.storage().ref(path);

	return ref
		.put(file)
		.then((result) => {
			return result.ref.fullPath;
		})
		.catch((e) => {
			Sentry.captureException(e);
			throw e;
		});
};

export type UploadResult = {
	success: { fileName: string; gcpPath: string }[];
	fail: { fileName: string }[];
};

export const uploadInspectionImages = async (
	files: File[],
	feature: Feature
): Promise<UploadResult> => {
	const success: { fileName: string; gcpPath: string }[] = [];
	const fail: { fileName: string }[] = [];

	for (const file of files) {
		await readFile(file)
			.then((result) => uploadInspectionImage(result, feature, file.name))
			.then((result) => success.push({ fileName: file.name, gcpPath: result }))
			.catch(() => fail.push({ fileName: file.name }));
	}

	return { success, fail };
};

export const uploadCustomFeatureImage = async (
	file: ArrayBuffer,
	teamId: string,
	featureName: string,
	fileName: string
): Promise<string> => {
	const path = [teamId, featureName, fileName].join('/');
	const ref = firebase.storage().ref(path);

	return ref
		.put(file)
		.then((result) => result.ref.fullPath)
		.catch((e) => {
			Sentry.captureException(e);
			throw e;
		});
};

export const uploadCustomFeatureImages = async (
	teamId: string,
	files: File[],
	featureName: string
) => {
	const filePaths: { name: string; path: string }[] = [];

	for (const file of files) {
		const imageObject: any = file;
		const isNotBlob = !!imageObject.path;
		if (isNotBlob) {
			filePaths.push({ name: imageObject.name, path: imageObject.path });
			continue;
		}

		await readFile(file)
			.then((result) =>
				uploadCustomFeatureImage(result, teamId, featureName, file.name)
			)
			.then((result) => filePaths.push({ name: file.name, path: result }));
	}

	return filePaths;
};

const readFile = async (file: File): Promise<ArrayBuffer> => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.onabort = () =>
			reject(
				new Error(
					'There was an error reading one of your images. Please try again.'
				)
			);

		reader.onerror = () =>
			reject(
				new Error(
					'There was an error reading one of your images. Please try again.'
				)
			);

		reader.onload = () => resolve(reader.result as ArrayBuffer);

		reader.readAsArrayBuffer(file);
	});
};
