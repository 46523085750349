import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip } from '@mui/material';
import { Rules } from '@rentcheck/biz';
import { ApiInspectionWithTemplate } from '@rentcheck/types';
import { FeatureWithMetadata } from 'store/reducers/features';
import FlagsTooltip from '../../common/flags-tooltip';

interface Props {
	feature: FeatureWithMetadata;
	inspection: ApiInspectionWithTemplate;
}

export default ({ feature, inspection }: Props) => {
	if (!Rules.Inspections.canPerformFeatureActions(inspection)) {
		return null;
	}

	if (!feature.maintenance_flags.length) {
		return null;
	}

	return (
		<FlagsTooltip flags={feature.maintenance_flags}>
			<Chip
				style={{ marginTop: 4, marginBottom: 4 }}
				icon={<FontAwesomeIcon icon={solid('flag')} />}
				label={`${feature.maintenance_flags.length}`}
			/>
		</FlagsTooltip>
	);
};
