import { colors } from '@rentcheck/theme';

interface BasicCustomization {
	styles: {
		gradient: string;
		primaryColor: string;
		secondaryColor: string;
	};

	text: string;
}

interface PlanGateCusomizations {
	any: BasicCustomization;
	professional: BasicCustomization;
	essential: BasicCustomization;
}

export const customizations: PlanGateCusomizations = {
	any: {
		styles: {
			gradient: 'linear-gradient(#757CF0, #E9E8FD)',
			primaryColor: colors.primary[900],
			secondaryColor: colors.black,
		},
		text: 'You discovered an essential feature',
	},
	professional: {
		styles: {
			gradient: 'linear-gradient(#232E3A, #0000B3)',
			primaryColor: colors.white,
			secondaryColor: colors.white,
		},

		text: 'You discovered a professional feature',
	},
	essential: {
		styles: {
			gradient: 'linear-gradient(#757CF0, #E9E8FD)',
			primaryColor: colors.primary[900],
			secondaryColor: colors.black,
		},
		text: 'You discovered an essential feature',
	},
};
