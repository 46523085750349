import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import { Inspection as APIInspection } from '@rentcheck/api-frontend';
import { ApiInspection } from '@rentcheck/types';
import { Row, Screens, SpacedRow, Spacer } from 'components';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { InspectionsActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';

interface Props {
	inspection: ApiInspection;
}

interface ComponentProps {
	inspections: APIInspection[];
	index: number;
	total: number;
}

const LeftNavigation = ({ inspections, index }: ComponentProps) => {
	if (index === 0) {
		return <div />;
	}

	const previousInspection = inspections[index - 1];

	return (
		<Link to={`/inspections/${previousInspection.id}`}>
			<Row>
				<FontAwesomeIcon icon={regular('arrow-left')} color="#354351" />
				<Spacer width={2} />
				<Typography color="#354351">Previous Inspection</Typography>
			</Row>
		</Link>
	);
};

const RightNavigation = ({ inspections, index, total }: ComponentProps) => {
	const dispatch: Dispatch = useDispatch();

	const filters = useTypedSelector((state) => state.inspections.filters);

	const needsToFetchMore =
		inspections.length < total && index + 1 === inspections.length;

	useEffect(() => {
		if (needsToFetchMore) {
			dispatch(InspectionsActions.getAll(inspections.length, filters)).catch(
				() => {}
			);
		}
	}, [index, inspections]);

	if (index === total - 1) {
		return <div />;
	}

	if (needsToFetchMore) {
		return <Typography color="#354351">Loading...</Typography>;
	}

	const nextInspection = inspections[index + 1];

	return (
		<Link to={`/inspections/${nextInspection.id}`}>
			<Row>
				<Typography color="#354351">Next Inspection</Typography>
				<Spacer width={2} />
				<FontAwesomeIcon icon={regular('arrow-right')} color="#354351" />
			</Row>
		</Link>
	);
};

export default ({ inspection }: Props) => {
	const { data: inspections, total } = useTypedSelector(
		(state) => state.inspections.inspections
	);

	const inspectionIndex = inspections.findIndex((i) => i.id === inspection.id);

	if (inspectionIndex < 0) {
		return null;
	}

	return (
		<Screens.Container>
			<SpacedRow style={{ marginTop: 20 }}>
				<LeftNavigation
					inspections={inspections}
					index={inspectionIndex}
					total={total}
				/>
				<RightNavigation
					inspections={inspections}
					index={inspectionIndex}
					total={total}
				/>
			</SpacedRow>
		</Screens.Container>
	);
};
