import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Typography } from '@mui/material';
import { Column, Screens, SpacedRow, Spacer } from 'components';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTypedSelector } from 'store/reducers/rootReducer';
import styled from 'styled-components';
import { colors } from 'theme';
import UpdatePassword from './update-password';
import UpdateProfile from './update-profile';

const ProfileScreen = () => {
	const history = useHistory();
	const location = useLocation();

	const profile = useTypedSelector((state) => state.activeProfile);

	if (!profile) {
		return null;
	}

	if (location.pathname === '/account/profile/edit') {
		return <UpdateProfile />;
	}

	if (location.pathname === '/account/profile/updatepassword') {
		return <UpdatePassword />;
	}

	return (
		<Screens.Container>
			<Screens.Header
				title="Profile"
				subtitle="Manage your personal profile email and password."
				showBreadCrumbs
			/>

			<SpacedRow>
				<Column>
					<Typography variant="h5">{profile.name}</Typography>
					<Spacer height={3} />
					<Typography color={colors.primary} fontWeight={600}>
						{profile.email}
					</Typography>
					<Spacer height={1} />
					<Typography fontWeight={600}>{profile.type}</Typography>
					<Spacer height={6} />
					<LinkRow to="/account/profile/updatepassword">
						<Typography color={colors.gray} fontWeight={600}>
							Update password
						</Typography>
						<Spacer width={2} />
						<FontAwesomeIcon
							icon={solid('arrow-circle-right')}
							color={colors.gray}
						/>
					</LinkRow>
				</Column>
				<Column>
					<Button onClick={() => history.push('/account/profile/edit')}>
						Edit Profile
					</Button>
				</Column>
			</SpacedRow>
		</Screens.Container>
	);
};

const LinkRow = styled(Link)`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export default ProfileScreen;
