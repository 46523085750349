import {
	Checkbox,
	TableCell,
	TableHead,
	TableRow,
	TableSortLabel,
} from '@mui/material';

import { MaintenanceFlag } from '@rentcheck/types';

interface Props {
	flags: MaintenanceFlag[];
	selectedIds: string[];
	selectAllIds: () => void;
	setSortBy: (key: keyof MaintenanceFlag) => void;
	sortBy: keyof MaintenanceFlag;
	setSortDirection: (direction: 'asc' | 'desc') => void;
	sortDirection: 'asc' | 'desc';
}

const headers = [
	{ id: 'skill', title: 'Skill' },
	{ id: 'room/feature', title: 'Area/Room/Feature' },
	{ id: 'images', title: 'Images' },
	{ id: 'notes', title: 'Notes' },
	{ id: 'created_by.type', title: 'Created By' },
	{ id: 'responsibility', title: 'Responsibility' },
	{ id: 'cost', title: 'Cost' },
	{ id: 'report', title: 'Report' },
];

const TableHeader = ({
	flags,
	selectedIds,
	selectAllIds,
	setSortBy,
	sortBy,
	setSortDirection,
	sortDirection,
}: Props) => {
	const checked = selectedIds.length === flags.length && selectedIds.length > 0;
	const indeterminate =
		selectedIds.length > 0 && selectedIds.length !== flags.length;

	const switchSortDirection = () => {
		if (sortDirection === 'asc') setSortDirection('desc');
		if (sortDirection === 'desc') setSortDirection('asc');
	};

	const handleSort = (headerId: keyof MaintenanceFlag) => {
		if (headerId === sortBy) return switchSortDirection();
		setSortBy(headerId);
	};

	const notSortable = (h: { id: string; title: string }) => {
		return ['images', 'report', 'notes'].includes(h.id);
	};

	return (
		<TableHead>
			<TableRow>
				<TableCell>
					<Checkbox
						id="checkbox-select_all"
						size="small"
						onChange={selectAllIds}
						indeterminate={indeterminate}
						checked={checked}
					/>
				</TableCell>
				{headers.map((h) => (
					<TableCell key={h.id}>
						<TableSortLabel
							active={sortBy === h.id}
							direction={sortDirection}
							hideSortIcon={notSortable(h)}
							sx={{ textWrap: 'nowrap' }}
							onClick={() => {
								if (notSortable(h)) return;
								handleSort(h.id as keyof MaintenanceFlag);
							}}>
							{h.title}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

export default TableHeader;
