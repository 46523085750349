/* eslint-disable @typescript-eslint/no-explicit-any */
import { InspectionEventsApi } from '@rentcheck/api-frontend';
import {
	ApiUser,
	FirebaseUpdatePayload,
	Inspection,
	InspectionEdited,
} from '@rentcheck/types';
import firebase from 'config/fbConfig';

export const getById = async (id: string): Promise<Inspection> =>
	firebase
		.firestore()
		.collection('inspections')
		.doc(id)
		.get()
		.then((result) => {
			if (result.exists) return result.data() as Inspection;
			throw new Error('Inspection not found');
		});

export const update = async (
	id: string,
	payload: FirebaseUpdatePayload<Inspection>
): Promise<Inspection | undefined> =>
	firebase
		.firestore()
		.collection('inspections')
		.doc(id)
		.update(payload)
		.then(() => getById(id))
		.catch(() => undefined);

export const edited = async (
	id: string,
	user: ApiUser,
	changeset: InspectionEdited
) => {
	InspectionEventsApi.create(id, {
		type: 'inspection_edited',
		metadata: {
			edited_by: user.name,
		},
	});

	return getById(id).then((inspection) =>
		update(id, { edits: [...(inspection.edits ?? []), changeset] })
	);
};

export const getByPropertyId = async (propertyId: string) => {
	const inspections: Inspection[] = [];
	await firebase
		.firestore()
		.collection('inspections')
		.where('propertyID', '==', propertyId)
		.get()
		.then((snapshot) =>
			snapshot.forEach((doc) => inspections.push(doc.data() as Inspection))
		)
		.catch(() => undefined);

	return inspections.sort(
		(a, b) => b.inspection_date.seconds - a.inspection_date.seconds
	);
};
