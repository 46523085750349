import { Grid, MenuItem } from '@mui/material';
import { Utils } from '@rentcheck/biz';
import { Feature } from '@rentcheck/types';
import FormTextField, { FormTextFieldRef } from 'components/form-text-field';
import _ from 'lodash';
import { useMemo, useRef } from 'react';
import { useWindowSize } from 'usehooks-ts';
import { getFeatureMedia, getThumbnailUrl } from 'utils/helpers';
import Image from './image';

interface Props {
	value: string[];
	setValue: (value: string[]) => void;
	feature: Feature;
}

const Cost = ({ value, setValue, feature }: Props) => {
	const textFieldRef = useRef<FormTextFieldRef>(null);
	const windowSize = useWindowSize();

	const width = useMemo(
		() => textFieldRef.current?.getWidth(),
		[textFieldRef.current, windowSize.width]
	);

	const handleChange = (imageSrc: string) => {
		setValue(_.xor(value, [imageSrc]));
	};

	const media = getFeatureMedia(feature).map((imageSrc) =>
		Utils.Media.isVideo(imageSrc) ? getThumbnailUrl(imageSrc) : imageSrc
	);

	if (!media.length) {
		return null;
	}

	return (
		<FormTextField
			select
			title="Attach Media"
			value={['selected']}
			multiple
			ref={textFieldRef}>
			<MenuItem value="selected" sx={{ display: 'none' }}>
				{value.length ? `${value.length} Selected` : 'Select media to attach'}
			</MenuItem>
			<Grid container sx={{ maxHeight: 240, width: width }}>
				{media.map((i) => (
					<Grid item>
						<Image
							src={i}
							onClick={handleChange}
							selectedImages={value}
							feature={feature}
						/>
					</Grid>
				))}
			</Grid>
		</FormTextField>
	);
};

export default Cost;
