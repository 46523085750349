import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Column, Row, Screens, Spacer, TextInput } from 'components';
import { useHistory } from 'react-router-dom';
import { useTypedSelector } from 'store/reducers/rootReducer';
import styled from 'styled-components';
import { isEmailValid } from 'utils/emails';
import ReauthenticateModal from './reauthenticate-modal';

import { LoadingButton } from '@mui/lab';
import { Button, Typography } from '@mui/material';
import { ActiveProfileActions } from 'store/actions';
import { colors } from 'theme';

const EditProfile = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const profile = useTypedSelector((state) => state.activeProfile);

	const [loading, setLoading] = useState(false);
	const [modalVisible, setModalVisible] = useState(false);

	const [name, setName] = useState(profile.name);
	const [email, setEmail] = useState(profile.email);

	const [submissionError, setSubmissionError] = useState('');
	const [nameError, setNameError] = useState<string | undefined>(undefined);
	const [emailError, setEmailError] = useState<string | undefined>(undefined);

	useEffect(() => {
		setNameError(undefined);
	}, [name]);

	useEffect(() => {
		setEmailError(undefined);
	}, [email]);

	const validateForm = () => {
		const nameError = name ? undefined : 'Full name is required';
		const emailError = isEmailValid(email)
			? undefined
			: 'Please enter a valid email';

		setNameError(nameError);
		setEmailError(emailError);

		return !(nameError || emailError);
	};

	const handleSaveAuthenticated = async () => {
		setLoading(true);

		try {
			await dispatch(
				ActiveProfileActions.update({
					name: profile.name !== name ? name : undefined,
					email: profile.email !== email ? email : undefined,
				})
			);

			history.push('/account/profile');
			setModalVisible(false);
			setLoading(false);
		} catch (e) {
			setSubmissionError((e as Error).message);
			setModalVisible(false);
			setLoading(false);
		}
	};

	const handleSave = async () => {
		if (!validateForm()) return;

		if (email != profile.email) {
			setModalVisible(true);
			return;
		}

		handleSaveAuthenticated();
	};

	if (!profile) {
		return null;
	}

	return (
		<Screens.Container>
			<Screens.Header
				title="Edit Profile"
				subtitle="Manage your personal profile email and password."
				showBreadCrumbs
			/>

			<MainColumn>
				<TextInput
					label="Full Name"
					value={name}
					onChange={(v) => setName(v)}
					error={nameError}
				/>
				<Spacer height={6} />
				<TextInput
					label="Email"
					value={email}
					onChange={(v) => setEmail(v)}
					error={emailError}
				/>
				<Spacer height={10} />
				{!!submissionError && (
					<>
						<Typography color={colors.error}>{submissionError}</Typography>
						<Spacer height={4} />
					</>
				)}
				<ButtonsRow>
					<Button
						variant="outlined"
						color="secondary"
						onClick={() => history.push('/account/profile')}>
						Cancel
					</Button>
					<Spacer width={6} />
					<LoadingButton onClick={handleSave} loading={loading}>
						Save
					</LoadingButton>
				</ButtonsRow>

				{modalVisible && (
					<ReauthenticateModal
						cancelPressed={() => setModalVisible(false)}
						reAuthSuccesfull={handleSaveAuthenticated}
						loading={loading}
					/>
				)}
			</MainColumn>
		</Screens.Container>
	);
};

const MainColumn = styled(Column)`
	width: 50%;
	min-width: 470px;
`;

const ButtonsRow = styled(Row)`
	justify-content: flex-end;
`;

export default EditProfile;
