import { useMediaQuery, useTheme } from '@mui/material';

import Authentication from '../authentication';
import Container from '../container';

const EssentialPlanLanding = () => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Container fullScreen={fullScreen}>
			<Authentication planId="essential-plan---$1.00-(includes-30-day-free-trial)" />
		</Container>
	);
};

export default EssentialPlanLanding;
