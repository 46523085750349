import { TableCell } from '@mui/material';
import { APIProperty } from '@rentcheck/types';
import { formatDate } from 'utils/helpers';

interface CellProps {
	property: APIProperty;
	onClick: () => void;
}

const LastCompletedInspection = ({ property, onClick }: CellProps) => {
	if (!property.last_completed_inspection) {
		return <TableCell onClick={onClick}>-</TableCell>;
	}
	return (
		<TableCell onClick={onClick}>
			{formatDate(property.last_completed_inspection.date)}
		</TableCell>
	);
};

export default LastCompletedInspection;
