import React, { useState, useEffect, useCallback } from 'react';
import { Table, TableBody, TableContainer, Paper } from '@mui/material';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { PropertiesActions, SnackbarActions } from 'store/actions';

import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';
import { InfiniteScroll } from 'components';

import TableRow from './row';
import TableHeader from './header';
import FiltersSkeleton from '../../common/skeleton-filters';

import {
	hasAppliedFilters,
	State as FiltersState,
} from 'store/reducers/properties/filters/communities';
import EmptyState from '../../common/empty-state';

interface Props {
	selectedIds: string[];
	setSelectedIds: (ids: string[]) => void;
}

const CommunitiesTable = ({ selectedIds, setSelectedIds }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const communities = useTypedSelector(
		(state) => state.properties.communities.data
	);
	const filters = useTypedSelector(
		(state) => state.properties.filters.communities
	);

	const [skippedFirstFetch, setSkippedFirstFetch] = useState(false);
	const [loadingFilters, setLoadingFilters] = useState(false);

	useEffect(() => {
		if (!skippedFirstFetch) {
			setSkippedFirstFetch(true);
			return;
		}

		fetchCommunities(filters);
	}, [filters]);

	const debouncedFetch = _.debounce((filters: FiltersState) => {
		setLoadingFilters(true);

		dispatch(PropertiesActions.communities.getAll(0, filters))
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => {
				setLoadingFilters(false);
			});
	}, 300);

	const fetchCommunities = useCallback((filters: FiltersState) => {
		setLoadingFilters(true);
		debouncedFetch(filters);
	}, []);

	const handleSelectAll = () => {
		if (selectedIds.length === communities.length) {
			return setSelectedIds([]);
		}

		setSelectedIds(communities.map((c) => c.id));
	};

	const handleSelectId = (id: string) => {
		setSelectedIds(_.xor(selectedIds, [id]));
	};

	if (
		communities.length === 0 &&
		!hasAppliedFilters(filters) &&
		!loadingFilters
	) {
		return <EmptyState propertyType="Communities" />;
	}

	return (
		<div>
			<TableContainer component={Paper}>
				<FiltersSkeleton loading={loadingFilters} />
				<Table sx={{ minWidth: 650 }}>
					<TableHeader
						selectedIds={selectedIds}
						setSelectedIds={setSelectedIds}
						selectAllIds={handleSelectAll}
						communities={communities}
					/>
					<TableBody>
						{communities.map((row) => (
							<TableRow
								key={row.id}
								community={row}
								selectedIds={selectedIds}
								setSelectedIds={setSelectedIds}
								selectId={handleSelectId}
							/>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			{!loadingFilters && (
				<InfiniteScroll
					objectName="Communities"
					filterState={filters}
					action={PropertiesActions.communities}
					offset={communities.length}
				/>
			)}
		</div>
	);
};

export default CommunitiesTable;
