import { ImageMetadata } from '@rentcheck/types';

export const metadataHasDamage = (metadata: ImageMetadata) => {
	if (metadata.foxy_ai.is_image_correct === false) {
		return false;
	}

	if (!metadata.foxy_ai.models) {
		return false;
	}

	/**
	 * Support for the general damage model
	 */
	if (metadata.foxy_ai.models.general_rent_damage) {
		const classificationResult =
			metadata.foxy_ai.models.general_rent_damage.results.find(
				(r) => r.annotationType === 'CLASSIFICATION'
			);

		if (!classificationResult) {
			return false;
		}

		return classificationResult.class === 'Damage';
	}

	return false;
};
