import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip } from '@mui/material';

import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { createSx } from './create-sx';

const ChipActive = () => {
	const dispatch = useDispatch();

	const activeOnly = useTypedSelector(
		(state) => state.tenants.filters.active_only
	);

	const handleClick = () => {
		dispatch({ type: 'SET_TENANTS_ACTIVE_ONLY_FILTER', value: true });
	};

	return (
		<Chip
			variant="outlined"
			icon={<FontAwesomeIcon icon={solid('user-check')} />}
			label="Active"
			onClick={handleClick}
			sx={createSx(activeOnly, '#06622B', '#EAF5ED', '#DBEFDF')}
		/>
	);
};

export default ChipActive;
