import {
	Dialog,
	DialogActions,
	DialogContent,
	Typography,
} from '@mui/material';

import { CenteredColumn, CenteredRow, LottieWrapper, Spacer } from 'components';
import { Button } from 'components/button';
import { IntercomAPI } from 'react-intercom';
import { useDispatch } from 'react-redux';
import { ModalFlowActions } from 'store/actions';
import { Dispatch } from 'types';

interface Props {
	open: boolean;
}

export default ({ open }: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const handleClose = () => {
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	const handleGetHelp = () => {
		IntercomAPI('showNewMessage');
		handleClose();
	};

	return (
		<Dialog open={open}>
			<CenteredRow style={{ marginTop: 40, marginBottom: -12 }}>
				<LottieWrapper variant="dog-computing" width={160} height={150} />
			</CenteredRow>
			<DialogContent>
				<CenteredColumn>
					<Typography variant="h6" align="center">
						Paw-don the delay, we're still setting up your account!
					</Typography>
					<Typography align="center" color="#4d4d4d" mt={2}>
						Your RentCheck account is taking a bit longer to set up than usual.
						<br />
						<br />
						This process typically takes up to 5 minutes, but occasionally it
						may extend beyond that. If you've been waiting for more than 15
						minutes, please don't hesitate to reach out to our support team.
					</Typography>
				</CenteredColumn>
				<Spacer height={10} />
			</DialogContent>
			<DialogActions>
				<Button variant="text" color="secondary" onClick={handleClose}>
					Close
				</Button>
				<Button onClick={handleGetHelp}>Contact Support</Button>
			</DialogActions>
		</Dialog>
	);
};
