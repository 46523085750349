import { ApiTeam, MultiValueFilter } from '@rentcheck/types';
import AutocompleteFilterChip from 'components/table/filter-button-v2/components/filter-chip/autocomplete';
import { useTeams } from 'hooks/teams';
import { useDispatch } from 'react-redux';
import { actionType, icon, useFilter } from './common';

export default () => {
	const dispatch = useDispatch();
	const filter = useFilter();

	const { loading, teams } = useTeams();

	const handleSetValue = (value: MultiValueFilter<ApiTeam> | undefined) => {
		if (!value || !value.value || value.value.length === 0) {
			dispatch({
				type: actionType,
				value: undefined,
			});

			return;
		}

		dispatch({
			type: actionType,
			value,
		});
	};

	if (!filter) {
		return null;
	}

	return (
		<AutocompleteFilterChip
			multiple
			title="Team"
			icon={icon}
			loading={loading}
			value={filter.value}
			condition={filter.condition}
			setFilterValue={handleSetValue}
			options={teams}
			getOptionLabel={(option) => option.internal_label}
			isOptionEqualToValue={(option, value) => option.id === value.id}
		/>
	);
};
