import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Checkbox, TableCell, TableRow } from '@mui/material';
import { Rules } from '@rentcheck/biz';
import { ApiPermissionGroupDigest } from '@rentcheck/types';
import IconButton from 'components/icon-button';
import MenuItem from 'components/menu-item';
import { useDispatch } from 'react-redux';
import { ModalFlowActions, TeammatesActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';

interface Props {
	isLastPermissionGroup: boolean;
	permissionGroup: ApiPermissionGroupDigest;
	selected: boolean;
	totalData: number;
	onSelect: (user: ApiPermissionGroupDigest) => void;
}

export default ({
	isLastPermissionGroup,
	permissionGroup,
	selected,
	totalData,
	onSelect,
}: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const profile = useTypedSelector((state) => state.activeProfile);
	const handleCloseConfirmationModal = () => {
		dispatch(ModalFlowActions.closeConfirmationModal());
	};

	const handleDelete = () => {
		if (isLastPermissionGroup) return;

		dispatch(
			ModalFlowActions.showConfirmationModal({
				icon: solid('trash'),
				title: 'Delete Permission Group',
				body1: [
					'Are you sure you want to delete this permission group?',
					'Deleting will update access for users assigned to this permission group. This action is irreversible.',
				],
				buttons: [
					{ title: 'Cancel', onClick: handleCloseConfirmationModal },
					{ title: 'Delete', onClick: handleDeleteConfirmed },
				],
			})
		);
	};

	const handleDeleteConfirmed = async () => {
		dispatch(ModalFlowActions.setConfirmationModalLoading(true));

		dispatch(TeammatesActions.PermissionGroups.remove([permissionGroup.id]))
			.then(() => {
				handleCloseConfirmationModal();
			})
			.finally(() =>
				dispatch(ModalFlowActions.setConfirmationModalLoading(false))
			);
	};

	const handleClicked = () => {
		if (!canEdit) return;

		dispatch(ModalFlowActions.showCreatePermissionGroup({ permissionGroup }));
	};

	const { result: canDelete, reason: deleteReason } =
		Rules.PermissionGroups.canDelete(totalData, 1, profile);
	const { result: canEdit, reason: editReason } =
		Rules.PermissionGroups.canEdit(profile);

	return (
		<TableRow>
			<TableCell>
				<Checkbox
					size="small"
					checked={selected}
					onChange={() => onSelect(permissionGroup)}
				/>
			</TableCell>
			<TableCell onClick={handleClicked}>
				{permissionGroup.name || '-'}
			</TableCell>
			<TableCell onClick={handleClicked}>
				{permissionGroup.user_count}
			</TableCell>

			<TableCell align="center">
				<IconButton icon={regular('ellipsis-v')}>
					<MenuItem
						disabled={!canEdit}
						tooltip={editReason}
						onClick={handleClicked}>
						Edit
					</MenuItem>

					<MenuItem
						onClick={handleDelete}
						disabled={!canDelete}
						tooltip={deleteReason}>
						Delete
					</MenuItem>
				</IconButton>
			</TableCell>
		</TableRow>
	);
};
