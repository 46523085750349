import { Skeleton } from '@mui/material';

import { Row, Spacer } from 'components';

const SkeletonHeader = () => {
	return (
		<Row>
			<Skeleton variant="text" width={190} />
			<Spacer width={2} />
			<Skeleton variant="circular" width={15} height={15} />
		</Row>
	);
};

export default SkeletonHeader;
