import { ImportDigest } from '@rentcheck/types';
import { Column } from 'components';

interface Props {
	object: ImportDigest;
}

export default ({ object }: Props) => {
	return (
		<Column>
			<div>{object.creator.name}</div>
			<div>{object.creator.email}</div>
		</Column>
	);
};
