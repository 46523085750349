import { combineReducers } from 'redux';
import unitsReducer, { State as UnitsState } from './units';
import buildingsReducer, { State as BuildingsState } from './buildings';
import communitiesReducer, { State as CommunitiesState } from './communities';
import filtersReducer, { State as FiltersState } from './filters';
import propertyReducer, { State as PropertyState } from './property';

export type State = {
	filters: FiltersState;
	units: UnitsState;
	buildings: BuildingsState;
	communities: CommunitiesState;
	property: PropertyState;
};

const reducer = combineReducers({
	filters: filtersReducer,
	units: unitsReducer,
	buildings: buildingsReducer,
	communities: communitiesReducer,
	property: propertyReducer,
});

export default reducer;
