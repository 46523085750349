import { Rules } from '@rentcheck/biz';
import { ApiInspection } from '@rentcheck/types';
import MenuItem from 'components/menu-item';
import { useStoreFeatures } from 'hooks/features';
import { useDispatch } from 'react-redux';
import { ModalFlowActions } from 'store/actions';
import { Dispatch } from 'types';

interface Props {
	inspection: ApiInspection;
	onSelected: () => void;
}

export default ({ inspection, onSelected }: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const features = useStoreFeatures(inspection.id);

	const handleClick = () => {
		onSelected();

		const flags = features
			.map((feature) => feature.maintenance_flags ?? [])
			.flat();

		dispatch(
			ModalFlowActions.showCreateMaintenanceReportModal({ inspection, flags })
		);
	};

	if (!Rules.Inspections.canCreateMaintenanceReport(inspection, features)) {
		return null;
	}

	return <MenuItem onClick={handleClick}>Create Maintenance Report</MenuItem>;
};
