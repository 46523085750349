import { TextField, Typography } from '@mui/material';
import { Column, Row, Spacer } from 'components';
import { useCreateWorkOrderModalData } from 'hooks/modals';
import { colors } from 'theme';

interface Props {
	value: string;
	setValue: (value: string) => void;
}

const Name = ({ value, setValue }: Props) => {
	const modalData = useCreateWorkOrderModalData('Latchel');
	const inspection = modalData?.metadata?.inspection;

	if (!inspection) return null;

	return (
		<Column>
			<Row>
				<Typography variant="h6">Name this work order:</Typography>
				<Spacer width={2} />
				<Typography sx={{ marginTop: 0.4 }} color={colors.gray}>
					(Required)
				</Typography>
			</Row>
			<Spacer height={4} />

			<TextField
				id="textinput-name"
				variant="filled"
				label="Name"
				value={value}
				onChange={(e) => setValue(e.target.value)}
			/>
		</Column>
	);
};

export default Name;
