import { Redirect, useLocation } from 'react-router-dom';
import { useTypedSelector } from 'store/reducers/rootReducer';

import { Screens } from 'components';

import PostDemoFollowUp from './post-demo-follow-up';

export default function AdminPanel() {
	const location = useLocation();

	const authenticatedUser = useTypedSelector(
		(state) => state.authenticatedUser
	);

	if (!authenticatedUser) {
		return null;
	}

	if (!location.pathname.startsWith('/account/sales')) {
		return null;
	}

	if (!authenticatedUser.internal_sales) {
		return <Redirect to={'/account/profile'} />;
	}

	return (
		<Screens.Container>
			<Screens.Header title="Sales Panel" />
			<PostDemoFollowUp />
		</Screens.Container>
	);
}
