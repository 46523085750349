import { Box } from '@mui/material';
import { ApiInspectionWithTemplate } from '@rentcheck/types';
import { FeatureWithMetadata } from 'store/reducers/features';

import CollapseButton from './collapse-button';
import FlaggedButton from './flagged-button';

interface Props {
	inspection: ApiInspectionWithTemplate;

	/**
	 * Required for the CollapseButton component, ignore if it's
	 * not needed (i.e. List Mode)
	 */
	features?: FeatureWithMetadata[];
	expandedFeatureIds?: string[];
	setExpandedFeatureIds?: (ids: string[]) => void;
}

export default ({
	inspection,
	features,
	expandedFeatureIds,
	setExpandedFeatureIds,
}: Props) => {
	return (
		<Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
			{features && !!expandedFeatureIds && !!setExpandedFeatureIds && (
				<CollapseButton
					features={features}
					expandedFeatureIds={expandedFeatureIds}
					setExpandedFeatureIds={setExpandedFeatureIds}
				/>
			)}
			<FlaggedButton inspection={inspection} />
		</Box>
	);
};
