import { Switch, Typography } from '@mui/material';
import {
	DefaultInspectionReportTemplate,
	InspectionReportTemplate,
} from '@rentcheck/types';
import { Row, SpacedRow } from 'components';
import _ from 'lodash';
import { colors } from 'theme';

export interface Props {
	title: string;
	isEditing: boolean;
	payload: InspectionReportTemplate;
	setPayload: (value: InspectionReportTemplate) => void;
	payloadKey: keyof InspectionReportTemplate;
}

export default ({
	title,
	isEditing,
	payload,
	setPayload,
	payloadKey,
}: Props) => {
	const thisSection = _.get(payload, payloadKey);

	const wholeSectionIsOff = (
		section: string | Record<string, any>
	): boolean => {
		return !_.values(section).find((v) =>
			typeof v === 'boolean' ? !!v : !wholeSectionIsOff(v)
		);
	};

	const handleToggleSection = () => {
		const newPayload = { ...payload };

		if (wholeSectionIsOff(thisSection) && !sectionActive) {
			const defaultPayload = _.cloneDeep(
				DefaultInspectionReportTemplate[payloadKey]
			);

			_.set(newPayload, payloadKey, defaultPayload);
		}

		_.set(newPayload, `${payloadKey}.section_active`, !sectionActive);

		if (thisSection) setPayload(newPayload);
	};

	const sectionActive = _.get(thisSection, 'section_active');

	return (
		<SpacedRow style={{ marginLeft: 16, marginRight: 16 }}>
			<Typography variant="overline">{title}</Typography>
			<Row>
				<Typography variant="overline" color={colors.secondary}>
					{sectionActive ? 'on' : 'off'}
				</Typography>
				<Switch
					disabled={!isEditing}
					checked={sectionActive}
					onChange={handleToggleSection}
				/>
			</Row>
		</SpacedRow>
	);
};
