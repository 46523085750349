import { Typography } from '@mui/material';
import { MaintenanceFlag } from '@rentcheck/types';
import { Column, Row, SpacedRow } from 'components';

import { featureDisplayName, formattedAddress } from 'utils/helpers';

import { FlagImagesMetadata } from 'store/reducers/modal-flows';
import Close from './close';

interface Props {
	property: FlagImagesMetadata['property'];
	flag: MaintenanceFlag;
}

const Header = ({ property, flag }: Props) => {
	return (
		<SpacedRow>
			<Column>
				<Typography variant="h4">{featureDisplayName(flag)}</Typography>
				<Row>
					<Typography>
						{formattedAddress(property)} - {flag.skill}
					</Typography>
				</Row>
			</Column>

			<Close />
		</SpacedRow>
	);
};

export default Header;
