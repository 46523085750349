import { ApiInspection } from '@rentcheck/types';
import MenuItem from 'components/menu-item';
import { useDispatch } from 'react-redux';
import { ModalFlowActions } from 'store/actions';
import { Dispatch } from 'types';

interface Props {
	inspection: ApiInspection;
	onSelected: () => void;
}

export default ({ inspection, onSelected }: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const handleClick = () => {
		dispatch(ModalFlowActions.showInspectionTimelineModal({ inspection }));
		onSelected();
	};

	return <MenuItem onClick={handleClick}>View Inspection Timeline</MenuItem>;
};
