import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Checkbox, Menu, TableCell, TableHead, TableRow } from '@mui/material';
import { Rules } from '@rentcheck/biz';
import { ApiPermissionGroupDigest } from '@rentcheck/types';
import { ActionsChip } from 'components';
import MenuItem from 'components/menu-item';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ModalFlowActions, TeammatesActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';

interface Props {
	totalData: number;
	selected: boolean;
	indeterminate: boolean;
	selectedIds: string[];
	data: ApiPermissionGroupDigest[];
	onSelect: () => void;
}

export default ({
	totalData,
	selected,
	indeterminate,
	selectedIds,
	onSelect,
}: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const profile = useTypedSelector((state) => state.activeProfile);

	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const handleClick = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		setAnchorEl(e.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleCloseConfirmationModal = () => {
		dispatch(ModalFlowActions.closeConfirmationModal());
	};

	const handleDelete = () => {
		handleClose();

		dispatch(
			ModalFlowActions.showConfirmationModal({
				icon: solid('trash'),
				title: 'Delete Permission Groups',
				body1: [
					'Are you sure you want to delete these permission group?',
					'Deleting will update access for users assigned to these permission groups. This action is irreversible.',
				],
				buttons: [
					{ title: 'Cancel', onClick: handleCloseConfirmationModal },
					{ title: 'Delete', onClick: handleDeleteConfirmed },
				],
			})
		);
	};

	const handleDeleteConfirmed = async () => {
		dispatch(ModalFlowActions.setConfirmationModalLoading(true));

		dispatch(TeammatesActions.PermissionGroups.remove(selectedIds))
			.then(() => {
				handleCloseConfirmationModal();
			})
			.finally(() =>
				dispatch(ModalFlowActions.setConfirmationModalLoading(false))
			);
	};

	const { result: canDelete, reason: deleteReason } =
		Rules.PermissionGroups.canDelete(totalData, selectedIds.length, profile);

	return (
		<TableHead>
			<TableRow>
				<TableCell sx={{ width: 30 }}>
					<Checkbox
						size="small"
						checked={selected}
						onChange={onSelect}
						indeterminate={indeterminate}
					/>
				</TableCell>
				<TableCell>Name</TableCell>
				<TableCell>Users</TableCell>

				<TableCell align="center" sx={{ width: 30 }}>
					<>
						<ActionsChip ids={selectedIds} openMenu={handleClick} />
						<Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
							<MenuItem
								tooltip={deleteReason}
								onClick={handleDelete}
								disabled={!canDelete}>
								Delete
							</MenuItem>
						</Menu>
					</>
				</TableCell>
			</TableRow>
		</TableHead>
	);
};
