import { Breakpoint, useMediaQuery, useTheme } from '@mui/material';
import { ApplicationSettings } from '@rentcheck/types';
import { ApplicationSettingsApi } from 'api';
import { RefObject, useEffect, useState } from 'react';
import { useTypedSelector } from 'store/reducers/rootReducer';

export const useOnClickOutside = (ref: any, handler: any) => {
	useEffect(() => {
		const listener = (event: any) => {
			// Do nothing if clicking ref's element or descendent elements
			if (!ref.current || ref.current.contains(event.target)) {
				return;
			}

			handler(event);
		};

		document.addEventListener('mousedown', listener);
		document.addEventListener('touchstart', listener);

		return () => {
			document.removeEventListener('mousedown', listener);
			document.removeEventListener('touchstart', listener);
		};
	}, [ref, handler]);
};

export const useKeyPress = (callback: any) => {
	useEffect(() => {
		document.addEventListener('keydown', callback);
		return () => {
			document.removeEventListener('keydown', callback);
		};
	}, [callback]);
};

export const useOnScreen = (ref?: RefObject<Element | undefined>) => {
	const [isIntersecting, setIntersecting] = useState(false);

	useEffect(() => {
		if (!ref?.current) {
			return;
		}

		const observer = new IntersectionObserver(
			([entry]) =>
				isIntersecting !== entry.isIntersecting &&
				setIntersecting(entry.isIntersecting)
		);

		observer.observe(ref.current);

		return () => {
			observer.disconnect();
		};
	}, [ref?.current, isIntersecting]);

	return isIntersecting;
};

export const useIsMobileBreakpoint = (key: number | Breakpoint = 'sm') => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down(key));

	return isMobile;
};

export const useRemoteSettings = () => {
	const profile = useTypedSelector((state) => state.activeProfile);

	const [loading, setLoading] = useState(true);
	const [settings, setSettings] = useState<ApplicationSettings>({
		minimum_app_version: '7.0.0',
		maintenance_admin_panel: false,
		maintenance_etl: false,
		subscription_plans: [],
	});

	useEffect(() => {
		ApplicationSettingsApi.listenForApplicationSettings(setSettings);

		setLoading(true);

		ApplicationSettingsApi.getApplicationSettings()
			.then((res) => {
				if (!res) return;
				setSettings(res);
			})
			.catch(() => {})
			.finally(() => setLoading(false));
	}, [profile]);

	return { settings, loading };
};
