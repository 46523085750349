import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Divider, Menu, MenuItem, Typography } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Feature, TemplateQuestion } from '@rentcheck/types';

import { Column, SpacedRow } from 'components';
import { colors } from 'theme';

interface Props {
	index: number;
	question: TemplateQuestion;
	featurePayload: Partial<Feature>;
	setFeaturePayload: (payload: Partial<Feature>) => void;
}

const DefaultQuestion = ({
	index,
	question,
	featurePayload,
	setFeaturePayload,
}: Props) => {
	const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

	const responses = featurePayload.responses ?? [];
	const answer = responses[index] ?? '';

	const handleMenuButtonClick = (e: React.MouseEvent<HTMLElement>) => {
		setAnchorElement(e.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorElement(null);
	};

	const handleSelection = (option: string) => {
		const updatedResponses = [...responses];
		updatedResponses[index] = option;

		setFeaturePayload({
			...featurePayload,
			responses: updatedResponses,
		});
	};

	return (
		<Column>
			<SpacedRow style={{ marginBottom: 12, marginTop: 12 }}>
				<Typography color="#00000099">{question.title}</Typography>

				<MenuButton
					answer={answer}
					variant="text"
					color="secondary"
					onClick={handleMenuButtonClick}
					endIcon={
						<FontAwesomeIcon
							color={colors.darkGray}
							icon={regular('chevron-down')}
						/>
					}>
					{answer}
				</MenuButton>
				<Menu
					autoFocus={false}
					open={!!anchorElement}
					anchorEl={anchorElement}
					onClose={handleMenuClose}
					onClick={() => {
						setAnchorElement(null);
					}}>
					{['Yes', 'No'].map((option) => (
						<MenuItem
							id={`menu-item-${option}`}
							onClick={() => handleSelection(option)}
							sx={{ minWidth: 60 }}>
							{option}
						</MenuItem>
					))}
				</Menu>
			</SpacedRow>

			<Divider />
		</Column>
	);
};

const MenuButton = styled(Button)<{ answer: string }>`
	color: ${({ answer }) => (answer === 'No' ? colors.error : colors.darkGray)};
	height: 30px;
	width: 60px;
	margin-top: -4px;
	margin-bottom: -4px;
`;

export default DefaultQuestion;
