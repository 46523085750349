import { Feature, ImageMetadata } from '@rentcheck/types';

export interface FeatureWithMetadata extends Feature {
	image_metadata: ImageMetadata[];
}

type InspectionFeatures = Record<string, FeatureWithMetadata>;

export type State = Record<string, InspectionFeatures>;

export interface Action {
	type: string;
	features?: FeatureWithMetadata[];
}

const defaultState: State = {};

const featuresReducer = (state: State = defaultState, action: Action) => {
	if (!action.features) {
		return state;
	}

	switch (action.type) {
		case 'UPDATE_FEATURES': {
			const updatedState = { ...state };

			action.features.forEach((f) => {
				const inspectionFeatures: InspectionFeatures = {
					...(updatedState[f.inspection_id] ?? {}),
				};

				inspectionFeatures[f.id] = f;
				updatedState[f.inspection_id] = inspectionFeatures;
			});

			return updatedState;
		}

		default:
			return state;
	}
};

export default featuresReducer;
