import {
	Checkbox,
	Divider,
	FormControl,
	FormControlLabel,
	MenuItem,
	Paper,
	Radio,
	RadioGroup,
	Typography,
} from '@mui/material';
import {
	ApiInspectionTemplateDigest,
	InspectionTemplate,
} from '@rentcheck/types';
import FormTextField from 'components/form-text-field';
import { useCreateInspectionTemplateModalData } from 'hooks/modals';
import _ from 'lodash';
import { useEffect } from 'react';
import {
	CreateInspectionTemplateMetadata,
	createInspectionTemplateMetadataModes,
} from 'store/reducers/modal-flows';

interface Props {
	baseTemplate?: ApiInspectionTemplateDigest;

	templateType?: ApiInspectionTemplateDigest['template_type'];
	setTemplateType: (
		templateType: ApiInspectionTemplateDigest['template_type']
	) => void;

	propertyType: ApiInspectionTemplateDigest['property_type'];
	setPropertyType: (
		propertyType: ApiInspectionTemplateDigest['property_type']
	) => void;

	isFastTrackEnabled: boolean;
	setIsFastTrackEnabled: (isFastTrackEnabled: boolean) => void;
}

export default ({
	baseTemplate,
	templateType,
	setTemplateType,
	propertyType,
	setPropertyType,
	isFastTrackEnabled,
	setIsFastTrackEnabled,
}: Props) => {
	const modalData = useCreateInspectionTemplateModalData();
	const template = modalData?.metadata?.template ?? baseTemplate;

	const handleChangeTemplateType = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setTemplateType(event.target.value as InspectionTemplate['template_type']);

		if (event.target.value === 'specific-features') {
			setIsFastTrackEnabled(false);
		}
	};

	useEffect(() => {
		if (!baseTemplate) return;

		if (templateType === 'specific-features') {
			setIsFastTrackEnabled(false);
		}

		if (templateType === 'all-rooms') {
			setIsFastTrackEnabled(true);
		}
	}, [templateType]);

	const showAllRoomsOption =
		!template || template?.template_type === 'all-rooms';
	const showSpecificFeaturesOption =
		!template || template?.template_type === 'specific-features';
	const showDivider = !template;

	return (
		<>
			<FormTextField
				select
				disabled={!!template}
				title="Inspection Template type"
				value={propertyType}
				onChange={(e) =>
					setPropertyType(
						e.target.value as CreateInspectionTemplateMetadata['mode']
					)
				}>
				{createInspectionTemplateMetadataModes.map((mode) => (
					<MenuItem value={mode}>{_.capitalize(mode)}</MenuItem>
				))}
			</FormTextField>

			<Paper elevation={0} sx={{ border: '1px solid #0000001F', mb: 3 }}>
				<FormControl>
					<RadioGroup value={templateType} onChange={handleChangeTemplateType}>
						{showAllRoomsOption && (
							<>
								<FormControlLabel
									disabled={!!template}
									value="all-rooms"
									control={<Radio />}
									sx={{ padding: 2, paddingLeft: 0.5 }}
									labelPlacement="start"
									label={
										<>
											<Typography>
												Inspection template covers all rooms and areas of a{' '}
												{propertyType}
											</Typography>
											<Typography variant="body2" mt={1} color="text.secondary">
												Inspections generated from this template will
												automatically include all rooms and areas configured on
												a {propertyType}. Examples include move-in / move-out
												inspections or periodic routine checks.
											</Typography>
										</>
									}
								/>
								<FormControlLabel
									control={<Checkbox checked={isFastTrackEnabled} />}
									value={isFastTrackEnabled}
									disabled={templateType !== 'all-rooms'}
									onChange={() => setIsFastTrackEnabled(!isFastTrackEnabled)}
									labelPlacement="start"
									sx={{
										backgroundColor: '#F6F5FF',
										margin: 0,
										marginBottom: !template ? 2 : 0,
										padding: 2,
										paddingLeft: 5,
										paddingRight: 1,
									}}
									label={
										<>
											<Typography>Enable Fast Track option</Typography>
											<Typography variant="body2" mt={1} color="text.secondary">
												Activate the option to expedite inspections in this
												template with Fast Track. If unchecked, users won't have
												the option for Fast Track inspections in this template.
											</Typography>
										</>
									}
								/>
							</>
						)}
						{showDivider && <Divider />}
						{showSpecificFeaturesOption && (
							<FormControlLabel
								disabled={!!template}
								value="specific-features"
								control={<Radio />}
								sx={{ padding: 2, paddingLeft: 0.5 }}
								labelPlacement="start"
								label={
									<>
										<Typography>
											Inspection template includes specific features to be
											documented
										</Typography>
										<Typography variant="body2" mt={1} color="text.secondary">
											Inspections generated from this template will include
											specific features to be documented. Specific features are
											selected during inspection creation and are set by default
											as dictated by the template. Examples include air filter
											or exterior landscaping checks.
										</Typography>
									</>
								}
							/>
						)}
					</RadioGroup>
				</FormControl>
			</Paper>
		</>
	);
};
