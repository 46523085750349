import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip as MuiChip } from '@mui/material';

export type SnapshotLabel =
	| 'Awaiting Review'
	| 'Almost Due'
	| 'Overdue'
	| 'Undelivered'
	| 'Revision Requested'
	| 'Awaiting Signature'
	| 'Units at Risk'
	| 'Unassigned';

interface Props {
	label: SnapshotLabel;
}

const Chip = ({ label }: Props) => {
	return (
		<MuiChip
			variant="outlined"
			icon={icon(label)}
			label={label}
			sx={createSx(label)}
		/>
	);
};

const icon = (label: SnapshotLabel) => {
	switch (label) {
		case 'Awaiting Review':
			return <FontAwesomeIcon icon={solid('file-signature')} />;
		case 'Almost Due':
			return <FontAwesomeIcon icon={solid('exclamation-triangle')} />;
		case 'Overdue':
			return <FontAwesomeIcon icon={solid('exclamation-triangle')} />;
		case 'Unassigned':
			return <FontAwesomeIcon icon={solid('clipboard-question')} />;
		case 'Undelivered':
			return <FontAwesomeIcon icon={solid('users-slash')} />;
		case 'Revision Requested':
			return <FontAwesomeIcon icon={solid('rotate-right')} />;
		case 'Awaiting Signature':
			return <FontAwesomeIcon icon={solid('pen')} />;
		case 'Units at Risk':
			return <FontAwesomeIcon icon={solid('building-circle-exclamation')} />;
	}
};

const createSx = (label: SnapshotLabel) => {
	const colorSx = () => {
		switch (label) {
			case 'Awaiting Review':
				return {
					backgroundColor: '#36B45F1F',
					borderColor: '#36B45F1F',

					'.MuiChip-icon': {
						color: '#06622B',
					},
				};
			case 'Almost Due':
				return {
					backgroundColor: '#EB830D1F',
					borderColor: '#EB830D1F',

					'.MuiChip-icon': {
						color: '#DB5C09',
					},
				};
			case 'Overdue':
				return {
					backgroundColor: '#F04D4C1F',
					borderColor: '#F04D4C1F',

					'.MuiChip-icon': {
						color: '#C71D23',
					},
				};
			case 'Undelivered':
				return {
					backgroundColor: '#FFFFFF',
					borderColor: '#CCD6E1',

					'.MuiChip-icon': {
						color: '#353535',
					},
				};
			case 'Revision Requested':
				return {
					backgroundColor: '#FFFFFF',
					borderColor: '#CCD6E1',

					'.MuiChip-icon': {
						color: '#353535',
					},
				};
			case 'Awaiting Signature':
				return {
					backgroundColor: '#FFFFFF',
					borderColor: '#CCD6E1',

					'.MuiChip-icon': {
						color: '#353535',
					},
				};
			case 'Units at Risk':
				return {
					backgroundColor: '#FFFFFF',
					borderColor: '#CCD6E1',

					'.MuiChip-icon': {
						color: '#C71D23',
					},
				};
		}
	};

	return {
		fontWeight: 500,
		marginRight: 1,
		marginTop: 0.5,
		marginBottom: 0.5,
		width: 190,
		height: 30,
		...colorSx(),
	};
};

export default Chip;
