import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { landing } from 'assets';
import { Column, Row, Spacer } from 'components';
import { colors, spacingPx } from 'theme';

interface Props {
	fullScreen: boolean;
	children: React.ReactNode;
	webLogo?: string;
	mobileLogo?: string;
}

const Container = ({ fullScreen, children, webLogo, mobileLogo }: Props) => {
	if (fullScreen) {
		return (
			<Row
				style={{
					width: '100vw',
					height: '100vh',
					flex: 1,
					marginTop: spacingPx(-14),
				}}>
				<ContentBackground
					style={{
						width: '100%',
						height: '100%',
						flex: 1,
						padding: spacingPx(2),
					}}>
					<Spacer height={10} />

					<Row>
						<img
							src={landing.rentCheckLogoWhite}
							style={{ height: spacingPx(6) }}
						/>
						{mobileLogo && (
							<>
								<Spacer width={5} />
								<FontAwesomeIcon icon={solid('plus')} color={colors.white} />
								<Spacer width={5} />
								<img src={mobileLogo} style={{ height: spacingPx(6) }} />
							</>
						)}
					</Row>
					<Spacer height={8} />

					{children}
					<Spacer height={10} />

					<img
						src={landing.customerQuote}
						style={{ height: spacingPx(80), objectFit: 'contain' }}
					/>
					<Spacer height={20} />
				</ContentBackground>
			</Row>
		);
	}

	return (
		<Row style={{ width: '100vw', height: '100vh' }}>
			<ContentBackground
				style={{
					width: '50%',
					height: '100%',
					paddingBottom: spacingPx(10),
					paddingTop: spacingPx(21),
				}}>
				<Row style={{ justifyContent: 'flex-end' }}>
					<Column style={{ marginRight: spacingPx(10) }}>
						<Spacer height={20} />

						<img
							src={landing.customerQuote}
							style={{ height: spacingPx(100) }}
						/>
					</Column>
				</Row>
			</ContentBackground>

			<Column
				style={{
					width: '50%',
					height: '100%',
					paddingRight: spacingPx(50),
					paddingLeft: spacingPx(10),
					paddingBottom: spacingPx(10),
					paddingTop: spacingPx(21),
					overflow: 'scroll',
					backgroundColor: colors.white,
				}}>
				<Row>
					<img src={landing.rentCheckLogo} style={{ height: spacingPx(6) }} />

					{webLogo && (
						<>
							<Spacer width={5} />
							<FontAwesomeIcon icon={solid('plus')} color={colors.primary} />
							<Spacer width={5} />
							<img src={webLogo} style={{ height: spacingPx(6) }} />
						</>
					)}
				</Row>

				<Spacer height={8} />

				{children}
			</Column>
		</Row>
	);
};

const ContentBackground = styled(Column)`
	background-image: url(${landing.bgGradient});
	overflow: scroll;
`;

export default Container;
