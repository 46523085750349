import { APIProperty, TeamUserInfo } from '@rentcheck/types';

export type AssignedTeammates = Record<string, TeamUserInfo[]>;

export const buildPropertyTeamAssignments = (
	properties: APIProperty[],
	assignedTeammates: AssignedTeammates
) => {
	const assignments: AssignedTeammates = {};

	properties.forEach((p) => {
		const assignedTeammatesForProperty =
			assignedTeammates[p.team?.id ?? ''] ?? [];
		assignments[p.id] = assignedTeammatesForProperty;
	});

	return assignments;
};
