import {
	AccountSettings,
	ApiInspectionTemplate,
	TemplateQuestion,
	TemplateSection,
} from '@rentcheck/types';
import _ from 'lodash';
import { defaultRooms } from 'screens/account/property-configuration/common/constants';
import { v4 as uuid } from 'uuid';

export type InitializedTemplateSection = TemplateSection & {
	isNewSection?: boolean;
};

export const initializeSections = (
	template: ApiInspectionTemplate,
	accountSettings?: AccountSettings
) => {
	const data: InitializedTemplateSection[] = [];

	data.push(...template.sections);

	const basicSectionNames: string[] = [];

	if (template.property_type === 'unit') {
		const customRooms = accountSettings?.rooms.unit ?? [];

		const interiorCustomRooms = customRooms
			.filter((r) => r.category === 'interior')
			.map((r) => r.name);
		const exteriorCustomRooms = customRooms
			.filter((r) => r.category === 'exterior')
			.map((r) => r.name);

		basicSectionNames.push(
			...defaultRooms.units.bedsAndBaths,
			...(template.is_rc_template ? [] : interiorCustomRooms),
			...defaultRooms.units.interior,
			...(template.is_rc_template ? [] : exteriorCustomRooms),
			...defaultRooms.units.exterior
		);
	}

	if (template.property_type === 'building') {
		const customRooms = accountSettings?.rooms.building ?? [];

		basicSectionNames.push(
			...customRooms.map((r) => r.name),
			...(template.is_rc_template ? [] : defaultRooms.building.floors),
			...(template.is_rc_template ? [] : defaultRooms.building.common)
		);
	}

	if (template.property_type === 'community') {
		const customRooms = accountSettings?.rooms.building ?? [];

		basicSectionNames.push(...customRooms.map((r) => r.name));
	}

	const newSections: InitializedTemplateSection[] = [];
	const isNewTemplate = template.sections.length === 0;

	basicSectionNames.forEach((room) => {
		if (data.find((d) => d.name === room)) {
			return;
		}

		newSections.push({
			id: uuid(),
			name: room,
			features: [],
			type: 'room',
			isNewSection: !isNewTemplate,
			logic: [],
		});
	});

	return [...newSections, ...data];
};

export const answersHaveChanged = (
	newAnswers: TemplateQuestion['answers'],
	originalAnswers: TemplateQuestion['answers']
) => {
	if (newAnswers.length !== originalAnswers.length) {
		return true;
	}

	const answersHaveChanged = newAnswers.some((a, idx) => {
		const originalTemplateAnswer = originalAnswers[idx];

		if (
			a.title !== originalTemplateAnswer.title ||
			a.requires_photo !== originalTemplateAnswer.requires_photo
		) {
			return true;
		}

		return false;
	});

	return answersHaveChanged;
};

export const questionsHaveChanged = (
	newQuestions: TemplateQuestion[],
	originalQuestions: TemplateQuestion[]
) => {
	if (newQuestions.length !== originalQuestions.length) {
		return true;
	}

	const questionsHaveChanged = newQuestions.some((f, idx) => {
		const originalTemplateQuestion = originalQuestions[idx];

		if (
			f.title !== originalTemplateQuestion.title ||
			f.type !== originalTemplateQuestion.type ||
			f.answer_type !== originalTemplateQuestion.answer_type
		) {
			return true;
		}

		if (f.answers.length !== originalTemplateQuestion.answers.length) {
			return true;
		}

		return answersHaveChanged(f.answers, originalTemplateQuestion.answers);
	});

	return questionsHaveChanged;
};

export const featuresHaveChanged = (
	newFeatures: TemplateSection['features'],
	originalFeatures: TemplateSection['features']
) => {
	if (newFeatures.length !== originalFeatures.length) {
		return true;
	}

	const featuresHaveChanged = newFeatures.some((f, idx) => {
		const originalTemplateFeature = originalFeatures[idx];

		if (
			f.name !== originalTemplateFeature.name ||
			f.description !== originalTemplateFeature.description ||
			f.is_rating_required !== originalTemplateFeature.is_rating_required ||
			f.number_of_photos_required !==
				originalTemplateFeature.number_of_photos_required ||
			_.xor(f.reference_photos, originalTemplateFeature.reference_photos)
				.length > 0
		) {
			return true;
		}

		if (f.questions.length !== originalTemplateFeature.questions.length) {
			return true;
		}

		return questionsHaveChanged(f.questions, originalTemplateFeature.questions);
	});

	return featuresHaveChanged;
};

export const sectionsHaveChanged = (
	newSections: TemplateSection[],
	originalSections: TemplateSection[]
) => {
	if (newSections.length !== originalSections.length) {
		return true;
	}

	const sectionsHaveChanged = newSections.some((s, idx) => {
		const originalTemplateSection = originalSections[idx];

		if (s.name !== originalTemplateSection.name) {
			return true;
		}

		return featuresHaveChanged(s.features, originalTemplateSection.features);
	});

	return sectionsHaveChanged;
};
