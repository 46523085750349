import React from 'react';
import { AccordionDetails } from '@mui/material';

interface Props {
	children: React.ReactNode | React.ReactNodeArray | null;
}

const Content = ({ children }: Props) => {
	return (
		<AccordionDetails style={{ marginLeft: 134 }}>{children}</AccordionDetails>
	);
};

export default Content;
