import styled from 'styled-components';

import { success } from 'assets';
import { spacingPx } from 'theme';

import { Typography } from '@mui/material';
import { CenteredColumn, Spacer } from '../../..';
import CreatePropertyFooter from './Footer';

interface Props {
	title?: string;
	extraText?: string;
	skipCreateInspection?: boolean;
	address: string;
	dismissModal: () => void;
	showInspectionModal?: () => void;
}

function Confirmation({
	title,
	extraText,
	address,
	skipCreateInspection,
	dismissModal,
	showInspectionModal,
}: Props) {
	return (
		<div>
			<CenteredColumn>
				<Illustration src={success.unitAdded} alt="confirm-property" />

				<Typography variant="h5">{title ?? 'Unit Added!'}</Typography>
				<Typography>{address}</Typography>

				{!!extraText && (
					<>
						<Spacer height={3} />
						<Typography align="center">{extraText}</Typography>
					</>
				)}

				<Spacer height={3} />
				{!skipCreateInspection && (
					<Typography variant="h4">
						Would you like to set up a new Inspection for the unit?
					</Typography>
				)}
			</CenteredColumn>
			{!skipCreateInspection && !!showInspectionModal && (
				<CreatePropertyFooter
					leftClick={dismissModal}
					rightClick={showInspectionModal}
					leftLabel={'Close'}
					rightLabel={'Set Up Inspection'}
					disabled={false}
				/>
			)}
			{skipCreateInspection && (
				<CreatePropertyFooter
					rightClick={dismissModal}
					rightLabel={'Close'}
					disabled={false}
				/>
			)}
		</div>
	);
}

const Illustration = styled.img`
	height: ${spacingPx(75)};
	resize-mode: contain;
`;

export default Confirmation;
