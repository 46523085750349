import { Screens } from 'components';

import { useTypedSelector } from 'store/reducers/rootReducer';
import CreateButton from './create-button';
import ResidentsTable from './list';

export default () => {
	const total = useTypedSelector((state) => state.tenants.tenants.total);

	return (
		<Screens.Container>
			<Screens.Header
				title="Residents"
				subtitle={`${total} Total`}
				actions={[<CreateButton />]}
			/>

			<ResidentsTable />
		</Screens.Container>
	);
};
