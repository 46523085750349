import { Card, CardContent } from '@mui/material';
import { Row, Spacer } from 'components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DashboardActions, SnackbarActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import styled from 'styled-components';
import { spacingPx } from 'theme';
import { Dispatch } from 'types';

import DailySnapshot from './daily-snapshot';
import DetailModals from './detail-modals';
import KeyMetrics from './key-metrics';
import LockedNotice from './locked-notice';
import Resources from './resources';

const Dashboard = () => {
	const dispatch: Dispatch = useDispatch();

	const profile = useTypedSelector((state) => state.activeProfile);
	const dashboardFilters = useTypedSelector((state) => state.dashboard.filters);

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (!profile) return;

		setLoading(true);

		dispatch(DashboardActions.get(dashboardFilters))
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	}, [profile, dashboardFilters]);

	return (
		<div>
			<LockedNotice />
			<ScreenContainer>
				<Card>
					<CardContent>
						<KeyMetrics loading={loading} />
					</CardContent>
				</Card>
				<Spacer height={5} />
				<Card>
					<CardContent>
						<Row style={{ alignItems: 'flex-start' }}>
							<DailySnapshot />
							<Spacer width={7} />
							<Resources />
						</Row>
					</CardContent>
				</Card>
			</ScreenContainer>
			<DetailModals />
		</div>
	);
};

const ScreenContainer = styled.div`
	width: 80vw;
	position: relative;
	margin-bottom: ${spacingPx(10)};
`;

export default Dashboard;
