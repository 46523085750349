import { Typography } from '@mui/material';
import { emptyStates } from 'assets';
import { CenteredColumn, Spacer } from 'components';
import styled from 'styled-components';
import { spacingPx } from 'theme';

const EmptyState = ({ active }: { active: boolean }) => {
	return (
		<Container>
			<Spacer height={15} />
			<EmptyStateImage src={emptyStates.residents} alt="empty-residents" />
			<Spacer height={15} />
			<Typography variant="h4" align="center">
				No {active ? 'Active ' : ''} Residents Yet
			</Typography>
			<Spacer height={4} />
			<Typography sx={{ maxWidth: 600 }}>
				Add your residents here and start saving time with resident-led
				inspections.
			</Typography>
		</Container>
	);
};

const EmptyStateImage = styled.img`
	height: 35vh;
	resize-mode: contain;
`;

const Container = styled(CenteredColumn)`
	padding: 0 ${spacingPx(40)};
`;

export default EmptyState;
