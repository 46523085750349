import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Autocomplete, MenuItem, Typography } from '@mui/material';
import { ApiInspection, ApiInspectionSkill } from '@rentcheck/types';
import { Column } from 'components';
import FormTextField from 'components/form-text-field';
import { useSkills } from 'hooks/skills';
import _ from 'lodash';
import { colors } from 'theme';

interface Props {
	value: ApiInspectionSkill | null;
	setValue: (value: ApiInspectionSkill | null) => void;
	error: string;
	inspection: ApiInspection;
}

const SkillRow = ({
	option,
	allOptions,
}: {
	option: ApiInspectionSkill;
	allOptions: ApiInspectionSkill[];
}) => {
	const hideVendor =
		option.vendor === 'RentCheck' ||
		_.uniq(allOptions.map((o) => o.vendor)).length === 1;

	if (hideVendor) {
		return <>{option.title}</>;
	}

	return (
		<Column>
			{option.title}
			<Typography color={colors.gray} fontSize={12} fontWeight={600}>
				{option.vendor}
			</Typography>
		</Column>
	);
};

const Skill = ({ value, setValue, error, inspection }: Props) => {
	const { title, skills, helperText: caption } = useSkills(inspection.id);

	return (
		<Autocomplete
			disablePortal
			options={skills}
			getOptionLabel={(option) => option.title}
			value={value}
			onChange={(_, newValue) => setValue(newValue)}
			id="textinput-skill"
			noOptionsText="Loading..."
			renderOption={(props, o) => (
				<MenuItem {...props}>
					<SkillRow option={o} allOptions={skills} />
				</MenuItem>
			)}
			renderInput={(params) => (
				<FormTextField
					{...params}
					title={title}
					caption={caption}
					placeholder="Select one"
					error={!!error}
					helperText="Required"
					icon={regular('wrench')}
				/>
			)}
		/>
	);
};

export default Skill;
