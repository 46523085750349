import { Dialog, DialogContent, DialogTitle, Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Spacer } from 'components';
import { useFlagImagesModalData } from 'hooks/modals';
import { ModalFlowActions } from 'store/actions';
import { Dispatch } from 'types';

import { Utils } from '@rentcheck/biz';
import { imageIs360Photo } from 'utils/helpers';
import Header from './header';
import Image from './image';
import Image360 from './image-360';
import Video from './video';

interface Props {
	open: boolean;
}

export default ({ open }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const modalData = useFlagImagesModalData();
	const flags = modalData?.metadata?.flags;

	const [currentIndex, setCurrentIndex] = useState(0);

	useEffect(() => {
		if (!modalData?.metadata?.initialFlag) {
			return;
		}

		if (!modalData?.metadata?.flags) {
			return;
		}

		const initialFlag = modalData.metadata.initialFlag;
		const flags = modalData.metadata.flags;

		const allMedia = flags
			.map((f) => [...(f.image_urls ?? []), ...(f.video_urls ?? [])])
			.flat();

		const initialImage = initialFlag.image_urls?.[0];

		setCurrentIndex(allMedia.indexOf(initialImage ?? '') ?? 0);
	}, [modalData]);

	const handleClose = () => {
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	const handleNext = () => {
		setCurrentIndex((currentIndex + 1) % allMedia.length);
	};

	const handlePrevious = () => {
		setCurrentIndex((currentIndex - 1 + allMedia.length) % allMedia.length);
	};

	const allMedia =
		flags
			?.filter((f) => f.image_urls || f.video_urls)
			.map((f) =>
				[...(f.image_urls ?? []), ...(f.video_urls ?? [])].map((uri) => ({
					uri,
					flag: f,
				}))
			)
			.flat() ?? [];

	const currentImage = allMedia[currentIndex]?.uri;
	const currentFlag = allMedia[currentIndex]?.flag;

	return (
		<Dialog fullScreen open={open} onClose={handleClose}>
			{!!currentFlag && (
				<>
					<DialogTitle sx={{ paddingLeft: 4, paddingRight: 4 }}>
						<Header
							property={modalData?.metadata?.property}
							flag={currentFlag}
						/>
						<Spacer height={4} />
						<Divider />
					</DialogTitle>

					<DialogContent>
						{!imageIs360Photo(currentImage) &&
							!Utils.Media.isVideo(currentImage) && (
								<Image
									imageUri={currentImage}
									handleNext={handleNext}
									handlePrevious={handlePrevious}
								/>
							)}
						{Utils.Media.isVideo(currentImage) && (
							<Video
								imageUri={currentImage}
								handleNext={handleNext}
								handlePrevious={handlePrevious}
							/>
						)}
						{imageIs360Photo(currentImage) && (
							<Image360
								imageUri={currentImage}
								handleNext={handleNext}
								handlePrevious={handlePrevious}
							/>
						)}
					</DialogContent>
				</>
			)}
		</Dialog>
	);
};
