import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Rules } from '@rentcheck/biz';
import { ApiInspectionWithTemplate } from '@rentcheck/types';
import IconButton from 'components/icon-button';
import { useDispatch } from 'react-redux';
import { ModalFlowActions } from 'store/actions';

interface Props {
	inspection: ApiInspectionWithTemplate;
}

export default ({ inspection }: Props) => {
	const dispatch = useDispatch();

	const handleShare = () => {
		dispatch(
			ModalFlowActions.showShareInspectionModal({
				object: inspection,
			})
		);
	};

	if (!Rules.Inspections.canShare(inspection)) {
		return null;
	}

	return (
		<IconButton icon={regular('share')} tooltip="Share" onClick={handleShare} />
	);
};
