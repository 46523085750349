import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip, CircularProgress, Tooltip } from '@mui/material';
import React from 'react';

import { colors } from 'theme';

interface Props {
	ids: string[];
	openMenu: (e: React.MouseEvent<HTMLElement>) => void;
	loading?: boolean;
}

const ActionsChip = ({ ids, openMenu, loading }: Props) => {
	return (
		<Tooltip title="Take bulk action" placement="bottom-end" arrow>
			<Chip
				label="Actions"
				deleteIcon={
					<>
						{loading && <CircularProgress size={15} color="secondary" />}
						{!loading && (
							<FontAwesomeIcon
								icon={solid('ellipsis-v')}
								color={colors.black}
								size="sm"
								style={{ marginLeft: 4, marginRight: 8 }}
							/>
						)}
					</>
				}
				onClick={ids.length > 0 ? openMenu : undefined}
				onDelete={openMenu}
				size="small"
				sx={{
					paddingLeft: 1,
					paddingRight: 1,
					backgroundColor: ids.length > 0 ? '' : 'transparent',
					fontWeight: 400,
					fontSize: '0.625rem',
				}}
				clickable={ids.length > 0}
			/>
		</Tooltip>
	);
};

export default ActionsChip;
