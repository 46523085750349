import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip, Menu, Typography } from '@mui/material';
import { Column } from 'components/layout/Column';
import { Row } from 'components/layout/Row';
import { ReactNode, useState } from 'react';
import * as theme from 'theme';

import ClearButton from './clear-button';
import FilterChip from './components/filter-chip';
import FilterChipContent from './components/filter-chip-content';
import FilterMenuItem from './components/filter-menu-item';
import FilterBuilder from './filter-builder';

export interface Filter {
	menuItemComponent: () => ReactNode;
	component: () => ReactNode;
}

interface Props {
	title: string;
	filters: Filter[];
	onClearFilters: () => void;
	appliedFiltersCount: number;
}

export default ({
	title,
	filters,
	onClearFilters,
	appliedFiltersCount,
}: Props) => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<Row style={{ gap: '8px', flexWrap: 'wrap' }}>
				{filters.map((f) => f.component())}
				<Chip
					label="Add filter"
					variant="outlined"
					icon={<FontAwesomeIcon icon={solid('plus')} />}
					onClick={handleClick}
					sx={{
						'.MuiChip-icon': {
							color: theme.colors.primary,
						},
					}}
					style={anchorEl ? { border: '1px solid #757CF0' } : undefined}
				/>
				<ClearButton
					appliedFiltersCount={appliedFiltersCount}
					onClearFilters={onClearFilters}
				/>
			</Row>
			<Menu
				open={!!anchorEl}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
				transformOrigin={{ vertical: 'top', horizontal: 'left' }}
				sx={{ '.MuiList-padding': { p: 1 } }}>
				<Typography variant="overline" ml={1} color="#445567">
					{title} Filters
				</Typography>
				<Column style={{ maxHeight: 400, overflow: 'scroll' }}>
					{filters.map((f) => f.menuItemComponent())}
				</Column>
			</Menu>
		</>
	);
};

export { FilterBuilder, FilterChip, FilterChipContent, FilterMenuItem };
