import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { LoadingButton } from '@mui/lab';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Typography,
} from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DialogTitle } from 'components';
import { useInspectionRevisionMetadata } from 'hooks/modals';
import {
	FeatureActions,
	InspectionActions,
	ModalFlowActions,
	SnackbarActions,
} from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';

import { useIsMobileBreakpoint } from 'utils/hooks';
import DueDate from './due-date';
import Note from './note';
import Revisions from './revisions';

interface Props {
	open: boolean;
}

export default ({ open }: Props) => {
	const dispatch: Dispatch = useDispatch();
	const isMobile = useIsMobileBreakpoint();

	const profile = useTypedSelector((state) => state.activeProfile);

	const modalData = useInspectionRevisionMetadata();
	const inspection = modalData?.metadata?.inspection;
	const features = modalData?.metadata?.features;

	const [loading, setLoading] = useState(false);
	const [revisionNotes, setRevisionNotes] = useState<{ [key: string]: string }>(
		{}
	);

	const [note, setNote] = useState('');
	const [dueDate, setDueDate] = useState<Date>();

	useEffect(() => {
		setNote('');
		setDueDate(moment().add(2, 'd').toDate());
		setRevisionNotes({});
	}, [open]);

	useEffect(() => {
		if (!features) return;

		const featureWithRevisionNotes = features
			.filter((f) => f.revision_notes)
			.map((f) => {
				return { [f.id]: f.revision_notes ?? '' };
			});

		setRevisionNotes(Object.assign({}, ...featureWithRevisionNotes));
	}, [features]);

	const handleClose = () => {
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	const handleRequestRevision = () => {
		if (!dueDate) {
			return dispatch(SnackbarActions.showError('Please select a due date'));
		}

		if (revisionNotes) {
			const featureIds = Object.keys(revisionNotes);
			featureIds.forEach((id) =>
				dispatch(
					FeatureActions.updateFeature(id, {
						revision_notes: revisionNotes[id],
					})
				)
			);
		}

		setLoading(true);

		const revisions = features.filter((f) => f.revision_requested);

		dispatch(
			InspectionActions.rejectInspection(
				inspection,
				profile,
				note,
				dueDate,
				revisions
			)
		)
			.then(() =>
				dispatch(
					SnackbarActions.showSuccess('Revision request sent successfully!')
				)
			)
			.then(handleClose)
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	if (!inspection || !features) {
		return null;
	}

	const completedByName =
		inspection.completed_by?.name ?? 'the inspection performer';

	return (
		<Dialog open={open} onClose={handleClose} fullScreen={isMobile}>
			<DialogTitle
				title="Request Revision"
				icon={solid('pen-swirl')}
				onClose={handleClose}
			/>

			<DialogContent>
				<Typography>
					Need something updated or added to this inspection? We'll reopen the
					inspection and send a notification to {completedByName} with a link to
					update it along with your instructions of what revisions need to be
					made.
				</Typography>
				<Revisions
					inspection={inspection}
					setRevisionNotes={setRevisionNotes}
					revisionNotes={revisionNotes}
				/>
				<DueDate dueDate={dueDate} setDueDate={setDueDate} />
				<Note note={note} setNote={setNote} />
			</DialogContent>

			<DialogActions>
				<Button onClick={handleClose} variant="text" color="secondary">
					Cancel
				</Button>
				<LoadingButton
					disabled={!dueDate}
					variant="contained"
					onClick={handleRequestRevision}
					loading={loading}>
					Request Revision
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};
