import { ApiTeam } from '@rentcheck/types';
import _ from 'lodash';
import { TeamsActionType, actionTypes } from 'store/actions/teams';

export type State = ApiTeam[];

type Action = {
	type: TeamsActionType;
	teams: ApiTeam[];
};

export default (state: State = [], { type, teams }: Action) => {
	switch (type) {
		case actionTypes.FETCHED_TEAMS: {
			const newState = _.uniqBy([...teams, ...state], 'id');
			return _.sortBy(newState, ['internal_label', 'name']);
		}
		case actionTypes.IMPERSONATE:
			return [];
		case actionTypes.SIGNOUT_SUCCESS:
			return [];
		default:
			return state;
	}
};
