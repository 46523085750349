import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ApiMaintenanceReport } from '@rentcheck/types';
import IconButton from 'components/icon-button';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FileRequestsActions } from 'store/actions';
import { Dispatch } from 'types';
import { Analytics } from 'utils';

interface Props {
	report: ApiMaintenanceReport;
}

export default ({ report }: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const [loading, setLoading] = useState(false);

	const handleDownloadPDF = async () => {
		if (report.pdf_report_location) {
			Analytics.trackEvent('downloaded_maintenance_report', {
				report_id: report.id,
			});

			window.open(report.pdf_report_location);
			return;
		}

		setLoading(true);

		dispatch(
			FileRequestsActions.create({
				documentType: 'MAINTENANCE_REPORT',
				documentId: report.id,
				exportType: 'PDF',
				dontNotify: true,
				title: `${report.name} Report`,
				subtitle: report.property.address,
				type: 'download',
			})
		).finally(() => {
			setLoading(false);
		});
	};

	return (
		<IconButton
			loading={loading}
			icon={regular('download')}
			tooltip="Download"
			onClick={handleDownloadPDF}
		/>
	);
};
