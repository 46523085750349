import { Typography } from '@mui/material';
import { ApiTeam } from '@rentcheck/types';
import { PopoverDropdown, Spacer } from 'components';
import { ConfirmationModal } from 'components/Common';
import Modal, { Footer } from 'components/Common/Modal';
import { useStoreTeams } from 'hooks/teams';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { PropertiesActions, SnackbarActions } from 'store/actions';
import { Dispatch } from 'types';

interface Props {
	propertyIds: string[];
	propertiesReassigned: () => void;
	setModalVisible: (visible: boolean) => void;
}

const AssignTeamModal = ({
	propertyIds,
	setModalVisible,
	propertiesReassigned,
}: Props) => {
	const dispatch: Dispatch = useDispatch();

	const { teams, loading } = useStoreTeams();

	const [confirmationModalVisible, setConfirmationModalVisible] =
		useState(false);

	const [selectedOrganization, setSelectedOrganization] = useState<
		ApiTeam | undefined
	>(undefined);

	const popoverItems =
		teams?.map((o) => ({
			title: o.internal_label,
			selected: selectedOrganization?.id === o.id,
			disabled: o.user_role !== 'admin',
			disabledTooltip: 'Only team Admin users can edit unit team assignments.',
			onClick: () => {
				setSelectedOrganization(o);
			},
		})) ?? [];

	const organizationName = selectedOrganization?.internal_label ?? '';

	const handleReassign = async () => {
		if (!selectedOrganization) return;

		setModalVisible(false);

		const progress = await dispatch(
			SnackbarActions.showProgress(`Reassigning to ${organizationName}`)
		);

		dispatch(
			PropertiesActions.units.update(propertyIds, {
				team_id: selectedOrganization.id,
			})
		)
			.then(() => {
				propertiesReassigned();
				dispatch(
					SnackbarActions.showSuccess(`Assigned to ${organizationName}`)
				);
			})
			.catch(() => dispatch(SnackbarActions.showError()))
			.finally(() => dispatch(SnackbarActions.hideSnackbar(progress.id)));
	};

	const singularOrPlural =
		propertyIds.length === 1 ? 'this unit' : 'these units';

	return (
		<>
			<Modal setHidden={() => setModalVisible(false)}>
				<Typography variant="h4">
					Which team do you want to assign {singularOrPlural} to?
				</Typography>
				<Spacer height={7} />
				<PopoverDropdown
					type="radio"
					options={popoverItems}
					width={134}
					loading={loading}
					title={organizationName ?? 'Select a team'}
					titleIsSelectedOption={!!organizationName}
				/>
				<Footer
					hideDivider
					leftButtonTitle="Cancel"
					leftButtonOnClick={() => setModalVisible(false)}
					disabled={!selectedOrganization}
					rightButtonTitle="Reassign"
					rightButtonOnClick={() => setConfirmationModalVisible(true)}
				/>
			</Modal>
			{confirmationModalVisible && (
				<ConfirmationModal
					title="Are you sure?"
					body={`Are you sure you want to reassign the team for these units? ${propertyIds.length} units will be updated.`}
					cancelTitle="Cancel"
					confirmTitle="Reassign"
					cancelPressed={() => setConfirmationModalVisible(false)}
					confirmPressed={handleReassign}
				/>
			)}
		</>
	);
};

export default AssignTeamModal;
