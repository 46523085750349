import { IntegrationRentcheckApi } from '@rentcheck/types';
import firebase from 'config/fbConfig';
import _ from 'lodash';
import {
	objectFromDocumentSnapshot,
	objectsFromQuerySnapshot,
} from './helpers';

export const get = async (
	id: string
): Promise<IntegrationRentcheckApi | undefined> =>
	firebase
		.firestore()
		.collection('integration_rentcheck_api')
		.doc(id)
		.get()
		.then((doc) => objectFromDocumentSnapshot<IntegrationRentcheckApi>(doc));

export const getForSubscriptionId = async (
	id: string
): Promise<IntegrationRentcheckApi | undefined> =>
	firebase
		.firestore()
		.collection('integration_rentcheck_api')
		.where('subscription_id', '==', id)
		.get()
		.then((data) => objectsFromQuerySnapshot<IntegrationRentcheckApi>(data))
		.then((data) => _.first(data));

export const deleteForSubscriptionId = async (id: string) => {
	const documents = await firebase
		.firestore()
		.collection('integration_rentcheck_api')
		.where('subscription_id', '==', id)
		.get()
		.then((data) => objectsFromQuerySnapshot<IntegrationRentcheckApi>(data));

	await Promise.all(
		documents.map((d) =>
			firebase
				.firestore()
				.collection('integration_rentcheck_api')
				.doc(d.id)
				.delete()
		)
	);
};
