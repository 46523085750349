import { Button, TextField, Typography } from '@mui/material';
import { Column, SpacedRow, Spacer } from 'components';
import { useTeams } from 'hooks/teams';
import { useState } from 'react';
import styled from 'styled-components';
import { colors, spacingPx } from 'theme';

interface Props {
	name: string;
	internalLabel: string;
	setName: (name: string) => void;
	setInternalLabel: (label: string) => void;
	nextStep: () => void;
	cancel: () => void;
}

const TeamName = ({
	name,
	internalLabel,
	setName,
	setInternalLabel,
	nextStep,
	cancel,
}: Props) => {
	const { teams } = useTeams();

	const [nameValue, setNameValue] = useState(name);
	const [internalLabelValue, setInternalLabelValue] = useState(internalLabel);

	const disabled = !nameValue || !internalLabelValue;

	return (
		<Column>
			<Typography variant="h5">Create Team</Typography>
			<Spacer height={6} />
			{teams.length > 0 && (
				<>
					<Typography>
						Set up multiple teams to give individuals access to specific groups
						of units within your portfolio. Team members will only be able to
						view and manage the units and inspections for the team or teams to
						which they've been invited.
					</Typography>
					<Spacer height={6} />
				</>
			)}
			<TextField
				id="textinput-name"
				variant="filled"
				placeholder="Name (used in resident communications & usually company name)"
				onChange={(e) => setNameValue(e.target.value)}
				value={nameValue}
			/>
			<Spacer height={6} />
			<TextField
				id="textinput-internal_label"
				variant="filled"
				placeholder="Internal Label (internal name displayed to you and team members)"
				onChange={(e) => setInternalLabelValue(e.target.value)}
				value={internalLabelValue}
			/>
			<Spacer height={10} />
			<Divider />
			<Spacer height={10} />
			<SpacedRow>
				<ButtonContainer left>
					<Button variant="outlined" color="secondary" onClick={cancel}>
						Cancel
					</Button>
				</ButtonContainer>
				<ButtonContainer right>
					<Button
						disabled={disabled}
						onClick={() => {
							setName(nameValue);
							setInternalLabel(internalLabelValue);
							nextStep();
						}}>
						Next
					</Button>
				</ButtonContainer>
			</SpacedRow>
		</Column>
	);
};

const ButtonContainer = styled.div<{ right?: boolean; left?: boolean }>`
	display: flex;
	flex-direction: column;
	padding-left: ${({ right }) => (right ? spacingPx(2) : 0)};
	padding-right: ${({ left }) => (left ? spacingPx(2) : 0)};
`;

const Divider = styled.div`
	height: 1px;
	width: 100%;
	background-color: ${colors.contentBorder};
`;

export default TeamName;
