import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Rules, Utils } from '@rentcheck/biz';
import { ApiInspectionWithTemplate } from '@rentcheck/types';
import { FeatureWithMetadata } from 'store/reducers/features';
import FlagsTooltip from '../../common/flags-tooltip';

interface Props {
	feature: FeatureWithMetadata;
	inspection: ApiInspectionWithTemplate;
	image: string;
}

export default ({ feature, inspection, image }: Props) => {
	if (!Rules.Inspections.canPerformFeatureActions(inspection)) {
		return null;
	}

	const flags = Utils.InspectionFeatures.getFlagsForMedia(feature, image);

	if (!flags.length) {
		return null;
	}

	return (
		<div
			style={{
				position: 'absolute',
				bottom: 0,
				right: 0,
				height: 36,
				width: 36,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}>
			<FlagsTooltip flags={flags}>
				<FontAwesomeIcon
					icon={solid('flag')}
					color="#fff"
					style={{
						filter: 'drop-shadow( 1px 1px 1px rgba(0, 0, 0, .5))',
					}}
				/>
			</FlagsTooltip>
		</div>
	);
};
