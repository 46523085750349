import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Dialog } from '@mui/material';
import { useDispatch } from 'react-redux';

import { DialogTitle } from 'components';
import { useCreateMaintenanceReportModalData } from 'hooks/modals';
import { ModalFlowActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';
import { formattedInspectionAddress } from 'utils/helpers';

import { useIsMobileBreakpoint } from 'utils/hooks';
import Content from './content';

interface Props {
	open: boolean;
}

const CreateMaintenanceReport = ({ open }: Props) => {
	const dispatch: Dispatch = useDispatch();
	const isMobile = useIsMobileBreakpoint();

	const profile = useTypedSelector((state) => state.activeProfile);

	const modalData = useCreateMaintenanceReportModalData();
	const { inspection, flags } = modalData?.metadata ?? {};

	if (!profile || !inspection || !flags) return null;

	const handleClose = () => {
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	return (
		<Dialog open={open} onClose={handleClose} fullScreen={isMobile}>
			<DialogTitle
				title="Create Maintenance Report"
				icon={solid('wrench')}
				onClose={handleClose}
				bottomSubtitle={formattedInspectionAddress(inspection)}
			/>

			<Content />
		</Dialog>
	);
};

export default CreateMaintenanceReport;
