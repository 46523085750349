import { Dialog, DialogContent, DialogTitle, Divider } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Row, Spacer } from 'components';
import { useFeatureImageModalData } from 'hooks/modals';
import { ModalFlowActions } from 'store/actions';
import { Dispatch } from 'types';
import { getFeatureMedia, imageIs360Photo } from 'utils/helpers';

import styled from 'styled-components';
import { EventRegister } from 'utils';
import { ViewerProps } from './common';
import Header from './header';
import Image, { FeatureImageViewerRef } from './image';
import Image360 from './image-360';
import InfoSidebar from './info-sidebar';
import Video from './video';

interface Props {
	open: boolean;
}

export default ({ open }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const modalData = useFeatureImageModalData();
	const features = modalData?.metadata?.features;

	const imageViewerRef = useRef<FeatureImageViewerRef>(null);

	const [currentIndex, setCurrentIndex] = useState(0);

	useEffect(() => {
		if (!modalData?.metadata?.initialFeature) {
			return;
		}

		if (!modalData?.metadata?.features) {
			return;
		}

		const initialFeature = modalData.metadata.initialFeature;
		const initialIndex = modalData.metadata.initialIndex ?? 0;
		const features = modalData.metadata.features;

		const allMedia = features.map((f) => getFeatureMedia(f)).flat();

		const initialMedia = getFeatureMedia(initialFeature)[initialIndex];

		setCurrentIndex(allMedia.indexOf(initialMedia) ?? 0);
	}, [modalData]);

	if (!features) {
		return null;
	}

	const allMedia = features.map((f) => getFeatureMedia(f)).flat();

	const currentMedia = allMedia[currentIndex];
	const currentFeature = features.find(
		(f) =>
			f?.images?.includes(currentMedia) || f?.videos?.includes(currentMedia)
	);

	const handleClose = () => {
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	const handleNext = () => {
		const newIndex = (currentIndex + 1) % allMedia.length;
		const currentMedia = allMedia[newIndex];
		const currentFeature = features.find(
			(f) =>
				f?.images?.includes(currentMedia) || f?.videos?.includes(currentMedia)
		);

		currentFeature &&
			EventRegister.emit(
				'inspection.featuresModal.currentFeatureChanged',
				currentFeature
			);

		setCurrentIndex(newIndex);
	};

	const handlePrevious = () => {
		const newIndex = (currentIndex - 1 + allMedia.length) % allMedia.length;
		const currentMedia = allMedia[newIndex];
		const currentFeature = features.find((f) =>
			f.images.includes(currentMedia)
		);

		currentFeature &&
			EventRegister.emit(
				'inspection.featuresModal.currentFeatureChanged',
				currentFeature
			);
		setCurrentIndex(newIndex);
	};

	if (!features || !currentFeature) {
		return <Dialog fullScreen open={open} onClose={handleClose} />;
	}

	const viewerMode = () => {
		if (currentFeature.videos.includes(currentMedia)) {
			return 'video';
		}

		if (imageIs360Photo(currentMedia)) {
			return 'image360';
		}

		return 'image';
	};

	const viewerProps: ViewerProps = {
		imageUri: currentMedia,
		feature: currentFeature,
		handleNext,
		handlePrevious,
		mode: 'view',
	};

	return (
		<Dialog fullScreen open={open} onClose={handleClose}>
			<DialogTitle sx={{ paddingLeft: 4, paddingRight: 4 }}>
				<Header feature={currentFeature} />
				<Spacer height={4} />
				<Divider />
			</DialogTitle>

			<DialogContent>
				<Container
					style={{
						width: '100%',
						height: '100%',
						alignItems: 'flex-start',
						justifyContent: 'flex-start',
					}}>
					<InfoSidebar
						feature={currentFeature}
						mediaUri={currentMedia}
						imageViewerRef={imageViewerRef}
					/>

					{viewerMode() === 'image360' && <Image360 {...viewerProps} />}
					{viewerMode() === 'video' && <Video {...viewerProps} />}
					{viewerMode() === 'image' && (
						<Image {...viewerProps} ref={imageViewerRef} />
					)}
				</Container>
			</DialogContent>
		</Dialog>
	);
};

const Container = styled(Row)`
	width: 100%;
	height: 100%;
	alignitems: flex-start;
	justifycontent: flex-start;
`;
