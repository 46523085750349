import { Button } from '@mui/material';
import React, { useState } from 'react';

import UpgradeSuccessModal from '../upgrade-success-modal';

interface Props {
	isInstalled: boolean;
	vendor: string;
	parentUrl: string;

	InstallModal: React.ComponentType<{
		open: boolean;
		handleClose: () => void;
	}>;
}

const InstallButton = ({
	isInstalled,
	vendor,
	parentUrl,
	InstallModal,
}: Props) => {
	const [modalOpen, setModalOpen] = useState(false);

	const handleConnect = () => {
		setModalOpen(true);
	};

	return (
		<>
			{!isInstalled && <Button onClick={handleConnect}>Install</Button>}

			<InstallModal open={modalOpen} handleClose={() => setModalOpen(false)} />

			<UpgradeSuccessModal
				onInstall={handleConnect}
				vendor={vendor}
				parentUrl={parentUrl}
			/>
		</>
	);
};

export default InstallButton;
