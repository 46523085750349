import { Inspection } from '@rentcheck/types';
import _ from 'lodash';

export interface DateRange {
	start: number;
	end: number;
}

export interface State {
	dateRange?: DateRange;
	inspectionTemplate: Inspection['inspection_template']['id'][];
	search: string;
	status: string[];

	sortBy: string;
	sortType: 'asc' | 'desc';
}

const initialState: State = {
	sortBy: 'created_date',
	sortType: 'desc',
	dateRange: undefined,
	search: '',
	inspectionTemplate: [],
	status: [],
};

const resetState: Partial<State> = {
	dateRange: undefined,
	inspectionTemplate: [],
	status: [],
};

type Action = {
	type: string;
	value?: string;
	dateRange: DateRange;
};

const reducer = (state: State = initialState, action: Action) => {
	switch (action.type) {
		case 'SIGNOUT_SUCCESS':
		case 'IMPERSONATE':
			return initialState;

		case 'TOGGLE_WORK_ORDERS_INSPECTION_TYPE_FILTER':
			if (!action.value) return state;
			return {
				...state,
				inspectionType: _.xor(state.inspectionTemplate, [action.value]),
			};

		case 'TOGGLE_WORK_ORDERS_STATUS_FILTER':
			if (!action.value) return state;
			return {
				...state,
				status: _.xor(state.status, [action.value]),
			};

		case 'SET_WORK_ORDERS_DATE_RANGE_FILTER':
			return { ...state, dateRange: action.dateRange };

		case 'TOGGLE_WORK_ORDERS_SORT_TYPE':
			return { ...state, sortType: state.sortType === 'asc' ? 'desc' : 'asc' };

		case 'SET_WORK_ORDERS_SORT_BY':
			if (!action.value) return state;
			return { ...state, sortBy: action.value, sortType: 'asc' };

		case 'SET_WORK_ORDERS_SEARCH_FILTER':
			return { ...state, search: action.value ?? '' };

		case 'RESET_WORK_ORDERS_FILTERS':
			return { ...state, ...resetState };

		default:
			return state;
	}
};

export const appliedFiltersCount = (state: State) => {
	return [
		!!state.dateRange,
		state.inspectionTemplate.length > 0,
		state.status.length > 0,
	].filter((f) => f).length;
};

export const hasAppliedFilters = (state: State) => {
	return appliedFiltersCount(state) > 0 || state.search.length > 0;
};

export default reducer;
