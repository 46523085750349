import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { InspectionEventsApi } from '@rentcheck/api-frontend';
import { ApiInspection, ApiInspectionEvent } from '@rentcheck/types';
import { SnackbarActions } from 'store/actions';
import { Dispatch } from 'types';

import { Timeline, timelineItemClasses } from '@mui/lab';
import TimelineListItem from './list-item';
import Skeleton from './skeleton';

interface Props {
	inspection: ApiInspection;
}

export default ({ inspection }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const [loading, setLoading] = useState(true);
	const [events, setEvents] = useState<ApiInspectionEvent[]>([]);

	useEffect(() => {
		InspectionEventsApi.getAll(inspection.id)
			.then((response) => {
				setEvents(response);
			})
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	}, [inspection]);

	return (
		<>
			{loading && <Skeleton />}

			<Timeline
				sx={{
					padding: 0,
					marginTop: -2,
					[`& .${timelineItemClasses.root}:before`]: {
						flex: 0,
						padding: 0,
					},
				}}>
				{events.map((e) => (
					<TimelineListItem key={e.id} event={e} />
				))}
			</Timeline>
		</>
	);
};
