import { useMediaQuery, useTheme } from '@mui/material';

import { landing } from 'assets';

import Authentication from '../authentication';
import Container from '../container';

const AppFolioLanding = () => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Container
			fullScreen={fullScreen}
			webLogo={landing.appFolio.logo}
			mobileLogo={landing.appFolio.logoWhite}>
			<Authentication planId="pro-plan-with-appfolio-&-30-day-free-trial" />
		</Container>
	);
};

export default AppFolioLanding;
