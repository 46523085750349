import { Row } from 'components';
import styled from 'styled-components';

import AllActive from './all-active';
import AlmostDue from './almost-due';
import Archived from './archived';
import AssignedToMe from './assigned-to-me';
import NeedsReview from './needs-review';
import Overdue from './overdue';

const QuickFilters = () => {
	return (
		<WrappingRow>
			<AllActive />
			<NeedsReview />
			<AlmostDue />
			<Overdue />
			<AssignedToMe />
			<Archived />
		</WrappingRow>
	);
};

const WrappingRow = styled(Row)`
	flex-wrap: wrap;
	gap: 8px;
`;

export default QuickFilters;
