import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
	Button,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	Divider,
	Switch,
	Tooltip,
	Typography,
} from '@mui/material';

import { colors } from '@rentcheck/theme';
import { TemplateFeature } from '@rentcheck/types';
import { Utils } from '@rentcheck/biz';

import { Dispatch } from 'types';
import { StorageApi } from 'api';
import { Column, DialogTitle, Row, SpacedRow } from 'components';
import FormTextField from 'components/form-text-field';
import IconButton from 'components/icon-button';
import { SnackbarActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';

interface Props {
	feature: TemplateFeature;
	disabled: boolean;
	onUpdate: (feature: TemplateFeature) => void;
}

const extractFileNameFromPhoto = (photo: string) => {
	const parts = photo.split('/');
	return parts[parts.length - 1];
};

export default ({ feature, disabled, onUpdate }: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const subscription = useTypedSelector((state) => state.subscription);

	const [open, setOpen] = useState(false);
	const [updatedFeature, setUpdatedFeature] =
		useState<TemplateFeature>(feature);
	const [photosRequiredValue, setPhotosRequiredValue] = useState<string>('');
	const [photoUploading, setPhotoUploading] = useState<boolean>(false);

	useEffect(() => {
		setUpdatedFeature(feature);
		setPhotosRequiredValue(
			feature.number_of_photos_required?.toString() ?? '1'
		);
	}, [feature]);

	const handleClick = () => {
		setOpen(true);
	};

	const handleSave = () => {
		onUpdate({
			...updatedFeature,
			number_of_photos_required: Number(photosRequiredValue),
		});

		setOpen(false);
	};

	const handleToggleRatingRequired = () => {
		setUpdatedFeature((feature) => ({
			...feature,
			is_rating_required: !updatedFeature.is_rating_required,
		}));
	};

	const handleToggleVideoEnabled = () => {
		setUpdatedFeature((feature) => ({
			...feature,
			is_video_enabled: !updatedFeature.is_video_enabled,
		}));
	};

	const handleChangeRequiredPhotos = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		const regex = /^[0-9\b]+$/;

		if (e.target.value && !regex.test(e.target.value)) {
			return;
		}

		setPhotosRequiredValue(e.target.value);
	};

	const handleUploadPhotos = (files: FileList | null) => {
		if (!files) return;
		if (!subscription) return;

		setPhotoUploading(true);

		StorageApi.uploadCustomFeatureImages(
			subscription.id,
			Array.from(files),
			updatedFeature.name
		)
			.then((data) => {
				const imagePaths = data.map((d) => d.path);
				const updatedReferencePhotos = [
					...(updatedFeature.reference_photos ?? []),
					...imagePaths,
				];
				setUpdatedFeature({
					...updatedFeature,
					reference_photos: updatedReferencePhotos,
				});
			})
			.catch((e) => {
				dispatch(
					SnackbarActions.showError('Error when uploading images: ' + e.message)
				);
			})
			.finally(() => setPhotoUploading(false));
	};

	return (
		<>
			<IconButton
				tabIndex={-1}
				disabled={disabled}
				onClick={handleClick}
				icon={regular('gear')}
				tooltip="Settings"
			/>

			<Dialog open={open} onClose={() => setOpen(false)}>
				<DialogTitle
					title="Feature Settings"
					bottomSubtitle={feature.name}
					icon={regular('gear')}
					onClose={() => setOpen(false)}
				/>
				<DialogContent sx={{ minHeight: 380 }}>
					<SpacedRow style={{ marginBottom: 16 }}>
						<Typography variant="subtitle2">
							Feature condition rating{' '}
							<Tooltip title="This allows the inspector to rate features as Poor, Fair, or Good during the inspection">
								<FontAwesomeIcon icon={regular('info-circle')} />
							</Tooltip>
						</Typography>
						<Row>
							<Typography variant="overline" color={colors.secondary}>
								{updatedFeature.is_rating_required ? 'on' : 'off'}
							</Typography>
							<Switch
								checked={updatedFeature.is_rating_required}
								onChange={handleToggleRatingRequired}
							/>
						</Row>
					</SpacedRow>

					<Divider />

					<SpacedRow style={{ marginTop: 16, marginBottom: 16 }}>
						<Typography variant="subtitle2">
							Number of photos required
						</Typography>
						<FormTextField
							inputSx={{ m: 0, width: 100 }}
							value={photosRequiredValue}
							onChange={handleChangeRequiredPhotos}
						/>
					</SpacedRow>

					<Divider />

					<SpacedRow style={{ marginTop: 16, marginBottom: 16 }}>
						<Typography variant="subtitle2">Add a reference photo</Typography>

						<LoadingButton
							variant="outlined"
							component="label"
							loading={photoUploading}
							startIcon={<FontAwesomeIcon icon={regular('image')} />}>
							Add Photo
							<VisuallyHiddenInput
								multiple
								type="file"
								accept="image/*"
								onChange={(e) => handleUploadPhotos(e.target.files)}
							/>
						</LoadingButton>
					</SpacedRow>

					{Utils.Subscriptions.hasPremiumVideoAddons(subscription) && (
						<>
							<Divider />

							<SpacedRow style={{ marginTop: 16 }}>
								<Typography variant="subtitle2">Video enabled</Typography>

								<Row>
									<Typography variant="overline" color={colors.secondary}>
										{updatedFeature.is_video_enabled ? 'on' : 'off'}
									</Typography>
									<Switch
										checked={updatedFeature.is_video_enabled}
										onChange={handleToggleVideoEnabled}
									/>
								</Row>
							</SpacedRow>
						</>
					)}

					<Column style={{ alignItems: 'flex-end' }}>
						{updatedFeature.reference_photos?.map((photo) => (
							<Chip
								sx={{ mt: 1 }}
								key={photo}
								label={extractFileNameFromPhoto(photo)}
								onDelete={() => {
									setUpdatedFeature({
										...updatedFeature,
										reference_photos: updatedFeature.reference_photos?.filter(
											(p) => p !== photo
										),
									});
								}}
							/>
						))}
					</Column>
				</DialogContent>
				<DialogActions>
					<Button
						variant="text"
						color="secondary"
						onClick={() => setOpen(false)}>
						Cancel
					</Button>
					<Button
						disabled={!photosRequiredValue || Number(photosRequiredValue) < 0}
						onClick={handleSave}>
						Save
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

const VisuallyHiddenInput = styled('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1,
});
