import { CardContent, Card as MuiCard, Skeleton } from '@mui/material';
import { Row, Spacer } from 'components';
import styled from 'styled-components';

export default () => {
	return (
		<Card elevation={0}>
			<CardContent>
				<Row>
					<Skeleton variant="circular" height={30} width={30} />
					<Spacer width={3} />
					<Skeleton variant="text" height={36} width={210} />
				</Row>
				<Spacer height={2} />
				<Skeleton variant="text" height={18} width="90%" />
				<Skeleton variant="text" height={18} width="85%" />
			</CardContent>
		</Card>
	);
};

const Card = styled(MuiCard)`
	border: 1px solid #0000001f;
	flex-grow: 1;
	height: 100%;
	transition: background-color 0.2s ease-in-out;

	:hover {
		background-color: #f6f5ff;
	}
`;
