import { CircularProgress, Snackbar, Typography } from '@mui/material';

import { Row, SpacedRow, Spacer } from 'components';

import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import IconButton from 'components/icon-button';
import { colors } from 'theme';
import { useIsMobileBreakpoint } from 'utils/hooks';

interface Props {
	onClose: () => void;
	onSave: () => void;
	saveLoading: boolean;
}

export default ({ onClose, onSave, saveLoading }: Props) => {
	const isMobile = useIsMobileBreakpoint(599);

	return (
		<Snackbar
			open
			autoHideDuration={null}
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
			<Row
				style={{
					backgroundColor: '#000000DE',
					padding: 14,
					borderRadius: 4,
					color: '#fff',
				}}>
				<SpacedRow>
					<Row>
						<IconButton
							icon={regular('times')}
							color="inherit"
							onClick={onClose}
						/>
						<Spacer width={2} />
						<Typography sx={{ whiteSpace: 'nowrap' }} color={colors.white}>
							Edit inspection feature
						</Typography>
					</Row>

					<Spacer width={20} />

					<Row>
						{!isMobile && (
							<LoadingButton
								loading={saveLoading}
								variant="outlined"
								color="inherit"
								onClick={onSave}
								sx={{ whiteSpace: 'nowrap' }}
								startIcon={<FontAwesomeIcon icon={solid('save')} />}
								loadingIndicator={
									<CircularProgress sx={{ color: '#fff' }} size={16} />
								}>
								Save Edits
							</LoadingButton>
						)}
						{isMobile && (
							<IconButton
								loading={saveLoading}
								icon={solid('save')}
								color="inherit"
								onClick={onSave}
								tooltip="Save Edits"
							/>
						)}
					</Row>
				</SpacedRow>
			</Row>
		</Snackbar>
	);
};
