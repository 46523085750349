import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Divider,
	Skeleton,
	useMediaQuery,
	useTheme,
} from '@mui/material';

import { DialogTitle, Screens } from 'components';
import { isEmbeddedInMobileApp } from 'utils/helpers';

import { handleClose } from './common';

export default () => {
	const theme = useTheme();
	const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Screens.Container>
			<Dialog
				open
				onClose={handleClose}
				fullScreen={mobileScreen || isEmbeddedInMobileApp()}>
				<DialogTitle
					title="Share Inspection"
					icon={solid('share')}
					onClose={handleClose}
				/>
				<DialogContent>
					<Skeleton variant="rectangular" height={20} width="25%" />
					<Skeleton
						variant="rectangular"
						height={40}
						width="100%"
						style={{ marginTop: 14 }}
					/>
					<Skeleton
						variant="rectangular"
						height={40}
						width="100%"
						style={{ marginTop: 14 }}
					/>
					<Divider sx={{ mt: 2 }} />
					<Skeleton
						variant="rectangular"
						height={20}
						width="25%"
						style={{ marginTop: 20 }}
					/>
					<Skeleton
						variant="rectangular"
						height={52}
						width="100%"
						style={{ marginTop: 14 }}
					/>
					<Divider sx={{ mt: 2 }} />
					<Skeleton
						variant="rectangular"
						height={20}
						width="15%"
						style={{ marginTop: 20 }}
					/>
					<Skeleton
						variant="rectangular"
						height={52}
						width="100%"
						style={{ marginTop: 14 }}
					/>
				</DialogContent>
				<DialogActions>
					<Button variant="text" color="secondary" onClick={handleClose}>
						Cancel
					</Button>
					<Button disabled>Share</Button>
				</DialogActions>
			</Dialog>
		</Screens.Container>
	);
};
