import { SelectChangeEvent } from '@mui/material';
import FormTextField from 'components/form-text-field';
import MenuItem from 'components/menu-item';
import usePermissionGroups from 'hooks/permission-groups';
import { ChangeEvent } from 'react';

interface Props {
	value: string[];
	setValue: (value: string[]) => void;
	caption?: string;
	required?: boolean;
}

export default ({
	value,
	setValue,
	caption = 'Select one or more permission groups to assign.',
	required,
}: Props) => {
	const { permissionGroups, loading } = usePermissionGroups(100);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const selectEvent = e as SelectChangeEvent<string[]>;
		const newValue = selectEvent.target.value;
		setValue(newValue as string[]);
	};

	return (
		<FormTextField
			title="Assign Permission Groups"
			caption={caption}
			placeholder="Select Permission Groups"
			required={required}
			loading={loading}
			select
			multiple
			value={value}
			onChange={handleChange}>
			{permissionGroups.map((pg) => (
				<MenuItem key={pg.id} value={pg.id}>
					{pg.name}
				</MenuItem>
			))}
		</FormTextField>
	);
};
