import { Skeleton } from '@mui/material';
import { Column, Spacer } from 'components';

export default () => {
	return (
		<Column>
			<Skeleton variant="text" width={'85%'} height={30} />
			<Skeleton variant="text" width={'55%'} height={30} />
			<Spacer height={5} />
			<Skeleton variant="text" width={'85%'} height={30} />
			<Skeleton variant="text" width={'55%'} height={30} />
			<Spacer height={5} />
			<Skeleton variant="text" width={'85%'} height={30} />
			<Skeleton variant="text" width={'55%'} height={30} />
			<Spacer height={5} />
			<Skeleton variant="text" width={'85%'} height={30} />
			<Skeleton variant="text" width={'55%'} height={30} />
			<Spacer height={5} />
			<Skeleton variant="text" width={'85%'} height={30} />
			<Skeleton variant="text" width={'55%'} height={30} />
			<Spacer height={5} />
			<Skeleton variant="text" width={'85%'} height={30} />
			<Skeleton variant="text" width={'55%'} height={30} />
			<Spacer height={5} />
			<Skeleton variant="text" width={'85%'} height={30} />
			<Skeleton variant="text" width={'55%'} height={30} />
			<Spacer height={5} />
		</Column>
	);
};
