import {
	Divider,
	AccordionSummary as MuiAccordionSummary,
	Typography,
	styled,
} from '@mui/material';

import { ApiInspectionWithTemplate } from '@rentcheck/types';
import { Column, Row, SpacedRow } from 'components';
import { FeatureWithMetadata } from 'store/reducers/features';

import { colors } from 'theme';
import { isEmbeddedInMobileApp } from 'utils/helpers';
import Collapse from './collapse';
import FeatureActions from './feature-actions';
import Flags from './flags';
import Navigation from './navigation';
import Rating from './rating';

interface Props {
	feature: FeatureWithMetadata;
	features: FeatureWithMetadata[];
	inspection: ApiInspectionWithTemplate;
	expanded: boolean;
	setExpanded: (expanded: boolean) => void;
}

export default ({
	feature,
	features,
	inspection,
	expanded,
	setExpanded,
}: Props) => {
	return (
		<AccordionSummary onClick={() => setExpanded(!expanded)}>
			<Column style={{ flex: 1 }}>
				<SpacedRow style={{ marginBottom: 12 }}>
					<Column>
						<Typography variant="h6">
							{feature.section.name_with_ordinal}
						</Typography>
						<Typography variant="subtitle2">{feature.name}</Typography>
					</Column>
				</SpacedRow>
				<SpacedRow style={{ marginBottom: 8 }}>
					<Row>
						<Rating feature={feature} inspection={inspection} />
						<Flags feature={feature} inspection={inspection} />
					</Row>
					<Row>
						<Navigation feature={feature} features={features} />
						<FeatureActions feature={feature} inspection={inspection} />
						<Collapse
							feature={feature}
							expanded={expanded}
							setExpanded={setExpanded}
						/>
					</Row>
				</SpacedRow>
				<Divider />
			</Column>
		</AccordionSummary>
	);
};

const AccordionSummary = styled(MuiAccordionSummary)`
	padding: 0;
	padding-top: 8px;

	margin: 0;

	position: sticky;
	top: ${isEmbeddedInMobileApp() ? '69px' : '56px'};
	background-color: ${colors.white};
	z-index: 1;

	.MuiAccordionSummary-content {
		margin: 0;
	}
`;
