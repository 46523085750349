import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Collapse } from '@mui/material';
import { Feature } from '@rentcheck/types';
import { Column, Row } from 'components';
import ToggleButton, { ToggleButtonRef } from 'components/toggle-button';
import { useEffect, useRef, useState } from 'react';
import { useIsMobileBreakpoint } from 'utils/hooks';

import Sidebar from '../sidebar';

interface Props {
	feature: Feature;
	featurePayload: Partial<Feature>;
	setFeaturePayload: (value: Partial<Feature>) => void;
}

export default ({ feature, featurePayload, setFeaturePayload }: Props) => {
	const isMobileBreakpoint = useIsMobileBreakpoint(601);

	const ref = useRef<ToggleButtonRef>(null);

	const [showInfo, setShowInfo] = useState(true);

	useEffect(() => {
		setTimeout(() => {
			ref.current?.click();
		}, 50);
	}, [feature]);

	if (isMobileBreakpoint) {
		return (
			<Row style={{ marginBottom: 12, marginRight: -40 }}>
				<ToggleButton
					ref={ref}
					selectedWhenMenuOpen
					icon={regular('info-circle')}
					tooltip={showInfo ? 'Hide Info' : 'View Info'}
					onClick={() => setShowInfo(!showInfo)}
					closeOnClickInside={false}>
					<Sidebar
						feature={feature}
						featurePayload={featurePayload}
						setFeaturePayload={setFeaturePayload}
						maxWidth={300}
					/>
				</ToggleButton>
			</Row>
		);
	}

	if (!isMobileBreakpoint) {
		return (
			<Column
				style={{
					marginRight: -40,
					height: '100%',
					position: 'relative',
				}}>
				<Row style={{ marginBottom: 12 }}>
					<ToggleButton
						selected={showInfo}
						icon={regular('info-circle')}
						tooltip={showInfo ? 'Hide Info' : 'View Info'}
						onClick={() => setShowInfo(!showInfo)}
					/>
				</Row>
				<Collapse
					in={showInfo}
					orientation="horizontal"
					sx={{ height: '100%', overflow: 'hidden' }}>
					<Sidebar
						style={{ marginRight: 56 }}
						feature={feature}
						featurePayload={featurePayload}
						setFeaturePayload={setFeaturePayload}
						maxWidth={300}
					/>
				</Collapse>
			</Column>
		);
	}

	return null;
};
