import { Dialog } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { DashboardActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';
import Title from './common/dialog-title';
import InspectionTable from './common/inspections-table';

const Overdue = () => {
	const location = useLocation();
	const history = useHistory();
	const dispatch: Dispatch = useDispatch();

	const open = location.pathname.startsWith('/dashboard/overdue');

	const dashboardData = useTypedSelector((state) => state.dashboard.data);
	const dashboardItems = useTypedSelector(
		(state) => state.dashboard.list.overdue
	);

	const handleClose = () => {
		history.push('/dashboard');
	};

	const handleFetch = (from: number) => {
		return dispatch(DashboardActions.getOverdue(from));
	};

	return (
		<Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
			<Title title="Inspections" chipTitle="Overdue" />
			<InspectionTable
				open={open}
				data={dashboardItems ?? []}
				fetch={handleFetch}
				total={dashboardData?.todo_list.overdue_inspections_count ?? 0}
			/>
		</Dialog>
	);
};

export default Overdue;
