import {
	LoadingButton as MUILoadingButton,
	LoadingButtonProps as MuiLoadingButtonProps,
} from '@mui/lab';
import {
	Button as MUIButton,
	ButtonProps as MuiButtonProps,
	Tooltip,
} from '@mui/material';
import PlanGateAnimatedIcon from 'components/plan-gate/animated-icon';
import { PlanGateContext } from 'components/plan-gate/context';
import { ReactNode, useContext } from 'react';

export interface ButtonProps extends MuiButtonProps {
	tooltip?: ReactNode;
}

export interface LoadingButtonProps extends MuiLoadingButtonProps {
	tooltip?: ReactNode;
}

export const Button = ({ tooltip, ...props }: ButtonProps) => {
	const context = useContext(PlanGateContext);

	if (!context.accessAllowed) {
		props.startIcon = (
			<PlanGateAnimatedIcon
				variant={props.variant === 'outlined' ? 'blue' : 'white'}
				mt={-14}
			/>
		);
	}

	return (
		<Tooltip title={tooltip ?? ''}>
			<div>
				<MUIButton {...props} />
			</div>
		</Tooltip>
	);
};

export const LoadingButton = ({ tooltip, ...props }: LoadingButtonProps) => {
	const context = useContext(PlanGateContext);

	if (!context.accessAllowed) {
		props.startIcon = (
			<PlanGateAnimatedIcon
				variant={props.variant === 'outlined' ? 'blue' : 'white'}
				mt={-11}
			/>
		);
	}

	return (
		<Tooltip title={tooltip ?? ''}>
			<div>
				<MUILoadingButton {...props} />
			</div>
		</Tooltip>
	);
};
