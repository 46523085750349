import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Typography,
} from '@mui/material';
import { Column, Spacer } from 'components';
import { OkToEnterT } from '../../common';

interface Props {
	value: OkToEnterT;
	setValue: (value: OkToEnterT) => void;
}

const OkToEnter = ({ value, setValue }: Props) => {
	return (
		<>
			<Column>
				<Typography variant="overline">Ok to enter</Typography>
				<Typography variant="caption" sx={{ marginTop: -0.5 }}>
					This is the work order field ‘Permission to Enter’ in AppFolio
				</Typography>
			</Column>

			<Spacer height={2} />

			<FormControl fullWidth>
				<InputLabel
					sx={
						value ? { transform: 'translate(12px,8px) scale(0.75)' } : undefined
					}>
					OK to enter
				</InputLabel>
				<Select
					value={value}
					variant="filled"
					onChange={(e) => setValue(e.target.value as OkToEnterT)}>
					<MenuItem id="No" value="No">
						No
					</MenuItem>
					<MenuItem id="Yes" value="Yes">
						Yes
					</MenuItem>
				</Select>
			</FormControl>

			<Spacer height={4} />
		</>
	);
};

export default OkToEnter;
