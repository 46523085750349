import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Screens } from 'components';
import ChipMenu from 'components/chip-menu';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ApiTeam } from '@rentcheck/types';
import { useStoreTeams } from 'hooks/teams';
import { PropertyTypeOption } from './common/types';
import CreateTemplateButton from './create-template-button';
import Templates from './templates';

const propertyTypeIcons = {
	Units: solid('home'),
	Buildings: solid('buildings'),
	Communities: solid('bench-tree'),
	All: solid('house-building'),
};

const propertyTypeOptions: PropertyTypeOption[] = [
	{ label: 'Unit Templates', value: 'Units' },
	{ label: 'Building Templates', value: 'Buildings' },
	{ label: 'Community Templates', value: 'Communities' },
];

export default () => {
	const history = useHistory();

	const { teams } = useStoreTeams();

	const [selectedTeams, setSelectedTeams] = useState<ApiTeam[]>([]);
	const [selectedPropertyTypes, setSelectedPropertyTypes] = useState<
		PropertyTypeOption[]
	>([]);

	const handleEditPropertyConfiguration = () => {
		history.push('/account/property-configuration');
	};

	const getTeamsChipLabel = (v: ApiTeam[]) => {
		if (v.length === 0) {
			return 'All Teams';
		}

		return `Teams: ${v.length} selected`;
	};

	const getPropertyTypesChipIcon = (v: PropertyTypeOption[]) => {
		if (v.length === 0 || v.length > 1) {
			return propertyTypeIcons.All;
		}

		return propertyTypeIcons[v[0].value];
	};

	const getPropertyTypesChipLabel = (v: PropertyTypeOption[]) => {
		if (v.length === 0) {
			return 'All Property Types';
		}

		if (v.length === 1) {
			return v[0].label;
		}

		return `${v.length} Property Types`;
	};

	return (
		<Screens.Container>
			<Screens.Header
				title="Inspection Templates"
				showBreadCrumbs
				subtitle="Customize RentCheck inspections by modifying our standard templates or creating your own templates from scratch."
				actions={[
					<Button
						variant="outlined"
						startIcon={<FontAwesomeIcon icon={solid('door-open')} />}
						onClick={handleEditPropertyConfiguration}>
						Edit Property Configuration
					</Button>,
					<CreateTemplateButton />,
				]}
			/>

			{teams.length > 0 && (
				<ChipMenu
					multiple
					searchable
					title="Teams"
					value={selectedTeams}
					setValue={setSelectedTeams}
					options={teams}
					getChipIcon={() => solid('users')}
					getChipLabel={getTeamsChipLabel}
					getOptionLabel={(o) => o.internal_label}
				/>
			)}

			<ChipMenu
				multiple
				sx={{ ml: 1 }}
				title="Property Types"
				value={selectedPropertyTypes}
				setValue={setSelectedPropertyTypes}
				options={propertyTypeOptions}
				getChipIcon={getPropertyTypesChipIcon}
				getChipLabel={getPropertyTypesChipLabel}
				getOptionLabel={(o) => o.label}
			/>

			<Templates teams={selectedTeams} propertyTypes={selectedPropertyTypes} />
		</Screens.Container>
	);
};
