import { useTypedSelector } from 'store/reducers/rootReducer';

export const useStoreFeatures = (inspectionId?: string) => {
	const featuresObject = useTypedSelector(
		(state) => state.features[inspectionId ?? '']
	);

	if (!featuresObject) {
		return [];
	}

	return Object.values(featuresObject);
};
