import { ApiInspection } from '@rentcheck/types';

import { useProperty } from 'hooks/properties';
import { CreateWorkOrderMetadata } from 'store/reducers/modal-flows';
import AppFolioWorkOrderIntegration from './appfolio';
import JenarkWorkOrderIntegration from './jenark';
import RentManagerWorkOrderIntegration from './rentmanager';

interface Props {
	inspection: ApiInspection;
	value?: CreateWorkOrderMetadata['provider'];
	setValue: (value?: CreateWorkOrderMetadata['provider']) => void;
}

const IntegrationWorkOrder = ({ inspection, value, setValue }: Props) => {
	const { property } = useProperty(inspection.property.id);

	if (!property) {
		return null;
	}

	return (
		<>
			<AppFolioWorkOrderIntegration
				inspection={inspection}
				property={property}
				value={value}
				setValue={setValue}
			/>
			<RentManagerWorkOrderIntegration
				inspection={inspection}
				property={property}
				value={value}
				setValue={setValue}
			/>
			<JenarkWorkOrderIntegration
				inspection={inspection}
				property={property}
				value={value}
				setValue={setValue}
			/>
		</>
	);
};

export default IntegrationWorkOrder;
