import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
	ApiInspectionEvent,
	InspectionEventInspectionSharedMetadata,
} from '@rentcheck/types';
import BaseTimelineEvent from '../base-event';

interface Props {
	event: ApiInspectionEvent;
}

const InspectionSharedTimelineEvent = ({ event }: Props) => {
	const metadata = event.metadata as InspectionEventInspectionSharedMetadata;

	return (
		<BaseTimelineEvent
			icon={solid('envelope')}
			title="Inspection shared"
			subtitle={`Shared by ${metadata.shared_by}`}
			timestamp={event.timestamp}
			sentTo={metadata.emails_sent}
			note={metadata.note}
		/>
	);
};

export default InspectionSharedTimelineEvent;
