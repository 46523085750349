import { TenantsApi } from '@rentcheck/api-frontend';
import { ApiInspection, ApiTenant, ReminderRecipient } from '@rentcheck/types';
import { parsePhoneNumber } from 'awesome-phonenumber';
import _ from 'lodash';

export type Settings = 'Email and SMS' | 'Email only';

const buildRecipient = (
	inspection: ApiInspection,
	invite: ApiInspection['invites'][0],
	tenant?: ApiTenant
): ReminderRecipient => {
	if (!tenant) {
		return {
			inviteId: invite.id,
			email: invite.email,
			inspectionId: inspection.id,
			phoneNumbers: [],
			additionalPhoneNumbers: [],
			selected: true,
		};
	}

	const phoneNumbers =
		tenant.phone_numbers?.map((p) => {
			return (
				parsePhoneNumber(p).number?.international ??
				parsePhoneNumber(`+${p}`).number?.international ??
				p
			);
		}) ?? [];

	return {
		inviteId: invite.id,
		email: invite.email,
		inspectionId: inspection.id,
		tenantId: tenant.id,
		name: `${tenant.first_name} ${tenant.last_name}`,
		phoneNumbers,
		additionalPhoneNumbers: [],
		selected: true,
	};
};

export const buildRecipients = async (inspections: ApiInspection[]) => {
	const invites = _.flatMap(inspections, (inspection) =>
		inspection.invites.map((invite) => ({ inspection, invite }))
	);

	const recipients: ReminderRecipient[] = [];
	const emailsChunks = _.chunk(
		_.uniq(invites.map(({ invite }) => invite.email)),
		30
	);

	const tenants: ApiTenant[] = [];

	/**
	 * We're not using Promise.all here because we want to make
	 * sure we don't overload the database connections.
	 */
	for (const emails of emailsChunks) {
		await TenantsApi.getAll(
			undefined,
			100,
			{
				emails: emails.join(','),
				active: true,
			},
			undefined
		).then((res) => tenants.push(...res.data));
	}

	invites.forEach(({ inspection, invite }) => {
		const tenant = tenants.find((tenant) =>
			tenant.emails.includes(invite.email)
		);
		recipients.push(buildRecipient(inspection, invite, tenant));
	});

	return _.uniqBy(recipients, 'email');
};
