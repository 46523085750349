import { LoadingButton } from '@mui/lab';
import {
	Button,
	MenuItem,
	Select,
	SelectChangeEvent,
	Typography,
} from '@mui/material';
import { ApiLatchelIntegration } from '@rentcheck/types';
import { Column, Row, SpacedRow, Spacer } from 'components';
import { useLatchelIntegrations } from 'hooks/latchel-integrations';
import _ from 'lodash';
import { useState } from 'react';
import styled from 'styled-components';
import { colors, spacingPx } from 'theme';
import { formatDate } from 'utils/helpers';

interface Props {
	internalLabel: string;
	setApiKey: (apiKey: string | null) => void;
	nextStep: () => void;
	back: () => void;
	loading: boolean;
	error: string;
}

const TeamLogo = ({
	internalLabel,
	nextStep,
	back,
	setApiKey,
	error,
	loading,
}: Props) => {
	const { latchelIntegrations } = useLatchelIntegrations();
	const integrations = _.uniqBy(latchelIntegrations, 'api_key');

	const [integration, setIntegration] = useState<ApiLatchelIntegration>();

	const handleSetIntegation = (e: SelectChangeEvent<string>) => {
		const apiKey = e.target.value;

		setIntegration(integrations.find((i) => i.api_key === apiKey));
		setApiKey(apiKey);
	};

	return (
		<Column>
			<Typography variant="h5">Enable Integrations</Typography>
			<Spacer height={1} />
			<Typography>{internalLabel}</Typography>
			<Spacer height={6} />
			<Typography>
				Enable the Latchel integration for this team by selecting a connection
				below. You can also enable this later from the Integrations page.
			</Typography>
			<Spacer height={6} />
			<Typography variant="h4">Select one (Optional)</Typography>
			<Spacer height={3} />
			<Select
				id="select-integration"
				variant="filled"
				value={integration?.api_key ?? 'placeholder'}
				onChange={handleSetIntegation}>
				<MenuItem value="placeholder" sx={{ display: 'none' }}></MenuItem>
				{integrations.map((i) => {
					if (!i) return null;
					return (
						<MenuItem
							key={i.api_key}
							value={i.api_key}
							id={`select-integration-${i.api_key}`}>
							<Row>
								{i.company_name}
								<Spacer width={2} />
								<Typography color={colors.gray} fontSize={12}>
									Installed by {i.created_by.name} on{' '}
									{formatDate(i.created_date)}
								</Typography>
							</Row>
						</MenuItem>
					);
				})}
			</Select>
			<Divider />
			<Spacer height={5} />
			{!!error && <Typography variant="error">{error}</Typography>}
			<Spacer height={5} />
			<SpacedRow>
				<ButtonContainer left>
					<Button
						variant="outlined"
						color="secondary"
						onClick={() => {
							back();
						}}>
						Back
					</Button>
				</ButtonContainer>
				<ButtonContainer right>
					<LoadingButton
						loading={loading}
						onClick={() => {
							nextStep();
						}}>
						Complete Team
					</LoadingButton>
				</ButtonContainer>
			</SpacedRow>
		</Column>
	);
};

const ButtonContainer = styled.div<{ right?: boolean; left?: boolean }>`
	display: flex;
	flex-direction: column;
	padding-left: ${({ right }) => (right ? spacingPx(2) : 0)};
	padding-right: ${({ left }) => (left ? spacingPx(2) : 0)};
`;

const Divider = styled.div`
	height: 1px;
	width: 100%;
	background-color: ${colors.contentBorder};
`;

export default TeamLogo;
