import { Rules } from '@rentcheck/biz';
import { ApiInspectionWithTemplate } from '@rentcheck/types';

import { Tooltip } from '@mui/material';
import TooltipCopyButton from 'components/tooltip-copy-button';
import GridItem from '../grid-item';

interface Props {
	inspection: ApiInspectionWithTemplate;
}

export default ({ inspection }: Props) => {
	if (!Rules.Inspections.canShowCompletedBy(inspection)) {
		return null;
	}

	if (!inspection.completed_by) {
		return null;
	}

	return (
		<GridItem
			title="Completed By"
			value={
				<Tooltip
					title={
						<span>
							{inspection.completed_by.email}
							<TooltipCopyButton text={inspection.completed_by.email} />
						</span>
					}>
					<span>{inspection.completed_by.name}</span>
				</Tooltip>
			}
		/>
	);
};
