import {
	ApiUser,
	AppFolioIntegration,
	BaseSyncDetails,
	FirebaseUpdatePayload,
	JenarkIntegration,
	RentmanagerIntegration,
	YardiIntegration,
} from '@rentcheck/types';
import firebase from 'firebase/app';

import {
	objectFromDocumentSnapshot,
	objectsFromQuerySnapshot,
} from './helpers';

// Yardi
export const getYardiIntegrationForSubscriptionId = async (
	subscriptionId: string
) => {
	return firebase
		.firestore()
		.collection('yardi_integrations')
		.where('subscription_id', '==', subscriptionId)
		.get()
		.then((data) => objectsFromQuerySnapshot<YardiIntegration>(data))
		.then((data) => {
			if (data.length === 0) {
				return null;
			}

			return data[0];
		});
};

// Jenark
export const getJenarkIntegrationForSubscriptionId = async (
	subscriptionId: string
) => {
	return firebase
		.firestore()
		.collection('jenark_integrations')
		.where('subscription_id', '==', subscriptionId)
		.get()
		.then((data) => objectsFromQuerySnapshot<JenarkIntegration>(data))
		.then((data) => {
			if (data.length === 0) {
				return null;
			}

			return data[0];
		});
};

// Rent Manager
export const getRentManagerIntegrationById = async (id: string) => {
	return firebase
		.firestore()
		.collection('rentmanager_integrations')
		.doc(id)
		.get()
		.then((data) => objectFromDocumentSnapshot<RentmanagerIntegration>(data));
};

export const getRentManagerIntegrationForSubscriptionId = async (
	subscriptionId: string
) => {
	return firebase
		.firestore()
		.collection('rentmanager_integrations')
		.where('subscription_id', '==', subscriptionId)
		.get()
		.then((data) => objectsFromQuerySnapshot<RentmanagerIntegration>(data));
};

export const updateRentManagerIntegration = async (
	id: string,
	payload: FirebaseUpdatePayload<RentmanagerIntegration>
) => {
	return firebase
		.firestore()
		.collection('rentmanager_integrations')
		.doc(id)
		.update(payload)
		.then(() => getRentManagerIntegrationById(id));
};

// AppFolio
export const getAppFolioIntegrationForSubscriptionId = async (
	subscriptionId: string
) => {
	return firebase
		.firestore()
		.collection('appfolio_integrations')
		.where('subscription_id', '==', subscriptionId)
		.get()
		.then((data) => objectsFromQuerySnapshot<AppFolioIntegration>(data))
		.then((data) => {
			if (data.length === 0) {
				return undefined;
			}

			return data[0];
		});
};

// RentManager
export const getRentmanagerIntegrationBySubscriptionId = async (
	subscriptionId: string
): Promise<RentmanagerIntegration | undefined> => {
	return firebase
		.firestore()
		.collection('rentmanager_integrations')
		.where('subscription_id', '==', subscriptionId)
		.get()
		.then(
			(snapshot) =>
				objectsFromQuerySnapshot<RentmanagerIntegration>(snapshot)[0]
		)
		.catch(() => {
			return undefined;
		});
};

export const getAllRentmanagerIntegrationsBySubscriptionId = async (
	subscriptionId: string
): Promise<RentmanagerIntegration[]> => {
	return firebase
		.firestore()
		.collection('rentmanager_integrations')
		.where('subscription_id', '==', subscriptionId)
		.get()
		.then(
			(snapshot) =>
				objectsFromQuerySnapshot<RentmanagerIntegration>(snapshot) ?? []
		)
		.catch(() => {
			return [];
		});
};

export const getAppFolioIntegrationById = async (id: string) => {
	return firebase
		.firestore()
		.collection('appfolio_integrations')
		.doc(id)
		.get()
		.then((data) => objectFromDocumentSnapshot<AppFolioIntegration>(data));
};

export const updateAppFolioIntegration = async (
	id: string,
	payload: FirebaseUpdatePayload<AppFolioIntegration>
) => {
	return firebase
		.firestore()
		.collection('appfolio_integrations')
		.doc(id)
		.update(payload)
		.then(() => getAppFolioIntegrationById(id));
};

export const installAppFolioIntegration = async (
	creator: ApiUser,
	subscriptionId: string,
	clientId: string,
	clientSecret: string
) => {
	const integration =
		await getAppFolioIntegrationForSubscriptionId(subscriptionId);

	const credentials = { client_id: clientId, client_secret: clientSecret };

	if (integration) {
		return firebase
			.firestore()
			.collection('appfolio_integrations')
			.doc(integration.id)
			.update(credentials)
			.then(() => getAppFolioIntegrationForSubscriptionId(subscriptionId));
	}

	const emptySyncDetails: BaseSyncDetails = { active: false, count: 0 };

	const ref = firebase.firestore().collection('appfolio_integrations').doc();

	return ref
		.set({
			...credentials,
			id: ref.id,
			active: true,
			created_by: {
				id: creator.id,
				name: creator.name,
			},
			property_groups: [],
			property_types: [],
			created_date: new Date(),
			subscription_id: subscriptionId,
			sync_details: {
				units: emptySyncDetails,
				buildings: emptySyncDetails,
				residents: emptySyncDetails,
				work_orders: emptySyncDetails,
				inspections: emptySyncDetails,
			},
		})
		.then(() => getAppFolioIntegrationForSubscriptionId(subscriptionId));
};
