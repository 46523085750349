import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Paper } from '@mui/material';
import firebase from 'firebase/app';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Feature } from '@rentcheck/types';

import { Utils } from '@rentcheck/biz';
import { CenteredRow } from 'components';
import { colors } from 'theme';
import { getThumbnailUrl } from 'utils/helpers';

interface Props {
	src: string;
	selectedImages: string[];
	onClick: (src: string) => void;
	feature: Feature;
}

interface IconProps {
	src: string;
	checked: boolean;
}

const urlCache: Record<string, string> = {};

const elementIdFromSource = (prefix: string, src: string) => {
	return `${prefix}-${src.split('.')[0].slice(-6)}`;
};

const CheckedIcon = ({ checked, src }: IconProps) => {
	return (
		<IconContainer id={elementIdFromSource('select-photos', src)}>
			<Icon icon={solid('flag')} color={checked ? '#CC6329' : '#7C8A9E'} />
		</IconContainer>
	);
};

const Image = ({ src, selectedImages, onClick }: Props) => {
	const [url, setUrl] = useState('');

	useEffect(() => {
		const mediaUri = Utils.Media.isVideo(src) ? getThumbnailUrl(src) : src;

		if (urlCache[mediaUri]) {
			setUrl(urlCache[mediaUri]);
			return;
		}

		setUrl('');

		const storage = firebase.storage();
		const ref = storage.ref(mediaUri);
		ref.getDownloadURL().then((url) => setUrl(url));
	}, [src]);

	const sizeStyle = { width: 144, height: 180 };

	return (
		<>
			<Paper
				sx={{ ...sizeStyle, margin: 1.5, cursor: 'pointer' }}
				onClick={() => onClick(src)}>
				<img
					src={url}
					id={elementIdFromSource('photos-carousel', src)}
					style={{ ...sizeStyle, objectFit: 'cover', borderRadius: 4 }}
				/>
				<CheckedIcon checked={selectedImages.includes(src)} src={src} />
			</Paper>
		</>
	);
};

const IconContainer = styled(CenteredRow)`
	height: 30px;
	width: 30px;
	border-radius: 15px;
	background-color: white;
	box-shadow: 0 0 4px #979797;

	position: absolute;
	margin-top: -45px;
	margin-left: 104px;
`;

const Icon = styled(FontAwesomeIcon)`
	transition: color 0.3s;

	&:hover {
		color: ${colors.primary};
	}
`;

export default Image;
