import Invoices from './invoices';
import PaymentMethod from './payment-method';
import PlanInfo from './plan-info';
import Summary from './summary';

export default () => {
	return (
		<>
			<Summary />
			<PlanInfo />
			<PaymentMethod />
			<Invoices />
		</>
	);
};
