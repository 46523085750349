import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Row } from 'components';
import IconButton, { IconButtonRef } from 'components/icon-button';
import { useFeatureDetailsModalData, useIsTopModal } from 'hooks/modals';
import { useEffect, useRef } from 'react';
import { FeatureWithMetadata } from 'store/reducers/features';
import { useKeyPress } from 'utils/hooks';

interface Props {
	feature: FeatureWithMetadata;
	setFeature: (value: FeatureWithMetadata) => void;
}

const ArrowsController = ({ feature, setFeature }: Props) => {
	const modalData = useFeatureDetailsModalData();
	const isTopModal = useIsTopModal('Feature Details');

	const nextButtonRef = useRef<IconButtonRef>(null);
	const previousButtonRef = useRef<IconButtonRef>(null);

	useEffect(() => {
		if (!modalData?.metadata?.features) {
			return;
		}

		if (!feature) {
			return;
		}

		const updatedFeature = modalData.metadata.features.find(
			(f) => f.id === feature.id
		);

		if (!updatedFeature) {
			return;
		}

		setFeature(updatedFeature);
	}, [modalData?.metadata?.features]);

	useKeyPress((event: { keyCode: number }) => {
		if (!isTopModal) {
			return;
		}

		// LEFT Arrow
		if (event.keyCode === 37) {
			previousButtonRef.current?.click();
		}

		// RIGHT Arrow
		if (event.keyCode === 39) {
			nextButtonRef.current?.click();
		}
	});

	const handlePrevious = () => {
		const features = modalData.metadata.features;
		const featureIndex = features.indexOf(feature);

		if (featureIndex < 0) {
			return;
		}

		if (featureIndex - 1 < 0) {
			const newFeature = features[features.length - 1];

			setFeature(newFeature);
			return;
		}

		const newFeature = features[featureIndex - 1];

		setFeature(newFeature);
		return;
	};

	const handleNext = () => {
		const features = modalData.metadata.features;
		const featureIndex = features.indexOf(feature);

		if (featureIndex < 0) {
			return;
		}

		if (featureIndex + 1 >= features.length) {
			setFeature(features[0]);
			return;
		}

		setFeature(features[featureIndex + 1]);
		return;
	};

	if (!modalData?.metadata) {
		return null;
	}

	return (
		<Row>
			<IconButton
				tooltip="Previous"
				icon={regular('arrow-left')}
				onClick={handlePrevious}
				ref={previousButtonRef}
			/>
			<IconButton
				tooltip="Next"
				icon={regular('arrow-right')}
				onClick={handleNext}
				ref={nextButtonRef}
			/>
		</Row>
	);
};

export default ArrowsController;
