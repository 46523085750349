import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Dispatch } from 'types';
import { SnackbarActions, AccountSettingsActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';

export const useAccountSettings = () => {
	const dispatch: Dispatch = useDispatch();

	const profile = useTypedSelector((state) => state.activeProfile);
	const accountSettings = useTypedSelector((state) => state.accountSettings);

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(true);

		dispatch(AccountSettingsActions.get())
			.catch(() => dispatch(SnackbarActions.showError()))
			.finally(() => setLoading(false));
	}, [profile]);

	return {
		loading,
		accountSettings,
	};
};
