import { CustomRoom } from '@rentcheck/types';
import { Column } from 'components';
import { useState } from 'react';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { defaultRooms } from './common/constants';
import RoomRow from './common/room-row';
import Section from './common/section';

export default () => {
	const accountSettings = useTypedSelector((state) => state.accountSettings);

	const [newRooms, setNewRooms] = useState<CustomRoom[]>([]);

	const rooms = accountSettings?.rooms.building ?? [];

	const handleAdd = () => {
		setNewRooms([{ name: '', category: 'building' }, ...newRooms]);
	};

	const handleRoomCreated = (room: CustomRoom) => {
		setNewRooms(newRooms.filter((r) => r !== room));
	};

	return (
		<Column>
			<Section title="Amenities and Common Areas" onAdd={handleAdd}>
				{newRooms.map((room) => (
					<RoomRow
						editable
						key={room.name}
						room={room}
						variant="building"
						onRoomCreated={handleRoomCreated}
					/>
				))}
				{rooms.map((room) => (
					<RoomRow editable key={room.name} room={room} variant="building" />
				))}
				{defaultRooms.building.common.map((room) => (
					<RoomRow key={room} room={{ name: room, category: 'other' }} />
				))}
			</Section>
		</Column>
	);
};
