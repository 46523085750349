import { Skeleton } from '@mui/material';

import { Row } from 'components';

const FiltersSkeleton = () => {
	return (
		<Row>
			<Skeleton
				variant="rectangular"
				width={32}
				height={32}
				sx={{ borderRadius: 4 }}
			/>
		</Row>
	);
};

export default FiltersSkeleton;
