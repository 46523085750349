import {
	CreatePermissionGroupV2Params,
	GetAllPermissionGroupsV2Params,
	GetAllPermissionGroupsV2Result,
	UpdatePermissionGroupV2Params,
} from '@rentcheck/types';
import _ from 'lodash';
import { State as FiltersState } from 'store/reducers/teammates/permission-groups-filters';
import { ReduxFn } from 'types';

import { PermissionGroupsApi } from '@rentcheck/api-frontend';
import { Utils } from '@rentcheck/biz';
import * as SnackbarActions from '../snackbar-actions';

const pageSize = 20;

const defaultSort: GetAllPermissionGroupsV2Params['options']['sort'] = {
	sort_by: 'name',
	sort_type: 'ASC',
};

export const getAll = (
	offset: number = 0,
	filters: FiltersState
): ReduxFn<GetAllPermissionGroupsV2Result> => {
	const fn: ReduxFn = async (dispatch) =>
		PermissionGroupsApi.getAll(
			Math.ceil(offset / pageSize),
			pageSize,
			filters,
			defaultSort
		)
			.then((res) => {
				dispatch({
					type: offset === 0 ? 'SET_PGS' : 'APPEND_PGS',
					value: res.data,
					totalResults: res.total_results,
				});

				return res;
			})
			.catch((e) => {
				dispatch(SnackbarActions.showError(e));

				return {
					data: [],
					total_results: 0,
				};
			});

	return fn;
};

export const setFilters = (value: Partial<FiltersState>): ReduxFn => {
	const fn: ReduxFn = async (dispatch) => {
		const tuples = _.toPairs(value);

		tuples.forEach((t) => {
			dispatch({
				type: 'UPDATE_PGS_FILTERS',
				key: t[0],
				value: t[1],
			});
		});
	};

	return fn;
};

export const remove = (ids: string[]): ReduxFn => {
	const fn: ReduxFn = async (dispatch) =>
		PermissionGroupsApi.remove(ids)
			.then(() => {
				dispatch({
					type: 'DELETE_PGS',
					value: [ids.map((id) => ({ id }))],
				});

				const message = `${ids.length} Permission groups deleted successfully`;
				dispatch(SnackbarActions.showSuccess(message));
			})
			.catch((e) => dispatch(SnackbarActions.showError(e)));

	return fn;
};

export const create = (
	data: CreatePermissionGroupV2Params['data']
): ReduxFn => {
	const fn: ReduxFn = async (dispatch) =>
		PermissionGroupsApi.create(data)
			.then((res) => {
				dispatch({
					type: 'CREATED_PGS',
					value: [Utils.PermissionGroups.buildDigest(res)],
				});

				const message = `Success! Permission Group ${res.name} created successfully`;
				dispatch(SnackbarActions.showSuccess(message));
			})
			.catch((e) => dispatch(SnackbarActions.showError(e)));

	return fn;
};

export const update = (
	id: string,
	data: UpdatePermissionGroupV2Params['data']
): ReduxFn => {
	const fn: ReduxFn = async (dispatch) =>
		PermissionGroupsApi.update(id, data)
			.then((res) => {
				dispatch({
					type: 'UPDATE_PGS',
					value: [Utils.PermissionGroups.buildDigest(res)],
				});

				const message = `Success! Updates saved for ${res.name}.`;
				dispatch(SnackbarActions.showSuccess(message));
			})
			.catch((e) => dispatch(SnackbarActions.showError(e)));

	return fn;
};
