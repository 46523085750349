import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import MuiPhoneNumber from 'mui-phone-number';
import { LoadingButton } from '@mui/lab';
import { Paper, Typography } from '@mui/material';

import { Screens, Spacer, Column } from 'components';
import { Dispatch } from 'types';
import { Analytics } from 'utils';
import FormTextField from 'components/form-text-field';
import { AccountSettingsActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { useAccountSettings } from 'hooks/account-settings';

import Skeleton from './skeleton';
import { EMAIL_REGEX } from '../../../constants';

interface EmailProps {
	emailAddress: string;
	setEmailAddress: (email: string) => void;
}

const EmailAddress = ({ emailAddress, setEmailAddress }: EmailProps) => {
	const [error, setError] = useState('');

	const handleEmailAddress = (value: string) => {
		const validString = value.replaceAll(/[\s,]/g, '').trim();
		setEmailAddress(validString);

		if (validString && !EMAIL_REGEX.test(validString)) {
			setError('Email address is invalid');
		} else {
			setError('');
		}
	};

	return (
		<FormTextField
			title="Email Address"
			placeholder="Add email address"
			value={emailAddress}
			onChange={(e) => handleEmailAddress(e.target.value)}
			error={!!error}
			helperText={error}
		/>
	);
};

interface PhoneNumberProps {
	phoneNumber: string;
	setPhoneNumber: (phoneNumber: string) => void;
}

const PhoneNumber = ({ phoneNumber, setPhoneNumber }: PhoneNumberProps) => {
	const handlePhoneNumber = (value: string) => {
		setPhoneNumber(value);
	};

	return (
		<>
			<Typography variant="overline">Phone Number</Typography>
			<Spacer height={2} />
			<PhoneNumberTextField
				variant="filled"
				placeholder="Add phone number"
				defaultCountry="us"
				onChange={(e) => handlePhoneNumber(e as string)}
				value={phoneNumber}
			/>
			<Spacer height={4} />
		</>
	);
};

export default () => {
	const dispatch = useDispatch<Dispatch>();

	const subscription = useTypedSelector((state) => state.subscription);
	const { loading: loadingAccountSettings, accountSettings } =
		useAccountSettings();

	const [emailAddress, setEmailAddress] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [disabled, setDisabled] = useState(false);

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (!accountSettings) return;

		if (!emailAddress) {
			setEmailAddress(accountSettings.inspection_contact_info?.email ?? '');
		}
		if (!phoneNumber) {
			setPhoneNumber(
				accountSettings.inspection_contact_info?.phone_number ?? ''
			);
		}
	}, [accountSettings]);

	useEffect(() => {
		if (!accountSettings?.inspection_contact_info) return;

		if (
			!emailAddress &&
			!phoneNumber &&
			!accountSettings.inspection_contact_info.email &&
			!accountSettings.inspection_contact_info.phone_number
		) {
			setDisabled(true);
			return;
		}

		if (emailAddress && !EMAIL_REGEX.test(emailAddress)) {
			setDisabled(true);
			return;
		}

		if (
			emailAddress === accountSettings.inspection_contact_info.email &&
			phoneNumber === accountSettings.inspection_contact_info.phone_number
		) {
			setDisabled(true);
			return;
		}

		if (
			phoneNumber === '+' &&
			!accountSettings.inspection_contact_info.phone_number
		) {
			setDisabled(true);
			return;
		}

		setDisabled(false);
	}, [accountSettings, emailAddress, phoneNumber]);

	if (loadingAccountSettings) {
		return <Skeleton />;
	}

	const handleSave = () => {
		if (!accountSettings) {
			return;
		}

		setLoading(true);

		dispatch(
			AccountSettingsActions.updateInspectionContactInfo({
				email: emailAddress,
				phone_number: phoneNumber === '+' ? '' : phoneNumber,
			})
		)
			.then(() => {
				if (!subscription) return;

				if (!accountSettings.inspection_contact_info?.email) {
					Analytics.trackEvent('Added Inspection Contact Info”', {
						subscription_id: subscription.id,
						chargebee_subscription_id: subscription.plan.sub_id,
						chargebee_customer_id: subscription.plan.customer_id,
					});
				}
			})
			.finally(() => setLoading(false));
	};

	return (
		<Screens.Container>
			<Screens.Header
				title="Inspection Messaging"
				showBreadCrumbs
				subtitle="Add and manage company contact information in inspection invitations and reminders so residents can easily reach out when needed."
			/>

			<Paper
				elevation={0}
				sx={{ border: '1px solid #0000001F', padding: '8px 16px 16px', mt: 3 }}>
				<Column>
					<Typography variant="subtitle1">
						Inspection Contact Information
					</Typography>
					<Spacer height={2} />

					<Typography color="#4D4D4D">
						Add company contact information for residents to use when they have
						specific requests or questions about an inspection.
					</Typography>

					<Typography color="#4D4D4D">
						These contact details will be included in RentCheck inspection
						invitations and scheduled reminders.
					</Typography>
					<Spacer height={4} />

					<Column style={{ width: '50%' }}>
						<EmailAddress
							emailAddress={emailAddress}
							setEmailAddress={setEmailAddress}
						/>

						<PhoneNumber
							phoneNumber={phoneNumber}
							setPhoneNumber={setPhoneNumber}
						/>

						<LoadingButton
							loading={loading}
							variant="contained"
							onClick={handleSave}
							disabled={disabled}
							style={{ maxWidth: 100 }}>
							Save
						</LoadingButton>
					</Column>
				</Column>
			</Paper>
		</Screens.Container>
	);
};

const PhoneNumberTextField = styled(MuiPhoneNumber)`
	.MuiInputAdornment-root {
		filter: grayscale();
	}

	.MuiIconButton-root {
		min-width: 20px !important;
		height: 20px !important;
	}
`;
