import { ApiSubscription, ApiUser } from '@rentcheck/types';
import firebase from 'firebase/app';
import { IntercomAPI } from 'react-intercom';
import { isEmbeddedInMobileApp } from './helpers';

type OptionsType = Record<
	string,
	string | number | Date | undefined | string[]
>;

const sanitizeEventName = (name: string) =>
	name.toLowerCase().replace(/[^a-zA-Z0-9]/g, '_');

export const initializeProfile = (
	profile: ApiUser,
	subscription: ApiSubscription | null
) => {
	const company = subscription?.rentcheck_contact?.company_id
		? {
				id: subscription?.rentcheck_contact?.company_id,
				name: subscription?.rentcheck_contact?.company,
				company_id: subscription?.rentcheck_contact?.company_id,
			}
		: undefined;

	IntercomAPI('boot', {
		app_id: process.env.intercom_app_id,
		email: profile.email,
		name: profile.name,
		user_id: profile.id,
		user_type: profile.type,
		company_name: subscription?.rentcheck_contact.company ?? 'not_set',
		sub_id: subscription?.id ?? 'not_set',
		chargebee_sub_id: subscription?.plan.sub_id ?? 'not_set',
		company,
	});

	firebase.analytics().setUserId(profile.id);
	firebase.analytics().setUserProperties(
		{
			user_type: profile.type,
			company_name: subscription?.rentcheck_contact.company ?? 'not_set',
			chargebee_sub_id: subscription?.plan.sub_id ?? 'not_set',
			chargebee_plan_id: subscription?.plan.plan_id ?? 'not_set',
			chargebee_plan_quantity:
				subscription?.plan.plan_qty.toString() ?? 'not_set',
			chargebee_addon_ids:
				subscription?.addons?.map((addon) => addon.id).join(', ') ?? 'not_set',
		},
		{ global: true }
	);
};

export const logOut = () => {
	firebase.analytics().setUserId('');
	firebase.analytics().setUserProperties(
		{
			user_type: '',
			company_name: '',
			chargebee_sub_id: '',
			chargebee_plan_id: '',
			chargebee_plan_quantity: '',
			chargebee_addon_ids: '',
		},
		{ global: true }
	);
};

export const keepButtonHidden = () => {
	IntercomAPI('update', {
		hide_default_launcher: true,
	});
};

export const trackEvent = (name: string, options?: OptionsType) => {
	try {
		const sanitizedName = sanitizeEventName(name);
		const payload = {
			...(options ?? {}),
			platform: isEmbeddedInMobileApp() ? 'mobile' : 'web',
		};

		IntercomAPI('trackEvent', sanitizedName, payload);
		firebase.analytics().logEvent(sanitizedName, payload);
	} catch (e) {
		console.log(e);
	}
};
