import { MouseEvent, useEffect, useState } from 'react';
import _ from 'lodash';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Divider,
	Popover,
	Typography,
	useMediaQuery,
	useTheme,
	RadioGroup,
	Radio,
	FormControlLabel,
} from '@mui/material';

import {
	APIProperty,
	ApiInspectionTemplateDigest,
	AssignMethod,
	ResidentsAssignmentType,
} from '@rentcheck/types';

import { Spacer } from 'components';
import { RecurringOption } from 'components/inspections/create-steps/recurrence';
import RecipientsList, { Recipient } from 'components/recipients-list';
import TeammatesList from 'components/teammates-list';
import { numberAndPluralizeWord } from 'utils/helpers';

import AssignMethodList from './assign-method-list';
import AssignMethodRow from './assign-method-row';
import { assignMethodData } from './common';
import InviteDate from './invite-date';
import { AssignedTeammates } from '../common/assigned-teammates';
import DialogTitle from '../common/inner-dialog-title';
import innerTransition from '../common/inner-transition';
import RowButton from '../common/row-button';

interface Props {
	properties: APIProperty[];
	template?: ApiInspectionTemplateDigest;
	disabled?: boolean;
	assignMethod?: AssignMethod;
	setAssignMethod: (value?: AssignMethod) => void;
	recipients: Recipient[];
	setRecipients: (value: Recipient[]) => void;
	inviteDate?: Date;
	setInviteDate: (value?: Date) => void;
	dueDate?: Date;
	recurrence?: RecurringOption;
	notifyChecked: boolean;
	setNotifyChecked: (checked: boolean) => void;
	notifyNote: string;
	setNotifyNote: (note: string) => void;
	assignedTeammates: AssignedTeammates;
	setAssignedTeammates: (teammates: AssignedTeammates) => void;
	residentsType: ResidentsAssignmentType;
	setResidentsType: (type: ResidentsAssignmentType) => void;
}

const Assign = ({
	template,
	properties,
	disabled,
	assignMethod,
	recipients,
	setAssignMethod,
	setRecipients,
	inviteDate,
	setInviteDate,
	dueDate,
	recurrence,
	notifyChecked,
	setNotifyChecked,
	notifyNote,
	setNotifyNote,
	assignedTeammates,
	setAssignedTeammates,
	residentsType,
	setResidentsType,
}: Props) => {
	const theme = useTheme();
	const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));

	const [modalOpen, setModalOpen] = useState(false);
	const [menuAnchor, setMenuAnchor] = useState<HTMLDivElement | null>(null);

	useEffect(() => {
		setRecipients([]);
	}, [assignMethod]);

	useEffect(() => {
		if (assignMethod === 'residents') {
			setAssignMethod(undefined);
			setRecipients([]);
		}
	}, [template]);

	const handleChangeResidentsType = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setResidentsType(event.target.value as ResidentsAssignmentType);
	};

	const handleOpen = () => {
		setModalOpen(true);
	};

	const handleClose = () => {
		setModalOpen(false);
	};

	const handleChangeAssignMethod = (
		_: AssignMethod,
		e: MouseEvent<HTMLDivElement>
	) => {
		setMenuAnchor(e.currentTarget);
	};

	const handleAssignMethod = (value: AssignMethod) => {
		setMenuAnchor(null);
		setAssignMethod(value);

		if (['self-perform', 'residents', 'myself'].includes(value)) {
			handleClose();
		}
	};

	const confirmButtonDisabled = () => {
		const propertiesWithTeams = properties.filter(
			(property) => !!property?.team?.id
		);

		const teamsCount = _.uniqBy(propertiesWithTeams, 'team.id').length;

		if (assignMethod === 'emails') {
			return recipients.length === 0;
		}

		if (assignMethod === 'teammates') {
			return (
				_.keys(assignedTeammates).length !== teamsCount ||
				!!_.values(assignedTeammates).find((v) => v.length === 0)
			);
		}

		return false;
	};

	const caption = assignMethod ? 'Assignee' : undefined;
	const title = assignMethod
		? assignMethodData[assignMethod].title
		: 'Assign Inspection';
	const noTeams = !properties.map((p) => !!p.team).includes(true);

	return (
		<>
			<RowButton
				caption={caption}
				title={title}
				icon={regular('users')}
				onClick={handleOpen}
				disabled={disabled}
				hasContent={!!assignMethod}
			/>

			{recipients.length > 0 && (
				<Typography
					variant="body2"
					color="#00000099"
					sx={{ marginLeft: 1, marginTop: -1, marginBottom: 2 }}>
					{numberAndPluralizeWord(recipients.length, 'Assignee')}
				</Typography>
			)}

			{(assignMethod === 'residents' || assignMethod === 'emails') &&
				recurrence &&
				recurrence !== 'Does not repeat' && (
					<Typography
						variant="body2"
						color="#00000099"
						sx={{ marginLeft: 1, marginTop: -1, marginBottom: 2 }}>
						Invitations will be sent 14 days before the inspection due date.
					</Typography>
				)}

			{assignMethod === 'residents' && (
				<>
					<RadioGroup
						value={residentsType}
						onChange={handleChangeResidentsType}>
						<FormControlLabel
							value="current"
							control={<Radio />}
							label="Current residents"
						/>
						<FormControlLabel
							value="future"
							control={<Radio />}
							label="Future residents"
						/>
					</RadioGroup>

					<Spacer height={4} />
				</>
			)}

			<InviteDate
				inviteDate={inviteDate}
				setInviteDate={setInviteDate}
				assignMethod={assignMethod}
				dueDate={dueDate}
				recurrence={recurrence}
			/>

			<Divider />

			<Dialog
				open={modalOpen}
				onClose={handleClose}
				fullScreen={mobileScreen}
				TransitionComponent={innerTransition}>
				<DialogTitle title="Assign Inspection" onClose={handleClose} />
				<DialogContent style={{ height: '60vh' }}>
					{!assignMethod && template && (
						<AssignMethodList
							template={template}
							onChange={handleAssignMethod}
							noTeams={noTeams}
						/>
					)}

					{!!assignMethod && template && (
						<>
							<AssignMethodRow
								variant={assignMethod}
								onClick={handleChangeAssignMethod}
								icon={solid('caret-down')}
							/>
							<Popover
								open={!!menuAnchor}
								anchorEl={menuAnchor}
								PaperProps={{
									style: { width: mobileScreen ? '100%' : 544 },
								}}
								onClose={() => setMenuAnchor(null)}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'center',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'center',
								}}>
								<div style={{ padding: '0 10px' }}>
									<AssignMethodList
										template={template}
										onChange={handleAssignMethod}
										noTeams={noTeams}
									/>
								</div>
							</Popover>
						</>
					)}

					{assignMethod === 'emails' && (
						<RecipientsList
							properties={properties}
							value={recipients}
							setValue={setRecipients}
						/>
					)}

					{assignMethod === 'teammates' && (
						<TeammatesList
							properties={properties}
							notifyChecked={notifyChecked}
							setNotifyChecked={setNotifyChecked}
							notifyNote={notifyNote}
							setNotifyNote={setNotifyNote}
							assignedTeammates={assignedTeammates}
							setAssignedTeammates={setAssignedTeammates}
						/>
					)}
				</DialogContent>
				<DialogActions>
					<Button variant="text" color="secondary" onClick={handleClose}>
						Back
					</Button>
					<Button
						variant="contained"
						onClick={handleClose}
						disabled={confirmButtonDisabled()}>
						Ok
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default Assign;
