import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
	Button,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	Divider,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import 'react-phone-input-2/lib/style.css';
import { useDispatch } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DatePicker, LoadingButton } from '@mui/lab';
import { FileRequestApi } from '@rentcheck/api-frontend';
import { ApiMaintenanceReport, ApiTenant, Occupancy } from '@rentcheck/types';
import { Column, DialogTitle, Row, Spacer } from 'components';
import FormTextField from 'components/form-text-field';
import RecipientsList, { Recipient } from 'components/recipients-list';
import { useShareInspectionModalData } from 'hooks/modals';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
	InspectionActions,
	ModalFlowActions,
	SnackbarActions,
} from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { colors } from 'theme';
import { Dispatch } from 'types';
import { Analytics } from 'utils';
import {
	formattedAddress,
	inspectionIsComplete,
	isEmbeddedInMobileApp,
	isRenter,
	postMessageToNativeApp,
} from 'utils/helpers';

interface Props {
	open: boolean;
}

type ShareMode = 'share' | 'review';

export default ({ open }: Props) => {
	const dispatch = useDispatch<Dispatch>();
	const theme = useTheme();
	const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
	const profile = useTypedSelector((state) => state.activeProfile);
	const location = useLocation();

	const modalData = useShareInspectionModalData();
	const object = modalData?.metadata?.object;

	const oneWeekFromToday = moment(new Date()).add(1, 'week').toDate();

	const [loading, setLoading] = useState(false);
	const [mode, setMode] = useState<ShareMode>('share');
	const [dueDate, setDueDate] = useState<Date | undefined>(oneWeekFromToday);
	const [recipients, setRecipients] = useState<Recipient[]>([]);
	const [message, setMessage] = useState('');
	const [properties, setProperties] = useState<
		ApiMaintenanceReport['property'][]
	>([]);

	useEffect(() => {
		setRecipients([]);
		setMessage('');
		setProperties([]);
		setMode('share');
		setDueDate(oneWeekFromToday);
	}, [open]);

	useEffect(() => {
		if (!object) return;

		setProperties([object.property]);
	}, [object]);

	const handleClose = () => {
		const isStandaloneShareModal =
			location.pathname.startsWith('/inspections/share');

		if (isEmbeddedInMobileApp() && isStandaloneShareModal) {
			postMessageToNativeApp({
				type: 'closed-modal',
				modal: 'share-inspection',
			});
		}

		dispatch(ModalFlowActions.closeCurrentModal());
	};

	const trackAnalyticsEvent = (recipients: string[]) => {
		if (mode === 'review') {
			Analytics.trackEvent('inspection_review_requested', {
				inspection_id: object.id,
				recipient_emails: recipients.join(),
			});

			return;
		}

		if (mode === 'share') {
			Analytics.trackEvent('shared_inspection_report', {
				inspection_id: object.id,
				recipient_emails: recipients.join(),
			});
			return;
		}
	};

	const handleChangeMode = (event: React.ChangeEvent<HTMLInputElement>) => {
		setMode(event.target.value as ShareMode);
	};

	const handleSnackbar = () => {
		if (isEmbeddedInMobileApp()) {
			postMessageToNativeApp({
				type: 'shared-inspection',
			});

			handleClose();
		} else {
			dispatch(SnackbarActions.showSuccess('Success! Inspection shared.'));
			dispatch(ModalFlowActions.closeCurrentModal());
		}
	};

	const handleShareOnly = (sendTo: string[]) => {
		FileRequestApi.create('INSPECTION_REPORT', object.id, 'PDF', {
			send_to: sendTo,
			note: message,
		})
			.then(() => {
				trackAnalyticsEvent(sendTo);
				handleSnackbar();
			})
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	const handleReview = (sendTo: string[]) => {
		if (!dueDate) {
			return;
		}

		dispatch(
			InspectionActions.requestReview(object.id, dueDate, sendTo, message)
		)
			.then(() => {
				trackAnalyticsEvent(sendTo);
				handleSnackbar();
			})
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	const handleShare = () => {
		if (!recipients.length) {
			return;
		}

		if (mode === 'review' && !dueDate) {
			return;
		}

		setLoading(true);

		const sendTo = recipients.map((r) =>
			typeof r === 'string' ? (r as string) : (r as ApiTenant).emails[0]
		);

		if (mode === 'review') {
			return handleReview(sendTo);
		}

		if (mode === 'share') {
			return handleShareOnly(sendTo);
		}
	};

	if (!object || properties.length === 0) {
		return null;
	}

	const validOccupancyStatus: Record<ShareMode, Occupancy['status'][]> = {
		share: ['current', 'future', 'past'],
		review: ['current', 'future'],
	};

	const shouldShowReviewOptions =
		['self-perform', 'teammates', 'myself'].includes(
			object.assigned_recipients.type
		) &&
		inspectionIsComplete(object) &&
		!isRenter(profile);

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			maxWidth="md"
			fullScreen={mobileScreen}>
			<DialogTitle
				title={`Share ${object.inspection_template.name} Inspection`}
				icon={solid('share')}
				onClose={handleClose}
				bottomSubtitle={formattedAddress(properties[0])}
			/>

			<DialogContent>
				<div style={{ minHeight: 350 }}>
					{shouldShowReviewOptions && (
						<Column>
							<Typography variant="overline">Inspection Sharing</Typography>
							<FormControl>
								<RadioGroup value={mode} onChange={handleChangeMode}>
									<FormControlLabel
										sx={{ marginTop: { xs: 1, sm: 0 } }}
										value="share"
										control={<Radio />}
										label={
											<Typography variant="subtitle2">
												Recipient receives a view only pdf report of the
												completed inspection
											</Typography>
										}
									/>
									<FormControlLabel
										sx={{ marginTop: { xs: 1, sm: 0 } }}
										value="review"
										control={<Radio />}
										label={
											<Row>
												<Typography variant="subtitle2">
													Recipient can add to the inspection, sign, and submit
													for approval
												</Typography>
												<Chip
													label="New"
													sx={{
														marginLeft: 1,
														backgroundColor: '#E9E8FD',
														'.MuiChip-icon': {
															color: colors.primary,
														},
													}}
													icon={<FontAwesomeIcon icon={solid('sparkles')} />}
												/>
											</Row>
										}
									/>
								</RadioGroup>
							</FormControl>

							<Divider sx={{ marginTop: 2, marginBottom: 2 }} />
						</Column>
					)}

					{mode === 'review' && (
						<Column>
							<Typography variant="overline">Due Date</Typography>
							<Spacer height={4} />
							<Row>
								<DatePicker
									inputFormat="MM/dd/yy"
									disablePast={true}
									value={dueDate}
									mask="__/__/__"
									renderInput={(props) => (
										<TextField
											{...props}
											variant="filled"
											label="Due date"
											error={!dueDate}
										/>
									)}
									onChange={(date) => {
										if (!date) setDueDate(undefined);
										setDueDate(date as Date);
									}}
								/>
							</Row>
							<Divider sx={{ marginTop: 2, marginBottom: 2 }} />
						</Column>
					)}

					<RecipientsList
						label="Emails"
						placeholder="Enter emails separated by a comma"
						properties={properties}
						value={recipients}
						setValue={setRecipients}
						validOccupancyStatus={validOccupancyStatus[mode]}
					/>

					<Divider sx={{ marginTop: 2, marginBottom: 2 }} />

					<FormTextField
						title="Message"
						multiline
						fullWidth
						maxRows={4}
						placeholder="Add a personal note"
						value={message}
						sx={{ marginTop: 1 }}
						onChange={(e) => setMessage(e.target.value)}
						helperText={
							message ? 'Message will be included in email' : 'Optional'
						}
					/>
				</div>
			</DialogContent>

			<DialogActions>
				<Button variant="text" onClick={handleClose} color="secondary">
					Cancel
				</Button>
				<LoadingButton
					loading={loading}
					variant="contained"
					onClick={handleShare}>
					Share
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};
