import { TextField, Typography } from '@mui/material';
import React from 'react';

import { Feature } from '@rentcheck/types';

import { Spacer } from 'components';

interface Props {
	featurePayload: Partial<Feature>;
	setFeaturePayload: (payload: Partial<Feature>) => void;
}

const Notes = ({ featurePayload, setFeaturePayload }: Props) => {
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFeaturePayload({ ...featurePayload, notes: e.target.value });
	};

	if (featurePayload.not_applicable) return null;

	return (
		<>
			<Spacer height={5} />

			<Typography variant="overline">Notes</Typography>
			<Spacer height={1} />

			<TextField
				id="edit-feature-note"
				value={featurePayload.notes}
				onChange={handleChange}
				placeholder="No Note"
				variant="filled"
				fullWidth
				multiline
			/>
		</>
	);
};

export default Notes;
