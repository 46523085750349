import { ApiInspectionTemplate } from '@rentcheck/types';

export type ActionTypes =
	| 'SET_INSPECTION_TEMPLATE'
	| 'UNSET_INSPECTION_TEMPLATE'
	| 'ADD_INSPECTION_TEMPLATE'
	| 'EDITED_INSPECTION_TEMPLATE_SECTIONS'
	| 'EDITED_INSPECTION_TEMPLATE_DETAILS'
	| 'REMOVE_INSPECTION_TEMPLATE'
	| 'SIGNOUT_SUCCESS';

export type State = ApiInspectionTemplate | null;

interface Action {
	type: ActionTypes;
	data: ApiInspectionTemplate;
}

export default (state: State = null, action: Action): State => {
	const data = action.data;

	if (action.type === 'UNSET_INSPECTION_TEMPLATE') {
		return null;
	}

	if (!data) return state;

	switch (action.type) {
		case 'SET_INSPECTION_TEMPLATE':
			return data;

		case 'EDITED_INSPECTION_TEMPLATE_SECTIONS':
			if (!state) {
				return data;
			}

			return { ...state, sections: data.sections };

		case 'EDITED_INSPECTION_TEMPLATE_DETAILS':
			if (!state) {
				return data;
			}

			return { ...data, sections: state.sections };

		case 'REMOVE_INSPECTION_TEMPLATE':
			if (!action.data) return state;
			if (!state) return state;

			if (action.data.id === state.id) {
				return null;
			}

			return state;

		case 'SIGNOUT_SUCCESS':
			return null;

		default:
			return state;
	}
};
