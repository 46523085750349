import {
	Divider,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import _ from 'lodash';

import { Utils } from '@rentcheck/biz';
import { ApiInspectionWithTemplate } from '@rentcheck/types';

import { Column, Spacer } from 'components';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { useIsMobileBreakpoint } from 'utils/hooks';

import RevisionRow from './row';

interface Props {
	inspection: ApiInspectionWithTemplate;
	setRevisionNotes: (notes: { [key: string]: string }) => void;
	revisionNotes: { [key: string]: string };
}

export default ({ inspection, setRevisionNotes, revisionNotes }: Props) => {
	const isMobile = useIsMobileBreakpoint();

	const features = useTypedSelector((state) =>
		Utils.InspectionFeatures.sortFeaturesBySection(
			_.values(state.features[inspection.id] ?? {}),
			inspection.inspection_template
		)
	);

	const revisions = features.filter((f) => f.revision_requested);

	return (
		<Column>
			<Spacer height={5} />
			<Typography variant="overline">Revisions</Typography>
			<Spacer height={3} />

			{revisions.length > 0 && (
				<TableContainer component={Paper}>
					<Table>
						<TableHead>
							<TableRow>
								{!isMobile && (
									<>
										<TableCell>Area/Room</TableCell>
										<TableCell>Feature</TableCell>
										<TableCell>Notes</TableCell>
										<TableCell>Rating</TableCell>
									</>
								)}
								{isMobile && <TableCell>Details</TableCell>}
								{!isMobile && <TableCell />}
								<TableCell />
							</TableRow>
						</TableHead>
						<TableBody>
							{revisions.map((f) => (
								<RevisionRow
									key={f.id}
									feature={f}
									setRevisionNotes={setRevisionNotes}
									revisionNotes={revisionNotes}
								/>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}

			{revisions.length <= 0 && (
				<Paper elevation={0} sx={{ border: '1px solid #0000001F', padding: 2 }}>
					<Typography>
						Currently, there are 0 features added for revision.
					</Typography>
					<Typography>
						To clearly outline necessary changes add individual features to this
						revision.
					</Typography>
				</Paper>
			)}

			<Spacer height={4} />
			<Divider />
		</Column>
	);
};
