import { Typography } from '@mui/material';
import { ApiTeam } from '@rentcheck/types';
import * as Sentry from '@sentry/react';
import { Column, Spacer, TextInput } from 'components';
import Modal, { Footer } from 'components/Common/Modal';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { TeamActions } from 'store/actions';
import { Dispatch } from 'types';

interface Props {
	team: ApiTeam;
	setModalVisible: (visible: boolean) => void;
}

const EditNameModal = ({ setModalVisible, team }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const [name, setName] = useState(team.name);
	const [internalLabel, setInternalLabel] = useState(team.internal_label);

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	const handleSave = async () => {
		setError('');
		setLoading(true);
		dispatch(
			TeamActions.update(team.id, {
				name,
				internal_label: internalLabel,
			})
		)
			.then(() => setModalVisible(false))
			.catch((e) => {
				setError(e.message);
				Sentry.captureException(e);
			})
			.finally(() => setLoading(false));
	};

	const disabled = !name || !internalLabel;

	return (
		<Modal setHidden={() => setModalVisible(false)}>
			<Column>
				<Typography variant="h5">Edit Team</Typography>
				<Spacer height={6} />
				<TextInput
					label="Name (the name used in resident communications; usually your company name)"
					onChange={setName}
					value={name}
				/>
				<Spacer height={6} />
				<TextInput
					label="Internal Label (displayed to you and team members; helpful when setting up multiple teams)"
					onChange={setInternalLabel}
					value={internalLabel}
				/>
				<Footer
					leftButtonTitle="Cancel"
					leftButtonOnClick={() => setModalVisible(false)}
					rightButtonTitle="Save & Close"
					rightButtonOnClick={handleSave}
					error={error}
					disabled={disabled}
					loading={loading}
				/>
			</Column>
		</Modal>
	);
};

export default EditNameModal;
