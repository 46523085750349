import React from 'react';
import { useDispatch } from 'react-redux';
import {
	TableHead,
	TableRow,
	TableCell,
	TableSortLabel,
	Checkbox,
} from '@mui/material';

import { WorkOrder } from '@rentcheck/api-frontend';

import { useTypedSelector } from 'store/reducers/rootReducer';

interface Props {
	selectedIds: string[];
	selectAllIds: () => void;
	workOrders: WorkOrder[];
}

const headers = [
	{ id: 'property_address', title: 'Address' },
	{ id: 'property_city', title: 'City' },
	{ id: 'property_zip_code', title: 'ZIP Code' },
	{ id: 'name', title: 'Report Name' },
	{ id: 'inspection_template', title: 'Template' },
	{ id: 'status', title: 'Status' },
	{ id: 'created_date', title: 'Date Created' },
];

const Header = ({ selectedIds, selectAllIds, workOrders }: Props) => {
	const dispatch = useDispatch();

	const sortBy = useTypedSelector(
		(state) => state.maintenance.filters.workOrders.sortBy
	);
	const sortType = useTypedSelector(
		(state) => state.maintenance.filters.workOrders.sortType
	);

	const switchSortDirection = () => {
		dispatch({
			type: 'TOGGLE_WORK_ORDERS_SORT_TYPE',
		});
	};

	const handleSort = (headerId: string) => {
		if (headerId === sortBy) {
			return switchSortDirection();
		}

		dispatch({
			type: 'SET_WORK_ORDERS_SORT_BY',
			value: headerId,
		});
	};

	const checked =
		selectedIds.length === workOrders.length && selectedIds.length > 0;

	const indeterminate =
		selectedIds.length > 0 && selectedIds.length !== workOrders.length;

	return (
		<TableHead>
			<TableRow>
				<TableCell>
					<Checkbox
						size="small"
						onChange={selectAllIds}
						indeterminate={indeterminate}
						checked={checked}
					/>
				</TableCell>
				{headers.map((h) => (
					<TableCell key={h.id}>
						<TableSortLabel
							active={sortBy === h.id}
							direction={sortType}
							onClick={() => handleSort(h.id)}>
							{h.title}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

export default Header;
