import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { LoadingButton } from '@mui/lab';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Typography,
} from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';
import styled from 'styled-components';
import { Analytics } from 'utils';
import { DialogTitle, Row, Spacer } from '..';
import { UploadEmailApi } from '../../api';
import { useTypedSelector } from '../../store/reducers/rootReducer';
import { colors } from '../../theme';
import UploadInProgress from './UploadInProgress';
import UploadModalText, { UploadType } from './UploadModalText';
import Uploader from './Uploader';

interface Props {
	setModalVisible: (visible: boolean) => void;
	type: UploadType;
}

const UploadEmailModal = ({ setModalVisible, type }: Props) => {
	const dispatch = useDispatch();

	const [loading, setLoading] = React.useState(false);
	const [response, setResponse] = React.useState(false);
	const [file, setFile] = React.useState<File | null>(null);

	const profile = useTypedSelector((state) => state.activeProfile);

	const upload = async () => {
		setLoading(true);

		if (file) {
			const result = await UploadEmailApi.sendUploadEmail(
				profile.name,
				file,
				type
			);

			if (!result) {
				dispatch(
					SnackbarActions.showError(
						'There was an error. Please try uploading your file again.'
					)
				);
			}

			if (type === 'resident') {
				Analytics.trackEvent('attached residents for upload', {});
			}

			if (type === 'property') {
				Analytics.trackEvent('attached units for upload', {});
			}

			setResponse(result);
		}
		setLoading(false);
	};

	if (response && !loading) {
		return <UploadInProgress setModalVisible={setModalVisible} />;
	}

	const title =
		type === 'property'
			? 'Upload Units to RentCheck'
			: 'Upload Residents to RentCheck';

	return (
		<Dialog open onClose={() => setModalVisible(false)}>
			<DialogTitle
				title={title}
				icon={solid('upload')}
				onClose={() => setModalVisible(false)}
			/>

			<DialogContent>
				<UploadModalText type={type} />

				<Spacer height={3} />

				<Typography variant="h6">Attach file</Typography>

				<Row>
					<Uploader setFile={setFile} file={file} />

					<Spacer width={3} />

					{file && (
						<Remover
							onClick={() => {
								setFile(null);
							}}>
							Remove
						</Remover>
					)}
				</Row>
			</DialogContent>
			<DialogActions>
				<Button variant="text" onClick={() => setModalVisible(false)}>
					Cancel
				</Button>

				<LoadingButton
					loading={loading}
					onClick={() => upload()}
					disabled={!file}>
					Upload
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

const Remover = styled.div`
	color: ${colors.primary};
	text-decoration: underline;
	&:hover {
		cursor: pointer;
	}
`;

export default UploadEmailModal;
