import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import {
	imageIs360Photo,
	imageUrlFromGCPUri,
	videoUrlFromGCPUri,
} from 'utils/helpers';

interface Props {
	src: string;
	children?: React.ReactNode;
	style?: React.CSSProperties;
	isVideo?: boolean;
}

const FirebaseMediaDiv = ({ src, style, children, isVideo }: Props) => {
	const uri = isVideo
		? videoUrlFromGCPUri(src, true)
		: imageUrlFromGCPUri(src, 240, 240);

	return (
		<Image style={style} image={uri}>
			{children}
			{!children && imageIs360Photo(src) && (
				<FontAwesomeIcon
					icon={regular('globe')}
					color="#fff"
					style={{ position: 'absolute', bottom: 6, right: 6 }}
				/>
			)}
			{!children && isVideo && (
				<FontAwesomeIcon
					icon={solid('video')}
					color="#fff"
					style={{ position: 'absolute', bottom: 6, right: 6 }}
				/>
			)}
		</Image>
	);
};

const Image = styled.div<{ image: string | null }>`
	background-color: #eaeaea;
	cursor: pointer;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: ${({ image }) => `url('${image}')`};
	position: relative;
`;

export default FirebaseMediaDiv;
