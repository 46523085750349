import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
	Checkbox,
	TableCell,
	TableRow,
	Tooltip,
	Typography,
} from '@mui/material';
import { TeamInvitesApi } from '@rentcheck/api-frontend';
import { Rules } from '@rentcheck/biz';
import { ApiUserDigest } from '@rentcheck/types';
import { Column } from 'components';
import IconButton from 'components/icon-button';
import MenuItem from 'components/menu-item';
import _ from 'lodash';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	ModalFlowActions,
	SnackbarActions,
	TeammatesActions,
} from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';
import { summarizeList } from 'utils/helpers';
import { useIsMobileBreakpoint } from 'utils/hooks';
import { handleDeactivateUsers } from './common';

interface Props {
	user: ApiUserDigest;
	selected: boolean;
	onSelect: (user: ApiUserDigest) => void;
}

const MobileInfoRow = ({
	title,
	value,
	wordWrap,
}: {
	title: string;
	value: string;
	wordWrap?: React.CSSProperties['wordWrap'];
}) => {
	return (
		<>
			<Typography
				variant="overline"
				color="secondary"
				sx={{
					mt: 3,
					':first-child': {
						mt: 0,
					},
				}}>
				{title}
			</Typography>
			<Typography sx={{ wordWrap }}>{value || '-'}</Typography>
		</>
	);
};

export default ({ user, selected, onSelect }: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const isMobile = useIsMobileBreakpoint('md');
	const profile = useTypedSelector((state) => state.activeProfile);
	const subscription = useTypedSelector((state) => state.subscription);

	const [loading, setLoading] = useState(false);

	const handleSendReminder = () => {
		setLoading(true);

		TeamInvitesApi.sendRemindersForAll(user.id)
			.then(() =>
				dispatch(SnackbarActions.showSuccess('Reminder sent successfully'))
			)
			.catch((e) => dispatch(SnackbarActions.showError(e)))
			.finally(() => setLoading(false));
	};

	const handleDeleteInvites = () => {
		setLoading(true);

		dispatch(TeammatesActions.Users.deleteAllInvitesForUser(user)).finally(() =>
			setLoading(false)
		);
	};

	const handleManageAccess = () => {
		dispatch(ModalFlowActions.showManageAccess({ user }));
	};

	const handleEditPermissionGroups = () => {
		dispatch(
			ModalFlowActions.showEditUsersPermissionGroupsModal({
				user_ids: [user.id],
			})
		);
	};

	const teamsTooltip =
		user.teams.length > 1 ? _.map(user.teams, 'internal_label').join(', ') : '';
	const permissionGroupsTooltip =
		user.permission_groups.length > 1
			? _.map(user.permission_groups, 'name').join(', ')
			: '';

	const { result: canDeactivate, reason: deactivateReason } =
		Rules.Teammates.canEdit(profile, subscription);
	const { result: canEdit, reason: editReason } = Rules.Teammates.canEdit(
		profile,
		subscription
	);

	const {
		result: canEditPermissionGroups,
		reason: editPermissionGroupsReason,
	} = Rules.PermissionGroups.canEdit(profile);

	if (isMobile) {
		return (
			<TableRow>
				<TableCell sx={{ verticalAlign: 'top', width: 30 }}>
					<Checkbox
						size="small"
						checked={selected}
						sx={{ mt: -1 }}
						onChange={() => onSelect(user)}
					/>
				</TableCell>
				<TableCell sx={{ maxWidth: 100 }}>
					<Column>
						<MobileInfoRow title="Name" value={user.name} />
						<MobileInfoRow
							title="Email"
							value={user.email}
							wordWrap="break-word"
						/>
						<MobileInfoRow
							title="Teams"
							value={user.teams.map((u) => u.internal_label).join(', ')}
						/>
						<MobileInfoRow
							title="Permission Groups"
							value={user.permission_groups.map((u) => u.name).join(', ')}
						/>
						<MobileInfoRow title="Status" value={user.status} />
					</Column>
				</TableCell>
				<TableCell align="center" sx={{ verticalAlign: 'top' }}>
					<IconButton icon={regular('ellipsis-v')} loading={loading}>
						{user.status === 'Active' && (
							<>
								<MenuItem
									onClick={() =>
										handleDeactivateUsers(profile, [user], dispatch)
									}
									tooltip={deactivateReason}
									disabled={!canDeactivate}
									visible={
										Rules.Teammates.canShowDeactivate(user, profile).result
									}>
									Deactivate User
								</MenuItem>
								<MenuItem
									disabled={!canEdit}
									tooltip={editReason}
									onClick={handleManageAccess}>
									Manage Access
								</MenuItem>
								<MenuItem
									disabled={!canEditPermissionGroups}
									tooltip={editPermissionGroupsReason}
									onClick={handleEditPermissionGroups}>
									Edit Permission Groups
								</MenuItem>
							</>
						)}
						{user.status !== 'Active' && (
							<>
								<MenuItem onClick={handleSendReminder}>Send Reminder</MenuItem>
								<MenuItem onClick={handleDeleteInvites}>Delete Invite</MenuItem>
								<MenuItem
									disabled={!canEditPermissionGroups}
									tooltip={editPermissionGroupsReason}
									onClick={handleEditPermissionGroups}>
									Edit Permission Groups
								</MenuItem>
							</>
						)}
					</IconButton>
				</TableCell>
			</TableRow>
		);
	}

	return (
		<TableRow>
			<TableCell>
				<Checkbox
					size="small"
					checked={selected}
					onChange={() => onSelect(user)}
				/>
			</TableCell>
			<TableCell>{user.name || '-'}</TableCell>
			<TableCell>{user.email}</TableCell>
			<Tooltip title={teamsTooltip}>
				<TableCell>
					{summarizeList(_.map(user.teams, 'internal_label'), 1) || '-'}
				</TableCell>
			</Tooltip>
			<Tooltip title={permissionGroupsTooltip}>
				<TableCell>
					{summarizeList(_.map(user.permission_groups, 'name'), 1) || '-'}
				</TableCell>
			</Tooltip>
			<TableCell>{user.status}</TableCell>
			<TableCell align="center">
				<IconButton icon={regular('ellipsis-v')} loading={loading}>
					{user.status === 'Active' && (
						<>
							<MenuItem
								onClick={() => handleDeactivateUsers(profile, [user], dispatch)}
								visible={
									Rules.Teammates.canShowDeactivate(user, profile).result
								}
								tooltip={deactivateReason}
								disabled={!canDeactivate}>
								Deactivate User
							</MenuItem>
							<MenuItem
								disabled={!canEdit}
								tooltip={editReason}
								onClick={handleManageAccess}>
								Manage Access
							</MenuItem>
							<MenuItem
								disabled={!canEditPermissionGroups}
								tooltip={editPermissionGroupsReason}
								onClick={handleEditPermissionGroups}>
								Edit Permission Groups
							</MenuItem>
						</>
					)}
					{user.status !== 'Active' && (
						<>
							<MenuItem onClick={handleSendReminder}>Send Reminder</MenuItem>
							<MenuItem onClick={handleDeleteInvites}>Delete Invite</MenuItem>
							<MenuItem
								disabled={!canEditPermissionGroups}
								tooltip={editPermissionGroupsReason}
								onClick={handleEditPermissionGroups}>
								Edit Permission Groups
							</MenuItem>
						</>
					)}
				</IconButton>
			</TableCell>
		</TableRow>
	);
};
