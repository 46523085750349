import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
	Alert,
	Card,
	CardContent,
	Dialog,
	DialogContent,
	Typography,
} from '@mui/material';
import {
	RentCheckIntegrationApi,
	RentCheckIntegrationCreateResponse,
} from '@rentcheck/api-frontend';
import { IntegrationRentcheckApi } from '@rentcheck/types';
import { RentCheckIntegrationApi as RentcheckIntegrationFirebaseApi } from 'api';
import { Column, DialogTitle, Row, SpacedRow, Spacer } from 'components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ModalFlowActions, SnackbarActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import styled from 'styled-components';
import { colors } from 'theme';
import { Dispatch } from 'types';

import { isRenter } from 'utils/helpers';

export default () => {
	const dispatch: Dispatch = useDispatch();

	const profile = useTypedSelector((state) => state.activeProfile);
	const subscription = useTypedSelector((state) => state.subscription);

	const [rentCheckIntegration, setRentCheckIntegration] =
		useState<IntegrationRentcheckApi>();

	const [newIntegration, setNewIntegration] =
		useState<RentCheckIntegrationCreateResponse>();

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (!subscription) {
			return;
		}

		RentcheckIntegrationFirebaseApi.getForSubscriptionId(subscription.id).then(
			setRentCheckIntegration
		);
	}, [subscription]);

	const handleCreateIdAndSecret = async () => {
		if (!subscription) {
			dispatch(
				SnackbarActions.showError(
					'You must have an active subscription in order to use the RentCheck API'
				)
			);
			return;
		}

		setLoading(true);
		dispatch(ModalFlowActions.setConfirmationModalLoading(true));

		try {
			await RentcheckIntegrationFirebaseApi.deleteForSubscriptionId(
				subscription.id
			);

			const newIntegration = await RentCheckIntegrationApi.create(
				subscription.id
			);
			setNewIntegration(newIntegration);

			const rentCheckIntegration =
				await RentcheckIntegrationFirebaseApi.getForSubscriptionId(
					subscription.id
				);
			setRentCheckIntegration(rentCheckIntegration);

			dispatch(ModalFlowActions.closeConfirmationModal());
		} catch (e) {
			dispatch(SnackbarActions.showError((e as Error).message));
		}

		setLoading(false);
		dispatch(ModalFlowActions.setConfirmationModalLoading(false));
	};

	const handleClose = () => {
		setNewIntegration(undefined);
	};

	const handleCopy = (type: string, value?: string) => {
		if (!navigator.clipboard) return;
		if (!value) return;

		navigator.clipboard.writeText(value);
		dispatch(
			SnackbarActions.showSuccess(`The ${type} was copied to your clipboard`)
		);
	};

	const handleRegenerate = () => {
		if (!subscription) {
			return;
		}

		dispatch(
			ModalFlowActions.showConfirmationModal({
				title: `Regenerate App Secret for ${subscription.rentcheck_contact.company}`,
				body1: [
					'Services using the previous App ID and Secret will stop working.',
				],
				body2: [
					'Be sure to deploy the new client secret quickly to ensure minimal service disruption.',
				],
				buttons: [
					ModalFlowActions.cancelButton(dispatch),
					{
						title: 'Regenerate',
						color: 'error',
						variant: 'contained',
						onClick: handleCreateIdAndSecret,
					},
				],
			})
		);
	};

	if (isRenter(profile)) return null;

	return (
		<>
			<Typography variant="h6">App ID and Secret</Typography>
			<Spacer height={3} />

			<Typography>
				Your App ID and Secret are used to authenticate your requests when
				accessing the RentCheck API.
			</Typography>

			<Spacer height={4} />

			{!rentCheckIntegration && (
				<LoadingButton loading={loading} onClick={handleCreateIdAndSecret}>
					Generate Client Id and Secret
				</LoadingButton>
			)}

			{!!rentCheckIntegration && (
				<Card sx={{ maxWidth: 560 }}>
					<CardContent>
						<Column>
							<Typography color="#445567">App ID</Typography>
							<SpacedRow>
								<Typography>{rentCheckIntegration.app_id}</Typography>
								<Clickable
									onClick={() =>
										handleCopy('App ID', rentCheckIntegration?.app_id)
									}>
									<Typography>Copy</Typography>
									<Spacer width={1} />
									<FontAwesomeIcon icon={regular('copy')} />
								</Clickable>
							</SpacedRow>
						</Column>

						<Spacer height={4} />

						<Column>
							<Typography color="#445567">App Secret</Typography>
							<SpacedRow>
								<Typography>**********************</Typography>
								<Clickable onClick={handleRegenerate}>
									<Typography>Regenerate</Typography>
								</Clickable>
							</SpacedRow>
						</Column>
					</CardContent>
				</Card>
			)}

			<Dialog open={!!newIntegration}>
				<DialogTitle
					title={`API Credentials for ${subscription?.rentcheck_contact.company}`}
					onClose={handleClose}
				/>

				<DialogContent>
					<Alert color="warning">
						You will only see the app secret this one time. Be sure to copy and
						paste it into a safe place before closing this modal.
					</Alert>

					<Spacer height={4} />
					<Card>
						<CardContent>
							<Column>
								<Typography color="#445567">App ID</Typography>
								<SpacedRow>
									<Typography>{newIntegration?.app_id}</Typography>
									<Clickable
										onClick={() =>
											handleCopy('App ID', newIntegration?.app_id)
										}>
										<Typography>Copy</Typography>
										<Spacer width={1} />
										<FontAwesomeIcon icon={regular('copy')} />
									</Clickable>
								</SpacedRow>
							</Column>

							<Spacer height={4} />

							<Column>
								<Typography color="#445567">App Secret</Typography>
								<SpacedRow>
									<Typography>{newIntegration?.app_secret}</Typography>
									<Clickable
										onClick={() =>
											handleCopy('App Secret', newIntegration?.app_secret)
										}>
										<Typography>Copy</Typography>
										<Spacer width={1} />
										<FontAwesomeIcon icon={regular('copy')} />
									</Clickable>
								</SpacedRow>
							</Column>
						</CardContent>
					</Card>
				</DialogContent>
			</Dialog>
		</>
	);
};

const Clickable = styled(Row)`
	cursor: pointer;
	padding: 4px;
	border-radius: 4px;
	color: ${colors.gray};

	-webkit-transition: background-color 0.25s ease;
	transition: background-color 0.25s ease;

	&:hover {
		background-color: #dadada;
	}
`;
