import { PropertiesApi } from '@rentcheck/api-frontend';
import { ApiInspection, APIProperty, Feature } from '@rentcheck/types';
import { FeaturesApi } from 'api';
import _ from 'lodash';
import { DiffResult } from 'store/actions/properties/units';
import { propertyIsUnit, sortRoomsFunction } from 'utils/helpers';

export const extraRoomsInInspection = (
	inspection: ApiInspection,
	property: APIProperty
): DiffResult[] => {
	return _.difference(inspection.room_names, property.room_names).map((r) => ({
		roomName: r,
		operation: 'add',
	}));
};

export const naRoomsInInspection = (features: Feature[]): DiffResult[] => {
	const roomTypeFeatures = features.filter((f) => f.section.type === 'room');
	const groupedFeatures = _.values(
		_.groupBy(roomTypeFeatures, (f) => f.section.name_with_ordinal)
	);

	return groupedFeatures
		.filter((group) => !group.find((f) => !f.not_applicable))
		.map(
			(g) =>
				({
					roomName: g[0].section.name_with_ordinal,
					operation: 'remove',
				}) as DiffResult
		);
};

export const inspectionPropertyDiff = async (
	inspection: ApiInspection
): Promise<DiffResult[]> => {
	try {
		const property = await PropertiesApi.getById(inspection.property.id);

		if (!property) {
			throw new Error(`Property not found: ${inspection.property.id}`);
		}

		if (!propertyIsUnit(property)) {
			return [] as DiffResult[];
		}

		const features = await FeaturesApi.getForInspectionId(inspection.id);

		const naRooms = naRoomsInInspection(features);
		const extraRooms = extraRoomsInInspection(inspection, property).filter(
			(r) => !naRooms.find((naRoom) => naRoom.roomName === r.roomName)
		);

		const cleanedupNaRooms = naRooms.filter((dr) =>
			property.room_names.includes(dr.roomName)
		);

		return [...extraRooms, ...cleanedupNaRooms].sort((a, b) =>
			sortRoomsFunction(a.roomName, b.roomName)
		);
	} catch (e: any) {
		return [];
	}
};
