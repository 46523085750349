import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Tooltip,
	Typography,
} from '@mui/material';
import { useDispatch } from 'react-redux';

import { DialogTitle } from 'components';
import { ModalFlowActions, TeammatesActions } from 'store/actions';
import { Dispatch } from 'types';

import { LoadingButton } from '@mui/lab';
import { MembershipRole } from '@rentcheck/types';
import { useManageAccessModalData } from 'hooks/modals';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import AssignPermissionGroup from '../edit-users-permission-groups/assign-permission-group';
import AssignRole from '../invite-users/assign-role';
import TeamSelection from '../invite-users/team-selection';

interface Props {
	open: boolean;
}

const getButtonProps = (teamIds: string[], role?: MembershipRole) => {
	const buttonDisabled = teamIds.length === 0 || !role;
	const buttonTooltip =
		teamIds.length === 0
			? 'Select at least one team'
			: !role
				? 'Select a role'
				: '';

	return { buttonDisabled, buttonTooltip };
};

export default ({ open }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const modalData = useManageAccessModalData();
	const userDigest = modalData?.metadata.user;

	const [teamIds, setTeamIds] = useState<string[]>([]);
	const [role, setRole] = useState<MembershipRole>();
	const [permissionGroupIds, setPermissionGroupIds] = useState<string[]>([]);

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (!userDigest) return;

		setTeamIds(userDigest.teams.map((team) => team.id));
		setPermissionGroupIds(
			userDigest.permission_groups.map((group) => group.id)
		);

		/**
		 * If the user has the same role for all their teams
		 * then set the role to that value, otherwise leave it undefined
		 */
		const roles = _.uniq(userDigest.teams.map((team) => team.role));
		setRole(roles.length === 1 ? roles[0] : undefined);
	}, [userDigest]);

	const handleClose = () => {
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	const handleSave = () => {
		if (!role) return;

		setLoading(true);

		dispatch(
			TeammatesActions.Users.updateAccess(userDigest, {
				team_ids: teamIds,
				role,
				permission_group_ids: permissionGroupIds,
			})
		)
			.then(() => handleClose())
			.finally(() => {
				setLoading(false);
			});
	};

	const { buttonDisabled, buttonTooltip } = getButtonProps(teamIds, role);

	if (!userDigest) return null;

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle
				title="Manage Access"
				icon={solid('key')}
				onClose={handleClose}
			/>
			<DialogContent>
				<Typography variant="overline">User</Typography>
				<Typography variant="h6" mb={3}>
					{userDigest.name}
				</Typography>

				<TeamSelection
					value={teamIds}
					setValue={setTeamIds}
					caption="Teammates will have access to the properties included in selected teams."
				/>
				<AssignRole value={role} setValue={setRole} />
				<AssignPermissionGroup
					value={permissionGroupIds}
					setValue={setPermissionGroupIds}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} variant="text" color="secondary">
					Cancel
				</Button>
				<Tooltip title={buttonTooltip}>
					<span>
						<LoadingButton
							loading={loading}
							disabled={buttonDisabled}
							onClick={handleSave}>
							Save
						</LoadingButton>
					</span>
				</Tooltip>
			</DialogActions>
		</Dialog>
	);
};
