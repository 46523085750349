import { isEmbeddedInMobileApp, postMessageToNativeApp } from 'utils/helpers';

export const handleNativeAppClose = () => {
	if (!isEmbeddedInMobileApp()) return;

	postMessageToNativeApp({
		type: 'closed-modal',
		modal: 'create-work-order',
	});
};
